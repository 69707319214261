import React, {Component, Fragment} from 'react';
import ListaTabla from './Includes/ListaTabla';
import ModalCuentaBancaria from './Includes/ModalCuentaBancaria';

import {CuentaBancariaService} from '../../../services/CuentaBancariaService/CuentaBancariaService';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {BotonActualizarLista, BotonExportarListaExcel, BotonExportarListaPDF, CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import FiltrosCuentaBancaria from "./Includes/FiltrosCuentaBancaria";
import Paginacion from "../../Include/Paginacion/Paginacion";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";


class CuentaBancaria extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			listar_cuenta_bancaria: [],
			lista_usuario: [],
			lista_empresa: [],
			
			filtro: {},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
		};
		
		this.Listar();
		this.RefreshList2();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	RefreshList2 = () => {
		this.ListEmpresa();
		this.ListaUsuario();
	};
	
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	ListEmpresa = () => {
		CatService.ListEmpresa().then((response) => {
			this.setState({
				lista_empresa: response.data
			});
		});
	};
	
	ListaUsuario = () => {
		CatService.ListUsuarioTipoReporte().then((response) => {
			this.setState({
				lista_usuario: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_usuario: []
			});
			this.showSnackBars('error', 'Por el momento no hay usuarios registrados');
		});
	};
	
	Listar = () => {
		CuentaBancariaService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				listar_cuenta_bancaria: response.data,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			this.setState({
				listar_cuenta_bancaria: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	exportar = (item, archivo, auth) => {
		let filtro = {
			id_cuenta_bancaria: item.id_cuenta_bancaria || '',
			id_usuario_apoderado: item.id_usuario_apoderado || '',
		};
		console.log('EXPORTAR::: ', item);
		HttpRequest.export('cuentasbancarias', ['cuentas_bancarias'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, false, false, false, archivo === "EXCEL")
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'Cuentas Bancarias'}
					/*botonPDF={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaPDF onClick={() => {
								
								}}/>
							) : ''}
						</Fragment>
					}*/
					botonEXCEL={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaExcel onClick={() => this.exportar({}, 'EXCEL', true)}/>
							) : ''}
						</Fragment>
					}
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() => {
								this.Listar();
							}}/>
						</Fragment>
					}
				/>
				<FiltrosCuentaBancaria
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
					lista_empresa={this.state.lista_empresa}
					lista_usuario={this.state.lista_usuario}
				/>
				
				<div className={'form margin-30-B'} style={{marginTop: '20px'}}>
					
					{this.state.listar_cuenta_bancaria.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_cuenta_bancaria}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
								RefreshList2={this.RefreshList2}
								lista_empresa={this.state.lista_empresa}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				<ModalCuentaBancaria
					tipo={'add'}
					item={{}}
					lista_empresa={this.state.lista_empresa}
					RefreshList={this.RefreshList}
					RefreshList2={this.RefreshList2}
					showSnackBars={this.showSnackBars}
				/>
			
			</div>
		);
	}
}

CuentaBancaria.propTypes = {};

export default IntegrationNotistack(CuentaBancaria);
