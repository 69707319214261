import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const ProveedorService = {
	Listar: (filtro, paginacion) => {
		let params = {
			/*filtro: {
				proveedor: filtro.proveedor || null,
				rfc: filtro.rfc || null,
				correo_electronico: filtro.correo_electronico || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}*/
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Proveedor_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarTodo: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Proveedor_Datos_All', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		
		let params = {
			id_proveedor: null,
			id_cat_tipo_persona: form.tipo_persona === 'fisica' ? 1 : 2,
			razon_social: form.razon_social,
			nombre_comercial: form.nombre_comercial,
			nombre: form.nombre,
			apellido_paterno: form.apellido_paterno,
			apellido_materno: form.apellido_materno,
			correo_electronico: form.correo_electronico,
			telefono: form.telefono,
			celular: form.celular,
			rfc: form.rfc,
			activo: form.activo ? 1 : 0,
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Proveedor_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_proveedor: form.id_proveedor,
			id_cat_tipo_persona: form.tipo_persona === 'fisica' ? 1 : 2,
			id_cat_giro_cliente: form.id_cat_giro_cliente,
			razon_social: form.razon_social,
			nombre_comercial: form.nombre_comercial,
			nombre: form.nombre,
			apellido_paterno: form.apellido_paterno,
			apellido_materno: form.apellido_materno,
			correo_electronico: form.correo_electronico,
			telefono: form.telefono,
			celular: form.celular,
			rfc: form.rfc,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Proveedor_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_proveedor) => {
		let params = {
			id_proveedor: id_proveedor
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Proveedor_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
