import React, {Component, Fragment} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";
import {CONFIG} from "../../../../../settings/Config/Config";
import {EditOutlined} from '@material-ui/icons';
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ModalDetalleEmpleado from "./ModalDetalleEmpleado";
import Zoom from '@material-ui/core/Zoom/index';
import Tooltip from "@material-ui/core/Tooltip";


class ListaEmpleados extends Component {
	render() {
		return (
			<List>
				{this.props.cliente_empleado.map((value, index) => (
					<Fragment key={index}>
						{index > 0 ? <Divider variant="inset" component="li"/> : null}
						<ListItem alignItems="flex-start">
							<ListItemAvatar>
								<Avatar
									alt={value.nombre + " " + value.apellido_paterno + " " + value.apellido_materno}
									src={CONFIG.src + value.foto}
								/>
							</ListItemAvatar>
							<ListItemText
								primary={value.nombre + " " + value.apellido_paterno + " " + value.apellido_materno}
								secondary={
									<React.Fragment>
										<Typography component="span" variant="body2" color="textPrimary">
											{value.departamento}, {value.puesto}
										</Typography>
										{" — "} {value.rfc} {value.curp} {value.nss}
									</React.Fragment>
								}
							/>
							<ListItemSecondaryAction>
								<ModalDetalleEmpleado
									item={value}
									icono={(
										<Tooltip TransitionComponent={Zoom} placement={"top"} title={"Detalles"}>
											<IconButton edge="end" aria-label="comments">
												<EditOutlined/>
											</IconButton>
										</Tooltip>
									)}
								/>
							</ListItemSecondaryAction>
						</ListItem>
					</Fragment>
				))}
			</List>
		);
	}
}


ListaEmpleados.propTypes = {
	cliente_empleado: PropTypes.array.isRequired,
};

export default ListaEmpleados;
