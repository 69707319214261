import React, {Component, Fragment} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Autoplay, Pagination, Navigation} from 'swiper/modules';

import credito_procea from "../../../../assets/img/icons/credito_procea.jpeg";
import solicitud_vacaciones from "../../../../assets/img/icons/solicitud_vacaciones.jpeg";

class ModalCreditoProcea extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: true
			},
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		console.log("Close modal");
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	};
	
	go = (tipo) => {
		console.log("this a go");
		this.closeModal();
		tipo ? this.props.goCreditoProcea() : this.props.goVacaciones();
	};
	
	
	render() {
		
		return (
			<div>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					<DialogContent>
						
						<Grid container spacing={0} alignItems={"flex-end"} alignContent={"flex-end"}>
							<Grid item xs={12}>
								<Swiper
									spaceBetween={30}
									centeredSlides={true}
									autoplay={{
										delay: 2500,
										disableOnInteraction: false,
									}}
									pagination={{
										clickable: true,
									}}
									navigation={true}
									modules={[Autoplay, Pagination, Navigation]}
									className="mySwiper"
								>
									<SwiperSlide>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<img src={solicitud_vacaciones} height={'100%'} alt={"Credito Procea"} onClick={() => this.go(0)} className={'cursor-pointer'}/>
											</Grid>
										</Grid>
									</SwiperSlide>
									<SwiperSlide>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<img src={credito_procea} width={'100%'} alt={"Credito Procea"} onClick={() => this.go(1)} className={'cursor-pointer'}/>
											</Grid>
										</Grid>
									</SwiperSlide>
								</Swiper>
							</Grid>
							
							<Grid item xs={12} align={'right'}>
								<Button onClick={() => this.close()} react-tappable variant="contained" color="primary" className={'btn-default text-transform-none cursor-pointer margin-10-T margin-5-B'}>
									Cerrar
								</Button>
							</Grid>
						
						</Grid>
					
					</DialogContent>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ModalCreditoProcea.propTypes = {
	goCreditoProcea: PropTypes.func.isRequired,
	goVacaciones: PropTypes.func.isRequired,
	RefreshList: PropTypes.func,
	showSnackBars: PropTypes.func,
};

export default ModalCreditoProcea;
