import React, {Component, Fragment} from "react";

import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import logo_svg from "../../../../assets/img/logo.svg";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import ModalSeleccionarArchivoElaboracionPropuesta from "./ModalSeleccionarArchivoElaboracionPropuesta";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {EnteroSolo, FieldsJs, FloatSolo} from "../../../../settings/General/General";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import {DeleteOutlined, PlaylistAddOutlined} from "@material-ui/icons";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import ModalPlaza from "../../Cat/Plaza/Includes/ModalPlaza";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import {PlazaService} from "../../../../services/_Cat/Plaza/PlazaService";


class SeccionPromotorRequerimientos extends Component {
	
	state = {};
	
	Usr = {};
	handleChange = this.props.handleChange;
	showSnackBars = this.props.showSnackBars;
	$BroadcastModalSeleccionarArchivoElaboracionPropuesta = this.props.$BroadcastModalSeleccionarArchivoElaboracionPropuesta;
	sirocActive = this.props.sirocActive;
	agregar_otro_impuesto = this.props.agregar_otro_impuesto;
	agregar_estado_municipio_impuesto = this.props.agregar_estado_municipio_impuesto;
	ListMunicipio = this.props.ListMunicipio;
	guardarPromotor = this.props.guardarPromotor;
	eliminar_estado_municipio_impuesto = this.props.eliminar_estado_municipio_impuesto;
	eliminar_otro_impuesto = this.props.eliminar_otro_impuesto;
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		const params = props.params || {};
		
		console.log('PARAMSSSSSS', props);
		this.state = {
			params: params,
			
			lista_plaza: props.state.lista_plazas || [],
			
		};
		
	}
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	RefreshListPlaza = (id_plaza) => {
		if (id_plaza > 0) {
			this.ListaPlaza().then(r => {
				this.setState({
					id_plaza_pertenece_cliente: id_plaza
				})
			}).catch(e => {
				console.log(e);
			});
		}
	};
	
	RefreshListPlaza2 = (id_plaza) => {
		if (id_plaza > 0) {
			this.ListaPlaza().then(r => {
				this.setState({
					id_plaza_opera_nomina: id_plaza
				})
			}).catch(e => {
				console.log(e);
			});
		}
	};
	
	ListaPlaza = () => {
		return new Promise((resolve, reject) => {
			PlazaService.Listar().then(value => {
				this.setState({
					lista_plaza: value.data
				});
				resolve(true);
			}).catch(reason => {
				this.setState({
					lista_plaza: []
				});
				reject(false)
			});
		});
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	render() {
		
		const disabledFieldPromotorRequerimientoCliente = this.props.disabledFieldPromotorRequerimientoCliente;
		
		return (
			<Fragment>
				<Grid container spacing={3}>
					
					<Grid item className={'text-left padding-10'} xs={12} sm={12} md={5} lg={5} xl={5}>
						
						<article className={'h-75-px'}>
							
							<div className={'padding-10'}>
								<Grid container spacing={2}>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<div className={'w-100'} align={'center'}>
											<img src={logo_svg} alt="logo" width={'200px'} height={'auto'}/>
										</div>
									</Grid>
								
								</Grid>
							</div>
						
						</article>
						
						<article className={'borde-punteado-lightgray-2 card-0 bg-white h-100-77-px'}>
							
							<div className={'padding-20'}>
								<Grid container spacing={2}>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography variant={'h6'} className={'text-left'}>
											1.- Datos de la empresa del cliente
										</Typography>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										
										<TextField
											fullWidth
											onChange={this.handleChange}
											type="text"
											margin="none"
											
											required
											helperText="Requerido"
											name="actividad_ante_sat"
											label="Actividad preponderante ante el SAT"
											value={this.props.state.actividad_ante_sat}
											disabled={disabledFieldPromotorRequerimientoCliente}
										/>
									
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										
										<TextField
											fullWidth
											onChange={this.handleChange}
											type="text"
											margin="none"
											inputProps={{maxLength: 13}}
											
											required
											helperText="Requerido"
											name="rfc"
											label="R.F.C"
											value={this.props.state.rfc}
											disabled={this.is_root}
										/>
									
									</Grid>
								
								</Grid>
							</div>
						
						</article>
					</Grid>
					
					<Grid item className={'text-left padding-10'} xs={12} sm={12} md={7} lg={7} xl={7}>
						<article className={'borde-punteado-lightgray-2 card-0 bg-white padding-20'}>
							
							<Grid container spacing={2}>
								
								<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
									
									<Grid container spacing={2}>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12}
										      xl={12}>
											<Typography variant={'h6'} className={'text-left margin-11-B'}>
												2.- Datos para operación
											</Typography>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12}
										      xl={12}>
											
											<TextField
												multiline
												fullWidth
												onChange={this.handleChange}
												type="text"
												margin="none"
												
												required
												helperText="Requerido"
												name="servicio_requerido_propuesto"
												label="Servicios requeridos o propuestos"
												value={this.props.state.servicio_requerido_propuesto}
												disabled={disabledFieldPromotorRequerimientoCliente}
											/>
										
										</Grid>
										
										{/*<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12}
										      xl={12}>
											<TextField
												select
												fullWidth
												margin="none"
												onChange={this.handleChange}
												SelectProps={{
													native: true, MenuProps: {
														className: '',
													},
												}}
												
												required
												helperText="Requerido"
												name="id_plaza_pertenece_cliente"
												label="Plaza a la que pertenece el cliente"
												value={this.props.state.id_plaza_pertenece_cliente}
												disabled={disabledFieldPromotorRequerimientoCliente}
											>
												<option value={''}>&nbsp;</option>
												{this.props.state.lista_plazas.map((item, key) => (
													<option key={key} value={item.id_plaza}>
														{item.nombre_comercial}
													</option>))}
											</TextField>
										</Grid>*/}
										
										<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12}>
											<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
												{!this.is_view() ? (
													<Grid item style={{width: '50px'}}>
														{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
															<Fragment>
																<ModalPlaza
																	componente={(
																		<Tooltip
																			TransitionComponent={Zoom}
																			placement={"right"}
																			title="Agregar nuevo Plaza"
																			children={(
																				<Fab
																					color="secondary"
																					size="small"
																					aria-label="Agregar nueva plaza"
																					children={(
																						<PlaylistAddOutlined/>
																					)}
																				/>
																			)}
																		/>
																	)}
																	id_plaza_direccion={null}
																	tipo={'add'}
																	item={{}}
																	RefreshList={this.RefreshListPlaza}
																	showSnackBars={this.props.showSnackBars}
																/>
															</Fragment>
														) : ''}
													</Grid>
												) : null}
												<Grid item style={{width: !this.is_view() ? 'calc(100% - 50px)' : 'calc(100%)'}}>
													<TextField
														select
														fullWidth
														margin="none"
														required
														onChange={this.handleChange}
														disabled={disabledFieldPromotorRequerimientoCliente}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														helperText="Requerido"
														name="id_plaza_pertenece_cliente"
														label="Plaza a la que pertenece el cliente"
														value={this.props.state.id_plaza_pertenece_cliente}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.lista_plaza.map(option => (
															<option key={option.id_plaza} value={option.id_plaza}>
																{option.plaza} - {option.abreviatura}
															</option>
														))}
													</TextField>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12}>
											<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
												{!this.is_view() ? (
													<Grid item style={{width: '50px'}}>
														{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
															<Fragment>
																<ModalPlaza
																	componente={(
																		<Tooltip
																			TransitionComponent={Zoom}
																			placement={"right"}
																			title="Agregar nuevo Plaza"
																			children={(
																				<Fab
																					color="secondary"
																					size="small"
																					aria-label="Agregar nueva plaza"
																					children={(
																						<PlaylistAddOutlined/>
																					)}
																				/>
																			)}
																		/>
																	)}
																	id_plaza_direccion={null}
																	tipo={'add'}
																	item={{}}
																	RefreshList={this.RefreshListPlaza2}
																	showSnackBars={this.props.showSnackBars}
																/>
															</Fragment>
														) : ''}
													</Grid>
												) : null}
												<Grid item style={{width: !this.is_view() ? 'calc(100% - 50px)' : 'calc(100%)'}}>
													<TextField
														select
														fullWidth
														margin="none"
														required
														onChange={this.handleChange}
														disabled={disabledFieldPromotorRequerimientoCliente}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														helperText="Requerido"
														name="id_plaza_opera_nomina"
														label="Plaza que opera la nómina"
														value={this.props.state.id_plaza_opera_nomina}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.lista_plaza.map(option => (
															<option key={option.id_plaza} value={option.id_plaza}>
																{option.plaza} - {option.abreviatura}
															</option>
														))}
													</TextField>
												</Grid>
											</Grid>
										</Grid>
										
										{/*<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12}
										      xl={12}>
											
											<TextField
												select
												fullWidth
												margin="none"
												onChange={this.handleChange}
												SelectProps={{
													native: true, MenuProps: {
														className: '',
													},
												}}
												
												required
												helperText="Requerido"
												name="id_plaza_opera_nomina"
												label="Plaza que opera la nómina"
												value={this.props.state.id_plaza_opera_nomina}
												disabled={disabledFieldPromotorRequerimientoCliente}
											>
												<option value={''}>&nbsp;</option>
												{this.props.state.lista_plazas.map((item, key) => (
													<option key={key} value={item.id_plaza}>
														{item.nombre_comercial}
													</option>))}
											</TextField>
										
										</Grid>*/}
									
									</Grid>
								
								</Grid>
								
								<Grid item className={'text-center'} xs={12} sm={12} md={6} lg={6} xl={6}>
									
									<div className={'margin-10-B margin-20-B margin-7-T'}>
										Archivos para elaboración de propuesta:
									</div>
									
									<div className={'w-100 h-100'}>
										
										<div
											className={'w-100-26-px h-auto bg-ghostwhite borde-punteado-danger-2 padding-10'}>
											<div className={'grid-20-60-px'}>
												
												{this.props.state.ficha_tecnica_archivo_elaboracion_propuesta.map((item, key) =>
													<ModalSeleccionarArchivoElaboracionPropuesta
														key={key}
														especifico={{
															label: item.archivo_elaboracion_propuesta || null,
															is_required: Number(item.is_required) === 1
														}}
														item={item}
														// tipo={disabledFieldPromotorRequerimientoCliente ? 'view' : (item.archivo_elaboracion_propuesta ? 'add' : 'edit')}
														tipo={item.archivo_elaboracion_propuesta ? 'add' : 'edit'}
														removefilepromotor={this.props.removefilepromotor}
														index={key}
														$BroadcastModalSeleccionarArchivoElaboracionPropuesta={this.$BroadcastModalSeleccionarArchivoElaboracionPropuesta}
													/>
												)}
												
												{/*{!disabledFieldPromotorRequerimientoCliente ? (*/}
													<ModalSeleccionarArchivoElaboracionPropuesta
														item={{}}
														tipo={'add'}
														especifico={{
															label: null,
															is_required: false
														}}
														removefilepromotor={this.props.removefilepromotor}
														$BroadcastModalSeleccionarArchivoElaboracionPropuesta={this.$BroadcastModalSeleccionarArchivoElaboracionPropuesta}
													/>
												{/*) : ''}*/}
											
											</div>
										</div>
									
									</div>
								
								</Grid>
							
							</Grid>
						
						</article>
					</Grid>
					
					<Grid item className={'text-left padding-10'} xs={12} sm={12} md={12} lg={12} xl={12}>
						
						<div className={'borde-punteado-lightgray-2 card-0 bg-white padding-20'}>
							
							<Grid container spacing={3}>
								
								<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography variant={'h6'} className={'text-left'}>
										3.- Datos para cotización
									</Typography>
								</Grid>
								
								<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
									
									<FormGroup row>
										
										<FormControlLabel
											control={<Checkbox type="checkbox" name='sueldo_salario'
											                   checked={this.props.state.sueldo_salario}
											                   onChange={(e) => {
												                   this.handleChange(e);
												                   this.sirocActive();
											                   }}
											                   value="sueldo_salario"
											                   disabled={disabledFieldPromotorRequerimientoCliente}/>}
											label="Sueldos y salarios"
										/>
										
										<FormControlLabel
											control={<Checkbox type="checkbox" name='cooperativa'
											                   checked={this.props.state.cooperativa}
											                   onChange={this.handleChange} value="cooperativa"
											                   disabled={disabledFieldPromotorRequerimientoCliente}/>}
											label="Cooperativa"
										/>
										
										<FormControlLabel
											control={<Checkbox type="checkbox" name='honorarios'
											                   checked={this.props.state.honorarios}
											                   onChange={this.handleChange} value="honorarios"
											                   disabled={disabledFieldPromotorRequerimientoCliente}/>}
											label="Honorarios"
										/>
										
										<FormControlLabel
											control={<Checkbox type="checkbox" name='sociedad_civil'
											                   checked={this.props.state.sociedad_civil}
											                   onChange={this.handleChange}
											                   value="sociedad_civil"
											                   disabled={disabledFieldPromotorRequerimientoCliente}/>}
											label="Sociedad civil"
										/>
										
										<FormControlLabel
											control={<Checkbox type="checkbox" name='asimilados_salario'
											                   checked={this.props.state.asimilados_salario}
											                   onChange={this.handleChange}
											                   value="asimilados_salario"
											                   disabled={disabledFieldPromotorRequerimientoCliente}/>}
											label="Asimilados a salarios"
										/>
										
										<FormControlLabel
											control={<Checkbox type="checkbox" name='outsourcing'
											                   checked={this.props.state.outsourcing}
											                   onChange={(e) => {
												                   this.handleChange(e);
												                   this.sirocActive();
											                   }} value="outsourcing"
											                   disabled={disabledFieldPromotorRequerimientoCliente}/>}
											label="Outsourcing"
										/>
										
										{!!this.props.state.siroc_active ? (
											<FormControlLabel
												control={<Checkbox type="checkbox" name='siroc'
												                   checked={this.props.state.siroc}
												                   onChange={this.handleChange}
												                   value="siroc"
												                   disabled={disabledFieldPromotorRequerimientoCliente}/>}
												label="SIROC"
											/>
										) : ""}
										
										<div className={'padding-10-L padding-10-R'}>
											<TextField
												onChange={this.handleChange}
												type="text"
												margin="none"
												
												helperText=""
												name="otros"
												label="Otros"
												value={this.props.state.otros}
												disabled={disabledFieldPromotorRequerimientoCliente}
											/>
										</div>
									
									</FormGroup>
								
								</Grid>
								
								
								<Grid item className={'text-left'} xs={12} sm={12} md={2} lg={2} xl={2}>
									
									<TextField
										select
										fullWidth
										margin="none"
										onChange={this.handleChange}
										SelectProps={{
											native: true, MenuProps: {
												className: '',
											},
										}}
										
										helperText="Requerido"
										name="id_cat_periodo_pago"
										label="Periodo de pago de nomina"
										value={this.props.state.id_cat_periodo_pago}
										disabled={disabledFieldPromotorRequerimientoCliente}
									>
										<option value={''}>&nbsp;</option>
										{this.props.state.lista_cat_periodo_pago.map((item, key) => (
											<option key={key} value={item.id_cat_periodo_pago}>
												{item.periodo_pago}
											</option>))}
									</TextField>
								
								</Grid>
								
								<Grid item className={'text-left'} xs={12} sm={4} md={3} lg={3} xl={2}>
									<TextField
										select
										fullWidth
										margin="none"
										onChange={this.handleChange}
										SelectProps={{
											native: true, MenuProps: {
												className: '',
											},
										}}
										
										helperText="Requerido"
										name="id_cat_factor_riesgo_imss"
										label="Factor de riesgo ante el IMSS"
										value={this.props.state.id_cat_factor_riesgo_imss}
										disabled={disabledFieldPromotorRequerimientoCliente}
									>
										<option value={''}>&nbsp;</option>
										{this.props.state.lista_cat_factor_riesgo_imss.map((item, key) => (
											<option key={key} value={item.id_cat_factor_riesgo_imss}>
												{item.factor_riesgo_imss}
											</option>))}
									</TextField>
								</Grid>
								
								
								<Grid item className={'text-left'} xs={12} sm={4} md={3} lg={3} xl={3}>
									<TextField
										select
										fullWidth
										margin="none"
										onChange={(e) => {
											this.handleChange(e);
											this.ListMunicipio(e);
										}}
										SelectProps={{
											native: true, MenuProps: {
												className: '',
											},
										}}
										
										helperText="¿En que entidad se realizó el trabajo?"
										name="id_cat_estado_entidad_trabajo"
										label="Estado"
										value={this.props.state.id_cat_estado_entidad_trabajo}
										disabled={disabledFieldPromotorRequerimientoCliente}
									>
										<option value={''}>&nbsp;</option>
										{this.props.state.lista_cat_estado.map((item, key) => (
											<option key={key} value={item.id_cat_estado}>
												{item.estado}
											</option>))}
									</TextField>
								</Grid>
								
								<Grid item className={'text-left'} xs={12} sm={4} md={3} lg={3} xl={3}>
									<TextField
										select
										fullWidth
										margin="none"
										onChange={this.handleChange}
										SelectProps={{
											native: true, MenuProps: {
												className: '',
											},
										}}
										
										helperText="¿En que municipio se realizó el trabajo?"
										name="id_cat_municipio_entidad_trabajo"
										label="Municipio"
										value={this.props.state.id_cat_municipio_entidad_trabajo}
										disabled={disabledFieldPromotorRequerimientoCliente}
									>
										<option value={''}>&nbsp;</option>
										{this.props.state.lista_cat_municipio.map((item, key) => (
											<option key={key} value={item.id_cat_municipio}>
												{item.municipio}
											</option>))}
									</TextField>
								</Grid>
								
								<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={2}>
									<div className={'row-flex'}>
										<div className={'w-100-80-px padding-10-R'}>
											<TextField
												fullWidth
												onChange={this.handleChange}
												type="text"
												margin="none"
												onKeyPress={FloatSolo}
												
												helperText="Escribir en %, Dejar '0' si no aplica"
												name="impuesto_sobre_nomina"
												label="Impuesto sobre nómina"
												value={this.props.state.impuesto_sobre_nomina}
												disabled={disabledFieldPromotorRequerimientoCliente}
												InputProps={{
													endAdornment: <InputAdornment
														position="end">%</InputAdornment>,
												}}
											/>
										</div>
										
										{!disabledFieldPromotorRequerimientoCliente ? (
											<div className={'w-80-px v-center'}>
												<Button size={'small'} variant="contained"
												        onClick={this.agregar_estado_municipio_impuesto}
												        className={'text-white text-transform-none ' + (disabledFieldPromotorRequerimientoCliente ? 'btn-gray' : 'btn-default-primary')}
												        disabled={disabledFieldPromotorRequerimientoCliente}>
													Agregar
												</Button>
											</div>
										): null}
									</div>
								</Grid>
								
								{this.props.state.ficha_tecnica_estado_municipio_impuesto.length > 0 ? (
									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										
										<div className={'w-100 text-left'}>
											
											{this.props.state.ficha_tecnica_estado_municipio_impuesto.map((item, key) => (<div
												className={'vertical-inline b-r-20 margin-5 ' + (disabledFieldPromotorRequerimientoCliente ? 'bg-gray' : 'bg-danger')}
												key={key}>
												<div
													className={'padding-3 padding-10-L padding-10-R v-center text-white'}>
													{item.estado}, {item.municipio} ({item.impuesto_sobre_nomina}%)
												</div>
												<div className={'padding-3 padding-10-R v-center text-white'}>
													{!disabledFieldPromotorRequerimientoCliente ? (
														<DeleteOutlined className={'px-18'} onClick={() => this.eliminar_estado_municipio_impuesto(key)}/>) : ''}
												</div>
											</div>))}
										
										</div>
									
									</Grid>) : ''}
									
								<Grid item className={'text-left'} xs={12} sm={12} md={2} lg={2} xl={1}>
									
									<div className={'row-flex'}>
										
										<div className={'w-140-px text-left'}>
											<FormControlLabel
												control={<Checkbox type="checkbox" name='otros_impuestos'
												                   checked={this.props.state.otros_impuestos}
												                   onChange={this.handleChange}
												                   value="otros_impuestos"
												                   disabled={disabledFieldPromotorRequerimientoCliente}/>}
												label="Otros impuestos"
											/>
										</div>
									
									</div>
								</Grid>
								
								{this.props.state.otros_impuestos ? ( <Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
									<div className={'row-flex'}>
									
											<div className={'w-100-320-px padding-0-L padding-10-R'}>
												<TextField
													fullWidth
													onChange={this.handleChange}
													type="text"
													margin="none"
													helperText="Describa el impuesto"
													name="otros_impuestos_nombre"
													label="Impuestos"
													value={this.props.state.otros_impuestos_nombre}
													disabled={disabledFieldPromotorRequerimientoCliente}
												/>
											</div>
										
											<div className={'w-100-px padding-10-L padding-20-R'}>
												<TextField
													fullWidth
													onChange={this.handleChange}
													type="text"
													margin="none"
													onKeyPress={FloatSolo}
													helperText="Escribir en %"
													name="otros_impuestos_porcentaje"
													label="Porcentaje"
													value={this.props.state.otros_impuestos_porcentaje}
													disabled={disabledFieldPromotorRequerimientoCliente}
													InputProps={{
														endAdornment: <InputAdornment
															position="end">%</InputAdornment>,
													}}
												/>
											</div>
										
										<div className={'w-80-px v-center'}>
											<Button size={'small'} variant="contained"
											        onClick={this.agregar_otro_impuesto}
											        className={'text-white text-transform-none ' + (disabledFieldPromotorRequerimientoCliente ? 'btn-gray' : 'btn-default-primary')}
											        disabled={disabledFieldPromotorRequerimientoCliente}>
												Agregar
											</Button>
										</div>
									</div>
								
								</Grid> ): null }
								
								{(this.props.state.otros_impuestos && this.props.state.ficha_tecnica_otro_impuesto.length > 0) ? (
									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										
										<div className={'w-100 text-left'}>
											
											{this.props.state.ficha_tecnica_otro_impuesto.map((item, key) => (<div
												className={'vertical-inline b-r-20 margin-5 ' + (disabledFieldPromotorRequerimientoCliente ? 'bg-gray' : 'bg-danger')}
												key={key}>
												<div
													className={'padding-3 padding-10-L padding-10-R v-center text-white'}>
													{item.otro_impuesto} ({item.porcentaje}%)
												</div>
												<div className={'padding-3 padding-10-R v-center text-white'}>
													{!disabledFieldPromotorRequerimientoCliente ? (
														<DeleteOutlined className={'px-18'} onClick={() => this.eliminar_otro_impuesto(key)}/>) : ''}
												</div>
											</div>))}
										
										</div>
									
									</Grid>) : ''}
								
								
								<Grid item className={'text-left'} xs={12} sm={4} md={3} lg={3} xl={2}>
									<FormControlLabel
										control={<Checkbox type="checkbox" name='cooperativa_alta_imss'
										                   checked={this.props.state.cooperativa_alta_imss}
										                   onChange={this.handleChange}
										                   value="cooperativa_alta_imss"
										                   disabled={disabledFieldPromotorRequerimientoCliente}/>}
										label="Si es cooperativa, ¿Están dados de alta en el IMSS?"
									/>
								</Grid>
								
								<Grid item className={'text-left'} xs={12} sm={4} md={3} lg={3} xl={2}>
									<TextField
										fullWidth
										onChange={this.handleChange}
										type="text"
										margin="none"
										onKeyPress={FloatSolo}
										
										helperText="En caso de estar en cooperativa porcentaje de comisión"
										name="cooperativa_porcentaje_comision_paga_trabajador"
										label="¿Qué porcentaje paga el trabajador?"
										value={this.props.state.cooperativa_porcentaje_comision_paga_trabajador}
										disabled={!this.props.state.cooperativa_alta_imss || disabledFieldPromotorRequerimientoCliente}
										InputProps={{
											endAdornment: <InputAdornment position="end">%</InputAdornment>,
										}}
									/>
								</Grid>
								
								<Grid item className={'text-left'} xs={12} sm={4} md={3} lg={3} xl={2}>
									<FormControlLabel
										control={<Checkbox type="checkbox"
										                   name='trabajador_entrega_recibo_honorarios'
										                   checked={this.props.state.trabajador_entrega_recibo_honorarios}
										                   onChange={this.handleChange}
										                   value="trabajador_entrega_recibo_honorarios"
										                   disabled={disabledFieldPromotorRequerimientoCliente}/>}
										label="¿El trabajador entrega recibo de honorarios?"
									/>
								</Grid>
								
								<Grid item className={'text-left'} xs={12} sm={4} md={3} lg={3} xl={2}>
									<FormControlLabel
										control={<Checkbox type="checkbox"
										                   name='pago_realiza_fuera_es_no_deducible'
										                   checked={this.props.state.pago_realiza_fuera_es_no_deducible}
										                   onChange={this.handleChange}
										                   value="pago_realiza_fuera_es_no_deducible"
										                   disabled={disabledFieldPromotorRequerimientoCliente}/>}
										label="Si el pago se realiza por fuera, ¿Es no deducible?"
									/>
								</Grid>
								
								<Grid item className={'text-left'} xs={12} sm={4} md={3} lg={3} xl={2}>
									<TextField
										fullWidth
										onChange={this.handleChange}
										type="text"
										margin="none"
										onKeyPress={FloatSolo}
										
										helperText="Si es outsourcing"
										name="outsourcing_porcentaje_paga_servicio"
										label="¿Que porcentaje paga por el servicio?"
										value={this.props.state.outsourcing_porcentaje_paga_servicio}
										disabled={disabledFieldPromotorRequerimientoCliente}
										InputProps={{
											endAdornment: <InputAdornment position="end">%</InputAdornment>,
										}}
									/>
								</Grid>
								
								<Grid item className={'text-left'} xs={12} sm={4} md={3} lg={3} xl={2}>
									<FormControlLabel
										control={<Checkbox type="checkbox" name='asimilados_hace_retencion_isr'
										                   checked={this.props.state.asimilados_hace_retencion_isr}
										                   onChange={this.handleChange}
										                   value="asimilados_hace_retencion_isr"
										                   disabled={disabledFieldPromotorRequerimientoCliente}/>}
										label="En caso de Asimilados, ¿Se le hace retención de ISR?"
									/>
								</Grid>
								
								<Grid item className={'text-left'} xs={12} sm={4} md={3} lg={3} xl={2}>
									<FormControlLabel
										control={<Checkbox type="checkbox"
										                   name='tienen_otras_prestaciones_aparte_salario'
										                   checked={this.props.state.tienen_otras_prestaciones_aparte_salario}
										                   onChange={this.handleChange}
										                   value="tienen_otras_prestaciones_aparte_salario"
										                   disabled={disabledFieldPromotorRequerimientoCliente}/>}
										label="¿Se tienen otras prestaciones aparte del salario?"
									/>
								</Grid>
								
								{this.props.state.tienen_otras_prestaciones_aparte_salario ? (
									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography variant={'h6'} className={'text-left'}>
											En caso de tener otras prestaciones aparte del salario contestar los
											siguientes
											incisos...
										</Typography>
									</Grid>) : ''}
								
								{this.props.state.tienen_otras_prestaciones_aparte_salario ? (
									<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={4} xl={4}>
										
										<Grid container spacing={2}>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12}
											      xl={12}>
												<TextField
													fullWidth
													onChange={this.handleChange}
													type="text"
													margin="none"
													onKeyPress={EnteroSolo}
													
													helperText="Días de vacaciones *por ley o *especiales"
													name="vacaciones_ley_o_especiales_cuantos_dias"
													label="¿Cuantos días?"
													value={this.props.state.vacaciones_ley_o_especiales_cuantos_dias}
													disabled={disabledFieldPromotorRequerimientoCliente}
												/>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12}
											      xl={12}>
												<TextField
													fullWidth
													onChange={this.handleChange}
													type="text"
													margin="none"
													onKeyPress={EnteroSolo}
													
													helperText="Prima vacacional *por ley o *especiales"
													name="prima_vacacional_ley_o_especiales_cuantos_dias"
													label="¿Cuantos días?"
													value={this.props.state.prima_vacacional_ley_o_especiales_cuantos_dias}
													disabled={disabledFieldPromotorRequerimientoCliente}
												/>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12}
											      xl={12}>
												<TextField
													fullWidth
													onChange={this.handleChange}
													type="text"
													margin="none"
													onKeyPress={EnteroSolo}
													
													helperText="Días de gratificación anual *por ley o *especiales"
													name="gratificacion_anual_ley_o_especiales_cuantos_dias"
													label="¿Cuantos días?"
													value={this.props.state.gratificacion_anual_ley_o_especiales_cuantos_dias}
													disabled={disabledFieldPromotorRequerimientoCliente}
												/>
											</Grid>
										
										</Grid>
									
									</Grid>) : ''}
								{this.props.state.tienen_otras_prestaciones_aparte_salario ? (
									<Grid item className={'text-left'} xs={12} sm={12} md={9} lg={8} xl={8}>
										
										<div className={'w-100'}>
											<table className={'w-100'}>
												<thead>
												<tr>
													<th className={'padding-2 padding-30-R'} align={'right'}>
														Tipo
													</th>
													<th className={'padding-2 w-65-px'} align={'center'}>
														%
													</th>
													<th className={'padding-2'} align={'center'}>
														Pago en efectivo
													</th>
													<th className={'padding-2'} align={'center'}>
														Vales
													</th>
													<th className={'padding-2'} align={'center'}>
														Prestaciones
													</th>
													<th className={'padding-2'} align={'center'}>
														Frecuencia
													</th>
													<th className={'padding-2'} align={'center'}>
														&nbsp;
													</th>
													<th className={'padding-2'} align={'center'}>
														Integra al SBC
													</th>
												</tr>
												</thead>
												<tbody>
												{this.props.state.ficha_tecnica_prestacion && this.props.state.ficha_tecnica_prestacion.map((item, key) => (
													<tr key={key}>
														<td className={'padding-2 padding-30-R'}
														    align={'right'}>
															{item.prestacion}
														</td>
														<td className={'padding-2'} align={'center'}>
															<TextField type="text" margin="none"
															           onKeyPress={FloatSolo}
															           name={"porcentaje__" + key}
															           onChange={(e) => this.handleChange(e, 'ficha_tecnica_prestacion', 'porcentaje')}
															           value={item.porcentaje}
															           disabled={disabledFieldPromotorRequerimientoCliente}
															           InputProps={{
																           endAdornment: <InputAdornment
																	           position="end">%</InputAdornment>,
															           }}
															/>
														</td>
														<td className={'padding-2'} align={'center'}>
															<Checkbox type="checkbox"
															          onChange={(e) => this.handleChange(e, 'ficha_tecnica_prestacion', 'pago_efectivo')}
															          name={"pago_efectivo__" + key}
															          checked={item.pago_efectivo}
															          disabled={disabledFieldPromotorRequerimientoCliente}
															/>
														</td>
														<td className={'padding-2'} align={'center'}>
															<Checkbox type="checkbox"
															          onChange={(e) => this.handleChange(e, 'ficha_tecnica_prestacion', 'vales')}
															          name={"vales__" + key}
															          checked={item.vales}
															          disabled={disabledFieldPromotorRequerimientoCliente}
															/>
														</td>
														<td className={'padding-2'} align={'center'}>
															<Checkbox type="checkbox"
															          onChange={(e) => this.handleChange(e, 'ficha_tecnica_prestacion', 'prestaciones')}
															          name={"prestaciones__" + key}
															          checked={item.prestaciones}
															          disabled={disabledFieldPromotorRequerimientoCliente}
															/>
														</td>
														<td className={'padding-2'} align={'center'}>
															<Checkbox type="checkbox"
															          onChange={(e) => this.handleChange(e, 'ficha_tecnica_prestacion', 'frecuencia')}
															          name={"frecuencia__" + key}
															          checked={item.frecuencia}
															          disabled={disabledFieldPromotorRequerimientoCliente}
															/>
														</td>
														<td className={'padding-2'} align={'center'}>
															<TextField select margin="none"
															           onChange={(e) => this.handleChange(e, 'ficha_tecnica_prestacion', 'fijo_variable')}
															           name={"fijo_variable__" + key}
															           SelectProps={{
																           native: true,
															           }}
															           value={item.fijo_variable}
															           disabled={disabledFieldPromotorRequerimientoCliente}
															>
																<option value={''}>&nbsp;</option>
																<option value={'Fijo'}>Fijo</option>
																<option value={'Variable'}>Variable</option>
															</TextField>
														</td>
														<td className={'padding-2'} align={'center'}>
															<Checkbox type="checkbox"
															          onChange={(e) => this.handleChange(e, 'ficha_tecnica_prestacion', 'integra_sbc')}
															          name={"integra_sbc__" + key}
															          checked={item.integra_sbc}
															          disabled={disabledFieldPromotorRequerimientoCliente}
															/>
														</td>
													</tr>))}
												</tbody>
											</table>
										</div>
									
									</Grid>
								) : ''}
								
								<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
									<TextField
										multiline
										fullWidth
										onChange={this.handleChange}
										type="text"
										margin="none"
										
										helperText="Comentarios adicionales para la elaboración de la propuesta"
										name="comentario_adicional_elaboracion_propuesta"
										label="Comentarios adicionales:"
										value={this.props.state.comentario_adicional_elaboracion_propuesta}
										disabled={disabledFieldPromotorRequerimientoCliente}
									/>
								</Grid>
								{/*<Grid item className={'text-center padding-10'} xs={12} sm={12} md={12} lg={12} xl={12}>
									<div id="results" style={{marginTop: '30px'}}></div>
								</Grid>*/}
								{!disabledFieldPromotorRequerimientoCliente ? (
									<Grid item className={'text-center padding-10'} xs={12} sm={12} md={12}
									      lg={12}
									      xl={12}>
										
										<Button variant="contained" color="primary"
										        onClick={this.guardarPromotor}
										        className={'btn-default-primary text-transform-none'}>
											{this.props.state.id_ficha_tecnica < 0 ? 'Actualizar' : 'Guardar'}
										</Button>
									
									</Grid>
								) : ''}
							
							</Grid>
						
						</div>
					
					</Grid>
				
				</Grid>
			</Fragment>
		)
	}
}


SeccionPromotorRequerimientos.propTypes = {
	disabledFieldPromotorRequerimientoCliente: PropTypes.bool.isRequired,
	state: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	$BroadcastModalSeleccionarArchivoElaboracionPropuesta: PropTypes.func.isRequired,
	sirocActive: PropTypes.func.isRequired,
	agregar_otro_impuesto: PropTypes.func.isRequired,
	ListMunicipio: PropTypes.func.isRequired,
	guardarPromotor: PropTypes.func.isRequired,
	eliminar_estado_municipio_impuesto: PropTypes.func.isRequired,
	eliminar_otro_impuesto: PropTypes.func.isRequired,
	removefilepromotor: PropTypes.func.isRequired
};


export default SeccionPromotorRequerimientos;
