import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

class ClienteReceptoraPagoDatoBancarioService {
	static Listar = (id_empresa) => {
		let params = {
			id_empresa: id_empresa
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Cliente_Receptora_Pago_Dato_Bancario_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Agregar = (form) => {
		let params = {
			id_empresa_dato_bancario: null,
			id_empresa: form.id_empresa,
			id_cat_banco: form.id_cat_banco || '',
			id_cat_banco_sucursal: form.id_cat_banco_sucursal || '',
			id_cat_banco_tipo_cuenta: form.id_cat_banco_tipo_cuenta || '',
			numero_cliente: form.numero_cliente || '',
			numero_cuenta: form.numero_cuenta || '',
			clabe_interbancaria: form.clabe_interbancaria || '',
			observaciones: form.observaciones || '',
			apoderado: form.apoderado || '',
			autorizados: form.autorizados || '',
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Cliente_Receptora_Pago_Dato_Bancario_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Modificar = (form) => {
		let params = {
			id_empresa_dato_bancario: form.id_empresa_dato_bancario,
			id_empresa: form.id_empresa,
			id_cat_banco: form.id_cat_banco || '',
			id_cat_banco_sucursal: form.id_cat_banco_sucursal || '',
			id_cat_banco_tipo_cuenta: form.id_cat_banco_tipo_cuenta || '',
			numero_cliente: form.numero_cliente || '',
			numero_cuenta: form.numero_cuenta || '',
			clabe_interbancaria: form.clabe_interbancaria || '',
			observaciones: form.observaciones || '',
			apoderado: form.apoderado || '',
			autorizados: form.autorizados || '',
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Cliente_Receptora_Pago_Dato_Bancario_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Eliminar = (id_empresa_dato_bancario, id_empresa) => {
		let params = {
			id_empresa_dato_bancario: id_empresa_dato_bancario,
			id_empresa: id_empresa
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Cliente_Receptora_Pago_Dato_Bancario_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
}

export default ClienteReceptoraPagoDatoBancarioService;
