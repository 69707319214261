import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DivSvg from "../../../Include/MiniComponents/DivSvg";
import alojamiento_bube from "../../../../assets/img/icons/alojamiento_nube.svg";

class ModalDescargaComprobantesNomina extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			tipo: props.tipo,
			project: props.tipo === 'acresco' ? 1 : 0,
			select: '',
		};
	}
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	render() {
		
		
		return (
			<div>
				
				<Dialog open={this.props.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'margin-15-B text-center'} style={{color: 'rgba(22, 91, 165, 0.8)'}}>
									¡Descarga realizada con éxito!
								</Typography>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
								<Fragment>
									<div className={'w-auto'} align={'center'}>
										<DivSvg img={alojamiento_bube} height={'150px'} width={'150px'} opacity={true}/>
									</div>
								</Fragment>
							</Grid>
							
							<Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'margin-10-B text-center'} style={{color: 'gray'}}>
											La descarga de Comprobantes de Nómina se encuentran en proceso y podrás obtener el resultado en el módulo Nube, en el directorio:
										</Typography>
										<Typography className={'margin-10-B text-center'} style={{color: 'black', fontWeight: 'bold'}}>
											{this.props.item.directorio || "4K32MBFRUDJT9ZT9_1_25-01-2021 22:30:34"}
										</Typography>
										<Typography className={'margin-10-B text-center'} style={{color: 'gray'}}>
											Dentro de la opción Comprobantes de Nómina.
										</Typography>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										
										<Typography className={'margin-15-B text-center'} style={{color: 'gray'}}>
											<o style={{color: 'rgba(165,17,19,0.8)'}}> Importante: </o> El tiempo de procesamiento para la descarga depende de la totalidad de archivos solicitados.
										</Typography>
									
									</Grid>
								</Grid>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.props.cancelModalDescarga} color="primary">
								{'Cerrar'}
							</Button>
						</Fragment>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalDescargaComprobantesNomina.propTypes = {
	item: PropTypes.object.isRequired,
	cancelModalDescarga: PropTypes.func.isRequired,
	fileText: PropTypes.string
};

export default ModalDescargaComprobantesNomina;
