import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {DateRangeOutlined, HdrStrong, PersonOutlined, SearchOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {PrioridadService} from "../../../../services/_Cat/Prioridad/PrioridadService";
import {CatService as TipoUsuarioServiceService, CatService} from "../../../../services/_Cat/CatService/CatService";
import {TipoUsuarioService} from "../../../../services/_Cat/TipoUsuarioService/TipoUsuarioService";
import {RecordatorioService} from "../../../../services/_Cat/RecordatorioService/RecordatorioService";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

class FiltrosRecordatorios extends Component {
	
	state = {};
	
	constructor() {
		super();
		this.state = {
			folio: '',
			id_recordatorio: '',
			id_usuario: '',
			id_empresa: '',
			id_cat_recordatorio: '',
			fecha_notificacion: '',
			id_cat_frecuencia_notificacion: '',
			
			lista_recordatorios:[],
			lista_frecuencia_notificacion:[],
			activo: '',
			
			fecha_inicio: null,
			fecha_fin: null
		};
		
		this.ListaRecordatorio();
		this.ListaRecordatorioFrecuenciaNotificacion();
	}
	
	ListaRecordatorio = () => {
		RecordatorioService.Listar().then((response) => {
			this.setState({
				lista_recordatorios: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_recordatorios: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaRecordatorioFrecuenciaNotificacion = () => {
		CatService.ListRecordatorioFrecuenciaNotificacion().then((response) => {
			this.setState({
				lista_frecuencia_notificacion: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_frecuencia_notificacion: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.id_recordatorio = this.state.id_recordatorio || undefined;
		filtro.id_usuario = this.state.id_usuario || undefined;
		filtro.folio = this.state.folio || undefined;
		filtro.id_empresa = this.state.id_empresa || undefined;
		filtro.id_cat_recordatorio = this.state.id_cat_recordatorio || undefined;
		filtro.fecha_notificacion = this.state.fecha_notificacion || undefined;
		filtro.id_cat_frecuencia_notificacion = this.state.id_cat_frecuencia_notificacion || undefined;
		filtro.fecha_inicio = this.state.fecha_inicio || undefined;
		filtro.fecha_fin = this.state.fecha_fin || undefined;
		filtro.activo = this.state.activo || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					{/*<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="folio"
									onChange={this.handleChange}
									label="Folio"
									autoComplete={'off'}
									value={this.state.folio}
								/>
							</Grid>
						</Grid>
					</Grid>*/}
					<Grid item xs={6} sm={6} md={6} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_recordatorio"
									label="Recordatorio"
									value={this.state.id_cat_recordatorio}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_recordatorios.map(option => (
										<option key={option.id_cat_recordatorio}
										        value={option.id_cat_recordatorio}>
											{option.recordatorio}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					{/*<Grid item xs={6} sm={6} md={6} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<MDDatePicker
									type={'range'}
									component={(
										<TextField
											fullWidth
											label="Fecha"
											value={(this.state.fecha_inicio && this.state.fecha_fin) ? (DateFormat.FormatText(this.state.fecha_inicio) + " al " + DateFormat.FormatText(this.state.fecha_fin)) : ''}
											readOnly
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton aria-label="Fecha">
															<DateRangeOutlined/>
														</IconButton>
													</InputAdornment>
												)
											}}
										/>
									)}
									startDate={this.state.fecha_inicio}
									endDate={this.state.fecha_fin}
									onChange={(range) => {
										this.setState({
											fecha_inicio: range.startDate,
											fecha_fin: range.endDate,
										});
									}}
								/>
							</Grid>
						</Grid>
					</Grid>*/}
					
					<Grid item xs={6} sm={6} md={6} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_frecuencia_notificacion"
									label="Frecuencia Notificación"
									value={this.state.id_cat_frecuencia_notificacion}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_frecuencia_notificacion.map(option => (
										<option key={option.id_cat_recordatorio_frecuencia_notificacion}
										        value={option.id_cat_recordatorio_frecuencia_notificacion}>
											{option.recordatorio_frecuencia_notificacion}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel htmlFor="age-native-activo">Activo</InputLabel>
									<Select
										native
										value={this.state.activo}
										onChange={this.handleChange}
										inputProps={{
											name: 'activo',
											id: 'age-native-activo',
										}}
									>
										<option value={'all'}>Todos</option>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={2} xl={2} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosRecordatorios.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosRecordatorios;
