import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const CronogramaService = {
	Listar: (filtro, paginacion) => {
		let fecha_inicio = (filtro.fecha_inicio || null) ? DateFormat.FormatSql(filtro.fecha_inicio) : null;
		let fecha_fin = (filtro.fecha_fin || null) ? DateFormat.FormatSql(filtro.fecha_fin) : null;
		
		let params = {
			filtro: {
				id_cronograma: filtro.id_cronograma || null,
				id_cat_proyecto: filtro.id_cat_proyecto || null,
				id_cat_proyecto_modulo: filtro.id_cat_proyecto_modulo || null,
				id_cat_cronograma_estatus: filtro.id_cat_cronograma_estatus || null,
				id_cat_tipo_usuario: filtro.id_cat_tipo_usuario || null,
				fecha_inicio: fecha_inicio || null,
				fecha_fin: fecha_fin || null,
				usuario_asignado: filtro.usuario_asignado || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cronograma_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha = form.fecha ? DateFormat.FormatSql(form.fecha) : '';
		
		let params = {
			id_cronograma: null,
			id_cat_proyecto: form.id_cat_proyecto,
			id_cat_proyecto_modulo: form.id_cat_proyecto_modulo,
			fecha: fecha,
			horas: form.horas,
			id_usuario: form.id_usuario,
			actividad: form.actividad,
			archivos: form.archivos,
			usuarios: form.usuarios,
			usuarios_notificar: form.usuarios_notificar,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cronograma_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha = form.fecha ? DateFormat.FormatSql(form.fecha) : '';
		
		let params = {
			id_cronograma: form.id_cronograma,
			id_cat_proyecto: form.id_cat_proyecto,
			id_cat_proyecto_modulo: form.id_cat_proyecto_modulo,
			fecha: fecha,
			horas: form.horas,
			id_usuario: form.id_usuario,
			actividad: form.actividad,
			archivos: form.archivos,
			usuarios: form.usuarios,
			usuarios_notificar: form.usuarios_notificar,
			activo: form.activo ? 1 : 0,
		};
		
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cronograma_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cronograma) => {
		let params = {
			id_cronograma: id_cronograma
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cronograma_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Estatus: (form) => {
		let params = {
			id_cronograma: form.id_cronograma,
			id_cat_cronograma_estatus: form.id_cat_cronograma_estatus
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cronograma_Estatus', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SubirArchivoVerificacion: (form) => {
		let params = {
			comprobante_archivo: form.comprobante_archivo,
			comprobante_formato: form.comprobante_formato,
			usuarios_notificar:  form.usuarios_notificar
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cronograma_Masivo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
