import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import {FichaTecnicaService} from "../../../../services/FichaTecnicaService/FichaTecnicaService";
import {ClientePotencialService} from '../../../../services/ClientePotencialService/ClientePotencialService';
import {FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import moment from "moment";
import {Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import {CONFIG} from "../../../../settings/Config/Config";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import Fab from "@material-ui/core/Fab";
import {SyncOutlined} from "@material-ui/icons";

class ClientesPagadorasConfirmar extends Component {
	
	state = {};
	
	Usr = {};
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			id_cliente: props.id_cliente,
			cliente_que_paga: [],
		};
	}
	
	handleChange = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			FichaTecnicaService.InitDato(this.state.id_cliente).then((response) => {
				
				let dato = FieldsJs.Copy(response.data);
				
				if (response.visible === 0) {
					
					this.setState({
						visible: 0,
						mensaje: response.message
					});
					
				} else {
					
					let cliente = dato.cliente || {};
					let ficha_tecnica_prestacion = dato.ficha_tecnica_prestacion;
					let ft = dato.ficha_tecnica || {};
					let ficha_tecnica_archivo_elaboracion_propuesta = dato.ficha_tecnica_archivo_elaboracion_propuesta;
					let ficha_tecnica_archivo_integracion_expediente = dato.ficha_tecnica_archivo_integracion_expediente;
					let ficha_tecnica_archivo_propuesta_fiscal = dato.ficha_tecnica_archivo_propuesta_fiscal;
					let ficha_tecnica_otro_impuesto = dato.ficha_tecnica_otro_impuesto;
					let ficha_tecnica_historial_accion_usuario = dato.ficha_tecnica_historial_accion_usuario || [];
					let lista_ejecutivo_asignado_tesoreria = dato.ejecutivo_asignado_tesoreria || [];
					let ficha_tecnica_cantidad_empleado_banco = dato.ficha_tecnica_cantidad_empleado_banco || [];
					let cliente_contratos_individuales = dato.cliente_contratos_individuales || [];
					let cliente_agendar_firma_contrato = dato.cliente_agendar_firma_contrato || [];
					let cat_tipo_pago = dato.cat_tipo_pago || [];
					
					let id_plaza_pertenece_cliente = '';
					if (FieldsJs.Array(dato.plaza_pertenece_cliente)) {
						id_plaza_pertenece_cliente = dato.plaza_pertenece_cliente.id_plaza;
					}
					
					let id_plaza_opera_nomina = '';
					if (FieldsJs.Array(dato.plaza_opera_nomina)) {
						id_plaza_opera_nomina = dato.plaza_opera_nomina.id_plaza;
					}
					
					for (let i = 0; i < ficha_tecnica_prestacion.length; i++) {
						var it = ficha_tecnica_prestacion[i];
						it.porcentaje = it.porcentaje || '';
						it.pago_efectivo = (it.pago_efectivo === 1);
						it.vales = (it.vales === 1);
						it.prestaciones = (it.prestaciones === 1);
						it.frecuencia = (it.frecuencia === 1);
						it.fijo_variable = it.fijo_variable || '';
						it.integra_sbc = (it.integra_sbc === 1);
					}
					
					let cliente_que_paga = dato.cliente_que_paga || [];
					
					for (let i = 0; i < cliente_que_paga.length; i++) {
						let item = cliente_que_paga[i];
						if (item.id_cat_cliente_que_paga_status === 2) {
							item.autorizado = 1;
						} else if (item.id_cat_cliente_que_paga_status === 3) {
							item.autorizado = 0;
						} else {
							item.autorizado = null;
						}
					}
					
					this.setState({
						
						cliente: dato.cliente,
						
						id_ficha_tecnica: ft.id_ficha_tecnica ? Number(ft.id_ficha_tecnica) : '',
						id_cat_ficha_tecnica_status: ft.id_cat_ficha_tecnica_status ? Number(ft.id_cat_ficha_tecnica_status) : '',
						
						actividad_ante_sat: ft.actividad_ante_sat || '',
						rfc: ft.rfc || dato.cliente.rfc || '',
						servicio_requerido_propuesto: ft.servicio_requerido_propuesto || '',
						ficha_tecnica_archivo_elaboracion_propuesta: ficha_tecnica_archivo_elaboracion_propuesta || [],
						ficha_tecnica_archivo_integracion_expediente: ficha_tecnica_archivo_integracion_expediente || [],
						ficha_tecnica_archivo_propuesta_fiscal: ficha_tecnica_archivo_propuesta_fiscal || [],
						
						id_plaza_pertenece_cliente: id_plaza_pertenece_cliente || '',
						id_plaza_opera_nomina: id_plaza_opera_nomina || '',
						
						sueldo_salario: (ft.sueldo_salario === 1),
						cooperativa: (ft.cooperativa === 1),
						honorarios: (ft.honorarios === 1),
						sociedad_civil: (ft.sociedad_civil === 1),
						asimilados_salario: (ft.asimilados_salario === 1),
						outsourcing: (ft.outsourcing === 1),
						otros: ft.otros || '',
						
						id_cat_periodo_pago: ft.id_cat_periodo_pago || '',
						impuesto_sobre_nomina: ft.impuesto_sobre_nomina || 0,
						
						otros_impuestos: (ficha_tecnica_otro_impuesto.length > 0),
						otros_impuestos_nombre: '',
						otros_impuestos_porcentaje: '',
						
						ficha_tecnica_otro_impuesto: ficha_tecnica_otro_impuesto || [],
						
						id_cat_factor_riesgo_imss: ft.id_cat_factor_riesgo_imss || '',
						id_cat_estado_entidad_trabajo: ft.id_cat_estado_entidad_trabajo || '',
						id_cat_municipio_entidad_trabajo: ft.id_cat_municipio_entidad_trabajo || '',
						
						cooperativa_alta_imss: (ft.cooperativa_alta_imss === 1),
						cooperativa_porcentaje_comision_paga_trabajador: ft.cooperativa_porcentaje_comision_paga_trabajador || '',
						
						trabajador_entrega_recibo_honorarios: (ft.trabajador_entrega_recibo_honorarios === 1),
						pago_realiza_fuera_es_no_deducible: (ft.pago_realiza_fuera_es_no_deducible === 1),
						outsourcing_porcentaje_paga_servicio: ft.outsourcing_porcentaje_paga_servicio || '',
						asimilados_hace_retencion_isr: (ft.asimilados_hace_retencion_isr === 1),
						tienen_otras_prestaciones_aparte_salario: (ft.tienen_otras_prestaciones_aparte_salario === 1),
						
						vacaciones_ley_o_especiales_cuantos_dias: ft.vacaciones_ley_o_especiales_cuantos_dias || '',
						prima_vacacional_ley_o_especiales_cuantos_dias: ft.prima_vacacional_ley_o_especiales_cuantos_dias || '',
						gratificacion_anual_ley_o_especiales_cuantos_dias: ft.gratificacion_anual_ley_o_especiales_cuantos_dias || '',
						
						ficha_tecnica_prestacion: ficha_tecnica_prestacion || [],
						
						comentario_adicional_elaboracion_propuesta: ft.comentario_adicional_elaboracion_propuesta || '',
						
						lista_cat_factor_riesgo_imss: dato.cat_factor_riesgo_imss,
						lista_cat_periodo_pago: dato.cat_periodo_pago,
						lista_plazas: dato.plazas,
						lista_cat_estado: dato.cat_estado,
						lista_cat_municipio: dato.cat_municipio,
						lista_cat_banco: dato.cat_banco,
						lista_cat_promotores: dato.cat_promotores,
						
						
						fecha_firma_contrato: ft.fecha_firma_contrato ? moment(ft.fecha_firma_contrato) : null,
						
						constancia_de_situacion_fiscal_base64: '',
						constancia_de_situacion_fiscal_formato: (ft.constancia_de_situacion_fiscal || '').split('.')[1],
						constancia_de_situacion_fiscal: ft.constancia_de_situacion_fiscal,
						
						comprobante_de_domicilio_base64: '',
						comprobante_de_domicilio_formato: (ft.comprobante_de_domicilio || '').split('.')[1],
						comprobante_de_domicilio: ft.comprobante_de_domicilio,
						
						acta_constitutiva_de_la_cliente_base64: '',
						acta_constitutiva_de_la_cliente_formato: (ft.acta_constitutiva_de_la_cliente || '').split('.')[1],
						acta_constitutiva_de_la_cliente: ft.acta_constitutiva_de_la_cliente,
						
						poder_del_representante_legal_base64: '',
						poder_del_representante_legal_formato: (ft.poder_del_representante_legal || '').split('.')[1],
						poder_del_representante_legal: ft.poder_del_representante_legal,
						
						identificacion_del_repesentante_legal_base64: '',
						identificacion_del_repesentante_legal_formato: (ft.identificacion_del_repesentante_legal || '').split('.')[1],
						identificacion_del_repesentante_legal: ft.identificacion_del_repesentante_legal,
						
						fecha_alta_imss: ft.fecha_alta_imss ? moment(ft.fecha_alta_imss) : null,
						confirmacion_fecha_primera_dispersion: ft.confirmacion_fecha_primera_dispersion ? moment(ft.confirmacion_fecha_primera_dispersion) : null,
						id_cat_periodo_pago_tipo_nomina: ft.id_cat_periodo_pago_tipo_nomina || '',
						comision_propuesta: ft.comision_propuesta || '',
						ficha_tecnica_cantidad_empleado_banco: ficha_tecnica_cantidad_empleado_banco || [],
						iva: ft.iva || '',
						retencion_isr_en_caso_de_ias: ft.retencion_isr_en_caso_de_ias || '',
						retencion_isn_en_caso_de_ias: ft.retencion_isn_en_caso_de_ias || '',
						
						
						lista_ejecutivo_asignado_tesoreria: lista_ejecutivo_asignado_tesoreria,
						id_ejecutivo_asignado_tesoreria: cliente.id_ejecutivo_asignado_tesoreria || '',
						
						cat_tipo_pago: cat_tipo_pago,
						cliente_que_paga: cliente_que_paga || [],
						ep_cliente_que_paga: '',
						ep_tipo_pago: '',
						ep_id_ejecutivo_asignado: '',
						// ep_id_cat_tipo_pago: '',
						
						cliente_que_factura: dato.cliente_que_factura || [],
						ef_cliente_que_factura: '',
						ef_clave_producto_o_servicio: '',
						ef_concepto: '',
						ef_porcentaje: '',
						// ef_id_ejecutivo_asignado: '',
						
						/* J U R I D I C O */
						total_contratos_individuales: cliente.total_contratos_individuales || '',
						total_contratos_individuales_elaborados: '',
						total_contratos_individuales_pendientes: '',
						cantidad_elaborado: '',
						
						excel_elaborado_base64: '',
						excel_elaborado_base64Tipo: '',
						excel_elaborado_archivo: '',
						excel_elaborado_formato: '',
						
						excel_pendiente_base64: '',
						excel_pendiente_base64Tipo: '',
						excel_pendiente_archivo: '',
						excel_pendiente_formato: '',
						
						comentario_general: '',
						
						cliente_contratos_individuales: cliente_contratos_individuales || [],
						
						comentario_juridico_contratos_individuales: cliente.comentario_juridico_contratos_individuales || '',
						
						cliente_agendar_firma_contrato: cliente_agendar_firma_contrato || [],
						
						
						
						
						siroc_active: ((ft.sueldo_salario === 1) || (ft.outsourcing === 1)),
						siroc: (Number(ft.siroc) === 1),
						
						comentario_observacion_desarrollador: '', // COMENTARIO PARA EL DESAROOLLADOR
						ficha_tecnica_historial_accion_usuario: ficha_tecnica_historial_accion_usuario, // HISTORIAL PARA EL DESAROOLLADOR
						
					});
					
				}
				
				setTimeout(() => this.openModal());
				
				resolve(true);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
				reject(false)
			});
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	guardar = () => {
		let data = {
			id_cliente: this.state.id_cliente,
			id_ficha_tecnica: this.state.id_ficha_tecnica,
			cliente_que_paga: this.state.cliente_que_paga,
		};
		ClientePotencialService.GuardarConfirmacionClientesPagadoras(data).then((response) => {
			
			this.props.RefreshList();
			
			this.close();
			
			this.showSnackBars('success', response.mensaje);
			
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	disabledFieldTesoreriaClientesPagadorasBolean = () => {
		
		let disabledFieldTesoreriaClientesPagadoras = true;
		
		if (FieldsJs.inArray([1, 2, 6], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldTesoreriaClientesPagadoras = false;
		}
		
		return disabledFieldTesoreriaClientesPagadoras;
	};
	
	render() {
		
		const disabledFieldTesoreriaClientesPagadoras = this.disabledFieldTesoreriaClientesPagadorasBolean();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Typography className={'px-20'}>
									<Fab size="small" color="secondary" aria-label="Actualizar" onClick={() => this.RefreshInit()} style={{marginRight: '15px'}}>
										<SyncOutlined/>
									</Fab>
									{this.props.titulo}
								</Typography>
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent>
						
						<article style={{backgroundColor: 'lightgray', padding: '15px'}}>
							
							<Grid container spacing={2} alignContent={"flex-start"} alignItems={"flex-start"}>
								
								{this.state.cliente_que_paga.map((item, key) => (
									<Grid key={key} item xs={12} sm={12} md={6} lg={6} xl={6}>
										<Card>
											<CardContent style={{padding: '10px'}}>
												
												<Grid container spacing={0}>
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Typography
															className={'text-right text-black margin-0 padding-0 px-14'}>
															{DateFormat.FormatText(item.created_at, 'all')}
														</Typography>
													</Grid>
													
													<Grid item xs={12} sm={8} md={8} lg={9} xl={9}>
														<Typography variant={'h5'} className={'text-left px-14'}>
															Empresa: {item.cliente_que_paga}
														</Typography>
														<Typography variant={'h5'} className={'text-left px-14'}>
															Tipo de pago: {item.tipo_pago}
														</Typography>
														<Typography variant={'h5'} className={'text-left px-14'}>
															Ejecutivo asignado: {item.ejecutivo_asignado}
														</Typography>
													</Grid>
													
													<Grid item xs={12} sm={4} md={4} lg={3} xl={3} align={'center'}>
														<Typography variant={'h5'} className={'text-center px-14'}>
															Confirmación
														</Typography>
														<div className={'w-100'} align={'center'}>
															<div className={'vertical-inline'}>
																<div
																	className={'v-center vertical-inline cursor-pointer'}
																	style={{
																		backgroundColor: item.id_cat_cliente_que_paga_status === 2 ? '#327400' : (item.autorizado === 1 ? '#2c5eb5' : 'lightgray'),
																		color: item.autorizado === 1 ? 'white' : 'black',
																		width: "40px",
																		height: "40px",
																		borderRadius: "100%",
																		margin: "10px"
																	}}
																	onClick={() => {
																		if (FieldsJs.inArray([1], item.id_cat_cliente_que_paga_status)) {
																			if (!disabledFieldTesoreriaClientesPagadoras) {
																				if (item.autorizado === 1) {
																					this.handleChange('cliente_que_paga#' + key, 'autorizado', null);
																				} else {
																					this.handleChange('cliente_que_paga#' + key, 'autorizado', 1);
																				}
																			}
																		}
																	}}>
																	<div className={'v-center w-100'}>
																		Si
																	</div>
																</div>
																<div
																	className={'v-center vertical-inline cursor-pointer'}
																	style={{
																		backgroundColor: item.id_cat_cliente_que_paga_status === 3 ? '#901C30' : (item.autorizado === 0 ? '#2c5eb5' : 'lightgray'),
																		color: item.autorizado === 0 ? 'white' : 'black',
																		width: "40px",
																		height: "40px",
																		borderRadius: "100%",
																		margin: "10px",
																	}}
																	onClick={() => {
																		if (FieldsJs.inArray([1], item.id_cat_cliente_que_paga_status)) {
																			if (!disabledFieldTesoreriaClientesPagadoras) {
																				if (item.autorizado === 0) {
																					this.handleChange('cliente_que_paga#' + key, 'autorizado', null);
																				} else {
																					this.handleChange('cliente_que_paga#' + key, 'autorizado', 0);
																				}
																			}
																		}
																	}}>
																	<div className={'v-center w-100'}>
																		No
																	</div>
																</div>
															</div>
														
														</div>
													</Grid>
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<List>
															{(item.cliente_que_paga_comentario || []).map((value, index) => (
																<Fragment key={index}>
																	<ListItem alignItems="flex-start"
																	          style={{padding: 0}}>
																		<ListItemAvatar>
																			<Avatar
																				alt={value.nombre_completo_usuario}
																				src={CONFIG.src + value.foto}
																				style={{height: '30px', width: '30px'}}
																			/>
																		</ListItemAvatar>
																		<ListItemText
																			style={{
																				fontSize: '12px',
																				margin: 0,
																				padding: 0
																			}}
																			primary={value.nombre_completo_usuario}
																			secondary={
																				<Fragment>
																					<Typography
																						component="span"
																						variant="body2"
																						color="textPrimary"
																						style={{fontSize: '10px'}}
																					>
																						Motivo:
																					</Typography>
																					{" — " + value.cliente_que_paga_comentario}
																				</Fragment>
																			}
																		/>
																	</ListItem>
																	<Divider variant="inset" component="li"/>
																</Fragment>
															))}
														</List>
													</Grid>
													
													{!disabledFieldTesoreriaClientesPagadoras ? (
														<Fragment>
															{item.autorizado === 0 ? (
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12}
																      style={{marginTop: '10px'}}>
																	<TextField
																		multiline
																		required
																		fullWidth
																		variant={"outlined"}
																		name={"motivo_rechazo__" + key}
																		onChange={(e) => this.handleChange('cliente_que_paga#' + key, 'motivo_rechazo', e.target.value)}
																		type="text"
																		margin="none"
																		
																		helperText=""
																		label="Motivo por el cual la cliente no pagará"
																		value={item.motivo_rechazo || ''}
																		disabled={disabledFieldTesoreriaClientesPagadoras}
																	/>
																</Grid>
															) : null}
														</Fragment>
													) : null}
												
												</Grid>
											
											</CardContent>
										</Card>
									</Grid>
								))}
								
								{this.state.cliente_que_paga.length <= 0 ? (
									<Fragment>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
											<VistaVacia
												numero={0}
												mensaje='No se encontraron empresas pagadoras'
												width={'100px'}
												height={'100px'}
											/>
										</Grid>
									</Fragment>
								) : ''}
							
							</Grid>
						
						</article>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={1}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!disabledFieldTesoreriaClientesPagadoras ? (
									<Button
										variant="contained"
										color="primary"
										className={'btn-default-primary text-transform-none'}
										onClick={() => this.guardar()}
									>
										Guardar y confirmar
									</Button>
								) : ''}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ClientesPagadorasConfirmar.propTypes = {
	titulo: PropTypes.string.isRequired,
	id_cliente: PropTypes.number.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ClientesPagadorasConfirmar;
