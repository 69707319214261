import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import ClienteReceptoraPagoService from "../../../../../services/_Cat/ClienteReceptoraPagoService/ClienteReceptoraPagoService";
import {
	BotonAccionAbrirModal,
	BotonAccionFooterModal,
	TituloHeaderModal
} from "../../../../Include/MiniComponents/GlobalComponent";
import BoxSelectFile from "../../../../Include/MiniComponents/BoxSelectFile";
import {Description, HdrStrongOutlined} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {GiroClienteService} from "../../../../../services/_Cat/GiroClienteService/GiroClienteService";
import {DropboxApi} from "../../../../../settings/Dropbox/DropboxApi";
import {CONFIG} from "../../../../../settings/Config/Config";

class ModalClienteReceptoraPago extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_empresa: (props.item || {}).id_empresa,
			id_cliente: (props.item || {}).id_cliente,
			nombre_comercial: '',
			razon_social: '',
			rfc: '',
			correo_electronico: '',
			telefono: '',
			pagina_web: '',
			domicilio_fiscal: '',
			id_cat_giro_cliente: '',
			
			logo_base64Tipo: '',
			logo_base64: '',
			logo_archivo: '',
			logo_formato: '',
			
			domicilio_base64Tipo: '',
			domicilio_base64: '',
			domicilio_archivo: '',
			domicilio_formato: '',
			
			foto_base64Tipo: '',
			foto_base64: '',
			foto_archivo: '',
			foto_formato: '',
			
			foto2_base64Tipo: '',
			foto2_base64: '',
			foto2_archivo: '',
			foto2_formato: '',
			
			activo: true,
			listar_cat_giro: []
		};
		
		console.log("MODALCLIENTERECEPTORAPAGO::: ", this.state.id_cliente);
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	ListarGiros = () => {
		return new Promise((resolve, reject) => {
			GiroClienteService.Listar().then((response) => {
				this.setState({
					listar_cat_giro: response.data
				});
				resolve(response);
				
			}).catch((error) => {
				this.setState({
					listar_cat_giro: []
				});
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		if (this.state.id_empresa > 0) {
			ClienteReceptoraPagoService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			ClienteReceptoraPagoService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_cliente: (this.props.item || {}).id_cliente,
			nombre_comercial: '',
			razon_social: '',
			id_cat_giro_cliente: '',
			rfc: '',
			correo_electronico: '',
			telefono: '',
			pagina_web: '',
			domicilio_fiscal: '',
			logo_base64Tipo: '',
			logo_base64: '',
			logo_archivo: '',
			logo_formato: '',
			domicilio_base64Tipo: '',
			domicilio_base64: '',
			domicilio_archivo: '',
			domicilio_formato: '',
			foto_base64Tipo: '',
			foto_base64: '',
			foto_archivo: '',
			foto_formato: '',
			foto2_base64Tipo: '',
			foto2_base64: '',
			foto2_archivo: '',
			foto2_formato: '',
			activo: true,
		});
		this.ListarGiros();
		
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		
		this.ListarGiros().then((response) => {
			this.setState({
				id_cat_giro_cliente: item.id_cat_giro_cliente || ''
			});
		});
		
		this.setState({
			id_empresa: item.id_empresa,
			id_cliente: item.id_cliente,
			nombre_comercial: item.nombre_comercial || '',
			razon_social: item.razon_social || '',
			rfc: item.rfc || '',
			correo_electronico: item.correo_electronico || '',
			telefono: item.telefono || '',
			pagina_web: item.pagina_web || '',
			domicilio_fiscal: item.domicilio_fiscal || '',
			logo_base64Tipo: item.logo_base64Tipo || '',
			logo_base64: item.logo_base64 || '',
			logo_archivo: item.logo_archivo || '',
			logo_formato: item.logo_formato || '',
			domicilio_base64Tipo: item.domicilio_base64Tipo || '',
			domicilio_base64: item.domicilio_base64 || '',
			domicilio_archivo: item.domicilio_archivo || '',
			domicilio_formato: item.domicilio_formato || '',
			foto_base64Tipo: item.foto_base64Tipo || '',
			foto_base64: item.foto_base64 || '',
			foto_archivo: item.foto_archivo || '',
			foto_formato: item.foto_formato || '',
			foto2_base64Tipo: item.foto2_base64Tipo || '',
			foto2_base64: item.foto2_base64 || '',
			foto2_archivo: item.foto2_archivo || '',
			foto2_formato: item.foto2_formato || '',
			logo: item.logo || '',
			comprobante: item.comprobante || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.ListarGiros().then((response) => {
			this.setState({
				id_cat_giro_cliente: item.id_cat_giro_cliente || ''
			});
		});
		
		this.setState({
			id_empresa: item.id_empresa,
			id_cliente: item.id_cliente,
			nombre_comercial: item.nombre_comercial || '',
			razon_social: item.razon_social || '',
			rfc: item.rfc || '',
			correo_electronico: item.correo_electronico || '',
			telefono: item.telefono || '',
			pagina_web: item.pagina_web || '',
			domicilio_fiscal: item.domicilio_fiscal || '',
			logo_base64Tipo: item.logo_base64Tipo || '',
			logo_base64: item.logo_base64 || '',
			logo_archivo: item.logo_archivo || '',
			logo_formato: item.logo_formato || '',
			domicilio_base64Tipo: item.domicilio_base64Tipo || '',
			domicilio_base64: item.domicilio_base64 || '',
			domicilio_archivo: item.domicilio_archivo || '',
			domicilio_formato: item.domicilio_formato || '',
			foto_base64Tipo: item.foto_base64Tipo || '',
			foto_base64: item.foto_base64 || '',
			foto_archivo: item.foto_archivo || '',
			foto_formato: item.foto_formato || '',
			foto2_base64Tipo: item.foto2_base64Tipo || '',
			foto2_base64: item.foto2_base64 || '',
			foto2_archivo: item.foto2_archivo || '',
			foto2_formato: item.foto2_formato || '',
			logo: item.logo || '',
			comprobante: item.comprobante || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Open(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_empresa}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'empresa'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									autoFocus
									margin="dense"
									name="nombre_comercial"
									label="Nombre comercial"
									type="text"
									fullWidth
									defaultValue={this.state.nombre_comercial}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									multiline
									margin="dense"
									name="razon_social"
									label="Razón social"
									type="text"
									fullWidth
									defaultValue={this.state.razon_social}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									multiline
									margin="dense"
									name="rfc"
									label="RFC"
									type="text"
									fullWidth
									defaultValue={this.state.rfc}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									inputProps={{
										maxLength: 13,
									}}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormControl fullWidth>
									<InputLabel>Giro</InputLabel>
									<Select
										native
										value={this.state.id_cat_giro_cliente}
										onChange={this.handleChange}
										name="id_cat_giro_cliente"
										input={<Input/>}
										disabled={this.state.tipo === 'view'}
									>
										<option value=""/>
										{this.state.listar_cat_giro.map(option => (
											<option key={option.id_cat_giro_cliente}
											        value={option.id_cat_giro_cliente}>
												{option.giro_cliente}
											</option>
										))}
									
									</Select>
								</FormControl>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								
								<TextField
									multiline
									margin="dense"
									name="correo_electronico"
									label="Correo electronico"
									type="text"
									fullWidth
									defaultValue={this.state.correo_electronico}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								
								<TextField
									multiline
									margin="dense"
									name="telefono"
									label="Teléfono"
									type="text"
									fullWidth
									defaultValue={this.state.telefono}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									onKeyPress={EnteroSolo}
									inputProps={{
										maxLength: 10,
									}}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									multiline
									margin="dense"
									name="domicilio_fiscal"
									label="Domicilio Fiscal"
									type="text"
									fullWidth
									defaultValue={this.state.domicilio_fiscal}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									multiline
									margin="dense"
									name="pagina_web"
									label="Pagina web"
									type="text"
									fullWidth
									defaultValue={this.state.pagina_web}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							{/*{this.state.tipo !== 'view' ? (*/}
								<Fragment>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										
										<BoxSelectFile
											FormatAccepted={["image/png", "image/jpeg", "image/svg"]}
											MegaByte={5}
											// button={(
											// 	<AddOutlined/>
											// )}
											label={(
												<b className={'vertical-inline v-center text-blue'}>Logotipo</b>
											)}
											style={{
												color: 'black',
												height: '115px'
											}}
											item={{
												base64: this.state.logo_base64,
												base64Tipo: this.state.logo_base64Tipo,
												archivo: this.state.logo_archivo,
												formato: this.state.logo_formato,
											}}
											onChange={(r) => {
												this.handleChange({
													target: {
														name: 'logo_base64',
														value: r.base64
													}
												});
												this.handleChange({
													target: {
														name: 'logo_base64Tipo',
														value: r.base64Tipo
													}
												});
												this.handleChange({
													target: {
														name: 'logo_archivo',
														value: r.archivo
													}
												});
												this.handleChange({
													target: {
														name: 'logo_formato',
														value: r.formato
													}
												});
											}}
											showSnackBars={this.props.showSnackBars}
											disabled={this.state.tipo === 'view'}
											local={true}
										/>
									
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										
										<BoxSelectFile
											FormatAccepted={["application/pdf"]}
											MegaByte={5}
											// button={(
											// 	<AddOutlined/>
											// )}
											label={(
												<b className={'vertical-inline v-center text-blue'}>Comprobante de domicilio (PDF)</b>
											)}
											style={{
												color: 'black',
												height: '115px'
											}}
											item={{
												base64: this.state.domicilio_base64,
												base64Tipo: this.state.domicilio_base64Tipo,
												archivo: this.state.domicilio_archivo,
												formato: this.state.domicilio_formato,
											}}
											onChange={(r) => {
												this.handleChange({
													target: {
														name: 'domicilio_base64',
														value: r.base64
													}
												});
												this.handleChange({
													target: {
														name: 'domicilio_base64Tipo',
														value: r.base64Tipo
													}
												});
												this.handleChange({
													target: {
														name: 'domicilio_archivo',
														value: r.archivo
													}
												});
												this.handleChange({
													target: {
														name: 'domicilio_formato',
														value: r.formato
													}
												});
											}}
											showSnackBars={this.props.showSnackBars}
											disabled={this.state.tipo === 'view'}
										/>
									
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										
										<BoxSelectFile
											FormatAccepted={["image/png", "image/jpeg", "image/svg"]}
											MegaByte={5}
											// button={(
											// 	<AddOutlined/>
											// )}
											label={(
												<b className={'vertical-inline v-center text-blue'}>Foto</b>
											)}
											style={{
												color: 'black',
												height: '115px'
											}}
											item={{
												base64: this.state.foto_base64,
												base64Tipo: this.state.foto_base64Tipo,
												archivo: this.state.foto_archivo,
												formato: this.state.foto_formato,
											}}
											onChange={(r) => {
												this.handleChange({
													target: {
														name: 'foto_base64',
														value: r.base64
													}
												});
												this.handleChange({
													target: {
														name: 'foto_base64Tipo',
														value: r.base64Tipo
													}
												});
												this.handleChange({
													target: {
														name: 'foto_archivo',
														value: r.archivo
													}
												});
												this.handleChange({
													target: {
														name: 'foto_formato',
														value: r.formato
													}
												});
											}}
											showSnackBars={this.props.showSnackBars}
											disabled={this.state.tipo === 'view'}
											local={true}
										/>
									
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										
										<BoxSelectFile
											FormatAccepted={["image/png", "image/jpeg", "image/svg"]}
											MegaByte={5}
											// button={(
											// 	<AddOutlined/>
											// )}
											label={(
												<b className={'vertical-inline v-center text-blue'}>Foto 2</b>
											)}
											style={{
												color: 'black',
												height: '115px'
											}}
											item={{
												base64: this.state.foto2_base64,
												base64Tipo: this.state.foto2_base64Tipo,
												archivo: this.state.foto2_archivo,
												formato: this.state.foto2_formato,
											}}
											onChange={(r) => {
												this.handleChange({
													target: {
														name: 'foto2_base64',
														value: r.base64
													}
												});
												this.handleChange({
													target: {
														name: 'foto2_base64Tipo',
														value: r.base64Tipo
													}
												});
												this.handleChange({
													target: {
														name: 'foto2_archivo',
														value: r.archivo
													}
												});
												this.handleChange({
													target: {
														name: 'foto2_formato',
														value: r.formato
													}
												});
											}}
											showSnackBars={this.props.showSnackBars}
											disabled={this.state.tipo === 'view'}
											local={true}
										/>
									
									</Grid>
								</Fragment>
							{/*) : null}*/}
							
							{/*{this.state.tipo !== 'add' ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										
										<Description className={'cursor-pointer'} style={{color: 'blue'}} onClick={() => this.exportar(this.state.logo)} />
									
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										
										<Description className={'cursor-pointer'} style={{color: 'blue'}} onClick={() => this.exportar(this.state.comprobante)} />
										
									</Grid>
								</Fragment>
							) : null}*/}
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_empresa}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalClienteReceptoraPago.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalClienteReceptoraPago;
