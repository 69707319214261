import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {HdrStrong, PersonOutlined, SearchOutlined, Send} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {PrioridadService} from "../../../../services/_Cat/Prioridad/PrioridadService";
import {CatService as TipoUsuarioServiceService, CatService} from "../../../../services/_Cat/CatService/CatService";
import {TipoUsuarioService} from "../../../../services/_Cat/TipoUsuarioService/TipoUsuarioService";


class FiltrosTickets extends Component {
	
	state = {};
	
	constructor() {
		super();
		this.state = {
			folio: '',
			id_cat_ticket_status: '',
			id_cat_tipo_usuario: '',
			id_cat_prioridad: '',
			
			lista_prioridad:[],
			lista_tipo_usuario:[],
			activo: '',
		};
		
		this.ListaPrioridad();
		this.ListaTipoUsuario();
	}
	
	ListaPrioridad = () => {
		CatService.ListCatTicketStatus().then((response) => {
			this.setState({
				lista_prioridad: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_prioridad: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaTipoUsuario = () => {
		TipoUsuarioService.Listar().then((response) => {
			this.setState({
				lista_tipo_usuario: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_tipo_usuario: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.folio = this.state.folio || undefined;
		filtro.id_cat_ticket_status = this.state.id_cat_ticket_status || undefined;
		filtro.id_cat_prioridad = this.state.id_cat_prioridad || undefined;
		filtro.id_cat_tipo_usuario = this.state.id_cat_tipo_usuario || undefined;
		filtro.enviado = this.state.enviado || undefined;
		filtro.activo = this.state.activo || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="folio"
									onChange={this.handleChange}
									label="Folio"
									autoComplete={'off'}
									value={this.state.folio}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_ticket_status"
									label="Estatus"
									value={this.state.id_cat_ticket_status}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_prioridad.map(option => (
										<option key={option.id_cat_ticket_status}
										        value={option.id_cat_ticket_status}>
											{option.ticket_status}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_tipo_usuario"
									label="Area de Solución"
									value={this.state.id_cat_tipo_usuario}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_tipo_usuario.map(option => (
										<option key={option.id_cat_tipo_usuario}
										        value={option.id_cat_tipo_usuario}>
											{option.tipo_usuario}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Send className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel htmlFor="age-native-activo">Tipo Ticket</InputLabel>
									<Select
										native
										value={this.state.enviado}
										onChange={this.handleChange}
										inputProps={{
											name: 'enviado',
											id: 'age-native-activo',
										}}
									>
										<option value={'all'}>Todos</option>
										<option value={1}>Enviado</option>
										<option value={0}>Recibido</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					{/*<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="rfc"
									onChange={this.handleChange}
									label="RFC"
									autoComplete={'off'}
									value={this.state.id_cat_ticket_status}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="correo_electronico"
									onChange={this.handleChange}
									label="Correo electrónico"
									autoComplete={'off'}
									value={this.state.id_cat_prioridad}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel htmlFor="age-native-activo">Activo</InputLabel>
									<Select
										native
										value={this.state.activo}
										onChange={this.handleChange}
										inputProps={{
											name: 'activo',
											id: 'age-native-activo',
										}}
									>
										<option value={'all'}>Todos</option>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>*/}
					
					<Grid item xs={12} sm={12} md={12} lg={2} xl={2} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosTickets.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosTickets;
