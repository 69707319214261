import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {Business, DateRangeOutlined, HdrStrong, PersonOutlined, SearchOutlined, FontDownload, LooksOne, Person} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {FormaPagoService} from "../../../../services/_Cat/FormaPagoService/FormaPagoService";
import {MetodoPagoService} from "../../../../services/_Cat/MetodoPagoService/MetodoPagoService";
import {FacturacionEstatusService} from "../../../../services/_Cat/FacturacionEstatusService/FacturacionEstatusService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";


class FiltrosTimbradoFacturacion extends Component {
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			uuid: '',
			id_usuario: '',
			id_plaza: '',
			id_facturacion: '',
			id_rocket_chat: '',
			id_cat_tipo_cfdi: '',
			cliente: '',
			id_cat_forma_pago: '',
			id_cat_metodo_pago: '',
			id_cat_facturacion_estatus: '',
			fecha_alta: false,
			ordenar_fecha_alta: false,
			folio: '',
			serie: '',
			empresa: '',
			fecha_inicio: null,
			fecha_fin: null,
			
			filtro_cat_forma_pago: [],
			filtro_cat_metodo_pago: [],
			filtro_cat_facturacion_estatus: [],
			
			listar_forma_pago: [],
			listar_metodo_pago: [],
			listar_cat_tipo_cfdi: [],
			listar_facturacion_estatus: [],
		};
		
		this.ListaFormaPago();
		this.ListaMetodoPago();
		this.ListaTipoCFDI();
		//this.ListaFacturacionEstatus();
	}
	
	ListaFormaPago = () => {
		CatService.ListFormaPagoFac({}, {}).then((response) => {
			this.setState({
				listar_forma_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_forma_pago: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaMetodoPago = () => {
		CatService.ListMetodoPagoFac({}, {}).then((response) => {
			this.setState({
				listar_metodo_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_metodo_pago: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaTipoCFDI = () => {
		CatService.ListTipoCFDIFac({}, {}).then((response) => {
			this.setState({
				listar_cat_tipo_cfdi: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_tipo_cfdi: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	/*ListaFacturacionEstatus = () => {
		FacturacionEstatusService.Listar({}, {}).then((response) => {
			let status = [];
			if (response.data.length > 0) {
				for (let i = 0; i < response.data.length; i++) {
					if (Number(response.data[i].id_cat_facturacion_estatus) !== 3) {
						status.push(response.data[i])
					}
				}
			}
			this.setState({
				listar_facturacion_estatus: status
			})
		}).catch((error) => {
			this.setState({
				listar_facturacion_estatus: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};*/
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.id_usuario = this.Usr.id_usuario || undefined;
		filtro.id_rocket_chat = this.Usr.id_rocket_chat || undefined;
		filtro.id_plaza = this.Usr.id_plaza || undefined;
		filtro.uuid = this.state.uuid || undefined;
		filtro.id_cat_tipo_cfdi = this.state.id_cat_tipo_cfdi || undefined;
		filtro.folio = this.state.folio || undefined;
		filtro.serie = this.state.serie || undefined;
		filtro.empresa = this.state.empresa || undefined;
		filtro.cliente = this.state.cliente || undefined;
		filtro.lugar_expedicion = this.state.lugar_expedicion || undefined;
		filtro.fecha_inicio = this.state.fecha_inicio || undefined;
		filtro.fecha_fin = this.state.fecha_fin || undefined;
		filtro.id_cat_forma_pago = this.state.id_cat_forma_pago || undefined;
		filtro.id_cat_metodo_pago = this.state.id_cat_metodo_pago || undefined;
		filtro.id_cat_facturacion_estatus = this.state.id_cat_facturacion_estatus || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="uuid"
									onChange={this.handleChange}
									label="UUID"
									autoComplete={'off'}
									value={this.state.uuid}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<FontDownload className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="serie"
									onChange={this.handleChange}
									label="Serie"
									autoComplete={'off'}
									value={this.state.serie}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<LooksOne className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="folio"
									onChange={this.handleChange}
									label="Folio"
									autoComplete={'off'}
									value={this.state.folio}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Business className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="empresa"
									onChange={this.handleChange}
									label="Empresa"
									autoComplete={'off'}
									value={this.state.empresa}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Person className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="cliente"
									onChange={this.handleChange}
									label="Cliente"
									autoComplete={'off'}
									value={this.state.cliente}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2} align={'left'}>
						<MDDatePicker
							type={'range'}
							component={(
								<TextField
									fullWidth
									label="Fecha"
									value={(this.state.fecha_inicio && this.state.fecha_fin) ? (DateFormat.FormatText(this.state.fecha_inicio) + " al " + DateFormat.FormatText(this.state.fecha_fin)) : ''}
									readOnly
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton aria-label="Fecha">
													<DateRangeOutlined/>
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							)}
							startDate={this.state.fecha_inicio}
							endDate={this.state.fecha_fin}
							onChange={(range) => {
								const finicio = new Date(range.startDate);
								const ffin = new Date( range.endDate);
								const actual = new Date();
								console.log(finicio.getFullYear(),ffin.getFullYear(),actual.getFullYear());
								if(actual.getFullYear() === finicio.getFullYear() && actual.getFullYear() === ffin.getFullYear()) {
									this.setState({
										fecha_inicio: range.startDate,
										fecha_fin: range.endDate,
									});
									setTimeout(() => this.HandleFiltro());
								} else {
									this.props.showSnackBars('error', "El periodo de la consulta (fecha inicial y final) debe pertenecer al mismo ejercicio.");
								}
							}}
						/>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_tipo_cfdi"
									label="Tipo de CFDI"
									value={this.state.id_cat_tipo_cfdi}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_tipo_cfdi.map(option => (
										<option key={option.id_cat_tipo_cfdi}
										        value={option.id_cat_tipo_cfdi}>
											{option.tipo_cfdi}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_forma_pago"
									label="Forma de pago"
									value={this.state.id_cat_forma_pago}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_forma_pago.map(option => (
										<option key={option.id_cat_forma_pago}
										        value={option.id_cat_forma_pago}>
											{option.forma_pago} - {option.descripcion}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_metodo_pago"
									label="Metodo de pago"
									value={this.state.id_cat_metodo_pago}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_metodo_pago.map(option => (
										<option key={option.id_cat_metodo_pago}
										        value={option.id_cat_metodo_pago}>
											{option.metodo_pago} - {option.descripcion}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_facturacion_estatus"
									label="Estatus"
									value={this.state.id_cat_facturacion_estatus}
								>
									<option key={0} value={''}>&nbsp;</option>
									<option key={1} value={1}>Vigente</option>
									<option key={2} value={2}>Cancelado</option>
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					
					{/*
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<FormControlLabel
							control={
								<Checkbox type="checkbox" name='ordenar_fecha_alta'
								          checked={this.state.ordenar_fecha_alta}
								          onChange={this.handleChange} value="activo" color="primary"
								/>
							}
							label={'Ordenar por fecha de alta'}
						/>
					</Grid>*/}
					
					<Grid item xs={6} sm={6} md={4} lg={1} xl={1} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosTimbradoFacturacion.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosTimbradoFacturacion;
