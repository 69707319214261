import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const QuinielaService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				id_quiniela: filtro.id_quiniela || null,
				nombre: filtro.nombre || null,
				responsable: filtro.responsable || null,
				fecha_inicio: filtro.fecha_inicio || null,
				fecha_fin: filtro.fecha_fin || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Quiniela_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalle: (id_quiniela) => {
		let params = {
			id_quiniela: id_quiniela
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Quiniela_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_inicio = form.fecha_inicio ? DateFormat.FormatSql(form.fecha_inicio) : '';
		let fecha_fin = form.fecha_fin ? DateFormat.FormatSql(form.fecha_fin) : '';
		
		let params = {
			nombre: form.nombre,
			id_usuario: form.id_usuario,
			fecha_inicio: fecha_inicio,
			fecha_fin: fecha_fin,
			responsable: form.responsable,
			correo_electronico: form.correo_electronico,
			celular: form.celular,
			observacion: form.observacion,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Quiniela_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_inicio = form.fecha_inicio ? DateFormat.FormatSql(form.fecha_inicio) : '';
		let fecha_fin = form.fecha_fin ? DateFormat.FormatSql(form.fecha_fin) : '';
		
		let params = {
			id_quiniela: form.id_quiniela,
			nombre: form.nombre,
			id_usuario: form.id_usuario,
			fecha_inicio: fecha_inicio,
			fecha_fin: fecha_fin,
			responsable: form.responsable,
			correo_electronico: form.correo_electronico,
			celular: form.celular,
			observacion: form.observacion,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Quiniela_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_quiniela) => {
		let params = {
			id_quiniela: id_quiniela
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Quiniela_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarMiembro: (id_quiniela) => {
		let params = {
			id_quiniela: id_quiniela,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Quiniela_Miembro_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	DetalleMiembro: (form) => {
		let params = {
			id_quiniela: form.id_quiniela
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Quiniela_Miembro_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AgregarMiembro: (form) => {
		let fecha_ingreso = form.fecha_ingreso ? DateFormat.FormatSql(form.fecha_ingreso) : '';
		
		let params = {
			id_quiniela: form.id_quiniela,
			nombre: form.nombre,
			apellido_paterno: form.apellido_paterno,
			apellido_materno: form.apellido_materno,
			correo_electronico: form.correo_electronico,
			celular: form.celular,
			id_cat_piloto: form.id_cat_piloto,
			id_cat_escuderia: form.id_cat_escuderia,
			fecha_ingreso: fecha_ingreso,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Quiniela_Miembro_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ModificarMiembro: (form) => {
		let fecha_ingreso = form.fecha_ingreso ? DateFormat.FormatSql(form.fecha_ingreso) : '';
		
		let params = {
			id_quiniela: form.id_quiniela,
			id_quiniela_miembro: form.id_quiniela_miembro,
			nombre: form.nombre,
			apellido_paterno: form.apellido_paterno,
			apellido_materno: form.apellido_materno,
			correo_electronico: form.correo_electronico,
			celular: form.celular,
			id_cat_piloto: form.id_cat_piloto,
			id_cat_escuderia: form.id_cat_escuderia,
			fecha_ingreso: fecha_ingreso,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Quiniela_Miembro_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	EliminarMiembro: (id_quiniela) => {
		let params = {
			id_quiniela: id_quiniela
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Quiniela_Miembro_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	PagoGuardar: (form) => {
		let fecha_pago = form.fecha_pago ? DateFormat.FormatSql(form.fecha_pago) : '';
		
		let params = {
			id_quiniela: form.id_quiniela,
			tipo: form.band_ingreso_egreso,
			importe: form.importe,
			fecha_pago: fecha_pago,
			observacion: form.observacion,
			miembros: form.miembros,
			activo: 1,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Quiniela_Guardar_Pago', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarIngresos: (form) => {
		let params = {
			id_quiniela: form.id_quiniela,
			tipo: 1,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Quiniela_Datos_Pago', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarEgresos: (form) => {
		let params = {
			id_quiniela: form.id_quiniela,
			tipo: 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Quiniela_Datos_Pago', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
