import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {CloudDownload, CloudUpload, Description, PhotoCameraOutlined, Check, Close} from "@material-ui/icons";
import {DropzoneArea} from "material-ui-dropzone";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {FichaTecnicaService} from "../../../../services/FichaTecnicaService/FichaTecnicaService";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import DivSvg from "../../../Include/MiniComponents/DivSvg";
import img_lista_vacia from '../../../../assets/img/logo_acresco.svg'
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";


class ModalSeleccionarArchivoExpedienteTrabajador extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			expediente_trabajador: (props.item || []).expediente_trabajador,
			id_ficha_tecnica: (props.state || '').id_ficha_tecnica,
			id_cliente: (props.state || '').cliente.id_cliente,
			ficha_tecnica_expediente_trabajador: [],
			lista_esquema: (props.item || []).lista_esquema,
			
			ficha_tecnica_cantidad_empleado_esquema: (props.item || []).ficha_tecnica_cantidad_empleado_esquema || [],
			id_cat_esquema: '',
			
			activo: true,
			rfc_cliente: (props.state || '').rfc,
			cliente: (props.state || '').cliente,
			files: [],
			archivo: [],
			rfc_trabajador: '',
			curp_trabajador: '',
			
			identificacion_oficial: false,
			rfc: false,
			curp: false,
			acta_nacimiento: false,
			solicitud_empleo: false,
			comprobante_domicilio: false,
			certificado_estudios: false,
			cv: false,
			
			clearfiles: false,
			dropzone_area_open: true
		};
		
		console.log("ModalSeleccionarArchivoExpedienteTrabajador ::: ", this.state);
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			this.InitDato();
			resolve(true);
		});
	};
	
	InitDato = () => {
		FichaTecnicaService.InitDato(this.state.id_cliente).then((response) => {
			
			let dato = FieldsJs.Copy(response.data);
			
			if (response.visible === 0) {
				
				this.setState({
					visible: 0,
					mensaje: response.message
				});
				
			} else {
				
				let cliente = dato.cliente || {};
				let ficha_tecnica_prestacion = dato.ficha_tecnica_prestacion;
				let ft = dato.ficha_tecnica || {};
				let ficha_tecnica_expediente_trabajador = dato.ficha_tecnica_expediente_trabajador;
				let ficha_tecnica_cantidad_empleado_esquema = dato.ficha_tecnica_cantidad_empleado_esquema;
				
				this.setState({
					
					visible: 1,
					
					cliente: dato.cliente,
					ficha_tecnica_cantidad_empleado_esquema: ficha_tecnica_cantidad_empleado_esquema,
					id_ficha_tecnica: ft.id_ficha_tecnica ? Number(ft.id_ficha_tecnica) : '',
					ficha_tecnica_expediente_trabajador: ficha_tecnica_expediente_trabajador
					
				});
			}
			
		}).catch((error) => {
			console.error(error);
		})
	};
	
	save = () => {
		try {
			
			if (this.state.files.length === 0) {
				throw Object({
					status: 400,
					mensaje: "Seleciona por lo menos un archivo del expediente del trabajador."
				});
			}
			
			if (!this.state.id_cat_esquema) {
				throw Object({
					status: 400,
					mensaje: "El campo esquema es requerido."
				});
			}
			
			if (!this.state.rfc_trabajador && !this.state.rfc_trabajador) {
				throw Object({
					status: 400,
					mensaje: "El campo RFC o CURP es requerido."
				});
			}
			
			if (!this.state.identificacion_oficial && !this.state.rfc && !this.state.curp && !this.state.acta_nacimiento &&
				!this.state.solicitud_empleo && !this.state.comprobante_domicilio && !this.state.certificado_estudios && !this.state.cv) {
				throw Object({
					status: 400,
					mensaje: "Selecciona por lo menos un tipo de documento del trabajador."
				});
			}
			
			showSpinner('spinner');
			this.dropUpload(0);
			
			console.log("THIS.STATE::: ", this.state);
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	dropUpload = (index) => {
		let cont_files = this.state.files.length - 1;
		this.getBase64(this.state.files[index], true).then(response => {
			let archivos = FieldsJs.Copy(this.state.archivo);
			if (response) {
				archivos.push(response);
			}
			this.setState({
				archivo: archivos
			});
			
			if (index < cont_files) {
				index = index + 1;
				this.dropUpload(index);
			} else {
				hideSpinner('spinner', 600);
				FichaTecnicaService.GuardarExpedienteTrabajador(this.state).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.InitDato();
					this.limpiarChecks();
					//this.closeModal();
					console.log('SUCCESS::: ', response);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	add = () => {
		console.log("THIS.PROPS::: ", this.props);
		const {item, state} = this.props;
		
		this.setState({
			id_ficha_tecnica: state.id_ficha_tecnica || '',
			id_cat_esquema: item.lista_esquema[0].id_cat_esquema,
			ficha_tecnica_expediente_trabajador: state.ficha_tecnica_expediente_trabajador || [],
			ficha_tecnica_cantidad_empleado_esquema: state.ficha_tecnica_cantidad_empleado_esquema || [],
			
			id_cliente: state.cliente.id_cliente || '',
			abreviatura: '',
			
			rfc_trabajador: '',
			curp_trabajador: '',
			
			identificacion_oficial: false,
			rfc: false,
			curp: false,
			acta_nacimiento: false,
			solicitud_empleo: false,
			comprobante_domicilio: false,
			certificado_estudios: false,
			cv: false,
			archivo: [],
			
			activo: true,
		});
		this.open();
	};
	
	limpiarChecks = () => {
		this.setState({
			rfc_trabajador: '',
			curp_trabajador: '',
			identificacion_oficial: false,
			rfc: false,
			curp: false,
			acta_nacimiento: false,
			solicitud_empleo: false,
			comprobante_domicilio: false,
			certificado_estudios: false,
			cv: false,
			clearfiles: false,
			dropzone_area_open: false,
			files: [],
			archivo: [],
		});
		this.setState({
			clearfiles: true,
			dropzone_area_open: true
		});
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			/*id_ficha_tecnica: item.id_ficha_tecnica || '',
			sexo: item.sexo || '',
			abreviatura: item.abreviatura || '',*/
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	
	getBase64 = (e, dropzone) => {
		return new Promise((resolve, reject) => {
			let files;
			if (dropzone) {
				files = e;
			} else {
				console.log(e.target);
				files = e.target.files;
			}
			
			let rfc_curp = (this.state.rfc_trabajador ? this.state.rfc_trabajador : this.state.curp_trabajador ? this.state.curp_trabajador : '');
			
			console.log('/' + this.state.rfc_cliente + '-' + FieldsJs.getClienteTipoPersona(this.state.cliente) + '/expediente/trabajadores/' +
				rfc_curp + '_' + files.name, files);
			
			DropboxApi.Upload('/' + this.state.rfc_cliente + '-' + FieldsJs.getClienteTipoPersona(this.state.cliente) + '/expediente/trabajadores/' +
				rfc_curp + '_' + files.name, files).then(function (response) {
				
				resolve(response.path_display);
				
			}).catch(function (error) {
				reject(error);
			});
		});
	};
	
	
	active_inactive_spinner = (active, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente) => {
		/*
		* INICIO: Activar el spinner
		* */
		let arr_temp = this.state.ficha_tecnica_archivo_integracion_expediente;
		if (id_ficha_tecnica_archivo_integracion_expediente || id_cat_archivo_integracion_expediente) {
			let uno = false;
			let dos = false;
			for (let i = 0; i < arr_temp.length; i++) {
				uno = Number(arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente) === Number(id_ficha_tecnica_archivo_integracion_expediente) && Number(id_ficha_tecnica_archivo_integracion_expediente) > 0;
				dos = Number(arr_temp[i].id_cat_archivo_integracion_expediente) === Number(id_cat_archivo_integracion_expediente) && Number(id_cat_archivo_integracion_expediente) > 0;
				if (uno && dos) {
					arr_temp[i].loading = active;
				} else if (uno && !dos) {
					arr_temp[i].loading = active;
				} else if (!uno && dos) {
					arr_temp[i].loading = active;
				}
			}
		}
		this.setState({
			ficha_tecnica_archivo_integracion_expediente: arr_temp
		});
		/*
		* FIN: Activar el spinner
		* */
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		}, () => {
			console.log("CONTENT:::: ", files);
		});
	};
	
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	downloadZip = () => {
		//path = CONFIG.src + path;
		let path = this.state.ficha_tecnica_expediente_trabajador[0].archivo[0].archivo;
		console.log("PATH ::: ", path);
		DropboxApi.GenerateZip(path, true).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	render() {
		let image = img_lista_vacia;
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				{this.props.state.active_modal ? setTimeout(()=> {this.add();}, 1500):null}
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_ficha_tecnica}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title" className={'expediente-trabajador'}
				>
					
					<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Typography variant="h6" className={'text-left'}>
									Expedientes del Trabajador
								</Typography>
							</Grid>
							{this.state.ficha_tecnica_expediente_trabajador.length > 0 ? (<Grid item xs={12} sm={12} md={6} lg={6} xl={6} align={'right'}>
								<Button
									variant="contained"
									color="default"
									startIcon={<CloudDownload />}
									onClick={() => this.downloadZip()}
								>
									Descargar Zip
								</Button>
							</Grid>) : null }
						</Grid>
					
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={4}>
							{FieldsJs.inArray([1, 2, 3, 13], this.Usr.id_cat_tipo_usuario) ? (
								<Grid item className={'text-left expediente-trabajador'} xs={12} sm={12} md={12} lg={12} xl={12}>
									<fieldset className={'borde-punteado-lightgray-2'}>
										<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Documentos</legend>
										<Grid container spacing={2}>
											
											{this.state.ficha_tecnica_cantidad_empleado_esquema.length > 0 ? (
												<Fragment>
													{this.state.ficha_tecnica_cantidad_empleado_esquema.map((item, key) => (
														<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
															<div className={'text-left vertical-inline w-100'}>
																<span className={'b-r-20 w-100 bg-danger padding-3 padding-10-L padding-10-R v-center text-white text-center'} key={key}>
																	{item.esquema} ({item.cantidad_empleado_esquema} Empleados - Pendientes {item.pendientes_empleado_esquema})
																</span>
															</div>
														</Grid>
													))}
												</Fragment>
											) : ''}
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												{
													this.state.dropzone_area_open ? (
														<div className={''}>
															<DropzoneArea
																onChange={this.handleChangeDropzoneArea.bind(this)}
																open={this.state.open}
																filesLimit={10}
																dropzoneText={'Arrastre y suelte el expediente del trabajador aquí o haga clic'}
																acceptedFiles={[]}
																showPreviews={false}
																maxFileSize={5000000}
																showAlerts={false}
																showFileNames={true}
																showFileNamesInPreview={true}
																clearOnUnmount={this.state.clearfiles}
															/>
														</div>
													) : null
												}
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={2}>
													<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
														
														<TextField
															select
															fullWidth
															margin="dense"
															onChange={(e) => {
																this.handleChange(e);
																this.limpiarChecks();
															}}
															SelectProps={{
																native: true,
																MenuProps: {
																	className: '',
																},
															}}
															name="id_cat_esquema"
															label="Esquema"
															value={this.state.id_cat_esquema}
														>
															<option key={0} value={''}>&nbsp;</option>
															{this.state.ficha_tecnica_cantidad_empleado_esquema.map(option => (
																<option key={option.id_cat_esquema} value={option.id_cat_esquema}>
																	{option.esquema}
																</option>
															))}
														</TextField>
													
													</Grid>
													
													<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
														
														<TextField
															margin="dense"
															name="rfc_trabajador"
															label="RFC"
															type="text"
															fullWidth
															value={this.state.rfc_trabajador}
															onChange={this.handleChange}
															disabled={this.state.tipo === 'view'}
														/>
													
													</Grid>
													<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
														
														<TextField
															margin="dense"
															name="curp_trabajador"
															label="CURP"
															type="text"
															fullWidth
															value={this.state.curp_trabajador}
															onChange={this.handleChange}
															disabled={this.state.tipo === 'view'}
														/>
													
													</Grid>
													
													<Grid item xs={12} sm={12} md={9} lg={10} xl={10}>
														<Grid container spacing={2}>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																
																<FormControlLabel
																	control={
																		<Checkbox
																			type="checkbox"
																			name='identificacion_oficial'
																			checked={this.state.identificacion_oficial}
																			onChange={this.handleChange} value="identificacion_oficial" color="primary"/>
																	}
																	
																	label={"Identificacion Oficial"}
																/>
																<FormControlLabel
																	control={
																		<Checkbox
																			type="checkbox"
																			name='rfc'
																			checked={this.state.rfc}
																			onChange={this.handleChange} value="rfc" color="primary"
																		/>
																	}
																	label={"RFC"}
																/>
																<FormControlLabel
																	control={
																		<Checkbox
																			type="checkbox"
																			name='curp'
																			checked={this.state.curp}
																			onChange={this.handleChange} value="curp" color="primary"
																		/>
																	}
																	label={"CURP"}
																/>
																
																{FieldsJs.inArray([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], this.state.id_cat_esquema) ? (
																	<Fragment>
																		<FormControlLabel
																			control={
																				<Checkbox
																					type="checkbox"
																					name='acta_nacimiento'
																					checked={this.state.acta_nacimiento}
																					onChange={this.handleChange} value="acta_nacimiento" color="primary"
																				/>
																			}
																			label={"Acta de Nacimiento"}
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					type="checkbox"
																					name='solicitud_empleo'
																					checked={this.state.solicitud_empleo}
																					onChange={this.handleChange} value="solicitud_empleo" color="primary"
																				/>
																			}
																			label={"Solicitud de Empleo"}
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					type="checkbox"
																					name='comprobante_domicilio'
																					checked={this.state.comprobante_domicilio}
																					onChange={this.handleChange} value="comprobante_domicilio" color="primary"
																				/>
																			}
																			label={"Comprobante de Domicilio"}
																		/>
																	</Fragment>
																) : null}
																
																{FieldsJs.inArray([1, 2, 3, 4, 5], this.state.id_cat_esquema) ? (<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				name='certificado_estudios'
																				checked={this.state.certificado_estudios}
																				onChange={this.handleChange} value="certificado_estudios" color="primary"
																			/>
																		}
																		label={"Certificado de Estudios"}
																	/>
																) : null}
																
																{FieldsJs.inArray([2, 4, 8, 9, 10], this.state.id_cat_esquema) ? (<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				name='cv'
																				checked={this.state.cv}
																				onChange={this.handleChange} value="cv" color="primary"
																			/>
																		}
																		label={"C. V."}
																	/>
																) : null}
															</Grid>
														</Grid>
													</Grid>
													
													<Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
														<div className={'h-100 w-100 vertical-inline'}>
															<div className={'v-end w-100 text-right'}>
																<Button variant="contained" color="primary" onClick={() => this.save()} className={'btn-default-primary text-transform-none'}>
																	Guardar
																</Button>
															</div>
														</div>
													</Grid>
												
												</Grid>
											</Grid>
										</Grid>
									
									</fieldset>
								
								</Grid>) : null}
							
							
							{this.state.ficha_tecnica_expediente_trabajador.length > 0 ? (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Table className={'desing-acresco'}>
									<TableHead>
										<TableRow>
											<TableCell component="th" align={'left'} style={{width: "50%"}}>Documento(s)</TableCell>
											<TableCell component="th" align={'left'}>Esquema</TableCell>
											<TableCell component="th" align={'left'}>RFC/CURP</TableCell>
											<TableCell component="th" align={'left'}>IO</TableCell>
											<TableCell component="th" align={'left'}>RFC</TableCell>
											<TableCell component="th" align={'left'}>CURP</TableCell>
											<TableCell component="th" align={'left'}>AN</TableCell>
											<TableCell component="th" align={'left'}>SE</TableCell>
											<TableCell component="th" align={'left'}>CD</TableCell>
											<TableCell component="th" align={'left'}>CE</TableCell>
											<TableCell component="th" align={'left'}>CV</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.state.ficha_tecnica_expediente_trabajador.map((item, key) => (
											<Fragment key={key}>
												<TableRow>
													<TableCell style={{width: "50%"}} align={'center'} className={'padding-5-L padding-5-R'}>
														<div className={'w-100'}>
															{item.archivo.map((item2) => (
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Descargar Documento">
																	<IconButton aria-label="Descargar Documento" onClick={() => this.exportar(item2.archivo)}>
																		<Description style={{color: '#8E1E32'}}/>
																	</IconButton>
																</Tooltip>
															))
															}
														
														</div>
													</TableCell>
													<TableCell align={'left'}>{item.esquema}</TableCell>
													<TableCell align={'left'}>{item.rfc_trabajador ? item.rfc_trabajador : item.curp_trabajador}</TableCell>
													<TableCell align={'left'}>{item.identificacion_oficial ? <Check style={{color: '#3fb459'}}/> : <Close style={{color: '#8E1E32'}}/>}</TableCell>
													<TableCell align={'left'}>{item.rfc ? <Check style={{color: '#3fb459'}}/> : <Close style={{color: '#8E1E32'}}/>}</TableCell>
													<TableCell align={'left'}>{item.curp ? <Check style={{color: '#3fb459'}}/> : <Close style={{color: '#8E1E32'}}/>}</TableCell>
													<TableCell align={'left'}>{item.acta_nacimiento ? <Check style={{color: '#3fb459'}}/> : <Close style={{color: '#8E1E32'}}/>}</TableCell>
													<TableCell align={'left'}>{item.solicitud_empleo ? <Check style={{color: '#3fb459'}}/> : <Close style={{color: '#8E1E32'}}/>}</TableCell>
													<TableCell align={'left'}>{item.comprobante_domicilio ? <Check style={{color: '#3fb459'}}/> : <Close style={{color: '#8E1E32'}}/>}</TableCell>
													<TableCell align={'left'}>{item.certificado_estudios ? <Check style={{color: '#3fb459'}}/> : <Close style={{color: '#8E1E32'}}/>}</TableCell>
													<TableCell align={'left'}>{item.curriculum_vitae ? <Check style={{color: '#3fb459'}}/> : <Close style={{color: '#8E1E32'}}/>}</TableCell>
												</TableRow>
											</Fragment>
										
										))}
									</TableBody>
								</Table>
							</Grid>) : <Fragment>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<div className={'w-auto'} align={'center'}>
										<DivSvg img={image} height={this.props.height || '100px'} width={this.props.width || '100px'} opacity={true}/>
										<Typography className={'text-center px-16'} style={{opacity: 0.5, padding: (this.props.paddingText || "20px"), color: this.props.colorText || 'gray'}}>
											{"No se encontraron expedientes"}
										</Typography>
									</div>
								</Grid>
							</Fragment>}
						</Grid>
						<br/>
					</DialogContent>
					
					{/*<Divider/>*/}
					
					<DialogActions>
						
						<Button onClick={this.close} color="primary">
							Cerrar
						</Button>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalSeleccionarArchivoExpedienteTrabajador.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	state: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshFichaTecnica: PropTypes.func.isRequired,
};

export default ModalSeleccionarArchivoExpedienteTrabajador;
