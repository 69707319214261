import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import {FichaTecnicaService} from "../../../../services/FichaTecnicaService/FichaTecnicaService";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {Icono} from "../../../Include/Icono/Icono";
import ModalImage from "../../../Include/ModalImage/ModalImage";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import TextField from "@material-ui/core/TextField";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import {ClientePotencialService} from "../../../../services/ClientePotencialService/ClientePotencialService";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import AltaDeEmpleados from "./AltaDeEmpleados";
import {CheckOutlined, CloudUploadOutlined, SyncOutlined} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";


class RevisarNominaDeEmpleadosYSubirAcuse extends Component {
	
	state = {};
	
	Usr = {};
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			id_cliente: props.id_cliente,
			
			cliente: {},
			
			id_cat_ficha_tecnica_status: '',
			
			id_nomina_empleado_alta_imss: '',
			
			acuse_base64: '',
			acuse_base64Tipo: '',
			acuse_archivo: '',
			acuse_formato: '',
			
			observaciones: '',
			cantidad_empleado: '',
			
			
			excel_nomina_empleado_pendiente_base64: '',
			excel_nomina_empleado_pendiente_base64Tipo: '',
			excel_nomina_empleado_pendiente_archivo: '',
			excel_nomina_empleado_pendiente_formato: '',
			
			comentario_pendiente: '',
			
			nomina_empleado_alta_imss: [],
			nomina_empleado_alta_imss_pendiente: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			FichaTecnicaService.InitDato(this.state.id_cliente).then((response) => {
				
				let dato = FieldsJs.Copy(response.data);
				
				if (response.visible === 0) {
					
					this.setState({
						visible: 0,
						mensaje: response.message
					});
					
				} else {
					
					let nomina_empleado_alta_imss = dato.nomina_empleado_alta_imss || [];
					
					let nomina_empleado_alta_imss_pendiente = dato.nomina_empleado_alta_imss_pendiente || [];
					
					let total_contratos_individuales = dato.cliente.total_contratos_individuales || 0;
					
					let id_nomina_empleado_alta_imss = null;
					for (let i = 0; i < nomina_empleado_alta_imss.length; i++) {
						let item = nomina_empleado_alta_imss[i];
						id_nomina_empleado_alta_imss = item.id_nomina_empleado_alta_imss;
					}
					
					this.setState({
						
						visible: 1,
						
						cliente: dato.cliente,
						
						id_cat_ficha_tecnica_status: (dato.ficha_tecnica || {}).id_cat_ficha_tecnica_status || '',
						
						id_nomina_empleado_alta_imss: id_nomina_empleado_alta_imss,
						
						nomina_empleado_alta_imss: nomina_empleado_alta_imss,
						
						nomina_empleado_alta_imss_pendiente: nomina_empleado_alta_imss_pendiente,
						
						total_contratos_individuales: total_contratos_individuales,
						
					});
					
				}
				resolve(true);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
				reject(false)
			});
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	subirAcuseComprobante = () => {
		
		try {
			let total_contratos_individuales = this.state.total_contratos_individuales;
			let cantidad_empleados = this.state.cantidad_empleado;
			
			if (cantidad_empleados > this.pendiente_para_realizar()) {
				throw Object({
					status: false,
					mensaje: "No puedes agregar mas acuse de empleados, excedes del total, pendientes por subir acuse: " + this.pendiente_para_realizar()
				});
			}
			
			ClientePotencialService.GuardarAcuseComprobanteAltaIMSS(this.state).then((response) => {
				
				this.RefreshInit();
				
				this.showSnackBars('success', response.mensaje);
				
				this.setState({
					
					acuse_base64: '',
					acuse_base64Tipo: '',
					acuse_archivo: '',
					acuse_formato: '',
					
					observaciones: '',
					cantidad_empleado: '',
					
				});
				
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
		
	};
	
	subirNominaEmpleadoFaltante = () => {
		ClientePotencialService.GuardarNominaEmpleadosAltaIMSSPendiente(this.state).then((response) => {
			
			this.RefreshInit();
			
			this.showSnackBars('success', response.mensaje);
			
			this.setState({
				
				excel_nomina_empleado_pendiente_base64: '',
				excel_nomina_empleado_pendiente_base64Tipo: '',
				excel_nomina_empleado_pendiente_archivo: '',
				excel_nomina_empleado_pendiente_formato: '',
				
				comentario_pendiente: '',
				
			});
			
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	canidad_acuse_adjuntado = () => {
		let cantidad = 0;
		for (let i = 0; i < this.state.nomina_empleado_alta_imss.length; i++) {
			let item = this.state.nomina_empleado_alta_imss[i];
			cantidad += (item.nomina_empleado_alta_imss_acuse_comprobante || []).length;
		}
		return cantidad;
	};
	
	disabledFieldPromotorSubirNoinaEmpleadosBolean = () => {
		
		let disabledFieldRevisarNominaDeEmpleadosYSubirAcuse = true;
		
		if (FieldsJs.inArray([1, 2, 7], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldRevisarNominaDeEmpleadosYSubirAcuse = false;
		}
		
		return disabledFieldRevisarNominaDeEmpleadosYSubirAcuse;
	};
	
	pendiente_para_realizar = () => {
		let arr_temp = this.state.nomina_empleado_alta_imss;
		
		let cat = 0;
		for (let i = 0; i < arr_temp.length; i++) {
			for (let x = 0; x < arr_temp[i].nomina_empleado_alta_imss_acuse_comprobante.length; x++) {
				let item = arr_temp[i].nomina_empleado_alta_imss_acuse_comprobante[x];
				cat += (item.cantidad_empleado ? Number(item.cantidad_empleado) : 0);
			}
		}
		return (this.state.total_contratos_individuales || 0) - cat;
	};
	
	
	render() {
		
		const disabledFieldRevisarNominaDeEmpleadosYSubirAcuse = this.disabledFieldPromotorSubirNoinaEmpleadosBolean();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Typography className={'px-20'}>
									<Fab size="small" color="secondary" aria-label="Actualizar" onClick={() => this.RefreshInit()} style={{marginRight: '15px'}}>
										<SyncOutlined/>
									</Fab>
									{this.props.titulo}
								</Typography>
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={9} lg={9} xl={9} align={'center'}>
								
								<Card>
									<CardContent style={{background: 'gray', padding: '15px'}}>
										
										<Grid container spacing={2}>
											
											{this.state.nomina_empleado_alta_imss.map((item, key) => (
												<Fragment key={key}>
													<Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
														
														<Tooltip
															TransitionComponent={Zoom} placement={"top"}
															title={(item.observaciones || 'Sin observaciones') + ' ' + (item.created_at ? DateFormat.FechaHoraFormatText(item.created_at) : '')}
															children={
																<article className={'card-0 bg-white'}
																         style={{padding: '10px 20px'}}>
																	{item.excel_nomina_empleado_archivo ? (
																		<div className="vertical-inline">
																			<div className={'v-center'}>
																				{Icono.get(item.excel_nomina_empleado_formato, '30px', '30px')}
																			</div>
																			<div className={'v-center margin-10-L'}>
																				<Fragment>
																					<ModalImage
																						tipo={'view'} desing={3}
																						item={{
																							base64: item.excel_nomina_empleado_base64 || '',
																							base64Tipo: item.excel_nomina_empleado_base64Tipo || '',
																							archivo: item.excel_nomina_empleado_archivo || '',
																							formato: item.excel_nomina_empleado_formato || '',
																						}}
																					/>
																				</Fragment>
																			</div>
																		</div>
																	) : ''}
																
																</article>
															}
														/>
													
													</Grid>
												</Fragment>
											))}
											
											{this.state.nomina_empleado_alta_imss.length <= 0 ? (
												<Fragment>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<VistaVacia
															padding={'10px'}
															paddingText={'10px'}
															colorText={'white'}
															numero={0}
															mensaje={'No se ha registrado nominas de empleados.'}
															height={'70px'}
															width={'70px'}
														/>
													</Grid>
												</Fragment>
											) : ''}
										
										</Grid>
									
									</CardContent>
								</Card>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3} align={'center'}>
								<AltaDeEmpleados
									id_cliente={this.state.id_cliente}
									icono={
										<Fab
											variant="extended"
											size="small"
											color="primary"
											className={"btn-default-primary text-transform-none w-100"}
											aria-label="Alta de empleados"
										>
											<CheckOutlined/> {disabledFieldRevisarNominaDeEmpleadosYSubirAcuse ? "Ver empleados" : "Alta de empleados"}
										</Fab>
									}
									RefreshList={this.props.RefreshList}
									showSnackBars={this.props.showSnackBars}
								/>
								{!disabledFieldRevisarNominaDeEmpleadosYSubirAcuse ? (
									<Typography component="span" style={{fontSize: '10px'}}>
										Selecciona el formato de archivo válido para la alta de los empleados en el sistema
									</Typography>
								) : null}
							</Grid>
							
							{!disabledFieldRevisarNominaDeEmpleadosYSubirAcuse ? (
								<Grid item xs={12} sm={12} md={8} lg={8} xl={8} align={'center'}>
									
									<p className={'margin-0'} component="span">
										Después de analizar la información (Nómina) realizar el alta de empleados (IMSS)
									</p>
									
									<p className={'margin-0 padding-5-T'} component="span">
										Sube los acuses de altas o movimientos
									</p>
								
								</Grid>
							) : null}
							
							{!disabledFieldRevisarNominaDeEmpleadosYSubirAcuse ? (
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4} align={'center'}>
									<Typography className={'text-center text-black padding-0 margin-0'}>
										Pendientes para subir acuse
									</Typography>
									<Typography className={'text-center text-black padding-0 margin-0 px-25'}>
										{this.pendiente_para_realizar()}
									</Typography>
								</Grid>
							) : null}
							
							<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
								
								<Card style={{height: '100%'}}>
									<CardContent style={{background: 'lightgray', padding: '15px', height: 'calc(100% - 30px)'}}>
										
										<Grid container spacing={2} alignItems={"flex-start"} alignContent={"flex-start"} style={{height: '100%'}}>
											
											{!disabledFieldRevisarNominaDeEmpleadosYSubirAcuse ? (
												
												<Fragment>
													
													<Grid item xs={12} sm={4} md={4} lg={4} xl={4} align={'center'}>
														
														<BoxSelectFile
															// button={(
															// 	<AddOutlined/>
															// )}
															label={(
																<div>
																	Acuse <b
																	className={'vertical-inline v-center text-blue'}>(Requerido)</b>
																</div>
															)}
															style={{
																color: 'blue',
																height: '110px'
															}}
															item={{
																base64: this.state.acuse_base64,
																base64Tipo: this.state.acuse_base64Tipo,
																archivo: this.state.acuse_archivo,
																formato: this.state.acuse_formato,
															}}
															onChange={(r) => {
																this.handleChange({
																	target: {
																		name: 'acuse_base64',
																		value: r.base64
																	}
																});
																this.handleChange({
																	target: {
																		name: 'acuse_base64Tipo',
																		value: r.base64Tipo
																	}
																});
																this.handleChange({
																	target: {
																		name: 'acuse_archivo',
																		value: r.archivo
																	}
																});
																this.handleChange({
																	target: {
																		name: 'acuse_formato',
																		value: r.formato
																	}
																});
															}}
															showSnackBars={this.showSnackBars}
															disabled={disabledFieldRevisarNominaDeEmpleadosYSubirAcuse}
														/>
													
													</Grid>
													
													<Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
														
														<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<TextField
																	fullWidth
																	onChange={this.handleChange}
																	type="text"
																	margin="none"
																	helperText=""
																	name="cantidad_empleado"
																	label="Cantidad de empleados"
																	value={this.state.cantidad_empleado}
																	onKeyPress={EnteroSolo}
																	inputProps={{
																		maxLength: 15,
																	}}
																	required
																	disabled={disabledFieldRevisarNominaDeEmpleadosYSubirAcuse}
																/>
															</Grid>
															
															<Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
																<TextField
																	multiline
																	fullWidth
																	onChange={this.handleChange}
																	type="text"
																	margin="none"
																	helperText=""
																	name="observaciones"
																	label="Observaciones"
																	value={this.state.observaciones}
																	disabled={disabledFieldRevisarNominaDeEmpleadosYSubirAcuse}
																/>
															</Grid>
															
															<Grid item xs={3} sm={3} md={3} lg={3} xl={3} align={'right'}>
																<Fab
																	variant="extended"
																	size="small"
																	color="primary"
																	className={"btn-default-primary text-transform-none w-100"}
																	aria-label="Subir archivo"
																	onClick={() => this.subirAcuseComprobante()}
																	disabled={disabledFieldRevisarNominaDeEmpleadosYSubirAcuse}
																>
																	<CloudUploadOutlined style={{marginRight: '5px'}}/> Subir
																</Fab>
															</Grid>
														
														</Grid>
													
													</Grid>
												
												</Fragment>
											
											) : (
												<Fragment>
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
														<Typography component="span">
															Acuses de alta o movimientos
														</Typography>
													</Grid>
												
												</Fragment>
											)}
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
												
												<Card>
													<CardContent style={{background: '#b0b0b0', padding: '15px'}}>
														
														<Grid container spacing={2}>
															
															{this.state.nomina_empleado_alta_imss.map((value, index) => (
																<Fragment key={index}>
																	{(value.nomina_empleado_alta_imss_acuse_comprobante || []).map((item, key) => (
																		<Fragment key={key}>
																			<Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
																				
																				<Card>
																					<CardContent style={{padding: '15px'}}>
																						
																						<ListItemAvatar>
																							<Fragment>
																								<Avatar>
																									<Fragment>
																										{Icono.get(item.acuse_formato, '30px', '30px')}
																									</Fragment>
																								</Avatar>
																								<ModalImage
																									tipo={'view'} desing={3}
																									item={{
																										base64: item.acuse_base64 || '',
																										base64Tipo: item.acuse_base64Tipo || '',
																										archivo: item.acuse_archivo || '',
																										formato: item.acuse_formato || '',
																									}}
																								/>
																							</Fragment>
																						</ListItemAvatar>
																						
																						<p className={'text-left text-black margin-0 padding-0 px-12'}>
																							Cantidad de empleados: {item.cantidad_empleado}
																						</p>
																						
																						<p className={'text-left text-black margin-0 padding-0 px-12'}>
																							Observaciones:
																						</p>
																						<p className={'text-left text-black margin-0 padding-0 px-12'}>
																							{item.observaciones ? item.observaciones:'Sin observaciones'}
																						</p>
																					
																					</CardContent>
																				</Card>
																			
																			</Grid>
																		</Fragment>
																	))}
																</Fragment>
															
															))}
															
															{this.canidad_acuse_adjuntado() <= 0 ? (
																<Fragment>
																	<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																		<VistaVacia
																			numero={0}
																			mensaje={'No se ha registrado acuses de alta de empleados.'}
																			height={'100px'}
																			width={'100px'}
																		/>
																	</Grid>
																</Fragment>
															) : ''}
														
														</Grid>
													
													</CardContent>
												</Card>
											
											</Grid>
										
										</Grid>
									
									</CardContent>
								</Card>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								
								<Card style={{height: '100%'}}>
									<CardContent style={{background: 'lightgray', padding: '15px', height: '100%'}}>
										
										<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
											
											{!disabledFieldRevisarNominaDeEmpleadosYSubirAcuse ? (
												<Fragment>
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
														
														<Typography component="span">
															Excel con las nominas de empleados pendientes
														</Typography>
													
													</Grid>
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
														
														<BoxSelectFile
															// button={(
															// 	<AddOutlined/>
															// )}
															label={(
																<div>
																	Faltante para dar de alta <b
																	className={'vertical-inline v-center text-blue'}>(Requerido)</b>
																</div>
															)}
															style={{
																color: 'red',
																height: '110px'
															}}
															item={{
																base64: this.state.excel_nomina_empleado_pendiente_base64,
																base64Tipo: this.state.excel_nomina_empleado_pendiente_base64Tipo,
																archivo: this.state.excel_nomina_empleado_pendiente_archivo,
																formato: this.state.excel_nomina_empleado_pendiente_formato,
															}}
															onChange={(r) => {
																this.handleChange({
																	target: {
																		name: 'excel_nomina_empleado_pendiente_base64',
																		value: r.base64
																	}
																});
																this.handleChange({
																	target: {
																		name: 'excel_nomina_empleado_pendiente_base64Tipo',
																		value: r.base64Tipo
																	}
																});
																this.handleChange({
																	target: {
																		name: 'excel_nomina_empleado_pendiente_archivo',
																		value: r.archivo
																	}
																});
																this.handleChange({
																	target: {
																		name: 'excel_nomina_empleado_pendiente_formato',
																		value: r.formato
																	}
																});
															}}
															showSnackBars={this.showSnackBars}
															disabled={disabledFieldRevisarNominaDeEmpleadosYSubirAcuse}
														/>
													
													</Grid>
													
													<Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
														<TextField
															multiline
															fullWidth
															onChange={this.handleChange}
															type="text"
															margin="none"
															
															helperText=""
															name="comentario_pendiente"
															label="Observaciones"
															value={this.state.comentario_pendiente}
															required
															disabled={disabledFieldRevisarNominaDeEmpleadosYSubirAcuse}
														/>
													</Grid>
													
													<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
														<Fab
															variant="extended"
															size="small"
															color="primary"
															className={"btn-default-primary text-transform-none w-100"}
															aria-label="Subir archivo"
															onClick={() => this.subirNominaEmpleadoFaltante()}
															disabled={disabledFieldRevisarNominaDeEmpleadosYSubirAcuse}
														>
															<CloudUploadOutlined style={{marginRight: '5px'}}/> Subir
														</Fab>
													</Grid>
												
												</Fragment>
											
											) : (
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
													
													<Typography component="span">
														Excel de empleados pendientes para la alta
													</Typography>
												
												</Grid>
											)}
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
												
												<Grid container spacing={2}>
													
													{(this.state.nomina_empleado_alta_imss_pendiente || []).map((item, key) => (
														<Fragment key={key}>
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																
																<Card>
																	<CardContent style={{padding: '15px'}}>
																		
																		<div className={'row-flex'}>
																			<div className="v-center"
																			     style={{paddingRight: '10px'}}>
																				<ListItemAvatar>
																					<Fragment>
																						<Avatar>
																							<Fragment>
																								{Icono.get(item.excel_nomina_empleado_pendiente_formato, '30px', '30px')}
																							</Fragment>
																						</Avatar>
																						<ModalImage
																							tipo={'view'} desing={3}
																							item={{
																								base64: item.excel_nomina_empleado_pendiente_base64 || '',
																								base64Tipo: item.excel_nomina_empleado_pendiente_base64Tipo || '',
																								archivo: item.excel_nomina_empleado_pendiente_archivo || '',
																								formato: item.excel_nomina_empleado_pendiente_formato || '',
																							}}
																						/>
																					</Fragment>
																				</ListItemAvatar>
																			</div>
																			<div className="v-center">
																				<Typography
																					className={'text-left text-black margin-0 padding-0 px-12'}>
																					Comentarios: {item.comentario_pendiente || 'Sin comentarios...'}
																				</Typography>
																			</div>
																		</div>
																	
																	</CardContent>
																</Card>
															
															</Grid>
														</Fragment>
													))}
													
													{(this.state.nomina_empleado_alta_imss_pendiente || []).length <= 0 ? (
														<Fragment>
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<VistaVacia
																	numero={0}
																	padding={'10px'}
																	paddingText={'10px'}
																	mensaje={'No se ha registrado de alta de nominas pendientes.'}
																	height={'50px'}
																	width={'50px'}
																/>
															</Grid>
														</Fragment>
													) : ''}
												
												</Grid>
											
											</Grid>
										
										</Grid>
									
									</CardContent>
								</Card>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={0}>
							<Grid item xs={4} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={8} align={'right'}>
								{/*<Button onClick={() => this.save()} color="primary">
									Indicar que la alta de empleados se completo
								</Button>*/}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

RevisarNominaDeEmpleadosYSubirAcuse.propTypes = {
	titulo: PropTypes.string.isRequired,
	id_cliente: PropTypes.number.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default RevisarNominaDeEmpleadosYSubirAcuse;
