import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {AccountTree, DateRangeOutlined, HdrStrong, MailOutline, PersonOutlined, SearchOutlined, ViewDay, Business} from "@material-ui/icons";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {CatService as TipoUsuarioServiceService, CatService} from "../../../../services/_Cat/CatService/CatService";
import {TipoUsuarioService} from "../../../../services/_Cat/TipoUsuarioService/TipoUsuarioService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {ProyectoService} from "../../../../services/_Cat/ProyectoService/ProyectoService";
import {ModuloService} from "../../../../services/_Cat/ModuloService/ModuloService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";


class FiltrosComprobantesPago extends Component {
	
	Usr = {};
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			usuario: '',
			id_empresa: '',
			ejercicio: '',
			periodo: '',
			id_cat_tipo_pago_comprobante_pago: '',
			id_cat_tipo_declaracion: '',
			activo: '',
			
			lista_ejercicio: [
				{anio: 2022},
				{anio: 2021},
				{anio: 2020},
				{anio: 2019},
				{anio: 2018},
				{anio: 2017},
				{anio: 2016},
				{anio: 2015},
				{anio: 2014},
				{anio: 2013},
				{anio: 2012},
				{anio: 2011},
				{anio: 2010},
				{anio: 2009},
				{anio: 2008},
				{anio: 2007},
				{anio: 2006},
				{anio: 2005},
			],
			lista_periodo: [
				{mes: 'Enero'},
				{mes: 'Febrero'},
				{mes: 'Marzo'},
				{mes: 'Abril'},
				{mes: 'Mayo'},
				{mes: 'Junio'},
				{mes: 'Julio'},
				{mes: 'Agosto'},
				{mes: 'Septiembre'},
				{mes: 'Octubre'},
				{mes: 'Noviembre'},
				{mes: 'Diciembre'}
			],
			lista_tipo_pago: [],
			lista_tipo_declaracion: [],
			
		};
		
		this.ListImpuesto();
		this.ListDeclaracion();
	}
	
	ListImpuesto = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoPagoComprobantePago().then((response) => {
				this.setState({
					lista_tipo_pago: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	ListDeclaracion = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoDeclaracion().then((response) => {
				this.setState({
					lista_tipo_declaracion: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.id_empresa = this.state.id_empresa || undefined;
		filtro.ejercicio = this.state.ejercicio || undefined;
		filtro.periodo = this.state.periodo || undefined;
		filtro.id_cat_tipo_pago_comprobante_pago = this.state.id_cat_tipo_pago_comprobante_pago || undefined;
		filtro.id_cat_tipo_declaracion = this.state.id_cat_tipo_declaracion || undefined;
		filtro.activo = this.state.activo || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (
						<Grid item xs={6} sm={6} md={2} lg={2} xl={2} align={'left'}>
							<Grid container spacing={1} alignItems={"flex-end"}>
								<Grid item className={'w-30-px'}>
									<Business className={'w-100 text-gray'}/>
								</Grid>
								<Grid item className={'w-100-30-px'}>
									<FormControl fullWidth>
										<InputLabel>Empresa</InputLabel>
										<Select
											native
											value={this.state.id_empresa}
											onChange={this.handleChange}
											name="id_empresa"
											input={<Input/>}
											disabled={this.props.tipo === 'view'}
										>
											<option value=""/>
											{this.props.lista_empresa.map(option => (
												<option key={option.id_empresa}
												        value={option.id_empresa}>
													{option.nombre_comercial}
												</option>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
					) : null}
					
					<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Tipo Pago</InputLabel>
									<Select
										native
										value={this.state.id_cat_tipo_pago_comprobante_pago}
										onChange={this.handleChange}
										name="id_cat_tipo_pago_comprobante_pago"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.state.lista_tipo_pago.map(option => (
											<option key={option.id_cat_tipo_pago_comprobante_pago}
											        value={option.id_cat_tipo_pago_comprobante_pago}>
												{option.tipo_pago_comprobante_pago}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					{/*<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Ejercicio</InputLabel>
									<Select
										native
										value={this.state.ejercicio}
										onChange={this.handleChange}
										name="ejercicio"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.state.lista_ejercicio.map(option => (
											<option key={option.anio}
											        value={option.anio}>
												{option.anio}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Periodo</InputLabel>
									<Select
										native
										value={this.state.periodo}
										onChange={this.handleChange}
										name="periodo"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.state.lista_periodo.map(option => (
											<option key={option.mes}
											        value={option.mes}>
												{option.mes}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Tipo Declaración</InputLabel>
									<Select
										native
										value={this.state.id_cat_tipo_declaracion}
										onChange={this.handleChange}
										name="id_cat_tipo_declaracion"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.state.lista_tipo_declaracion.map(option => (
											<option key={option.id_cat_tipo_declaracion}
											        value={option.id_cat_tipo_declaracion}>
												{option.abreviatura + '-' + option.tipo_declaracion}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>*/}
					
					<Grid item xs={12} sm={12} md={12} lg={2} xl={2} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosComprobantesPago.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	lista_empresa: PropTypes.array.isRequired,
	ListEmpresa: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosComprobantesPago;
