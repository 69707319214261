import React, {Component, Fragment} from 'react';
import ListaTabla from './Includes/ListaTabla';
import ModalRecordatorios from './Includes/ModalRecordatorios';

import {RecordatoriosService} from '../../../services/RecordatoriosService/RecordatoriosService';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {BotonActualizarLista, CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";
import FiltrosRecordatorios from "./Includes/FiltrosRecordatorios";
import Paginacion from "../../Include/Paginacion/Paginacion";


class Recordatorios extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			listar_cat_recordatorio: [],
			
			filtro: {},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
		};
		this.Listar();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	Listar = () => {
		RecordatoriosService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				listar_cat_recordatorio: response.data,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			
		}).catch((error) => {
			this.setState({
				listar_cat_recordatorio: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'Recordatorios'}
					/*botonPDF={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaPDF onClick={() => {
								
								}}/>
							) : ''}
						</Fragment>
					}
					botonEXCEL={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaExcel onClick={() => {
								
								}}/>
							) : ''}
						</Fragment>
					}*/
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() => {
								this.Listar();
							}}/>
						</Fragment>
					}
				/>
				
				<FiltrosRecordatorios
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				
				<div className={'form margin-30-B margin-20-T'}>
					
					{this.state.listar_cat_recordatorio.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_cat_recordatorio}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				<ModalRecordatorios
					tipo={'new'}
					item={
						{}
					}
					componente={(
						<Zoom className={'btn-fixed-bottom-right cursor-pointer'}
						      key={'inherit'}
						      timeout={1500}
						      in={true}
						      style={{transitionDelay: `${100}ms`}}
						      unmountOnExit
						>
							<Fab color={'primary'}><Add/></Fab>
						</Zoom>
					)
					}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>
			
			</div>
		);
	}
}

Recordatorios.propTypes = {};

export default IntegrationNotistack(Recordatorios);
