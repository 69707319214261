import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import {FichaTecnicaService} from "../../../../services/FichaTecnicaService/FichaTecnicaService";
import {FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import TextField from "@material-ui/core/TextField";
import {Icono} from "../../../Include/Icono/Icono";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {DangerCheckbox, WarningCheckbox} from '../../../Include/ColorCheckbox/ColorCheckbox'
import {ClientePotencialService} from "../../../../services/ClientePotencialService/ClientePotencialService";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {CONFIG} from "../../../../settings/Config/Config";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {Cancel, CheckOutlined, DateRangeOutlined, Edit, RadioButtonChecked, RadioButtonUnchecked, SyncOutlined} from "@material-ui/icons";
import Draggable from "react-draggable";
import Paper from "@material-ui/core/Paper";
import ModalImage from "../../../Include/ModalImage/ModalImage";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import subir_confirmar_prenomina from '../../../../assets/img/icon-subtabla/subir_confirmar_prenomina.svg';
import globo_escritura from '../../../../assets/img/icons/globo_escritura.svg';
import {PopupService} from "../../../../settings/PoPup/PoPup";
import Fab from "@material-ui/core/Fab";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";


function DraggableModal(props) {
	return (
		<Draggable cancel={'[class*="MuiDialogContent-root"]'} enableUserSelectHack={false}>
			<Paper {...props} />
		</Draggable>
	);
}

class SubirPrenominaDeEmpleados extends Component {
	
	state = {};
	
	Usr = {};
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			id_cliente: props.id_cliente,
			
			cliente: '',
			
			id_cat_ficha_tecnica_status: '',
			
			periodo: '',
			id_fecha_prenomina: null,
			
			fecha_inicio: null,
			fecha_fin: null,
			
			prenomina_base64: '',
			prenomina_base64Tipo: '',
			prenomina_archivo: '',
			prenomina_formato: '',
			comentario: '',
			
			checkbox_rechazado_no: false,
			checkbox_rechazado_si: false,
			motivo_comentario: '',
			
			fecha_prenomina: [],
			editfecha: false,
			prenomina: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			FichaTecnicaService.InitDato(this.state.id_cliente).then((response) => {
				
				let dato = FieldsJs.Copy(response.data);
				
				if (response.visible === 0) {
					
					this.setState({
						visible: 0,
						mensaje: response.message
					});
					
				} else {
					
					let fecha_prenomina = dato.fecha_prenomina || [];
					if (fecha_prenomina.length > 0) {
						this.SeleccionaFechaPrenomina(fecha_prenomina[0]);
					}
					this.setState({
						
						visible: 1,
						
						cliente: dato.cliente,
						
						id_cat_ficha_tecnica_status: (dato.ficha_tecnica || {}).id_cat_ficha_tecnica_status || '',
						
						fecha_prenomina: fecha_prenomina,
						
					});
					
					if (this.state.id_fecha_prenomina > 0) {
						setTimeout(() => this.ActualizarListaPrenominaYPrenominaComentario(this.state.id_fecha_prenomina), 2000)
					}
					
				}
				resolve(true);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
				reject(false)
			});
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	AgregarFechaPrenomina = () => {
		
		try {
			
			if (!(this.state.fecha_inicio && this.state.fecha_fin)) {
				throw Object({
					status: false,
					mensaje: "Selecciona la fecha inicio y fin de la prenómina"
				});
			}
			
			ClientePotencialService.ClienteFechaPrenominaGuardar(this.state).then((response) => {
				
				this.RefreshInit();
				
				this.setState({
					fecha_inicio: null,
					fecha_fin: null,
				});
				
				this.showSnackBars('success', response.mensaje);
				
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
		
	};
	
	SeleccionaFechaPrenomina = (value) => {
		let id_prenomina = null;
		let id_cat_prenomina_status = null;
		for (let i = 0; i < value.prenomina.length; i++) {
			let item = value.prenomina[i];
			id_prenomina = item.id_prenomina;
			id_cat_prenomina_status = item.id_cat_prenomina_status;
		}
		this.setState({
			
			id_fecha_prenomina: value.id_fecha_prenomina,
			id_cat_fecha_prenomina_status: value.id_cat_fecha_prenomina_status,
			
			id_prenomina: id_prenomina,
			id_cat_prenomina_status: id_cat_prenomina_status,
			
			periodo: DateFormat.FormatText(value.fecha_inicio) + ' al ' + DateFormat.FormatText(value.fecha_fin),
			prenomina: value.prenomina || [],
		});
		this.MoverScroll();
	};
	
	MoverScroll = () => {
		setTimeout(() => FieldsJs.scrollEnd("scroll-chat-prenomina"));
	};
	
	ActualizarListaPrenominaYPrenominaComentario = (id_fecha_prenomina) => {
		let value = {};
		for (let i = 0; i < this.state.fecha_prenomina.length; i++) {
			let item = this.state.fecha_prenomina[i];
			if (item.id_fecha_prenomina === id_fecha_prenomina) {
				value = item;
			}
		}
		this.SeleccionaFechaPrenomina(value);
	};
	
	SubirPrenominaCliente = () => {
		
		try {
			
			/*if (!(this.state.fecha_inicio && this.state.fecha_fin)) {
				throw Object({
					status: false,
					mensaje: "Selecciona la fecha inicio y la fecha fin"
				});
			}*/
			
			ClientePotencialService.ClientePrenominaGuradar(this.state).then((response) => {
				
				this.RefreshInit();
				
				this.setState({
					prenomina_base64: '',
					prenomina_base64Tipo: '',
					prenomina_archivo: '',
					prenomina_formato: '',
					comentario: '',
				});
				
				setTimeout(() => {
					this.ActualizarListaPrenominaYPrenominaComentario(this.state.id_fecha_prenomina);
				}, 2000);
				
				this.showSnackBars('success', response.mensaje);
				
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
		
	};
	
	EnviarPrenominaComentarioStatus = () => {
		
		try {
			
			if (!this.state.motivo_comentario) {
				throw Object({
					status: false,
					mensaje: "Escribe una observacion"
				});
			}
			
			ClientePotencialService.ClientePrenominaComentarioGuardar(this.state).then((response) => {
				
				this.RefreshInit();
				
				this.setState({
					checkbox_rechazado_no: false,
					checkbox_rechazado_si: false,
					motivo_comentario: '',
				});
				
				setTimeout(() => {
					this.ActualizarListaPrenominaYPrenominaComentario(this.state.id_fecha_prenomina);
				}, 2000);
				
				this.showSnackBars('success', response.mensaje);
				
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
		
	};
	
	item_component = (r) => {
		return (
			<Fragment>
				<Typography component="span" variant="body2" color="textPrimary">Motivo / Comentario:</Typography>
				{' — '}
				<span className={'vertical-inline v-center'} style={{
					backgroundColor: r.color || "black",
					borderRadius: '100%',
					padding: '5px'
				}}/>
				{' — ' + r.comentario}
			</Fragment>
		);
	};
	
	ConfirmarAprobacionDePenomina = () => {
		try {
			
			if (!this.state.id_fecha_prenomina) {
				throw Object({
					status: false,
					mensaje: "Selecciona la fecha de la prenómina para realizar esta acción"
				});
			}
			
			let msg = `¿Deseas aprobar la prenómina del periodo ${this.state.periodo}?`;
			
			PopupService.Confirm(['Cancelar', 'Aprobar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
				
				if (r.button === 'Aprobar') {
					
					ClientePotencialService.PrenominaAprobarYBloquear(this.state).then((response) => {
						
						this.RefreshInit();
						
						this.setState({
							prenomina_base64: '',
							prenomina_base64Tipo: '',
							prenomina_archivo: '',
							prenomina_formato: '',
							comentario: '',
							
							checkbox_rechazado_no: false,
							checkbox_rechazado_si: false,
							motivo_comentario: '',
						});
						
						setTimeout(() => {
							this.ActualizarListaPrenominaYPrenominaComentario(this.state.id_fecha_prenomina);
						}, 2000);
						
						this.showSnackBars('success', response.mensaje);
						
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
					
				}
				
			});
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	cat_fecha_prenomina_status = () => {
		return this.state.id_cat_fecha_prenomina_status === 3;
	};
	
	cat_prenomina_status = () => {
		return this.state.id_cat_prenomina_status === 2;
	};
	
	disabledFieldPromotorPrenominaBolean = () => {
		
		let disabledFieldPromotorPrenomina = true;
		
		if (FieldsJs.inArray([1, 2, 3], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldPromotorPrenomina = false;
		}
		
		return disabledFieldPromotorPrenomina;
	};
	
	disabledFieldRecursosHumanosBolean = () => {
		
		let disabledFieldRecursosHumanos = true;
		
		if (FieldsJs.inArray([1, 2, 7], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldRecursosHumanos = false;
		}
		
		return disabledFieldRecursosHumanos;
	};
	
	editFechaPrenomina = (item, key) => {
		console.log(item, key);
		this.setState({
			fecha_inicio: new Date(DateFormat.FormatText(item.fecha_inicio)),
			fecha_fin: new Date(DateFormat.FormatText(item.fecha_fin)),
			id_fecha_prenomina: item.id_fecha_prenomina,
			editfecha: true
		});
	};
	
	cancelFechaPrenomina = (item, key) => {
		console.log(item, key);
		this.setState({
			fecha_inicio: null,
			fecha_fin: null,
			editfecha: false
		});
	};
	
	
	render() {
		
		const disabledFieldPromotorPrenomina = this.disabledFieldPromotorPrenominaBolean();
		
		const disabledFieldRecursosHumanos = this.disabledFieldRecursosHumanosBolean();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={6}>
								<Typography className={'px-20'}>
									<Fab size="small" color="secondary" aria-label="Actualizar" onClick={() => this.RefreshInit()} style={{marginRight: '15px'}}>
										<SyncOutlined/>
									</Fab>
									{this.props.titulo}
								</Typography>
							</Grid>
							<Grid item xs={((this.state.periodo && !this.cat_fecha_prenomina_status() && !disabledFieldPromotorPrenomina) ? 4 : 6)} align={"right"}>
								<Typography className={'px-22 padding-10-R'}>
									Periodo: {this.state.periodo || 'Elija en la lista'}
								</Typography>
							</Grid>
							{(this.state.periodo && !this.cat_fecha_prenomina_status() && !disabledFieldPromotorPrenomina) ? (
								<Grid item xs={2} align={"right"}>
									<Fab
										variant="extended"
										size="small"
										color="primary"
										aria-label="Aprobar prenómina"
										disabled={!this.cat_prenomina_status()}
										onClick={() => this.ConfirmarAprobacionDePenomina()}
										className={(!this.cat_prenomina_status() ? "" : "bg-green ") + "text-transform-none"}
									>
										<CheckOutlined/>
										Aprobar prenómina
									</Fab>
								</Grid>
							) : null}
						</Grid>
					</DialogTitle>
					
					<DialogContent style={{padding: "8px 8px"}}>
						
						<Grid container spacing={2} style={{height: "400px"}}>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{height: "calc(100% - 22px)"}}>
								
								<article style={{marginBottom: "10px"}}>
									
									<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
										
										<Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
											<div className={'w-100 vertical-inline'}>
												{this.state.editfecha ? (
													<div className={'v-end margin-10-R'}>
														<IconButton edge="end" aria-label="comments">
															<Cancel onClick={() => this.cancelFechaPrenomina()}/>
														</IconButton>
													</div>
												) : null}
												<div className={'v-center w-100'}>
													<MDDatePicker
														type={'range'}
														component={(
															<TextField
																fullWidth
																label="Fecha inicio y fin prenómina"
																value={(this.state.fecha_inicio && this.state.fecha_fin) ? (DateFormat.FormatText(this.state.fecha_inicio) + " al " + DateFormat.FormatText(this.state.fecha_fin)) : ''}
																readOnly
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			<IconButton
																				aria-label="Fecha inicio y fin prenómina">
																				<DateRangeOutlined/>
																			</IconButton>
																		</InputAdornment>
																	)
																}}
																disabled={disabledFieldRecursosHumanos}
															/>
														)}
														startDate={this.state.fecha_inicio}
														endDate={this.state.fecha_fin}
														onChange={(range) => {
															this.setState({
																fecha_inicio: range.startDate,
																fecha_fin: range.endDate,
															});
														}}
													/>
												</div>
											</div>
										</Grid>
										
										<Grid item xs={3} sm={3} md={3} lg={3} xl={3} align={'center'}>
											<Fab
												variant="extended"
												size="small"
												color="primary"
												className={(this.state.editfecha ? "btn-default-secundary" : "btn-default-primary") + " text-transform-none"}
												aria-label="Agregar"
												onClick={() => this.AgregarFechaPrenomina()}
												disabled={disabledFieldRecursosHumanos}
											>
												{this.state.editfecha ? 'Guardar' : 'Agregar'}
											</Fab>
										</Grid>
									
									</Grid>
								
								</article>
								
								<article
									style={{
										overflow: "auto",
										padding: "10px",
										height: "calc(100% - (55px + 50px))",
										background: "gray",
									}}
								>
									
									<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'} style={{paddingTop: 0, paddingBottom: 0}}>
											<List component="nav" aria-label="main mailbox folders" style={{marginTop: '-10px', marginBottom: '-10px'}}>
												{this.state.fecha_prenomina.map((value, index) => (
													<ListItem
														key={index}
														style={{
															backgroundColor: 'white',
															borderRadius: '5px',
															marginTop: '10px',
															marginBottom: '10px',
														}}
													>
														
														<ListItemIcon>
															{this.state.id_fecha_prenomina === value.id_fecha_prenomina ? (
																<div className={'v-center'}>
																	<IconButton edge="end" aria-label="comments">
																		<RadioButtonChecked onClick={() => this.SeleccionaFechaPrenomina(value)}
																		                    style={{color: (this.state.id_fecha_prenomina === value.id_fecha_prenomina) ? 'blue' : ''}}
																		/>
																	</IconButton>
																</div>
															) : (
																<div className={'v-center'}>
																	<IconButton edge="end" aria-label="comments">
																		<RadioButtonUnchecked onClick={() => this.SeleccionaFechaPrenomina(value)}
																		                      style={{color: (this.state.id_fecha_prenomina === value.id_fecha_prenomina) ? 'blue' : ''}}
																		/>
																	</IconButton>
																</div>
															)}
														</ListItemIcon>
														{/*{(this.state.id_fecha_prenomina === value.id_fecha_prenomina) ? (
															<ListItemSecondaryAction>
																<IconButton edge="end" aria-label="comments">
																	<CheckOutlined
																		style={{color: (this.state.id_fecha_prenomina === value.id_fecha_prenomina) ? 'blue' : ''}}
																	/>
																</IconButton>
															</ListItemSecondaryAction>
														) : null}*/}
														<ListItemText
															primary={(
																<Fragment>
																	<div className={'w-100 vertical-inline'}>
																		
																		{FieldsJs.Array(value.prenomina_seleccionada) ? (
																			<div className={'v-center'}>
																				<ModalImage
																					tipo={'view'} desing={null}
																					item={{
																						base64: value.prenomina_seleccionada.prenomina_base64 || '',
																						base64Tipo: value.prenomina_seleccionada.prenomina_base64Tipo || '',
																						archivo: value.prenomina_seleccionada.prenomina_archivo || '',
																						formato: value.prenomina_seleccionada.prenomina_formato || '',
																					}}
																				/>
																			</div>
																		) : (
																			<div className={'v-center'}>
																				<DateRangeOutlined
																					style={{color: (this.state.id_fecha_prenomina === value.id_fecha_prenomina) ? 'blue' : ''}}
																				/>
																			</div>
																		)}
																		
																		<div className={'v-center'}>
																			<Typography style={{padding: 0, margin: 0}} component={'p'}>
																				{DateFormat.FormatText(value.fecha_inicio) + ' al ' + DateFormat.FormatText(value.fecha_fin)}
																			</Typography>
																		</div>
																	</div>
																	
																	<p className={'ff-arial margin-0 vertical-inline v-center px-11'}
																	   style={{
																		   background: value.color || "black",
																		   padding: "3px 10px",
																		   borderRadius: "15px",
																		   lineHeight: "11px",
																		   color: "white"
																	   }}>{value.fecha_prenomina_status}</p>
																	{this.state.id_fecha_prenomina === value.id_fecha_prenomina ? (
																		<ListItemSecondaryAction>
																			<IconButton edge="end" aria-label="comments">
																				<Edit style={{color: 'orange'}} onClick={() => {
																					this.editFechaPrenomina(value, index);
																				}}/>
																			</IconButton>
																		</ListItemSecondaryAction>
																	) : null}
																</Fragment>
															
															)}
															style={{color: (this.state.id_fecha_prenomina === value.id_fecha_prenomina) ? 'blue' : ''}}
														/>
													
													</ListItem>
												))}
											</List>
										</Grid>
									
									</Grid>
								
								</article>
							
							</Grid>
							
							<Grid item xs={8} sm={8} md={8} lg={8} xl={8} style={{height: "calc(100% - 22px)"}}>
								
								<article className={'borde-punteado-lightgray-1 bg-image-chat b-r-5'} style={{
									overflow: "auto",
									padding: "10px",
									height: "100%",
									backgroundColor: "#f7f7f7"
								}} id={'scroll-chat-prenomina'}>
									
									{this.state.id_fecha_prenomina > 0 ? (
										<Grid container spacing={2}>
											{this.state.prenomina.map((value, index) => (
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
													
													<Grid container spacing={0}>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<Card style={{borderRadius: "20px 20px 0px 20px"}}>
																<CardContent style={{padding: "15px"}}>
																	<Grid container spacing={2}
																	      alignContent={"center"}
																	      alignItems={"center"}>
																		<Grid item xs={12} sm={12} md={12} lg={12}
																		      xl={12} align={'right'}
																		      style={{
																			      height: "0px",
																			      padding: "0px 0px 0px 0px"
																		      }}>
																			<div style={{
																				backgroundColor: value.color || "black",
																				borderRadius: '5px',
																				padding: '0 10px',
																				color: 'white',
																				margin: "10px"
																			}}
																			     className={'vertical-inline v-center'}>{value.prenomina_status}
																			</div>
																		</Grid>
																		<Grid item xs={2} sm={2} md={2} lg={2}
																		      xl={2}>
																			<Avatar style={{
																				width: 80,
																				height: 80
																			}}>
																				<div align={"center"}>
																					{Icono.get(value.prenomina_formato, '30px', '30px')}
																					<br/>
																					<ModalImage
																						tipo={'view'} desing={3}
																						item={{
																							base64: value.prenomina_base64 || '',
																							base64Tipo: value.prenomina_base64Tipo || '',
																							archivo: value.prenomina_archivo || '',
																							formato: value.prenomina_formato || '',
																						}}
																					/>
																				</div>
																			
																			</Avatar>
																		</Grid>
																		<Grid item xs={10} sm={10} md={10} lg={10}
																		      xl={10}>
																			<Typography component="span">
																				Fecha
																				alta: {DateFormat.FechaHoraFormatString(value.created_at)}
																			</Typography>
																			<Typography component="p">
																				Observaciones: {value.comentario || 'Sin observaciones'}
																			</Typography>
																		</Grid>
																	</Grid>
																</CardContent>
															</Card>
														</Grid>
														<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
														</Grid>
														<Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
															<Card style={{borderRadius: "0px 0px 20px 20px"}}>
																<CardContent style={{padding: "0px"}}>
																	<List>
																		{value.prenomina_comentario.map((r, k) => (
																			<Fragment key={k}>
																				{k > 0 ? <Divider variant="inset"
																				                  component="li"/> : null}
																				<ListItem alignItems="flex-start"
																				          style={{
																					          paddingBottom: "3px",
																					          paddingTop: "3px"
																				          }}>
																					<ListItemAvatar>
																						<Avatar
																							style={{
																								width: 34,
																								height: 34
																							}}
																							alt={r.nombre_completo_usuario}
																							src={CONFIG.src + r.foto}
																						/>
																					</ListItemAvatar>
																					<ListItemText
																						primary={r.nombre_completo_usuario}
																						secondary={this.item_component(r)}
																					/>
																				</ListItem>
																			</Fragment>
																		))}
																	</List>
																	{!(value.prenomina_comentario.length > 0) ? (
																		<VistaVacia
																			numero={null}
																			mensaje={'No se ha respondido a la prenomina'}
																			image={globo_escritura}
																			height={"50px"}
																			width={"50px"}
																			padding={"10px"}
																			paddingText={"10px"}
																		/>
																	) : null}
																</CardContent>
															</Card>
														</Grid>
													</Grid>
												
												</Grid>
											))}
											{!(this.state.prenomina.length > 0) ? (
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<VistaVacia
														numero={null}
														mensaje={'No se encontraron prenóminas para las fechas seleccionadas'}
														image={subir_confirmar_prenomina}
														height={"170px"}
														width={"170px"}
													/>
												</Grid>
											) : null}
										</Grid>
									) : (
										<VistaVacia
											numero={null}
											mensaje={'Selecciona la fecha de la prenómina'}
											image={subir_confirmar_prenomina}
											height={"170px"}
											width={"170px"}
										/>
									)}
								
								</article>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					{this.state.id_fecha_prenomina > 0 ? (
						<DialogActions style={{height: "130px"}}>
							<Grid container spacing={2}>
								<Grid item xs={4} align={'left'}>
									{this.state.id_fecha_prenomina > 0 ? (
										<article className={'borde-punteado-lightgray-1 bg-white padding-10'}
										         style={{marginTop: "-58px"}}>
											
											<Grid container spacing={2} alignItems={"flex-end"}
											      alignContent={"flex-end"}>
												
												<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'center'}>
													
													<BoxSelectFile
														// button={(
														// 	<AddOutlined/>
														// )}
														label={(
															<b className={'vertical-inline v-center text-blue'}>Prenómina</b>
														)}
														style={{
															color: 'red',
															height: '100px'
														}}
														item={{
															base64: this.state.prenomina_base64,
															base64Tipo: this.state.prenomina_base64Tipo,
															archivo: this.state.prenomina_archivo,
															formato: this.state.prenomina_formato,
														}}
														onChange={(r) => {
															this.handleChange({
																target: {
																	name: 'prenomina_base64',
																	value: r.base64
																}
															});
															this.handleChange({
																target: {
																	name: 'prenomina_base64Tipo',
																	value: r.base64Tipo
																}
															});
															this.handleChange({
																target: {
																	name: 'prenomina_archivo',
																	value: r.archivo
																}
															});
															this.handleChange({
																target: {
																	name: 'prenomina_formato',
																	value: r.formato
																}
															});
														}}
														showSnackBars={this.showSnackBars}
														disabled={disabledFieldRecursosHumanos || this.cat_fecha_prenomina_status()}
													/>
												
												</Grid>
												
												<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
													<TextField
														multiline
														fullWidth
														onChange={this.handleChange}
														type="text"
														margin="none"
														
														helperText=""
														name="comentario"
														label="Observaciones"
														value={this.state.comentario}
														disabled={disabledFieldRecursosHumanos || this.cat_fecha_prenomina_status()}
													/>
												</Grid>
												
												<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
													<Button onClick={() => this.close()} color="primary">
														Cerrar
													</Button>
												</Grid>
												<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'right'}>
													<Fab
														variant="extended"
														size="small"
														color="primary"
														className={"btn-default-primary text-transform-none"}
														aria-label="Subir"
														onClick={() => this.SubirPrenominaCliente()}
														disabled={disabledFieldRecursosHumanos || this.cat_fecha_prenomina_status()}
													>
														Subir
													</Fab>
												</Grid>
											
											</Grid>
										
										</article>
									) : null}
								</Grid>
								<Grid item xs={8}>
									{this.state.prenomina.length > 0 ? (
										<article className={'borde-punteado-lightgray-1 bg-white padding-10'}>
											
											<FormGroup row>
												<FormControlLabel
													control={
														<WarningCheckbox
															type="checkbox"
															name='checkbox_rechazado_no'
															checked={this.state.checkbox_rechazado_no}
															onChange={(e) => {
																this.handleChange(e);
																if (e.target.checked) {
																	this.handleChange({
																		target: {
																			type: 'checkbox',
																			checked: false,
																			name: 'checkbox_rechazado_si'
																		}
																	});
																}
																this.handleChange({
																	target: {
																		type: 'text',
																		value: 'La prenómina es correcta',
																		name: 'motivo_comentario'
																	}
																});
															}}
															value="checkbox_rechazado_no"
															color="default"
															disabled={disabledFieldPromotorPrenomina || this.cat_fecha_prenomina_status()}
														/>
													}
													label={'Pre aprobar'}
												/>
												<FormControlLabel
													control={
														<DangerCheckbox
															type="checkbox" name='checkbox_rechazado_si'
															checked={this.state.checkbox_rechazado_si}
															onChange={(e) => {
																this.handleChange(e);
																if (e.target.checked) {
																	this.handleChange({
																		target: {
																			type: 'checkbox',
																			checked: false,
																			name: 'checkbox_rechazado_no'
																		}
																	});
																}
																this.handleChange({
																	target: {
																		type: 'text',
																		value: '',
																		name: 'motivo_comentario'
																	}
																});
															}}
															value="checkbox_rechazado_si"
															color="primary"
															disabled={disabledFieldPromotorPrenomina || this.cat_fecha_prenomina_status()}
														/>
													}
													label={'No aprobar'}
												/>
											</FormGroup>
											
											<Grid container spacing={2} alignItems={"flex-end"}
											      alignContent={"flex-end"}>
												<Grid item xs={1} sm={1} md={1} lg={1} xl={1} align={'center'}>
													<Avatar
														style={{
															width: 34,
															height: 34
														}}
														alt={this.Usr.nombre_completo}
														src={CONFIG.src + this.Usr.foto}
													/>
												</Grid>
												<Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
													<TextField
														multiline
														fullWidth
														onChange={this.handleChange}
														type="text"
														margin="none"
														
														helperText=""
														name="motivo_comentario"
														label={(!!this.state.checkbox_rechazado_no && !!this.state.checkbox_rechazado_si) ? 'Observaciones' : (!this.state.checkbox_rechazado_si ? "Comentario" : "Motivo rechazo")}
														value={this.state.motivo_comentario}
														disabled={this.cat_fecha_prenomina_status()}
													/>
												</Grid>
												<Grid item xs={2} sm={2} md={2} lg={2} xl={2} align={'right'}>
													<Fab
														variant="extended"
														size="small"
														color="primary"
														className={"btn-default-primary text-transform-none"}
														aria-label="Enviar"
														onClick={() => this.EnviarPrenominaComentarioStatus()}
														disabled={this.cat_fecha_prenomina_status()}
													>
														Enviar
													</Fab>
												</Grid>
											</Grid>
										
										</article>
									) : null}
								</Grid>
							</Grid>
						</DialogActions>
					) : (
						<DialogActions>
							<Grid container spacing={0}>
								<Grid item xs={12} align={'left'}>
									<Button onClick={() => this.close()} color="primary">
										Cerrar
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					)}
				
				</Dialog>
			
			
			</div>
		);
	}
}

SubirPrenominaDeEmpleados.propTypes = {
	titulo: PropTypes.string.isRequired,
	id_cliente: PropTypes.number.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default SubirPrenominaDeEmpleados;
