import React, {Component, Fragment} from 'react';
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import bg_nueva_propuesta from '../../../../assets/img/icons/bg-nueva-propuesta.svg';
import bg_propuesta_cancelada from '../../../../assets/img/icons/bg-propuesta-cancelada.svg';
import bg_requiere_otra_propuesta from '../../../../assets/img/icons/bg-requiere-otra-propuesta.svg';
import bg_propuesta_aceptada from '../../../../assets/img/icons/bg-propuesta-aceptada.svg';

class TxtImgStatusPropuesta extends Component {
	
	bg_img = () => {
		let bg = {};
		switch (this.props.id_cat_ficha_tecnica_status) {
			case 2:
			case 3:
				bg = {
					backgroundImage: `url(${bg_nueva_propuesta})`
				};
				break;
			case 4:
				bg = {
					backgroundImage: `url(${bg_propuesta_cancelada})`
				};
				break;
			case 5:
				bg = {
					backgroundImage: `url(${bg_requiere_otra_propuesta})`
				};
				break;
			case 6:
			case 7:
			case 8:
			case 9:
			case 10:
				bg = {
					backgroundImage: `url(${bg_propuesta_aceptada})`
				};
				break;
			default: break;
		}
		return bg;
	};
	
	render() {
		const bg = this.bg_img();
		return (
			<Fragment>
				<div className={'w-auto'} align={'center'}>
					<div className={'bg-img-cover-x-center-y-center h-150-px w-150-px'} style={bg}/>
				</div>
				<Typography variant={'h5'} className={'text-center margin-30-T margin-10-B'} style={{color: "gray"}}>
					{this.props.texto}
				</Typography>
			</Fragment>
		);
	}
}

TxtImgStatusPropuesta.propTypes = {
	texto: PropTypes.string.isRequired,
	id_cat_ficha_tecnica_status: PropTypes.number.isRequired,
};
export default TxtImgStatusPropuesta;
