import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {ClienteService} from "../../../../services/ClienteService/ClienteService";
import ModalCuentaBancaria from "./ModalCuentaBancaria";
import {CONFIG} from "../../../../settings/Config/Config";

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar la cuenta bancaria de la empresa: ${item.nombre_comercial.toUpperCase()}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				ClienteService.Eliminar(item.id_cuenta_bancaria).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'}>Empresa</TableCell>
							<TableCell component="th" align={'left'}>Banco</TableCell>
							<TableCell component="th" align={'left'}>No. de cuenta</TableCell>
							<TableCell component="th" align={'left'}>Clabe</TableCell>
							<TableCell component="th" align={'left'}>RFC</TableCell>
							<TableCell component="th" align={'left'}>Apoderado</TableCell>
							<TableCell component="th" align={'left'}>Autorizados</TableCell>
							<TableCell component="th" align={'left'}>Domicilio registrado</TableCell>
							<TableCell component="th" align={'left'}>Sucursal de apertura</TableCell>
							<TableCell component="th" align={'left'}>Teléfono Registrado 1</TableCell>
							<TableCell component="th" align={'left'}>Teléfono Registrado 2</TableCell>
							<TableCell component="th" align={'left'}>No. de cliente</TableCell>
							<TableCell component="th" align={'left'}>Correo electrónico</TableCell>
							<TableCell component="th" align={'center'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<TableRow key={key}>
								<TableCell align={'center'} className={'text-uppercase'}>{item.nombre_comercial}</TableCell>
								<TableCell align={'left'}>{item.banco}</TableCell>
								<TableCell align={'left'}>{item.numero_cuenta}</TableCell>
								<TableCell align={'left'}>{item.clabe}</TableCell>
								<TableCell align={'left'} className={'text-uppercase'}>{item.rfc}</TableCell>
								<TableCell align={'left'}>{item.nombre_completo_apoderado}</TableCell>
								<TableCell align={'left'}>{item.autorizados.map((aut, ind) => (
									aut.nombre_completo_autorizado
								))}</TableCell>
								<TableCell align={'left'}>{item.domicilio}</TableCell>
								<TableCell align={'left'}>{item.sucursal_apertura}</TableCell>
								<TableCell align={'left'}>{item.telefono}</TableCell>
								<TableCell align={'left'}>{item.telefono_dos}</TableCell>
								<TableCell align={'left'}>{item.numero_cliente}</TableCell>
								<TableCell align={'left'}>{item.correo_electronico}</TableCell>
								<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
									<div className={'w-auto vertical-inline'}>
										<div className={'v-center'}>
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
												<IconButton aria-label="Eliminar"
												            onClick={() => this.eliminar(item)}>
													<DeleteOutlined/>
												</IconButton>
											</Tooltip>
										</div>
										<div className={'v-center'}>
											<ModalCuentaBancaria
												tipo={'edit'}
												item={item}
												lista_empresa={this.props.lista_empresa}
												RefreshList={this.props.RefreshList}
												RefreshList2={this.props.RefreshList2}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
										<div className={'v-center'}>
											<ModalCuentaBancaria
												tipo={'view'}
												item={item}
												lista_empresa={this.props.lista_empresa}
												RefreshList={this.props.RefreshList}
												RefreshList2={this.props.RefreshList2}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	lista_empresa: PropTypes.array.isRequired,
	RefreshList2: PropTypes.func.isRequired,
};

export default ListaTabla;
