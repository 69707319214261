import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {TicketsService} from "../../../../services/TicketsService/TicketsService";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Typography from "@material-ui/core/Typography";


import add_cliente_venta from '../../../../assets/img/icons/facturacion.svg';
import edit_cliente_venta from '../../../../assets/img/icons/facturacion.svg';
import view_cliente_venta from '../../../../assets/img/icons/facturacion.svg';
import agregar_producto_requerimiento_adicional from '../../../../assets/img/icons/facturacion.svg';
import carnet_identificacion from '../../../../assets/img/icons/facturacion.svg';
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import './../Tickets.css';
import {UsuariosService} from "../../../../services/_Sis/UsuariosService/UsuariosService";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import TicketSvg from "../../../../assets/img/icons/ticket.svg";
import DocSvg from "../../../../assets/img/icons/doc.svg";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {Cloud, Today} from "@material-ui/icons";
import ModalConfirmDate from "./ModalConfirmDate";
import img_fecha_confirmar from "../../../../assets/img/icons/programar.svg";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {DropzoneArea} from "material-ui-dropzone";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


const styles = {
	root: {
		background: "transparent",
		color: "black"
	},
	input: {
		color: "black"
	},
	input_number: {
		color: "black",
		textAlign: "right",
		alignText: "right",
	}
};


class ModalTicketsResponse extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_ticket: props.id_ticket || null,
			
			folio: '',
			
			key: null,
			id_usuario_solicitante: '',
			id_cat_area_usuario_solicitante: '',
			id_cat_area_solucion: '',
			id_usuario_asignado: '',
			id_cat_prioridad: '',
			id_empresa: '',
			fecha_solucion: null,
			requerimiento: '',
			url_ticket_requerimiento: '',
			is_solicitante: '',
			id_ticket_requerimiento_archivos: '',
			
			lista_usuario_solicitante: [],
			lista_tipo_usuario_solicitante: [],
			lista_area_solucion: [],
			lista_usuario_asignado: [],
			lista_prioridad: [],
			lista_empresa: [],
			lista_area: [],
			
			ticket_requerimiento: [],
			usuarios_notificados: [],
			archivos: [],
			files: [],
			
		};
	}
	
	handleChange = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	handleChangeCheckPS = (item, valor) => {
		let lista = FieldsJs.Copy(this.state.ticket_requerimiento);
		let cont = 0;
		lista.map((value) => {
			if (Number(item.id_ticket_requerimiento) === Number(value.id_ticket_requerimiento)) {
				value.isChecked = !value.isChecked;
			}
		})
		
		lista.map((value) => {
			if (value.isChecked) {
				cont++;
			}
		})
		
		this.setState({
			ticket_requerimiento: lista
		})
		console.log("ITEM", item);
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	ListaUsuario = (id_cat_tipo_area) => {
		UsuariosService.TipoUsuario(id_cat_tipo_area).then((response) => {
			this.setState({
				lista_usuario_asignado: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_usuario_asignado: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		console.log('USER', this.Usr.id_usuario, this.Usr.id_cat_tipo_usuario);
		this.init().then(r => {
			// this.ListaUsuario(8);
			
			this.setState({
				id_usuario_solicitante: this.Usr.id_usuario,
				id_cat_area_usuario_solicitante: this.Usr.id_cat_tipo_usuario,
				ticket_requerimiento: [],
				
			});
			
			this.init().then(r => {
				setTimeout(() => this.openModal());
			}).catch(e => {
				console.log(e);
			});
			
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			if (this.props.id_ticket > 0) {
				TicketsService.Detalles(this.props.id_ticket).then((response) => {
					let item = FieldsJs.Copy(response.data);
					let fecha_emitir_comprobante = item.fecha_emitir_comprobante ? moment(item.fecha_emitir_comprobante) : null;
					this.setState({
						id_ticket: item.id_ticket,
						
						folio: item.folio,
						id_cat_area_solucion: item.id_cat_area_solucion,
						id_usuario_asignado: item.id_usuario_asignado,
						id_cat_prioridad: item.id_cat_prioridad,
						id_empresa: item.id_empresa,
						fecha_solucion: item.fecha_solucion,
						requerimiento: item.requerimiento,
						is_solicitante: item.is_solicitante,
						ticket_requerimiento: item.requerimientos || [],
						url_ticket_requerimiento: item.url_ticket_requerimiento,
						usuarios_notificados: item.usuarios_notificados
						
					});
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false)
				});
			} else {
				this.setState({
					id_cat_area_solucion: 8,
					id_usuario_asignado: '',
					id_cat_prioridad: '',
					id_empresa: '',
					fecha_solucion: null,
					requerimiento: '',
					ticket_requerimiento: [],
					url_ticket_requerimiento: '',
					usuarios_notificados: []
				});
				
				resolve(true);
			}
		});
	};
	
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	guardarDocTimbradoDbx = (ftar) => {
		
		console.log("1 FTAR::: ", ftar);
		
		let files;
		
		return new Promise((resolve, reject) => {
			let band = 0;
			let band2 = 0;
			if (FieldsJs.Field(ftar)) {
				for (let i = 0; i < ftar.length; i++) {
					if (FieldsJs.Field(ftar[i]) === true) {
						band++;
					}
				}
				let archives = [];
				
				for (let i = 0; i < ftar.length; i++) {
					console.log("2 FTAR::: ", ftar);
					files = ftar[i];
					
					let ruta = '/Tickets/' + files.name;
					
					DropboxApi.Upload(ruta, files).then(function (response) {
						archives.push(response.path_display);
						
						band2++;
						
						console.log("DROPBOX UPLOAD: ", response, band, band2);
						
						if (band === band2) {
							resolve(archives);
						}
					}).catch(function (error) {
						reject(error);
					});
				}
			} else {
				band2++;
				if (band === band2) {
					resolve([]);
				}
			}
		});
	};
	
	save = () => {
		try {
			
			let tickets = this.state.ticket_requerimiento;
			console.log(tickets);
			let band = false;
			console.log('this.isSolicitante()', this.isSolicitante());
			if (this.isSolicitante()) {
				for (let i = 0; i < tickets.length; i++) {
					if (tickets[i].isRequirente === false && !FieldsJs.Field(tickets[i].observacion) && tickets[i].id_cat_ticket_status === 3) {
						band = true;
					}
				}
			} else {
				/*for (let i = 0; i < tickets.length; i++) {
					if (!tickets[i].isChecked && !FieldsJs.Field(tickets[i].observacion)) {
						band = true;
					}
				}*/
			}
			
			if (band) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Observación.'
				})
			}
			
			let msg = '¿Estás seguro de guardar los cambios del ticket: #' + this.state.folio + '?';
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
				if (r.button === 'Aceptar') {
					
					let band_archivo = false;
					
					for (let i = 0; i < tickets.length; i++) {
						if (typeof tickets[i].files !== 'undefined') {
							if (tickets[i].files.length > 0) {
								band_archivo = true;
							}
						}
					}
					
					if (band_archivo) {
						let band = 0;
						for (let i = 0; i < tickets.length; i++) {
							let guardar = new Promise((resolve, reject) => {
								this.guardarDocTimbradoDbx(tickets[i].files || null).then(response => {
									tickets[i].archivos = response;
									band++;
									resolve(response);
									console.log("DROPBOX UPLOAD: ", this.state);
								}).catch(error => {
									reject(error);
								})
							});
							
							guardar.then(response => {
								if (band === (tickets.length)) {
									TicketsService.Modificar(this.state).then((response) => {
										this.close();
										this.props.RefreshList();
										this.props.showSnackBars('success', response.mensaje);
									}).catch((error) => {
										this.props.showSnackBars('error', error.mensaje);
									});
								}
							});
						}
					} else {
						TicketsService.Modificar(this.state).then((response) => {
							this.close();
							this.props.RefreshList();
							this.props.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.props.showSnackBars('error', error.mensaje);
						});
					}
				}
			});
			
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
		
	};
	
	add = () => {
		this.setState({
			id_usuario_solicitante: this.Usr.id_usuario,
			id_cat_area_usuario_solicitante: this.Usr.id_cat_tipo_usuario,
			id_cat_area_solucion: '',
			id_usuario_asignado: '',
			id_cat_prioridad: '',
			id_empresa: '',
			fecha_solucion: null,
			requerimiento: '',
			url_ticket_requerimiento: ''
		});
		
		this.open();
	};
	
	add_asignacion = () => {
		try {
			
			
			let ticket_requerimiento = FieldsJs.Copy(this.state.ticket_requerimiento || []);
			let id_area_usuario = FieldsJs.Copy(this.state.id_cat_area_solucion || '');
			
			
			if (!this.state.id_cat_area_solucion) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Area de solución.'
				})
			} else {
				let band = false;
				if (ticket_requerimiento.length > 0) {
					for (let i = 0; i < ticket_requerimiento.length; i++) {
						if (Number(ticket_requerimiento[i].id_cat_area_solucion) != Number(id_area_usuario)) {
							band = true;
						}
					}
				}
				if (band) {
					throw Object({
						status: false, mensaje: 'Area de solución debe ser la misma en todos los requerimientos.'
					})
				}
			}
			
			
			if (!this.state.id_usuario_asignado) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Usuario asignado.'
				})
			}
			
			if (!this.state.id_cat_prioridad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Prioridad.'
				})
			}
			
			if (!this.state.id_empresa) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Empresa relacionada.'
				})
			}
			
			if (!this.state.requerimiento) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Requerimiento relacionada.'
				})
			}
			
			
			if (this.is_edited_ticket()) {
				for (let i = 0; i < ticket_requerimiento.length; i++) {
					let item = ticket_requerimiento[i];
					if (this.state.key === i) {
						//item.id_usuario_solicitante = this.state.id_usuario_solicitante || null;
						//item.id_cat_area_usuario_solicitante = this.state.id_cat_area_usuario_solicitante || null;
						item.id_cat_area_solucion = this.state.id_cat_area_solucion || null;
						item.id_usuario_asignado = this.state.id_usuario_asignado || null;
						item.id_cat_prioridad = this.state.id_cat_prioridad || null;
						item.id_empresa = this.state.id_empresa || null;
						item.fecha_solucion = this.state.fecha_solucion || null;
						item.requerimiento = this.state.requerimiento || null;
						item.url_ticket_requerimiento = this.state.url_ticket_requerimiento || null;
					}
				}
			} else {
				let item;
				item = {
					//id_usuario_solicitante: this.state.id_usuario_solicitante || null,
					//id_cat_area_usuario_solicitante: this.state.id_cat_area_usuario_solicitante || null,
					id_cat_area_solucion: this.state.id_cat_area_solucion || null,
					id_usuario_asignado: this.state.id_usuario_asignado || null,
					id_cat_prioridad: this.state.id_cat_prioridad || null,
					id_empresa: this.state.id_empresa || null,
					fecha_solucion: this.state.fecha_solucion || null,
					requerimiento: this.state.requerimiento || null,
					url_ticket_requerimiento: this.state.url_ticket_requerimiento || null
				};
				ticket_requerimiento.push(item);
			}
			
			this.setState({
				key: null,
				//id_usuario_solicitante: '',
				//id_cat_area_usuario_solicitante: '',
				// id_cat_area_solucion: '',
				//fecha_solucion: null,
				id_usuario_asignado: '',
				id_cat_prioridad: '',
				id_empresa: '',
				requerimiento: '',
				ticket_requerimiento: ticket_requerimiento,
				url_ticket_requerimiento: ''
			});
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
		
		console.log(this.state.ticket_requerimiento);
	};
	
	is_edited_ticket = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	edit = (item, key) => {
		try {
			
			this.setState({
				key: key,
				id_usuario_solicitante: item.id_usuario_solicitante || '',
				id_cat_area_usuario_solicitante: item.id_cat_area_usuario_solicitante || '',
				id_cat_area_solucion: item.id_cat_area_solucion || '',
				id_usuario_asignado: item.id_usuario_asignado || '',
				id_cat_prioridad: item.id_cat_prioridad || '',
				id_empresa: item.id_empresa || '',
				fecha_solucion: item.fecha_solucion || null,
				requerimiento: item.requerimiento || '',
				url_ticket_requerimiento: item.url_ticket_requerimiento || ''
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			id_usuario_solicitante: '',
			id_cat_area_usuario_solicitante: '',
			id_cat_area_solucion: '',
			id_usuario_asignado: '',
			id_cat_prioridad: '',
			id_empresa: '',
			fecha_solucion: null,
			requerimiento: '',
			url_ticket_requerimiento: ''
		});
	};
	
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el requerimiento?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_contactos = FieldsJs.Copy(this.state.ticket_requerimiento);
					
					lista_contactos.splice(key, 1);
					
					this.setState({
						ticket_requerimiento: lista_contactos
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_usuario_solicitante: item.id_usuario_solicitante || '',
			id_cat_area_usuario_solicitante: item.id_cat_area_usuario_solicitante || '',
			id_cat_area_solucion: item.id_cat_area_solucion === 1 ? 'fisica' : 'moral',
			id_usuario_asignado: item.id_usuario_asignado || '',
			id_cat_prioridad: item.id_cat_prioridad || '',
			id_empresa: item.id_empresa || '',
			fecha_solucion: item.fecha_solucion || null,
			requerimiento: item.requerimiento || '',
			url_ticket_requerimiento: item.url_ticket_requerimiento || ''
			
		});
		this.open();
	};
	
	isSolicitante = () => {
		return this.state.is_solicitante ? true : false;
	}
	
	is_completado = () => {
		let contResuelto = 0;
		let contCompletado = 0;
		this.state.ticket_requerimiento.map((item, key) => {
			if (item.id_cat_ticket_status === 3) {
				contResuelto++;
			}
			if (item.id_cat_ticket_status === 5) {
				contCompletado++;
			}
			
		});
		if (contCompletado === this.state.ticket_requerimiento.length) {
			return false;
		} else if (this.isSolicitante() && contResuelto > 0) {
			return true;
		} else if (this.isSolicitante() && contResuelto === 0) {
			return false;
		} else if (!this.isSolicitante() && contResuelto !== this.state.ticket_requerimiento.length) {
			return true;
		}
	}
	
	exportar = (url) => {
		try {
			if (!url) {
				throw Object({
					status: false, mensaje: 'URL Anexo no registrada.'
				})
			}
			
			window.open(url, '_blank');
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	confirmDate = (item) => {
		let msg = '¿Deseas confirmar la fecha de solicitud para solucionar el requerimiento?';
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Aceptar') {
				TicketsService.ConfirmarDate(item).then((response) => {
					this.close();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	}
	
	descargarZip = (path) => {
		//path = CONFIG.src + path;
		// let path = this.state.ficha_tecnica_expediente_trabajador[1].archivo[0].archivo;
		console.log("PATH ::: ", path);
		DropboxApi.GenerateZip(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	handleChangeDropzoneArea = (files) => {
		
		let ticket_requerimiento = FieldsJs.Copy(this.state.ticket_requerimiento);
		
		for (let i = 0; i < ticket_requerimiento.length; i++) {
			if (ticket_requerimiento[i].id_ticket_requerimiento === this.state.id_ticket_requerimiento_archivos) {
				ticket_requerimiento[i].files = files;
			}
		}
		
		this.setState({
			files: files,
			ticket_requerimiento: ticket_requerimiento
		});
		
		console.log("FILES: ", files, ticket_requerimiento);
		
	};
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'new':
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		
		const fecha_confirmar = {
			backgroundImage: `url(${img_fecha_confirmar})`,
			opacity: 1,
			height: '30px',
			width: '30px',
			marginTop: '2px',
			backgroundSize: 'contain'
		};
		
		const btn_add = {
			backgroundImage: `url(${add_cliente_venta})`,
			opacity: 1
		};
		
		const btn_edit = {
			backgroundImage: `url(${edit_cliente_venta})`,
			opacity: 1
		};
		
		const btn_view = {
			backgroundImage: `url(${view_cliente_venta})`,
			opacity: 1
		};
		
		const btn_agregar_producto_requerimiento_adicional = {
			backgroundImage: `url(${agregar_producto_requerimiento_adicional})`,
			opacity: 1
		};
		
		const lista_vacia_datos_cliente = {
			backgroundImage: `url(${carnet_identificacion})`,
			opacity: 0.5
		};
		
		const desglose = '';
		
		const {classes} = this.props;
		
		let disabledInputVenta = false;
		
		let disabledInputVentaEditar = false;
		
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				
				{/*{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_ticket}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}*/}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Typography variant={'h5'} className={'text-left text-gray'}>
							{'Ticket' + ' #' + this.state.folio}
						</Typography>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-100'}>
								
								<Grid container spacing={0} className={'h-100'}>
									{this.state.ticket_requerimiento.length > 0 ? (
										<Fragment>
											{this.state.ticket_requerimiento.length > 0 ? this.state.ticket_requerimiento.map((item, key) => (
												<div className={'w-100'} style={{padding: '0px 10px 10px 10px', marginBottom: '15px'}}>
													<div className={'margin-10 vertical-inline w-100'} style={{borderBottom: '1px solid rgba(100,100,100,0.2)', width: '100%'}}>
														<img className={'v-center'} src={TicketSvg} alt="add" width={100} height={80} style={{opacity: 0.7, marginRight: '15px'}}/>
														
														<div className={'v-center text-left'}>
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<Grid container spacing={0}>
																	
																	<Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
																		<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
																			Area de Solución:
																		</Typography>
																		<Typography style={{fontWeight: 'lighter'}}>
																			{item.area_solucion}
																		</Typography>
																	</Grid>
																	<Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
																		<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
																			Usuario Asignado:
																		</Typography>
																		<Typography style={{fontWeight: 'lighter'}}>
																			{item.usuario_asignado}
																		</Typography>
																	</Grid>
																	<Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
																		<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
																			Prioridad:
																		</Typography>
																		<Typography style={{fontWeight: 'lighter'}}>
																			{item.prioridad}
																		</Typography>
																	</Grid>
																	<Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
																		<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
																			Empresa(s) Relacionada(s):
																		</Typography>
																		<Typography style={{fontWeight: 'lighter'}}>
																			{/*{item.nombre_comercial}*/}
																			{item.empresas.map((item, key) => (
																				<div className={'vertical-inline b-r-20 margin-5 margin-0-L ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
																					<div className={'padding-3 padding-10-L padding-10-R v-center text-white px-14'}>
																						{item.nombre_comercial}
																					</div>
																				</div>
																			))
																			}
																		</Typography>
																	</Grid>
																	<Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
																		<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
																			Estatus:
																		</Typography>
																		<Typography className={'text-left'} style={{fontWeight: 'lighter'}}>
																			{item.ticket_status}
																		</Typography>
																	</Grid>
																	
																	{item.fecha_solicitud_activo === 1 ? (
																		<Grid item xs={9} sm={2} md={2} lg={2} xl={2}>
																			<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
																				Fecha Agendada:
																			</Typography>
																			<Typography className={'text-left'} style={{fontWeight: 'lighter'}}>
																				{item.fecha_solicitud}
																			</Typography>
																		</Grid>
																	) : null}
																	
																	{FieldsJs.inArray([1, 2, 12], this.Usr.id_cat_tipo_usuario) && item.confirmar_fecha === 0 && item.fecha_solicitud_activo === 1 ? (
																		<Fragment>
																			<Grid item xs={3} sm={7} md={4} lg={4} xl={4}>
																				<div>
																					<Typography className={'padding-10-T padding-10-B px-14 text-center'} style={{fontWeight: 'bold'}}>
																						¿Confirmar fecha agendada?
																					</Typography>
																				</div>
																				<div className={'w-100 text-center'}>
																					<div className={'vertical-inline'}>
																						<div
																							className={'v-center vertical-inline cursor-pointer'}
																							style={{
																								backgroundColor: '#efefef',
																								color: 'black',
																								width: "40px",
																								height: "40px",
																								borderRadius: "100%",
																								margin: "10px"
																							}}
																							onClick={() => {
																								this.confirmDate(item)
																							}}>
																							<div className={'v-center text-center w-100'}>
																								Si
																							</div>
																						</div>
																						
																						<div className={'v-center vertical-inline cursor-pointer'}>
																							<ModalConfirmDate
																								tipo={"add"}
																								item={item}
																								component={(
																									<div className={'w-100 text-center'}>
																										<div className={'vertical-inline'}>
																											<div
																												className={'v-center vertical-inline cursor-pointer'}
																												style={{
																													backgroundColor: '#efefef',
																													color: 'black',
																													width: "40px",
																													height: "40px",
																													borderRadius: "100%",
																													margin: "10px"
																												}}
																											>
																												<div className={'v-center text-center w-100'}>
																													No
																												</div>
																											</div>
																										</div>
																									</div>)}
																								showSnackBars={this.showSnackBars}
																								RefreshInit={this.RefreshInit}
																							/>
																						</div>
																					</div>
																				</div>
																			</Grid>
																		</Fragment>
																	) : FieldsJs.inArray([1, 2, 12], this.Usr.id_cat_tipo_usuario) && item.confirmar_fecha === 1 && item.fecha_solicitud_activo === 1 ? (
																		<Fragment>
																			<Grid item xs={3} sm={7} md={4} lg={4} xl={4}>
																				<div className={'w-100 text-left h-90-px'}>
																					<div className={'vertical-inline h-100'}>
																						<div className={'v-center vertical-inline cursor-pointer'}>
																							<ModalConfirmDate
																								tipo={"add"}
																								item={item}
																								showSnackBars={this.showSnackBars}
																								RefreshInit={this.RefreshInit}
																							/>
																						</div>
																					</div>
																				</div>
																			</Grid>
																		</Fragment>
																	) : null}
																	
																	<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																		<Grid container spacing={0}>
																			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																				<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
																					Requerimiento:
																				</Typography>
																				<Typography style={{fontWeight: 'lighter'}}>
																					{item.requerimiento}
																				</Typography>
																			</Grid>
																		</Grid>
																	</Grid>
																	
																	<Grid item xs={12} sm={10} md={4} lg={4} xl={4}>
																		<Grid container spacing={0}>
																			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																				<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
																					Documento(s) Anexo:
																				</Typography>
																				<div className={'vertical-inline'}>
																					{item.documentos.map((item, key) => (
																						<div className={'vertical-inline b-r-20 margin-5 margin-0-L'} key={key}>
																							<img className={'v-center cursor-pointer'} src={DocSvg} alt="add" width={40} height={40} style={{opacity: 0.7, marginRight: '15px'}}
																							     onClick={() => this.descargarZip(item.archivo)}/>
																						</div>
																					))
																					}
																					{item.documentos.length <= 0 ? 'Sin registrar' : ''}
																				</div>
																			</Grid>
																		</Grid>
																	</Grid>
																	
																	<Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
																		<Grid container spacing={0}>
																			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																				<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
																					URL Anexo:
																				</Typography>
																				
																				{/*<Tooltip  placement={"top"} onClick={() => this.exportar(item.url_ticket_requerimiento)}
																				         title="URL Anexo">
																					<IconButton aria-label="URL Anexo">
																						<Cloud/>
																					</IconButton>
																				</Tooltip>*/}
																				<div className={'vertical-inline'}>
																					<div className={'v-center'} style={{cursor: 'pointer', paddingRight: '15px'}} onClick={() => this.exportar(item.url_ticket_requerimiento)}>
																						<Cloud/>
																					</div>
																					<div className={'v-center'}>
																						<Typography style={{fontWeight: 'lighter', fontSize: '12px'}}>
																							{item.url_ticket_requerimiento ? item.url_ticket_requerimiento : 'Sin registrar'}
																						</Typography>
																					</div>
																				</div>
																			</Grid>
																			{item.documentos.map((itema, keya) => (
																				<Fragment key={keya}>
																					{FieldsJs.Field(itema.url_ticket_requerimiento) ? (
																						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																							<div className={'vertical-inline'}>
																								<div className={'v-center'} style={{cursor: 'pointer', paddingRight: '15px'}} onClick={() => this.exportar(itema.url_ticket_requerimiento)}>
																									<Cloud style={{color: "#3e823b"}}/>
																								</div>
																								<div className={'v-center'}>
																									<Typography style={{fontWeight: 'lighter', fontSize: '12px'}}>
																										{itema.url_ticket_requerimiento ? itema.url_ticket_requerimiento : 'Sin registrar'}
																									</Typography>
																								</div>
																							</div>
																						</Grid>
																					) : null}
																				</Fragment>
																			))}
																		</Grid>
																	</Grid>
																	
																	{this.state.usuarios_notificados.length > 0 ? (
																		<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																			<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
																				Usuarios Notificados:
																			</Typography>
																			
																			{this.state.usuarios_notificados.map((item, key) => (
																				<div key={key} className={'vertical-inline margin-15-R'}>
																					<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
																					     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
																					</div>
																					<Typography variant={'h5'} className={'v-center padding-15-L margin-0 text-black px-13'}>
																						{item.nombre_completo}
																					</Typography>
																				</div>
																			))}
																		</Grid>
																	) : null}
																	
																	{item.confirmar_fechas.length > 0 ? (
																		<Fragment>
																			<Divider/>
																			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																				{item.confirmar_fechas.map((value, key) => (
																					<Fragment>
																						<ListItem
																							alignItems="flex-center"
																							style={{padding: 0}}>
																							<ListItemAvatar>
																								{/*<div style={fecha_confirmar}></div>*/}
																								<Today style={{height: '30px', width: '30px'}}/>
																							</ListItemAvatar>
																							<ListItemText
																								style={{
																									fontSize: '12px !important',
																									margin: 0,
																									padding: 0
																								}}
																								primary={<Typography type="body2" style={{fontSize: '11px', margin: 0, padding: 0}}>
																									{value.fecha_solucion}</Typography>}
																								secondary={
																									<Fragment>
																										<Typography
																											component="span"
																											variant="body2"
																											color="textPrimary"
																											style={{fontSize: '11px'}}
																										>
																											Motivo:
																										</Typography>
																										{" — " + value.motivo}
																									</Fragment>
																								}
																							/>
																						</ListItem>
																						<Divider/>
																					</Fragment>
																				))}
																			</Grid>
																		</Fragment>
																	) : null}
																	
																	{item.observaciones.length > 0 ? (
																		<Fragment>
																			<Divider/>
																			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																				{item.observaciones.map((value, key) => (
																					<Fragment>
																						<ListItem
																							alignItems="flex-center"
																							style={{padding: 0}}>
																							<ListItemAvatar>
																								<Avatar
																									alt={value.nombre_completo_usuario}
																									src={CONFIG.foto_default}
																									style={{
																										height: '30px',
																										width: '30px',
																										marginTop: '2px'
																									}}
																								/>
																							</ListItemAvatar>
																							<ListItemText
																								style={{
																									fontSize: '12px !important',
																									margin: 0,
																									padding: 0
																								}}
																								primary={<Typography type="body2" style={{fontSize: '10px', margin: 0, padding: 0}}>
																									{value.nombre_completo_usuario}</Typography>}
																								secondary={
																									<Fragment>
																										<Typography
																											component="span"
																											variant="body2"
																											color="textPrimary"
																											style={{fontSize: '10px'}}
																										>
																											Observación:
																										</Typography>
																										{" — " + value.observacion}
																									</Fragment>
																								}
																							/>
																						</ListItem>
																						<Divider/>
																					</Fragment>
																				))}
																			</Grid>
																		</Fragment>
																	) : null}
																	
																	{(item.id_cat_ticket_status !== 3 && !this.isSolicitante() || item.id_cat_ticket_status === 3 && this.isSolicitante()) && item.id_cat_ticket_status !== 5 || this.is_root() ? (
																		<Fragment>
																			<Grid item xs={12} sm={10} md={10} lg={10} xl={10} style={{marginTop: '10px'}}>
																				<TextField
																					multiline
																					required={this.isSolicitante() && item.isRequirente === false}
																					fullWidth
																					variant={"outlined"}
																					name={"observacion__" + key}
																					onChange={(e) => this.handleChange('ticket_requerimiento#' + key, 'observacion', e.target.value)}
																					type="text"
																					margin="none"
																					helperText=""
																					label="Observación"
																					value={item.observacion || ''}
																				/>
																			</Grid>
																		</Fragment>
																	) : null}
																	
																	{!this.state.is_solicitante ?
																		!this.isSolicitante() ? (
																			<Fragment>
																				<Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
																					{item.id_cat_ticket_status !== 3 && item.id_cat_ticket_status !== 5 || this.is_root() ? (
																						<Fragment>
																							<div>
																								<Typography className={'text-center'} style={{fontWeight: 'lighter'}}>
																									¿Está resuelto?
																								</Typography>
																							</div>
																							<div className={'w-100 text-center'}>
																								<div className={'vertical-inline'}>
																									<div
																										className={'v-center vertical-inline cursor-pointer'}
																										style={{
																											backgroundColor: item.isChecked ? '#2c5eb5' : '#efefef',
																											color: item.isChecked ? 'white' : 'black',
																											width: "40px",
																											height: "40px",
																											borderRadius: "100%",
																											margin: "10px"
																										}}
																										onClick={() => {
																											if (!item.isChecked) {
																												this.handleChange('ticket_requerimiento#' + key, 'isChecked', true);
																											} else {
																												this.handleChange('ticket_requerimiento#' + key, 'isChecked', null);
																											}
																										}}>
																										<div className={'v-center text-center w-100'}>
																											Si
																										</div>
																									</div>
																								</div>
																							</div>
																						
																						</Fragment>
																					) : null}
																				</Grid>
																				
																				{item.id_cat_ticket_status !== 3 && item.id_cat_ticket_status !== 5 || this.is_root() ? (
																					
																					<Fragment>
																						<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
																							<div className={'vertical-inline h-100 w-100'}>
																								<div className={'v-center h-100'}>
																									<FormControlLabel
																										className={'text-black px-12 text-bold'}
																										control={
																											<Checkbox
																												type="checkbox"
																												onChange={(e) => this.handleChange('ticket_requerimiento#' + key, 'check_anexar_url', !e.target.value)}
																												color="primary"
																												name={"check_anexar_url__" + key}
																												checked={item.check_anexar_url}
																												value={item.check_anexar_url || null}
																											/>
																										}
																										label={<div style={{fontWeight: 'bold'}}>Anexar URL.</div>}
																									/>
																								</div>
																							</div>
																						</Grid>
																						
																						<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
																							<div className={'vertical-inline h-100 w-100'}>
																								<div className={'v-center h-100'}>
																									<FormControlLabel
																										className={'text-black px-12 text-bold'}
																										control={
																											<Checkbox
																												type="checkbox"
																												onChange={(e) => {
																													this.handleChange('ticket_requerimiento#' + key, 'check_anexar_documento', !e.target.value);
																													this.setState({id_ticket_requerimiento_archivos: item.id_ticket_requerimiento});
																												}}
																												color="primary"
																												name={"check_anexar_documento__" + key}
																												checked={item.check_anexar_documento}
																												value={item.check_anexar_documento || null}
																											/>
																										}
																										label={<div style={{fontWeight: 'bold'}}>Anexar documento.</div>}
																									/>
																								</div>
																							</div>
																						</Grid>
																						
																						{item.check_anexar_url ? (
																							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																								<Grid container spacing={1} alignItems={"flex-end"} className={'margin-10-B'}>
																									<Grid item className={'w-30-px'}>
																										<Cloud className={'w-100 text-gray'}/>
																									</Grid>
																									<Grid item className={'w-100-30-px'}>
																										<TextField type={'text'}
																										           fullWidth
																										           name={"url_ticket_requerimiento_archivo__" + key}
																										           onChange={(e) => this.handleChange('ticket_requerimiento#' + key, 'url_ticket_requerimiento_archivo', e.target.value)}
																										           label="URL Anexo" autoComplete={'off'}
																										           value={item.url_ticket_requerimiento_archivo}
																										
																										/>
																									</Grid>
																								</Grid>
																							</Grid>
																						) : null}
																						
																						{item.check_anexar_documento && Number(item.id_ticket_requerimiento) === Number(this.state.id_ticket_requerimiento_archivos) ? (
																							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																								<div className={'w-100'}>
																									<DropzoneArea
																										onChange={this.handleChangeDropzoneArea.bind(this)}
																										open={item.check_anexar_documento}
																										filesLimit={5}
																										dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
																										//acceptedFiles={['text/xml', 'application/pdf']}
																										showPreviews={false}
																										maxFileSize={5000000}
																										showAlerts={false}
																										showFileNames={true}
																										showFileNamesInPreview={true}
																									/>
																								</div>
																							</Grid>
																						) : null}
																					</Fragment>
																				) : null}
																			
																			</Fragment>
																		) : null : (<Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
																			{item.id_cat_ticket_status === 3 || this.is_root() ? (
																				<Fragment>
																					<div>
																						<Typography className={'text-center'} style={{fontWeight: 'lighter'}}>
																							¿Está resuelto?
																						</Typography>
																					</div>
																					<div className={'w-100 text-center'}>
																						<div className={'vertical-inline'}>
																							<div
																								className={'v-center vertical-inline cursor-pointer'}
																								style={{
																									backgroundColor: (item.isRequirente ? '#2c5eb5' : '#efefef'),
																									color: item.isRequirente ? 'white' : 'black',
																									width: "40px",
																									height: "40px",
																									borderRadius: "100%",
																									margin: "10px"
																								}}
																								onClick={() => {
																									if (!item.isRequirente) {
																										this.handleChange('ticket_requerimiento#' + key, 'isRequirente', true);
																									}
																								}}>
																								<div className={'v-center text-center w-100'}>
																									Si
																								</div>
																							</div>
																							<div
																								className={'v-center vertical-inline cursor-pointer'}
																								style={{
																									backgroundColor: (item.isRequirente === false ? '#2c5eb5' : '#efefef'),
																									color: item.isRequirente === false ? 'white' : 'black',
																									width: "40px",
																									height: "40px",
																									borderRadius: "100%",
																									margin: "10px",
																								}}
																								onClick={() => {
																									if (item.isRequirente || !item.isRequirente) {
																										this.handleChange('ticket_requerimiento#' + key, 'isRequirente', false);
																									}
																								}}>
																								<div className={'v-center text-center w-100'}>
																									No
																								</div>
																							</div>
																						</div>
																					</div>
																				</Fragment>
																			) : null}
																		</Grid>)}
																</Grid>
															</Grid>
														</div>
													</div>
												</div>
											)) : null}
										</Fragment>
									) : (
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-center'}>
											<VistaVacia numero={0} height={'100px'} width={'100px'}
											            padding={'30px'}
											            mensaje={'Sin requerimientos registrados.'}/>
										
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									{!this.is_view() ? "Cancelar" : "Cerrar"}
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() && this.is_completado() ? (
									<Button onClick={() => this.save()} color="primary">
										{this.is_edit() > 0 ? 'Actualizar' : 'Guardar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalTicketsResponse.propTypes = {
	id_ticket: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	is_solicitante: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	classes: PropTypes.object.isRequired,
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};


export default withStyles(styles)(ModalTicketsResponse);
