import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {AttachMoney, DeleteOutlined, HdrWeakOutlined} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {CONFIG} from "../../../../settings/Config/Config";
import {Dropbox} from "dropbox";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {QuinielaService} from "../../../../services/QuinielaService/QuinielaService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";


class ModalPagos extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			tipo: props.tipo,
			band_ingreso_egreso: props.tipo === 'add' ? 1 : 0,
			id_quiniela: props.id_quiniela || '',
			
			monto: (props.item || '').monto,
			saldo: 0,
			fecha_pago: null,
			observacion: '',
			archivo: '',
			
			comprobante_base64: '',
			comprobante_base64Tipo: '',
			comprobante_archivo: '',
			comprobante_formato: '',
			file: '',
			
			activo: true,
			
			lista_miembros: [],
			miembros: []
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	
	save = () => {
		try {
			
			/*if (!this.state.importe) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Importe.'
				})
			}
			
			if (!this.state.fecha_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Fecha pago.'
				})
			}
			
			if (!this.state.file) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Comprobante.'
				})
			}*/
			
			/*this.getBase64(this.state.file[0], true).then(response => {
				this.setState({
					archivo: response.path_display
				});*/
			
			console.log("DESPUES DE GUARDAR:::: ", this.state);
			QuinielaService.PagoGuardar(this.state).then((response) => {
				this.props.showSnackBars('success', response.mensaje);
				this.modalClose();
				this.props.RefreshList();
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
			//});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	getBase64 = (e, dropzone) => {
		return new Promise((resolve) => {
			const accessToken = CONFIG.DROPBOX_TOKEN;
			let files;
			if (dropzone) {
				files = e;
			} else {
				console.log(e.target);
				files = e.target.files;
			}
			
			const dbx = new Dropbox({
				accessToken,
				fetch
			});
			
			console.log("DROPBOX: ", '/Estados de Cuenta/' + this.state.item.id_adquisicion + this.state.item.propietario + '/' + files.name);
			dbx.filesUpload({path: '/Estados de Cuenta/' + this.state.item.id_adquisicion + '-' + this.state.item.propietario + '/' + files.name, contents: files})
				.then(function (response) {
					console.log("DROPBOX UPLOAD: ", response);
					resolve(response);
				})
				.catch(function (error) {
					console.error(error);
				});
		});
	};

	add = () => {
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			monto: '',
			fecha_pago: null,
			comprobante_base64: '',
			comprobante_base64Tipo: '',
			comprobante_archivo: '',
			comprobante_formato: '',
			file: '',
			archivo: '',
			id_quiniela_miembro: '',
			importe: '',
			observacion: '',
			miembros: [],
			activo: 1,
		});
		
		this.openModal();
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_admin = () => {
		return FieldsJs.inArray([2], this.Usr.id_cat_tipo_usuario);
	};
	
	is_view = () => {
		return this.state.tipo === 'view';
	};
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	handleChangeCheckPS = (item, valor) => {
		let lista = FieldsJs.Copy(this.state.lista_miembros);
		let bandTodo = false;
		let cont = 0;
		lista.map((value) => {
			if (Number(item.id_quiniela) === Number(value.id_quiniela)) {
				value.check = !value.check;
			}
		});
		
		lista.map((value) => {
			if (value.check) {
				cont++;
			}
		});
		
		console.log('CONT', cont);
		if (cont === lista.length) {
			this.setState({
				checkPS: true
			})
		} else if (cont < lista.length) {
			this.setState({
				checkPS: false
			})
		}
		
		this.setState({
			lista_miembros: lista
		})
		console.log("ITEM", item);
	};
	
	add_datos_ingreso_egreso = () => {
		
		let lista_partidas = FieldsJs.Copy(this.state.lista_miembros || []);
		let band = false;
		let importe = FieldsJs.Copy(this.state.importe);
		let fecha_pago = DateFormat.FormatSql(FieldsJs.Copy(this.state.fecha_pago));
		let observacion = FieldsJs.Copy(this.state.observacion);
		
		try {
			if (!this.state.importe) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Importe.'
				})
			}
			
			if (!this.state.fecha_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Fecha pago.'
				})
			}
			
			band = true;
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
		
		if (band) {
			lista_partidas.map((item) => {
				if (item.check) {
					item.importe = Number(importe);
					item.fecha_pago = fecha_pago;
					item.observacion = observacion;
					item.check = false;
				}
			});
			
			this.setState({
				lista_miembros: lista_partidas,
				checkPS: false
			});
		}
		
		console.log("this.state.lista_facturacion_producto", this.state.lista_miembros);
	};
	
	handleChangeCheckPST = (item, valor) => {
		
		let lista = this.state.lista_miembros;
		for (let i = 0; i < lista.length; i++) {
			lista[i].check = !this.state.checkPS;
		}
		
		this.setState({
			checkPS: !this.state.checkPS
		});
		
		this.setState({
			lista_miembros: lista
		});
		
		console.log(this.state.lista_miembros);
	};
	
	eliminar_miembro = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.miembros);
		arr_temp.splice(key, 1);
		this.setState({
			miembros: arr_temp
		});
	};
	
	agregar_miembro = (e) => {
		var arr_temp = FieldsJs.Copy(this.state.miembros);
		var lista_miembros = FieldsJs.Copy(this.props.lista_miembros);
		let id_quiniela_miembro = e.target.value;
		console.log("id_quiniela_miembro", id_quiniela_miembro);
		for (let i = 0; i < lista_miembros.length; i++) {
			if (Number(lista_miembros[i].id_quiniela_miembro) === Number(id_quiniela_miembro)) {
				arr_temp.push({id_quiniela_miembro:lista_miembros[i].id_quiniela_miembro, nombre:lista_miembros[i].nombre_completo_miembro});
			}
		}
		
		this.setState({
			miembros: arr_temp,
			id_quiniela_miembro: ''
		});
	};
	
	render() {
		
		const classes = {};
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span style={{cursor: 'pointer'}} children={this.props.componente} onClick={() => this.add()}/>
					</Fragment>
				) : (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Registro de Pagos">
						<IconButton aria-label="Registro de Pagos" onClick={() => this.add()}>
							<AttachMoney/>
						</IconButton>
					</Tooltip>
				)}
				
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>Registro de {this.state.tipo === 'add' ? 'Ingreso' : 'Egreso'}</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'b-r-5 padding-10'}>
								
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'px-14 margin-0'} style={{color: '#3e3e3e'}}>Agrega un nuevo {this.state.tipo === 'add' ? 'ingreso' : 'egreso'}: </Typography>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HdrWeakOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth>
													<InputLabel>Miembro(s)</InputLabel>
													<Select
														native
														value={this.state.id_quiniela_miembro}
														onChange={(e) => {
															this.handleChange(e);
															this.agregar_miembro(e);
														}}
														name="id_quiniela_miembro"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.props.lista_miembros.map(option => (
															<option key={option.id_quiniela_miembro}
															        value={option.id_quiniela_miembro}>
																{option.nombre_completo_miembro}
															</option>
														))}
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									{this.state.miembros.length > 0 ? (
										<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
											<div className={'w-100 text-left'}>
												{this.state.miembros.map((item, key) => (
													<div className={'vertical-inline b-r-20 margin-5 bg-danger'} key={key}>
														<div className={'padding-3 padding-10-L padding-10-R v-center text-white'}>
															{item.nombre}
														</div>
														<div className={'padding-3 padding-10-R v-center text-white'}>
															<DeleteOutlined className={'px-18'} onClick={() => this.eliminar_miembro(key)}/>
														</div>
													</div>)
												)}
											</div>
										</Grid>
									) : ''}
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<TextField
											type={'text'}
											fullWidth
											required
											name="importe"
											onChange={this.handleChange}
											label="Importe"
											autoComplete={'off'}
											value={this.state.importe}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<KeyboardDatePicker
											fullWidth
											clearable
											format={'dd/MM/yyyy'}
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											label="Fecha Pago"
											value={this.state.fecha_pago}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_pago');
											}}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											disabled={this.state.tipo === 'view'}
											required
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											type={'text'}
											fullWidth
											rows={2}
											name="observacion"
											onChange={this.handleChange}
											label="Observación"
											autoComplete={'off'}
											value={this.state.observacion}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							
							</Grid>
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							Cerrar
						</Button>
						
						<Button onClick={() => this.save()} color="primary">
							Agregar
						</Button>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPagos.propTypes = {
	id_quiniela: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	tipo: PropTypes.string.isRequired,
	componente: PropTypes.element,
	lista_miembros: PropTypes.array,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default IntegrationNotistack(ModalPagos);
