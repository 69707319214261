import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {ComprobantesNominaService} from '../../../../services/ComprobantesNominaService/ComprobantesNominaService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";
import ModalEmpresa from "../../Cat/Empresa/Includes/ModalEmpresa";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {Business, DeleteOutlined, Group, HdrStrong, SpeakerNotes, Sync, Phone, Payment, Person, AttachMoney, DateRangeOutlined} from "@material-ui/icons";
import {DropzoneArea} from "material-ui-dropzone";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import DocSvg from "../../../../assets/img/icons/doc.svg";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import moment from "moment";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";


class ModalComprobantesNomina extends Component {
	
	Usr = {};
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_comprobante_nomina: (props.item || '').id_comprobante_nomina,
			tipo: props.tipo,
			id_empresa: '',
			ejercicio: '',
			periodo: '',
			fecha_inicio: null,
			fecha_fin: null,
			id_cat_banco: '',
			cuenta_bancaria: '',
			observaciones: '',
			estatus: '',
			files: [],
			archivos: [],
			fecha_alta: null,
			importe: '',
			check_recargo: false,
			recargo: '',
			id_empleado: '',
			id_cat_periodicidad_pago: '',
			
			lista_empresa: this.props.ListEmpresa || [],
			lista_ejercicio: [
				{anio: 2022},
				{anio: 2021},
				{anio: 2020},
				{anio: 2019},
				{anio: 2018},
				{anio: 2017},
				{anio: 2016},
				{anio: 2015},
				{anio: 2014},
				{anio: 2013},
				{anio: 2012},
				{anio: 2011},
				{anio: 2010},
				{anio: 2009},
				{anio: 2008},
				{anio: 2007},
				{anio: 2006},
				{anio: 2005},
			],
			lista_periodo: [
				{mes: 'Enero'},
				{mes: 'Febrero'},
				{mes: 'Marzo'},
				{mes: 'Abril'},
				{mes: 'Mayo'},
				{mes: 'Junio'},
				{mes: 'Julio'},
				{mes: 'Agosto'},
				{mes: 'Septiembre'},
				{mes: 'Octubre'},
				{mes: 'Noviembre'},
				{mes: 'Diciembre'}
			],
			lista_banco: [],
			lista_tipo_documento: [],
			lista_empleado: [],
			lista_cat_periodicidad_pago: [],
		};
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	ListBanco = () => {
		return new Promise((resolve, reject) => {
			CatService.ListBanco().then((response) => {
				this.setState({
					lista_banco: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	ListEmpleado = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpleado().then((response) => {
				this.setState({
					lista_empleado: response.data
				});
				resolve(response);
			}).catch(error => {
				this.setState({
					lista_empleado: []
				});
				reject(error);
			})
		});
	};
	
	ListPeriodicidadPago = () => {
		return new Promise((resolve, reject) => {
			CatService.ListPeriodicidadTipo().then((response) => {
				this.setState({
					lista_cat_periodicidad_pago: response.data
				});
				resolve(response);
			}).catch(error => {
				this.setState({
					lista_cat_periodicidad_pago: []
				});
				reject(error);
			})
		});
	};
	
	RefreshListEmpresa = (id_empresa) => {
		this.props.ListEmpresa().then((response) => {
			this.setState({
				id_empresa: id_empresa
			});
		});
	};
	
	guardarDocTimbradoDbx = () => {
		
		let files = this.state.files;
		
		return new Promise((resolve, reject) => {
			let band = files.length;
			let band2 = 0;
			let archives = this.state.archivos;
			let file;
			
			for (let i = 0; i < files.length; i++) {
				file = files[i];
				
				let date = Date.parse(new Date());
				
				let ruta = '/Comprobantes_Nomina/' + date + '_' + this.state.fecha_alta + '/' + file.name;
				
				DropboxApi.Upload(ruta, file).then(function (response) {
					archives.push({archivo: response.path_display});
					
					band2++;
					
					console.log("DROPBOX UPLOAD: ", response, band, band2);
					
					if (band === band2) {
						resolve(archives);
					}
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	save = () => {
		
		try {
			
			if (!FieldsJs.Field(this.state.id_empresa)) {
				throw Object({
					status: false, mensaje: 'Campo empresa es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.id_empleado)) {
				throw Object({
					status: false, mensaje: 'Campo id_empleado es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.id_cat_periodicidad_pago)) {
				throw Object({
					status: false, mensaje: 'Campo periodo de pago es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.fecha_alta)) {
				throw Object({
					status: false, mensaje: 'Campo fecha de pago es requerido.'
				})
			}
			/*if (!FieldsJs.Field(this.state.cuenta_bancaria)) {
				throw Object({
					status: false, mensaje: 'Campo cuenta bancaria es requerido.'
				})
			}*/
			/*if (this.state.check_recargo && !FieldsJs.Field(this.state.recargo)) {
				throw Object({
					status: false, mensaje: 'Campo recargo / actualización es requerido.'
				})
			}*/
			
			if (this.state.files.length > 0) {
				
				let guardar = new Promise((resolve, reject) => {
					showSpinner('spinner');
					this.guardarDocTimbradoDbx(this.state.files).then(response => {
						this.setState({
							archivos: response,
						});
						resolve(response);
						hideSpinner('spinner', 500);
						console.log("DROPBOX UPLOAD: ", this.state);
					}).catch(error => {
						hideSpinner('spinner', 500);
						reject(error);
					})
				});
				
				guardar.then(response => {
					if (this.state.id_comprobante_nomina > 0) {
						ComprobantesNominaService.Modificar(this.state).then((response) => {
							this.props.RefreshList();
							this.modalClose();
							this.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.showSnackBars('error', error.mensaje);
						});
					} else {
						ComprobantesNominaService.Agregar(this.state).then((response) => {
							this.props.RefreshList();
							this.modalClose();
							this.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.showSnackBars('error', error.mensaje);
						});
					}
				});
				
			} else {
				if (this.state.id_comprobante_nomina > 0) {
					ComprobantesNominaService.Modificar(this.state).then((response) => {
						this.props.RefreshList();
						this.modalClose();
						this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
					});
				} else {
					ComprobantesNominaService.Agregar(this.state).then((response) => {
						this.props.RefreshList();
						this.modalClose();
						this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
					});
				}
			}
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		this.props.ListEmpresa();
		this.ListBanco();
		this.ListEmpleado();
		this.ListPeriodicidadPago();
		this.setState({
			id_comprobante_nomina: '',
			id_empleado: '',
			id_cat_periodicidad_pago: '',
			id_empresa: '',
			ejercicio: '',
			periodo: '',
			estatus: '',
			id_cat_banco: '',
			cuenta_bancaria: '',
			observaciones: '',
			importe: '',
			check_recargo: false,
			recargo: '',
			files: [],
			archivos: [],
			fecha_vigencia: null,
			fecha_inicio: null,
			fecha_fin: null,
			fecha_alta: null,
			activo: true,
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log("item:::::::::::::", this.props);
		
		this.props.ListEmpresa().then((response) => {
			this.setState({
				id_empresa: item.id_empresa || '',
			});
		});
		this.ListBanco().then((response) => {
			this.setState({
				id_cat_banco: item.id_cat_banco || '',
			});
		});
		this.ListEmpleado().then((response) => {
			this.setState({
				id_empleado: item.id_empleado || '',
			});
		});
		
		this.ListPeriodicidadPago().then((response) => {
			this.setState({
				id_periodicidad_pago: item.id_periodicidad_pago || '',
			});
		});
		
		this.setState({
			id_comprobante_nomina: item.id_comprobante_nomina || '',
			cuenta_bancaria: item.cuenta_bancaria || '',
			fecha_inicio: item.fecha_inicio ? moment(item.fecha_inicio) : null,
			fecha_fin: item.fecha_fin ? moment(item.fecha_fin) : null,
			importe: item.importe || '',
			check_recargo: item.recargo > 0,
			recargo: item.recargo || '',
			ejercicio: item.ejercicio || '',
			periodo: item.periodo || '',
			estatus: item.estatus || '',
			observaciones: item.observaciones || '',
			//files: [],
			archivos: item.archivos || [],
			fecha_alta: item.fecha_alta ? moment(item.fecha_alta) : null,
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log("item:::::::::::::", this.props);
		
		this.props.ListEmpresa().then((response) => {
			this.setState({
				id_empresa: item.id_empresa || '',
			});
		});
		this.ListBanco().then((response) => {
			this.setState({
				id_cat_banco: item.id_cat_banco || '',
			});
		});
		this.ListEmpleado().then((response) => {
			this.setState({
				id_empleado: item.id_empleado || '',
			});
		});
		this.ListPeriodicidadPago().then((response) => {
			this.setState({
				id_periodicidad_pago: item.id_periodicidad_pago || '',
			});
		});
		this.setState({
			id_comprobante_nomina: item.id_comprobante_nomina || '',
			cuenta_bancaria: item.cuenta_bancaria || '',
			fecha_inicio: item.fecha_inicio ? moment(item.fecha_inicio) : null,
			fecha_fin: item.fecha_fin ? moment(item.fecha_fin) : null,
			importe: item.importe || '',
			check_recargo: item.recargo > 0,
			recargo: item.recargo || '',
			ejercicio: item.ejercicio || '',
			periodo: item.periodo || '',
			estatus: item.estatus || '',
			observaciones: item.observaciones || '',
			archivos: item.archivos || [],
			activo: (item.activo === 1),
			fecha_alta: item.fecha_alta ? moment(item.fecha_alta) : null,
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_comprobante_nomina > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	descargarZip = (path) => {
		console.log("PATH ::: ", path);
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<Grid>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Comprobante de Nómina</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2} alignItems={"flex-end"}>
							
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-50-px padding-10-R'}>
										<ModalEmpresa
											tipo={'add'}
											item={{}}
											componente={
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Agregar empresa">
													<Fab size="small" color="primary" aria-label="Agregar empresa">
														<Add/>
													</Fab>
												</Tooltip>
											}
											RefreshList={this.RefreshListEmpresa}
											showSnackBars={this.props.showSnackBars}
										/>
									</Grid>
									<Grid item className={'w-30-px'}>
										<Business className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-80-px'}>
										<FormControl fullWidth required>
											<InputLabel>Empresa</InputLabel>
											<Select
												native
												value={this.state.id_empresa}
												onChange={this.handleChange}
												name="id_empresa"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.props.lista_empresa.map(option => (
													<option key={option.id_empresa}
													        value={option.id_empresa}>
														{option.nombre_comercial}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							
							</Grid>
							
							{/*<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrong className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Ejercicio</InputLabel>
											<Select
												native
												value={this.state.ejercicio}
												onChange={this.handleChange}
												name="ejercicio"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_ejercicio.map(option => (
													<option key={option.anio}
													        value={option.anio}>
														{option.anio}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrong className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Periodo</InputLabel>
											<Select
												native
												value={this.state.periodo}
												onChange={this.handleChange}
												name="periodo"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_periodo.map(option => (
													<option key={option.mes}
													        value={option.mes}>
														{option.mes}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							
							</Grid>*/}
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrong className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Empleado</InputLabel>
											<Select
												native
												value={this.state.id_empleado}
												onChange={this.handleChange}
												name="id_empleado"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_empleado.map(option => (
													<option key={option.id_empleado}
													        value={option.id_empleado}>
														{option.nombre_completo}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrong className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Tipo Pago</InputLabel>
											<Select
												native
												value={this.state.id_cat_periodicidad_pago}
												onChange={this.handleChange}
												name="id_cat_periodicidad_pago"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_cat_periodicidad_pago.map(option => (
													<option key={option.id_cat_periodicidad_pago}
													        value={option.id_cat_periodicidad_pago}>
														{option.periodicidad_pago}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							{/*<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrong className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Banco</InputLabel>
											<Select
												native
												value={this.state.id_cat_banco}
												onChange={this.handleChange}
												name="id_cat_banco"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_banco.map(option => (
													<option key={option.id_cat_banco}
													        value={option.id_cat_banco}>
														{option.banco}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>*/}
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<KeyboardDatePicker
									autoOk={true}
									format="dd/MM/yyyy"
									fullWidth
									required
									clearable
									inputProps={{readOnly: true}}
									/*KeyboardButtonProps={{
										'aria-label': 'Fecha de pago',
									}}*/
									label="Fecha de Pago"
									value={this.state.fecha_alta}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_alta');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							{/*<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Payment className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="cuenta_bancaria"
											onChange={this.handleChange}
											label="Últimos 4 dígitos de la cta. bancaria"
											autoComplete={'off'}
											value={this.state.cuenta_bancaria}
											inputProps={{maxLength: 4}}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>*/}
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="importe"
											onChange={this.handleChange}
											label="Importe"
											autoComplete={'off'}
											value={this.state.importe}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									fullWidth
									multiline
									rows={3}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="observaciones"
									label="Observaciones"
									value={this.state.observaciones}
								/>
							</Grid>
							
							
							{this.state.archivos.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
										Documento(s) Anexo:
									</Typography>
									<div className={'vertical-inline'}>
										{this.state.archivos.map((item, key) => (
											<div>
												<div className={'vertical-inline b-r-20 margin-5 margin-0-L'} key={key}>
													<img className={'v-center cursor-pointer'} src={DocSvg} alt="add" width={40} height={40} style={{opacity: 0.7, marginRight: '5px'}} onClick={() => this.descargarZip(item.archivo)}/>
													
													
													{this.props.tipo === 'edit' ? (<div className={'vertical-inline v-end b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
														<div className={'padding-3 v-end text-white cursor-pointer'}>
															{this.props.tipo === 'view' ? null : (
																<DeleteOutlined className={'px-16 text-white'} onClick={() => this.eliminar_documento(key)}/>
															)
															}
														</div>
													</div>) : null}
												</div>
											
											
											</div>
										))}
									</div>
								</Grid>
							) : null}
							
							{this.props.tipo !== 'view' ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'dropzone-cronograma margin-15-T'}>
									<div className={'w-100'}>
										<DropzoneArea
											onChange={this.handleChangeDropzoneArea.bind(this)}
											open={this.state.open}
											filesLimit={1}
											dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
											acceptedFiles={['application/pdf']}
											showPreviews={false}
											maxFileSize={5000000}
											showAlerts={false}
											showFileNames={true}
											showFileNamesInPreview={true}
										/>
									</div>
								</Grid>
							) : null}
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_comprobante_nomina > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</Grid>
		);
	}
}

ModalComprobantesNomina.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object,
	lista_empresa: PropTypes.array.isRequired,
	ListEmpresa: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalComprobantesNomina);
