import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Fab from "@material-ui/core/Fab";
import {CronogramaService} from '../../../../services/CronogramaService/CronogramaService';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {ProyectoService} from "../../../../services/_Cat/ProyectoService/ProyectoService";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {EditOutlined, HdrStrong, SearchOutlined} from "@material-ui/icons";
import {CatService} from "../../../../services/_Cat/CatService/CatService";


class ModalEstatus extends Component {
	
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_cronograma: (props.item || '').id_cronograma,
			tipo: props.tipo,
			id_cat_cronograma_estatus: '',
			lista_cat_cronograma_estatus: [],
		};
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	ListCatCronogramaEstatus = () => {
		return new Promise((resolve, reject) => {
			CatService.ListCronogramaEstatus().then((response) => {
				this.setState({
					lista_cat_cronograma_estatus: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_cat_cronograma_estatus: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	
	save = () => {
		try {
			CronogramaService.Estatus(this.state).then((response) => {
				this.props.RefreshList();
				this.modalClose();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		const {item} = this.props;
		
		this.setState({
			id_cronograma: item.id_cronograma || '',
			id_cat_cronograma_estatus: '',
			lista_cat_cronograma_estatus: [],
			activo: true,
		});
		
		this.ListCatCronogramaEstatus();
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_cronograma: item.id_cronograma || '',
			id_cat_cronograma_estatus: item.id_cat_cronograma_estatus.toString() || null,
			lista_cat_cronograma_estatus: item.lista_cat_cronograma_estatus || [],
			activo: (item.activo === 1),
		});
		
		this.ListCatCronogramaEstatus();
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.ListCatCronogramaEstatus().then(() => {
			this.setState({
				id_cat_proyecto: item.id_cat_proyecto || '',
			});
			
		});
		
		this.setState({
			id_cronograma: item.id_cronograma || '',
			id_cat_cronograma_estatus: item.id_cat_cronograma_estatus || null,
			lista_cat_cronograma_estatus: item.lista_cat_cronograma_estatus || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_cronograma > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Estatus">
						<IconButton aria-label="Estatus" onClick={() => this.edit()}>
							<HdrStrong/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<HdrStrong/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{'Actualizar Estatus'}</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-left'}>
								{/*<span className={'text-left text-black px-13'}>Estatus de la actividad:</span>*/}
								<Grid item className={'text-left ticketfrecuencia'} xs={12} sm={12} md={12} lg={12} xl={12}>
									<RadioGroup
										row aria-label="position"
										name="id_cat_cronograma_estatus"
										value={this.state.id_cat_cronograma_estatus}
										onChange={this.handleChange}>
										{this.state.lista_cat_cronograma_estatus.map((item, key) => (
											<FormControlLabel
												style={{color: 'gray', fontSize: '13px'}}
												key={key}
												labelPlacement="end"
												control={<Radio color="primary"/>}
												value={item.id_cat_cronograma_estatus.toString()}
												label={item.cronograma_estatus}
											/>
										))}
									</RadioGroup>
								</Grid>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_cronograma > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalEstatus.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalEstatus);
