import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {CuentaBancariaService} from "../../../../services/CuentaBancariaService/CuentaBancariaService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {
	AccountBalance, Add,
	AlternateEmailOutlined,
	EditOutlined,
	GrainOutlined,
	Group,
	HdrStrongOutlined,
	HdrWeakOutlined,
	HowToVoteOutlined,
	ImageAspectRatioOutlined,
	Person,
	PhoneOutlined,
	PinDropOutlined,
	StreetviewOutlined
} from "@material-ui/icons";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ModalEmpresa from "../../Cat/Empresa/Includes/ModalEmpresa";
import Fab from "@material-ui/core/Fab";

class ModalCuentaBancaria extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			//Cuenta bancaria
			id_cuenta_bancaria: (props.item || {}).id_cuenta_bancaria,
			id_empresa: '',
			id_cat_banco: '',
			id_usuario_apoderado: '',
			id_usuario_autorizado: '',
			numero_cuenta: '',
			clabe: '',
			rfc: '',
			apoderado: '',
			sucursal_apertura: '',
			telefono: '',
			telefono_dos: '',
			numero_cliente: '',
			correo_electronico: '',
			cat_empresa: [],
			cat_banco: [],
			
			//Direccion
			id_cat_estado: '',
			id_cat_municipio: '',
			calle: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			referencia: '',
			cat_estado: [],
			cat_municipio: [],
			
			
			usuarios_autorizados: [],
			lista_usuario_autorizado: [],
			
			activo: true,
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
			this.ListEstado();
			this.ListBanco();
			this.ListaUsuario();
			
			if (this.state.id_cat_estado > 0)
				this.ListMunicipio(null, this.state.id_cat_estado);
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	/*ListEmpresa = () => {
		CatService.ListEmpresa().then((response) => {
			this.setState({
				cat_empresa: response.data
			});
		});
	};*/
	
	ListEstado = () => {
		CatService.ListEstado().then((response) => {
			this.setState({
				cat_estado: response.data
			});
		});
	};
	
	ListMunicipio = (e, id_cat_estado) => {
		
		if (!id_cat_estado) {
			id_cat_estado = e.target.value;
		}
		
		CatService.ListMunicipio(id_cat_estado).then((response) => {
			this.setState({
				cat_municipio: response.data
			});
		});
	};
	
	ListBanco = () => {
		CatService.ListBanco().then((response) => {
			this.setState({
				cat_banco: response.data
			});
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	save = () => {
		if (this.state.id_cuenta_bancaria > 0) {
			CuentaBancariaService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.RefreshList2();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			CuentaBancariaService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.RefreshList2();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		this.setState({
			id_cuenta_bancaria: '',
			id_empresa: '',
			id_cat_banco: '',
			id_usuario_apoderado: '',
			id_usuario_autorizado: '',
			numero_cuenta: '',
			clabe: '',
			rfc: '',
			apoderado: '',
			sucursal_apertura: '',
			telefono: '',
			telefono_dos: '',
			numero_cliente: '',
			correo_electronico: '',
			
			//Direccion
			id_cat_estado: '',
			id_cat_municipio: '',
			calle: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			referencia: '',
			
			usuarios_autorizados: [],
			
			activo: true
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		
		this.setState({
			id_cuenta_bancaria: item.id_cuenta_bancaria || '',
			id_empresa: item.id_empresa || '',
			id_cat_banco: item.id_cat_banco || '',
			id_usuario_apoderado: item.id_usuario_apoderado || '',
			id_usuario_autorizado: item.id_usuario_autorizado || '',
			numero_cuenta: item.numero_cuenta || '',
			clabe: item.clabe || '',
			rfc: item.rfc || '',
			apoderado: item.apoderado || '',
			sucursal_apertura: item.sucursal_apertura || '',
			telefono: item.telefono || '',
			telefono_dos: item.telefono_dos || '',
			numero_cliente: item.numero_cliente || '',
			correo_electronico: item.correo_electronico || '',
			
			//Direccion
			id_cuenta_bancaria_direccion: item.id_cuenta_bancaria_direccion || '',
			id_cat_estado: item.id_cat_estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
			calle: item.calle || '',
			numero_exterior: item.numero_exterior || '',
			numero_interior: item.numero_interior || '',
			codigo_postal: item.codigo_postal || '',
			colonia: item.colonia || '',
			referencia: item.referencia || '',
			
			usuarios_autorizados: [],
			
			activo: (Number(item.activo) === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cuenta_bancaria: item.id_cuenta_bancaria || '',
			id_empresa: item.id_empresa || '',
			id_cat_banco: item.id_cat_banco || '',
			id_usuario_apoderado: item.id_usuario_apoderado || '',
			id_usuario_autorizado: item.id_usuario_autorizado || '',
			numero_cuenta: item.numero_cuenta || '',
			clabe: item.clabe || '',
			rfc: item.rfc || '',
			apoderado: item.apoderado || '',
			sucursal_apertura: item.sucursal_apertura || '',
			telefono: item.telefono || '',
			telefono_dos: item.telefono_dos || '',
			numero_cliente: item.numero_cliente || '',
			correo_electronico: item.correo_electronico || '',
			
			//Direccion
			id_cuenta_bancaria_direccion: item.id_cuenta_bancaria_direccion || '',
			id_cat_estado: item.id_cat_estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
			calle: item.calle || '',
			numero_exterior: item.numero_exterior || '',
			numero_interior: item.numero_interior || '',
			codigo_postal: item.codigo_postal || '',
			colonia: item.colonia || '',
			referencia: item.referencia || '',
			
			usuarios_autorizados: [],
			
			activo: (Number(item.activo) === 1),
		});
		this.open();
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el contacto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_contactos = FieldsJs.Copy(this.state.lista_contactos);
					
					lista_contactos.splice(key, 1);
					
					this.setState({
						lista_contactos: lista_contactos
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	ListaUsuario = () => {
		return new Promise((resolve, reject) => {
			CatService.ListUsuarioTipoReporte().then((response) => {
				this.setState({
					lista_usuario_autorizado: response.data,
				});
				const {item} = this.props;
				if (this.props.tipo === 'view' || this.props.tipo === 'edit') {
					setTimeout(() => {
						for (let i = 0; i < item.autorizados.length; i++) {
							this.asignarUsuario(item.autorizados[i].id_usuario, item.autorizados[i].id_usuario);
						}
					});
				}
				resolve(response);
			}).catch((error) => {
				this.setState({
					lista_usuario_autorizado: []
				});
				this.showSnackBars('error', 'Por el momento no hay usuarios registrados');
				reject(false);
			});
		});
	};
	
	asignarUsuario = (id_usuario, id_usuario_autorizado) => {
		let usuarios = FieldsJs.Copy(this.state.lista_usuario_autorizado);
		let users = FieldsJs.Copy(this.state.usuarios_autorizados);
		let banduser = false;
		let userkey = 0;
		for (let i = 0; i < users.length; i++) {
			if (Number(users[i].id_usuario) === Number(id_usuario)) {
				banduser = true;
				userkey = i;
			}
		}
		if (!banduser) {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = true;
					if (id_usuario_autorizado) {
						usuarios[i].id_usuario = id_usuario_autorizado;
					}
					users.push(usuarios[i]);
				}
			}
		} else {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = false;
				}
			}
			users.splice(userkey, 1);
			//this.showSnackBars('error', 'El usuario ya se encuentra seleccionado.');
		}
		this.setState({
			lista_usuario_autorizado: usuarios,
			usuarios_autorizados: users,
			id_usuario_autorizado: ''
		});
		console.log("ASIGNARUSUARIO", users, usuarios, this.state.usuarios_autorizados);
	};
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cuenta_bancaria}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Cuenta Bancaria'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={8} lg={8} xl={8}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-50-px text-left'}>
												<ModalEmpresa
													tipo={'add'}
													item={{}}
													componente={
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Agregar empresa">
															<Fab size="small" color="primary" aria-label="Agregar empresa">
																<Add/>
															</Fab>
														</Tooltip>
													}
													RefreshList={this.props.RefreshList2}
													showSnackBars={this.props.showSnackBars}
												/>
											</Grid>
											<Grid item className={'w-100-50-px'}>
												<FormControl fullWidth required>
													<InputLabel>Empresa</InputLabel>
													<Select
														native
														value={this.state.id_empresa}
														onChange={(e) => {
															this.handleChange(e);
														}}
														name="id_empresa"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.props.lista_empresa.map(option => (
															<option key={option.id_empresa}
															        value={option.id_empresa}>
																{option.nombre_comercial}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
											
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AccountBalance className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth required>
													<InputLabel>Banco</InputLabel>
													<Select
														native
														value={this.state.id_cat_banco}
														onChange={(e) => {
															this.handleChange(e);
														}}
														name="id_cat_banco"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_banco.map(option => (
															<option key={option.id_cat_banco}
															        value={option.id_cat_banco}>
																{option.banco}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HdrWeakOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="numero_cuenta"
												           onChange={this.handleChange}
													//inputProps={{maxLength: 10}}
													        onKeyPress={EnteroSolo}
													        label="Número de cuenta" autoComplete={'off'}
													        value={this.state.numero_cuenta}
													        disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HdrWeakOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="clabe"
												           onChange={this.handleChange}
													//inputProps={{maxLength: 10}}
													        onKeyPress={EnteroSolo}
													        required
													        label="Clabe" autoComplete={'off'}
													        value={this.state.clabe}
													        disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									{/*<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HowToVoteOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="rfc"
												           onChange={this.handleChange}
												           inputProps={{maxLength: this.state.tipo_persona === 'fisica' ? 13 : 12}}
												           label="R.F.C." autoComplete={'off'}
												           value={this.state.rfc}
												           required
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>*/}
									
									<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HdrWeakOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="numero_cliente"
												           onChange={this.handleChange}
												           label="Número de cliente" autoComplete={'off'}
												           value={this.state.numero_cliente}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Person className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth required>
													<InputLabel>Apoderado</InputLabel>
													<Select
														native
														value={this.state.id_usuario_apoderado}
														onChange={(e) => {
															this.handleChange(e);
														}}
														name="id_usuario_apoderado"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.lista_usuario_autorizado.map(option => (
															<option key={option.id_usuario}
															        value={option.id_usuario}>
																{option.nombre_completo}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AccountBalance className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="sucursal_apertura"
												           onChange={this.handleChange}
												           label="Sucursal de apertura" autoComplete={'off'}
												           value={this.state.sucursal_apertura}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<PhoneOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="telefono"
												           onChange={this.handleChange}
												           inputProps={{maxLength: 10}}
												           onKeyPress={EnteroSolo}
												           label="Teléfono registrado 1" autoComplete={'off'}
												           value={this.state.telefono}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<PhoneOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="telefono_dos"
												           onChange={this.handleChange}
												           inputProps={{maxLength: 10}}
												           onKeyPress={EnteroSolo}
												           label="Teléfono registrado 2" autoComplete={'off'}
												           value={this.state.telefono_dos}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AlternateEmailOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="correo_electronico"
												           onChange={this.handleChange}
												           label="Correo electrónico" autoComplete={'off'}
												           value={this.state.correo_electronico}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									
									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<Divider/>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<PinDropOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="calle"
												           onChange={this.handleChange}
												           label="Calle" autoComplete={'off'}
												           value={this.state.calle}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<StreetviewOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="numero_exterior"
												           onChange={this.handleChange}
												           label="Numero Exterior" autoComplete={'off'}
												           value={this.state.numero_exterior}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<StreetviewOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="numero_interior"
												           onChange={this.handleChange}
												           label="Numero Interior" autoComplete={'off'}
												           value={this.state.numero_interior}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<ImageAspectRatioOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="codigo_postal"
												           onChange={this.handleChange}
												           label="Código Postal" autoComplete={'off'}
												           inputProps={{maxLength: 5}}
												           onKeyPress={EnteroSolo}
												           value={this.state.codigo_postal}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<GrainOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="colonia"
												           onChange={this.handleChange}
												           label="Colonia" autoComplete={'off'}
												           value={this.state.colonia}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HdrWeakOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth>
													<InputLabel>Estado</InputLabel>
													<Select
														native
														value={this.state.id_cat_estado}
														onChange={(e) => {
															this.handleChange(e);
															this.ListMunicipio(e);
														}}
														name="id_cat_estado"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_estado.map(option => (
															<option key={option.id_cat_estado}
															        value={option.id_cat_estado}>
																{option.estado}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HdrStrongOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												
												<FormControl fullWidth>
													<InputLabel>Municipio</InputLabel>
													<Select
														native
														value={this.state.id_cat_municipio}
														onChange={this.handleChange}
														name="id_cat_municipio"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_municipio.map(option => (
															<option key={option.id_cat_municipio}
															        value={option.id_cat_municipio}>
																{option.municipio}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<GrainOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="referencia"
												           onChange={this.handleChange}
												           label="Referencia" autoComplete={'off'}
												           value={this.state.referencia}
												           multiline
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									{/*<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox type="checkbox" name='activo'
															          checked={this.state.activo}
															          onChange={this.handleChange} value="activo"
															          color="primary"
															          disabled={this.props.tipo === 'view'}/>
														}
														label={'Activo'}
													/>
												</FormGroup>
											</Grid>
										</Grid>
									</Grid>*/}
								
								</Grid>
							</Grid>
							
							<Grid item className={'text-left borde-punteado-lightgray-2'} xs={12} sm={12} md={4} lg={4} xl={4}>
								
								<Grid container spacing={1} alignItems={"flex-center"}>
									<Grid className={'w-30-px'}>
										<Group className={'w-100 text-gray'}/>
									</Grid>
									<Grid className={'w-100-30-px'}>
										<Typography className={'px-14 text-left text-gray margin-2-T'}>
											{'Autorizado(s)'}
										</Typography>
									</Grid>
									<Grid className={'w-100'}>
										<div style={{maxHeight: 350, overflowY: 'auto'}}>
											{this.state.lista_usuario_autorizado.map((item, index) => (
												<Fragment key={index}>
													<div className={'vertical-inline w-95 cursor-pointer'} onClick={() => {
														if (this.props.tipo !== 'view') {
															this.asignarUsuario(item.id_usuario);
														}
													}}>
														<div className='v-center padding-10-L'>
															<FormGroup row className={'text-center check-recuser'}>
																<FormControlLabel
																	control={
																		<Checkbox
																			type="checkbox"
																			color="primary"
																			checked={item.check}
																			value={item.check}
																			onChange={{}}
																			disabled={this.props.tipo === 'view'}
																		/>
																	}
																	label={''}
																/>
															</FormGroup>
														</div>
														
														<div className={'v-center vertical-inline'}>
															<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
															     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
															</div>
															<Typography variant={'h5'} className={'v-center padding-15-L margin-0 text-black px-13'}>
																{item.nombre_completo}
															</Typography>
														</div>
													</div>
												</Fragment>
											))}
										</div>
									
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<BotonAccionFooterModal
							id={this.state.id_cuenta_bancaria}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCuentaBancaria.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	lista_empresa: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	RefreshList2: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCuentaBancaria;
