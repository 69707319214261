import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import ModalTimbradoNomina from "./ModalTimbradoNomina";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {TimbradoNominaService} from "../../../../services/TimbradoNominaService/TimbradoNominaService";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import ModalEstatus from "./ModalEstatus";
import icon_mas from "../../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../../assets/img/icons/expandir-menos.svg";
import SubTablaTimbradoNomina from "./SubTablaTimbradoNomina";
import Fab from "@material-ui/core/Fab";
import {Backup, Description, EventNote, FindInPage} from "@material-ui/icons";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import ModalSubirPDF from "./ModalSubirPDF";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el resguardo del equipo: ${item.equipo}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				TimbradoNominaService.Eliminar(item.id_resguardo).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					
					this.props.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	
	spanStatus = (item) => {
		let bgg = 'white';
		switch (Number(item.id_cat_cronograma_estatus)) {
			case 1:
				bgg = 'gray';
				break;
			case 2:
				bgg = '#00c1ff';
				break;
			case 3:
				bgg = '#fcee15';
				break;
			case 4:
				bgg = '#c66500';
				break;
			case 5:
				bgg = '#119703';
				break;
			default:
				bgg = '#ccc';
				break;
		}
		
		const foto_default = CONFIG.foto_default;
		
		return (
			<Fragment>
				<div className={'w-100 text-white'} style={{borderRadius: '5px', background: bgg}}>
					{item.cronograma_estatus}
				</div>
			</Fragment>
		);
	};
	
	generarCarta = (item, archivo, auth) => {
		
		let filtro = {
			id_resguardo: item.id_resguardo || null,
		};
		HttpRequest.export('cartapdf', ['carta'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, true, false, false, archivo === "PDF")
		
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		return (
			<Paper className={classes.root}>
				{
					FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (
						<Table className={classes.table + ' desing-acresco'}>
							<TableHead>
								<TableRow>
									{/*<TableCell component="th" align={'left'} className={'padding-10-L'}>Id</TableCell>*/}
									<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>...</TableCell>
									{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (<TableCell component="th" align={'left'}>Usuario</TableCell>) : null}
									{/*<TableCell component="th" align={'left'}>Día</TableCell>*/}
									<TableCell component="th" align={'left'}>Centro de Trabajo</TableCell>
									<TableCell component="th" align={'left'}>Puesto</TableCell>
									<TableCell component="th" align={'center'}>Cantidad de Equipos</TableCell>
									<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									lista.map((item, key) => {
										return (
											<Fragment>
												<TableRow key={key}>
													{/*<TableCell align={'left'} className={'padding-10-L'}>{item.id_resguardo}</TableCell>*/}
													<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
														<div className={'w-100'} align={'center'}>
															<div style={!!item.expandir ? bg_menos : bg_mas}
															     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
															     onClick={() => this.props.SubTabla(item, key)}
															/>
														</div>
													</TableCell>
													{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (<TableCell align={'left'} className={'padding-10-L'}>{item.nombre_completo}</TableCell>) : null}
													{/*<TableCell align={'left'}>{DateFormat.getHoraFecha(item.fecha)}</TableCell>*/}
													<TableCell align={'left'}>{item.centro_trabajo || 'Sin registrar'}</TableCell>
													<TableCell align={'left'}>{item.puesto || 'Sin registrar'}</TableCell>
													<TableCell align={'center'}>{item.cantidad_equipos}</TableCell>
													<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
														<div className={'w-auto vertical-inline'}>
															<div className={'v-center'}>
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Equipos Asignados">
																		<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#ccc"}} onClick={() => this.props.exportarResguardo(item, 'EXCEL', true, 0)}>
																			<EventNote style={{fontSize: 15}}/>
																		</Fab>
																	</Tooltip>
																</Tooltip>
															</div>
														</div>
													
													</TableCell>
												
												</TableRow>
												
												{(
													<Fragment>
														{!!item.expandir ? (
															<TableRow>
																<TableCell align={'center'} className={'padding-20-L padding-20-R padding-20-T padding-20-B'} colSpan={13}>
																	
																	<SubTablaTimbradoNomina
																		tipo_vista={this.props.tipo_vista}
																		item={item}
																		eliminar={this.eliminar}
																		showSnackBars={this.props.showSnackBars}
																		RefreshList={this.props.RefreshList}
																	/>
																
																</TableCell>
															</TableRow>
														) : null}
													</Fragment>
												)}
											</Fragment>
										)
									})
								}
							</TableBody>
						</Table>
					) : (
						<Table className={classes.table + ' desing-acresco'}>
							<TableHead>
								<TableRow>
									<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Equipo</TableCell>
									<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Cantidad</TableCell>
									<TableCell component="th" align={'left'}>Marca</TableCell>
									<TableCell component="th" align={'left'}>Modelo</TableCell>
									<TableCell component="th" align={'left'}>Serie</TableCell>
									<TableCell component="th" align={'left'}>Color</TableCell>
									<TableCell component="th" align={'left'}>Recibió</TableCell>
									{/*<TableCell component="th" align={'left'}>Estado Actual</TableCell>*/}
									<TableCell component="th" align={'left'}>Dirección Actual</TableCell>
									<TableCell component="th" align={'center'}>URL Anexo</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									lista.map((item, key) => {
										return (
											<Fragment>
												<TableRow key={key}>
													<TableCell align={'left'} style={{paddingLeft: '15px'}}>{item.equipo}</TableCell>
													<TableCell align={'left'} style={{paddingLeft: '15px'}}>{item.cantidad}</TableCell>
													<TableCell align={'left'}>{item.marca}</TableCell>
													<TableCell align={'left'}>{item.modelo}</TableCell>
													<TableCell align={'left'}>{item.serie}</TableCell>
													<TableCell align={'left'}>{item.color}</TableCell>
													<TableCell align={'left'}>{item.recibio_recepcion}</TableCell>
													{/*<TableCell align={'left'}>{item.estado_actual}</TableCell>*/}
													<TableCell align={'left'}>{item.direccion_actual}</TableCell>
													<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
														<div className={'w-auto vertical-inline'}>
															
															<div className={'v-center'}>
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Generar carta resguardo">
																	<IconButton aria-label="Generar carta resguardo" onClick={() => this.generarCarta(item)}><Description/></IconButton>
																</Tooltip>
															</div>
															
															{ item.archivo_firmado ? (
																<div className={'v-center'}>
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Descargar resguardo firmado">
																		<IconButton aria-label="Descargar resguardo firmado" onClick={() => this.exportar(item.archivo_firmado)}><FindInPage style={{color: 'rgb(0, 99, 0)'}}/></IconButton>
																	</Tooltip>
																</div>
															) : (
																<div className={'v-center'}>
																	<ModalSubirPDF
																		tipo={'add'}
																		item={item}
																		componente={
																			<Tooltip TransitionComponent={Zoom} placement={"top"} title="Subir resguardo firmado" className={!FieldsJs.Field(item.archivo) ? 'animate__animated animate__bounce animate__delay-2s animate__infinite infinite':''}>
																				<IconButton aria-label="Subir resguardo firmado"><Backup/></IconButton>
																			</Tooltip>
																		}
																		showSnackBars={this.props.showSnackBars}
																		RefreshList={this.props.RefreshList}
																	/>
																</div>
															)}
															
															{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
																<div className={'v-center'}>
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
																		<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}><DeleteOutlined/></IconButton>
																	</Tooltip>
																</div>
															) : ''}
															
															<div className={'v-center'}>
																<ModalTimbradoNomina
																	tipo={'edit'}
																	item={item}
																	RefreshList={this.props.RefreshList}
																	showSnackBars={this.props.showSnackBars}
																	lista_empresa={this.props.lista_empresa}
																/>
															</div>
															
															<div className={'v-center'}>
																<ModalTimbradoNomina
																	tipo={'view'}
																	item={item}
																	RefreshList={this.props.RefreshList}
																	showSnackBars={this.props.showSnackBars}
																	lista_empresa={this.props.lista_empresa}
																/>
															</div>
														</div>
													</TableCell>
												</TableRow>
											</Fragment>
										)
									})
								}
							</TableBody>
						</Table>
					)
				}
			
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
