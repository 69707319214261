import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {RecordatoriosService} from "../../../../services/RecordatoriosService/RecordatoriosService";
import {HdrWeakOutlined} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Typography from "@material-ui/core/Typography";


import add_cliente_venta from '../../../../assets/img/icons/facturacion.svg';
import edit_cliente_venta from '../../../../assets/img/icons/facturacion.svg';
import view_cliente_venta from '../../../../assets/img/icons/facturacion.svg';
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {EmpresaService} from "../../../../services/_Cat/EmpresaService/EmpresaService";

import './../Recordatorios.css';
import DialogTitle from "@material-ui/core/DialogTitle";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import Divider from "@material-ui/core/Divider";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import {RecordatorioService} from "../../../../services/_Cat/RecordatorioService/RecordatorioService";
import Paper from "@material-ui/core/Paper";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";


const styles = {
	root: {
		background: "transparent",
		color: "black"
	},
	input: {
		color: "black"
	},
	input_number: {
		color: "black",
		textAlign: "right",
		alignText: "right",
	}
};


class ModalRecordatorios extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_recordatorio: (props.item || {}).id_recordatorio,
			
			key: null,
			area_solucion: '',
			id_usuario_solicitante: '',
			id_cat_area_usuario_solicitante: '',
			id_cat_area_solucion: '',
			id_usuario_asignado: '',
			id_cat_prioridad: '',
			id_empresa: '',
			fecha_solucion: null,
			recordatorio: '',
			url_ticket_requerimiento: '',
			fecha_solicitud: null,
			
			lista_usuario_solicitante: [],
			lista_tipo_usuario_solicitante: [],
			lista_area_solucion: [],
			lista_usuario_asignado: [],
			lista_prioridad: [],
			lista_recordatorios: [],
			lista_empresa: [],
			lista_area: [],
			lista_frecuencia_notificacion: [],
			
			
			ticket_requerimiento: [],
			usuarios_asignados: [],
			
			
			ficha_tecnica_archivo_integracion_expediente: [],
			archivo_integracion_expediente: '',
			id_ficha_tecnica_archivo_integracion_expediente: '',
			id_cat_archivo_integracion_expediente: '',
			loading: '',
			base64: '',
			formato: '',
			archivo: '',
			is_required: '',
			
			
			fecha_notificacion: null,
			fecha_fin_notificacion: null,
			frecuencia_notificacion: '',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => {
			this.setState({
				[r.name]: r.value
			});
		});
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	ListaEmpresa = () => {
		CatService.ListEmpresa().then((response) => {
			this.setState({
				lista_empresa: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_empresa: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaRecordatorios = () => {
		RecordatorioService.Listar().then((response) => {
			this.setState({
				lista_recordatorios: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_recordatorios: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaTicketFrecuenciaNotificacion = () => {
		CatService.ListRecordatorioFrecuenciaNotificacion().then((response) => {
			this.setState({
				lista_frecuencia_notificacion: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_frecuencia_notificacion: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaUsuario = () => {
		return new Promise((resolve, reject) => {
			CatService.ListUsuarioTipoReporte().then((response) => {
				this.setState({
					lista_usuario_asignado: response.data,
				});
				const {item} = this.props;
				if (this.props.tipo === 'view' || this.props.tipo === 'edit') {
					setTimeout(() => {
						for (let i = 0; i < item.asignados.length; i++) {
							this.asignarUsuario(item.asignados[i].id_usuario, item.asignados[i].id_recordatorio_usuario);
						}
					});
				}
				resolve(response);
			}).catch((error) => {
				this.setState({
					lista_usuario_asignado: []
				});
				this.showSnackBars('error', 'Por el momento no hay usuarios registrados');
				reject(false);
			});
		});
	};
	
	ListaUsuarioxid = (id) => {
		let select = "";
		this.state.lista_usuario_asignado.map((item) => {
			if (Number(item.id_usuario) === Number(id)) {
				select = item.name;
			}
		})
		return select;
	};
	
	ListaTipoRequerimientoxid = (id) => {
		let select = "";
		this.state.lista_prioridad.map((item) => {
			if (Number(item.id_cat_prioridad) === Number(id)) {
				select = item.prioridad;
			}
		})
		return select;
	};
	
	ListaEmpresaxid = (id) => {
		let select = "";
		if (id) {
			this.state.lista_empresa.map((item) => {
				if (Number(item.id_empresa) === Number(id)) {
					select = item.nombre_comercial;
				}
			})
		} else {
			select = "Sin registrar";
		}
		
		return select;
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		console.log('USER', this.Usr.id_usuario, this.Usr.id_cat_tipo_usuario);
		this.init().then(r => {
			//this.ListaEmpresa();
			this.ListaRecordatorios();
			this.ListaUsuario();
			this.ListaTicketFrecuenciaNotificacion();
			
			this.init().then(r => {
				setTimeout(() => this.openModal());
			}).catch(e => {
				console.log(e);
			});
			
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			resolve(true);
			/*if (this.props.id_recordatorio > 0) {
				RecordatoriosService.Detalles(this.props.id_recordatorio).then((response) => {
					let item = FieldsJs.Copy(response.data);
					let fecha_emitir_comprobante = item.fecha_emitir_comprobante ? moment(item.fecha_emitir_comprobante) : null;
					this.setState({
						id_recordatorio: item.id_recordatorio,
						
						
						id_cat_area_solucion: item.id_cat_area_solucion,
						id_usuario_asignado: item.id_usuario_asignado,
						id_cat_prioridad: item.id_cat_prioridad,
						id_empresa: item.id_empresa,
						fecha_solucion: item.fecha_solucion,
						recordatorio: item.recordatorio,
						ticket_requerimiento: item.requerimientos || [],
						url_ticket_requerimiento: item.url_ticket_requerimiento,
						fecha_solicitud: item.fecha_solicitud,
						
					});
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false)
				});
			} else {
				this.setState({
					id_cat_area_solucion: '',
					id_usuario_asignado: '',
					id_cat_prioridad: '',
					id_empresa: '',
					fecha_solucion: null,
					recordatorio: '',
					ticket_requerimiento: [],
					url_ticket_requerimiento: '',
					fecha_solicitud: null,
				});
				
				resolve(true);
			}*/
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	save = () => {
		if (Number(this.state.id_recordatorio) > 0) {
			RecordatoriosService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			RecordatoriosService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		this.setState({
			frecuencia_notificacion: '1',
			
			fecha_notificacion: null,
			fecha_fin_notificacion: null,
			id_cat_recordatorio: '',
			recordatorio: '',
			usuarios_asignados: [],
			activo: true,
		});
		
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		this.setState({
			frecuencia_notificacion: item.id_cat_frecuencia_notificacion.toString() || '',
			fecha_notificacion: item.fecha_notificacion || null,
			fecha_fin_notificacion: item.fecha_fin_notificacion || null,
			id_cat_recordatorio: item.id_cat_recordatorio || null,
			recordatorio: item.recordatorio || '',
			usuarios_asignados: [],
			activo: item.activo ? 1 : 0,
		});
		
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			frecuencia_notificacion: item.id_cat_frecuencia_notificacion.toString() || '',
			fecha_notificacion: item.fecha_notificacion || null,
			fecha_fin_notificacion: item.fecha_fin_notificacion || null,
			id_cat_recordatorio: item.id_cat_recordatorio || null,
			recordatorio: item.recordatorio || '',
			usuarios_asignados: [],
			activo: item.activo ? 1 : 0,
		});
		
		this.open();
	};
	
	asignarUsuario = (id_usuario, id_recordatorio_usuario) => {
		let usuarios = FieldsJs.Copy(this.state.lista_usuario_asignado);
		let users = FieldsJs.Copy(this.state.usuarios_asignados);
		let banduser = false;
		let userkey = 0;
		for (let i = 0; i < users.length; i++) {
			if (Number(users[i].id_usuario) === Number(id_usuario)) {
				banduser = true;
				userkey = i;
			}
		}
		if (!banduser) {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = true;
					if (id_recordatorio_usuario) {
						usuarios[i].id_recordatorio_usuario = id_recordatorio_usuario;
					}
					users.push(usuarios[i]);
				}
			}
		} else {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = false;
				}
			}
			users.splice(userkey, 1);
			//this.showSnackBars('error', 'El usuario ya se encuentra seleccionado.');
		}
		this.setState({
			lista_usuario_asignado: usuarios,
			usuarios_asignados: users,
			id_usuario_asignado: ''
		});
		console.log("ASIGNARUSUARIO", users, this.state.usuarios_asignados);
	};
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'new':
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		
		const btn_add = {
			backgroundImage: `url(${add_cliente_venta})`,
			opacity: 1
		};
		
		const btn_edit = {
			backgroundImage: `url(${edit_cliente_venta})`,
			opacity: 1
		};
		
		const btn_view = {
			backgroundImage: `url(${view_cliente_venta})`,
			opacity: 1
		};
		
		const {classes} = this.props;
		
		return (
			<div>
				
				{/*<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>*/}
				
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_recordatorio}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						
						<div className={'text-gray '}>
							Nuevo Recordatorio
						</div>
						
						{/*<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Ticket'}
							
						/>*/}
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-100'}>
								
								<Grid container spacing={0} className={'h-100'}>
									<Grid item xs={12} sm={12} md={4} lg={3} xl={3} className={'padding-10 padding-0-B'}>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-left'}>
											<Typography variant={'h6'} className={'text-black margin-10-B px-12'}>
												Usuarios a Asignar:
											</Typography>
										</Grid>
										
										<Paper style={{maxHeight: 300, overflowY: 'auto'}}>
											{this.state.lista_usuario_asignado.map((item, key) => (
												<Fragment key={key}>
													<div className={'vertical-inline w-95 cursor-pointer'} onClick={() => this.asignarUsuario(item.id_usuario)}>
														<div className='v-center padding-10-L'>
															<FormGroup row className={'text-center check-recuser'}>
																<FormControlLabel
																	control={
																		<Checkbox
																			type="checkbox"
																			color="primary"
																			checked={item.check}
																			value={item.check}
																			onChange={{}}
																		/>
																	}
																	label={''}
																/>
															</FormGroup>
														</div>
														
														<div className={'v-center vertical-inline'}>
															<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
															     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
															</div>
															<Typography variant={'h5'} className={'v-center padding-15-L margin-0 text-black px-13'}>
																{item.nombre_completo}
															</Typography>
														</div>
													</div>
												</Fragment>
											))}
										</Paper>
									
									
									</Grid>
									
									<Grid item xs={12} sm={12} md={8} lg={9} xl={9} className={'padding-10 padding-0-B'}>
										<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'padding-10-T text-left'}>
														<Typography variant={'h6'} className={'text-black px-12'}>
															Información del Recordatorio
														</Typography>
													</Grid>
													
													{/*<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
														<Grid container spacing={1} alignItems={"flex-end"}>
															<Grid item className={'w-30-px'}>
																<Person className={'w-100 text-gray'}/>
															</Grid>
															<Grid item className={'w-100-30-px'}>
																<FormControl required fullWidth>
																	<InputLabel>Usuario Asignado</InputLabel>
																	<Select
																		native
																		value={this.state.id_usuario_asignado}
																		onChange={(e) => {
																			this.handleChange(e);
																			this.asignarUsuario(e.target.value);
																		}}
																		name="id_usuario_asignado"
																		input={<Input/>}
																		disabled={this.props.tipo === 'view'}
																	>
																		<option value=""/>
																		{this.state.lista_usuario_asignado.map(option => (
																			<option key={option.id_usuario}
																			        value={option.id_usuario}>
																				{option.nombre_completo}
																			</option>
																		))}
																	</Select>
																</FormControl>
															</Grid>
														</Grid>
													</Grid>*/}
													
													<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
														<KeyboardDateTimePicker
															format="yyyy/MM/dd hh:mm a"
															fullWidth
															clearable
															required
															inputProps={{readOnly: true}}
															KeyboardButtonProps={{
																'aria-label': 'Notificar a partir de:',
															}}
															label="Notificar a partir de:"
															value={this.state.fecha_notificacion}
															onChange={(date) => {
																this.handleChange(null, null, null, date, 'fecha_notificacion');
															}}
															// minDate={moment(new Date())}
															clearLabel={'Limpiar'}
															okLabel={'Aceptar'}
															cancelLabel={'Cancelar'}
															onError={console.log}
															disabled={this.is_view()}
														/>
													</Grid>
													
													<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
														<KeyboardDateTimePicker
															format="yyyy/MM/dd hh:mm a"
															fullWidth
															clearable
															inputProps={{readOnly: true}}
															KeyboardButtonProps={{
																'aria-label': 'Finalizar Notificaciones:',
															}}
															label="Finalizar Notificaciones:"
															value={this.state.fecha_fin_notificacion}
															onChange={(date) => {
																this.handleChange(null, null, null, date, 'fecha_fin_notificacion');
															}}
															// minDate={moment(new Date())}
															clearLabel={'Limpiar'}
															okLabel={'Aceptar'}
															cancelLabel={'Cancelar'}
															onError={console.log}
															disabled={this.is_view()}
														/>
													</Grid>
													
													<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
														<Grid container spacing={1} alignItems={"flex-end"}>
															<Grid item className={'w-30-px'}>
																<HdrWeakOutlined className={'w-100 text-gray'}/>
															</Grid>
															<Grid item className={'w-100-30-px'}>
																<FormControl fullWidth>
																	<InputLabel>Tipo Recordatorio</InputLabel>
																	<Select
																		native
																		value={this.state.id_cat_recordatorio}
																		onChange={this.handleChange}
																		name="id_cat_recordatorio"
																		input={<Input/>}
																		disabled={this.props.tipo === 'view'}
																	>
																		<option value=""/>
																		{this.state.lista_recordatorios.map(option => (
																			<option key={option.id_cat_recordatorio}
																			        value={option.id_cat_recordatorio}>
																				{option.recordatorio}
																			</option>
																		))}
																	
																	</Select>
																</FormControl>
															</Grid>
														</Grid>
													</Grid>
													
													{/*<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
														<Grid container spacing={1} alignItems={"flex-end"}>
															<Grid item className={'w-30-px'}>
																<Business className={'w-100 text-gray'}/>
															</Grid>
															<Grid item className={'w-100-30-px'}>
																<FormControl fullWidth>
																	<InputLabel>Empresa Relacionada</InputLabel>
																	<Select
																		native
																		value={this.state.id_empresa}
																		onChange={this.handleChange}
																		name="id_empresa"
																		input={<Input/>}
																		disabled={this.props.tipo === 'view'}
																	>
																		<option value=""/>
																		{this.state.lista_empresa.map(option => (
																			<option key={option.id_empresa}
																			        value={option.id_empresa}>
																				{option.nombre_comercial}
																			</option>
																		))}
																	
																	</Select>
																</FormControl>
															</Grid>
														</Grid>
													</Grid>*/}
													
													<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
														<TextField
															rows={3}
															inputProps={{
																style: {
																	fontSize: '13px',
																	lineHeight: '13px',
																	padding: '0px',
																	margin: '0px',
																}
															}}
															required
															className={'px-12 padding-0 margin-0'}
															multiline
															fullWidth
															value={this.state.recordatorio}
															onChange={this.handleChange}
															type="text"
															margin="none"
															helperText=""
															name="recordatorio"
															label="Descripción"
															disabled={this.props.tipo === 'view'}
														/>
													</Grid>
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-left'}>
														<span className={'text-left text-black px-13'}>Frecuencia de las notificaciones:</span>
														<Grid item className={'text-left ticketfrecuencia'} xs={12} sm={12} md={12} lg={12} xl={12}>
															<RadioGroup
																row aria-label="position"
																name="frecuencia_notificacion"
																value={this.state.frecuencia_notificacion}
																onChange={this.handleChange}>
																{this.state.lista_frecuencia_notificacion && this.state.lista_frecuencia_notificacion.map((item, key) => (
																	<FormControlLabel
																		style={{color: 'gray', fontSize: '13px'}}
																		key={key}
																		labelPlacement="end"
																		control={<Radio color="primary"/>}
																		value={item.id_cat_recordatorio_frecuencia_notificacion.toString()}
																		label={item.recordatorio_frecuencia_notificacion}
																		disabled={this.props.tipo === 'view'}
																	/>
																))}
															</RadioGroup>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'padding-10-L padding-10-R'}>
										<Divider/>
									</Grid>
									{this.state.usuarios_asignados.length > 0 ? (
										<Fragment>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'padding-10-T padding-10-L padding-10-R text-left'}>
												<Typography variant={'h6'} className={'text-black px-12'}>
													Usuarios Asignados
												</Typography>
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={2}>
													{this.state.usuarios_asignados.map((item, key) => (
														<Grid item xs={12} sm={12} md={2} lg={3} xl={3}>
															<div className={'vertical-inline margin-15-T margin-15-B w-100'}>
																<div className={'v-center w-50-px h-50-px b-r-100 bg-img-contain-x-center-y-center'}
																     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
																</div>
																<Typography variant={'h5'} className={'v-center padding-15-L margin-0 text-black px-13'}>
																	{item.nombre_completo}
																</Typography>
															</div>
														</Grid>
													))}
												</Grid>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Divider/>
											</Grid>
										</Fragment>
									) : (
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'padding-10-L padding-10-R text-center'}>
											<VistaVacia numero={0} height={'100px'} width={'100px'} padding={'30px'} mensaje={'Sin usuarios asignados.'}/>
										</Grid>
									)}
								</Grid>
							
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									{!this.is_view() ? "Cancelar" : "Cerrar"}
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() ? (
									<Button onClick={() => this.save()} color="primary">
										{this.is_edit() > 0 ? 'Actualizar' : 'Guardar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalRecordatorios.propTypes = {
	classes: PropTypes.object.isRequired,
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};


export default withStyles(styles)(ModalRecordatorios);
