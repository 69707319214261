import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import ModalComprobantesNomina from "./ModalComprobantesNomina";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ComprobantesNominaService} from "../../../../services/ComprobantesNominaService/ComprobantesNominaService";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {AttachMoney, MonetizationOn, Money, SpeakerNotes, Description, InsertDriveFile} from "@material-ui/icons";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import icon_rocket_chat from "../../../../assets/img/icons/rocketchat.svg";
import ModalSendRocketChat from "./ModalSendRocketChat";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el comprobante de nómina con id ${item.id_comprobante_nomina}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				ComprobantesNominaService.Eliminar(item.id_comprobante_nomina).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					
					this.props.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	
	spanStatus = (item) => {
		let bgg = 'white';
		switch (Number(item.id_cat_cronograma_estatus)) {
			case 1:
				bgg = 'gray';
				break;
			case 2:
				bgg = '#00c1ff';
				break;
			case 3:
				bgg = '#fcee15';
				break;
			case 4:
				bgg = '#c66500';
				break;
			case 5:
				bgg = '#119703';
				break;
			default:
				bgg = '#ccc';
				break;
		}
		
		const foto_default = CONFIG.foto_default;
		
		return (
			<Fragment>
				<div className={'w-100 text-white'} style={{borderRadius: '5px', background: bgg}}>
					{item.cronograma_estatus}
				</div>
			</Fragment>
		);
	};
	
	verDocumento = (path, item) => {
		console.log("PATH ::: ", path);
		const arc = path.split('.');
		var typeDoc = arc[arc.length - 1];
		
		DropboxApi.Open(path, '', '', '', '', item.id_comprobante_nomina + '_'+ item.nombre_comercial+'_'+item.empleado+'_'+item.periodicidad_pago+'_'+DateFormat.getFechaTextDiagonal(item.fecha_alta, '_', true)+'.'+typeDoc).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'} className={'padding-10-L'}>Id</TableCell>
							<TableCell component="th" align={'left'}>Fecha de Pago</TableCell>
							<TableCell component="th" align={'left'}>Usuario</TableCell>
							<TableCell component="th" align={'left'}>RFC</TableCell>
							<TableCell component="th" align={'left'}>Empresa</TableCell>
							<TableCell component="th" align={'left'}>Empleado</TableCell>
							<TableCell component="th" align={'left'}>Periodo Pago</TableCell>
							<TableCell component="th" align={'left'}>Tipo Pago</TableCell>
							<TableCell component="th" align={'left'}>Importe</TableCell>
							{/*<TableCell component="th" align={'left'}>Cuenta Bancaria</TableCell>*/}
							<TableCell component="th" align={'left'}>Documento(PDF)</TableCell>
							<TableCell component="th" align={'left'}>Fecha Alta</TableCell>
							<TableCell component="th" align={'left'}>Observaciones</TableCell>
							{/*<TableCell component="th" align={'left'}>Acuse Pago</TableCell>
							<TableCell component="th" align={'left'}>Fecha Pago</TableCell>
							*/}
							{/*<TableCell component="th" align={'center'} className={'w-100-px'}>Estatus</TableCell>*/}
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							lista.map((item, key) => {
								return (
									<TableRow key={key}>
										<TableCell align={'left'} className={'padding-10-L'}>{item.id_comprobante_nomina}</TableCell>
										<TableCell align={'left'}>{DateFormat.getFechaTextDiagonal(item.fecha_alta)}</TableCell>
										<TableCell align={'left'}>{item.nombre_completo}</TableCell>
										<TableCell align={'left'}>{item.rfc}</TableCell>
										<TableCell align={'left'}>{item.nombre_comercial}</TableCell>
										{/*<TableCell align={'left'}>{item.fecha_inicio && item.fecha_fin ? DateFormat.FormatText(item.fecha_inicio) + " al " + DateFormat.FormatText(item.fecha_fin) : 'Sin registrar'}</TableCell>*/}
										<TableCell align={'left'}>{item.empleado || 'Sin registrar'}</TableCell>
										<TableCell align={'left'}>{item.periodicidad || 'Sin registrar'}</TableCell>
										<TableCell align={'left'}>{item.periodicidad_pago || 'Sin registrar'}</TableCell>
										<TableCell align={'left'}>${FieldsJs.Currency(item.importe)}</TableCell>
										<TableCell align={'left'}>{item.lc ? <Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver Comprobante de nómina">
											<IconButton aria-label="Ver Comprobante de nómina" onClick={() => this.verDocumento(item.lc, item)}><Description/></IconButton>
										</Tooltip> : '-'}</TableCell>
										{/*<TableCell align={'left'}>{item.acuse ? <Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver Acuse Pago">
											<IconButton aria-label="Ver Acuse Pago" onClick={() => this.verDocumento(item.acuse)}><InsertDriveFile style={{color: 'rgb(142, 30, 50)'}}/></IconButton>
										</Tooltip> : '-'}</TableCell>
										<TableCell align={'left'}>{item.fecha_pago ? DateFormat.getFechaTextDiagonal(item.fecha_pago) : '-'}</TableCell>
										*/}
										<TableCell align={'center'}>{DateFormat.getFechaTextDiagonal(item.created_at)}</TableCell>
										<TableCell align={'center'}>{item.observaciones}</TableCell>
										{/*<TableCell align={'center'}>{item.status}</TableCell>*/}
										<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
											<div className={'w-auto vertical-inline'}>
												
												<div className={'v-center'}>
													{/*<ModalRecordatorios
														tipo={'new'}
														item={{}}
														componente={(
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Recordatorio">
																<IconButton aria-label="Recordatorio"><SpeakerNotes/></IconButton>
															</Tooltip>
														)}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>*/}
													{/*<ModalSendRocketChat
														item={item}
														tipo={'add'}
														component={(
															<Tooltip TransitionComponent={Zoom} placement={"top"}
															         title="Enviar Notificación">
																<IconButton aria-label="Enviar Notificación">
																	<img className={'v-center'} src={icon_rocket_chat} alt="add" width={25} height={25} style={{opacity: 0.7}}/>
																</IconButton>
															</Tooltip>
														)}
														listar_usuarios={this.props.listar_usuarios}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>*/}
												</div>
												
												{/*{FieldsJs.inArray([1, 2, 6, 13], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<ModalComprobantesNomina
															tipo={'add'}
															item={item}
															componente={(
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Comprobante Pago">
																	<IconButton aria-label="Comprobante Pago"><AttachMoney/></IconButton>
																</Tooltip>
															)}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												) : null}*/}
												
												<div className={'v-center'}>
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
														<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}><DeleteOutlined/></IconButton>
													</Tooltip>
												</div>
												
												<div className={'v-center'}>
													<ModalComprobantesNomina
														tipo={'edit'}
														item={item}
														ListEmpresa={this.props.ListEmpresa}
														lista_empresa={this.props.lista_empresa}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
												
												<div className={'v-center'}>
													<ModalComprobantesNomina
														tipo={'view'}
														item={item}
														ListEmpresa={this.props.ListEmpresa}
														lista_empresa={this.props.lista_empresa}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
											</div>
										
										</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	lista_empresa: PropTypes.array.isRequired,
	listar_usuarios: PropTypes.array,
	ListEmpresa: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
