import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import ModalImportesNetos from "./ModalImportesNetos";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ImportesNetosService} from "../../../../services/ImportesNetosService/ImportesNetosService";
import {$cInfo, $cSuccess, EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {AttachMoney, MonetizationOn, Money, SpeakerNotes, Description, InsertDriveFile, PostAdd, Mail} from "@material-ui/icons";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import SubTablaImportesNetos from "./SubTablaImportesNetos";
import icon_mas from "../../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../../assets/img/icons/expandir-menos.svg";
import {PusherSubscribe, PusherUnSubscribe} from "../../../../settings/Pusher/Pusher";
import Fab from "@material-ui/core/Fab";
import ModalImportarArchivo from "./ModalImportarArchivo";


const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el importe neto con id ${item.id_importe_neto}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				ImportesNetosService.Eliminar(item.id_importe_neto).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					
					this.props.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	is_cancel = (item) => {
		return item.activo === 0;
	};
	
	ver_root = () => {
		return this.Usr.id_usuario === 1;
	};
	
	verDocumento = (path) => {
		console.log("PATH ::: ", path);
		DropboxApi.Open(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
	};
	
	sendMail = (item) => {
		let msg = `¿Deseas enviar el correo electrónico del importe neto con id ${item.id_importe_neto}?`;
		
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Aceptar') {
				ImportesNetosService.SendMail(item.id_importe_neto).then((response) => {
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>...</TableCell>
							<TableCell component="th" align={'left'} className={'padding-10-L'}>N.P.</TableCell>
							<TableCell component="th" align={'left'}>Usuario</TableCell>
							<TableCell component="th" align={'left'}>Periodo</TableCell>
							<TableCell component="th" align={'left'}>Fecha Envío</TableCell>
							<TableCell component="th" align={'left'}>Estatus</TableCell>
							<TableCell component="th" align={'left'}>Observación</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							lista.map((item, key) => {
								return (
									<Fragment key={key}>
										<TableRow>
											<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
												<div className={'w-100'} align={'center'}>
													{item.empresas.length > 0 ? (
														<div style={!!item.expandir ? bg_menos : bg_mas} className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'} onClick={() => this.props.SubTabla(item, key)}/>
													) : "..."}
												</div>
											</TableCell>
											<TableCell align={'left'} className={'padding-10-L'}>{item.id_importe_neto}</TableCell>
											<TableCell align={'left'} className={'padding-10-L'}>{item.nombre_completo}</TableCell>
											<TableCell align={'left'}>{DateFormat.getFecha(item.fecha_inicio) + ' al ' + DateFormat.getFecha(item.fecha_fin)}</TableCell>
											<TableCell align={'left'}>{item.fecha_envio}</TableCell>
											<TableCell align={'left'}>{item.estatus_text}</TableCell>
											<TableCell align={'left'}>{item.observacion}</TableCell>
											<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
												<div className={'w-auto vertical-inline'}>
													
													{/*<div className={'v-center'}>
													<ModalRecordatorios
														tipo={'new'}
														item={{}}
														componente={(
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Recordatorio">
																<IconButton aria-label="Recordatorio"><SpeakerNotes/></IconButton>
															</Tooltip>
														)}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>*/}
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Enviar correo electrónico">
															<IconButton aria-label="Enviar correo electrónico" onClick={() => this.sendMail(item)}><Mail style={{color: item.estatus === 2 ? 'rgb(0, 99, 0)':'rgba(0, 0, 0, 0.54)'}}/></IconButton>
														</Tooltip>
													</div>
													
													<div className={'v-center'}>
														<div className={item.empresas.length <= 0 ? 'animate__animated animate__bounce animate__delay-2s animate__infinite infinite' : ''}>
															<ModalImportarArchivo
																item={item}
																componente={
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Importar Archivo">
																		<IconButton aria-label="Importar Archivo"><PostAdd/></IconButton>
																	</Tooltip>
																}
																RefreshList={this.props.RefreshList}
																showSnackBars={this.props.showSnackBars}
															/>
														</div>
													</div>
													
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
															<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}><DeleteOutlined/></IconButton>
														</Tooltip>
													</div>
													
													<div className={'v-center'}>
														<ModalImportesNetos
															tipo={'edit'}
															item={item}
															ListEmpresa={this.props.ListEmpresa}
															lista_empresa={this.props.lista_empresa}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
													
													<div className={'v-center'}>
														<ModalImportesNetos
															tipo={'view'}
															item={item}
															ListEmpresa={this.props.ListEmpresa}
															lista_empresa={this.props.lista_empresa}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												</div>
											
											</TableCell>
										</TableRow>
										
										{(
											<Fragment>
												{!!item.expandir ? (
													<TableRow>
														<TableCell align={'center'}
														           className={'padding-20-L padding-20-R padding-20-T padding-20-B'}
														           colSpan={this.ver_root() ? 13 : 13}
														>
															
															<SubTablaImportesNetos
																tipo_vista={this.props.tipo_vista}
																item={item}
																showSnackBars={this.props.showSnackBars}
																RefreshList={this.props.RefreshList}
															/>
														
														</TableCell>
													</TableRow>
												) : null}
											</Fragment>
										)}
									</Fragment>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	lista_empresa: PropTypes.array.isRequired,
	ListEmpresa: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	SubTabla: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
