import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {FichaTecnicaService} from "../../../../services/FichaTecnicaService/FichaTecnicaService";
import {FieldsJs, hideSpinner} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import TextField from "@material-ui/core/TextField";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {Icono} from "../../../Include/Icono/Icono";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {ClientePotencialService} from "../../../../services/ClientePotencialService/ClientePotencialService";
import Fab from "@material-ui/core/Fab";
import {SyncOutlined} from "@material-ui/icons";
import {CONFIG} from "../../../../settings/Config/Config";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import DivSvg from "../../../Include/MiniComponents/DivSvg";
import img_lista_vacia from '../../../../assets/img/logo_acresco.svg'

class SubirContratoPrestacionServicioPromotor extends Component {
	
	state = {};
	
	Usr = {};
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			id_cliente: props.id_cliente,
			
			cliente: {},
			
			id_cat_ficha_tecnica_status: '',
			
			contrato_prestacion_servicio_base64: '',
			contrato_prestacion_servicio_base64Tipo: '',
			contrato_prestacion_servicio_archivo: '',
			contrato_prestacion_servicio_formato: '',
			archivo: '',
			
			observaciones: '',
			lugar_resguardo: '',
			responsable_resguardo: '',
			
			contrato_prestacion_servicio: [],
			cliente_contrato_prestacion_servicio: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			FichaTecnicaService.InitDato(this.state.id_cliente).then((response) => {
				
				let dato = FieldsJs.Copy(response.data);
				
				if (response.visible === 0) {
					
					this.setState({
						visible: 0,
						mensaje: response.message
					});
					
				} else {
					
					let contrato_prestacion_servicio = dato.contrato_prestacion_servicio || [];
					let cliente_contrato_prestacion_servicio = dato.cliente_contrato_prestacion_servicio || [];
					
					this.setState({
						
						visible: 1,
						
						cliente: dato.cliente,
						
						id_cat_ficha_tecnica_status: (dato.ficha_tecnica || {}).id_cat_ficha_tecnica_status || '',
						
						contrato_prestacion_servicio: contrato_prestacion_servicio,
						cliente_contrato_prestacion_servicio: cliente_contrato_prestacion_servicio,
						
					});
					
				}
				resolve(true);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
				reject(false)
			});
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	getBase64 = (e, dropzone) => {
		return new Promise((resolve, reject) => {
			let files;
			if (dropzone) {
				files = e;
			} else {
				console.log(e.target);
				files = e.target.files;
			}
			
			let namearchivo = (this.state.contrato_prestacion_servicio.length === 0 ? "Contrato_Prestacion_Servicio_Firmado" : "Contrato_Prestacion_Servicio_Firmado" + (Number(this.state.contrato_prestacion_servicio.length) + 1)) + '.' + this.state.contrato_prestacion_servicio_formato;
			
			let ruta = '/' + this.state.cliente.rfc + '-' + FieldsJs.getClienteTipoPersona(this.state.cliente) + '/contrato/' + namearchivo;
			
			console.log(ruta, files);
			
			DropboxApi.Upload(ruta, files).then(function (response) {
				
				resolve(response.path_display);
				
			}).catch(function (error) {
				reject(error);
			});
		});
	};
	
	save = () => {
		try {
			
			console.log("SAVE FORMATO ALTA ::: ", this.state);
			if (this.state.file.length === 0) {
				throw Object({
					status: 400,
					mensaje: "El archivo de contrato de prestacion de servicio firmado es requerido."
				});
			}
			
			this.getBase64(this.state.file[0], true).then(response => {
				let archivos = FieldsJs.Copy(this.state.archivo);
				
				this.setState({
					archivo: response
				});
				
				console.log("SAVE 222 FORMATO ALTA ::: ", this.state);
				
				hideSpinner('spinner', 600);
				
				ClientePotencialService.SubirContratoPrestacionServicioPromotor(this.state).then((response) => {
					
					this.props.RefreshList();
					
					this.close();
					
					this.showSnackBars('success', response.mensaje);
					
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
				
			});
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	desactivar_nomina_empleados = (item, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Estas seguro de desactivar el archivo contrato de prestacion de servicios del cliente?').then((r) => {
				if (r.button === 'Aceptar') {
					ClientePotencialService.DesactivarContratoPrestacionServicioPromotor(item, key).then((response) => {
						
						this.RefreshInit();
						
						this.showSnackBars('success', response.mensaje);
						
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				}
			});
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	disabledFieldPromotorSubirFormatoAltaBajaEmpleadoBolean = () => {
		
		let disabledFieldPromotorSubirFormatoAltaBajaEmpleado = true;
		
		if (FieldsJs.inArray([1, 2, 3], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldPromotorSubirFormatoAltaBajaEmpleado = false;
		}
		
		return disabledFieldPromotorSubirFormatoAltaBajaEmpleado;
	};
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	render() {
		
		const disabledFieldPromotorSubirFormatoAltaBajaEmpleado = this.disabledFieldPromotorSubirFormatoAltaBajaEmpleadoBolean();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Typography className={'px-20'}>
									<Fab size="small" color="secondary" aria-label="Actualizar" onClick={() => this.RefreshInit()} style={{marginRight: '15px'}}>
										<SyncOutlined/>
									</Fab>
									{this.props.titulo}
								</Typography>
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} direction="row" justify="space-evenly" alignItems="flex-start">
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6} align={'left'}>
								<fieldset className={'borde-punteado-dotted-gray-1'}>
									<legend align={'left'} style={{color: 'dimgrey'}}>Juridico</legend>
									<Card>
										<CardContent style={{background: 'lightgray'}}>
											
											<Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
												
												{this.state.cliente_contrato_prestacion_servicio.map((item, key) => (
													<Fragment key={key}>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															
															<Card style={{height: '100%'}}>
																<CardContent style={{padding: '10px'}}>
																	
																	<div align={'right'}
																	     style={{
																		     width: '100%',
																		     height: 0
																	     }}
																	>
																	</div>
																	
																	<Typography
																		className={'text-right text-black margin-0 padding-0 px-14 h-0-px'}>
																		{item.created_at ? DateFormat.FechaHoraFormatText(item.created_at) : ''}
																	</Typography>
																	
																	{item.contrato_prestacion_servicio_archivo ? (
																		<Fragment>
																			<div className={'v-center'}>
																				<p className={'text-left text-black margin-0 padding-0 px-12'}>
																					Contrato:
																				</p>
																			</div>
																			<div className="vertical-inline">
																				<div className={'padding-5-R v-center'}>
																					{Icono.get(item.contrato_prestacion_servicio_formato, '20px', '20px')}
																				</div>
																				<div className={'v-center padding-10-R'}>
																					{/*<Fragment>
																						<ModalImage tipo={'view'} desing={3} item={{
																							base64: item.contrato_prestacion_servicio_base64 || '',
																							base64Tipo: item.contrato_prestacion_servicio_base64Tipo || '',
																							archivo: item.contrato_prestacion_servicio_archivo || '',
																							formato: item.contrato_prestacion_servicio_formato || '',
																						}}/>
																					</Fragment>*/}
																					<Typography className={'px-13 margin-0 cursor-pointer'} style={{color: "blue"}} onClick={() => this.exportar(item.contrato_prestacion_servicio)}>
																						Descargar
																					</Typography>
																				</div>
																			</div>
																		</Fragment>
																	) : null}
																	
																	<Typography className={'text-left px-14 text-black margin-0 padding-0'}>
																		Observaciones: {item.observaciones || 'Sin observaciones'}
																	</Typography>
																
																</CardContent>
															</Card>
														
														</Grid>
													</Fragment>
												))}
												
												{this.state.cliente_contrato_prestacion_servicio.length <= 0 ? (
													<Fragment>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<div className={'vertical-inline w-100'}>
																<div className={'v-center padding-10-R'}>
																	<DivSvg img={img_lista_vacia} height={this.props.height || '60px'} width={this.props.width || '60px'} opacity={true}/>
																</div>
																<div className={'v-center'}>
																	<Typography className={'text-center px-14'} style={{opacity: 0.5, padding: "0px", color: 'gray'}}>
																		{"No se ha registrado el contrato de prestación de servicio."}
																	</Typography>
																</div>
															</div>
															
															{/*<VistaVacia
															paddingText={'10px'}
															padding={'10px'}
															numero={0}
															mensaje={'No se ha registrado el contrato de prestación de servicio.'}
															height={'40px'}
														/>*/}
														</Grid>
													</Fragment>
												) : null}
											
											</Grid>
										
										</CardContent>
									</Card>
								</fieldset>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6} align={'left'}>
								<fieldset className={'borde-punteado-dotted-gray-1'}>
									<legend align={'left'} style={{color: 'dimgrey'}}>Promotor</legend>
									<Card>
										<CardContent style={{background: 'lightgray', padding: '15px'}}>
											
											<Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
												
												{this.state.contrato_prestacion_servicio.map((item, key) => (
													<Fragment key={key}>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															
															<Card style={{height: '100%'}}>
																<CardContent style={{padding: '10px'}}>
																	
																	<div align={'right'}
																	     style={{
																		     width: '100%',
																		     height: 0
																	     }}
																	>
																		{!disabledFieldPromotorSubirFormatoAltaBajaEmpleado ? (
																			<Fragment>
																				<div
																					className={'cursor-pointer'}
																					style={{
																						textAlign: 'center',
																						marginLeft: 5,
																						float: 'right',
																						padding: '6px',
																						background: 'red',
																						height: '6px',
																						width: '6px',
																						lineHeight: '6px',
																						color: 'white',
																						borderRadius: '100%',
																						fontSize: '12px',
																					}}
																					onClick={() => this.desactivar_nomina_empleados(item, key)}
																					children={'x'}
																				/>
																			</Fragment>
																		) : null}
																	</div>
																	
																	<Typography
																		className={'text-right text-black margin-0 padding-0 px-12 h-0-px'}>
																		{item.created_at ? DateFormat.FechaHoraFormatText(item.created_at) : ''}
																	</Typography>
																	
																	{item.contrato_prestacion_servicio_archivo ? (
																		<Fragment>
																			<p className={'text-left text-black margin-0 padding-0 px-12'}>
																				Contrato de prestación y servicios:
																			</p>
																			<div className={'vertical-inline'}>
																				
																				<div className={'padding-5-R v-center'}>
																					{Icono.get(item.contrato_prestacion_servicio_formato, '20px', '20px')}
																				</div>
																				<div className={'v-center padding-10-R'}>
																					<Typography className={'px-13 margin-0 cursor-pointer'} style={{color: "blue"}} onClick={() => this.exportar(item.contrato_prestacion_servicio)}>
																						Descargar
																					</Typography>
																					{/*<Fragment>
																						<ModalImage tipo={'view'} desing={3} item={{
																							base64: item.contrato_prestacion_servicio_base64 || '',
																							base64Tipo: item.contrato_prestacion_servicio_base64Tipo || '',
																							archivo: item.contrato_prestacion_servicio_archivo || '',
																							formato: item.contrato_prestacion_servicio_formato || '',
																						}}/>
																					</Fragment>*/}
																				</div>
																			</div>
																		</Fragment>
																	) : null}
																	
																	<Typography className={'text-left px-12 text-black margin-0 padding-0'}>
																		Resguardo Físico: {!item.lugar_resguardo && !item.responsable_resguardo ? 'Sin resguardo': item.lugar_resguardo + (item.lugar_resguardo && item.responsable_resguardo ? ' - ':' ') + item.responsable_resguardo}
																	</Typography>
																	
																	<Typography className={'text-left px-14 text-black margin-0 padding-0'}>
																		Observaciones: {item.observaciones || 'Sin observaciones'}
																	</Typography>
																
																</CardContent>
															</Card>
														
														</Grid>
													</Fragment>
												))}
												
												{this.state.contrato_prestacion_servicio.length <= 0 ? (
													<Fragment>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<div className={'vertical-inline w-100'}>
																<div className={'v-center padding-10-R'}>
																	<DivSvg img={img_lista_vacia} height={this.props.height || '60px'} width={this.props.width || '60px'} opacity={true}/>
																</div>
																<div className={'v-center'}>
																	<Typography className={'text-center px-14'} style={{opacity: 0.5, padding: "0px", color: 'gray'}}>
																		{"No se ha registrado el contrato de prestación de servicio."}
																	</Typography>
																</div>
															</div>
														</Grid>
													</Fragment>
												) : ''}
											
											</Grid>
										
										</CardContent>
									</Card>
								</fieldset>
							</Grid>
							
							
							{!disabledFieldPromotorSubirFormatoAltaBajaEmpleado ? (
								<Fragment>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										
										<BoxSelectFile
											style={{height: '120px'}}
											label={(
												<div>
													Contrato de prestación y servicios<b className={'vertical-inline v-center text-blue'}>(Requerido)</b>
												</div>
											)}
											item={{
												base64: this.state.contrato_prestacion_servicio_base64,
												base64Tipo: this.state.contrato_prestacion_servicio_base64Tipo,
												archivo: this.state.contrato_prestacion_servicio_archivo,
												formato: this.state.contrato_prestacion_servicio_formato,
											}}
											onChange={(r) => {
												this.handleChange({
													target: {
														name: 'contrato_prestacion_servicio_base64',
														value: r.base64
													}
												});
												this.handleChange({
													target: {
														name: 'file',
														value: r.file
													}
												});
												this.handleChange({
													target: {
														name: 'contrato_prestacion_servicio_base64Tipo',
														value: r.base64Tipo
													}
												});
												this.handleChange({
													target: {
														name: 'contrato_prestacion_servicio_archivo',
														value: r.archivo
													}
												});
												this.handleChange({
													target: {
														name: 'contrato_prestacion_servicio_formato',
														value: r.formato
													}
												});
											}}
											showSnackBars={this.showSnackBars}
											disabled={disabledFieldPromotorSubirFormatoAltaBajaEmpleado}
										/>
									
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
										
										<Typography className={'px-13 margin-0 padding-0'}>
											Resguardo Físico
										</Typography>
									
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6} align={'center'}>
										
										<TextField
											multiline
											fullWidth
											onChange={this.handleChange}
											type="text"
											margin="dense"
											//required
											helperText=""
											name="lugar_resguardo"
											label="Lugar de resguardo"
											value={this.state.lugar_resguardo}
											disabled={disabledFieldPromotorSubirFormatoAltaBajaEmpleado}
										/>
									
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6} align={'center'}>
										
										<TextField
											multiline
											fullWidth
											onChange={this.handleChange}
											type="text"
											margin="dense"
											//required
											helperText=""
											name="responsable_resguardo"
											label="Responsable del resguardo"
											value={this.state.responsable_resguardo}
											disabled={disabledFieldPromotorSubirFormatoAltaBajaEmpleado}
										/>
									
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										
										<TextField
											multiline
											fullWidth
											onChange={this.handleChange}
											type="text"
											margin="dense"
											//required
											helperText=""
											name="observaciones"
											label="Observaciones"
											value={this.state.observaciones}
											disabled={disabledFieldPromotorSubirFormatoAltaBajaEmpleado}
										/>
									
									</Grid>
								
								</Fragment>
							) : null}
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={1}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!disabledFieldPromotorSubirFormatoAltaBajaEmpleado ? (
									<Button
										variant="contained"
										color="primary"
										onClick={() => this.save()}
										className={'btn-default-primary text-transform-none'}
										disabled={disabledFieldPromotorSubirFormatoAltaBajaEmpleado}
									>
										Subir contrato de prestación y servicios
									</Button>
								) : ''}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

SubirContratoPrestacionServicioPromotor.propTypes = {
	titulo: PropTypes.string.isRequired,
	id_cliente: PropTypes.number.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default SubirContratoPrestacionServicioPromotor;
