import React, {Component} from "react";
import PropTypes from 'prop-types';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import {FichaTecnicaService} from "../../../../services/FichaTecnicaService/FichaTecnicaService";
import {FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Fab from "@material-ui/core/Fab";
import {PictureAsPdfOutlined, SendOutlined, SyncOutlined} from "@material-ui/icons";
import {CONFIG} from "../../../../settings/Config/Config";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";

class DatosBancariosPromotor extends Component {
	
	state = {};
	
	Usr = {};
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			id_cliente: props.id_cliente,
			
			cliente: {},
			
			promotor_usuario_alta_cliente: {},
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	RefreshInit = () => {
		this.init();
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			FichaTecnicaService.InitDato(this.state.id_cliente).then((response) => {
				
				let dato = FieldsJs.Copy(response.data);
				
				if (response.visible === 0) {
					
					this.setState({
						visible: 0,
						mensaje: response.message
					});
					
				} else {
					
					let cliente = dato.cliente || {};
					
					this.setState({
						
						cliente: cliente,
						
						promotor_usuario_alta_cliente: dato.promotor_usuario_alta_cliente || {},
						
					});
					
				}
				
				setTimeout(() => this.openModal());
				
				resolve(true);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
				reject(false);
			});
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	disabledFieldTesoreriaDatosPromotorBolean = () => {
		
		let disabledFieldTesoreriaDatosPromotor = true;
		
		if (FieldsJs.inArray([1, 2, 6], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldTesoreriaDatosPromotor = false;
		}
		
		return disabledFieldTesoreriaDatosPromotor;
	};
	
	render() {
		
		const disabledFieldTesoreriaDatosPromotor = this.disabledFieldTesoreriaDatosPromotorBolean();
		
		let promotor = this.state.promotor_usuario_alta_cliente || {};
		let foto = '';
		
		if (promotor.foto) {
			foto = CONFIG.src + promotor.foto;
		} else {
			foto = CONFIG.foto_default;
		}
		
		const bg_foto = {
			backgroundColor: `black`,
			backgroundImage: `url(${foto})`,
		};
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Typography className={'px-20'}>
									<Fab size="small" color="secondary" aria-label="Actualizar"
									     onClick={() => this.RefreshInit()} style={{marginRight: '15px'}}>
										<SyncOutlined/>
									</Fab>
									{this.props.titulo}
								</Typography>
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent>
						<Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} align={'center'}>
								
								<div className={'padding-10-B padding-10-T'}>
									<div className={'w-100-px h-100-px card-1 b-r-100'}>
										<div className={'w-100-px h-100-px b-r-100 bg-img-contain-x-center-y-center'}
										     style={bg_foto}>
										</div>
									</div>
								</div>
								
								<Typography style={{fontSize: 20}}>
									{promotor.usuario_nombre_completo}
								</Typography>
								
								<Typography style={{fontSize: 18}}>
									RFC: {promotor.rfc || "No registrado"}
								</Typography>
								
								<Typography style={{fontSize: 18}}>
									CURP: {promotor.curp || "No registrado"}
								</Typography>
								
								<Typography style={{fontSize: 14}}>
									Correo electrónico: {promotor.correo_electronico || "No registrado"}
								</Typography>
								
								<Typography style={{fontSize: 14}}>
									Telefono: {promotor.telefono || "No registrado"}
								</Typography>
								
								<Typography style={{fontSize: 14}}>
									Celular: {promotor.celular || "No registrado"}
								</Typography>
							
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								
								<div style={{background: "#ddfffc", padding: 15}}>
									
									{(promotor.usuario_banco || []).map((value, index) => (
										<div key={index} style={{width: "100%", marginBottom: 15}}>
											<Typography style={{fontSize: 18}}>
												{value.banco}
											</Typography>
											<Typography style={{fontSize: 16}}>
												Sucursal: {value.banco_sucursal}
											</Typography>
											<Typography style={{fontSize: 14}}>
												Tipo de cuenta: {value.banco_tipo_cuenta}
											</Typography>
											<Typography style={{fontSize: 14}}>
												No. cliente: {value.numero_cliente}
											</Typography>
											<Typography style={{fontSize: 14}}>
												Numero de cuenta: {value.numero_cuenta}
											</Typography>
											<Typography style={{fontSize: 14}}>
												Clabe interbancaria: {value.clabe_interbancaria}
											</Typography>
										</div>
									))}
									
									{(promotor.usuario_banco || []).length <= 0 ? (
										<VistaVacia
											width={50}
											height={50}
											numero={0}
											mensaje={'No se encontraron cuentas bancarias.'}
										/>
									) : null}
								
								</div>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div style={{
									background: '#cecece',
									padding: 10,
									borderRadius: 7,
								}}>
									<Typography style={{fontSize: 14, textAlign: "center"}}>
										Datos bancarios
									</Typography>
									<Grid container spacing={2} direction="row" justify="center" alignItems="center">
										<Grid item>
											<Fab
												variant="extended"
												size="small"
												color="secondary"
												aria-label="add"
												style={{
													backgroundColor: '#901b30',
													fontSize: 12,
													paddingRight: 15,
													paddingLeft: 15
												}}
											>
												Ver PDF <PictureAsPdfOutlined style={{marginLeft: 10, fontSize: 20}}/>
											</Fab>
										</Grid>
										<Grid item>
											<Fab
												variant="extended"
												size="small"
												color="secondary"
												aria-label="add"
												style={{
													backgroundColor: '#4CAF50',
													fontSize: 12,
													paddingRight: 15,
													paddingLeft: 15
												}}
											>
												Enviar por correo electrónico <SendOutlined
												style={{marginLeft: 10, fontSize: 20}}/>
											</Fab>
										</Grid>
									</Grid>
								</div>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={1}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!disabledFieldTesoreriaDatosPromotor ? (
									<Button variant="contained" color="primary"
									        onClick={this.guardarTesoreriaDatosPromotor}
									        className={'btn-default-primary text-transform-none'}>
										Guardar
									</Button>
								) : ''}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

DatosBancariosPromotor.propTypes = {
	titulo: PropTypes.string.isRequired,
	id_cliente: PropTypes.number.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default DatosBancariosPromotor;
