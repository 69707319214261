import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FieldsJs} from "../../../../settings/General/General";
import TextField from "@material-ui/core/TextField";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Divider from "@material-ui/core/Divider";
import {TicketsService} from "../../../../services/TicketsService/TicketsService";
import {Send} from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import icon_email from "../../../../assets/img/icons/email.svg";
import icon_rocket_chat from "../../../../assets/img/icons/rocketchat.svg";
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import Table from '@material-ui/core/Table/index';
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import BarraProgreso from "./BarraProgreso";


class ModalReport extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			
			tipo: props.tipo,
			item: props.item,
			reporte: [],
		};
	}
	
	reporte = () => {
		return new Promise((resolve, reject) => {
			TicketsService.Reporte().then((response) => {
				this.close();
				//this.props.showSnackBars('success', response.mensaje);
				resolve(response);
			}).catch((error) => {
				//this.props.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	};
	
	openModal = () => {
		this.reporte().then((response) => {
			this.setState({
				open: true,
				scroll: 'paper',
				reporte: response.data
			});
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	closeModal = () => {
		this.setState({open: false});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	close = () => {
		this.closeModal();
	};
	
	save = () => {
		TicketsService.SendMail(this.state).then((response) => {
			this.close();
			this.props.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	render() {
		
		return (
			<span>
				
				{this.props.component ? (
					<Fragment>
						<span onClick={() => this.openModal('paper')} style={{cursor: 'pointer'}}>
							{this.props.component}
						</span>
					</Fragment>
				) : (
					<Fragment>
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={() => this.openModal('paper')}>
							<Send className={'margin-5-R px-14'}/>
							Progreso
						</Fab>
					</Fragment>
				)}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.closeModal}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal} className={'sendMessage-ticket'}
				>
					
					<DialogTitle style={{color: 'gray'}}>Progreso</DialogTitle>
					
					<DialogContent>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={2} className={'h-100'}>
									
									{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'text-center px-13'} style={{fontWeight: 'bold'}}>
											Seleccionar el tipo de notificación y el usuario a quien va dirigido el ticket
										</Typography>
									</Grid>*/}
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<div className={'borde-punteado-lightgray-1 card-0 text-center margin-10-R margin-20-B cursor-pointer'}>
											<div className={'w-100 text-center'}>
												<Table className={'desing-acresco'}>
													<TableHead>
														<TableRow>
															<TableCell component="th" align={'center'} className={'w-300-px padding-20-L padding-20-R'}>Usuario</TableCell>
															<TableCell component="th" align={'center'}>No. Tickets</TableCell>
															<TableCell component="th" align={'center'}>No. Tickets Resueltos</TableCell>
															<TableCell component="th" align={'center'}>No. Tickets Pendientes</TableCell>
															<TableCell component="th" align={'center'} className={'w-150-px'}>Cumplimiento</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{this.state.reporte.map((item, key) => {
															return (
																<Fragment key={key}>
																	<TableRow>
																		<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
																			<div className={'w-100'} align={'left'}>
																				{item.nombre_completo_asignado}
																			</div>
																		</TableCell>
																		<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
																			<div className={'w-100'} align={'center'}>
																				{item.total_tickets}
																			</div>
																		</TableCell>
																		<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
																			<div className={'w-100'} align={'center'}>
																				{item.tickets_resueltos}
																			</div>
																		</TableCell>
																		<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
																			<div className={'w-100'} align={'center'}>
																				{item.tickets_pendientes}
																			</div>
																		</TableCell>
																		<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
																			<div className={'w-100'} align={'center'}>
																				<BarraProgreso color={1} completed={Number(item.cumplimiento) || 0} buffer={100} value={Number(item.cumplimiento) || 0} size={'150px'}/>
																			</div>
																		</TableCell>
																	</TableRow>
																</Fragment>
															)
														})}
													</TableBody>
												</Table>
											</div>
										</div>
									</Grid>
									
								</Grid>
							</Grid>
						</Grid>

					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={0}>
							<Grid item xs={12} align={'right'}>
								<Button onClick={() => this.close()} color="primary">
									{"Cerrar"}
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			
			</span>
		);
	}
}

ModalReport.propTypes = {
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	component: PropTypes.element,
};

export default ModalReport;
