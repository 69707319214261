import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {Add, Domain, HowToVoteOutlined, Person, PersonOutlined, SearchOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import ModalEmpresa from "../../Cat/Empresa/Includes/ModalEmpresa";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Input from "@material-ui/core/Input";


class FiltrosCuentaBancaria extends Component {
	
	state = {};
	
	constructor() {
		super();
		this.state = {
			id_empresa: '',
			rfc: '',
			id_usuario_apoderado: '',
			id_usuario_autorizado: '',
			sucursal_apertura: '',
			correo_electronico: '',
			activo: '',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.id_empresa = this.state.id_empresa || undefined;
		filtro.rfc = this.state.rfc || undefined;
		filtro.id_usuario_apoderado = this.state.id_usuario_apoderado || undefined;
		filtro.id_usuario_autorizado = this.state.id_usuario_autorizado || undefined;
		filtro.sucursal_apertura = this.state.sucursal_apertura || undefined;
		filtro.activo = this.state.activo || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Domain className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Empresa</InputLabel>
									<Select
										native
										value={this.state.id_empresa}
										onChange={(e) => {
											this.handleChange(e);
										}}
										name="id_empresa"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.props.lista_empresa.map(option => (
											<option key={option.id_empresa}
											        value={option.id_empresa}>
												{option.nombre_comercial}
											</option>
										))}
									
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HowToVoteOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField type={'text'} fullWidth name="rfc"
								           onChange={this.handleChange}
								           inputProps={{maxLength: 13}}
								           label="RFC" autoComplete={'off'}
								           value={this.state.rfc}
								           disabled={this.props.tipo === 'view'}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Person className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Apoderado</InputLabel>
									<Select
										native
										value={this.state.id_usuario_apoderado}
										onChange={(e) => {
											this.handleChange(e);
										}}
										name="id_usuario_apoderado"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.props.lista_usuario.map(option => (
											<option key={option.id_usuario}
											        value={option.id_usuario}>
												{option.nombre_completo}
											</option>
										))}
									
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Person className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Usuario Autorizado</InputLabel>
									<Select
										native
										value={this.state.id_usuario_autorizado}
										onChange={(e) => {
											this.handleChange(e);
										}}
										name="id_usuario_autorizado"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.props.lista_usuario.map(option => (
											<option key={option.id_usuario}
											        value={option.id_usuario}>
												{option.nombre_completo}
											</option>
										))}
									
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="sucursal_apertura"
									onChange={this.handleChange}
									label="Sucursal apertura"
									autoComplete={'off'}
									value={this.state.sucursal_apertura}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel htmlFor="age-native-activo">Activo</InputLabel>
									<Select
										native
										value={this.state.activo}
										onChange={this.handleChange}
										inputProps={{
											name: 'activo',
											id: 'age-native-activo',
										}}
									>
										<option value={'all'}>Todos</option>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={2} xl={2} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosCuentaBancaria.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	lista_empresa: PropTypes.array.isRequired,
	lista_usuario: PropTypes.array.isRequired
};

export default FiltrosCuentaBancaria;
