import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {HdrStrong, MailOutline, PersonOutlined, SearchOutlined, ViewList, ViewModule} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {TipoUsuarioService} from "../../../../services/_Cat/TipoUsuarioService/TipoUsuarioService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";


class FiltrosUsuarios extends Component {
	
	state = {};
	
	constructor() {
		super();
		this.state = {
			usuario: '',
			correo_electronico: '',
			id_cat_tipo_usuario: '',
			dias_disponibles_vacaciones: '',
			lista_tipo_usuario: [],
			activo: 'all',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.usuario = this.state.usuario || null;
		filtro.correo_electronico = this.state.correo_electronico || null;
		filtro.id_cat_tipo_usuario = this.state.id_cat_tipo_usuario || null;
		filtro.dias_disponibles_vacaciones = this.state.dias_disponibles_vacaciones || null;
		filtro.activo = this.state.activo || null;
		
		this.props.HandleFiltro(filtro);
	};
	
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="usuario"
									onChange={this.handleChange}
									label="Usuario"
									autoComplete={'off'}
									value={this.state.usuario}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4} align={'left'}>
						<TextField
							type={'text'}
							fullWidth
							name="dias_disponibles_vacaciones"
							onChange={this.handleChange}
							label="Días disponibles"
							autoComplete={'off'}
							value={this.state.dias_disponibles_vacaciones}
						/>
					</Grid>
					
					<Grid item xs={12} sm={12} md={6} lg={1} xl={1} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.HandleFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosUsuarios.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosUsuarios;
