import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";
import {CONFIG} from "../../settings/Config/Config";
import {FieldsJs} from "../../settings/General/General";

import {Dropbox} from "dropbox";

export const FichaTecnicaService = {
	InitDato: (id_cliente) => {
		let params = {
			id_cliente: id_cliente
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ficha_Tecnica_Init_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarDatoPromotor: (form) => {
		
		let params = {
			
			id_cliente: form.id_cliente,
			
			id_ficha_tecnica: form.id_ficha_tecnica,
			
			actividad_ante_sat: form.actividad_ante_sat,
			rfc: form.rfc,
			servicio_requerido_propuesto: form.servicio_requerido_propuesto,
			
			ficha_tecnica_archivo_elaboracion_propuesta: form.ficha_tecnica_archivo_elaboracion_propuesta || [],
			
			ficha_tecnica_archivo_integracion_expediente: form.ficha_tecnica_archivo_integracion_expediente || [],
			
			plaza_pertenece_cliente: {
				id_plaza: form.id_plaza_pertenece_cliente
			},
			
			plaza_opera_nomina: {
				id_plaza: form.id_plaza_opera_nomina
			},
			
			sueldo_salario: form.sueldo_salario,
			cooperativa: form.cooperativa,
			honorarios: form.honorarios,
			sociedad_civil: form.sociedad_civil,
			asimilados_salario: form.asimilados_salario,
			outsourcing: form.outsourcing,
			otros: form.otros,
			
			id_cat_periodo_pago: form.id_cat_periodo_pago,
			impuesto_sobre_nomina: form.impuesto_sobre_nomina,
			
			ficha_tecnica_estado_municipio_impuesto: form.ficha_tecnica_estado_municipio_impuesto || [],
			ficha_tecnica_otro_impuesto: form.ficha_tecnica_otro_impuesto || [],
			
			id_cat_factor_riesgo_imss: form.id_cat_factor_riesgo_imss,
			id_cat_estado_entidad_trabajo: form.id_cat_estado_entidad_trabajo,
			id_cat_municipio_entidad_trabajo: form.id_cat_municipio_entidad_trabajo,
			
			cooperativa_alta_imss: form.cooperativa_alta_imss,
			cooperativa_porcentaje_comision_paga_trabajador: form.cooperativa_porcentaje_comision_paga_trabajador,
			
			trabajador_entrega_recibo_honorarios: form.trabajador_entrega_recibo_honorarios,
			pago_realiza_fuera_es_no_deducible: form.pago_realiza_fuera_es_no_deducible,
			outsourcing_porcentaje_paga_servicio: form.outsourcing_porcentaje_paga_servicio,
			asimilados_hace_retencion_isr: form.asimilados_hace_retencion_isr,
			
			tienen_otras_prestaciones_aparte_salario: form.tienen_otras_prestaciones_aparte_salario,
			
			vacaciones_ley_o_especiales_cuantos_dias: form.vacaciones_ley_o_especiales_cuantos_dias,
			prima_vacacional_ley_o_especiales_cuantos_dias: form.prima_vacacional_ley_o_especiales_cuantos_dias,
			gratificacion_anual_ley_o_especiales_cuantos_dias: form.gratificacion_anual_ley_o_especiales_cuantos_dias,
			
			
			ficha_tecnica_prestacion: form.ficha_tecnica_prestacion || [],
			
			comentario_adicional_elaboracion_propuesta: form.comentario_adicional_elaboracion_propuesta,
			
			comentario_observacion_desarrollador: form.comentario_observacion_desarrollador,
			
			siroc: form.siroc || 0
			
		};
		console.log("FORM: ", form);
		return new Promise((resolve, reject) => {
			let ws = '';
			if (params.id_ficha_tecnica) {
				ws = '_Promotor_Requerimiento_Cliente_Modificar';
			} else {
				ws = '_Promotor_Requerimiento_Cliente_Guardar';
			}
			HttpRequest.post(ws, params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarDatoFiscal: (form) => {
		console.log("GUARDARDATOFISCALSERVICE", form);
		let params = {
			
			id_cliente: form.id_cliente,
			
			id_ficha_tecnica: form.id_ficha_tecnica,
			
			ficha_tecnica_archivo_propuesta_fiscal: form.ficha_tecnica_archivo_propuesta_fiscal,
			
			comentario_observacion_desarrollador: form.comentario_observacion_desarrollador,
			
		};
		return new Promise((resolve, reject) => {
			let ws = '';
			if (params.id_ficha_tecnica) {
				ws = '_Fiscal_Archivo_Propuesta_Guardar';
			} else {
				ws = '_Fiscal_Archivo_Propuesta_Modificar';
			}
			HttpRequest.post(ws, params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarDatoPromotorRespuesta: (form) => {
		
		let params = {
			
			id_cliente: form.id_cliente,
			
			id_ficha_tecnica: form.id_ficha_tecnica,
			
			cliente_cancelo: (form.cliente_cancelo) ? 1 : 0,
			cliente_requiere_nueva_propuesta: (form.cliente_requiere_nueva_propuesta) ? 1 : 0,
			cliente_acepto: (form.cliente_acepto) ? 1 : 0,
			
			porque_requiere_nueva_propuesta: form.porque_requiere_nueva_propuesta,
			
			comentario_observacion_desarrollador: form.comentario_observacion_desarrollador
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Promotor_Respuesta_Cliente_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarDatoIntegracionExpediente: (form, ficha_tecnica_archivo_integracion_expediente) => {
		
		let expedientes = [];
		
		for (let i = 0; i < ficha_tecnica_archivo_integracion_expediente.length; i++) {
			let item = ficha_tecnica_archivo_integracion_expediente[i];
			expedientes.push({
				id_cat_archivo_integracion_expediente: item.id_cat_archivo_integracion_expediente,
				id_ficha_tecnica_archivo_integracion_expediente: item.id_ficha_tecnica_archivo_integracion_expediente,
				id_ficha_tecnica: item.id_ficha_tecnica,
				titulo: item.titulo,
				descripcion: item.descripcion,
				archivo: item.archivo,
				formato: item.formato
			})
		}
		
		let fecha_firma_contrato = DateFormat.FormatSql(form.fecha_firma_contrato);
		
		let fecha_alta_imss = DateFormat.FormatSql(form.fecha_alta_imss);
		
		let confirmacion_fecha_primera_dispersion = DateFormat.FormatSql(form.confirmacion_fecha_primera_dispersion);
		
		let params = {
			
			id_cliente: form.id_cliente,
			
			id_ficha_tecnica: form.id_ficha_tecnica,
			
			fecha_firma_contrato: fecha_firma_contrato,
			
			ficha_tecnica_archivo_integracion_expediente: expedientes,
			
			fecha_alta_imss: fecha_alta_imss,
			confirmacion_fecha_primera_dispersion: confirmacion_fecha_primera_dispersion,
			id_cat_periodo_pago_tipo_nomina: form.id_cat_periodo_pago_tipo_nomina,
			comision_propuesta: form.comision_propuesta,
			ficha_tecnica_cantidad_empleado_banco: form.ficha_tecnica_cantidad_empleado_banco,
			ficha_tecnica_cantidad_empleado_esquema: form.ficha_tecnica_cantidad_empleado_esquema,
			iva: form.iva,
			retencion_isr_en_caso_de_ias: form.retencion_isr_en_caso_de_ias,
			retencion_isn_en_caso_de_ias: form.retencion_isn_en_caso_de_ias,
			
			comentario_observacion_desarrollador: form.comentario_observacion_desarrollador
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Promotor_Integracion_Expediente_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarDatoIntegracionExpedienteXId: (form) => {
		let params = {
			id_cliente: form.id_cliente,
			id_ficha_tecnica: form.id_ficha_tecnica,
			id_cat_archivo_integracion_expediente: form.id_cat_archivo_integracion_expediente,
			id_ficha_tecnica_archivo_integracion_expediente: form.id_ficha_tecnica_archivo_integracion_expediente,
			archivo: form.archivo,
			formato: form.formato
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Promotor_Integracion_Expediente_X_Id_Guardar', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarExpedienteTrabajador: (form) => {
		let params = {
			id_cliente: form.id_cliente,
			id_ficha_tecnica: form.id_ficha_tecnica,
			id_cat_esquema: form.id_cat_esquema,
			archivo: form.archivo,
			rfc_trabajador: form.rfc_trabajador,
			curp_trabajador: form.curp_trabajador,
			identificacion_oficial: form.identificacion_oficial,
			rfc: form.rfc,
			curp: form.curp,
			acta_nacimiento: form.acta_nacimiento,
			solicitud_empleo: form.solicitud_empleo,
			comprobante_domicilio: form.comprobante_domicilio,
			certificado_estudios: form.certificado_estudios,
			cv: form.cv,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Promotor_Expediente_Trabajador', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
