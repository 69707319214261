import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {FieldsJs} from "../../../../settings/General/General";
import {ClientePotencialService} from "../../../../services/ClientePotencialService/ClientePotencialService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {CONFIG} from "../../../../settings/Config/Config";

// import {VentasService} from "../../../../services/VentasService/VentasService";


class ModalReasignarPromotor extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			item: {},
			id_usuario: '',
			usuario_promotor: [],
		};
	}
	
	openModal = () => {
		
		this.usuario_promotor();
		
		const {item} = this.props;
		console.log(item);
		this.setState({
			modal: {
				open: true
			},
			id_usuario: item.id_usuario_alta_cliente || '',
			item: item,
		});
		console.log(this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	usuario_promotor = () => {
		CatService.ListUsuarioPromotor().then(response => {
			this.setState({
				usuario_promotor: response.data || []
			})
		}).catch(error => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	save = () => {
		
		console.log(this.state);
		
		let nombre_completo = '';
		
		for (let i = 0; i < this.state.usuario_promotor.length; i++) {
			if (Number(this.state.usuario_promotor[i].id_usuario) === Number(this.state.id_usuario)) {
				nombre_completo = this.state.usuario_promotor[i].nombre_completo;
			}
		}
		
		let msg = `¿Deseas reasignar a ${nombre_completo} para el cliente ${this.state.item.razon_social}?`;
		
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Aceptar') {
				try {
					if (!this.state.id_usuario) {
						throw Object({
							status: false,
							mensaje: "Selecciona un promotor para asignar a la ficha técnica"
						});
					}
					ClientePotencialService.ReasignarPromotor(this.state.item.id_cliente, this.state.id_usuario).then((response) => {
						this.closeModal();
						this.props.RefreshList();
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				} catch (e) {
					this.props.showSnackBars('error', e.mensaje);
				}
			}
			
		});
		
	};
	
	render() {
		
		return (
			<div>
				
				<div onClick={() => this.openModal()}>
					{this.props.icono}
				</div>
				
				<Dialog open={this.state.modal.open} onClose={() => this.closeModal()} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						Reasignar promotor
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={3} alignContent={"center"} alignItems={"center"}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
								
								<TextField
									select
									fullWidth
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {},
									}}
									
									name="id_usuario"
									label="Promotor"
									value={this.state.id_usuario}
								>
									<option value={''}>&nbsp;</option>
									{this.state.usuario_promotor.map(option => (
										<option key={option.id_usuario} value={option.id_usuario}>
											{option.nombre} {option.apellido_paterno} {option.apellido_materno}
										</option>
									))}
								</TextField>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Button onClick={() => this.closeModal()} color="primary">
							Cerrar
						</Button>
						<Button onClick={() => this.save()} color="primary">
							Aceptar
						</Button>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}


ModalReasignarPromotor.propTypes = {
	item: PropTypes.object.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalReasignarPromotor;
