import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import {Icono} from "../../../../componets/Include/Icono/Icono"
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../../settings/Config/Config";
import {Dropbox} from "dropbox";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";


class ModalSeleccionarArchivoElaboracionPropuesta extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			scroll: 'paper',
			tipo: props.tipo,
			
			id_ficha_tecnica_archivo_elaboracion_propuesta: '',
			id_cat_archivo_elaboracion_propuesta: '',
			titulo: '',
			descripcion: '',
			base64: '',
			base64Tipo: '',
			archivo: '',
			formato: '',
			file: ''
		};
	}
	
	showSnackBars = (type, message) => {
		if (message) {
			ShowSnackBarsNotistack(this.props, type, message, null);
		}
	};
	
	handleChange = (e) => {
		const {value, name, checked, type} = e.target;
		this.setState({
			[name]: type === 'checkbox' ? checked : value
		});
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	closeModal = () => {
		this.setState({open: false});
	};
	
	fileSelect = () => {
		document.getElementById('archivo_promotor').click();
	};
	
	getBase64 = (e) => {
		let formatos = [
			"image/jpeg",
			"image/png",
			"image/svg",
			"application/pdf",
			"application/xlsx",
			"application/csv",
			"application/docx",
			"text/txt",
			".csv",
			"text/csv",
		];
		
		this.setState({
			file: e.target.files[0]
		});
		
		console.log("getBase64::: ", this.state);
		
		/*const accessToken = CONFIG.DROPBOX_TOKEN;
			const dbx = new Dropbox({accessToken, fetch});
			dbx.filesDownload({
				path: '/ficha_tecnica_archivo_elaboracion_propuesta/Resto del Mundo S.A. de C.V./rets890702sf2-Guia.pdf'
			}).then(function (data) {
				console.log("DROPBOX::: ", data);
				const url = URL.createObjectURL(data.fileBlob);
				const a = document.createElement('a');
				a.href = url;
				a.download = data.name || 'download';
				const clickHandler = () => {
					setTimeout(() => {
						URL.revokeObjectURL(url);
					}, 150);
				};
				a.addEventListener('click', clickHandler, false);
				a.click();
				
				return a;
			}).catch(function (error) {
				console.error(error);
			});
			return*/
		
		FileBase64.Base64(e.target, formatos, '', true).then((response) => {
			this.setState({
				base64: response.base64,
				base64Tipo: response.base64Tipo,
				archivo: response.archivo,
				formato: response.formato,
				// file: ''
			});
			console.log("FileBase64:::", this.state);
		}).catch((error) => {
			this.setState({
				base64: '',
				base64Tipo: '',
				archivo: '',
				formato: '',
				file: ''
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	add = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_ficha_tecnica_archivo_elaboracion_propuesta: '',
			id_cat_archivo_elaboracion_propuesta: item.id_cat_archivo_elaboracion_propuesta,
			titulo: '',
			descripcion: '',
			base64: '',
			base64Tipo: '',
			archivo: '',
			formato: '',
			file: ''
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_ficha_tecnica_archivo_elaboracion_propuesta: item.id_ficha_tecnica_archivo_elaboracion_propuesta,
			id_cat_archivo_elaboracion_propuesta: item.id_cat_archivo_elaboracion_propuesta,
			titulo: item.titulo,
			descripcion: item.descripcion,
			base64: item.base64,
			base64Tipo: item.base64Tipo,
			archivo: item.archivo,
			formato: item.formato,
			file: item.file
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_ficha_tecnica_archivo_elaboracion_propuesta: item.id_ficha_tecnica_archivo_elaboracion_propuesta,
			id_cat_archivo_elaboracion_propuesta: item.id_cat_archivo_elaboracion_propuesta,
			titulo: item.titulo,
			descripcion: item.descripcion,
			base64: item.base64,
			base64Tipo: item.base64Tipo,
			archivo: item.archivo,
			formato: item.formato,
			file: item.file
		});
		
		this.openModal('paper');
		
	};
	
	save = () => {
		if (this.state.id_ficha_tecnica_archivo_elaboracion_propuesta) {
			if (!this.state.archivo) {
				this.showSnackBars('error', 'Selecciona un archivo');
				return;
			}
			if (!this.state.titulo) {
				this.showSnackBars('error', 'Coloca el titulo del archivo');
				return;
			}
			/*if (!this.state.descripcion) {
				this.showSnackBars('error', 'Realiza una descripción breve del archivo');
				return;
			}*/
			this.props.$BroadcastModalSeleccionarArchivoElaboracionPropuesta({
				status: true,
				accion: 'update_archivo_elaboracion_propuesta',
				id_ficha_tecnica_archivo_elaboracion_propuesta: this.state.id_ficha_tecnica_archivo_elaboracion_propuesta,
				id_cat_archivo_elaboracion_propuesta: this.state.id_cat_archivo_elaboracion_propuesta,
				titulo: this.state.titulo,
				descripcion: this.state.descripcion,
				// base64Tipo: '',
				// archivo: '',
				// formato: '',
				base64Tipo: this.state.base64Tipo,
				archivo: this.state.archivo,
				formato: this.state.formato,
				file: this.state.file
			});
		} else {
			if (!this.state.archivo) {
				this.showSnackBars('error', 'Selecciona un archivo');
				return;
			}
			if (!this.state.titulo) {
				this.showSnackBars('error', 'Coloca el titulo del archivo');
				return;
			}
			/*if (!this.state.descripcion) {
				this.showSnackBars('error', 'Realiza una descripción breve del archivo');
				return;
			}*/
			this.props.$BroadcastModalSeleccionarArchivoElaboracionPropuesta({
				status: true,
				accion: 'add_archivo_elaboracion_propuesta',
				id_ficha_tecnica_archivo_elaboracion_propuesta: ('id_' + (+new Date()).toString()),
				id_cat_archivo_elaboracion_propuesta: this.state.id_cat_archivo_elaboracion_propuesta,
				titulo: this.state.titulo,
				descripcion: this.state.descripcion,
				base64Tipo: this.state.base64Tipo,
				archivo: this.state.archivo,
				formato: this.state.formato,
				file: this.state.file
			});
		}
		this.closeModal();
	};
	
	viewDbx = (item) => {
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		let path = item.archivo;
		console.log("ITEM::: ", item, path);
		
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
	}
	
	BOTON_ACCION_IMAGEN = (item) => {
		let BTNS = '';
		switch (this.state.tipo) {
			case 'add':
				item = {
					base64: this.state.base64,
					archivo: this.state.archivo,
					base64Tipo: this.state.base64Tipo,
					formato: this.state.formato,
					file: this.state.file
				};
				BTNS = (
					<div>
						<Button size="small" color="primary" onClick={() => this.fileSelect()}>
							Cambiar
						</Button>
						<Button size="small" color="primary" onClick={() => this.viewDbx(item)} className={'cursor-pointer'}>
							Ver
						</Button>
						{/*<ModalImage tipo={this.state.tipo} desing={1} item={item}/>*/}
					</div>
				);
				break;
			case 'edit':
				BTNS = (
					<div>
						<Button size="small" color="primary" onClick={() => this.viewDbx(item)} className={'cursor-pointer'}>
							Ver
						</Button>
						{/*<ModalImage tipo={this.state.tipo} desing={1} item={item}/>*/}
					</div>
				);
				break;
			case 'view':
				BTNS = (
					<div>
						<Button size="small" color="primary" onClick={() => this.viewDbx(item)} className={'cursor-pointer'}>
							Ver
						</Button>
						{/*<ModalImage tipo={this.state.tipo} desing={1} item={item}/>*/}
					</div>
				);
				break;
		}
		return BTNS;
	};
	
	render() {
		
		const {item} = this.props;
		
		let BTN_ACTION;
		
		let HTML_FILE;
		
		let HTML_BOTON_ACCION_IMAGEN = this.BOTON_ACCION_IMAGEN(item);
		
		let HTML_AVG = Icono.get(this.state.formato, '60px', 'auto');
		
		if (this.state.tipo === 'add') {
			BTN_ACTION = (
				<Fragment>
					{(this.props.especifico.label) ? (
						<Tooltip TransitionComponent={Zoom} placement={"bottom"}
						         title={(!!item.id_ficha_tecnica_archivo_elaboracion_propuesta) ? (this.props.especifico.label + ": " + item.titulo + "(" + item.descripcion + ")") : ""}>
							
							<div
								className={'w-100 h-90-px borde-punteado-danger-2 margin--1 vertical-inline bg-ghostwhite'}>
								<div className={'v-center w-100 cursor-pointer'} onClick={() => {
									if (!!item.id_ficha_tecnica_archivo_elaboracion_propuesta) {
										this.edit();
									} else {
										this.add();
									}
								}}>
									{(!!item.id_ficha_tecnica_archivo_elaboracion_propuesta) ? (
										<div className={'v-center padding-0-T padding-0-B padding-10'}>
											{Icono.get(item.formato, '100%', 'auto')}
										</div>
									) : (
										<div
											className={'v-center padding-0-T padding-0-B padding-3 vertical-inline h-70-px'}>
											<div className={'v-center'}>
												<div className={'px-9'}>
													{this.props.especifico.label}
												</div>
												{this.props.especifico.is_required ? (
													<div className={'px-8 text-danger margin-3-T'}>
														(Requerido)
													</div>
												) : ''}
											</div>
										</div>
									)}
								</div>
								{/*<div style={{height: '0px', width: '0px'}}>
									<div style={{
										float: 'right',
										position: 'relative',
										padding: '6px',
										background: 'red',
										height: '6px',
										width: '6px',
										lineHeight: '6px',
										color: 'white',
										borderRadius: '100%',
										fontSize: '12px',
										right: '-9px',
										top: '-9px',
									}} className={'cursor-pointer'}
									     onClick={() => this.props.removefilepromotor(item, this.props.index)}>x
									</div>
								</div>*/}
							</div>
						</Tooltip>
					) : (
						<Tooltip TransitionComponent={Zoom} placement={"bottom"}
						         title={"Click para seleccionar otro archivo"}>
							<div
								className={'w-100 h-90-px borde-punteado-danger-2 margin--1 vertical-inline bg-ghostwhite'}>
								<div className={'v-center w-100 cursor-pointer'} onClick={() => this.add()}>
									<div className={'v-center padding-0-T padding-0-B padding-10'}>
										{Icono.get('add', '30px', '30px')}
									</div>
								</div>
							</div>
						</Tooltip>
					)}
				</Fragment>
			);
		} else if (this.state.tipo === 'edit') {
			BTN_ACTION = (
				<Fragment>
					<Tooltip TransitionComponent={Zoom} placement={"bottom"}
					         title={item.titulo + "(" + item.descripcion + ")"}>
						<div
							className={'w-100 h-90-px borde-punteado-danger-2 margin--1 vertical-inline bg-ghostwhite'}>
							<div className={'v-center w-100 cursor-pointer'} onClick={() => this.edit()}>
								<div className={'v-center padding-0-T padding-0-B padding-10'}>
									{Icono.get(item.formato, '100%', 'auto')}
								</div>
							</div>
							<div style={{height: '0px', width: '0px'}}>
								<div style={{
									float: 'right',
									position: 'relative',
									padding: '6px',
									background: 'red',
									height: '6px',
									width: '6px',
									lineHeight: '6px',
									color: 'white',
									borderRadius: '100%',
									fontSize: '12px',
									right: '-9px',
									top: '-9px',
								}} className={'cursor-pointer'}
								     onClick={() => this.props.removefilepromotor(item, this.props.index)}>x
								</div>
							</div>
						</div>
					</Tooltip>
				</Fragment>
			);
		} else if (this.state.tipo === 'view') {
			BTN_ACTION = (
				<Fragment>
					{(this.props.especifico.label) ? (
						<Tooltip TransitionComponent={Zoom} placement={"bottom"}
						         title={(!!item.id_ficha_tecnica_archivo_elaboracion_propuesta) ? (this.props.especifico.label + ": " + item.titulo + "(" + item.descripcion + ")") : ""}>
							
							<div
								className={'w-100 h-90-px borde-punteado-danger-2 margin--1 vertical-inline bg-ghostwhite'}>
								<div className={'v-center w-100 cursor-pointer'} onClick={() => {
									if (!!item.id_ficha_tecnica_archivo_elaboracion_propuesta) {
										this.view()
									}
								}}>
									{(!!item.id_ficha_tecnica_archivo_elaboracion_propuesta) ? (
										<div className={'v-center padding-0-T padding-0-B padding-10'}>
											{Icono.get(item.formato, '100%', 'auto')}
										</div>
									) : (
										<div
											className={'v-center padding-0-T padding-0-B padding-3 vertical-inline h-70-px'}>
											<div className={'v-center opacity-lista-vacia'}>
												<div className={'px-9'}>
													{this.props.especifico.label}
												</div>
												{this.props.especifico.is_required ? (
													<div className={'px-8 text-danger margin-3-T'}>
														(Requerido)
													</div>
												) : ''}
											</div>
										</div>
									)}
								</div>
							</div>
						</Tooltip>
					) : (
						<Tooltip TransitionComponent={Zoom} placement={"bottom"}
						         title={item.titulo + "(" + item.descripcion + ")"}>
							<div
								className={'w-100 h-90-px borde-punteado-danger-2 margin--1 vertical-inline bg-ghostwhite'}>
								<div className={'v-center w-100 cursor-pointer'} onClick={() => this.view()}>
									<div className={'v-center padding-0-T padding-0-B padding-10'}>
										{Icono.get(item.formato, '100%', 'auto')}
									</div>
								</div>
							</div>
						</Tooltip>
					)}
				</Fragment>
			);
		}
		
		if (this.state.archivo) {
			HTML_FILE = (
				<div className={'w-100 h-100 padding-2'}>
					<div className={'w-100 h-100 borde-punteado-danger-2 vertical-inline bg-ghostwhite'}>
						<div className={'padding-0-T padding-0-B v-center w-100'}>
							<div className={'w-100'} align={'center'}>
								<div>{HTML_AVG}</div>
							</div>
							<div className={'w-100 margin-10-T'} align={'center'}>
								{HTML_BOTON_ACCION_IMAGEN}
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			HTML_FILE = (
				<div className={'w-100 h-100 padding-2'}>
					<div className={'w-100 h-100 borde-punteado-danger-2 bg-ghostwhite'}
					     onClick={() => this.fileSelect()}>
						<div className={'vertical-inline w-100 h-100'}>
							<div className={'v-center padding-0-T padding-0-B w-100'} align={'center'}>
								{Icono.get('add', '30px', '30px')}
							</div>
						</div>
					</div>
				</div>
			);
		}
		
		return (
			<Fragment>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.closeModal}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						{this.state.tipo === 'add' ? 'Agregar' : (this.state.tipo === 'edit' ? 'Editar' : (this.state.tipo === 'view' ? 'Detalle' : ''))} archivo
						para elaboración de propuestas
						{this.props.especifico.label ? (
							<Fragment>
								<br/>
								<p className={'px-16 text-gray padding-0 margin-0'}>{this.props.especifico.label}</p>
							</Fragment>
						) : ""}
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={3}>
							
							<Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={'padding-10'}>
								
								<input type="file" id="archivo_promotor" onChange={(e) => this.getBase64(e)}
								       className={'display-none'}/>
								
								{HTML_FILE}
							
							</Grid>
							
							<Grid item xs={12} sm={8} md={8} lg={8} xl={8} className={'padding-10'}>
								
								<TextField
									autoFocus
									fullWidth
									type="text"
									margin="dense"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									
									required
									name="titulo"
									label="Título"
									helperText="Título del archivo"
									defaultValue={this.state.titulo}
								/>
								
								<TextField
									fullWidth
									multiline
									type="text"
									margin="dense"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									
									name="descripcion"
									label="Descripción"
									helperText="Descripción breve del archivo seleccionado"
									defaultValue={this.state.descripcion}
								/>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={this.closeModal} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={this.save} color="primary">
								{this.state.id_ficha_tecnica_archivo_elaboracion_propuesta ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</Fragment>
		);
	}
}

ModalSeleccionarArchivoElaboracionPropuesta.propTypes = {
	tipo: PropTypes.string.isRequired,
	especifico: PropTypes.object.isRequired,
	removefilepromotor: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalSeleccionarArchivoElaboracionPropuesta);
