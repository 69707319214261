import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import {ComprasService} from "../../../../services/ComprasService/ComprasService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {AddOutlined, CancelOutlined, DeleteOutlined, EditOutlined, PlaylistAddOutlined, SaveOutlined} from '@material-ui/icons';
import Fab from "@material-ui/core/Fab";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";

import carrito_de_compra_3 from '../../../../assets/img/icons/carro-de-la-compra-3.svg'
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ModalEmpresa from "./ModalEmpresa";
import ModalPlaza from "../../Cat/Plaza/Includes/ModalPlaza";
import ModalOficina from "./ModalOficina";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {CONFIG} from "../../../../settings/Config/Config";
import {PlazaService} from "../../../../services/_Cat/Plaza/PlazaService";
import {PlazaDireccionService} from "../../../../services/_Cat/PlazaDireccion/PlazaDireccionService";
import {EmpresaPlazaOficinaService} from "../../../../services/ComprasService/EmpresaPlazaOficinaService";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import ModalProveedor from "./ModalProveedor";
import {ProveedorService} from "../../../../services/ProveedorService/ProveedorService";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TabContext from "@material-ui/lab/TabContext";
import AppBar from "@material-ui/core/AppBar";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import {TabPanel} from "@material-ui/lab";
import {DropzoneArea} from "material-ui-dropzone";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import ModalComprasServicio from "./ModalComprasServicio";
import utf8 from "utf8";

class ModalObservaciones extends Component {

	state = {};

	Usr = {};

	constructor(props) {
		super(props);

		this.Usr = ReactLocalStorageService.get('Usr') || {};

		this.state = {
			modal: {
				open: false
			},

			id_compra: props.id_compra,
			observaciones: ''
		};
	}

	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};

	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};

	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};

	close = () => {
		this.closeModal();
	};

	init = () => {
		const {item} = this.props;
		return new Promise((resolve) => {
			
			this.setState({
				id_compra: item.id_compra,
				observaciones: item.observaciones || '',
			});
			
			resolve(true);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};

	render() {

		return (
			<div>

				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>

				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>

					<DialogTitle>
						Observaciones
					</DialogTitle>

					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{this.state.observaciones}
							</Grid>
						</Grid>
					</DialogContent>

					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={12} align={'right'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>

				</Dialog>

			</div>
		);
	}
}

ModalObservaciones.propTypes = {
	id_compra: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalObservaciones;
