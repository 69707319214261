import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import {CargaCFDIService} from "../../../../services/CargaCFDIService/CargaCFDIService";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {CronogramaService} from "../../../../services/CronogramaService/CronogramaService";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import {EditOutlined, EventNote, GetApp, Group} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {CONFIG} from "../../../../settings/Config/Config";
import {CatService} from "../../../../services/_Cat/CatService/CatService";


class ModalMasivo extends Component {
	
	Usr = {};
	state = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			comprobante_base64: '',
			comprobante_base64Tipo: '',
			comprobante_archivo: '',
			comprobante_formato: '',
			
			lista_usuario_autorizado: [],
			usuarios_notificar: [],
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.setState({
			comprobante_base64: '',
			comprobante_base64Tipo: '',
			comprobante_archivo: '',
			comprobante_formato: '',
		});
		this.ListUsuarios();
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	ListUsuarios = () => {
		return new Promise((resolve, reject) => {
			CatService.ListUsuario().then((response) => {
				let notificar = [];
				if (!FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario)) {
					for (let i = 0; i < response.data.length; i++) {
						if (Number(this.Usr.id_cat_tipo_usuario) === Number(response.data[i].id_cat_tipo_usuario) || Number(response.data[i].id_cat_tipo_usuario) === 13) {
							notificar.push(response.data[i]);
						}
					}
				} else {
					notificar = response.data;
				}
				this.setState({
					lista_usuario_autorizado: notificar
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_usuarios: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	subir_comprobante = () => {
		try {
			
			if (!this.state.comprobante_formato) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Selecciona el comprobante.'
				})
			}
			
			if (!this.state.comprobante_archivo) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Selecciona un comprobante.'
				})
			}
			
			try {
				CronogramaService.SubirArchivoVerificacion(this.state).then((response) => {
					this.setState({
						comprobante_base64: '',
						comprobante_base64Tipo: '',
						comprobante_archivo: '',
						comprobante_formato: '',
						
					});
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.setState({
						comprobante_base64: '',
						comprobante_base64Tipo: '',
						comprobante_archivo: '',
						comprobante_formato: '',
						
					});
					this.props.showSnackBars('error', error.mensaje);
				});
			} catch (e) {
				this.props.showSnackBars('error', e.mensaje);
			}
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	excelMasivoExport = (item, archivo, auth) => {
		try {
			
			let params = {
				auth: auth,
				archivo: archivo
			};
			
			HttpRequest.export('_Cronograma_Masivo_Export_Original', ['Masivo', 'Cronograma'], params, false, false, false, archivo === "EXCEL");
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	exportar = (item, archivo, auth) => {
		try {
			
			let params = {
				auth: auth,
				archivo: archivo
			};
			
			HttpRequest.export('_Cronograma_Masivo_Export', ['Masivo', 'Cronograma'], params, false, false, false, archivo === "EXCEL");
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	asignarUsuarioReporte = (id_usuario, id_usuario_autorizado) => {
		let usuarios = FieldsJs.Copy(this.state.lista_usuario_autorizado);
		let users = FieldsJs.Copy(this.state.usuarios_notificar);
		let banduser = false;
		let userkey = 0;
		for (let i = 0; i < users.length; i++) {
			if (Number(users[i].id_usuario) === Number(id_usuario)) {
				banduser = true;
				userkey = i;
			}
		}
		if (!banduser) {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = true;
					if (id_usuario_autorizado) {
						usuarios[i].id_usuario = id_usuario_autorizado;
					}
					users.push(usuarios[i]);
				}
			}
		} else {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = false;
				}
			}
			users.splice(userkey, 1);
		}
		this.setState({
			lista_usuario_autorizado: usuarios,
			usuarios_notificar: users,
		});
		console.log("ASIGNARUSUARIO", users, usuarios, this.state.usuarios_notificar);
	};
	
	render() {
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => this.open()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_empresa}
						tipo={this.state.tipo}
						onClick={() => this.open()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<div className={'vertical-inline w-100'}>
							<div className={'v-center w-100-400-px'}>
								Importar Actividades
							</div>
							<div className={'v-center w-400-px text-right'}>
								{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
									<Fab className={'margin-10-R'} variant="extended" size="small" color="primary" style={{backgroundColor: "#004f63"}} onClick={() => this.excelMasivoExport({}, 'EXCEL', true)}>
										<GetApp style={{fontSize: 15, marginRight: 5}}/> Descargar Layout
									</Fab>
								) : null}
								<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#006300"}} onClick={() => this.exportar({}, 'EXCEL', true)}>
									<GetApp style={{fontSize: 15, marginRight: 5}}/> Descargar Layout
								</Fab>
							</div>
						</div>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							<Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
								<BoxSelectFile
									MegaByte={5}
									label={(
										<b className={'vertical-inline v-center text-blue'}>Archivo Excel</b>
									)}
									style={{
										color: 'black',
										height: '200px'
									}}
									item={{
										base64: this.state.comprobante_base64,
										base64Tipo: this.state.comprobante_base64Tipo,
										archivo: this.state.comprobante_archivo,
										formato: this.state.comprobante_formato,
									}}
									onChange={(r) => {
										this.handleChange({
											target: {
												name: 'comprobante_base64',
												value: r.base64
											}
										});
										this.handleChange({
											target: {
												name: 'comprobante_base64Tipo',
												value: r.base64Tipo
											}
										});
										this.handleChange({
											target: {
												name: 'comprobante_archivo',
												value: r.archivo
											}
										});
										this.handleChange({
											target: {
												name: 'comprobante_formato',
												value: r.formato
											}
										});
									}}
									showSnackBars={this.props.showSnackBars}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
								<Grid container className={'text-left borde-punteado-lightgray-2 padding-10'} alignItems={"flex-center"}>
									<Grid className={'w-30-px'}>
										<Group className={'w-100 text-gray'}/>
									</Grid>
									<Grid className={'w-100-30-px'}>
										<Typography className={'px-14 text-left text-gray margin-2-T'}>
											{'Notificar a:'}
										</Typography>
									</Grid>
									<Grid className={'w-100'}>
										<div style={{minHeight: 152, maxHeight: 152, overflowY: 'auto'}}>
											{this.state.lista_usuario_autorizado.map((item, index) => (
												<Fragment key={index}>
													<div className={'vertical-inline w-95 cursor-pointer'} onClick={() => {
														if (this.props.tipo !== 'view') {
															this.asignarUsuarioReporte(item.id_usuario);
														}
													}}>
														<div className='v-center padding-10-L'>
															<FormGroup row className={'text-center check-recuser'}>
																<FormControlLabel
																	control={
																		<Checkbox
																			type="checkbox"
																			color="primary"
																			checked={item.check}
																			value={item.check}
																			disabled={this.props.tipo === 'view'}
																		/>
																	}
																	label={''}
																/>
															</FormGroup>
														</div>
														
														<div className={'v-center vertical-inline'}>
															<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
															     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
															</div>
															<Typography variant={'h5'} className={'v-center padding-15-L margin-0 text-black px-13'}>
																{item.nombre_completo}
															</Typography>
														</div>
													</div>
												</Fragment>
											))}
										</div>
									</Grid>
								</Grid>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								<Button onClick={() => this.subir_comprobante()} color="primary" variant={"contained"}>
									Importar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ModalMasivo.propTypes = {
	componente: PropTypes.element,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalMasivo;
