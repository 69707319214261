import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../../settings/General/General";
import {AttachFile, AttachMoney, Business, DeleteOutlined, Description, EditOutlined, HdrStrong, HomeWork, House, SearchOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DocSvg from "../../../../../assets/img/icons/doc.svg";
import {DropzoneArea} from "material-ui-dropzone";
import {DropboxApi} from "../../../../../settings/Dropbox/DropboxApi";
import EmpresaAcrescoSucursalService from "../../../../../services/_Cat/EmpresaAcrescoSucursalService/EmpresaAcrescoSucursalService";
import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import moment from "moment";
import {BotonAccionAbrirModal, TituloHeaderModal} from "../../../../Include/MiniComponents/GlobalComponent";
import Divider from "@material-ui/core/Divider";
import ModalCentroTrabajoRepositorioJuridico from "../../CentroTrabajoRepositorioJuridico/Includes/ModalCentroTrabajoRepositorioJuridico";
import Add from "@material-ui/icons/Add";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import ModalEmpresaSucursal from "../../EmpresaSucursal/Includes/ModalEmpresaSucursal";
import {CatService} from "../../../../../services/_Cat/CatService/CatService";


class ModalEliminarSucursalesSAT extends Component {
	
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_empresa_sucursal: (props.item || '').id_empresa_sucursal,
			id_empresa: (props.item || '').id_empresa,
			tipo: props.tipo,
			observaciones: '',
			fecha_baja: null,
			files: [],
			archivos: [],
		};
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	
	guardarDocTimbradoDbx = () => {
		
		let files = this.state.files;
		
		return new Promise((resolve, reject) => {
			let band = files.length;
			let band2 = 0;
			let archives = this.state.archivos;
			let file;
			
			if (band > 0) {
				for (let i = 0; i < files.length; i++) {
					console.log("2 FTAR::: ", files);
					file = files[i];
					
					let ruta = '/Empresas/Sucursales SAT/Comprobante_baja/' + this.state.id_empresa + '_' + file.name;
					
					DropboxApi.Upload(ruta, file).then(function (response) {
						archives.push({archivo: response.path_display});
						
						band2++;
						
						console.log("DROPBOX UPLOAD: ", response, band, band2);
						
						if (band === band2) {
							resolve(archives);
						}
					}).catch(function (error) {
						reject(error);
					});
				}
			} else {
				resolve([]);
			}
			
		});
	};
	
	save = () => {
		
		try {
			
			if (!FieldsJs.Field(this.state.fecha_baja)) {
				throw Object({
					status: false, mensaje: 'Campo fecha apertura es requerido.'
				})
			}
			
			/*if (!FieldsJs.Field(this.state.files) && this.state.files.length === 0) {
				throw Object({
					status: false, mensaje: 'No se ha seleccionado el archivo.'
				})
			}*/
			
			if (!FieldsJs.Field(this.state.files) && this.state.files.length === 0) {
				EmpresaAcrescoSucursalService.Baja(this.state).then((response) => {
					this.props.RefreshList();
					this.modalClose();
					hideSpinner('spinner', 500);
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			} else {
				let guardar = new Promise((resolve, reject) => {
					showSpinner('spinner');
					this.guardarDocTimbradoDbx(this.state.files).then(response => {
						this.setState({
							archivos: response,
						});
						resolve(response);
						console.log("DROPBOX UPLOAD: ", this.state);
					}).catch(error => {
						reject(error);
					})
				});
				
				guardar.then(response => {
					EmpresaAcrescoSucursalService.Baja(this.state).then((response) => {
						this.props.RefreshList();
						this.modalClose();
						hideSpinner('spinner', 500);
						this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
					});
				});
			}
			
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		const {item} = this.props;
		
		this.setState({
			id_empresa_sucursal: item.id_empresa_sucursal || '',
			id_empresa: item.id_empresa || '',
			id_cat_empresa_sucursal: '',
			observaciones: '',
			fecha_baja: null,
			archivos: [],
			activo: true,
		});
		
		this.ListEmpresaSucursal();
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		this.ListEmpresaSucursal().then((response) => {
			this.setState({
				id_cat_empresa_sucursal: item.id_cat_empresa_sucursal || '',
			});
		});
		
		
		this.setState({
			id_empresa_sucursal: item.id_empresa_sucursal || '',
			id_empresa: item.id_empresa || '',
			fecha_baja: item.fecha_baja ? moment(item.fecha_baja) : null,
			observaciones: item.observaciones || null,
			archivos: item.archivos || [],
			activo: (item.activo === 1),
		});
		
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.ListEmpresaSucursal().then((response) => {
			this.setState({
				id_cat_empresa_sucursal: item.id_cat_empresa_sucursal || '',
			});
		});
		
		this.setState({
			id_empresa_sucursal: item.id_empresa_sucursal || '',
			id_empresa: item.id_empresa || '',
			observaciones: item.observaciones || null,
			fecha_baja: item.fecha_baja ? moment(item.fecha_baja) : null,
			archivos: item.archivos || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	ListEmpresaSucursal = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpresaSucursal().then((response) => {
				this.setState({
					listar_cat_empresa_sucursal: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({listar_cat_empresa_sucursal: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	RefreshListEmpresaSucursal = (id_cat_empresa_sucursal) => {
		this.ListEmpresaSucursal().then((response) => {
			let centros = FieldsJs.Copy(this.state.listar_cat_empresa_sucursal);
			let direccion = '';
			for (let i = 0; i < centros.length; i++) {
				if (Number(id_cat_empresa_sucursal) === Number(centros[i].id_cat_empresa_sucursal)) {
					direccion = centros[i].direccion;
				}
			}
			this.setState({
				id_cat_empresa_sucursal: id_cat_empresa_sucursal,
				direccion: direccion,
			});
		});
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_empresa_sucursal > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Estatus">
						<IconButton aria-label="Estatus" onClick={() => this.edit()}>
							<HdrStrong/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Sucursales SAT">
					<IconButton aria-label="Sucursales SAT" onClick={() => this.add()}><Business/></IconButton>
				</Tooltip>
			);
		}
		return BTN_ACTION;
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_empresa_sucursal}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						{'Baja de Sucursal SAT'}
					</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							{this.props.tipo !== 'view' ? (
								<Fragment>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'px-16 text-center'} style={{fontWeight: '400'}}>
											{this.props.item.nombre}
										</Typography>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<KeyboardDatePicker
											format="dd/MM/yyyy"
											fullWidth
											required
											clearable
											inputProps={{readOnly: true}}
											/*KeyboardButtonProps={{
												'aria-label': 'Fecha de pago',
											}}*/
											label="Fecha de baja"
											value={this.state.fecha_baja}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_baja');
											}}
											// minDate={moment(new Date())}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											onError={console.log}
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											fullWidth
											multiline
											name="observaciones"
											label="Observaciones"
											type="text"
											value={this.state.observaciones}
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'dropzone-cronograma'}>
										<Typography className={'padding-10-T padding-10-B px-12 text-left'} style={{fontWeight: 'bold'}}>
											Acuse (PDF):
										</Typography>
										<div className={'w-100 px-12'}>
											<DropzoneArea
												onChange={this.handleChangeDropzoneArea.bind(this)}
												open={this.state.open}
												filesLimit={1}
												dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
												acceptedFiles={['application/pdf']}
												showPreviews={false}
												maxFileSize={5000000}
												showAlerts={false}
												showFileNames={true}
												showFileNamesInPreview={true}
											/>
										</div>
									</Grid>
								
								</Fragment>
							) : null}
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_empresa_sucursal > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalEliminarSucursalesSAT.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalEliminarSucursalesSAT);
