import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {AccountTree, Adjust, Business, DateRangeOutlined, HdrStrong, MailOutline, Person, PersonOutlined, SearchOutlined, ViewDay} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {EnteroSolo, FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {CatService as TipoUsuarioServiceService, CatService} from "../../../../services/_Cat/CatService/CatService";
import {TipoUsuarioService} from "../../../../services/_Cat/TipoUsuarioService/TipoUsuarioService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import Input from "@material-ui/core/Input";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ModalProyecto from "../../Cat/Proyecto/Includes/ModalProyecto";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Add from "@material-ui/icons/Add";
import ModalModulo from "../../Cat/Modulo/Includes/ModalModulo";
import {ProyectoService} from "../../../../services/_Cat/ProyectoService/ProyectoService";
import {ModuloService} from "../../../../services/_Cat/ModuloService/ModuloService";
import TableCell from "@material-ui/core/TableCell";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";


class FiltrosRepositorioJuridico extends Component {
	
	Usr = {};
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			id_repositorio_juridico: '',
			empresa: '',
			id_cat_tipo_documento_repositorio_juridico: '',
			id_cat_centro_trabajo_repositorio_juridico: '',
			responsable: '',
			id_cat_tipo_usuario: '',
			representante_legal: '',
			activo: 'all',
			
			lista_empresa: [],
			lista_cat_tipo_documento_rj: [],
			lista_cat_centro_trabajo_rj: [],
			lista_tipo_usuario: [],
			
		};
		
		this.ListDocumentosRJ();
		this.ListCentroTrabajoRJ();
		this.ListaTipoUsuario();
	}
	
	ListaTipoUsuario = () => {
		TipoUsuarioService.Listar().then((response) => {
			this.setState({
				lista_tipo_usuario: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_tipo_usuario: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListDocumentosRJ = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoDocumentoRJ(this.Usr.id_cat_tipo_usuario).then((response) => {
				this.setState({
					lista_cat_tipo_documento_rj: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_cat_tipo_documento_rj: []});
				this.props.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListCentroTrabajoRJ = () => {
		return new Promise((resolve, reject) => {
			CatService.ListCentroTrabajoRJ().then((response) => {
				this.setState({
					lista_cat_centro_trabajo_rj: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_cat_centro_trabajo_rj: []});
				this.props.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.id_repositorio_juridico = this.state.id_repositorio_juridico || undefined;
		filtro.empresa = this.state.empresa || undefined;
		filtro.id_cat_tipo_documento_repositorio_juridico = this.state.id_cat_tipo_documento_repositorio_juridico || undefined;
		filtro.id_cat_centro_trabajo_repositorio_juridico = this.state.id_cat_centro_trabajo_repositorio_juridico || undefined;
		filtro.responsable = this.state.responsable || undefined;
		filtro.representante_legal = this.state.representante_legal || undefined;
		filtro.activo = this.state.activo || undefined;
		filtro.id_cat_tipo_usuario = this.state.id_cat_tipo_usuario || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<Adjust className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									margin="none"
									name="id_repositorio_juridico"
									label="Id"
									type="text"
									fullWidth
									value={this.state.id_repositorio_juridico}
									onChange={this.handleChange}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<Business className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth margin="dense" className={'margin-0'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											fullWidth
											onChange={this.handleChange}
											type="text"
											className={'margin-0'}
											margin="dense"
											name="empresa"
											label="Empresa"
											value={this.state.empresa}
										/>
									</Grid>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Tipo Documento</InputLabel>
									<Select
										native
										value={this.state.id_cat_tipo_documento_repositorio_juridico}
										onChange={this.handleChange}
										name="id_cat_tipo_documento_repositorio_juridico"
										input={<Input/>}
									>
										<option value=""/>
										{this.state.lista_cat_tipo_documento_rj.map(option => (
											<option key={option.id_cat_tipo_documento_repositorio_juridico}
											        value={option.id_cat_tipo_documento_repositorio_juridico}>
												{option.tipo_documento}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					{/*<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Centro de Trabajo:</InputLabel>
									<Select
										native
										value={this.state.id_cat_centro_trabajo_repositorio_juridico}
										onChange={(e) => {
											this.handleChange(e);
										}}
										name="id_cat_centro_trabajo_repositorio_juridico"
										input={<Input/>}
									>
										<option value=""/>
										{this.state.lista_cat_centro_trabajo_rj.map(option => (
											<option key={option.id_cat_centro_trabajo_repositorio_juridico}
											        value={option.id_cat_centro_trabajo_repositorio_juridico}>
												{option.centro_trabajo}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>*/}
					
					<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<Person className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth margin="dense" className={'margin-0'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											fullWidth
											onChange={this.handleChange}
											type="text"
											className={'margin-0'}
											margin="dense"
											name="representante_legal"
											label="Representante Legal"
											value={this.state.representante_legal}
										/>
									</Grid>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<Person className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth margin="dense" className={'margin-0'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											fullWidth
											onChange={this.handleChange}
											type="text"
											className={'margin-0'}
											margin="dense"
											name="responsable"
											label="Responsable"
											value={this.state.responsable}
										/>
									</Grid>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_tipo_usuario"
									label="Departamento"
									value={this.state.id_cat_tipo_usuario}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_tipo_usuario.map(option => (
										<option key={option.id_cat_tipo_usuario}
										        value={option.id_cat_tipo_usuario}>
											{option.tipo_usuario}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					{/*<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel htmlFor="age-native-activo">Activo</InputLabel>
									<Select
										native
										value={this.state.activo}
										onChange={this.handleChange}
										inputProps={{
											name: 'activo',
											id: 'age-native-activo',
										}}
									>
										<option value={'all'}>Todos</option>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>*/}
					
					<Grid item xs={12} sm={12} md={12} lg={2} xl={2} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosRepositorioJuridico.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosRepositorioJuridico;
