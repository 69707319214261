import React, {Component, Fragment} from "react";

import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {FieldsJs} from "../../../../settings/General/General";
import Button from "@material-ui/core/Button";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {Icono} from "../../../Include/Icono/Icono";
import TxtImgStatusPropuesta from "./TxtImgStatusPropuesta";
import Switch from "@material-ui/core/Switch";
import ChatFichaTecnica from "../../ClientePotencial/Pasos/ChatFichaTecnica";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {ExpandMore} from "@material-ui/icons";
import AccordionDetails from "@material-ui/core/AccordionDetails";


class SeccionPropuestaFiscalRespuestaClientePromotor extends Component {
	
	state = {};
	
	Usr = {};
	removefileFiscal = this.props.removefileFiscal;
	BOTON_ACCION_IMAGEN = this.props.BOTON_ACCION_IMAGEN;
	handleChange = this.props.handleChange;
	fileSelect = this.props.fileSelect;
	getBase64 = this.props.getBase64;
	guardarFiscal = this.props.guardarFiscal;
	guardarRespuesta = this.props.guardarRespuesta;
	cambio = this.props.cambio;
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		const params = props.params || {};
		
		this.state = {
			params: params,
			id_ficha_tecnica: this.props.state.id_ficha_tecnica || '',
			disabled_chat: this.props.state.id_ficha_tecnica ? false:true
		};
	}
	
	obtenerFormato = (text) => {
		let t = text.split('.');
		return t[t.length - 1];
	};
	
	render() {
		
		const disabledFieldFiscalSubirPropuesta = this.props.disabledFieldFiscalSubirPropuesta;
		const disabledFieldPromotorRespuestaCliente = this.props.disabledFieldPromotorRespuestaCliente;
		
		return (
			<Fragment>
				<Grid container spacing={2} direction="row"
				      justify={!disabledFieldPromotorRespuestaCliente ? "flex-start" : "center"}
				      alignItems={!disabledFieldPromotorRespuestaCliente ? "flex-start" : "center"}>
					
					<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
						
						{(this.props.state.ficha_tecnica_archivo_propuesta_fiscal.length > 0 || !disabledFieldFiscalSubirPropuesta) ? (
							
							<Fragment>
								
								<Typography variant={'h5'}
								            className={'text-center text-black margin-30-T margin-10-B'}>
									Fiscal
								</Typography>
								
								{FieldsJs.inArray([1, 2, 4], this.Usr.id_cat_tipo_usuario) ? (
									<Typography variant={'h6'}
									            className={'text-center text-black margin-0-T margin-10-B'}>
										Agrega y selecciona la propuesta para el cliente
									</Typography>
								) : ''}
								
								<article className={'borde-punteado-lightgray-2 card-0 bg-white padding-20'}>
									
									<Grid container spacing={2}>
										{/*{this.props.state.propuestas.map((item, keys) => (
												<Fragment key={keys}>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														{item.id === 0 ? (
															<Fragment>
																{
																	// this.props.state.ficha_tecnica_archivo_propuesta_fiscal.map((item, key) =>
																	item.propuesta.map((item, key) =>
																		<Fragment key={key}>
																			
																			<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
																				
																				{!disabledFieldFiscalSubirPropuesta ? (
																					<div style={{height: '0px', width: '0px'}}>
																						<div style={{
																							float: 'right',
																							position: 'relative',
																							padding: '6px',
																							background: 'red',
																							height: '6px',
																							width: '6px',
																							lineHeight: '6px',
																							color: 'white',
																							borderRadius: '100%',
																							fontSize: '12px',
																							right: '-9px',
																							top: '-9px',
																						}} className={'cursor-pointer'}
																						     onClick={() => this.removefileFiscal(item, key)}>x
																						</div>
																					</div>
																				) : ""}
																				
																				<div className={'card padding-10 h-100-20-px bg-ghostwhite'}>
																					<div className={'row-flex h-100'}>
																						<div className={'w-130-px h-100 vertical-inline'}>
																							<div className={'v-center w-100'} align={'center'}>
																								<div className={'w-60-px h-60-px'}>
																									{Icono.get(item.formato || this.obtenerFormato(item.archivo), '60px', '60px')}
																								</div>
																								<div className={'padding-5'}>
																									{this.BOTON_ACCION_IMAGEN(item)}
																								</div>
																								<div className={'padding-5'}
																								     style={item.style || {}}>
																									{item.archivo_propuesta_status}
																								</div>
																							</div>
																						</div>
																						<div className={'w-100-130-px h-100'}>
																							
																							<Grid container spacing={2}>
																								
																								<Grid item xs={12} sm={12} md={12} lg={12}
																								      xl={12}>
																									<TextField
																										autoFocus
																										fullWidth
																										type="text"
																										margin="dense"
																										onChange={(e) => this.handleChange(e, 'ficha_tecnica_archivo_propuesta_fiscal', 'titulo')}
																										disabled={disabledFieldFiscalSubirPropuesta || FieldsJs.inArray([2, 3, 4], item.id_cat_archivo_propuesta_status)}
																										
																										name={'titulo__' + keys + key}
																										label="Título"
																										helperText="Título del archivo"
																										value={item.titulo}
																									/>
																								</Grid>
																								
																								<Grid item xs={12} sm={12} md={12} lg={12}
																								      xl={12}>
																									<TextField
																										fullWidth
																										multiline
																										type="text"
																										margin="dense"
																										onChange={(e) => this.handleChange(e, 'ficha_tecnica_archivo_propuesta_fiscal', 'comentario_adicional')}
																										disabled={disabledFieldFiscalSubirPropuesta || FieldsJs.inArray([2, 3, 4], item.id_cat_archivo_propuesta_status)}
																										
																										name={'comentario_adicional__' + keys + key}
																										label="Comentarios adicionales"
																										helperText="Comentario adicional para que el promotor lo remita al cliente, adjunto con propuesta"
																										value={item.comentario_adicional}
																									/>
																								</Grid>
																								
																								<Grid item xs={12} sm={12} md={12} lg={12}
																								      xl={12}>
																									<Typography
																										className={'text-danger px-14 text-left'}>
																										{item.porque_requiere_nueva_propuesta}
																									</Typography>
																								</Grid>
																							
																							</Grid>
																						
																						</div>
																					</div>
																				</div>
																			
																			</Grid>
																		</Fragment>)
																}
															</Fragment>
														) : (
															<Accordion>
																<AccordionSummary
																	expandIcon={<ExpandMore/>}
																	aria-controls="panel1a-content"
																	id="panel1a-header"
																>
																	<Typography className={''}>Propuesta {item.id}</Typography>
																</AccordionSummary>
																<AccordionDetails>
																	<Grid container spacing={2} direction="row">
																		{item.propuesta.map((item, key) =>
																			<Fragment key={key}>
																				
																				<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
																					
																					{!disabledFieldFiscalSubirPropuesta ? (
																						<div style={{height: '0px', width: '0px'}}>
																							<div style={{
																								float: 'right',
																								position: 'relative',
																								padding: '6px',
																								background: 'red',
																								height: '6px',
																								width: '6px',
																								lineHeight: '6px',
																								color: 'white',
																								borderRadius: '100%',
																								fontSize: '12px',
																								right: '-9px',
																								top: '-9px',
																							}} className={'cursor-pointer'}
																							     onClick={() => this.removefileFiscal(item, key)}>x
																							</div>
																						</div>
																					) : ""}
																					
																					<div className={'card padding-10 h-100-20-px bg-ghostwhite'}>
																						<div className={'row-flex h-100'}>
																							<div className={'w-130-px h-100 vertical-inline'}>
																								<div className={'v-center w-100'} align={'center'}>
																									<div className={'w-60-px h-60-px'}>
																										{Icono.get(item.formato || this.obtenerFormato(item.archivo), '60px', '60px')}
																									</div>
																									<div className={'padding-5'}>
																										{this.BOTON_ACCION_IMAGEN(item)}
																									</div>
																									<div className={'padding-5'}
																									     style={item.style || {}}>
																										{item.archivo_propuesta_status}
																									</div>
																								</div>
																							</div>
																							<div className={'w-100-130-px h-100'}>
																								
																								<Grid container spacing={2}>
																									
																									<Grid item xs={12} sm={12} md={12} lg={12}
																									      xl={12}>
																										<TextField
																											autoFocus
																											fullWidth
																											type="text"
																											margin="dense"
																											onChange={(e) => this.handleChange(e, 'ficha_tecnica_archivo_propuesta_fiscal', 'titulo')}
																											disabled={disabledFieldFiscalSubirPropuesta || FieldsJs.inArray([2, 3, 4], item.id_cat_archivo_propuesta_status)}
																											
																											name={'titulo__' + keys + key}
																											label="Título"
																											helperText="Título del archivo"
																											value={item.titulo}
																										/>
																									</Grid>
																									
																									<Grid item xs={12} sm={12} md={12} lg={12}
																									      xl={12}>
																										<TextField
																											fullWidth
																											multiline
																											type="text"
																											margin="dense"
																											onChange={(e) => this.handleChange(e, 'ficha_tecnica_archivo_propuesta_fiscal', 'comentario_adicional')}
																											disabled={disabledFieldFiscalSubirPropuesta || FieldsJs.inArray([2, 3, 4], item.id_cat_archivo_propuesta_status)}
																											
																											name={'comentario_adicional__' + keys + key}
																											label="Comentarios adicionales"
																											helperText="Comentario adicional para que el promotor lo remita al cliente, adjunto con propuesta"
																											value={item.comentario_adicional}
																										/>
																									</Grid>
																									
																									<Grid item xs={12} sm={12} md={12} lg={12}
																									      xl={12}>
																										<Typography
																											className={'text-danger px-14 text-left'}>
																											{item.porque_requiere_nueva_propuesta}
																										</Typography>
																									</Grid>
																								
																								</Grid>
																							
																							</div>
																						</div>
																					</div>
																				
																				</Grid>
																			</Fragment>)}
																	</Grid>
																</AccordionDetails>
															</Accordion>
														)}
													</Grid>
												</Fragment>
											)
										)}*/}
										
										{this.props.state.ficha_tecnica_archivo_propuesta_fiscal.map((item, key) =>
											<Fragment key={key}>
												
												<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
													
													{!disabledFieldFiscalSubirPropuesta ? (
														<div style={{height: '0px', width: '0px'}}>
															<div style={{
																float: 'right',
																position: 'relative',
																padding: '6px',
																background: 'red',
																height: '6px',
																width: '6px',
																lineHeight: '6px',
																color: 'white',
																borderRadius: '100%',
																fontSize: '12px',
																right: '-9px',
																top: '-9px',
															}} className={'cursor-pointer'}
															     onClick={() => this.removefileFiscal(item, key)}>x
															</div>
														</div>
													) : ""}
													
													<div className={'card padding-10 h-100-20-px bg-ghostwhite'}>
														<div className={'row-flex h-100'}>
															<div className={'w-130-px h-100 vertical-inline'}>
																<div className={'v-center w-100'} align={'center'}>
																	<div className={'w-60-px h-60-px'}>
																		{Icono.get(item.formato || this.obtenerFormato(item.archivo), '60px', '60px')}
																	</div>
																	<div className={'padding-5'}>
																		{this.BOTON_ACCION_IMAGEN(item)}
																	</div>
																	<div className={'padding-5'}
																	     style={item.style || {}}>
																		{item.archivo_propuesta_status}
																	</div>
																</div>
															</div>
															<div className={'w-100-130-px h-100'}>
																
																<Grid container spacing={2}>
																	
																	<Grid item xs={12} sm={12} md={12} lg={12}
																	      xl={12}>
																		<TextField
																			autoFocus
																			fullWidth
																			type="text"
																			margin="dense"
																			onChange={(e) => this.handleChange(e, 'ficha_tecnica_archivo_propuesta_fiscal', 'titulo')}
																			disabled={disabledFieldFiscalSubirPropuesta || FieldsJs.inArray([2, 3, 4], item.id_cat_archivo_propuesta_status)}
																			
																			name={'titulo__' + key}
																			label="Título"
																			helperText="Título del archivo"
																			value={item.titulo}
																		/>
																	</Grid>
																	
																	<Grid item xs={12} sm={12} md={12} lg={12}
																	      xl={12}>
																		<TextField
																			fullWidth
																			multiline
																			type="text"
																			margin="dense"
																			onChange={(e) => this.handleChange(e, 'ficha_tecnica_archivo_propuesta_fiscal', 'comentario_adicional')}
																			disabled={disabledFieldFiscalSubirPropuesta || FieldsJs.inArray([2, 3, 4], item.id_cat_archivo_propuesta_status)}
																			
																			name={'comentario_adicional__' + key}
																			label="Comentarios adicionales"
																			helperText="Comentario adicional para que el promotor lo remita al cliente, adjunto con propuesta"
																			value={item.comentario_adicional}
																		/>
																	</Grid>
																	
																	<Grid item xs={12} sm={12} md={12} lg={12}
																	      xl={12}>
																		<Typography
																			className={'text-danger px-14 text-left'}>
																			{item.porque_requiere_nueva_propuesta}
																		</Typography>
																	</Grid>
																
																</Grid>
															
															</div>
														</div>
													</div>
												
												</Grid>
											</Fragment>)}
										
										{!disabledFieldFiscalSubirPropuesta ? (
											<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12}
											      xl={12}>
												<div
													className={'w-100 h-100-px borde-punteado-danger-2 margin--1 cursor-pointer bg-ghostwhite'}
													onClick={() => this.fileSelect('archivo_fiscal')}>
													<div className={'vertical-inline w-100 h-100 cursor-pointer'}>
														<div
															className={'v-center padding-0-T padding-0-B w-100 padding-10'}>
															{Icono.get('add', 30, 30)}<br/>
															<Typography
																className={'text-center text-gray margin-5-T margin-0-B px-18'}>
																Agrega y selecciona la propuesta para el cliente,
																formatos
																aceptados: PDF, WORD, EXCEL, PNG, JPEG, TXT
															</Typography>
														</div>
														<input type="file" id="archivo_fiscal"
														       onChange={(e) => this.getBase64(e, 'archivo_fiscal')}
														       className={'display-none'}/>
													</div>
												</div>
											</Grid>
										) : ''}
									
									</Grid>
									
									<Grid container spacing={3}>
										
										<Grid item className={'text-center padding-10'} xs={12} sm={12} md={12}
										      lg={12}
										      xl={12}>
											{!disabledFieldFiscalSubirPropuesta ? (
												<div className={'margin-20-T margin-10-B'}>
													<Button variant="contained" color="primary"
													        onClick={this.guardarFiscal}
													        className={'btn-default-primary text-transform-none'}>
														Guardar
													</Button>
												</div>
											) : ''}
										</Grid>
									
									</Grid>
								
								</article>
							
							</Fragment>
						
						) : ''}
						
						<Fragment>
							{this.props.HISTORIAL_2}
						</Fragment>
					
					</Grid>
					
					{(this.props.state.id_cat_ficha_tecnica_status === 2 && this.Usr.id_cat_tipo_usuario === 3) ? (
						<Fragment>
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<TxtImgStatusPropuesta
									texto={"Espera a que el fiscal suba la propuesta"}
									id_cat_ficha_tecnica_status={this.props.state.id_cat_ficha_tecnica_status}
								/>
							</Grid>
						</Fragment>
					) : ''}
					
					<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
						
						<ChatFichaTecnica
							disabled={this.state.disabled_chat}
							state={this.props.state}
							BOTON_ACCION_IMAGEN={this.BOTON_ACCION_IMAGEN}
							showSnackBars={this.props.showSnackBars}
							handleChange={this.props.handleChange}
						/>
						
						{!disabledFieldPromotorRespuestaCliente ? (
							
							<Fragment>
								
								<Typography variant={'h5'}
								            className={'text-center text-black margin-30-T margin-10-B'}>
									Promotor
								</Typography>
								
								<article className={'borde-punteado-lightgray-2 card-0 bg-white padding-20'}>
									
									<FormGroup row>
										
										<FormControlLabel
											control={<Switch type='checkbox' name='cliente_cancelo' disableRipple
											                 checked={this.props.state.cliente_cancelo}
											                 onChange={(e) => {
												                 this.handleChange(e);
												                 this.cambio(e);
											                 }}
											/>}
											label="Cliente canceló la propuesta"
										/>
										
										<FormControlLabel
											control={<Switch type='checkbox' name='cliente_requiere_nueva_propuesta'
											                 disableRipple
											                 checked={this.props.state.cliente_requiere_nueva_propuesta}
											                 onChange={(e) => {
												                 this.handleChange(e);
												                 this.cambio(e);
											                 }}
											/>}
											label="Cliente requiere nueva propuesta"
										/>
										
										<FormControlLabel
											control={<Switch type='checkbox' name='cliente_acepto' disableRipple
											                 checked={this.props.state.cliente_acepto}
											                 onChange={(e) => {
												                 this.handleChange(e);
												                 this.cambio(e);
											                 }}
											/>}
											label="Cliente aceptó propuesta"
										/>
									
									</FormGroup>
									
									{this.props.state.cliente_requiere_nueva_propuesta ? (
										<FormGroup row>
											
											<div className="padding-10 w-100"/>
											
											<TextField
												multiline
												fullWidth
												rows={7}
												onChange={this.handleChange}
												type="text"
												margin="none"
												variant={"outlined"}
												
												required
												helperText="Describe el motivo por el cual, el cliente requiere una nueva propuesta"
												name="porque_requiere_nueva_propuesta"
												label="Motivo"
												value={this.props.state.porque_requiere_nueva_propuesta}
											/>
										
										</FormGroup>
									) : ''}
									
									{this.props.state.cliente_acepto ? (
										<FormGroup>
											
											<div className="padding-10 w-100"/>
											
											<Typography
												className={'px-14 text-left text-gray margin-5-T margin-5-B'}>
												Nota: Documentos que se tienen que solicitar al cliente después de
												realizar
												confirmación del servicio ofrecido.
											</Typography>
											
											<Typography
												className={'px-14 text-left text-gray margin-5-T margin-5-B'}>
												1.- Constancia de situación fiscal<br/>
												2.- Comprobante de domicilio<br/>
												3.- Acta constitutiva de la empresa<br/>
												4.- Poder del representante legal<br/>
												5.- Identificación del representante legal<br/>
											</Typography>
										
										</FormGroup>
									) : ''}
									
									<div className="padding-5 w-100"/>
									
									{this.props.state.cliente_requiere_nueva_propuesta || this.props.state.cliente_acepto || this.props.state.cliente_cancelo ? (
										<Button variant="contained" onClick={this.guardarRespuesta}
										        className={this.props.state.cliente_cancelo === true ? 'bg-danger text-white text-transform-none' : (
											        this.props.state.cliente_requiere_nueva_propuesta === true ? 'bg-warning text-white text-transform-none' : (
												        this.props.state.cliente_acepto === true ? 'bg-success text-white text-transform-none' : ''
											        )
										        )}>
											{this.props.state.cliente_cancelo === true ? 'Confirmar cancelación' : (
												this.props.state.cliente_requiere_nueva_propuesta === true ? 'Confirmar nueva propuesta' : (
													this.props.state.cliente_acepto === true ? 'Confirmar aceptación' : ''
												)
											)}
										</Button>
									) : ''}
								
								</article>
							
							</Fragment>
						
						) : (
							
							<Fragment>
								<div className={'margin-15-T'}>
									{(this.props.state.id_cat_ficha_tecnica_status === 2 && this.Usr.id_cat_tipo_usuario === 4) ? (
										<Fragment>
											<TxtImgStatusPropuesta
												texto={"Aun no se ha seleccionado la propuesta para remitir al cliente"}
												id_cat_ficha_tecnica_status={this.props.state.id_cat_ficha_tecnica_status}
											/>
										</Fragment>
									) : ''}
									
									{(this.props.state.id_cat_ficha_tecnica_status === 3) ? (
										<Fragment>
											<TxtImgStatusPropuesta texto={
												(this.props.state.ficha_tecnica_archivo_propuesta_fiscal.length > 0 && this.Usr.id_cat_tipo_usuario === 4) ?
													"Espera a que el promotor remita la propuesta al cliente" : "Aun no se ha seleccionado la propuesta para remitir al cliente"
											} id_cat_ficha_tecnica_status={this.props.state.id_cat_ficha_tecnica_status}/>
										</Fragment>
									) : ''}
									
									{(this.props.state.id_cat_ficha_tecnica_status === 4) ? (
										<Fragment>
											<TxtImgStatusPropuesta texto={"El cliente ha cancelado la propuesta"}
											                       id_cat_ficha_tecnica_status={this.props.state.id_cat_ficha_tecnica_status}
											/>
										</Fragment>
									) : ''}
									
									{(this.props.state.id_cat_ficha_tecnica_status === 5) ? (
										<Fragment>
											<TxtImgStatusPropuesta
												texto={"El cliente ha solicitado una nueva propuesta"}
												id_cat_ficha_tecnica_status={this.props.state.id_cat_ficha_tecnica_status}
											/>
										</Fragment>
									) : ''}
									
									{(FieldsJs.inArray([6, 7, 8, 9, 10], this.props.state.id_cat_ficha_tecnica_status || 0)) ? (
										<Fragment>
											<TxtImgStatusPropuesta texto={"El cliente ha aceptado la propuesta"}
											                       id_cat_ficha_tecnica_status={this.props.state.id_cat_ficha_tecnica_status}
											/>
										</Fragment>
									) : ''}
								</div>
							</Fragment>
						
						)}
						
						{/*{typeof this.props.state.id_cat_ficha_tecnica_status} {this.props.state.id_cat_ficha_tecnica_status} {this.Usr.id_cat_tipo_usuario}*/}
					
					</Grid>
				
				</Grid>
			</Fragment>
		)
	}
}


SeccionPropuestaFiscalRespuestaClientePromotor.propTypes = {
	disabledFieldFiscalSubirPropuesta: PropTypes.bool.isRequired,
	disabledFieldPromotorRespuestaCliente: PropTypes.bool.isRequired,
	state: PropTypes.object.isRequired,
	removefileFiscal: PropTypes.func.isRequired,
	BOTON_ACCION_IMAGEN: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	fileSelect: PropTypes.func.isRequired,
	getBase64: PropTypes.func.isRequired,
	HISTORIAL_2: PropTypes.object.isRequired,
	cambio: PropTypes.func.isRequired,
};


export default SeccionPropuestaFiscalRespuestaClientePromotor;
