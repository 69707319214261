import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import {ComprasService} from "../../../../services/ComprasService/ComprasService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {AddOutlined, CancelOutlined, DeleteOutlined, EditOutlined, PlaylistAddOutlined, SaveOutlined} from '@material-ui/icons';
import Fab from "@material-ui/core/Fab";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";

import carrito_de_compra_3 from '../../../../assets/img/icons/carro-de-la-compra-3.svg'
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ModalEmpresa from "./ModalEmpresa";
import ModalPlaza from "../../Cat/Plaza/Includes/ModalPlaza";
import ModalOficina from "./ModalOficina";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {CONFIG} from "../../../../settings/Config/Config";
import {PlazaService} from "../../../../services/_Cat/Plaza/PlazaService";
import {PlazaDireccionService} from "../../../../services/_Cat/PlazaDireccion/PlazaDireccionService";
import {EmpresaPlazaOficinaService} from "../../../../services/ComprasService/EmpresaPlazaOficinaService";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import ModalProveedor from "./ModalProveedor";
import {ProveedorService} from "../../../../services/ProveedorService/ProveedorService";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TabContext from "@material-ui/lab/TabContext";
import AppBar from "@material-ui/core/AppBar";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import {TabPanel} from "@material-ui/lab";
import {DropzoneArea} from "material-ui-dropzone";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";

class ModalComprasServicios extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		let dias_pago = [];
		for (let i = 1; i <= 31; i++) {
			dias_pago[i] = i;
		}
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo || '',
			id_compra_servicio: props.id_compra_servicio,
			
			id_cat_tipo_comprobante: '',
			id_plaza: '',
			id_empresa: '',
			id_plaza_direccion: '',
			quien_recibio: '',
			dia_pago: '',
			
			descripcion: '',
			fecha_compra: null,
			fecha_pago: null,
			total: '',
			
			key: null,
			id_compra_producto: null,
			compra_producto: '',
			compra_descripcion: '',
			compra_cantidad: '',
			compra_precio_unitario: '',
			compra_total: '',
			iva_incluido: false,
			iva_aplicado: false,
			servicio_compra: 'Servicio',
			id_cat_servicio_gasto: '',
			otro_servicio_gasto: '',
			
			id_cat_periodicidad: '',
			lista_periodicidad: [],
			id_proveedor: '',
			lista_proveedor: [],
			
			lista_compra_producto: [],
			
			lista_empresa: [],
			lista_plaza: [],
			lista_plaza_direccion: [],
			
			lista_cat_tipo_comprobante: [],
			lista_servicio_gasto: [],
			lista_dias_pago: dias_pago,
			
			valuetab: '1',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		console.log(this.state.fecha_compra);
	};
	
	handleChangeIvaIncluido = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		
		if (!this.state.iva_incluido)
			this.setState({
				iva_aplicado: false
			})
	};
	
	handleChangeIvaAplicado = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		
		if (!this.state.iva_aplicado)
			this.setState({
				iva_incluido: false
			})
	};
	
	handleChangePlaza = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		this.setState({
			id_plaza_direccion: '',
			id_empresa: ''
		})
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		this.ListaPlaza();
		this.ListaServicioGasto();
		this.ListaProveedor();
		this.ListaPeriodicidad();
		
		return new Promise((resolve, reject) => {
			if (this.props.id_compra_servicio > 0) {
				ComprasService.DetallesServicio(this.props.id_compra_servicio).then((response) => {
					let item = FieldsJs.Copy(response.data);
					let fecha_compra = item.fecha_compra ? moment(item.fecha_compra) : null;
					
					
					this.setState({
						id_compra_servicio: item.id_compra_servicio,
						
						id_cat_tipo_comprobante: item.id_cat_tipo_comprobante || '',
						id_empresa: item.id_empresa || '',
						id_plaza: item.id_plaza || '',
						id_plaza_direccion: item.id_plaza_direccion || '',
						quien_recibio: item.quien_recibio || '',
						dia_pago: item.dia_pago || '',
						
						descripcion: item.descripcion || '',
						fecha_compra: fecha_compra,
						total: item.total || '',
						servicio_compra: item.servicio_compra || '',
						id_cat_servicio_gasto: item.id_cat_servicio_gasto || '',
						otro_servicio_gasto: item.otro_servicio_gasto || '',
						id_cat_periodicidad: item.id_cat_periodicidad || '',
						
						key: null,
						id_compra_producto: null,
						compra_producto: '',
						compra_descripcion: '',
						compra_cantidad: '',
						compra_precio_unitario: '',
						compra_total: '',
						iva_incluido: false,
						iva_aplicado: false,
						
						lista_compra_producto: item.compra_producto || [],
					});
					
					this.ListaPlazaDireccion('', item.id_plaza);
					this.ListaEmpresa('', item.id_plaza_direccion);
					
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false)
				});
			} else {
				this.setState({
					
					id_compra_servicio: null,
					
					id_cat_tipo_comprobante: '',
					id_empresa: '',
					id_plaza: '',
					id_plaza_direccion: '',
					quien_recibio: '',
					id_cat_servicio_gasto: '',
					otro_servicio_gasto: '',
					id_cat_periodicidad: '',
					id_proveedor: '',
					dia_pago: '',
					
					servicio_compra: 'Servicio',
					
					descripcion: '',
					fecha_compra: null,
					total: '',
					
					key: null,
					id_compra_producto: null,
					compra_producto: '',
					compra_descripcion: '',
					compra_cantidad: '',
					compra_precio_unitario: '',
					compra_total: '',
					iva_incluido: false,
					iva_aplicado: false,
					
					lista_empresa: [],
					lista_plaza: [],
					lista_plaza_direccion: [],
					lista_compra_producto: [],
				});
				
				resolve(true);
			}
		});
	};
	
	RefreshListEmpresa = (id_empresa) => {
		this.ListaEmpresa(true, this.state.id_plaza_direccion);
		if (id_empresa > 0) {
			this.setState({
				id_empresa: id_empresa
			})
		}
	};
	
	RefreshListPlazaDireccion = (id_plaza_direccion) => {
		this.ListaPlazaDireccion(true, this.state.id_plaza);
		if (id_plaza_direccion > 0) {
			this.setState({
				id_plaza_direccion: id_plaza_direccion
			})
		}
	};
	
	RefreshListPlaza = (id_plaza) => {
		if (id_plaza > 0) {
			this.setState({
				id_plaza: id_plaza
			})
		}
	};
	
	ListaServicioGasto = () => {
		CatService.ListServicioGasto().then(response => {
			this.setState({
				lista_servicio_gasto: response.data
			});
			console.log('EMPRESAS', this.state.lista_empresa);
		}).catch(reason => {
			this.setState({
				lista_servicio_gasto: []
			});
		});
	};
	
	ListaPeriodicidad = () => {
		CatService.ListPeriodicidad().then(response => {
			this.setState({
				lista_periodicidad: response.data
			});
			console.log('EMPRESAS', this.state.lista_empresa);
		}).catch(reason => {
			this.setState({
				lista_periodicidad: []
			});
		});
	};
	
	RefreshListProveedor = (id_proveedor) => {
		this.ListaProveedor();
		if (id_proveedor > 0) {
			this.setState({
				id_proveedor: id_proveedor
			})
		}
	};
	
	ListaProveedor = () => {
		ProveedorService.Listar().then(response => {
			this.setState({
				lista_proveedor: response.data
			});
		}).catch(reason => {
			this.setState({
				lista_proveedor: []
			});
		});
	};
	
	ListaEmpresa = (e, id_plaza_direccion) => {
		if (!id_plaza_direccion) {
			this.setState({
				id_plaza_direccion: e.target.value
			});
			id_plaza_direccion = e.target.value
		}
		
		EmpresaPlazaOficinaService.Listar(this.state, id_plaza_direccion).then(value => {
			this.setState({
				lista_empresa: value.data
			});
			console.log('EMPRESAS', this.state.lista_empresa);
		}).catch(reason => {
			this.setState({
				lista_empresa: []
			});
		});
	};
	
	ListaPlazaDireccion = (e, id_plaza) => {
		
		if (!id_plaza) {
			id_plaza = e.target.value
		}
		
		PlazaDireccionService.Listar(id_plaza).then((response) => {
			this.setState({
				lista_plaza_direccion: response.data
			});
		}).catch(reason => {
			this.setState({
				lista_plaza_direccion: []
			});
		});
	};
	
	
	ListaPlaza = () => {
		PlazaService.Listar().then(value => {
			this.setState({
				lista_plaza: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_plaza: []
			});
		});
	};
	
	plazaAbreviatura = (id_plaza) => {
		console.log(id_plaza);
		var plaza = '';
		for (let i = 0; i < this.state.lista_plaza.length; i++) {
			if (Number(this.state.lista_plaza[i].id_plaza) === Number(id_plaza)) {
				plaza = this.state.lista_plaza[i].plaza + ' - ' + this.state.lista_plaza[i].abreviatura;
			}
		}
		console.log('PLAZA', plaza);
		return plaza;
	}
	
	getPlazaOficina = (item) => {
		var plaza_direccion = this.plazaAbreviatura(item.id_plaza);
		for (let i = 0; i < this.state.lista_plaza_direccion.length; i++) {
			if (Number(this.state.lista_plaza_direccion[i].id_plaza_direccion) === Number(item.id_plaza_direccion)) {
				plaza_direccion = plaza_direccion + ' - ' + this.state.lista_plaza_direccion[i].nombre_plaza;
			}
		}
		console.log('PLAZA', plaza_direccion);
		return plaza_direccion;
	}
	
	
	add = () => {
		
		try {
			
			if (!this.state.compra_producto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Producto.'
				})
			}
			
			if (!(this.state.compra_cantidad > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cantidad.'
				})
			}
			
			if (!(this.state.compra_precio_unitario > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Precio Unitario.'
				})
			}
			
			if (!(this.state.compra_total > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Total.'
				})
			}
			
			let proveedor = '';
			if (FieldsJs.Field(this.state.id_proveedor)) {
				let proveedores = FieldsJs.Copy(this.state.lista_proveedor || []);
				for (let i = 0; i < proveedores.length; i++) {
					if (Number(proveedores[i].id_proveedor) === Number(this.state.id_proveedor)) {
						proveedor = Number(proveedores[i].id_cat_tipo_persona) === 1 ? proveedores[i].nombre_completo : proveedores[i].nombre_comercial;
					}
				}
			}
			
			let periodicidad = '';
			if (FieldsJs.Field(this.state.id_cat_periodicidad)) {
				let periodicidades = FieldsJs.Copy(this.state.lista_periodicidad || []);
				for (let i = 0; i < periodicidades.length; i++) {
					if (Number(periodicidades[i].id_cat_periodicidad) === Number(this.state.id_cat_periodicidad)) {
						periodicidad = periodicidades[i].periodicidad;
					}
				}
			}
			
			let lista_compra_producto = FieldsJs.Copy(this.state.lista_compra_producto || []);
			
			if (this.is_edited_product()) {
				for (let i = 0; i < lista_compra_producto.length; i++) {
					let item = lista_compra_producto[i];
					if (this.state.key === i) {
						item.id_proveedor = this.state.id_proveedor || '';
						item.proveedor = proveedor || '';
						item.id_cat_periodicidad = this.state.id_cat_periodicidad || '';
						item.periodicidad = periodicidad || '';
						item.compra_producto = this.state.compra_producto || '';
						item.compra_producto = this.state.compra_producto || '';
						item.compra_descripcion = this.state.compra_descripcion || '';
						item.compra_cantidad = this.state.compra_cantidad || 0;
						item.compra_precio_unitario = this.state.compra_precio_unitario || 0;
						item.compra_total = this.state.compra_total || 0;
						item.iva_incluido = this.state.iva_incluido ? 1 : 0;
						item.iva_aplicado = this.state.iva_aplicado ? 1 : 0;
					}
				}
			} else {
				let item;
				item = {
					id_proveedor: this.state.id_proveedor || '',
					proveedor: proveedor || '',
					id_cat_periodicidad: this.state.id_cat_periodicidad || '',
					periodicidad: periodicidad || '',
					id_compra_producto: null,
					id_compra_servicio: this.state.id_compra_servicio || null,
					compra_producto: this.state.compra_producto || '',
					compra_descripcion: this.state.compra_descripcion || '',
					compra_cantidad: this.state.compra_cantidad || 0,
					compra_precio_unitario: this.state.compra_precio_unitario || 0,
					compra_total: this.state.compra_total || 0,
					iva_incluido: this.state.iva_incluido ? 1 : 0,
					iva_aplicado: this.state.iva_aplicado ? 1 : 0,
				};
				lista_compra_producto.push(item);
			}
			
			this.setState({
				key: null,
				id_proveedor: '',
				proveedor: '',
				id_cat_periodicidad: '',
				periodicidad: '',
				id_compra_producto: '',
				compra_producto: '',
				compra_descripcion: '',
				compra_cantidad: '',
				compra_precio_unitario: '',
				compra_total: '',
				iva_incluido: false,
				iva_aplicado: false,
				lista_compra_producto: lista_compra_producto
			});
			
			this.calcularTotalCompra();
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	save = () => {
		try {
			
			if (!FieldsJs.Field(this.state.servicio_compra)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Servicio o Compra.'
				})
			}
			
			/*if (!FieldsJs.Field(this.state.descripcion)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Descripción.'
				})
			}*/
			
			if (!this.state.fecha_compra) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Fecha de compra.'
				})
			}
			
			if (!this.state.total) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Importe.'
				})
			}
			
			try {
				ComprasService.GuardarServicio(this.state).then((response) => {
					this.closeModal();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			} catch (e) {
				this.props.showSnackBars('error', e.mensaje);
			}
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	edit = (value, key) => {
		try {
			
			this.setState({
				key: key,
				id_compra_producto: value.id_compra_producto,
				id_proveedor: value.id_proveedor,
				proveedor: value.proveedor,
				id_cat_periodicidad: value.id_cat_periodicidad,
				periodicidad: value.periodicidad,
				compra_producto: value.compra_producto,
				compra_descripcion: value.compra_descripcion,
				compra_cantidad: value.compra_cantidad,
				compra_precio_unitario: value.compra_precio_unitario,
				compra_total: value.compra_total,
				iva_incluido: (value.iva_incluido === 1),
				iva_aplicado: (value.iva_aplicado === 1),
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			id_compra_producto: '',
			id_proveedor: '',
			proveedor: '',
			id_cat_periodicidad: '',
			periodicidad: '',
			compra_producto: '',
			compra_descripcion: '',
			compra_cantidad: '',
			compra_precio_unitario: '',
			compra_total: '',
			iva_incluido: false,
			iva_aplicado: false,
		});
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar esta este producto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_compra_producto = FieldsJs.Copy(this.state.lista_compra_producto);
					
					lista_compra_producto.splice(key, 1);
					
					this.setState({
						lista_compra_producto: lista_compra_producto
					});
					
					this.calcularTotalCompra();
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	calcularTotalProducto = () => {
		setTimeout(() => {
			let compra_cantidad = this.state.compra_cantidad;
			let compra_precio_unitario = this.state.compra_precio_unitario;
			let compra_total = 0;
			
			if (compra_cantidad > 0 && compra_precio_unitario > 0) {
				compra_total = (compra_cantidad * compra_precio_unitario);
			}
			
			this.setState({
				compra_total: compra_total
			});
		});
	};
	
	calcularPrecioUnitarioProducto = () => {
		setTimeout(() => {
			let compra_cantidad = this.state.compra_cantidad || 1;
			let compra_precio_unitario = 0;
			let compra_total = this.state.compra_total;
			
			if (compra_cantidad > 0 && compra_total > 0) {
				if (this.state.iva_aplicado === 0) {
					compra_precio_unitario = (compra_total / compra_cantidad);
				}
			}
			
			this.setState({
				compra_cantidad: compra_cantidad,
				compra_precio_unitario: compra_precio_unitario
			});
		});
	};
	
	calcularTotalCompra = () => {
		setTimeout(() => {
			let lista_compra_producto = this.state.lista_compra_producto || [];
			let total = 0;
			for (let i = 0; i < lista_compra_producto.length; i++) {
				let item = lista_compra_producto[i];
				if (Number(item.compra_total) > 0) {
					total += this.calcularTotalConSinIVA(item);
				}
			}
			this.setState({
				total: total
			});
		});
	};
	
	
	calcularSubTotalConSinIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1 && value.iva_aplicado === 0) {
			return value.compra_precio_unitario / 1.16;
		} else {
			return value.compra_precio_unitario;
		}
	};
	
	calcularIVAPrecioUnitario = (value) => {
		if (value.iva_aplicado === 0) {
			if (value.iva_incluido === 1) {
				return value.compra_precio_unitario - (value.compra_precio_unitario / 1.16);
			} else {
				return value.compra_precio_unitario * 0.16;
			}
		} else {
			return 0;
		}
	};
	
	calcularTotalConSinIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1 || value.iva_aplicado === 1) {
			return value.compra_precio_unitario;
		} else {
			return value.compra_precio_unitario * 1.16;
		}
	};
	
	calcularSubTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1 && value.iva_aplicado === 0) {
			return value.compra_total / 1.16;
		} else {
			return value.compra_total;
		}
	};
	
	calcularIVA = (value) => {
		if (value.iva_aplicado === 0) {
			if (value.iva_incluido === 1) {
				return value.compra_total - (value.compra_total / 1.16);
			} else {
				return value.compra_total * 0.16;
			}
		} else {
			return 0;
		}
	};
	
	calcularTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1 || value.iva_aplicado === 1) {
			return value.compra_total;
		} else {
			return value.compra_total * 1.16;
		}
	};
	
	calcularDesgoseTotales = () => {
		let desglose = {
			subtotal: 0,
			iva: 0,
			total: 0,
		};
		let lista_compra_producto = this.state.lista_compra_producto;
		for (let i = 0; i < lista_compra_producto.length; i++) {
			let item = lista_compra_producto[i];
			desglose.subtotal += this.calcularSubTotalConSinIVA(item);
			desglose.iva += this.calcularIVA(item);
			desglose.total += this.calcularTotalConSinIVA(item);
		}
		return desglose;
	};
	
	validatePlaza = () => {
		console.log('validacion de plaza');
		let band = false;
		
		if (this.state.id_plaza > 0) {
			band = true;
		}
		
		return band;
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	
	handleChangeTabs = (e, variable, campo, date, input) => {
		console.log(e, variable);
		
		try {
			
			if (this.state.informacion_global && !FieldsJs.Field(this.state.anio_global)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Año.'
				})
			}
			
			this.setState({
				valuetab: variable
			});
			console.log(this.state);
			
		} catch (e) {
			console.log(this.state);
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	
	render() {
		
		const is_root = this.is_root();
		
		const desglose = this.calcularDesgoseTotales();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.open;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<span onClick={() => this.open()} style={{cursor: "pointer"}}>
						{this.props.icono}
					</span>
				)}
				
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Agregar Servicio
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									{!this.is_view() ? (
										<Grid item style={{width: '50px'}}>
											{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
												<Fragment>
													<ModalPlaza
														componente={(
															<Tooltip
																TransitionComponent={Zoom}
																placement={"right"}
																title="Agregar nuevo Plaza"
																children={(
																	<Fab
																		color="secondary"
																		size="small"
																		aria-label="Agregar nueva plaza"
																		children={(
																			<PlaylistAddOutlined/>
																		)}
																	/>
																)}
															/>
														)}
														id_plaza_direccion={null}
														tipo={'add'}
														item={{}}
														RefreshList={this.RefreshListPlaza}
														showSnackBars={this.props.showSnackBars}
													/>
												</Fragment>
											) : ''}
										</Grid>
									) : null}
									<Grid item style={{width: 'calc(100% - 50px)'}}>
										<TextField
											select
											fullWidth
											margin="none"
											onChange={(e) => {
												this.handleChangePlaza(e);
												this.ListaPlazaDireccion(e, false);
											}}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											
											name="id_plaza"
											label="Plaza"
											value={this.state.id_plaza}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.lista_plaza.map(option => (
												<option key={option.id_plaza} value={option.id_plaza}>
													{option.plaza} - {option.abreviatura}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									{!this.is_view() ? (
										<Grid item style={{width: '50px'}}>
											{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
												<Fragment>
													<ModalOficina
														componente={(
															<Tooltip
																TransitionComponent={Zoom}
																placement={"right"}
																title="Agregar oficina"
																children={(
																	<Fab
																		color="default"
																		size="small"
																		aria-label="Agregar nueva oficina"
																		children={(
																			<PlaylistAddOutlined/>
																		)}
																	/>
																)}
															/>
														)}
														id_plaza_direccion={null}
														tipo={'add'}
														item={{
															id_plaza: this.state.id_plaza,
															plaza: this.plazaAbreviatura(this.state.id_plaza)
														}}
														RefreshList={this.RefreshListPlazaDireccion}
														showSnackBars={this.props.showSnackBars}
													/>
												</Fragment>
											) : ''}
										</Grid>
									) : null}
									<Grid item style={{width: 'calc(100% - 50px)'}}>
										<TextField
											select
											fullWidth
											onChange={(e) => {
												this.handleChange(e);
												this.ListaEmpresa(e);
											}}
											margin="none"
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											
											name="id_plaza_direccion"
											label="Oficina"
											value={this.state.id_plaza_direccion}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.lista_plaza_direccion.map((option, index) => (
												<option key={option.id_plaza_direccion} value={option.id_plaza_direccion}>
													{/*{CadenaDomicilio(option)}*/}
													{option.nombre_plaza}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									{!this.is_view() ? (
										<Grid item style={{width: '50px'}}>
											{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
												<Fragment>
													<ModalEmpresa
														componente={(
															<Tooltip
																TransitionComponent={Zoom}
																placement={"right"}
																title="Agregar empresa"
																children={(
																	<Fab
																		color="default"
																		size="small"
																		aria-label="Agregar nueva empresa"
																		children={(
																			<PlaylistAddOutlined/>
																		)}
																	/>
																)}
															/>
														)}
														id_plaza_direccion={null}
														tipo={'add'}
														item={{
															id_plaza: this.state.id_plaza,
															id_plaza_direccion: this.state.id_plaza_direccion,
															plaza_direccion: this.getPlazaOficina(this.state)
														}}
														RefreshList={this.RefreshListEmpresa}
														showSnackBars={this.props.showSnackBars}
													/>
												</Fragment>
											) : ''}
										</Grid>
									) : null}
									<Grid item style={{width: 'calc(100% - 50px)'}}>
										<TextField
											select
											fullWidth
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											
											name="id_empresa"
											label="Empresa"
											value={this.state.id_empresa}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.lista_empresa.map((option, index) => (
												<option key={option.id_empresa} value={option.id_empresa}>
													{option.nombre_comercial}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							
							{/*<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Selecciona una opción:</FormLabel>
									<RadioGroup row aria-label="position" name="servicio_compra"
									            value={this.state.servicio_compra}
									            onChange={this.handleChange}
									>
										<FormControlLabel
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={'Servicio'}
											label="Servicio"
											disabled={this.props.tipo === 'view'}
										/>
										<FormControlLabel
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={'Compra'}
											label="Compra"
											disabled={this.props.tipo === 'view'}
										/>
									</RadioGroup>
								</FormControl>
							</Grid>*/}
							
							{/*<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
								{this.state.servicio_compra === "Servicio" ? (
									<TextField
										select
										fullWidth
										margin="none"
										onChange={this.handleChange}
										disabled={this.is_view()}
										SelectProps={{
											native: true,
											MenuProps: {
												className: '',
											},
										}}
										
										name="id_cat_servicio_gasto"
										label="Tipo servicio"
										value={this.state.id_cat_servicio_gasto}
									>
										<option key={0} value={''}>&nbsp;</option>
										{this.state.lista_servicio_gasto.map((option, index) => (
											<option key={option.id_cat_servicio_gasto} value={option.id_cat_servicio_gasto}>
												{option.servicio_gasto}
											</option>
										))}
									</TextField>
								) : null}
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<KeyboardDatePicker
									format={'dd/MM/yyyy'}
									fullWidth
									clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha límite de pago',
									}}
									label={"Fecha límite de pago"}
									value={this.state.fecha_pago}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_pago');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									disabled={this.is_view()}
								/>
							</Grid>*/}
			
						
						<Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
							<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
								
								<Fragment>
									<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
										<KeyboardDatePicker
											format={'dd/MM/yyyy'}
											fullWidth
											required
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'Fecha de contratación del servicio',
											}}
											label={"Fecha de contratación del servicio"}
											value={this.state.fecha_compra}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_compra');
											}}
											// minDate={moment(new Date())}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											onError={console.log}
											disabled={this.is_view()}
										/>
									</Grid>
									
									<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
										<TextField
											select
											fullWidth
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											
											name="id_cat_servicio_gasto"
											label="Tipo servicio"
											value={this.state.id_cat_servicio_gasto}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.lista_servicio_gasto.map((option, index) => (
												<option key={option.id_cat_servicio_gasto} value={option.id_cat_servicio_gasto}>
													{option.servicio_gasto}
												</option>
											))}
										</TextField>
									</Grid>
									
									<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
										<TextField
											select
											fullWidth
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											name="dia_pago"
											label="Día de pago"
											value={this.state.dia_pago}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.lista_dias_pago.map((option, index) => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</TextField>
									</Grid>
									
									{this.state.servicio_compra === 'Servicio' && Number(this.state.id_cat_servicio_gasto) === 6 ? (
										<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
											<TextField
												margin="none"
												name="otro_servicio_gasto"
												label="Otro servicio"
												type="text"
												fullWidth
												value={this.state.otro_servicio_gasto}
												onChange={this.handleChange}
												disabled={this.is_view()}
											/>
										</Grid>
									) : null
									}
									
									{/*<Grid item xs={this.state.servicio_compra === 'Servicio' && Number(this.state.id_cat_servicio_gasto) === 6 ? 8 : this.state.servicio_compra === 'Servicio' ? 10 : 4}
									      sm={this.state.servicio_compra === 'Servicio' && Number(this.state.id_cat_servicio_gasto) === 6 ? 8 : this.state.servicio_compra === 'Servicio' ? 10 : 4}
									      md={this.state.servicio_compra === 'Servicio' && Number(this.state.id_cat_servicio_gasto) === 6 ? 8 : this.state.servicio_compra === 'Servicio' ? 10 : 4}
									      lg={this.state.servicio_compra === 'Servicio' && Number(this.state.id_cat_servicio_gasto) === 6 ? 8 : this.state.servicio_compra === 'Servicio' ? 10 : 4}
									      xl={this.state.servicio_compra === 'Servicio' && Number(this.state.id_cat_servicio_gasto) === 6 ? 8 : this.state.servicio_compra === 'Servicio' ? 10 : 4}>
										<TextField
											rows={3}
											multiline
											margin="none"
											name="descripcion"
											label="Descripción"
											type="text"
											fullWidth
											value={this.state.descripcion}
											onChange={this.handleChange}
											required={true}
											disabled={this.is_view()}
										/>
									</Grid>*/}
								</Fragment>
							</Grid>
						</Grid>
						
						
						<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
							<Grid container spacing={1} alignItems={"flex-end"} alignContent={"flex-end"}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={"center"}>
									<Typography component={'h2'} className={'margin-0 padding-0 px-14'}>
										Monto total:
									</Typography>
									<Typography component={'h2'} className={'margin-0 padding-0 px-25'}>
										${FieldsJs.Currency(this.state.total)}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
							
							{this.state.servicio_compra === 'Servicio' ? (
								<Fragment>
									<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
										<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
											{!this.is_view() ? (
												<Grid item style={{width: '50px'}}>
													<Fragment>
														<ModalProveedor
															tipo={'add'}
															componente={
																<Fab
																	color="default"
																	size="small"
																	aria-label="Agregar Proveedor"
																	children={(
																		<PlaylistAddOutlined/>
																	)}
																/>
															}
															RefreshList={this.RefreshListProveedor}
															showSnackBars={this.props.showSnackBars}
														/>
													</Fragment>
												</Grid>
											) : null}
											<Grid item style={{width: 'calc(100% - 50px)'}}>
												<TextField
													select
													fullWidth
													margin="none"
													onChange={this.handleChange}
													disabled={this.is_view()}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}
													
													name="id_proveedor"
													label="Proveedor"
													value={this.state.id_proveedor}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.lista_proveedor.map((option, index) => (
														<option key={option.id_proveedor} value={option.id_proveedor}>
															{option.id_cat_tipo_persona === 1 ? option.nombre_completo : option.nombre_comercial}
														</option>
													))}
												</TextField>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
										<TextField
											select
											fullWidth
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											name="id_cat_periodicidad"
											label="Periodicidad"
											value={this.state.id_cat_periodicidad}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.lista_periodicidad.map((option, index) => (
												<option key={option.id_cat_periodicidad} value={option.id_cat_periodicidad}>
													{option.periodicidad}
												</option>
											))}
										</TextField>
									</Grid>
								</Fragment>
							
							) : null
							}
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<TextField
									margin="none"
									name="compra_producto"
									label="Producto - Descripción"
									type="text"
									fullWidth
									value={this.state.compra_producto}
									onChange={this.handleChange}
									required={true}
								/>
							</Grid>
						</Grid>
						
						{!this.is_view() ? (
							<Fragment>
										{/*<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
											<TextField
												margin="none"
												name="compra_descripcion"
												label="Descripción"
												type="text"
												fullWidth
												value={this.state.compra_descripcion}
												onChange={this.handleChange}
												required={false}
											/>
										</Grid>*/}
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Grid container spacing={2} alignItems={"flex-end"}
											      alignContent={"flex-end"}>
												
												<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
													<TextField
														margin="none"
														name="compra_cantidad"
														label="Cantidad"
														type="text"
														fullWidth
														value={this.state.compra_cantidad}
														onChange={(e) => {
															this.handleChange(e);
															this.calcularTotalProducto();
														}}
														onKeyPress={EnteroSolo}
														inputProps={{maxLength: 5}}
														required={true}
													/>
												</Grid>
												
												<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
													<TextField
														margin="none"
														name="compra_precio_unitario"
														label="Precio Unitario"
														type="text"
														fullWidth
														value={this.state.compra_precio_unitario}
														onChange={(e) => {
															this.handleChange(e);
															this.calcularTotalProducto();
														}}
														onKeyPress={EnteroSolo}
														inputProps={{maxLength: 50}}
														required={true}
													/>
												</Grid>
												
												<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
													<TextField
														margin="none"
														name="compra_total"
														label="Importe"
														type="text"
														fullWidth
														value={this.state.compra_total}
														onChange={(e) => {
															this.handleChange(e);
															this.calcularPrecioUnitarioProducto();
														}}
														onKeyPress={EnteroSolo}
														inputProps={{maxLength: 50}}
														required={true}
													/>
												</Grid>
												
												<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
													<FormGroup row>
														<FormControlLabel
															control={
																<Checkbox
																	type="checkbox"
																	name='iva_incluido'
																	checked={this.state.iva_incluido}
																	onChange={this.handleChangeIvaIncluido}
																	value="iva_incluido"
																	color="primary"
																	disabled={this.props.tipo === 'view'}
																/>
															}
															label={'IVA incluido'}
														/>
													</FormGroup>
												</Grid>
												<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
													<FormGroup row>
														<FormControlLabel
															control={
																<Checkbox
																	type="checkbox"
																	name='iva_aplicado'
																	checked={this.state.iva_aplicado}
																	onChange={this.handleChangeIvaAplicado}
																	value="iva_aplicado"
																	color="primary"
																	disabled={this.props.tipo === 'view'}
																/>
															}
															label={'No aplica IVA'}
														/>
													</FormGroup>
												</Grid>
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={"right"}>
													<Tooltip
														TransitionComponent={Zoom}
														placement={"top"}
														title="Cancelar edición"
														children={(
															<span>
																<Fab size="small" color="secondary"
																     aria-label="Cancelar edición"
																     onClick={() => this.edit_cancel()}
																     style={{marginLeft: "15px"}}
																     disabled={!this.is_edited_product()}>
																	<CancelOutlined/>
																</Fab>
															</span>
														)}
													/>
													<Tooltip
														TransitionComponent={Zoom}
														placement={"top"}
														title={this.is_edited_product() ? "Actualizar" : "Agregar"}
														children={(
															<Fab size="small" color="secondary"
															     aria-label={this.is_edited_product() ? "Actualizar" : "Agregar"}
															     onClick={() => this.add()}
															     style={{marginLeft: "15px"}}>
																{this.is_edited_product() ? (
																	<SaveOutlined/>
																) : (
																	<AddOutlined/>
																)}
															</Fab>
														)}
													/>
												</Grid>
											
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Fragment>
						) : null}
						
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<table className={'desing-mark margin-15-T'}>
								<thead>
								<tr>
									{this.state.servicio_compra === "Servicio" ? (
										<Fragment>
											<th>Proveedor</th>
											<th>Periodicidad</th>
										</Fragment>
									) : null}
									<th>Producto</th>
									<th>Descripción</th>
									{is_root ? (
										<th align={"center"} style={{color: '#00a8ff'}}>IVA incluido</th>
									) : null}
									{is_root ? (
										<th align={"center"} style={{color: '#00a8ff'}}>No aplica IVA</th>
									) : null}
									<th align={"center"}>Cantidad</th>
									{is_root ? (
										<th align={"right"} style={{color: '#00a8ff'}}>Importe P. U.</th>
									) : null}
									<th align={"right"}>
										{is_root ? 'Subtotal P. U.' : 'Precio Unitario'}
									</th>
									{is_root ? (
										<th align={"right"} style={{color: '#00a8ff'}}>IVA P. U.</th>
									) : null}
									{is_root ? (
										<th align={"right"} style={{color: '#00a8ff'}}>Precio Unitario</th>
									) : null}
									{is_root ? (
										<th align={"right"} style={{color: '#00a8ff'}}>Importe Total</th>
									) : null}
									<th align={"right"}>
										{is_root ? 'Subtotal' : 'Total'}
									</th>
									{is_root ? (
										<th align={"right"} style={{color: '#00a8ff'}}>IVA</th>
									) : null}
									{is_root ? (
										<th align={"right"} style={{color: '#00a8ff'}}>Importe</th>
									) : null}
									{!this.is_view() ? (
										<th align={'right'} width={'110px'}>Acciones</th>
									) : null}
								</tr>
								</thead>
								<tbody>
								{this.state.lista_compra_producto.map((value, key) => (
									<tr key={key}>
										{this.state.servicio_compra === "Servicio" ? (
											<Fragment>
												<td>{value.proveedor}</td>
												<td>{value.periodicidad}</td>
											</Fragment>
										) : null}
										<td>{value.compra_producto}</td>
										<td>{value.compra_descripcion}</td>
										{is_root ? (
											<td align={"center"}>
												{value.iva_incluido === 1 ? (
													<div style={{
														width: "15px",
														color: "white",
														borderRadius: "3px",
														padding: "1px 7px",
														background: "#3F51B5",
														display: "inline-grid",
														fontSize: '12px',
													}}>Si</div>
												) : (
													<div style={{
														width: "15px",
														color: "white",
														borderRadius: "3px",
														padding: "1px 7px",
														background: "#FF5722",
														display: "inline-grid",
														fontSize: '12px',
													}}>No</div>
												)}
											</td>
										) : null}
										{is_root ? (
											<td align={"center"}>
												{value.iva_aplicado === 1 ? (
													<div style={{
														width: "15px",
														color: "white",
														borderRadius: "3px",
														padding: "1px 7px",
														background: "#3F51B5",
														display: "inline-grid",
														fontSize: '12px',
													}}>Si</div>
												) : (
													<div style={{
														width: "15px",
														color: "white",
														borderRadius: "3px",
														padding: "1px 7px",
														background: "#FF5722",
														display: "inline-grid",
														fontSize: '12px',
													}}>No</div>
												)}
											</td>
										) : null}
										<td align={"center"}>
											{value.compra_cantidad}
										</td>
										{is_root ? (
											<td align={"right"} style={{color: '#00a8ff'}}>
												${FieldsJs.Currency(value.compra_precio_unitario)}
											</td>
										) : null}
										<td align={"right"}>
											<b>
												${FieldsJs.Currency(this.calcularSubTotalConSinIVAPrecioUnitario(value))}
											</b>
										</td>
										{is_root ? (
											<td align={"right"} style={{color: '#00a8ff'}}>
												<b>
													${FieldsJs.Currency(this.calcularIVAPrecioUnitario(value))}
												</b>
											</td>
										) : null}
										{is_root ? (
											<td align={"right"} style={{color: '#00a8ff'}}>
												<b>
													${FieldsJs.Currency(this.calcularTotalConSinIVAPrecioUnitario(value))}
												</b>
											</td>
										) : null}
										{is_root ? (
											<td align={"right"} style={{color: '#00a8ff'}}>
												${FieldsJs.Currency(value.compra_total)}
											</td>
										) : null}
										<td align={"right"}>
											<b>
												${FieldsJs.Currency(this.calcularSubTotalConSinIVA(value))}
											</b>
										</td>
										{is_root ? (
											<td align={"right"} style={{color: '#00a8ff'}}>
												<b>
													${FieldsJs.Currency(this.calcularIVA(value))}
												</b>
											</td>
										) : null}
										{is_root ? (
											<td align={"right"} style={{color: '#00a8ff'}}>
												<b>
													${FieldsJs.Currency(this.calcularTotalConSinIVA(value))}
												</b>
											</td>
										) : null}
										{!this.is_view() ? (
											<td align={'right'}>
												<Tooltip
													TransitionComponent={Zoom}
													placement={"top"}
													title="Eliminar"
													children={(
														<Fab
															size="small"
															aria-label="Eliminar"
															onClick={() => this.delete(value, key)}
															style={{
																marginLeft: "10px",
																backgroundColor: "transparent",
																color: "#808080",
																boxShadow: "none",
															}}
															children={(
																<DeleteOutlined/>
															)}
														/>
													)}
												/>
												{this.state.key === key ? (
													<Tooltip
														TransitionComponent={Zoom}
														placement={"top"}
														title="Producto en edición"
														children={(
															<Fab
																size="small"
																aria-label="Editar"
																onClick={() => this.edit(value, key)}
																style={{
																	marginLeft: "10px",
																	backgroundColor: "#f50057",
																	color: "white",
																}}
																children={(
																	<EditOutlined/>
																)}
															/>
														)}
													/>
												) : (
													<Tooltip
														TransitionComponent={Zoom}
														placement={"top"}
														title="Editar producto"
														children={(
															<Fab
																size="small"
																aria-label="Editar"
																onClick={() => this.edit(value, key)}
																style={{
																	marginLeft: "10px",
																	backgroundColor: "transparent",
																	color: "#808080",
																	boxShadow: "none",
																}}
																children={(
																	<EditOutlined/>
																)}
															/>
														)}
													/>
												)}
											</td>
										) : null}
									</tr>
								))}
								{!(this.state.lista_compra_producto.length > 0) ? (
									<tr>
										<td colSpan={!this.is_view() ? (is_root ? 14 : 6) : (is_root ? 12 : 5)}
										    align={'center'}>
											<VistaVacia
												numero={null}
												mensaje={'Ningún producto agregado.'}
												image={carrito_de_compra_3}
												padding={'20px'}
												paddingText={'20px'}
												height={'80px'}
												width={'80px'}
											/>
										</td>
									</tr>
								) : null}
								<tr>
									<td colSpan={(is_root ? this.is_view() ? 11 : 11 : this.is_view() ? 3 : 3)}/>
									<td align={"right"}>Subtotal</td>
									<td align={"right"}>${FieldsJs.Currency(desglose.subtotal)}</td>
									{!this.is_view() ? (<td/>) : null}
								</tr>
								<tr>
									<td colSpan={(is_root ? this.is_view() ? 11 : 11 : this.is_view() ? 3 : 3)}/>
									<td align={"right"}>IVA</td>
									<td align={"right"}>${FieldsJs.Currency(desglose.iva)}</td>
									{!this.is_view() ? (<td/>) : null}
								</tr>
								<tr>
									<td colSpan={(is_root ? this.is_view() ? 11 : 11 : this.is_view() ? 3 : 3)}/>
									<td align={"right"}>Total</td>
									<td align={"right"}>${FieldsJs.Currency(desglose.total)}</td>
									{!this.is_view() ? (<td/>) : null}
								</tr>
								</tbody>
							</table>
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() ? (
									<Button onClick={() => this.save()} color="primary">
										{this.is_edit() > 0 ? 'Actualizar' : 'Agregar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalComprasServicios.propTypes = {
	id_compra_servicio: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	icono: PropTypes.element,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalComprasServicios;
