import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import {ReactLocalStorageService} from "../../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../../settings/PoPup/PoPup";
import EmpresaAcrescoCertificadoService from "../../../../../services/_Cat/EmpresaAcrescoCertificadoService/EmpresaAcrescoCertificadoService";
import {CONFIG} from "../../../../../settings/Config/Config";
import ModalCertificado from "./ModalCertificado";
import {DropboxApi} from "../../../../../settings/Dropbox/DropboxApi";
import {CloudDownload, Description} from "@material-ui/icons";
import {FieldsJs} from "../../../../../settings/General/General";
import BarraProgreso from "../../../Tickets/Includes/BarraProgreso";
import ModalRevocado from "../../../RepositorioJuridico/Includes/ModalRevocado";
import Button from "@material-ui/core/Button";

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar la sucursal ${item.nombre_comercial}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				EmpresaAcrescoCertificadoService.Eliminar(item.id_repositorio_juridico, item.id_empresa).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Open(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	descargarZip = (item) => {
		console.log("PATH ::: ", item);
		if (item.archivos.length > 0) {
			DropboxApi.Download(item.archivos[0].archivo).then(function (data) {
				console.log('Descarga con éxito!');
			}).catch(function (error) {
				console.error("ERROR::: ", error);
			});
		}
	};
	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'} className={'w-10-px padding-20-L padding-10-R'}>
								ID
							</TableCell>
							<TableCell component="th" align={'left'}>Empresa</TableCell>
							<TableCell component="th" align={'center'}>Documento</TableCell>
							<TableCell component="th" align={'center'}>Descarga</TableCell>
							<TableCell component="th" align={'center'}>Responsable Resguardo</TableCell>
							<TableCell component="th" align={'center'}>Departamento</TableCell>
							<TableCell component="th" align={'center'}>Vigencia</TableCell>
							<TableCell component="th" align={'center'}>Estatus</TableCell>
							<TableCell component="th" align={'center'}>Activo</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<TableRow key={key}>
								<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
									{item.id_repositorio_juridico}
								</TableCell>
								<TableCell align={'left'}>{item.razon_social}</TableCell>
								<TableCell align={'center'}>{item.tipo_documento}</TableCell>
								<TableCell align={'center'}>
									<div className={'text-center'}>
										<Tooltip TransitionComponent={Zoom} placement={"top"} title="Descargar">
											<IconButton aria-label="Descargar" onClick={() => this.descargarZip(item)}>
												<CloudDownload style={{color: 'rgb(142, 30, 50)'}}/>
											</IconButton>
										</Tooltip>
									</div>
								</TableCell>
								<TableCell align={'left'}>{item.responsable}</TableCell>
								<TableCell align={'left'}>{item.tipo_usuario}</TableCell>
								<TableCell align={'center'}>
									{FieldsJs.Field(item.fecha_vigencia) ? (
										<Tooltip TransitionComponent={Zoom} placement={"top"} title={"Vigencia: " + item.fecha_vigencia}>
											<div>
												<BarraProgreso color={item.id_cat_repositorio_juridico_estatus} completed={item.progreso || 0} buffer={100} value={item.progreso || 0} size={'150px'}/>
											</div>
										</Tooltip>
									) : (
										<BarraProgreso color={item.id_cat_repositorio_juridico_estatus} completed={item.progreso || 0} buffer={100} value={item.progreso || 0} size={'150px'}/>
									)}
								</TableCell>
								<TableCell align={'center'}>
									<div className={'w-auto vertical-inline'}>
										<div className={'v-center'}>
											<ModalRevocado
												tipo={'add'}
												item={item}
												componente={
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Cambiar estatus">
														<Button aria-label="Cambiar estatus" onClick={() => {
														}} className={'px-12'} style={{color: '#3e3e3e', fontWeight: 500, textTransform: 'capitalize'}}>
															{item.estatus}
														</Button>
													</Tooltip>
												}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
									</div>
								</TableCell>
								<TableCell align={'center'}>
									{item.activo === 1 ? (
										<div style={{
											width: "50px",
											color: "white",
											borderRadius: "3px",
											padding: "2px 10px",
											background: "darkgreen",
											display: "inline-grid",
										}}>Activo</div>
									) : (
										<div style={{
											width: "50px",
											color: "white",
											borderRadius: "3px",
											padding: "2px 10px",
											background: "#901C30",
											display: "inline-grid",
										}}>Inactivo</div>
									)}
								</TableCell>
								<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
									<div className={'w-auto vertical-inline'}>
										<div className={'v-center'}>
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
												<IconButton aria-label="Eliminar"
												            onClick={() => this.eliminar(item)}>
													<DeleteOutlined/>
												</IconButton>
											</Tooltip>
										</div>
										<div className={'v-center'}>
											<ModalCertificado
												tipo={'edit'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
										<div className={'v-center'}>
											<ModalCertificado
												tipo={'view'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ListaTabla;
