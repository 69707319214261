import React, {Component, Fragment} from 'react';

import Typography from "@material-ui/core/Typography";
import ListaTabla from './Includes/ListaTabla';
import ModalRepositorioJuridico from './Includes/ModalRepositorioJuridico';

import {RepositorioJuridicoService} from '../../../services/RepositorioJuridicoService/RepositorioJuridicoService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../settings/Config/Config";
import Paginacion from "../../Include/Paginacion/Paginacion";
import FiltrosRepositorioJuridico from "./Includes/FiltrosRepositorioJuridico";
import {BotonActualizarLista, BotonExportarListaExcel, BotonExportarListaPDF, CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import {ProyectoService} from "../../../services/_Cat/ProyectoService/ProyectoService";
import {ModuloService} from "../../../services/_Cat/ModuloService/ModuloService";
import Sync from "@material-ui/icons/Sync";
import Fab from "@material-ui/core/Fab";
import {EventNote, ListAlt, Publish} from "@material-ui/icons";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import ModalReport from "../Tickets/Includes/ModalReport";
import ModalBitacora from "./Includes/ModalBitacora";


class Resguardo extends Component {
	
	Usr = {};
	
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		let ids_expandido = ReactLocalStorageService.get('ids_expandido');
		
		this.state = {
			listar_resguardo: [],
			filtro: {
				id_repositorio_juridico: '',
				empresa: '',
				id_cat_tipo_documento_repositorio_juridico: '',
				id_cat_centro_trabajo_repositorio_juridico: '',
				responsable: '',
				representante_legal: '',
				activo: 'all'
			},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			ids_expandido: ids_expandido || []
		};
		
		this.RefreshList();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	Listar = () => {
		RepositorioJuridicoService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				listar_resguardo: response.data,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			this.setState({listar_resguardo: []});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	Eliminar = (item) => {
		
		let msg = `¿Deseas eliminar el usuario ${item.nombre} ${item.apellido_paterno} ${item.apellido_materno}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				RepositorioJuridicoService.Eliminar(item.id_usuario).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	ShowSubTabla = (item, key) => {
		let expandir = !!!item.expandir;
		this.handleChange({
			target: {
				value: expandir,
				name: 'expandir__' + key,
				type: 'text'
			}
		}, 'listar_resguardo', 'expandir');
		let ids_temp = this.state.ids_expandido;
		let ids_expandido = [];
		if (expandir) {
			ids_expandido = ids_temp;
			ids_expandido.push({
				id_resguardo: item.id_resguardo
			});
		} else {
			for (let i = 0; i < ids_temp.length; i++) {
				if (Number(ids_temp[i].id_resguardo) !== Number(item.id_resguardo)) {
					ids_expandido.push({
						id_resguardo: ids_temp[i].id_resguardo
					})
				}
			}
			
		}
		ReactLocalStorageService.set('ids_expandido', ids_expandido);
		this.setState({
			ids_expandido: ids_expandido
		});
	};
	
	SubTabla = (item, key) => {
		this.ShowSubTabla(item, key);
	};
	
	exportarBitacora = (item, archivo, auth, general) => {
		this.showSnackBars('error', "En desarrollo");
		return
		try {
			
			let filtro = {
				general: general || null,
				id_usuario: FieldsJs.Field(item) ? item.id_usuario : this.Usr.id_usuario
			};
			let params = {
				auth: auth,
				archivo: archivo,
				filtro: filtro
			};
			HttpRequest.export('_Resguardo_Excel', ['Resguardo_Equipos'], params, false, false, false, archivo === "EXCEL");
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	exportarResguardo = (item, archivo, auth, general) => {
		try {
			
			let filtro = {
				general: general || null,
				id_usuario: FieldsJs.Field(item) ? item.id_usuario : this.Usr.id_usuario
			};
			let params = {
				auth: auth,
				archivo: archivo,
				filtro: filtro
			};
			HttpRequest.export('_Resguardo_Excel', ['Resguardo_Equipos'], params, false, false, false, archivo === "EXCEL");
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	exportar = (item, archivo, auth) => {
		let filtro = {
			empresa: this.state.filtro.empresa || '',
			id_cat_tipo_documento_repositorio_juridico: this.state.filtro.id_cat_tipo_documento_repositorio_juridico || '',
			id_cat_centro_trabajo_repositorio_juridico: this.state.filtro.id_cat_centro_trabajo_repositorio_juridico || '',
			responsable: this.state.filtro.responsable || '',
			representante_legal: this.state.filtro.representante_legal || '',
			activo: this.state.filtro.activo || 'all',
		};
		console.log('EXPORTAR::: ', item);
		HttpRequest.export('repositorio_juridico', ['repositorio_juridico'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, false, false, false, archivo === "EXCEL")
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'Repositorio Jurídico'}
					botonEXCEL={
						<Fragment>
							<div className={'v-center'}>
								<Fragment>
									<BotonExportarListaExcel onClick={() => this.exportar({}, 'EXCEL', true)}/>
								</Fragment>
							</div>
						</Fragment>
					}
					botonPDF={
						<Fragment>
							<div className={'v-center'}>
								<ModalBitacora
									item={this.state}
									component={(
										<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#8e1e32"}}>
											<EventNote style={{fontSize: 15, marginRight: 5}}/> Bitácora
										</Fab>
									)}
									showSnackBars={this.showSnackBars}
								/>
							</div>
						</Fragment>
					}
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() => {
								this.Listar();
							}}/>
						</Fragment>
					}
				/>
				
				<FiltrosRepositorioJuridico
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				
				<div className={'form margin-30-B'}>
					
					<Typography variant={'h6'} className={'margin-30-T margin-30-B'}>
					
					</Typography>
					
					{this.state.listar_resguardo.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_resguardo}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
								SubTabla={this.SubTabla}
								exportarResguardo={this.exportarResguardo}
								//lista_usuarios={this.state.lista_usuarios}
								//lista_proyectos={this.state.lista_proyectos}
								//lista_proyectos_modulos={this.state.lista_proyectos_modulos}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				<ModalRepositorioJuridico
					tipo={'add'}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>
			
			</div>
		);
	}
}

Resguardo.propTypes = {};

export default IntegrationNotistack(Resguardo);
