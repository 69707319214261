import {iDB, IndexedDBRequest} from "../../settings/IndexedDBRequest/IndexedDBRequest";
import {FieldsJs} from '../../settings/General/General';

export const IDBLocalFichaTecnicaService = {
	Iniciar: Iniciar,
	VerificarClienteFichaTecnica: VerificarClienteFichaTecnica,
	SaveClienteFichaTecnica: SaveClienteFichaTecnica,
	UpdateClienteFichaTecnica: UpdateClienteFichaTecnica,
	DeleteClienteFichaTecnica: DeleteClienteFichaTecnica,
};

function Iniciar(id_cliente, id_ficha_tecnica, state) {
	
	return new Promise(function (resolve, reject) {
		VerificarClienteFichaTecnica(id_cliente, id_ficha_tecnica).then(function (info) {
			if (info.data.id_cliente > 0) {
				UpdateClienteFichaTecnica(id_cliente, state, id_ficha_tecnica).then(function (r) {
					resolve(r);
				}).catch(function (e) {
					reject(e);
				});
			} else {
				SaveClienteFichaTecnica(id_cliente, state, id_ficha_tecnica).then(function (r) {
					resolve(r);
				}).catch(function (e) {
					reject(e);
				});
			}
		}).catch(function (error) {
			reject(error);
		})
	})
}

function VerificarClienteFichaTecnica(id_cliente) {
	return new Promise(function (resolve, reject) {
		IndexedDBRequest.GetRow(iDB('acresco.production.db'), 'ficha_tecnica', ['id', 'id_ficha_tecnica', 'id_cliente', 'state'], {id_cliente: id_cliente}).then(function (response) {
			resolve(response);
		}).catch(function (error) {
			reject(error);
		});
	});
}

function SaveClienteFichaTecnica(id_cliente, state, id_ficha_tecnica) {
	var campos = {
		'id_cliente': id_cliente || '',
		'id_ficha_tecnica': id_ficha_tecnica || '',
		'state': JSON.stringify(state || {}),
	};
	return new Promise(function (resolve, reject) {
		try {
			if (!FieldsJs.Field(id_cliente)) {
				throw Object({
					status: 400,
					mensaje: 'id_cliente: Campo Requerido.'
				})
			}
			IndexedDBRequest.InsertData(iDB('acresco.production.db'), 'ficha_tecnica', campos).then(function (response) {
				resolve(response);
			}).catch(function (error) {
				reject(error);
			});
		} catch (error) {
			reject(error);
		}
	});
}

function UpdateClienteFichaTecnica(id_cliente, state, id_ficha_tecnica) {
	var campos = {
		'id_cliente': id_cliente || '',
		'id_ficha_tecnica': id_ficha_tecnica || '',
		'state': JSON.stringify(state || {}),
	};
	var where = {
		id_cliente: id_cliente
	};
	return new Promise(function (resolve, reject) {
		try {
			if (!FieldsJs.Field(id_cliente)) {
				throw Object({
					status: 400,
					mensaje: 'id_cliente: Campo Requerido.'
				})
			}
			IndexedDBRequest.UpdateData(iDB('acresco.production.db'), 'ficha_tecnica', campos, where).then(function (response) {
				resolve(response);
			}).catch(function (error) {
				reject(error);
			});
		} catch (error) {
			reject(error);
		}
	});
}

function DeleteClienteFichaTecnica(id_cliente) {
	var where = {
		"id_cliente": id_cliente
	};
	return new Promise(function (resolve, reject) {
		try {
			if (!FieldsJs.Field(id_cliente)) {
				throw Object({
					status: 400,
					mensaje: 'id_cliente: Campo Requerido.'
				})
			}
			IndexedDBRequest.DeleteData(iDB('acresco.production.db'), 'ficha_tecnica', where).then(function (response) {
				resolve(response);
			}).catch(function (error) {
				reject(error);
			});
		} catch (error) {
			reject(error);
		}
	});
}
