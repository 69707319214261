import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const CuentaBancariaService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				id_empresa: filtro.id_empresa || null,
				id_usuario_autorizado: filtro.id_usuario_autorizado || null,
				sucursal_apertura: filtro.sucursal_apertura || null,
				id_usuario_apoderado: filtro.id_usuario_apoderado || null,
				rfc: filtro.rfc || null,
				correo_electronico: filtro.correo_electronico || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cuenta_Bancaria_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		
		let params = {
			id_cuenta_bancaria: form.id_cuenta_bancaria,
			id_empresa: form.id_empresa,
			id_cat_banco: form.id_cat_banco,
			id_usuario_apoderado: form.id_usuario_apoderado,
			numero_cuenta: form.numero_cuenta,
			clabe: form.clabe,
			rfc: form.rfc ? form.rfc.toUpperCase():'',
			sucursal_apertura: form.sucursal_apertura,
			telefono: form.telefono,
			telefono_dos: form.telefono_dos,
			numero_cliente: form.numero_cliente,
			correo_electronico: form.correo_electronico,
			id_cat_estado: form.id_cat_estado,
			id_cat_municipio: form.id_cat_municipio,
			calle: form.calle,
			numero_exterior: form.numero_exterior,
			numero_interior: form.numero_interior,
			codigo_postal: form.codigo_postal,
			colonia: form.colonia,
			referencia: form.referencia,
			usuarios_autorizados: form.usuarios_autorizados,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cuenta_Bancaria_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cuenta_bancaria: form.id_cuenta_bancaria,
			id_empresa: form.id_empresa,
			id_cat_banco: form.id_cat_banco,
			id_usuario_apoderado: form.id_usuario_apoderado,
			numero_cuenta: form.numero_cuenta,
			clabe: form.clabe,
			rfc: form.rfc ? form.rfc.toUpperCase():'',
			sucursal_apertura: form.sucursal_apertura,
			telefono: form.telefono,
			telefono_dos: form.telefono_dos,
			numero_cliente: form.numero_cliente,
			correo_electronico: form.correo_electronico,
			id_cat_estado: form.id_cat_estado,
			id_cat_municipio: form.id_cat_municipio,
			calle: form.calle,
			numero_exterior: form.numero_exterior,
			numero_interior: form.numero_interior,
			codigo_postal: form.codigo_postal,
			colonia: form.colonia,
			referencia: form.referencia,
			usuarios_autorizados: form.usuarios_autorizados,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cuenta_Bancaria_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cuenta_bancaria) => {
		let params = {
			id_cuenta_bancaria: id_cuenta_bancaria
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cuenta_Bancaria_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
