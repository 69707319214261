import React, {Component, Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {$cWarn, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import {AssignmentTurnedIn, Cloud, SearchOutlined} from "@material-ui/icons";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import {CONFIG} from "../../../../settings/Config/Config";
import {ImportesNetosService} from "../../../../services/ImportesNetosService/ImportesNetosService";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 600,
	},
});


class SubTablaImportesNetos extends Component {
	state = {};
	Usr = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			folio: props.item.folio,
			item: props.item,
			listar_trabajadores: props.item.trabajadores || [],
		}
	}
	
	componentDidMount() {
		$cWarn("componentDidMount");
	}
	
	componentWillUnmount() {
		$cWarn("componentWillUnmount");
	}
	
	pasos = () => {
		let curculo = [
			{
				fecha_alta: "12/12/2019",
				serie: '1111111',
				folio: '2222222',
				folio_fiscal: '3333333',
				url_xml_factura: 'http://localhost:3000/facturacion',
				url_pdf_factura: 'http://localhost:3000/facturacion',
			},
			{
				fecha_alta: "12/12/2019",
				serie: '1111111',
				folio: '2222222',
				folio_fiscal: '3333333',
				url_xml_factura: 'http://localhost:3000/facturacion',
				url_pdf_factura: 'http://localhost:3000/facturacion',
			},
		
		];
		return curculo;
	};
	
	
	exportar = (url) => {
		try {
			if (!url) {
				throw Object({
					status: false, mensaje: 'URL Anexo no registrada.'
				})
			}
			
			window.open(url, '_blank');
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar al trabajador ${item.nombre}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				ImportesNetosService.EliminarTrabajador(item.id_importe_neto_trabajador).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					
					this.props.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	eliminarEmpresa = (item) => {
		let msg = `¿Deseas eliminar la empresa ${item.nombre_comercial}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				ImportesNetosService.EliminarEmpresa(item.id_importe_neto_empresa).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					
					this.props.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	
	render() {
		
		
		const {classes} = this.props;
		
		return (
			<Fragment>
				<div className={'bg-white card-1 padding-15-L padding-15-R'}>
					<Grid container spacing={2} alignItems={"flex-start"} alignContent={"flex-start"}>
						{this.state.item.empresas.length > 0 ? this.state.item.empresas.map((it, k) => {
								return (
									<Fragment key={k}>
										
										<Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
											<div className={'borde-punteado-gray-2-opacity'} style={{borderOpacity: '0.2'}}>
												<div className={'w-100 vertical-inline'}>
													<div className={'w-55-px'}></div>
													<div className={'w-100-110-px'}>
														<Typography className={'px-16 text-center bg-white padding-10-T'} style={{fontWeight: 'bold', textTransform: 'uppercase'}}>
															{it.nombre_comercial}
														</Typography>
													</div>
													<div className={'w-55-px'}>
														<Tooltip className={'padding-0-B'} TransitionComponent={Zoom} placement={"top"} title="Eliminar">
															<IconButton aria-label="Eliminar" onClick={() => this.eliminarEmpresa(it)}><DeleteOutlined/></IconButton>
														</Tooltip>
													</div>
												</div>
											
												<Typography className={'px-14 text-center bg-white padding-0'}>
													{'Nombre de la Empresa: ' + it.nombre_comercial}
												</Typography>
												<Typography className={'px-14 text-center bg-white padding-10-B'}>
													{'Fecha de emisión del reporte: ' + DateFormat.getFecha(it.fecha_emision)}
												</Typography>
												
												
												<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Table className={classes.table + ' desing-integraciones'} style={{minWidth: '100px !important'}}>
															<TableHead>
																<TableRow>
																	<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Clave</TableCell>
																	<TableCell component="th" align={'left'}>Nombre del Trabajador</TableCell>
																	<TableCell component="th" align={'right'}>Neto Pagado</TableCell>
																	<TableCell component="th" align={'right'}>Acciones</TableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																{it.trabajadores.map((item, key) => {
																	return (
																		<Fragment key={key}>
																			<TableRow>
																				<TableCell align={'left'} style={{paddingLeft: '15px'}}>{item.clave}</TableCell>
																				<TableCell align={'left'}>{item.nombre}</TableCell>
																				<TableCell align={'right'}>{item.neto_pagado}</TableCell>
																				<TableCell align={'right'}>
																					<div className={'v-center'}>
																						<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
																							<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}><DeleteOutlined/></IconButton>
																						</Tooltip>
																					</div>
																				</TableCell>
																			</TableRow>
																		</Fragment>
																	);
																})
																}
															</TableBody>
														</Table>
													</Grid>
												</Grid>
											</div>
										</Grid>
									</Fragment>
								)
							}
						) : null}
					</Grid>
				</div>
			</Fragment>
		);
	}
}

SubTablaImportesNetos.propTypes = {
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired
};

export default withStyles(styles)(SubTablaImportesNetos);
