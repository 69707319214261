import React, {Component, Fragment} from 'react';
import ListaTabla from './Includes/ListaTabla';
import ModalQuiniela from './Includes/ModalQuiniela';

import {QuinielaService} from '../../../services/QuinielaService/QuinielaService';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import Paginacion from "../../Include/Paginacion/Paginacion";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {AttachMoney, EventNote, Sync} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";


class Quiniela extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			listar_adquisicion: [],
			
			filtro: {},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
		};
		
		this.Listar();
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	Listar = () => {
		QuinielaService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				listar_adquisicion: response.data,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			this.setState({
				listar_adquisicion: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	exportar = (item, archivo, auth) => {
		let filtro = {
			id_adquisicion: item.id_adquisicion || '',
			id_usuario: item.id_usuario || '',
		};
		console.log('EXPORTAR::: ', item);
		HttpRequest.export('adquisicion', ['cuenta_pago'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, false, false, false, archivo === "EXCEL")
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<Grid container direction="row" justify="space-between" alignItems="center" spacing={2}
				      style={{marginTop: 20, marginBottom: 20}}>
					<Grid item>
						<Typography variant={'h5'} className={'text-left'}>
							{'Quiniela'}
						</Typography>
					</Grid>
					<Grid item>
						<div className={'vertical-inline w-100'}>
							{/*<div className={'v-center'}>
								<ModalPagos
									tipo={'add'}
									componente={
										<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#2884a5", marginRight: 15}}>
											<AttachMoney style={{fontSize: 14, marginRight: 5}}/> Nuevo Ingreso
										</Fab>
									}
									RefreshList={this.RefreshList}
									showSnackBars={this.showSnackBars}
								/>
							</div>
							<div className={'v-center'}>
								<ModalPagos
									tipo={'edit'}
									componente={
										<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#82261b", marginRight: 15}}>
											<AttachMoney style={{fontSize: 14, marginRight: 5}}/> Nuevo Egreso
										</Fab>
									}
									RefreshList={this.RefreshList}
									showSnackBars={this.showSnackBars}
								/>
							</div>
							<div className={'v-center'}>
								<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#006300", marginRight: 15}} onClick={() => this.exportar({}, 'EXCEL', true)}>
									<EventNote style={{fontSize: 14, marginRight: 5}}/> Excel
								</Fab>
							</div>*/}
							<div className={'v-center'}>
								<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#3f50b6"}} onClick={() => this.Listar()}>
									<Sync style={{fontSize: 14, marginRight: 5}}/> Actualizar
								</Fab>
							</div>
						</div>
					</Grid>
				</Grid>
				
				{/*<FiltrosQuiniela
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>*/}
				
				<div className={'form margin-30-B'} style={{marginTop: '20px'}}>
					
					{this.state.listar_adquisicion.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_adquisicion}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
								exportar={this.exportar}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (
					<ModalQuiniela
						tipo={'add'}
						item={{}}
						RefreshList={this.RefreshList}
						showSnackBars={this.showSnackBars}
					/>
				) : null}
			</div>
		);
	}
}

Quiniela.propTypes = {};

export default IntegrationNotistack(Quiniela);
