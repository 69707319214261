import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {RecordatoriosService} from "../../../../services/RecordatoriosService/RecordatoriosService";
import {CONFIG} from "../../../../settings/Config/Config";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";


class ListaTablaIngresos extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el recordatorio ${item.recordatorio}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				RecordatoriosService.Eliminar(item.id_recordatorio).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	activo = (item, activo) => {
		let msg = `¿Deseas ${activo ? 'activar':'desactivar'} el recordatorio ${item.recordatorio}?`;
		let txtbtn = activo ? 'Activar':'Desactivar';
		PopupService.Confirm(['Cancelar', txtbtn], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Activar' || r.button === 'Desactivar') {
				RecordatoriosService.Activo(item.id_recordatorio, activo).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>
								ID
							</TableCell>
							<TableCell component="th" align={'left'}>Miembro</TableCell>
							<TableCell component="th" align={'left'}>Importe</TableCell>
							<TableCell component="th" align={'left'}>Fecha Pago</TableCell>
							<TableCell component="th" align={'left'}>Observación</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<TableRow key={key}>
								<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
									{key+1}
								</TableCell>
								<TableCell align={'left'}>{item.nombre_completo_miembro}</TableCell>
								<TableCell align={'left'}>{item.importe}</TableCell>
								<TableCell align={'left'}>{DateFormat.FechaFormatText(item.fecha_pago)}</TableCell>
								<TableCell align={'left'}>{item.observacion}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTablaIngresos.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ListaTablaIngresos;
