import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import {AttachFile, AttachMoney, Lock, DeleteOutlined, Description, EditOutlined, HdrStrong, HdrWeakOutlined, HomeWork, House, SearchOutlined} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import EmpresaFacturacionService from "../../../../services/EmpresaFacturacionService/EmpresaFacturacionService";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import {CONFIG} from "../../../../settings/Config/Config";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import empresa_default from "../../../../assets/img/icons/business.svg";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import ModalEmpresaSucursalCertificadoArchivo from "./ModalEmpresaSucursalCertificadoArchivo";
import {KeyboardDatePicker} from "@material-ui/pickers";

class ModalEmpresaSucursalCertificado extends Component {
	
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_empresa_archivo: (props.item || '').id_empresa_archivo,
			id_empresa: (props.item || '').id_empresa,
			tipo: props.tipo,
			
			key: null,
			fecha_vigencia: null,
			id_empresa_sucursal: (props.item || '').id_empresa_sucursal,
			password: '',
			numero_certificado: '',
			cer_base64Tipo: '',
			cer_base64: '',
			cer_archivo: '',
			cer_formato: '',
			
			key_base64Tipo: '',
			key_base64: '',
			key_archivo: '',
			key_formato: '',
			
			sucursales: [],
			certificados: [],
		};
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		//this.setState({id_empresa_sucursal: this.state.id_empresa_sucursal ? this.state.id_empresa_sucursal:this.state.sucursales[0].id_empresa_sucursal});
		this.sucursales().then((response) => {
			this.setState({id_empresa_sucursal: response.data[0].id_empresa_sucursal});
			this.certificados().then((response) => {
				this.setState({open: true, scroll: 'paper'});
			});
		});
		
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	sucursales = () => {
		return new Promise((resolve, reject) => {
			EmpresaFacturacionExtService.Sucursales(this.props.item).then((response) => {
				this.setState({
					sucursales: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	certificados = (id_empresa_sucursal) => {
		return new Promise((resolve, reject) => {
			let response = [];
			if (this.state.key === null) {
				let id = id_empresa_sucursal ? id_empresa_sucursal : this.state.id_empresa_sucursal;
				
				EmpresaFacturacionExtService.Certificados(id).then((response) => {
					this.setState({
						certificados: response.data,
					});
					resolve(response);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
					reject(error);
				});
			} else {
				resolve(response);
			}
		});
	}
	
	save = () => {
		
		try {
			
			/*if (!FieldsJs.Field(this.state.fecha_apertura)) {
				throw Object({
					status: false, mensaje: 'Campo fecha apertura es requerido.'
				})
			}
			*/
			
			if (this.state.key !== null) {
				EmpresaFacturacionExtService.CertificadosModificar(this.state).then((response) => {
					this.edit_cancel().then((res) => {
						setTimeout(() => {
							this.certificados();
						}, 900);
					});
					hideSpinner('spinner', 500);
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			} else {
				EmpresaFacturacionExtService.CertificadosAgregar(this.state).then((response) => {
					this.edit_cancel().then((res) => {
						this.certificados();
					});
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					hideSpinner('spinner', 500);
					this.showSnackBars('error', error.mensaje);
				});
			}
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		const {item} = this.props;
		
		this.setState({
			id_empresa_archivo: item.id_empresa_archivo || '',
			id_empresa: item.id_empresa || '',
			id_empresa_sucursal: item.id_empresa_sucursal || '',
			key: null,
			fecha_vigencia: null,
			password: item.password || '',
			numero_certificado: item.numero_certificado || '',
			cer_base64Tipo: '',
			cer_base64: '',
			cer_archivo: '',
			cer_formato: '',
			
			key_base64Tipo: '',
			key_base64: '',
			key_archivo: '',
			key_formato: '',
			activo: true,
		});
		
		this.openModal();
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_empresa_archivo: item.id_empresa_archivo || '',
			id_empresa: item.id_empresa || '',
			tipo_archivo: item.tipo_archivo || '',
			observaciones: item.observaciones || null,
			fecha_emision: item.fecha_emision ? moment(item.fecha_emision) : null,
			fecha_vigencia: item.fecha_vigencia ? moment(item.fecha_vigencia) : null,
			fecha_apertura: item.fecha_apertura ? moment(item.fecha_apertura) : null,
			comprobante_pago: 1,
			archivos: item.archivos || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_empresa_archivo > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Estatus">
						<IconButton aria-label="Estatus" onClick={() => this.edit()}>
							<HdrStrong/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Certificados">
					<IconButton aria-label="Certificados" onClick={() => this.add()}><Lock style={{opacity: 0.7, color: Number(this.props.item.is_vigente) === 0 ? 'rgba(0, 0, 0, 0.54)':Number(this.props.item.is_vigente) === 1 ? '#8E1E32':'darkgreen'}}/></IconButton>
				</Tooltip>
			);
		}
		return BTN_ACTION;
	};
	
	edit = (value, key) => {
		try {
			
			this.setState({
				key: key,
				id_empresa_sucursal_certificado: value.id_empresa_sucursal_certificado || '',
				id_empresa_sucursal: value.id_empresa_sucursal || '',
				password: value.password || '',
				numero_certificado: value.numero_certificado || '',
				cer_base64Tipo: value.base64Tipo || '',
				cer_base64: CONFIG.src_facturacion + value.archivo_logo || '',
				cer_archivo: CONFIG.src_facturacion + value.archivo_logo || '',
				cer_formato: value.formato_logo || '',
				fecha_vigencia: value.fecha_vigencia ? moment(value.fecha_vigencia) : null,
				
				key_base64Tipo: value.base64Tipo || '',
				key_base64: CONFIG.src_facturacion + value.archivo_logo || '',
				key_archivo: CONFIG.src_facturacion + value.archivo_logo || '',
				key_formato: value.formato_logo || '',
				
				activo: value.activo ? 1 : 0
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		return new Promise((resolve) => {
			this.setState({
				key: null,
				password: '',
				numero_certificado: '',
				cer_base64Tipo: '',
				cer_base64: '',
				cer_archivo: '',
				cer_formato: '',
				
				key_base64Tipo: '',
				key_base64: '',
				key_archivo: '',
				key_formato: '',
				
				activo: true
			}, () => {
				resolve();
			});
			
		});
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el certificado con Id: ' + key + '?').then((r) => {
				if (r.button === 'Aceptar') {
					EmpresaFacturacionExtService.CertificadosDelete(value).then((response) => {
						this.certificados();
						this.edit_cancel();
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
						
					});
				}
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	mouseEvent = (event, item) => {
		if (event.type === "mousedown") {
			this.setState({password_ver: item.password});
		} else {
			this.setState({password_ver: ""});
		}
	}
	
	descargar = (archivo) => {
		const arc = archivo.split('.');
		console.log(CONFIG.src_facturacion + archivo);
		const url = CONFIG.src_facturacion + archivo;
		//const url = URL.createObjectURL(CONFIG.src_facturacion + archivo);
		const a = document.createElement('a');
		a.href = url;
		a.download = arc[arc.length - 1] || 'download';
		const clickHandler = () => {
			setTimeout(() => {
				URL.revokeObjectURL(url);
			}, 150);
		};
		a.addEventListener('click', clickHandler, false);
		a.click();
	}
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						{'Certificados'}
					</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} align={'center'}>
								<fieldset className={'borde-punteado-gray-2 text-center h-88'}>
									<legend align={'left'} style={{color: '#901C30', fontWeight: 'bold'}}> Empresa</legend>
									
									<Grid container spacing={2}>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
											<Typography variant={'h5'} className={'px-20 text-uppercase'} style={{fontWeight: 'bold'}}>
												{this.props.item.nombre_razon_social}
											</Typography>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<div className={''}>
												<div className={'card-0 w-70-px h-70-px'}>
													<img style={{width: 70, height: 70}} alt={'Logo'} src={this.props.item.archivo_logo ? CONFIG.src_facturacion + this.props.item.archivo_logo : empresa_default}/>
												</div>
											</div>
										</Grid>
										
										<Grid item xs={12} sm={12} md={8} lg={8} xl={8} align={'left'}>
											<Grid container spacing={2}>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
													<Typography variant={'h5'} className={'px-16 margin-0-T margin-0-B'}>
														{this.props.item.rfc}
													</Typography>
													<Typography variant={'h5'} className={'px-14'}>
														{this.props.item.tipo_persona}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								
								</fieldset>
							</Grid>
							
							<Grid item xs={12} sm={12} md={8} lg={8} xl={8} align={'left'}>
								<fieldset className={'borde-punteado-gray-2 text-left'}>
									<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Certificado</legend>
									
									<Grid container spacing={2}>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={8} lg={8} xl={8}>
											<FormControl fullWidth required>
												<InputLabel>Sucursal</InputLabel>
												<Select
													native
													value={this.state.id_empresa_sucursal}
													onChange={(e) => {
														this.handleChange(e);
														this.certificados(e.target.value);
													}}
													name="id_empresa_sucursal"
													input={<Input/>}
													disabled={this.props.tipo === 'view'}
												>
													<option value=""/>
													{this.state.sucursales.map(option => (
														<option key={option.id_empresa_sucursal}
														        value={option.id_empresa_sucursal}>
															{option.nombre}
														</option>
													))}
												</Select>
											</FormControl>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
												<KeyboardDatePicker
													format="dd/MM/yyyy"
													fullWidth
													required
													clearable
													inputProps={{readOnly: true}}
													/*KeyboardButtonProps={{
														'aria-label': 'Fecha de pago',
													}}*/
													label="Vigencia"
													value={this.state.fecha_vigencia}
													onChange={(date) => {
														this.handleChange(null, null, null, date, 'fecha_vigencia');
													}}
													// minDate={moment(new Date())}
													clearLabel={'Limpiar'}
													okLabel={'Aceptar'}
													cancelLabel={'Cancelar'}
													onError={console.log}
													disabled={this.props.tipo === 'view'}
												/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												required
												name="numero_certificado"
												label="Número de Certificado"
												type="text"
												fullWidth
												autocomplete="off"
												value={this.state.numero_certificado}
												onChange={this.handleChange}
												disabled={this.state.tipo === 'view'}
												/*onKeyPress={EnteroSolo}
												inputProps={{
													maxLength: 6,
												}}*/
											/>
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<TextField
												required
												name="password"
												label="Contraseña del Certificado"
												type="password"
												fullWidth
												autocomplete="off"
												value={this.state.password}
												onChange={this.handleChange}
												disabled={this.state.tipo === 'view'}
												/*onKeyPress={EnteroSolo}
												inputProps={{
													maxLength: 6,
												}}*/
											/>
										
										</Grid>
									
									</Grid>
								
								</fieldset>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Paper style={{overflowX: "auto"}}>
									<Table className={'desing-acresco'}>
										<TableHead>
											<TableRow>
												<TableCell component="th" align={'center'} className={'w-10-px padding-20-L padding-10-R'}>
													ID
												</TableCell>
												<TableCell component="th" align={'center'}>Sucursal</TableCell>
												<TableCell component="th" align={'center'}>Número de Certificado</TableCell>
												<TableCell component="th" align={'center'}>Password</TableCell>
												<TableCell component="th" align={'center'}>Archivo Certificado (.CER)</TableCell>
												<TableCell component="th" align={'center'}>Archivo Certificado (.KEY)</TableCell>
												<TableCell component="th" align={'center'}>Archivo Certificado (.PEM)</TableCell>
												<TableCell component="th" align={'center'}>Archivo Llave Privada (.PEM)</TableCell>
												<TableCell component="th" align={'center'}>Activo</TableCell>
												<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.certificados.map((item, key) => (
												<TableRow key={key}>
													<TableCell align={'center'} className={'padding-20-L padding-20-R'}> {key + 1} </TableCell>
													<TableCell align={'center'}>{item.nombre}</TableCell>
													<TableCell align={'center'}>{item.numero_certificado}</TableCell>
													<TableCell align={'center'}>
														<div className={'cursor-pointer hover-blue'} onMouseDown={(e) => {
															this.mouseEvent(e, item);
														}} onMouseUp={(e) => {
															this.mouseEvent(e, item);
														}}>{this.state.password_ver ? this.state.password_ver : "**********"}</div>
													</TableCell>
													<TableCell align={'center'}>
														{item.archivo_cer ? (
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Descargar"
																children={(
																	<Fab
																		size="small"
																		aria-label="Descargar"
																		onClick={() => this.descargar(item.archivo_cer)}
																		style={{
																			marginLeft: "10px",
																			backgroundColor: "transparent",
																			color: "#118e06",
																			boxShadow: "none",
																		}}
																		children={(
																			<Description/>
																		)}
																	/>
																)}
															/>
														) : (
															<ModalEmpresaSucursalCertificadoArchivo
																id_empresa_sucursal_certificado={item.id_empresa_sucursal_certificado}
																tipo={'add'}
																item={item}
																tipo_archivo={1}
																componente={(
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Subir archivo">
																		<IconButton aria-label="Subir archivo"><Lock/></IconButton>
																	</Tooltip>
																)}
																RefreshList={this.certificados}
																showSnackBars={this.props.showSnackBars}
															/>
														)}
													</TableCell>
													<TableCell align={'center'}>
														{item.archivo_key ? (
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Descargar"
																children={(
																	<Fab
																		size="small"
																		aria-label="Descargar"
																		onClick={() => this.descargar(item.archivo_key)}
																		style={{
																			marginLeft: "10px",
																			backgroundColor: "transparent",
																			color: "#118e06",
																			boxShadow: "none",
																		}}
																		children={(
																			<Description/>
																		)}
																	/>
																)}
															/>
														) : (
															<ModalEmpresaSucursalCertificadoArchivo
																id_empresa_sucursal_certificado={item.id_empresa_sucursal_certificado}
																tipo={'add'}
																item={item}
																tipo_archivo={2}
																componente={(
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Subir archivo">
																		<IconButton aria-label="Subir archivo"><Lock/></IconButton>
																	</Tooltip>
																)}
																RefreshList={this.certificados}
																showSnackBars={this.props.showSnackBars}
															/>
														)}
													
													</TableCell>
													<TableCell align={'center'}>
														{item.archivo_cer_pem ? (
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Descargar"
																children={(
																	<Fab
																		size="small"
																		aria-label="Descargar"
																		onClick={() => this.descargar(item.archivo_cer_pem)}
																		style={{
																			marginLeft: "10px",
																			backgroundColor: "transparent",
																			color: "#118e06",
																			boxShadow: "none",
																		}}
																		children={(
																			<Description/>
																		)}
																	/>
																)}
															/>
														) : (
															<ModalEmpresaSucursalCertificadoArchivo
																id_empresa_sucursal_certificado={item.id_empresa_sucursal_certificado}
																tipo={'add'}
																item={item}
																tipo_archivo={3}
																componente={(
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Subir archivo">
																		<IconButton aria-label="Subir archivo"><Lock/></IconButton>
																	</Tooltip>
																)}
																RefreshList={this.certificados}
																showSnackBars={this.props.showSnackBars}
															/>
														)}
													</TableCell>
													<TableCell align={'center'}>
														{item.archivo_key_pem ? (
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Descargar"
																children={(
																	<Fab
																		size="small"
																		aria-label="Descargar"
																		onClick={() => this.descargar(item.archivo_key_pem)}
																		style={{
																			marginLeft: "10px",
																			backgroundColor: "transparent",
																			color: "#118e06",
																			boxShadow: "none",
																		}}
																		children={(
																			<Description/>
																		)}
																	/>
																)}
															/>
														) : (
															<ModalEmpresaSucursalCertificadoArchivo
																id_empresa_sucursal_certificado={item.id_empresa_sucursal_certificado}
																tipo={'add'}
																item={item}
																tipo_archivo={4}
																componente={(
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Subir archivo">
																		<IconButton aria-label="Subir archivo"><Lock/></IconButton>
																	</Tooltip>
																)}
																RefreshList={this.certificados}
																showSnackBars={this.props.showSnackBars}
															/>
														)}
													</TableCell>
													
													<TableCell align={'center'}>
														{item.activo === 1 ? (
															<div style={{
																width: "50px",
																color: "white",
																borderRadius: "3px",
																padding: "2px 10px",
																background: "darkgreen",
																display: "inline-grid",
															}}>Activo</div>
														) : (
															<div style={{
																width: "50px",
																color: "white",
																borderRadius: "3px",
																padding: "2px 10px",
																background: "#901C30",
																display: "inline-grid",
															}}>Inactivo</div>
														)}
													</TableCell>
													
													
													<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
														<div className={'center'}>
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Eliminar"
																children={(
																	<Fab
																		size="small"
																		aria-label="Eliminar"
																		onClick={() => this.delete(item, key + 1)}
																		style={{
																			marginLeft: "10px",
																			backgroundColor: "transparent",
																			color: "#808080",
																			boxShadow: "none",
																		}}
																		children={(
																			<DeleteOutlined/>
																		)}
																	/>
																)}
															/>
															{this.state.key === key ? (
																<Tooltip
																	TransitionComponent={Zoom}
																	placement={"top"}
																	title="Cancelar edición"
																	children={(
																		<Fab
																			size="small"
																			aria-label="Cancelar edición"
																			onClick={() => this.edit_cancel(item, key)}
																			style={{
																				marginLeft: "10px",
																				backgroundColor: "#f50057",
																				color: "white",
																			}}
																			children={(
																				<EditOutlined/>
																			)}
																		/>
																	)}
																/>
															) : (
																<Tooltip
																	TransitionComponent={Zoom}
																	placement={"top"}
																	title="Editar"
																	children={(
																		<Fab
																			size="small"
																			aria-label="Editar"
																			onClick={() => this.edit(item, key)}
																			style={{
																				marginLeft: "10px",
																				backgroundColor: "transparent",
																				color: "#808080",
																				boxShadow: "none",
																			}}
																			children={(
																				<EditOutlined/>
																			)}
																		/>
																	)}
																/>)}
														</div>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</Paper>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.key !== null ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalEmpresaSucursalCertificado.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalEmpresaSucursalCertificado);
