import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import ModalLineaCaptura from "./ModalLineaCaptura";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {LineaCapturaService} from "../../../../services/LineaCapturaService/LineaCapturaService";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import ModalRecordatorios from "../../Reccordatorios/Includes/ModalRecordatorios";
import {AttachMoney, MonetizationOn, Money, SpeakerNotes, Description, InsertDriveFile} from "@material-ui/icons";
import ModalComprobantePago from "./ModalComprobantePago";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import icon_rocket_chat from "../../../../assets/img/icons/rocketchat.svg";
import ModalSendRocketChat from "./ModalSendRocketChat";
import ModalCancelar from "./ModalCancelar";
import ModalVerEliminarCP from "./ModalVerEliminarCP";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar la linea de captura con id ${item.id_linea_captura}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				LineaCapturaService.Eliminar(item.id_linea_captura).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					
					this.props.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	eliminarAcuse = (item) => {
		let msg = `¿Deseas eliminar el acuse de pago del id ${item.id_linea_captura}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				LineaCapturaService.EliminarAcuse(item.id_linea_captura).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					
					this.props.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	
	spanStatus = (item) => {
		let bgg = 'white';
		switch (Number(item.id_cat_cronograma_estatus)) {
			case 1:
				bgg = 'gray';
				break;
			case 2:
				bgg = '#00c1ff';
				break;
			case 3:
				bgg = '#fcee15';
				break;
			case 4:
				bgg = '#c66500';
				break;
			case 5:
				bgg = '#119703';
				break;
			default:
				bgg = '#ccc';
				break;
		}
		
		const foto_default = CONFIG.foto_default;
		
		return (
			<Fragment>
				<div className={'w-100 text-white'} style={{borderRadius: '5px', background: bgg}}>
					{item.cronograma_estatus}
				</div>
			</Fragment>
		);
	};
	
	verDocumento = (item, tipo) => {
		console.log(item);
		let path = '';
		if (!tipo) {
			if (item.lc_is_local) {
				path = CONFIG.src + item.lc;
				window.open(path, '', 'width=900, height=600');
			} else {
				
				var pat = item.lc.split('.');
				if (pat.length > 1) {
					path = pat[0];
					for (let i = 1; i < (pat.length - 1); i++) {
						path = path + '.' + pat[i];
					}
					path = path + "." + pat[pat.length - 1].toLowerCase();
				} else {
					path = pat[0] + "." + pat[1].toLowerCase();
				}
				
				DropboxApi.Open(path).then(function (data) {
					console.log('Descarga con éxito!');
				}).catch(function (error) {
					console.error("ERROR::: ", error);
				});
			}
		} else {
			if (item.acuse_is_local) {
				path = CONFIG.src + item.acuse;
				window.open(path, '', 'width=900, height=600');
			} else {
				
				var pat = item.acuse.split('.');
				if (pat.length > 1) {
					path = pat[0];
					for (let i = 1; i < (pat.length - 1); i++) {
						path = path + '.' + pat[i];
					}
					path = path + "." + pat[pat.length - 1].toLowerCase();
				} else {
					path = pat[0] + "." + pat[1].toLowerCase();
				}
				
				DropboxApi.Open(path).then(function (data) {
					console.log('Descarga con éxito!');
				}).catch(function (error) {
					console.error("ERROR::: ", error);
				});
			}
		}
		
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'} className={'padding-10-L'}>ID</TableCell>
							<TableCell component="th" align={'center'}>Fecha</TableCell>
							<TableCell component="th" align={'center'}>Usuario</TableCell>
							<TableCell component="th" align={'center'}>RFC</TableCell>
							<TableCell component="th" align={'center'}>Empresa</TableCell>
							<TableCell component="th" align={'center'}>Ejercicio</TableCell>
							<TableCell component="th" align={'center'}>Periodo</TableCell>
							<TableCell component="th" align={'center'}>Estatus</TableCell>
							<TableCell component="th" align={'center'}>Fecha Vigencia</TableCell>
							<TableCell component="th" align={'center'}>Tipo Impuesto</TableCell>
							<TableCell component="th" align={'center'}>Impuesto</TableCell>
							<TableCell component="th" align={'center'}>Dec.</TableCell>
							<TableCell component="th" align={'center'}>LC(PDF)</TableCell>
							<TableCell component="th" align={'center'}>Acuse Pago</TableCell>
							<TableCell component="th" align={'center'}>Fecha Pago</TableCell>
							<TableCell component="th" align={'center'}>Importe</TableCell>
							<TableCell component="th" align={'center'}>Accesorios</TableCell>
							<TableCell component="th" align={'center'}>Total</TableCell>
							<TableCell component="th" align={'center'} className={'w-100-px'}>Estatus Pago</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							lista.map((item, key) => {
								return (
									<TableRow key={key}>
										<TableCell align={'left'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.id_linea_captura}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{DateFormat.getFechaTextDiagonal(item.fecha_alta)}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.nombre_completo}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.rfc}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.nombre_comercial}</TableCell>
										<TableCell align={'center'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.ejercicio || 'Sin registrar'}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.periodo || 'Sin registrar'}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.status || 'Sin registrar'}</TableCell>
										<TableCell align={'center'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{DateFormat.getFechaTextDiagonal(item.fecha_vigencia)}</TableCell>
										<TableCell align={'center'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.abreviatura_impuesto || 'Sin registrar'}</TableCell>
										<TableCell align={'center'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>
											{item.impuestos.map((itemp, keyim) => (
												<Fragment key={keyim}>
													{keyim > 0 ? ' | ' + itemp.linea_captura_impuesto : itemp.linea_captura_impuesto}
												</Fragment>
											))}
										</TableCell>
										<TableCell align={'center'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.abreviatura_declaracion || 'Sin registrar'}</TableCell>
										<TableCell align={'center'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.archivos.map((itemarc) => (
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver LC(PDF)">
												<IconButton aria-label="Ver LC(PDF)" onClick={() => this.verDocumento(item, 0)}><Description/></IconButton>
											</Tooltip>
										))}
										</TableCell>
										<TableCell align={'center'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.acuse ? (
											<Fragment>
												{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
													<ModalVerEliminarCP
														item={item}
														tipo={'add'}
														componente={
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver Acuse Pago">
																<IconButton aria-label="Ver Acuse Pago"><InsertDriveFile style={{color: 'rgb(142, 30, 50)'}}/></IconButton>
															</Tooltip>
														}
														showSnackBars={this.props.showSnackBars}
														RefreshList={this.props.RefreshList}
														verDocumento={this.verDocumento}
														eliminarAcuse={this.eliminarAcuse}
													/>
												) : (
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver Acuse Pago">
														<IconButton aria-label="Ver Acuse Pago" onClick={() => this.verDocumento(item, 1)}><InsertDriveFile style={{color: 'rgb(142, 30, 50)'}}/></IconButton>
													</Tooltip>
												)}
											</Fragment>
										) : '-'}</TableCell>
										<TableCell align={'center'}
										           className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.fecha_pago ? DateFormat.getFechaTextDiagonal(item.fecha_pago) : '-'}</TableCell>
										<TableCell align={'left'}
										           className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.recargo > 0 ? '$' + FieldsJs.Currency(item.importe - item.recargo) : '$' + FieldsJs.Currency(item.importe)}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.recargo > 0 ? '$' + FieldsJs.Currency(item.recargo) : '-'}</TableCell>
										<TableCell align={'left'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.importe > 0 ? '$' + FieldsJs.Currency(item.importe) : '-'}</TableCell>
										<TableCell align={'center'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.status_pago}</TableCell>
										<TableCell align={'center'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-5-L padding-5-R' : 'padding-5-L padding-5-R'}>
											<div className={'w-auto vertical-inline'}>
												
												<div className={'v-center'}>
													{/*<ModalRecordatorios
														tipo={'new'}
														item={{}}
														componente={(
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Recordatorio">
																<IconButton aria-label="Recordatorio"><SpeakerNotes/></IconButton>
															</Tooltip>
														)}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>*/}
													<ModalSendRocketChat
														item={item}
														tipo={'add'}
														component={(
															<Tooltip TransitionComponent={Zoom} placement={"top"}
															         title="Enviar Notificación">
																<IconButton aria-label="Enviar Notificación">
																	<img className={'v-center'} src={icon_rocket_chat} alt="add" width={25} height={25} style={{opacity: 0.7}}/>
																</IconButton>
															</Tooltip>
														)}
														listar_usuarios={this.props.listar_usuarios}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
												
												{FieldsJs.inArray([1, 2, 6, 13], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<ModalComprobantePago
															tipo={'add'}
															item={item}
															componente={(
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Comprobante Pago">
																	<IconButton aria-label="Comprobante Pago"><AttachMoney/></IconButton>
																</Tooltip>
															)}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												) : null}
												
												{Number(this.Usr.id_usuario) === 124 ? (
													<Fragment>
														{item.id_usuario === 124 ? (
															<div className={'v-center'}>
																<ModalCancelar
																	tipo={'add'}
																	item={item}
																	componente={(
																		<Tooltip TransitionComponent={Zoom} placement={"top"} title="Cancelar">
																			<IconButton aria-label="Cancelar"><AttachMoney/></IconButton>
																		</Tooltip>
																	)}
																	RefreshList={this.props.RefreshList}
																	showSnackBars={this.props.showSnackBars}
																/>
															</div>
														) : null}
													</Fragment>
												) : (
													<div className={'v-center'}>
														<ModalCancelar
															tipo={'add'}
															item={item}
															componente={(
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Cancelar">
																	<IconButton aria-label="Cancelar"><AttachMoney/></IconButton>
																</Tooltip>
															)}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												)}
												
												{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
															<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}><DeleteOutlined/></IconButton>
														</Tooltip>
													</div>
												) : null}
												
												{Number(this.Usr.id_usuario) === 124 ? (
													<Fragment>
														{item.id_usuario === 124 ? (
															<div className={'v-center'}>
																<ModalLineaCaptura
																	tipo={'edit'}
																	item={item}
																	ultimoid={item.id_linea_captura}
																	ListEmpresa={this.props.ListEmpresa}
																	lista_empresa={this.props.lista_empresa}
																	RefreshList={this.props.RefreshList}
																	showSnackBars={this.props.showSnackBars}
																/>
															</div>
														) : null
														}
													</Fragment>
												) : (
													<div className={'v-center'}>
														<ModalLineaCaptura
															tipo={'edit'}
															item={item}
															ultimoid={item.id_linea_captura}
															ListEmpresa={this.props.ListEmpresa}
															lista_empresa={this.props.lista_empresa}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												)
												}
												
												<div className={'v-center'}>
													<ModalLineaCaptura
														tipo={'view'}
														item={item}
														ListEmpresa={this.props.ListEmpresa}
														lista_empresa={this.props.lista_empresa}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
											</div>
										
										</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	lista_empresa: PropTypes.array.isRequired,
	listar_usuarios: PropTypes.array,
	ListEmpresa: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
