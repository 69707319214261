import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {ImportesNetosService} from '../../../../services/ImportesNetosService/ImportesNetosService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";
import ModalEmpresa from "../../Cat/Empresa/Includes/ModalEmpresa";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {Business, DateRangeOutlined, DeleteOutlined, Group, HdrStrong, SpeakerNotes, Sync} from "@material-ui/icons";
import {DropzoneArea} from "material-ui-dropzone";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import DocSvg from "../../../../assets/img/icons/doc.svg";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import moment from "moment";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";


class ModalImportesNetos extends Component {
	
	Usr = {};
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_importe_neto: (props.item || '').id_importe_neto,
			tipo: props.tipo,
			observaciones: '',
			estatus: '',
			fecha_envio: null,
			fecha_inicio: null,
			fecha_fin: null,
			files: [],
			archivos: [],
			
		};
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	
	save = () => {
		
		try {
			
			/*if (!FieldsJs.Field(this.state.id_empresa)) {
				throw Object({
					status: false, mensaje: 'Campo empresa es requerido.'
				})
			}*/
			
			
			if (this.state.id_importe_neto > 0) {
				ImportesNetosService.Modificar(this.state).then((response) => {
					this.props.RefreshList();
					this.modalClose();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			} else {
				ImportesNetosService.Agregar(this.state).then((response) => {
					this.props.RefreshList();
					this.modalClose();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		
		this.setState({
			id_importe_neto: '',
			estatus: '',
			observaciones: '',
			fecha_envio: null,
			fecha_inicio: null,
			fecha_fin: null,
			activo: true,
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		this.setState({
			id_importe_neto: item.id_importe_neto || '',
			estatus: item.estatus || '',
			observaciones:  item.observacion || '',
			fecha_envio: item.fecha_envio ? new Date(item.fecha_envio) : null,
			fecha_inicio: item.fecha_inicio ? new Date(item.fecha_inicio) : null,
			fecha_fin: item.fecha_fin ? new Date(item.fecha_fin) : null,
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		this.setState({
			id_importe_neto: item.id_importe_neto || '',
			estatus: item.estatus || '',
			observaciones:  item.observacion || '',
			fecha_envio: item.fecha_envio ? new Date(item.fecha_envio) : null,
			fecha_inicio: item.fecha_inicio ? new Date(item.fecha_inicio) : null,
			fecha_fin: item.fecha_fin ? new Date(item.fecha_fin) : null,
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_importe_neto > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	descargarZip = (path) => {
		console.log("PATH ::: ", path);
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
	};
	
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<Grid>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Importe Neto</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2} alignItems={"flex-end"}>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<MDDatePicker
									type={'range'}
									component={(
										<TextField
											disabled={this.props.tipo === 'view'}
											required
											fullWidth
											label="Periodo"
											value={(this.state.fecha_inicio && this.state.fecha_fin) ? (DateFormat.FormatText(this.state.fecha_inicio) + " al " + DateFormat.FormatText(this.state.fecha_fin)) : ''}
											readOnly
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton aria-label="Periodo / Ejercicio">
															<DateRangeOutlined/>
														</IconButton>
													</InputAdornment>
												)
											}}
										/>
									)}
									startDate={this.state.fecha_inicio}
									endDate={this.state.fecha_fin}
									onChange={(range) => {
										console.log("RANGE", range);
										this.setState({
											fecha_inicio: range.startDate,
											fecha_fin: range.endDate,
										});
									}}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<KeyboardDateTimePicker
										format="yyyy/MM/dd hh:mm a"
										fullWidth
										clearable
										inputProps={{readOnly: true}}
										KeyboardButtonProps={{
											'aria-label': 'Fecha/Hora envío información',
										}}
										label="Fecha/Hora envío información"
										value={this.state.fecha_envio}
										onChange={(date) => {
											this.handleChange(null, null, null, date, 'fecha_envio');
										}}
										// minDate={moment(new Date())}
										clearLabel={'Limpiar'}
										okLabel={'Aceptar'}
										cancelLabel={'Cancelar'}
										onError={console.log}
										disabled={this.props.tipo === 'view'}
									/>
								
								{/*<KeyboardDatePicker
									format="dd/MM/yyyy"
									fullWidth
									required
									clearable
									inputProps={{readOnly: true}}
									label="Fecha envío información"
									value={this.state.fecha_envio}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_envio');
									}}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									disabled={this.props.tipo === 'view'}
								/>*/}
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									fullWidth
									multiline
									rows={3}
									onChange={this.handleChange}
									disabled={this.props.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="observaciones"
									label="Observaciones"
									value={this.state.observaciones}
								/>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_importe_neto > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</Grid>
		);
	}
}

ModalImportesNetos.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object,
	lista_empresa: PropTypes.array.isRequired,
	ListEmpresa: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalImportesNetos);
