import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles/index';
import Popover from '@material-ui/core/Popover/index';
import Typography from '@material-ui/core/Typography/index';
import MenuItem from '@material-ui/core/MenuItem/index';
import MenuList from '@material-ui/core/MenuList/index';
import Divider from '@material-ui/core/Divider/index';
import IconButton from '@material-ui/core/IconButton/index';
import ListItemIcon from '@material-ui/core/ListItemIcon/index';
import {DeleteOutline, MoreVert} from '@material-ui/icons/index';

import ModalClientePotencial from './ModalClientePotencial';
import {FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import ModalCliente from "./ModalCliente";

const useStyles = makeStyles(theme => ({
	typography: {
		padding: theme.spacing(0),
	},
}));

function PoPoverClientePotencial(props) {
	const {eliminar, item} = props;
	const Usr = ReactLocalStorageService.get('Usr');
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	
	function handleClick(event) {
		setAnchorEl(event.currentTarget);
	}
	
	function handleClose() {
		setAnchorEl(null);
	}
	
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : null;
	
	return (
		<div>
			
			<IconButton
				aria-label="More"
				aria-controls="long-menu"
				aria-haspopup="true"
				aria-describedby={id}
				onClick={handleClick}
			>
				<MoreVert/>
			</IconButton>
			
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				
				
				<MenuList style={{width: '250px'}}>
					
					<ModalCliente
						id_cliente={item.id_cliente}
						tipo={'view'}
						item={item}
						showSnackBars={props.showSnackBars}
						RefreshList={props.RefreshList}
					/>
					
					{FieldsJs.inArray([1, 2, 3, 13], Usr.id_cat_tipo_usuario) ? (
						<ModalCliente
							id_cliente={item.id_cliente}
							tipo={'edit'}
							item={item}
							showSnackBars={props.showSnackBars}
							RefreshList={props.RefreshList}
						/>
					) : ''}
					
					{FieldsJs.inArray([1, 2, 3, 13], Usr.id_cat_tipo_usuario) ? (
						<Divider className={'margin-10-T margin-10-B'}/>
					) : ''}
					
					{FieldsJs.inArray([1, 2, 3, 13], Usr.id_cat_tipo_usuario) ? (
						<MenuItem button={true} onClick={() => eliminar(item)}>
							<ListItemIcon>
								<DeleteOutline style={{
									color: 'red'
								}}/>
							</ListItemIcon>
							<Typography variant="inherit" noWrap style={{
								color: 'red'
							}} className={classes.typography}>
								Eliminar
							</Typography>
						</MenuItem>
					) : ''}
				
				</MenuList>
			</Popover>
		</div>
	);
}

PoPoverClientePotencial.propTypes = {
	tipo_vista: PropTypes.string.isRequired,
	eliminar: PropTypes.func.isRequired,
	item: PropTypes.object.isRequired,
	$BroadcastModalClientePotencial: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default PoPoverClientePotencial;
