import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import {ComprasService} from "../../../../services/ComprasService/ComprasService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {AddOutlined, CancelOutlined, DeleteOutlined, EditOutlined, PlaylistAddOutlined, SaveOutlined} from '@material-ui/icons';
import Fab from "@material-ui/core/Fab";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";

import carrito_de_compra_3 from '../../../../assets/img/icons/carro-de-la-compra-3.svg'
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ModalEmpresa from "./ModalEmpresa";
import ModalPlaza from "../../Cat/Plaza/Includes/ModalPlaza";
import ModalOficina from "./ModalOficina";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {CONFIG} from "../../../../settings/Config/Config";
import {PlazaService} from "../../../../services/_Cat/Plaza/PlazaService";
import {PlazaDireccionService} from "../../../../services/_Cat/PlazaDireccion/PlazaDireccionService";
import {EmpresaPlazaOficinaService} from "../../../../services/ComprasService/EmpresaPlazaOficinaService";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import ModalProveedor from "./ModalProveedor";
import {ProveedorService} from "../../../../services/ProveedorService/ProveedorService";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TabContext from "@material-ui/lab/TabContext";
import AppBar from "@material-ui/core/AppBar";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import {TabPanel} from "@material-ui/lab";
import {DropzoneArea} from "material-ui-dropzone";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import ModalComprasServicio from "./ModalComprasServicio";
import utf8 from "utf8";

class ModalCompras extends Component {

	state = {};

	Usr = {};

	constructor(props) {
		super(props);

		this.Usr = ReactLocalStorageService.get('Usr') || {};

		let dias_pago = [];
		for (let i = 1; i <= 31; i++) {
			dias_pago[i] = i;
		}

		this.state = {
			modal: {
				open: false
			},

			id_compra: props.id_compra,
			tipo: (props.item || '').tipo,

			id_cat_tipo_comprobante: '',
			id_plaza: '',
			id_empresa: '',
			id_plaza_direccion: '',
			quien_recibio: '',
			dia_pago: '',

			descripcion: '',
			fecha_compra: null,
			fecha_pago: null,
			total: 0,

			key: null,
			id_compra_producto: null,
			compra_producto: '',
			compra_descripcion: '',
			compra_cantidad: '',
			compra_precio_unitario: '',
			compra_total: '',
			iva_incluido: false,
			iva_aplicado: false,
			servicio_compra: 'Compra',
			id_cat_servicio_gasto: '',
			otro_servicio_gasto: '',
			observaciones: '',

			id_cat_periodicidad: '',
			lista_periodicidad: [],
			id_proveedor: '',
			lista_proveedor: [],

			lista_compra_producto: [],

			files: [],
			lista_empresa: [],
			lista_plaza: [],
			lista_plaza_direccion: [],

			lista_cat_tipo_comprobante: [],
			lista_servicio: [],
			lista_dias_pago: dias_pago,

			valuetab: '1',
			id_estatus_pago: '',
			
			base64Tipoxml: '',
			base64xml: '',
			archivoxml: '',
			formatoxml: '',
			nombre_archivoxml: '',
			base64Tipopdf: '',
			base64pdf: '',
			archivopdf: '',
			formatopdf: '',
			nombre_archivopdf: ''
		};
	}

	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};

	handleChangeIvaIncluido = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));

		if (!this.state.iva_incluido)
			this.setState({
				iva_aplicado: false
			})
	};

	handleChangeIvaAplicado = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));

		if (!this.state.iva_aplicado)
			this.setState({
				iva_incluido: false
			})
	};

	handleChangePlaza = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		this.setState({
			id_plaza_direccion: '',
			id_empresa: ''
		})
	};

	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};

	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
	};

	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};

	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {});
	};

	close = () => {
		this.closeModal();
	};

	init = () => {
		this.ListaPlaza();
		this.ListaServicioGasto();
		this.ListaProveedor();
		this.ListaPeriodicidad();

		return new Promise((resolve, reject) => {
			if (this.props.id_compra > 0) {
				ComprasService.Detalles(this.props.id_compra).then((response) => {
					let item = FieldsJs.Copy(response.data);
					let fecha_compra = item.fecha_compra ? DateFormat.FormatText(moment(item.fecha_compra)) : null;
					let fecha_pago = item.fecha_pago ? moment(item.fecha_pago) : null;


					this.setState({
						id_compra: item.id_compra,
						id_compra_servicio: item.id_compra_servicio,

						id_cat_tipo_comprobante: item.id_cat_tipo_comprobante || '',
						id_empresa: item.id_empresa || '',
						id_estatus_pago: item.id_estatus_pago || '',
						id_plaza: item.id_plaza || '',
						id_plaza_direccion: item.id_plaza_direccion || '',
						quien_recibio: item.quien_recibio || '',
						dia_pago: item.dia_pago || '',

						descripcion: item.descripcion || '',
						fecha_compra: fecha_compra,
						fecha_pago: fecha_pago,
						total: item.total || '',
						servicio_compra: item.servicio_compra || '',
						id_cat_servicio_gasto: item.id_cat_servicio_gasto || '',
						otro_servicio_gasto: item.otro_servicio_gasto || '',
						id_cat_periodicidad: item.id_cat_periodicidad || '',
						observaciones: item.observaciones || '',

						key: null,
						id_compra_producto: null,
						compra_producto: '',
						compra_descripcion: '',
						compra_cantidad: '',
						compra_precio_unitario: '',
						compra_total: '',
						iva_incluido: false,
						iva_aplicado: false,

						lista_compra_producto: item.compra_producto || [],
					});

					this.ListaPlazaDireccion('', item.id_plaza);
					this.ListaEmpresa('', item.id_plaza_direccion);

					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false)
				});
			} else {
				this.setState({

					id_compra: null,
					
					id_compra_servicio: '',
					id_estatus_pago: '',
					id_cat_tipo_comprobante: '',
					id_empresa: '',
					id_plaza: '',
					id_plaza_direccion: '',
					quien_recibio: '',
					id_cat_servicio_gasto: '',
					otro_servicio_gasto: '',
					id_cat_periodicidad: '',
					id_proveedor: '',
					dia_pago: '',
					observaciones: '',
					servicio_compra: 'Compra',

					descripcion: '',
					fecha_compra: null,
					fecha_pago: null,
					total: '',

					key: null,
					id_compra_producto: null,
					compra_producto: '',
					compra_descripcion: '',
					compra_cantidad: '',
					compra_precio_unitario: '',
					compra_total: '',
					iva_incluido: false,
					iva_aplicado: false,

					lista_empresa: [],
					lista_plaza: [],
					lista_plaza_direccion: [],
					lista_compra_producto: [],
				});

				resolve(true);
			}
		});
	};

	RefreshListEmpresa = (id_empresa) => {
		this.ListaEmpresa(true, this.state.id_plaza_direccion);
		if (id_empresa > 0) {
			this.setState({
				id_empresa: id_empresa
			})
		}
	};

	RefreshListPlazaDireccion = (id_plaza_direccion) => {
		this.ListaPlazaDireccion(true, this.state.id_plaza);
		if (id_plaza_direccion > 0) {
			this.setState({
				id_plaza_direccion: id_plaza_direccion
			})
		}
	};

	RefreshListPlaza = (id_plaza) => {
		if (id_plaza > 0) {
			this.setState({
				id_plaza: id_plaza
			})
		}
	};

	ListaServicioGasto = () => {
		CatService.ListServicioCompra().then(response => {
			this.setState({
				lista_servicio: response.data
			});
		}).catch(reason => {
			this.setState({
				lista_servicio: []
			});
		});
	};

	ListaPeriodicidad = () => {
		CatService.ListPeriodicidad().then(response => {
			this.setState({
				lista_periodicidad: response.data
			});
		}).catch(reason => {
			this.setState({
				lista_periodicidad: []
			});
		});
	};

	RefreshListProveedor = (id_proveedor) => {
		this.ListaProveedor();
		if (id_proveedor > 0) {
			this.setState({
				id_proveedor: id_proveedor
			})
		}
	};

	ListaProveedor = () => {
		ProveedorService.Listar().then(response => {
			this.setState({
				lista_proveedor: response.data
			});
		}).catch(reason => {
			this.setState({
				lista_proveedor: []
			});
		});
	};

	ListaEmpresa = (e, id_plaza_direccion) => {
		if (!id_plaza_direccion) {
			this.setState({
				id_plaza_direccion: e.target.value
			});
			id_plaza_direccion = e.target.value
		}

		EmpresaPlazaOficinaService.Listar(this.state, id_plaza_direccion).then(value => {
			this.setState({
				lista_empresa: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_empresa: []
			});
		});
	};

	ListaPlazaDireccion = (e, id_plaza) => {

		if (!id_plaza) {
			id_plaza = e.target.value
		}

		PlazaDireccionService.Listar(id_plaza).then((response) => {
			this.setState({
				lista_plaza_direccion: response.data
			});
		}).catch(reason => {
			this.setState({
				lista_plaza_direccion: []
			});
		});
	};


	ListaPlaza = () => {
		PlazaService.Listar().then(value => {
			this.setState({
				lista_plaza: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_plaza: []
			});
		});
	};

	plazaAbreviatura = (id_plaza) => {
		var plaza = '';
		for (let i = 0; i < this.state.lista_plaza.length; i++) {
			if (Number(this.state.lista_plaza[i].id_plaza) === Number(id_plaza)) {
				plaza = this.state.lista_plaza[i].plaza + ' - ' + this.state.lista_plaza[i].abreviatura;
			}
		}
		return plaza;
	}

	getPlazaOficina = (item) => {
		var plaza_direccion = this.plazaAbreviatura(item.id_plaza);
		for (let i = 0; i < this.state.lista_plaza_direccion.length; i++) {
			if (Number(this.state.lista_plaza_direccion[i].id_plaza_direccion) === Number(item.id_plaza_direccion)) {
				plaza_direccion = plaza_direccion + ' - ' + this.state.lista_plaza_direccion[i].nombre_plaza;
			}
		}
		return plaza_direccion;
	}
	
	getEmpresaPlazaOficina = (item) => {
		let id_empresa_plaza_oficina
		for (let i = 0; i < this.state.lista_empresa.length; i++) {
			if (Number(this.state.lista_empresa[i].id_empresa) === Number(item.id_empresa)) {
				id_empresa_plaza_oficina = this.state.lista_empresa[i].id_empresa_plaza_oficina;
			}
		}
		return id_empresa_plaza_oficina;
	}
	
	add = () => {

		try {

			if (!this.state.compra_producto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Producto.'
				})
			}

			if (!(this.state.compra_cantidad > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cantidad.'
				})
			}

			if (!(this.state.compra_precio_unitario > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Precio Unitario.'
				})
			}

			if (!(this.state.compra_total > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Total.'
				})
			}

			let proveedor = '';
			if (FieldsJs.Field(this.state.id_proveedor)) {
				let proveedores = FieldsJs.Copy(this.state.lista_proveedor || []);
				for (let i = 0; i < proveedores.length; i++) {
					if (Number(proveedores[i].id_proveedor) === Number(this.state.id_proveedor)) {
						proveedor = Number(proveedores[i].id_cat_tipo_persona) === 1 ? proveedores[i].nombre_completo : proveedores[i].nombre_comercial;
					}
				}
			}

			let periodicidad = '';
			if (FieldsJs.Field(this.state.id_cat_periodicidad)) {
				let periodicidades = FieldsJs.Copy(this.state.lista_periodicidad || []);
				for (let i = 0; i < periodicidades.length; i++) {
					if (Number(periodicidades[i].id_cat_periodicidad) === Number(this.state.id_cat_periodicidad)) {
						periodicidad = periodicidades[i].periodicidad;
					}
				}
			}

			let lista_compra_producto = FieldsJs.Copy(this.state.lista_compra_producto || []);

			if (this.is_edited_product()) {
				for (let i = 0; i < lista_compra_producto.length; i++) {
					let item = lista_compra_producto[i];
					if (this.state.key === i) {
						item.id_proveedor = this.state.id_proveedor || '';
						item.proveedor = proveedor || '';
						item.id_cat_periodicidad = this.state.id_cat_periodicidad || '';
						item.periodicidad = periodicidad || '';
						item.compra_producto = this.state.compra_producto || '';
						item.compra_producto = this.state.compra_producto || '';
						item.compra_descripcion = this.state.compra_descripcion || '';
						item.compra_cantidad = this.state.compra_cantidad || 0;
						item.compra_precio_unitario = this.state.compra_precio_unitario || 0;
						item.compra_total = this.state.compra_total || 0;
						item.iva_incluido = this.state.iva_incluido ? 1 : 0;
						item.iva_aplicado = this.state.iva_aplicado ? 1 : 0;
					}
				}
			} else {
				let item;
				item = {
					id_proveedor: this.state.id_proveedor || '',
					proveedor: proveedor || '',
					id_cat_periodicidad: this.state.id_cat_periodicidad || '',
					periodicidad: periodicidad || '',
					id_compra_producto: null,
					id_compra: this.state.id_compra || null,
					compra_producto: this.state.compra_producto || '',
					compra_descripcion: this.state.compra_descripcion || '',
					compra_cantidad: this.state.compra_cantidad || 0,
					compra_precio_unitario: this.state.compra_precio_unitario || 0,
					compra_total: this.state.compra_total || 0,
					iva_incluido: this.state.iva_incluido ? 1 : 0,
					iva_aplicado: this.state.iva_aplicado ? 1 : 0,
				};
				lista_compra_producto.push(item);
			}

			this.setState({
				key: null,
				id_proveedor: '',
				proveedor: '',
				id_cat_periodicidad: '',
				periodicidad: '',
				id_compra_producto: '',
				compra_producto: '',
				compra_descripcion: '',
				compra_cantidad: '',
				compra_precio_unitario: '',
				compra_total: '',
				iva_incluido: false,
				iva_aplicado: false,
				lista_compra_producto: lista_compra_producto
			});

			this.calcularTotalCompra();

		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};

	save = () => {
		try {

			/*if (!FieldsJs.Field(this.state.servicio_compra)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Servicio o Compra.'
				})
			}*/

			/*if (!FieldsJs.Field(this.state.descripcion)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Descripción.'
				})
			}*/
			
			if (this.state.files.length < 2) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Archivo xml y pdf.'
				})
			}
			
			if(this.state.servicio_compra !== 'Servicio') {
				if (!this.state.fecha_compra) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Fecha de compra.'
					})
				}

				if (!this.state.total) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Importe.'
					})
				}
			}

			try {
				ComprasService.Guardar(this.state).then((response) => {
					this.closeModal();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			} catch (e) {
				this.props.showSnackBars('error', e.mensaje);
			}
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};

	edit = (value, key) => {
		try {

			this.setState({
				key: key,
				id_compra_producto: value.id_compra_producto,
				id_proveedor: value.id_proveedor,
				proveedor: value.proveedor,
				id_cat_periodicidad: value.id_cat_periodicidad,
				periodicidad: value.periodicidad,
				compra_producto: value.compra_producto,
				compra_descripcion: value.compra_descripcion,
				compra_cantidad: value.compra_cantidad,
				compra_precio_unitario: value.compra_precio_unitario,
				compra_total: value.compra_total,
				iva_incluido: (value.iva_incluido === 1),
				iva_aplicado: (value.iva_aplicado === 1),
			});

		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};

	edit_cancel = () => {
		this.setState({
			key: null,
			id_compra_producto: '',
			id_proveedor: '',
			proveedor: '',
			id_cat_periodicidad: '',
			periodicidad: '',
			compra_producto: '',
			compra_descripcion: '',
			compra_cantidad: '',
			compra_precio_unitario: '',
			compra_total: '',
			iva_incluido: false,
			iva_aplicado: false,
		});
	};

	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar esta este producto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_compra_producto = FieldsJs.Copy(this.state.lista_compra_producto);

					lista_compra_producto.splice(key, 1);

					this.setState({
						lista_compra_producto: lista_compra_producto
					});

					this.calcularTotalCompra();
				}
			});

		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};

	calcularTotalProducto = () => {
		setTimeout(() => {
			let compra_cantidad = this.state.compra_cantidad;
			let compra_precio_unitario = this.state.compra_precio_unitario;
			let compra_total = 0;

			if (compra_cantidad > 0 && compra_precio_unitario > 0) {
				compra_total = (compra_cantidad * compra_precio_unitario);
			}

			this.setState({
				compra_total: compra_total
			});
		});
	};

	calcularPrecioUnitarioProducto = () => {
		setTimeout(() => {
			let compra_cantidad = this.state.compra_cantidad || 1;
			let compra_precio_unitario = 0;
			let compra_total = this.state.compra_total;

			if (compra_cantidad > 0 && compra_total > 0) {
				if (this.state.iva_aplicado === 0) {
					compra_precio_unitario = (compra_total / compra_cantidad);
				}
			}

			this.setState({
				compra_cantidad: compra_cantidad,
				compra_precio_unitario: compra_precio_unitario
			});
		});
	};

	calcularTotalCompra = () => {
		setTimeout(() => {
			let lista_compra_producto = this.state.lista_compra_producto || [];
			let total = 0;
			for (let i = 0; i < lista_compra_producto.length; i++) {
				let item = lista_compra_producto[i];
				if (Number(item.compra_total) > 0) {
					total += this.calcularTotalConSinIVA(item);
				}
			}
			this.setState({
				total: total
			});
		});
	};


	calcularSubTotalConSinIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1 && value.iva_aplicado === 0) {
			return value.compra_precio_unitario / 1.16;
		} else {
			return value.compra_precio_unitario;
		}
	};

	calcularIVAPrecioUnitario = (value) => {
		if (value.iva_aplicado === 0) {
			if (value.iva_incluido === 1) {
				return value.compra_precio_unitario - (value.compra_precio_unitario / 1.16);
			} else {
				return value.compra_precio_unitario * 0.16;
			}
		} else {
			return 0;
		}
	};

	calcularTotalConSinIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1 || value.iva_aplicado === 1) {
			return value.compra_precio_unitario;
		} else {
			return value.compra_precio_unitario * 1.16;
		}
	};

	calcularSubTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1 && value.iva_aplicado === 0) {
			return value.compra_total / 1.16;
		} else {
			return value.compra_total;
		}
	};

	calcularIVA = (value) => {
		if (value.iva_aplicado === 0) {
			if (value.iva_incluido === 1) {
				return value.compra_total - (value.compra_total / 1.16);
			} else {
				return value.compra_total * 0.16;
			}
		} else {
			return 0;
		}
	};

	calcularTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1 || value.iva_aplicado === 1) {
			return value.compra_total;
		} else {
			return value.compra_total * 1.16;
		}
	};

	calcularDesgoseTotales = () => {
		let desglose = {
			subtotal: 0,
			iva: 0,
			total: 0,
		};
		let lista_compra_producto = this.state.lista_compra_producto;
		for (let i = 0; i < lista_compra_producto.length; i++) {
			let item = lista_compra_producto[i];
			desglose.subtotal += this.calcularSubTotalConSinIVA(item);
			desglose.iva += this.calcularIVA(item);
			desglose.total += this.calcularTotalConSinIVA(item);
		}
		return desglose;
	};

	validatePlaza = () => {
		let band = false;

		if (this.state.id_plaza > 0) {
			band = true;
		}

		return band;
	};

	is_view = () => {
		return this.props.tipo === 'view';
	};

	is_edit = () => {
		return this.props.tipo === 'edit';
	};

	is_add = () => {
		return this.props.tipo === 'add';
	};

	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};

	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};


	handleChangeTabs = (e, variable, campo, date, input) => {
		try {
			if (this.state.informacion_global && !FieldsJs.Field(this.state.anio_global)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Año.'
				})
			}

			this.setState({
				valuetab: variable
			});
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	searchXmlToJson = (json, name, elemento) => {
		let valor = '';
		if (json.hasOwnProperty('elements')) {
			for (let a = 0; a < json.elements.length; a++) {
				if (json.elements[a].hasOwnProperty('name') && json.elements[a].hasOwnProperty('attributes')) {
					if (json.elements[a].name === name) {
						valor = json.elements[a].attributes[elemento];
					}
				}
				if (json.elements[a].hasOwnProperty('elements')) {
					for (let b = 0; b < json.elements[a].elements.length; b++) {
						if (json.elements[a].elements[b].hasOwnProperty('name') && json.elements[a].elements[b].hasOwnProperty('attributes')) {
							if (json.elements[a].elements[b].name === name) {
								valor = json.elements[a].elements[b].attributes[elemento];
							}
						}
						if (json.elements[a].elements[b].hasOwnProperty('elements')) {
							for (let c = 0; c < json.elements[a].elements[b].elements.length; c++) {
								if (json.elements[a].elements[b].elements[c].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].hasOwnProperty('attributes')) {
									if (json.elements[a].elements[b].elements[c].name === name) {
										valor = json.elements[a].elements[b].elements[c].attributes[elemento];
									}
								}
								if (json.elements[a].elements[b].elements[c].hasOwnProperty('elements')) {
									for (let d = 0; d < json.elements[a].elements[b].elements[c].elements.length; d++) {
										if (json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('attributes')) {
											if (json.elements[a].elements[b].elements[c].elements[d].name === name) {
												valor = json.elements[a].elements[b].elements[c].elements[d].attributes[elemento];
											}
										}
										if (json.elements[a].elements[b].elements[c].elements[d].hasOwnProperty('elements')) {
											for (let f = 0; f < json.elements[a].elements[b].elements[c].elements[d].elements.length; f++) {
												if (json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('attributes')) {
													if (json.elements[a].elements[b].elements[c].elements[d].elements[f].name === name) {
														valor = json.elements[a].elements[b].elements[c].elements[d].elements[f].attributes[elemento];
													}
												}
												if (json.elements[a].elements[b].elements[c].elements[d].elements[f].hasOwnProperty('elements')) {
													for (let g = 0; g < json.elements[a].elements[b].elements[c].elements[d].elements[f].elements.length; g++) {
														if (json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].hasOwnProperty('name') && json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].hasOwnProperty('attributes')) {
															if (json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].name === name) {
																valor = json.elements[a].elements[b].elements[c].elements[d].elements[f].elements[g].attributes[elemento];
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		
		if (name === 'cfdi:Emisor' && elemento === 'Nombre' ||
			name === 'cfdi:Receptor' && elemento === 'Nombre')
			valor = utf8.decode(valor);
		
		return valor;
	};
	
	handleChangeDropzoneArea = (files) => {
		this.setState({
			files: files
		}, () => {
			if (this.state.files.length > 0) {
				showSpinner('spinner');
				
				for (let i = 0; i < this.state.files.length; i++) {
					if (this.state.files[i].type === "text/xml") {
						FileBase64.Base64XmlToJson(this.state.files[i]).then((response) => {
							let xmljson = {
								rfc_emisor: this.searchXmlToJson(response, 'cfdi:Emisor', 'Rfc' || ''),
								empresa_facturadora: this.searchXmlToJson(response, 'cfdi:Emisor', 'Nombre' || ''),
								rfc_receptor: this.searchXmlToJson(response, 'cfdi:Receptor', 'Rfc' || ''),
								cliente: this.searchXmlToJson(response, 'cfdi:Receptor', 'Nombre' || ''),
								iva: this.searchXmlToJson(response, 'cfdi:Impuestos', 'TotalImpuestosTrasladados' || ''),
								total_facturado: this.searchXmlToJson(response, 'cfdi:Emisor', 'Rfc' || ''),
								concepto_facturacion: this.searchXmlToJson(response, 'cfdi:Concepto', 'Descripcion' || ''),
								clave_unidad: this.searchXmlToJson(response, 'cfdi:Concepto', 'ClaveUnidad' || ''),
								clave_concepto: this.searchXmlToJson(response, 'cfdi:Concepto', 'ClaveProdServ' || ''),
								
								version: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Version' || ''),
								serie: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Serie' || ''),
								folio: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Folio' || ''),
								fecha: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Fecha') ? DateFormat.FormatSql(moment(this.searchXmlToJson(response, 'cfdi:Comprobante', 'Fecha'))) : '',
								//sello: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Sello' || ''),
								forma_pago: this.searchXmlToJson(response, 'cfdi:Comprobante', 'FormaPago' || ''),
								numero_certificado: this.searchXmlToJson(response, 'cfdi:Comprobante', 'NoCertificado' || ''),
								//certificado: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Certificado' || ''),
								subtotal: this.searchXmlToJson(response, 'cfdi:Comprobante', 'SubTotal' || ''),
								moneda: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Moneda' || ''),
								tipo_cambio: this.searchXmlToJson(response, 'cfdi:Comprobante', 'TipoCambio' || ''),
								total: this.searchXmlToJson(response, 'cfdi:Comprobante', 'Total' || ''),
								tipo_comprobante: this.searchXmlToJson(response, 'cfdi:Comprobante', 'TipoDeComprobante' || ''),
								metodo_pago: this.searchXmlToJson(response, 'cfdi:Comprobante', 'MetodoPago' || ''),
								lugar_expedicion: this.searchXmlToJson(response, 'cfdi:Comprobante', 'LugarExpedicion' || ''),
								uuid: this.searchXmlToJson(response, 'tfd:TimbreFiscalDigital', 'UUID') || '',
								uuid_relacionado: this.searchXmlToJson(response, 'cfdi:CfdiRelacionado', 'UUID') || '',
								base64: response.base64,
								archivo: response.archivo,
								formato: response.formato,
								nombre_archivo: response.name,
								file: this.state.files[i],
								ruta: ''
							};
							
							this.setState({
								fecha_compra: xmljson.fecha,
								total: xmljson.total
							});
							
							this.setState({
								base64Tipoxml: '',
								base64xml: '',
								archivoxml: '',
								formatoxml: '',
								nombre_archivoxml: ''
							}, () => {
								this.darformato(this.state.files[i], true).then(() => {});
							});
							
							hideSpinner('spinner');
						}).catch((error) => {
							hideSpinner('spinner');
						})
					} else {
							this.setState({
								base64Tipopdf: '',
								base64pdf: '',
								archivopdf: '',
								formatopdf: '',
								nombre_archivopdf: ''
							}, () => {
								this.darformato(this.state.files[i]).then(() => {});
							});
					}
					if (i >= (files.length - 1)) {
						hideSpinner('spinner');
					}
				}
			} else {
				this.setState({jsonXML: '', jsonXMLError: ''});
			}
		});
		
		
	};
	
	darformato = (file, xml) => {
		return new Promise((resolve, reject) => {
			let formatos = ["application/pdf","text/xml"];
			let fil = {files: [file]};
			FileBase64.Base64(fil, formatos, this.props.MegaByte || null, true).then((response) => {
				//this.props.onChange(response);
				if (xml) {
					this.handleChange({
						target: {
							name: 'base64Tipoxml',
							value: response.base64Tipo
						}
					});
					this.handleChange({
						target: {
							name: 'base64xml',
							value: response.base64
						}
					});
					this.handleChange({
						target: {
							name: 'archivoxml',
							value: response.archivo
						}
					});
					this.handleChange({
						target: {
							name: 'formatoxml',
							value: response.formato
						}
					});
					this.handleChange({
						target: {
							name: 'nombre_archivoxml',
							value: response.name
						}
					});
				} else {
					this.handleChange({
						target: {
							name: 'base64Tipopdf',
							value: response.base64Tipo
						}
					});
					this.handleChange({
						target: {
							name: 'base64pdf',
							value: response.base64
						}
					});
					this.handleChange({
						target: {
							name: 'archivopdf',
							value: response.archivo
						}
					});
					this.handleChange({
						target: {
							name: 'formatopdf',
							value: response.formato
						}
					});
					this.handleChange({
						target: {
							name: 'nombre_archivopdf',
							value: response.name
						}
					});
				}
				
				resolve(true);
			}).catch((error) => {
				
				this.props.showSnackBars('error', error.mensaje);
				reject();
			});
		});
	}

	ListaEmpresa = (e, id_plaza_direccion) => {
		if (!id_plaza_direccion) {
			this.setState({
				id_plaza_direccion: e.target.value
			});
			id_plaza_direccion = e.target.value
		}

		EmpresaPlazaOficinaService.Listar(this.state, id_plaza_direccion).then(value => {
			this.setState({
				lista_empresa: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_empresa: []
			});
		});
	};

	ListaPlazaDireccion = (e, id_plaza) => {

		if (!id_plaza) {
			id_plaza = e.target.value
		}

		PlazaDireccionService.Listar(id_plaza).then((response) => {
			this.setState({
				lista_plaza_direccion: response.data
			});
		}).catch(reason => {
			this.setState({
				lista_plaza_direccion: []
			});
		});
	};

	ListaPlaza = () => {
		PlazaService.Listar().then(value => {
			this.setState({
				lista_plaza: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_plaza: []
			});
		});
	};

	RefreshListEmpresa = (id_empresa) => {
		this.ListaEmpresa(true, this.state.id_plaza_direccion);
		if (id_empresa > 0) {
			this.setState({
				id_empresa: id_empresa
			})
		}
	};

	RefreshListPlazaDireccion = (id_plaza_direccion) => {
		this.ListaPlazaDireccion(true, this.state.id_plaza);
		if (id_plaza_direccion > 0) {
			this.setState({
				id_plaza_direccion: id_plaza_direccion
			})
		}
	};

	RefreshListPlaza = (id_plaza) => {
		if (id_plaza > 0) {
			this.setState({
				id_plaza: id_plaza
			})
		}
	};
	
	handleChangeTipoServicio = (valor) => {
		let ts = FieldsJs.Copy(this.state.lista_servicio);
		for (let i = 0; i < ts.length; i++) {
			if (Number(ts[i].id_compra_servicio) === Number(valor)) {
				this.setState({
					id_plaza: ts[i].id_plaza,
					id_plaza_direccion: ts[i].id_plaza_direccion,
					id_empresa: ts[i].id_empresa
				})
			}
		}
	};

	render() {

		const is_root = this.is_root();

		const desglose = this.calcularDesgoseTotales();

		return (
			<div>

				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>

				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>

					<DialogTitle>
						Servicios / Gastos
					</DialogTitle>

					<DialogContent>

						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>

							<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Selecciona una opción:</FormLabel>
									<RadioGroup row aria-label="position" name="servicio_compra"
									            value={this.state.servicio_compra}
									            onChange={this.handleChange}
									>
										<FormControlLabel
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={'Servicio'}
											label="Servicio"
											disabled={this.props.tipo === 'view'}
										/>
										<FormControlLabel
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={'Compra'}
											label="Compra"
											disabled={this.props.tipo === 'view'}
										/>
									</RadioGroup>
								</FormControl>
							</Grid>

							{this.state.servicio_compra === "Servicio" ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
										<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
											<Grid item style={{width: '50px'}}>
												<Fragment>
													<ModalComprasServicio
														componente={(
															<Tooltip
																TransitionComponent={Zoom}
																placement={"right"}
																title="Agregar nuevo servicio"
																children={(
																	<Fab
																		color="default"
																		size="small"
																		aria-label="Agregar nuevo servicio"
																		children={(
																			<PlaylistAddOutlined/>
																		)}
																	/>
																)}
															/>
														)}
														id_compra={null}
														tipo={'add'}
														item={{
															id_plaza: this.state.id_plaza,
															id_plaza_direccion: this.state.id_plaza_direccion,
															plaza_direccion: this.getPlazaOficina(this.state)
														}}
														RefreshList={this.ListaServicioGasto}
														showSnackBars={this.props.showSnackBars}
													/>
												</Fragment>
											</Grid>
											{this.state.id_compra_servicio ? (
												<Grid item style={{width: '50px'}}>
													<Fragment>
														<ModalComprasServicio
															componente={(
																<Tooltip
																	TransitionComponent={Zoom}
																	placement={"right"}
																	title="Editar servicio"
																	children={(
																		<Fab
																			color="default"
																			size="small"
																			aria-label="Editar servicio"
																			children={(
																				<EditOutlined/>
																			)}
																		/>
																	)}
																/>
															)}
															id_compra_servicio={this.state.id_compra_servicio}
															tipo={'edit'}
															item={{
																id_plaza: this.state.id_plaza,
																id_plaza_direccion: this.state.id_plaza_direccion,
																plaza_direccion: this.getPlazaOficina(this.state)
															}}
															RefreshList={this.ListaServicioGasto}
															showSnackBars={this.props.showSnackBars}
														/>
													</Fragment>
												</Grid>
											) : null }
											<Grid item style={{width: this.state.id_compra_servicio ? 'calc(100% - 100px)':'calc(100% - 50px)'}}>
												<TextField
													select
													fullWidth
													margin="none"
													onChange={(e)=> {
														this.handleChange(e);
														this.handleChangeTipoServicio(e.target.value);
													}}
													disabled={this.is_view()}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}

													name="id_compra_servicio"
													label="Tipo servicio"
													value={this.state.id_compra_servicio}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.lista_servicio.map((option, index) => (
														<option key={option.id_compra_servicio} value={option.id_compra_servicio}>
															{option.nombre_comercial[0].toUpperCase() + option.nombre_comercial.slice(1).toLowerCase() +' - '+ option.plaza[0].toUpperCase() + option.plaza.slice(1).toLowerCase() + ' - ' + option.servicio_gasto[0].toUpperCase() + option.servicio_gasto.slice(1).toLowerCase()}
														</option>
													))}
												</TextField>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<TextField
											select
											fullWidth
											margin="none"
											required
											onChange={(e) => {
												this.handleChange(e);
											}}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											name="id_estatus_pago"
											label="Estatus Pago"
											value={this.state.id_estatus_pago}
										>
											<option key={0} value={''}>&nbsp;</option>
											<option key={1} value={1}>Liquidado</option>
											<option key={2} value={2}>Por Pagar</option>

										</TextField>
									</Grid>
								</Fragment>
							) : (
								<Fragment>
									<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
											{!this.is_view() ? (
												<Grid item style={{width: '50px'}}>
													{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
														<Fragment>
															<ModalPlaza
																componente={(
																	<Tooltip
																		TransitionComponent={Zoom}
																		placement={"right"}
																		title="Agregar nuevo Plaza"
																		children={(
																			<Fab
																				color="default"
																				size="small"
																				aria-label="Agregar nueva plaza"
																				children={(
																					<PlaylistAddOutlined/>
																				)}
																			/>
																		)}
																	/>
																)}
																id_plaza_direccion={null}
																tipo={'add'}
																item={{}}
																RefreshList={this.RefreshListPlaza}
																showSnackBars={this.props.showSnackBars}
															/>
														</Fragment>
													) : ''}
												</Grid>
											) : null}
											<Grid item style={{width: 'calc(100% - 50px)'}}>
												<TextField
													select
													fullWidth
													margin="none"
													onChange={(e) => {
														this.handleChangePlaza(e);
														this.ListaPlazaDireccion(e, false);
													}}
													disabled={this.is_view()}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}

													name="id_plaza"
													label="Plaza"
													value={this.state.id_plaza}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.lista_plaza.map(option => (
														<option key={option.id_plaza} value={option.id_plaza}>
															{option.plaza} - {option.abreviatura}
														</option>
													))}
												</TextField>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
											{!this.is_view() ? (
												<Grid item style={{width: '50px'}}>
													{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
														<Fragment>
															<ModalOficina
																componente={(
																	<Tooltip
																		TransitionComponent={Zoom}
																		placement={"right"}
																		title="Agregar oficina"
																		children={(
																			<Fab
																				color="default"
																				size="small"
																				aria-label="Agregar nueva oficina"
																				children={(
																					<PlaylistAddOutlined/>
																				)}
																			/>
																		)}
																	/>
																)}
																id_plaza_direccion={null}
																tipo={'add'}
																item={{
																	id_plaza: this.state.id_plaza,
																	plaza: this.plazaAbreviatura(this.state.id_plaza)
																}}
																RefreshList={this.RefreshListPlazaDireccion}
																showSnackBars={this.props.showSnackBars}
															/>
														</Fragment>
													) : ''}
												</Grid>
											) : null}
											<Grid item style={{width: 'calc(100% - 50px)'}}>
												<TextField
													select
													fullWidth
													onChange={(e) => {
														this.handleChange(e);
														this.ListaEmpresa(e);
													}}
													margin="none"
													disabled={this.is_view()}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}

													name="id_plaza_direccion"
													label="Oficina"
													value={this.state.id_plaza_direccion}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.lista_plaza_direccion.map((option, index) => (
														<option key={option.id_plaza_direccion} value={option.id_plaza_direccion}>
															{/*{CadenaDomicilio(option)}*/}
															{option.nombre_plaza}
														</option>
													))}
												</TextField>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
										<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
											{!this.is_view() ? (
												<Fragment>
													<Grid item style={{width: '50px'}}>
														{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
															<Fragment>
																<ModalEmpresa
																	componente={(
																		<Tooltip
																			TransitionComponent={Zoom}
																			placement={"right"}
																			title="Agregar empresa"
																			children={(
																				<Fab
																					color="default"
																					size="small"
																					aria-label="Agregar nueva empresa"
																					children={(
																						<PlaylistAddOutlined/>
																					)}
																				/>
																			)}
																		/>
																	)}
																	id_plaza_direccion={null}
																	tipo={'add'}
																	item={{
																		id_plaza: this.state.id_plaza,
																		id_plaza_direccion: this.state.id_plaza_direccion,
																		plaza_direccion: this.getPlazaOficina(this.state)
																	}}
																	RefreshList={this.RefreshListEmpresa}
																	showSnackBars={this.props.showSnackBars}
																/>
															</Fragment>
														) : ''}
													</Grid>
													
													{FieldsJs.Field(this.state.id_empresa) ? (
														<Grid item style={{width: '50px'}}>
															{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
																<Fragment>
																	<ModalEmpresa
																		componente={(
																			<Tooltip
																				TransitionComponent={Zoom}
																				placement={"right"}
																				title="Editar empresa"
																				children={(
																					<Fab
																						color="default"
																						size="small"
																						aria-label="Editar empresa"
																						children={(
																							<EditOutlined/>
																						)}
																					/>
																				)}
																			/>
																		)}
																		id_plaza_direccion={null}
																		tipo={'edit'}
																		item={{
																			id_plaza: this.state.id_plaza,
																			id_plaza_direccion: this.state.id_plaza_direccion,
																			plaza_direccion: this.getPlazaOficina(this.state),
																			id_empresa_plaza_oficina: this.getEmpresaPlazaOficina(this.state),
																			id_empresa: this.state.id_empresa
																		}}
																		RefreshList={this.RefreshListEmpresa}
																		showSnackBars={this.props.showSnackBars}
																	/>
																</Fragment>
															) : ''}
														</Grid>
													) : null}
													
													
												</Fragment>
											) : null}
											<Grid item style={{width: FieldsJs.Field(this.state.id_empresa) ? 'calc(100% - 100px)':'calc(100% - 50px)'}}>
												<TextField
													select
													fullWidth
													margin="none"
													onChange={this.handleChange}
													disabled={this.is_view()}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}

													name="id_empresa"
													label="Empresa"
													value={this.state.id_empresa}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.lista_empresa.map((option, index) => (
														<option key={option.id_empresa} value={option.id_empresa}>
															{option.nombre_comercial}
														</option>
													))}
												</TextField>
											</Grid>
										</Grid>
									</Grid>
									
									{FieldsJs.Field(this.state.fecha_compra) && FieldsJs.Field(this.state.total) ? (
										<Fragment>
											<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
												Fecha Compra: {this.state.fecha_compra}
											</Grid>
											<Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
												Total: ${FieldsJs.Currency(this.state.total)}
											</Grid>
										</Fragment>
									):null }
									
								</Fragment>
							)}


							{Number(this.state.id_estatus_pago) === 2 ? (
								<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
									<KeyboardDatePicker
										format={'dd/MM/yyyy'}
										fullWidth
										clearable
										inputProps={{readOnly: true}}
										KeyboardButtonProps={{
											'aria-label': 'Fecha límite de pago',
										}}
										label={"Fecha límite de pago"}
										value={this.state.fecha_pago}
										onChange={(date) => {
											this.handleChange(null, null, null, date, 'fecha_pago');
										}}
										// minDate={moment(new Date())}
										clearLabel={'Limpiar'}
										okLabel={'Aceptar'}
										cancelLabel={'Cancelar'}
										onError={console.log}
										disabled={this.is_view()}
									/>
								</Grid>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									fullWidth
									multiline
									rows={2}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="observaciones"
									label="Observaciones"
									value={this.state.observaciones}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>

								<DropzoneArea
									onChange={this.handleChangeDropzoneArea.bind(this)}
									open={true}
									filesLimit={2}
									dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
									acceptedFiles={['text/xml','application/pdf']}
									showPreviews={false}
									maxFileSize={5000000}
									showAlerts={false}
									showFileNames={true}
									showFileNamesInPreview={true}
								/>
							</Grid>
						</Grid>
					</DialogContent>

					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() ? (
									<Button onClick={() => this.save()} color="primary">
										{this.is_edit() > 0 ? 'Actualizar' : 'Agregar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>

				</Dialog>

			</div>
		);
	}
}

ModalCompras.propTypes = {
	id_compra: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCompras;
