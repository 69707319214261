import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FieldsJs} from "../../../../settings/General/General";
import TextField from "@material-ui/core/TextField";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Divider from "@material-ui/core/Divider";
import {RepositorioJuridicoService} from "../../../../services/RepositorioJuridicoService/RepositorioJuridicoService";
import {EventNote, Send} from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Table from '@material-ui/core/Table/index';
import BarraProgreso from "../../Tickets/Includes/BarraProgreso";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import FiltrosRepositorioBitacora from "./FiltrosRepositorioBitacora";
import {BotonActualizarLista, BotonExportarListaExcel, CabeceraTituloPdfExcelLista} from "../../../Include/MiniComponents/GlobalComponent";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";

class ModalBitacora extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			
			tipo: props.tipo,
			item: props.item,
			
			reporte: [],
			
			filtro: {},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
		};
	}
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	reporte = () => {
		return new Promise((resolve, reject) => {
			RepositorioJuridicoService.Bitacora(this.state.filtro, this.state.paginacion).then((response) => {
				this.setState({
					reporte: response.data,
					paginacion: {
						total: response.paginacion.total,
						page: response.paginacion.page,
						limit: response.paginacion.limit,
						rangos: this.DefaultRangos()
					}
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					reporte: []
				});
				reject(error);
			});
		});
	};
	
	openModal = () => {
		this.reporte().then((response) => {
			this.setState({
				open: true,
				scroll: 'paper',
			});
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.reporte());
	};
	
	closeModal = () => {
		this.setState({open: false});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	close = () => {
		this.closeModal();
	};
	
	exportar = (item, archivo, auth) => {
		let filtro = {
			
			fecha : this.state.filtro.fecha || "",
			id_repositorio_juridico : this.state.filtro.id_repositorio_juridico || "",
			usuario : this.state.filtro.usuario || "",
			actividad : this.state.filtro.actividad || "",
			
		};
		console.log('EXPORTAR::: ', item);
		HttpRequest.export('repositorio_juridico_bitacora', ['repositorio_juridico_bitacora'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, false, false, false, archivo === "EXCEL")
	};
	
	
	render() {
		
		return (
			<span>
				
				{this.props.component ? (
					<Fragment>
						<span onClick={() => this.openModal('paper')} style={{cursor: 'pointer'}}>
							{this.props.component}
						</span>
					</Fragment>
				) : (
					<Fragment>
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={() => this.openModal('paper')}>
							<Send className={'margin-5-R px-14'}/>
							Bitácora
						</Fab>
					</Fragment>
				)}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.closeModal}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal} className={'sendMessage-ticket'}
				>
					
					<DialogTitle style={{color: 'gray'}}>
						<CabeceraTituloPdfExcelLista
							titulo={'Bitácora'}
							botonEXCEL={
								<Fragment>
									<div className={'v-center'}>
										<Fragment>
											<BotonExportarListaExcel onClick={() => this.exportar({}, 'EXCEL', true)}/>
										</Fragment>
									</div>
								</Fragment>
							}
						/>
						</DialogTitle>
					
					<DialogContent>
						<Grid container spacing={0}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'margin-15-B'}>
									<FiltrosRepositorioBitacora
										HandleFiltro={this.HandleFiltro}
										AplicarFiltros={this.AplicarFiltros}
										showSnackBars={this.props.showSnackBars}
									/>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={2} className={'h-100'}>
									
									{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'text-center px-13'} style={{fontWeight: 'bold'}}>
											Seleccionar el tipo de notificación y el usuario a quien va dirigido el ticket
										</Typography>
									</Grid>*/}
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<div className={'borde-punteado-lightgray-1 card-0 text-center margin-10-R margin-20-B cursor-pointer'}>
											<div className={'w-100 text-center'}>
												<Table className={'desing-acresco'}>
													<TableHead>
														<TableRow>
															<TableCell component="th" align={'center'} width={200}>Fecha</TableCell>
															<TableCell component="th" align={'center'} width={100}>Id</TableCell>
															<TableCell component="th" align={'center'}>Usuario</TableCell>
															<TableCell component="th" align={'center'}>Actividad</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{this.state.reporte.map((item, key) => {
															return (
																<Fragment key={key}>
																	<TableRow>
																		<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
																			<div className={'w-100'} align={'center'}>
																				{DateFormat.FechaHoraFormatText(item.created_at)}
																			</div>
																		</TableCell>
																		<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
																			<div className={'w-100'} align={'center'}>
																				{item.id_repositorio_juridico}
																			</div>
																		</TableCell>
																		<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
																			<div className={'w-100'} align={'center'}>
																				{item.nombre_completo}
																			</div>
																		</TableCell>
																		<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
																			<div className={'w-100'} align={'center'}>
																				{item.actividad}
																			</div>
																		</TableCell>
																	</TableRow>
																</Fragment>
															)
														})}
													</TableBody>
												</Table>
											</div>
										</div>
									</Grid>
									
								</Grid>
							</Grid>
						</Grid>

					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={0}>
							<Grid item xs={12} align={'right'}>
								<Button onClick={() => this.close()} color="primary">
									{"Cerrar"}
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			
			</span>
		);
	}
}

ModalBitacora.propTypes = {
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	component: PropTypes.element,
};

export default ModalBitacora;
