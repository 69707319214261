import React, {Component, Fragment} from 'react';
import ListaTabla from './Includes/ListaTabla';
import ModalTickets from './Includes/ModalTickets';

import {TicketsService} from '../../../services/TicketsService/TicketsService';
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import {IntegrationNotistack, ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import FiltrosTickets from "./Includes/FiltrosTickets";
import Paginacion from "../../Include/Paginacion/Paginacion";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import "./Tickets.css";
import Typography from "@material-ui/core/Typography";
import Sync from "@material-ui/icons/Sync";
import PusherWatchChannelEvent, {PusherSubscribe, PusherUnSubscribe} from "../../../settings/Pusher/Pusher";
import {$cSuccess, FieldsJs, $cInfo, $cError, hideSpinner, showSpinner} from '../../../settings/General/General';
import TableCell from "@material-ui/core/TableCell";
import {EventNote, ListAlt, Send} from "@material-ui/icons";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import ModalSendMail from "./Includes/ModalSendMail";
import IconButton from "@material-ui/core/IconButton";
import ModalReport from "./Includes/ModalReport";

class Tickets extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		let ids_expandido = ReactLocalStorageService.get('ids_expandido');
		
		this.state = {
			fecha_inicio: null,
			fecha_fin: null,
			
			listar_ticket: [],
			
			filtro: {
				id_cat_area_solucion: this.Usr.id_cat_tipo_usuario
			},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			ids_expandido: ids_expandido || []
		};
		
		this.RefreshList();
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	Listar = () => {
		TicketsService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			console.log("PAGINACION: ", response.paginacion);
			let listar_ticket = response.data;
			
			this.setState({
				listar_ticket: listar_ticket,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			
		}).catch((error) => {
			
			this.setState({listar_facturacion: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	ShowSubTabla = (item, key) => {
		let expandir = !!!item.expandir;
		this.handleChange({
			target: {
				value: expandir,
				name: 'expandir__' + key,
				type: 'text'
			}
		}, 'listar_ticket', 'expandir');
		let ids_temp = this.state.ids_expandido;
		let ids_expandido = [];
		if (expandir) {
			ids_expandido = ids_temp;
			ids_expandido.push({
				id_facturacion: item.id_facturacion
			});
		} else {
			for (let i = 0; i < ids_temp.length; i++) {
				if (Number(ids_temp[i].id_facturacion) !== Number(item.id_facturacion)) {
					ids_expandido.push({
						id_facturacion: ids_temp[i].id_facturacion
					})
				}
			}
			
		}
		ReactLocalStorageService.set('ids_expandido', ids_expandido);
		this.setState({
			ids_expandido: ids_expandido
		});
	};
	
	SubTabla = (item, key) => {
		this.ShowSubTabla(item, key);
	};
	
	
	componentDidMount() {
		$cSuccess("componentDidMount");
		PusherSubscribe('my-channel-tickets', info => {
			$cSuccess(info);
			this.InitPusher();
		});
	}
	
	componentWillUnmount() {
		$cInfo("componentWillUnmount");
		PusherUnSubscribe('my-channel-tickets', info => {
			$cInfo(info);
		});
	}
	
	InitPusher = () => {
		PusherWatchChannelEvent('my-event-tickets', (data) => {
			$cError(JSON.stringify(data));
			FieldsJs.TimePromise(1000, () => this.Listar(false), () => console.warn('Nuevo Ticket.'))
		});
	};
	
	exportar = (item, archivo, auth, email) => {
		try {
			
			let fecha_inicio = this.state.fecha_inicio ? DateFormat.FormatSql(this.state.fecha_inicio) : null;
			let fecha_fin = this.state.fecha_fin ? DateFormat.FormatSql(this.state.fecha_fin) : null;
			
			let filtro = {
				fecha_alta_inicio: this.state.fecha_inicio,
				fecha_alta_fin: this.state.fecha_fin,
				id_cat_prioridad: this.state.id_cat_prioridad,
				id_cat_ticketStatus: this.state.id_cat_ticketStatus,
				id_usuario_solicitante: this.state.id_usuario_solicitante,
				id_cat_tipo_usuario: this.state.id_cat_tipo_usuario,
			};
			
			let params = {
				auth: auth,
				archivo: archivo,
				filtro: filtro,
			};
			
			HttpRequest.export('_Tickets_Datos_Excel', ['Tickets'], params, false, false, false, archivo === "EXCEL");
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				<div className={'row-flex margin-30-T margin-30-B'}>
					<div className={'w-100-400-px'}>
						<div className={'vertical-inline text-left w-100'}>
							<Typography variant={'h5'} className={'v-center text-left'}>
								Tickets
							</Typography>
							
							
							<div className={'margin-15-R margin-15-L v-center'}>
								<Tooltip TransitionComponent={Zoom} placement={"bottom"}
								         title={'Nuevo Ticket'}>
									<div className={'w-20-px h-20-px b-r-100 cursor-pointer tooltip_bar1'} style={{}}/>
								</Tooltip>
							</div>
							<div className={'margin-15-R v-center'}>
								<Tooltip TransitionComponent={Zoom} placement={"bottom"} className={'v-center'}
								         title={'Ticket en Proceso'}>
									<div className={'w-20-px h-20-px b-r-100 cursor-pointer tooltip_bar2'} style={{}}/>
								</Tooltip>
							</div>
							<div className={'margin-15-R v-center'}>
								<Tooltip TransitionComponent={Zoom} placement={"bottom"} className={'v-center'}
								         title={'Ticket Resuelto'}>
									<div className={'w-20-px h-20-px b-r-100 cursor-pointer tooltip_bar3'} style={{}}/>
								</Tooltip>
							</div>
							<div className={'margin-15-R v-center'}>
								<Tooltip TransitionComponent={Zoom} placement={"bottom"} className={'v-center'}
								         title={'Ticket Cancelado'}>
									<div className={'w-20-px h-20-px b-r-100 cursor-pointer tooltip_bar4'} style={{}}/>
								</Tooltip>
							</div>
						</div>
					</div>
					<div className={'w-400-px text-right'}>
						<div className={'vertical-inline h-100'}>
							
							{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (
								<div className={'v-center'}>
									<ModalReport
										item={this.state}
										component={(
											<Fab variant="extended" size="small" color="primary" aria-label="Excel" className={'margin-10-L bg-gray'}>
												<ListAlt className={'margin-5-R px-14'}/>Ver progreso
											</Fab>
										)}
										showSnackBars={this.showSnackBars}
									/>
								</div>
							) : null}
							
							
							{/*<Fab variant="extended" size="small" color="primary" aria-label="PDF"
						     className={'margin-10-L bg-danger'} onClick={() => this.exportar({}, 'PDF', true)}>
							<PictureAsPdf className={'margin-5-R px-14'}/>
							PDF
						</Fab>
						*/}
							
							<div className={'v-center'}>
								<Fab variant="extended" size="small" color="primary" aria-label="Excel"
								     className={'margin-10-L bg-green'} onClick={() => this.exportar({}, 'EXCEL', true)}>
									<EventNote className={'margin-5-R px-14'}/>
									Excel
								</Fab>
							</div>
							
							<div className={'v-center'}>
								<Fab variant="extended" size="small" color="primary" aria-label="Add"
								     className={'margin-10-L'} onClick={this.RefreshList}>
									<Sync className={'margin-5-R px-14'}/>
									Actualizar
								</Fab>
							</div>
						</div>
					</div>
				</div>
				
				
				<FiltrosTickets
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				
				<div className={'form margin-30-B'} style={{marginTop: '20px'}}>
					
					{this.state.listar_ticket.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_ticket}
								delete={this.delete}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
								SubTabla={this.SubTabla}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				<ModalTickets
					id_ticket={null}
					tipo={'new'}
					item={
						{}
					}
					icono={(
						<Zoom className={'btn-fixed-bottom-right cursor-pointer'}
						      key={'inherit'}
						      timeout={1500}
						      in={true}
						      style={{transitionDelay: `${100}ms`}}
						      unmountOnExit
						>
							<Fab color={'primary'}><Add/></Fab>
						</Zoom>
					)
					}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>
			
			</div>
		);
	}
}

Tickets.propTypes = {};

export default IntegrationNotistack(Tickets);
