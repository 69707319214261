import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Sync from "@material-ui/icons/Sync";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {FieldsJs} from "../../../../settings/General/General";
import TextField from "@material-ui/core/TextField";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Divider from "@material-ui/core/Divider";
import {TicketsService} from "../../../../services/TicketsService/TicketsService";

class ModalConfirmDate extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			
			tipo: props.tipo,
			item: props.item,
			id_usuario: this.Usr.id_usuario,
			id_ticket_requerimiento: props.item.id_ticket_requerimiento,
			fecha_confirm: null,
			motivo: ''
		};
	}
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	closeModal = () => {
		this.setState({open: false});
	};
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	close = () => {
		this.closeModal();
	};
	
	save = () => {
		TicketsService.NewConfirmarDate(this.state).then((response) => {
			this.close();
			this.props.RefreshInit();
			this.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	render() {
		
		return (
			<span>
				
				{this.props.component ? (
					<Fragment>
						<span onClick={() => this.openModal('paper')} style={{cursor: 'pointer'}}>
							{this.props.component}
						</span>
					</Fragment>
				) : (
					<Fragment>
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={() => this.openModal('paper')}>
							<Sync className={'margin-5-R px-14'}/>
							Otra fecha
						</Fab>
					</Fragment>
				)}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.closeModal}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					{/*<DialogTitle style={{color: 'gray'}}>Fecha solicitud: <strong style={{color: 'black'}}></strong></DialogTitle>*/}
					
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'min-h-100'}>
								<Grid container spacing={4} className={'h-100'}>
									
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{paddingBottom: '5px'}}>
										<Typography className={'padding-10-T text-center px-13'} style={{fontWeight: 'bold'}}>
											Asignar otra fecha para la solución del requerimiento:
										</Typography>
									</Grid>
									
									<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
										<div className={'vertical-inline h-100 b-r-6'} style={{backgroundColor: '#efefef'}}>
											<div className={'v-center'}>
												<div align={'center'}>
												<Typography className={'padding-10-T padding-10-B text-center px-12'} style={{fontWeight: 'bold'}}>
													Fecha solicitud:
												</Typography>
												<Typography className={'text-center px-15'} style={{fontWeight: 'lighter'}}>
													{this.state.item.fecha_solicitud}
												</Typography>
												</div>
											</div>
										</div>
									</Grid>
									<Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
										<Grid container spacing={0}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<KeyboardDateTimePicker
													format="yyyy/MM/dd hh:mm a"
													fullWidth
													clearable
													required
													inputProps={{readOnly: true}}
													KeyboardButtonProps={{
														'aria-label': 'Nueva Fecha',
													}}
													label="Nueva Fecha"
													value={this.state.fecha_confirm}
													onChange={(date) => {
														this.handleChange(null, null, null, date, 'fecha_confirm');
													}}
													// minDate={moment(new Date())}
													clearLabel={'Limpiar'}
													okLabel={'Aceptar'}
													cancelLabel={'Cancelar'}
													onError={console.log}
													disabled={this.is_view()}
												/>
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: '10px'}}>
												<TextField
													multiline
													required
													fullWidth
													variant={"outlined"}
													name={"motivo"}
													onChange={this.handleChange}
													type="text"
													margin="none"
													
													helperText=""
													label="Motivo"
													value={this.state.motivo}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					<br/>
					
					<Divider/>

					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									{!this.is_view() ? "Cancelar" : "Cerrar"}
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								<Button onClick={() => this.save()} color="primary">
									{this.is_edit() > 0 ? 'Actualizar' : 'Guardar'}
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
					<br/>
				</Dialog>
			
			</span>
		);
	}
}

ModalConfirmDate.propTypes = {
	tipo: PropTypes.oneOf([
		"add",
		"view",
		"edit",
	]),
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.object.isRequired,
	component: PropTypes.element,
	RefreshInit: PropTypes.func.isRequired,
};

export default ModalConfirmDate;
