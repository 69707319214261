import {HttpRequest, HttpRequestDropbox} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const RepositorioContableService = {
	Listar: (filtro, paginacion) => {
		
		//let fecha_alta = (filtro.fecha_alta || null) ? DateFormat.FormatSql(filtro.fecha_alta) : null;
		
		let params = {
			filtro: {
				autorizado: filtro.autorizado|| null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Contable_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarAnterior: (ruta) => {
		let params = {ruta:ruta || '/Repositorio Contable'};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Contable_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarHistorial: (ruta) => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Contable_Descargas', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cat_tipo_informacion: form.id_cat_tipo_informacion,
			id_cuenta_repositorio_contable: form.id_cuenta_repositorio_contable,
			archivos: form.archivos
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Contable_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AgregarFolder: (form) => {
		let params = {
			path: form.nombre_carpeta ? form.ruta +"/"+ form.nombre_carpeta : ""
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Contable_Guardar_Folder', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	DescargarZip: (ruta) => {
		let params = {ruta:ruta || null};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Contable_Descargar_Zip', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_sexo: form.id_cat_sexo,
			sexo: form.sexo,
			abreviatura: form.abreviatura,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Repositorio_Contable_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_sexo) => {
		let params = {
			id_cat_sexo: id_cat_sexo
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Repositorio_Contable_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	NuevaCuenta: (form) => {
		let params = {
			id_cat_banco: form.id_cat_banco,
			dia_corte: form.dia_corte,
			numero_cuenta: form.numero_cuenta,
			id_cat_tipo_cuenta_tarjeta: form.id_cat_tipo_cuenta_tarjeta,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Contable_Nueva_Cuenta', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarCuentas: (form) => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Contable_Listar_Cuenta', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AutorizarArchivo: (form) => {
		let params = {
			id_repositorio_contable: form.id_repositorio_contable
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Contable_Autorizar_Archivo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
