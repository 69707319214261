import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Typography from "@material-ui/core/Typography/index";
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import EventNote from '@material-ui/icons/EventNote';
import Sync from '@material-ui/icons/Sync';
import ListaTabla from './Includes/ListaTabla';
import ModalCompras from './Includes/ModalCompras';

import {ComprasService} from '../../../services/ComprasService/ComprasService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Zoom from '@material-ui/core/Zoom/index';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab/index";
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import carrito_de_compra_1 from "../../../assets/img/icons/carro-de-la-compra-1.svg";
import Paginacion from "../../Include/Paginacion/Paginacion";
import FiltrosCompras from "./Includes/FiltrosCompras";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import {CONFIG} from "../../../settings/Config/Config";


class Compras extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			
			filtro: {},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			listar_cat_compra: []
		};
		
		this.RefreshList();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	Listar = () => {
		ComprasService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			
			let listar_cat_compra = response.data;
			
			this.setState({
				listar_cat_compra: listar_cat_compra,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			
		}).catch((error) => {
			
			this.setState({listar_cat_compra: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	delete = (item) => {
		
		let msg = `¿Deseas eliminar el compra?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				ComprasService.Eliminar(item.id_compra).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	exportar = (item, archivo, auth) => {
		if (item.id_compra > 0) {
			let tipo = 2;
			HttpRequest.export('cxid', ['compras'], {
				id_compra: item.id_compra,
				tipo: tipo,
			}, !(tipo === 3))
		} else {
			let fecha_alta_inicio = (this.state.filtro.fecha_alta_inicio || null) ? DateFormat.FormatSql(this.state.filtro.fecha_alta_inicio) : null;
			let fecha_alta_fin = (this.state.filtro.fecha_alta_fin || null) ? DateFormat.FormatSql(this.state.filtro.fecha_alta_fin) : null;
			let fecha_compra_inicio = (this.state.filtro.fecha_compra_inicio || null) ? DateFormat.FormatSql(this.state.filtro.fecha_compra_inicio) : null;
			let fecha_compra_fin = (this.state.filtro.fecha_compra_fin || null) ? DateFormat.FormatSql(this.state.filtro.fecha_compra_fin) : null;
			
			let filtro = {
				numero_compra: this.state.filtro.numero_compra || null,
				usuario: this.state.filtro.usuario || null,
				id_plaza: this.state.filtro.id_plaza || null,
				empresa: this.state.filtro.empresa || null,
				oficina: this.state.filtro.oficina || null,
				cliente_interna_plaza_direccion: this.state.filtro.cliente_interna_plaza_direccion || null,
				fecha_alta_inicio: fecha_alta_inicio,
				fecha_alta_fin: fecha_alta_fin,
				fecha_compra_inicio: fecha_compra_inicio,
				fecha_compra_fin: fecha_compra_fin,
				ordenar_fecha_alta: this.state.filtro.ordenar_fecha_alta || null,
				ordenar_fecha_compra: this.state.filtro.ordenar_fecha_compra || null,
				servicio_compra: this.state.filtro.servicio_compra || null
			};
			HttpRequest.export('c', ['compras'], {
				auth: auth,
				archivo: archivo,
				filtro: filtro
			}, false, false, false, archivo === "EXCEL")
		}
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<div className={'row-flex margin-30-T margin-30-B'}>
					<div className={'w-100-300-px'}>
						<Typography variant={'h5'} className={'text-left'}>
							Gastos Operativos
						</Typography>
					</div>
					<div className={'w-300-px text-right'}>
						{/*<Fab variant="extended" size="small" color="primary" aria-label="PDF"
						     className={'margin-10-L bg-danger'} onClick={() => this.exportar({}, 'PDF', true)}>
							<PictureAsPdf className={'margin-5-R px-14'}/>
							PDF
						</Fab>
						<Fab variant="extended" size="small" color="primary" aria-label="Excel"
						     className={'margin-10-L bg-green'} onClick={() => this.exportar({}, 'EXCEL', false)}>
							<EventNote className={'margin-5-R px-14'}/>
							Excel
						</Fab>*/}
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={this.RefreshList}>
							<Sync className={'margin-5-R px-14'}/>
							Actualizar
						</Fab>
					</div>
				</div>
				
				<FiltrosCompras
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				
				<div className={'form margin-30-B'}>
					
					{this.state.listar_cat_compra.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_cat_compra}
								delete={this.delete}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={null}
							mensaje={'No se encontraron datos.'}
							image={carrito_de_compra_1}
							// padding={'20px'}
							// paddingText={'20px'}
							// height={'80px'}
							// width={'80px'}
						/>
					)}
				
				</div>
				
				<ModalCompras
					id_compra={null}
					item={{}}
					tipo={'add'}
					icono={(
						<Zoom className={'btn-fixed-bottom-right cursor-pointer'}
						      key={'inherit'}
						      timeout={1500}
						      in={true}
						      style={{transitionDelay: `${100}ms`}}
						      unmountOnExit
						>
							<Fab color={'primary'}><Add/></Fab>
						</Zoom>
					)}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>
			
			</div>
		);
	}
}

Compras.propTypes = {
};

export default IntegrationNotistack(Compras);
