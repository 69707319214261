import React, {Component, Fragment} from 'react';

import './VacacionesInfo.css';
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {ReactLocalStorageService} from "../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Grid from "@material-ui/core/Grid";
import {FieldsJs} from "../../../settings/General/General";
import Icon from '@material-ui/core/Icon';
import {HomeService} from "../../../services/HomeService/HomeService";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import {VacacionesService} from "../../../services/VacacionesService/VacacionesService";
import header_vacaciones from "../../../assets/img/icons/header_vacaciones.jpeg";
import footer_vacaciones from "../../../assets/img/icons/footer_vacaciones.jpeg";
import Link from '@material-ui/core/Link';

class VacacionesInfo extends Component {
	
	state = {};
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			Usr: (ReactLocalStorageService.get('Usr') || {}),
			
			venta_creado_disenador_asignado_texto: '',
			venta_creado_disenador_asignado: [
				['Element', 'Density', {role: 'style'}, {role: 'annotation'}],
				['Copper', 8.94, '#b87333', 'Cu'],
				['Silver', 10.49, 'silver', 'Ag'],
				['Gold', 19.30, 'gold', 'Au'],
				['Platinum', 21.45, 'color: #e5e4e2', 'Pt']
			],
			venta_en_produccion_texto: '', venta_en_produccion: [],
			venta_en_almacen_texto: '', venta_en_almacen: [],
			
			listar_home: [],
			lista_tickets: [],
			registros: [],
			cfdi: [],
			acceso: [],
			actividades: [],
			plataformas: [],
			vacaciones: [],
		};
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	renderSwitchIcon = (icono) => {
		return <Icon className={'px-30 margin-10-R'} style={{color: '#d9e9eb'}}>{icono}</Icon>;
	};
	
	go = (u) => {
		this.props.history.push('/' + u);
	};
	
	redireccion = (url) => {
		if (FieldsJs.Field(url)) {
			window.open(url, '_blank');
		}
	};
	
	getRegistros = (id_menu) => {
		let registros = 0;
		if (this.state.registros.length > 0) {
			for (let i = 0; i < this.state.registros.length; i++) {
				if (Number(id_menu) === Number(this.state.registros[i].id_menu)) {
					registros = this.state.registros[i].total
				}
			}
		}
		return registros;
	};
	
	
	render() {
		
		return (
			<div align={'center'}>
				<div>
					<div className='Home ContaineViewComponet'>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
							</Grid>
							<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
								<img src={header_vacaciones} width={'100%'}  alt={"Vacaciones"} />
							</Grid>
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
							</Grid>
							<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
								<h1 className='text-justify margin-0-T' style={{color: '#8E1E32'}}>Consideraciones</h1>
								<ul className='text-justify'>
									<li>Debe tener más de un año de antigüedad para iniciar el proceso de solicitud. </li>
									<li>Debe tener días disponibles (en caso contrario la solicitud será rechazada).</li>
									<li>Debe generar la solicitud con 30 días naturales de anticipación a la fecha de inicio de vacaciones.</li>
									<li>El número de días del rango fecha inicial y final debe ser igual o mayor a 6 días.</li>
									<li>En el mes de diciembre no se autorizan vacaciones.</li>
									<li>Se sugiere planear el periodo vacacional, con el propósito de no afectar la operación de la empresa.</li>
								</ul>
								
								<h1 className='text-justify margin-0-B' style={{color: '#8E1E32'}}>Proceso</h1>
							</Grid>
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
							</Grid>
							<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
								<img src={footer_vacaciones} width={'100%'}  alt={"Vacaciones"} />
							</Grid>
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Link href="/vacaciones" className={'px-24'} style={{fontWeight: 500, textDecoration: 'underline'}}>
									Solicitar ahora
								</Link>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		);
	}
}

VacacionesInfo.propTypes = {};

export default IntegrationNotistack(VacacionesInfo);
