import React, {Component, Fragment} from 'react';

import './Notificaciones.css';
import logo_svg from '../../../assets/img/logo.svg';

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {ReactLocalStorageService} from "../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {$cError, $cInfo, $cSuccess, EnteroSolo, FieldsJs} from "../../../settings/General/General";
import Icon from '@material-ui/core/Icon';
import Chart from "react-google-charts";
import Typography from "@material-ui/core/Typography";
import {NotificacionesService} from "../../../services/NotificacionesService/NotificacionesService";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import {TabPanel} from "@material-ui/lab";
import AppBar from "@material-ui/core/AppBar";
import TabContext from "@material-ui/lab/TabContext";
import giro_de_clientes from "../../../assets/img/logo_integraciones.svg";
import documentos_compartidos from "../../../assets/img/icons/documentos-compartidos.svg";
import DivSvg from "../../Include/MiniComponents/DivSvg";
import {Cancel, CloudDownload} from "@material-ui/icons";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {DropboxApi} from "../../../settings/Dropbox/DropboxApi";
import VistaVacia from "../../Include/VistaVacia/VistaVacia";
import PusherWatchChannelEvent, {PusherSubscribe, PusherUnSubscribe} from "../../../settings/Pusher/Pusher";
import Paginacion from "../../Include/Paginacion/Paginacion";

// import Button from "@material-ui/core/Button";


const ItemGraficaArea = ({state}) => {
	let grafica = [
		['Progreso de ventas', 'Porcentaje']
	];
	grafica.push([state.venta_creado_disenador_asignado_texto, state.venta_creado_disenador_asignado.length]);
	
	return (
		<Fragment>
			<div align={'center'} style={{width: '100%'}} className={'margin-5 fill-transparent'}>
				
				<Typography component={'h2'} className={'ff-arial'} style={{}}>
					Gráfica porcentaje progreso de ventas
				</Typography>
				
				{grafica.length > 0 ? (
					<Chart
						width={'100%'}
						height={'190px'}
						chartType="Sankey   "
						loader={
							<Typography component={'h2'} className={'ff-arial'}>
								Cargando gráficas...
							</Typography>
						}
						data={grafica}
						options={{
							is3D: true,
							backgroundColor: {fill: 'transparent'},
							title: '',
							legend: {
								alignment: 'center',
								position: 'bottom',
								textStyle: {
									color: 'black',
									fontSize: 10,
								},
							},
							pieHole: 0,
							animation: {
								duration: 1000,
								easing: 'out',
								startup: true,
							},
						}}
						rootProps={{'data-testid': '3'}}
					/>
				) : (
					<Fragment>
						<Typography component={'h2'} className={'ff-arial'}>
							Esperando datos...
						</Typography>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};


const ItemGrafica = ({state}) => {
	let grafica = [
		['Progreso de ventas', 'Porcentaje']
	];
	grafica.push([state.venta_creado_disenador_asignado_texto, state.venta_creado_disenador_asignado.length]);
	const columns = [
		{type: "string", id: "President"},
		{type: "number", id: "Start"},
		{type: "number", id: "End"}
	];
	
	const rows = [
		["VANTIS S DE RL DE CV", 13, 16],
		["VANGUARDIA EN INYECCION DE PLASTICOS DEL BAJIO S DE RL DE CV", 3, 10]
	];
	
	return (
		<Fragment>
			<div align={'center'} style={{width: '100%'}} className={'margin-5 fill-transparent'}>
				
				<Typography component={'h2'} className={'ff-arial'} style={{}}>
					Gráfica porcentaje progreso de ventas
				</Typography>
				
				{grafica.length > 0 ? (
					<Chart
						width={'100%'}
						height={'190px'}
						chartType="Timeline"
						loader={
							<Typography component={'h2'} className={'ff-arial'}>
								Cargando gráficas...
							</Typography>
						}
						data={[columns, ...rows]}
						options={{
							timeline: {colorByRowLabel: true},
							backgroundColor: '#ffd',
						}}
						rootProps={{'data-testid': '3'}}
					/>
				) : (
					<Fragment>
						<Typography component={'h2'} className={'ff-arial'}>
							Esperando datos...
						</Typography>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};


class Notificaciones extends Component {
	
	state = {};
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			venta_creado_disenador_asignado_texto: '',
			venta_creado_disenador_asignado: [
				['Element', 'Density', {role: 'style'}, {role: 'annotation'}],
				['Copper', 8.94, '#b87333', 'Cu'],
				['Silver', 10.49, 'silver', 'Ag'],
				['Gold', 19.30, 'gold', 'Au'],
				['Platinum', 21.45, 'color: #e5e4e2', 'Pt']
			],
			venta_en_produccion_texto: '', venta_en_produccion: [],
			venta_en_almacen_texto: '', venta_en_almacen: [],
			
			listar_notificaciones: [],
			listar_documentos_compartidos: [],
			
			valuetab: '1',
			
			filtro: {
				tipo: '1'
			},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
		};
		
		this.ListarNotificaciones();
	}
	
	ListarNotificaciones = () => {
		NotificacionesService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				listar_notificaciones: response.data,
				// listar_notificaciones: response.data.notificaciones,
				// listar_documentos_compartidos: response.data.documentos_compartidos,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			
			let Usr = ReactLocalStorageService.get('Usr') || {};
			if (FieldsJs.Field(Usr)) {
				Usr.notificaciones = response.total;
				ReactLocalStorageService.set('Usr', Usr);
			}

		}).catch((error) => {
			this.setState({
				listar_notificaciones: [],
				listar_documentos_compartidos: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.ListarNotificaciones());
	};
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	renderSwitchIcon = (icono) => {
		return <Icon className={'px-30 margin-10-R'} style={{color: '#d9e9eb'}}>{icono}</Icon>;
	};
	
	go = (u) => {
		this.props.history.push('/' + u);
	};
	
	redireccion = (url) => {
		if (FieldsJs.Field(url)) {
			window.open(url, '_blank');
		}
	};
	
	handleChangeTabs = (e, variable, campo, date, input) => {
		console.log(e, variable);
		this.setState({
			valuetab: variable,
			filtro: {
				tipo: variable
			}
		}, () => {
				this.ListarNotificaciones()
			
		});
	};
	
	descargarZip = (item) => {
		let archivos = item.archivos;
		if (archivos.length > 0) {
			for (let i = 0; i < archivos.length; i++) {
				DropboxApi.Download(archivos[i].archivo).then(function (data) {
					console.log('Descarga con éxito!');
				}).catch(function (error) {
					console.error("ERROR::: ", error);
				});
			}
		} else {
			this.showSnackBars('error', "No hay documentos regitrados.");
		}
	};
	
	eliminarNotificacion = (item) => {
		NotificacionesService.Eliminar(item).then((response) => {
			this.ListarNotificaciones();
			this.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	}
	
	notificacionesTotal = () => {
		return new Promise((resolve, reject) => {
			NotificacionesService.Total().then((response) => {
				let Usr = ReactLocalStorageService.get('Usr') || {};
				if (FieldsJs.Field(Usr)) {
					Usr.notificaciones = response.data;
					ReactLocalStorageService.set('Usr', Usr);
				}
				resolve(response);
			}).catch((error) => {
				let Usr = ReactLocalStorageService.get('Usr') || {};
				if (FieldsJs.Field(Usr)) {
					Usr.notificaciones = 0;
					ReactLocalStorageService.set('Usr', Usr);
				}
				reject(error);
			});
		});
	}
	
	componentDidMount() {
		$cSuccess("componentDidMount");
		PusherSubscribe('my-channel-notificaciones', info => {
			$cSuccess(info);
			this.InitPusher();
		});
	}
	
	componentWillUnmount() {
		$cInfo("componentWillUnmount");
		PusherUnSubscribe('my-channel-notificaciones', info => {
			$cInfo(info);
		});
	}
	
	InitPusher = () => {
		PusherWatchChannelEvent('my-event-notificaciones', (data) => {
			$cError(JSON.stringify(data));
			FieldsJs.TimePromise(1000, () => this.ListarNotificaciones(), () => console.warn('Nueva Notificación.'))
		});
	};
	
	render() {
		
		
		const bg_logo_svg = {
			backgroundImage: `url(${logo_svg})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'bottom',
			backgroundAttachment: 'fixed',
			backgroundSize: '350px 350px',
			opacity: '1',
		};
		
		return (
			<div className={'w-100'} align={'center'}>
				<div>
					<div className='Notificaciones ContaineViewComponet' style={bg_logo_svg}>
						<Grid container spacing={0}>
							<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
							</Grid>
							<Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
								<Fragment>
									<Grid container spacing={0}>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<TabContext value={this.state.valuetab}>
												<AppBar position="static">
													<TabList variant="fullWidth" onChange={this.handleChangeTabs} aria-label="simple tabs example">
														<Tab label={<Fragment>
															<p className={'margin-0 padding-0 px-14'} style={{fontWeight: 'bold'}}>Notificaciones</p>
															{/*<p className={'margin-0 padding-0 px-13'}>{this.props.titulo}</p>*/}
														</Fragment>} value="1"/>
														<Tab label={<Fragment>
															<p className={'margin-0 padding-0 px-14'} style={{fontWeight: 'bold'}}>Documentos Compartidos</p>
															{/*<p className={'margin-0 padding-0 px-13'}>Contratos individuales firmados</p>*/}
														</Fragment>} value="2"/>
													</TabList>
												</AppBar>
												
												<TabPanel value="1" className={'padding-0-L padding-0-R'}>
													<Grid container spacing={2} alignContent={"flex-start"} alignItems={"stretch"}>
														{this.state.listar_notificaciones.length > 0 ? (
															<Fragment>
																{this.state.listar_notificaciones.map((item, key) => (
																	<Fragment key={key}>
																		<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																			<Fragment>
																				<Card>
																					<div className={'padding-10'}>
																						<div className={'vertical-inline w-100'}>
																							<div className={'v-center vertical-inline w-50-px'}>
																								<DivSvg className={'v-center'} img={giro_de_clientes} height={40} width={42}/>
																							</div>
																							<div className={'w-100-95-px text-left'}>
																								<Typography variant={'h5'} style={{fontWeight: 'bold'}} className={'v-center padding-5-L margin-0 text-black px-13'}>
																									{item.modulo}
																								</Typography>
																								<Typography variant={'h5'} className={'truncate v-center padding-5-L margin-0 text-black px-13'}>
																									{item.notificacion}
																								</Typography>
																							</div>
																							<div className={'vertical-inline h-40-px w-45-px'}>
																								<div className={'v-center padding-5-T'}>
																									<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
																										<IconButton aria-label="Eliminar" onClick={() => this.eliminarNotificacion(item)}>
																											<Cancel style={{color: '#8E1E32', fontSize: '30px'}}/>
																										</IconButton>
																									</Tooltip>
																								</div>
																							</div>
																						</div>
																					</div>
																				</Card>
																			</Fragment>
																		</Grid>
																	</Fragment>
																))}
															</Fragment>
														) : (
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<VistaVacia
																	height={50}
																	width={50}
																	numero={0}
																	mensaje={'No tienes notificaciones por el momento.'}
																/>
															</Grid>
														)}
													
													</Grid>
												</TabPanel>
												
												<TabPanel value="2" className={'padding-0-L padding-0-R'}>
													<Grid container spacing={2} alignContent={"flex-start"} alignItems={"stretch"}>
														{this.state.listar_notificaciones.length > 0 ? (
															<Fragment>
																{this.state.listar_notificaciones.map((item, key) => (
																	<Fragment key={key}>
																		<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																			<Fragment>
																				<Card>
																					<div className={'padding-10'}>
																						<div className={'vertical-inline w-100'}>
																							<div className={'v-center vertical-inline w-50-px'}>
																								<DivSvg className={'v-center'} img={documentos_compartidos} height={40} width={42}/>
																							</div>
																							<div className={'w-100-95-px text-left'}>
																								<Typography variant={'h5'} style={{fontWeight: 'bold'}} className={'v-center padding-5-L margin-0 text-black px-13'}>
																									{item.modulo}
																								</Typography>
																								<Typography variant={'h5'} className={'truncate v-center padding-5-L margin-0 text-black px-13'}>
																									{item.notificacion}
																								</Typography>
																								<div className={'vertical-inline w-100'}>
																									<div className={'v-center padding-5-R'}>
																										<Typography variant={'h5'} className={'truncate v-center padding-5-L margin-0 text-black px-13'}>
																											Descarga aquí
																										</Typography>
																									</div>
																									<div className={'v-center'}>
																										<CloudDownload className={'cursor-pointer'} onClick={() => this.descargarZip(item)} style={{color: 'rgb(142, 30, 50)'}}/>
																									</div>
																								</div>
																							
																							</div>
																							<div className={'vertical-inline h-100 w-45-px'}>
																								<div className={'v-center padding-5-T'}>
																									<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
																										<IconButton aria-label="Eliminar" onClick={() => this.eliminarNotificacion(item)}>
																											<Cancel style={{color: '#8E1E32', fontSize: '30px'}}/>
																										</IconButton>
																									</Tooltip>
																								</div>
																							</div>
																						</div>
																					</div>
																				</Card>
																			</Fragment>
																		</Grid>
																	</Fragment>
																))}
															</Fragment>
														) : (
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<VistaVacia
																	height={50}
																	width={50}
																	numero={0}
																	mensaje={'No tienes notificaciones por el momento.'}
																/>
															</Grid>
														)}
													</Grid>
												</TabPanel>
											</TabContext>
										</Grid>
									</Grid>
									<Paginacion
										total={this.state.paginacion.total}
										page={this.state.paginacion.page}
										limit={this.state.paginacion.limit}
										rangos={this.state.paginacion.rangos}
										onClick={(data) => this.AplicarPaginacion(data)}
									/>
								</Fragment>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		);
	}
}

Notificaciones.propTypes = {};

export default IntegrationNotistack(Notificaciones);
