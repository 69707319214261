import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import {FichaTecnicaService} from "../../../../services/FichaTecnicaService/FichaTecnicaService";
import {FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {Icono} from "../../../Include/Icono/Icono";
import ModalImage from "../../../Include/ModalImage/ModalImage";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import TextField from "@material-ui/core/TextField";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {ClientePotencialService} from "../../../../services/ClientePotencialService/ClientePotencialService";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import Fab from "@material-ui/core/Fab";
import {CloudUploadOutlined, SyncOutlined} from "@material-ui/icons";
import ListaEmpleados from "./includes/ListaEmpleados";


class AltaDeEmpleados extends Component {
	
	state = {};
	
	Usr = {};
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			id_cliente: props.id_cliente,
			
			cliente: {},
			
			id_cat_ficha_tecnica_status: '',
			
			excel_formato_alta_empleado_base64: '',
			excel_formato_alta_empleado_base64Tipo: '',
			excel_formato_alta_empleado_archivo: '',
			excel_formato_alta_empleado_formato: '',
			
			observaciones: '',
			
			cliente_empleado: [],
			cliente_formato_alta_empleado: [],
			
			
			layout_excel: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			FichaTecnicaService.InitDato(this.state.id_cliente).then((response) => {
				
				let dato = FieldsJs.Copy(response.data);
				
				if (response.visible === 0) {
					
					this.setState({
						visible: 0,
						mensaje: response.message
					});
					
				} else {
					
					let cliente_empleado = dato.cliente_empleado || [];
					let cliente_formato_alta_empleado = dato.cliente_formato_alta_empleado || [];
					
					let layout_excel = dato.layout_excel || [];
					
					this.setState({
						
						visible: 1,
						
						cliente: dato.cliente,
						
						id_cat_ficha_tecnica_status: (dato.ficha_tecnica || {}).id_cat_ficha_tecnica_status || '',
						
						cliente_empleado: cliente_empleado,
						cliente_formato_alta_empleado: cliente_formato_alta_empleado,
						
						layout_excel: layout_excel,
						
					});
					
				}
				resolve(true);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
				reject(false)
			});
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	subirNominaEmpleadoFaltante = () => {
		ClientePotencialService.GuardarFormatoAltaEmpleado(this.state).then((response) => {
			
			this.RefreshInit();
			
			this.showSnackBars('success', response.mensaje);
			
			this.setState({
				
				excel_formato_alta_empleado_base64: '',
				excel_formato_alta_empleado_base64Tipo: '',
				excel_formato_alta_empleado_archivo: '',
				excel_formato_alta_empleado_formato: '',
				
				observaciones: '',
				
			});
			
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	disabledFieldAltaDeEmpleadosBolean = () => {
		
		let disabledFieldAltaDeEmpleados = true;
		
		if (FieldsJs.inArray([1, 2, 7], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldAltaDeEmpleados = false;
		}
		
		return disabledFieldAltaDeEmpleados;
	};
	
	render() {
		
		const disabledFieldAltaDeEmpleados = this.disabledFieldAltaDeEmpleadosBolean();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Typography className={'px-20'}>
									<Fab size="small" color="secondary" aria-label="Actualizar" onClick={() => this.RefreshInit()} style={{marginRight: '15px'}}>
										<SyncOutlined/>
									</Fab>
									Alta de empleados en el sistema
								</Typography>
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} align={'center'}>
								
								<Card style={{height: '100%'}}>
									<CardContent style={{background: 'lightgray', padding: '15px', height: '100%'}}>
										
										<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
											
											{!disabledFieldAltaDeEmpleados ? (
												<Fragment>
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
														<Fragment>
															{/*<Avatar>*/}
															{/*	<Fragment>*/}
															{/*		{Icono.get(this.state.layout_excel.formato_alta_empleado_formato, '30px', '30px')}*/}
															{/*	</Fragment>*/}
															{/*</Avatar>*/}
															<ModalImage
																tipo={'view'} desing={null}
																item={{
																	base64: this.state.layout_excel.formato_alta_empleado_base64 || '',
																	base64Tipo: this.state.layout_excel.formato_alta_empleado_base64Tipo || '',
																	archivo: this.state.layout_excel.formato_alta_empleado_archivo || '',
																	formato: this.state.layout_excel.formato_alta_empleado_formato || '',
																}}
															/>
														</Fragment>
														<br/>
														<Typography component="span" style={{fontSize: '10px'}}>
															Click en el archivo para descargar el formato de alta de empleados
														</Typography>
													</Grid>
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
														
														<BoxSelectFile
															// button={(
															// 	<AddOutlined/>
															// )}
															label={(
																<div>
																	Excel para la alta de empleados <b
																	className={'vertical-inline v-center text-blue'}>(Requerido)</b>
																</div>
															)}
															style={{
																color: 'red',
																height: '110px'
															}}
															item={{
																base64: this.state.excel_formato_alta_empleado_base64,
																base64Tipo: this.state.excel_formato_alta_empleado_base64Tipo,
																archivo: this.state.excel_formato_alta_empleado_archivo,
																formato: this.state.excel_formato_alta_empleado_formato,
															}}
															onChange={(r) => {
																this.handleChange({
																	target: {
																		name: 'excel_formato_alta_empleado_base64',
																		value: r.base64
																	}
																});
																this.handleChange({
																	target: {
																		name: 'excel_formato_alta_empleado_base64Tipo',
																		value: r.base64Tipo
																	}
																});
																this.handleChange({
																	target: {
																		name: 'excel_formato_alta_empleado_archivo',
																		value: r.archivo
																	}
																});
																this.handleChange({
																	target: {
																		name: 'excel_formato_alta_empleado_formato',
																		value: r.formato
																	}
																});
															}}
															showSnackBars={this.showSnackBars}
															disabled={disabledFieldAltaDeEmpleados}
														/>
													
													</Grid>
													
													<Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
														<TextField
															multiline
															fullWidth
															onChange={this.handleChange}
															type="text"
															margin="none"
															
															helperText=""
															name="observaciones"
															label="Observaciones"
															value={this.state.observaciones}
															required
															disabled={disabledFieldAltaDeEmpleados}
														/>
													</Grid>
													
													<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
														<Fab
															variant="extended"
															size="small"
															color="primary"
															className={"btn-default-primary text-transform-none w-100"}
															aria-label="Subir archivo"
															onClick={() => this.subirNominaEmpleadoFaltante()}
															disabled={disabledFieldAltaDeEmpleados}
														>
															<CloudUploadOutlined style={{marginRight: '5px'}}/> Subir
														</Fab>
													</Grid>
												
												</Fragment>
											
											) : (
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
													
													<Typography component="span">
														Archivos seleccionados al realizar la alta de empleados
													</Typography>
												
												</Grid>
											)}
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
												
												<Grid container spacing={2}>
													
													{(this.state.cliente_formato_alta_empleado || []).map((item, key) => (
														<Fragment key={key}>
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																
																<Card>
																	<CardContent style={{padding: '15px'}}>
																		
																		<div className={'row-flex'}>
																			<div className="v-center"
																			     style={{paddingRight: '10px'}}>
																				<ListItemAvatar>
																					<Fragment>
																						<Avatar>
																							<Fragment>
																								{Icono.get(item.excel_formato_alta_empleado_formato, '30px', '30px')}
																							</Fragment>
																						</Avatar>
																						<ModalImage
																							tipo={'view'} desing={3}
																							item={{
																								base64: item.excel_formato_alta_empleado_base64 || '',
																								base64Tipo: item.excel_formato_alta_empleado_base64Tipo || '',
																								archivo: item.excel_formato_alta_empleado_archivo || '',
																								formato: item.excel_formato_alta_empleado_formato || '',
																							}}
																						/>
																					</Fragment>
																				</ListItemAvatar>
																			</div>
																			<div className="v-center">
																				<Typography
																					className={'text-left text-black margin-0 padding-0 px-12'}>
																					Comentarios: {item.observaciones || 'Sin comentarios...'}
																				</Typography>
																			</div>
																		</div>
																	
																	</CardContent>
																</Card>
															
															</Grid>
														</Fragment>
													))}
													
													{(this.state.cliente_formato_alta_empleado || []).length <= 0 ? (
														<Fragment>
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<VistaVacia
																	numero={0}
																	padding={'10px'}
																	paddingText={'10px'}
																	mensaje={'No se ha registrado de alta de nominas pendientes.'}
																	height={'50px'}
																	width={'50px'}
																/>
															</Grid>
														</Fragment>
													) : ''}
												
												</Grid>
											
											</Grid>
										
										</Grid>
									
									</CardContent>
								</Card>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={8} lg={8} xl={8} align={'center'}>
								<ListaEmpleados cliente_empleado={this.state.cliente_empleado}/>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={0}>
							<Grid item xs={4} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={8} align={'right'}>
								{/*<Button onClick={() => this.save()} color="primary">
									Indicar que la alta de empleados se completo
								</Button>*/}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

AltaDeEmpleados.propTypes = {
	id_cliente: PropTypes.number.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default AltaDeEmpleados;
