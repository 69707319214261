import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {
	BotonAccionAbrirModal,
	BotonAccionFooterModal,
	TituloHeaderModal
} from "../../../../Include/MiniComponents/GlobalComponent";
import ClienteReceptoraPagoDatoBancarioService
	from "../../../../../services/_Cat/ClienteReceptoraPagoDatoBancarioService/ClienteReceptoraPagoDatoBancarioService";
import {
	AccountBalanceOutlined,
	AccountTreeOutlined,
	BusinessOutlined,
	CommentOutlined,
	ConfirmationNumberOutlined, SyncOutlined, Person
} from "@material-ui/icons";
import BancoService from "../../../../../services/_Cat/BancoService/BancoService";
import BancoSucursalService from "../../../../../services/_Cat/BancoSucursalService/BancoSucursalService";
import BancoTipoCuentaService from "../../../../../services/_Cat/BancoTipoCuentaService/BancoTipoCuentaService";
import ModalBanco from "../../Banco/Includes/ModalBanco";
import ModalBancoSucursal from "../../BancoSucursal/Includes/ModalBancoSucursal";
import Fab from "@material-ui/core/Fab";
import ModalBancoTipoCuenta from "../../BancoTipoCuenta/Includes/ModalBancoTipoCuenta";

class ModalClienteReceptoraPagoDatoBancario extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_empresa_dato_bancario: (props.item || {}).id_empresa_dato_bancario || '',
			id_empresa: (props.item || {}).id_empresa || '',
			id_cat_banco: '',
			id_cat_banco_sucursal: '',
			id_cat_banco_tipo_cuenta: '',
			numero_cliente: '',
			numero_cuenta: '',
			clabe_interbancaria: '',
			observaciones: '',
			activo: true,
			apoderado: '',
			autorizados: '',
			
			cat_banco: [],
			cat_banco_sucursal: [],
			cat_banco_tipo_cuenta: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			this.ListBanco();
			this.ListBancoTipoCuenta();
			if (this.state.id_cat_banco > 0) {
				this.ListBancoSucursal({target: {value: this.state.id_cat_banco}});
			}
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		if (this.state.id_empresa_dato_bancario > 0) {
			ClienteReceptoraPagoDatoBancarioService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			ClienteReceptoraPagoDatoBancarioService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_empresa_dato_bancario: (this.props.item || {}).id_empresa_dato_bancario || '',
			id_empresa: (this.props.item || {}).id_empresa || '',
			id_cat_banco: '',
			id_cat_banco_sucursal: '',
			id_cat_banco_tipo_cuenta: '',
			numero_cliente: '',
			numero_cuenta: '',
			clabe_interbancaria: '',
			observaciones: '',
			apoderado: '',
			autorizados: '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_empresa_dato_bancario: (this.props.item || {}).id_empresa_dato_bancario || '',
			id_empresa: (this.props.item || {}).id_empresa || '',
			id_cat_banco: item.id_cat_banco || '',
			id_cat_banco_sucursal: item.id_cat_banco_sucursal || '',
			id_cat_banco_tipo_cuenta: item.id_cat_banco_tipo_cuenta || '',
			numero_cliente: item.numero_cliente || '',
			numero_cuenta: item.numero_cuenta || '',
			clabe_interbancaria: item.clabe_interbancaria || '',
			observaciones: item.observaciones || '',
			apoderado: item.apoderado || '',
			autorizados: item.autorizados || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_empresa_dato_bancario: (this.props.item || {}).id_empresa_dato_bancario || '',
			id_empresa: (this.props.item || {}).id_empresa || '',
			id_cat_banco: item.id_cat_banco || '',
			id_cat_banco_sucursal: item.id_cat_banco_sucursal || '',
			id_cat_banco_tipo_cuenta: item.id_cat_banco_tipo_cuenta || '',
			numero_cliente: item.numero_cliente || '',
			numero_cuenta: item.numero_cuenta || '',
			clabe_interbancaria: item.clabe_interbancaria || '',
			observaciones: item.observaciones || '',
			apoderado: item.apoderado || '',
			autorizados: item.autorizados || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	ListBanco = () => {
		BancoService.Listar().then((response) => {
			this.setState({
				cat_banco: response.data
			});
		});
	};
	
	ListBancoSucursal = (e) => {
		let id_cat_banco = e.target.value;
		BancoSucursalService.Listar(id_cat_banco).then((response) => {
			this.setState({
				cat_banco_sucursal: response.data
			});
		});
	};
	
	ListBancoTipoCuenta = () => {
		BancoTipoCuentaService.Listar().then((response) => {
			this.setState({
				cat_banco_tipo_cuenta: response.data
			});
		});
	};
	
	// agregar = () => {
	// 	UsuarioBancoService.Agregar(this.state).then((response) => {
	// 		this.props.showSnackBars('success', response.mensaje);
	// 		this.RefreshInit();
	// 		this.setState({
	// 			id_usuario: '',
	// 			id_cat_banco: '',
	// 			id_cat_banco_sucursal: '',
	// 			id_cat_banco_tipo_cuenta: '',
	// 			numero_cliente: '',
	// 			numero_cuenta: '',
	// 			clabe_interbancaria: '',
	// 			observaciones: '',
	// 			activo: true,
	// 		});
	// 	}).catch((error) => {
	// 		this.props.showSnackBars('error', error.mensaje);
	// 	});
	// };
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_empresa_dato_bancario}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Datos Bancarios'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-50-px'}>
										<ModalBanco
											componente={(
												<Fab size="small" color="secondary">
													<AccountBalanceOutlined/>
												</Fab>
											)}
											tipo={'add'}
											item={{}}
											RefreshList={this.ListBanco}
											showSnackBars={this.props.showSnackBars}
										/>
									</Grid>
									<Grid item className={'w-100-50-px'}>
										<TextField
											select
											fullWidth
											onChange={(e) => {
												this.handleChange(e);
												this.ListBancoSucursal(e);
											}}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											
											name="id_cat_banco"
											label="Banco"
											value={this.state.id_cat_banco}
											required
										>
											<option value={''}>&nbsp;</option>
											{this.state.cat_banco.map(option => (
												<option key={option.id_cat_banco} value={option.id_cat_banco}>
													{option.banco}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-50-px'}>
										<ModalBancoSucursal
											componente={(
												<Fab size="small" color="secondary">
													<BusinessOutlined/>
												</Fab>
											)}
											tipo={'add'}
											item={{
												id_cat_banco_sucursal: null,
												id_cat_banco: this.state.id_cat_banco
											}}
											RefreshList={() => {
												this.ListBancoSucursal({target: {value: this.state.id_cat_banco}});
											}}
											showSnackBars={this.props.showSnackBars}
										/>
									</Grid>
									<Grid item className={'w-100-50-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											
											name="id_cat_banco_sucursal"
											label="Sucursal"
											value={this.state.id_cat_banco_sucursal}
										>
											<option value={''}>&nbsp;</option>
											{this.state.cat_banco_sucursal.map(option => (
												<option key={option.id_cat_banco_sucursal}
												        value={option.id_cat_banco_sucursal}>
													{option.banco_sucursal}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-50-px'}>
										<ModalBancoTipoCuenta
											componente={(
												<Fab size="small" color="secondary">
													<AccountTreeOutlined/>
												</Fab>
											)}
											tipo={'add'}
											item={{}}
											RefreshList={() => {
												this.ListBancoTipoCuenta();
											}}
											showSnackBars={this.props.showSnackBars}
										/>
									</Grid>
									<Grid item className={'w-100-50-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											
											name="id_cat_banco_tipo_cuenta"
											label="Tipo de cuenta"
											value={this.state.id_cat_banco_tipo_cuenta}
											required
										>
											<option value={''}>&nbsp;</option>
											{this.state.cat_banco_tipo_cuenta.map(option => (
												<option key={option.id_cat_banco_tipo_cuenta}
												        value={option.id_cat_banco_tipo_cuenta}>
													{option.banco_tipo_cuenta}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<ConfirmationNumberOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="numero_cliente"
											onChange={this.handleChange}
											label="No. cliente" autoComplete={'off'}
											value={this.state.numero_cliente}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Person className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="apoderado"
											onChange={this.handleChange}
											label="Apoderado" autoComplete={'off'}
											value={this.state.apoderado}
											
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Person className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="autorizados"
											onChange={this.handleChange}
											label="Autorizados" autoComplete={'off'}
											value={this.state.autorizados}
											
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<ConfirmationNumberOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="numero_cuenta"
											onChange={this.handleChange}
											label="No. cuenta" autoComplete={'off'}
											value={this.state.numero_cuenta}
											required
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<ConfirmationNumberOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="clabe_interbancaria"
											onChange={this.handleChange}
											label="Clabe interbancaria" autoComplete={'off'}
											value={this.state.clabe_interbancaria}
											required
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<CommentOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											multiline
											fullWidth
											name="observaciones"
											onChange={this.handleChange}
											label="Observaciones" autoComplete={'off'}
											value={this.state.observaciones}
										/>
									</Grid>
								</Grid>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_empresa_dato_bancario}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalClienteReceptoraPagoDatoBancario.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalClienteReceptoraPagoDatoBancario;
