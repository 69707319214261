import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FieldsJs} from "../../../../settings/General/General";
import TextField from "@material-ui/core/TextField";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {Group, Send} from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import icon_rocket_chat from "../../../../assets/img/icons/rocketchat.svg";
import {LineaCapturaService} from "../../../../services/LineaCapturaService/LineaCapturaService";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {CONFIG} from "../../../../settings/Config/Config";

class ModalSendRocketChat extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			
			tipo: props.tipo,
			item: props.item,
			id_linea_captura: (props.item || {}).id_linea_captura,
			id_usuario: '',
			mensaje: '',
			listar_usuarios: props.listar_usuarios || [],
			usuarios_notificar: []
		};
		
	}
	
	openModal = () => {
		const {item} = this.props;
		console.log("ITEM:::", item);
		
		this.setState({
			open: true,
			scroll: 'paper',
			item: item,
			id_usuario: '',
			mensaje: '',
			id_linea_captura: item.id_linea_captura || '',
			listar_usuarios: this.props.listar_usuarios || [],
			usuarios_notificar: []
		});
	};
	
	closeModal = () => {
		this.setState({open: false});
	};
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	close = () => {
		this.closeModal();
	};
	
	save = () => {
		LineaCapturaService.SendMail(this.state).then((response) => {
			this.close();
			this.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	asignarUsuarioReporte = (id_usuario, id_usuario_autorizado) => {
		let usuarios = FieldsJs.Copy(this.state.listar_usuarios);
		let users = FieldsJs.Copy(this.state.usuarios_notificar);
		let banduser = false;
		let userkey = 0;
		for (let i = 0; i < users.length; i++) {
			if (Number(users[i].id_usuario) === Number(id_usuario)) {
				banduser = true;
				userkey = i;
			}
		}
		if (!banduser) {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = true;
					if (id_usuario_autorizado) {
						usuarios[i].id_usuario = id_usuario_autorizado;
					}
					users.push(usuarios[i]);
				}
			}
		} else {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = false;
				}
			}
			users.splice(userkey, 1);
		}
		this.setState({
			listar_usuarios: usuarios,
			usuarios_notificar: users,
		});
		console.log("ASIGNARUSUARIO", users, usuarios, this.state.usuarios_notificar);
	};
	
	render() {
		
		return (
			<span>
				
				{this.props.component ? (
					<Fragment>
						<span onClick={() => this.openModal('paper')} style={{cursor: 'pointer'}}>
							{this.props.component}
						</span>
					</Fragment>
				) : (
					<Fragment>
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={() => this.openModal('paper')}>
							<Send className={'margin-5-R px-14'}/>
							Enviar Notificación
						</Fab>
					</Fragment>
				)}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.closeModal}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal} className={'sendMessage-ticket'}
				>
					
					<DialogTitle style={{color: 'gray'}}>Enviar Notificación</DialogTitle>
					
					<DialogContent>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={2} className={'h-100'}>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'text-center px-13'} style={{fontWeight: 'bold'}}>
											 El mensaje por defecto se enviará al usuario que subió la Linea de Captura y a Fiscal, si desea notificar a otro usuario, seleccione a continuación:
										</Typography>
									</Grid>
									
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={2}>
												<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
													<div className={'borde-punteado-lightgray-1 card-0 text-center'} onClick={() => {
														this.setState({
															check_rocket_chat: !this.state.check_rocket_chat
														})
													}}>
														<div className={'w-100 text-center padding-10-B padding-10-T'}>
															<span style={{cursor: 'pointer', fontSize: 14}}>
																Rocket Chat
															</span>
														</div>
														<div className={'w-100 text-center check-notificacion'}>
															<div className={'w-100 margin-10-B'}>
																<img className={'v-center'} src={icon_rocket_chat} alt="add" width={80} height={80} style={{opacity: 0.7}}/>
															</div>
														</div>
													</div>
												</Grid>
												
												<Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
													<Grid container spacing={2}>
														{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<TextField
																select
																fullWidth
																required
																margin="none"
																onChange={this.handleChange}
																disabled={this.is_view()}
																SelectProps={{
																	native: true,
																	MenuProps: {
																		className: '',
																	},
																}}
																name="id_usuario"
																label="Usuario"
																value={this.state.id_usuario}
															>
																<option key={0} value={''}>&nbsp;</option>
																{this.props.listar_usuarios.map(option => (
																	<option key={option.id_usuario} value={option.id_usuario}>
																		{option.nombre_completo} - {option.usuario_rocket_chat}
																	</option>
																))}
															</TextField>
														</Grid>*/}
														
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
															<TextField
																multiline
																rows={5}
																required
																fullWidth
																variant={"outlined"}
																name={"mensaje"}
																onChange={this.handleChange}
																type="text"
																margin="none"
																helperText=""
																label="Mensaje"
																value={this.state.mensaje}
															/>
														</Grid>
													</Grid>
												</Grid>
												
											<Grid item className={'text-left borde-punteado-lightgray-2 margin-8 margin-15-B'} xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={1} alignItems={"flex-center"}>
														<Grid className={'w-30-px'}>
															<Group className={'w-100 text-gray'}/>
														</Grid>
														<Grid className={'w-100-30-px'}>
															<Typography className={'px-14 text-left text-gray margin-2-T'}>
																{'Notificar a:'}
															</Typography>
														</Grid>
														<Grid className={'w-100'}>
															<div style={{maxHeight: 280, overflowY: 'auto'}}>
																{this.state.listar_usuarios.map((item, index) => (
																	<Fragment key={index}>
																		<div className={'vertical-inline w-95 cursor-pointer'} onClick={() => {
																			if (this.props.tipo !== 'view') {
																				this.asignarUsuarioReporte(item.id_usuario);
																			}
																		}}>
																			<div className='v-center padding-10-L'>
																				<FormGroup row className={'text-center check-recuser'}>
																					<FormControlLabel
																						control={
																							<Checkbox
																								type="checkbox"
																								color="primary"
																								checked={item.check}
																								value={item.check}
																								disabled={this.props.tipo === 'view'}
																							/>
																						}
																						label={''}
																					/>
																				</FormGroup>
																			</div>
																			
																			<div className={'v-center vertical-inline'}>
																				<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
																				     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
																				</div>
																				<Typography variant={'h5'} className={'v-center padding-15-L margin-0 text-black px-13'}>
																					{item.nombre_completo}
																				</Typography>
																			</div>
																		</div>
																	</Fragment>
																))}
															</div>
														</Grid>
													</Grid>
												</Grid>
											
											</Grid>
										{/*</fieldset>*/}
									</Grid>
									
								</Grid>
							</Grid>
						</Grid>

					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={0}>
							<Grid item xs={12} align={'right'}>
								<Button onClick={() => this.close()} color="primary">
									{!this.is_view() ? "Cancelar" : "Cerrar"}
								</Button>
								<Button onClick={() => this.save()} color="primary">
									{'Enviar'}
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			
			</span>
		);
	}
}

ModalSendRocketChat.propTypes = {
	tipo: PropTypes.oneOf([
		"add",
		"view",
		"edit",
	]),
	item: PropTypes.object.isRequired,
	listar_usuarios: PropTypes.array.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	component: PropTypes.element,
	RefreshList: PropTypes.func.isRequired,
};

export default ModalSendRocketChat;
