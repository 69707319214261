import React, {Component, Fragment} from 'react';
import Typography from "@material-ui/core/Typography";
import ListaTabla from './Includes/ListaTabla';
import ModalComprobantesNomina from './Includes/ModalComprobantesNomina';
import {ComprobantesNominaService} from '../../../services/ComprobantesNominaService/ComprobantesNominaService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../settings/Config/Config";
import Paginacion from "../../Include/Paginacion/Paginacion";
import FiltrosComprobantesNomina from "./Includes/FiltrosComprobantesNomina";
import {BotonActualizarLista, BotonExportarListaExcel, BotonExportarListaPDF, CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import Fab from "@material-ui/core/Fab";
import {PostAdd} from "@material-ui/icons";
import ModalDescargaComprobantesNomina from "./Includes/ModalDescargaComprobantesNomina";


class ComprobantesNomina extends Component {
	
	Usr = {};
	
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			listar_cronograma: [],
			filtro: {
				id_comprobante_nomina: '',
				usuario: '',
				id_empresa: '',
				ejercicio: '',
				periodo: '',
				id_cat_tipo_impuesto: '',
				id_cat_tipo_declaracion: '',
				id_empleado: '',
				id_cat_periodicidad_pago: '',
				importe: '',
				fecha_alta: null,
				fecha_pago: null,
				fecha_pago_inicio: null,
				fecha_pago_fin: null,
				fecha_alta_inicio: null,
				fecha_alta_fin: null,
				activo: 1
			},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			lista_empresa: [],
			listar_usuarios: [],
			
			
			band_download_comprobante_nomina: false,
			directorio: ''
			
		};
		
		this.RefreshList();
		this.ListEmpresa();
		this.listarUsuarios();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	listarUsuarios = () => {
		CatService.ListUsuario().then(response => {
			this.setState({
				listar_usuarios: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_usuarios: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	Listar = () => {
		ComprobantesNominaService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				listar_cronograma: response.data,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			this.setState({listar_cronograma: []});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	Eliminar = (item) => {
		
		let msg = `¿Deseas eliminar el usuario ${item.nombre} ${item.apellido_paterno} ${item.apellido_materno}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				ComprobantesNominaService.Eliminar(item.id_usuario).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	ListEmpresa = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpresa().then((response) => {
				this.setState({
					lista_empresa: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	exportar = (item, archivo, auth) => {
		const {filtro} = this.state;
		let filter = {
			id_comprobante_nomina: filtro.id_comprobante_nomina || null,
			usuario: filtro.usuario || null,
			id_empresa: filtro.id_empresa || null,
			ejercicio: filtro.ejercicio || null,
			periodo: filtro.periodo || null,
			id_cat_banco: filtro.id_cat_banco || null,
			id_cat_tipo_documento_estados_cuenta: filtro.id_cat_tipo_documento_estados_cuenta || null,
			id_empleado: filtro.id_empleado || null,
			id_cat_periodicidad_pago: filtro.id_cat_periodicidad_pago || null,
			fecha_alta: (filtro.fecha_alta || null) ? DateFormat.FormatSql(filtro.fecha_alta) : null,
			fecha_pago: (filtro.fecha_pago || null) ? DateFormat.FormatSql(filtro.fecha_pago) : null,
			fecha_pago_inicio: (filtro.fecha_pago_inicio || null) ? DateFormat.FormatSql(filtro.fecha_pago_inicio) : null,
			fecha_pago_fin: (filtro.fecha_pago_fin || null) ? DateFormat.FormatSql(filtro.fecha_pago_fin) : null,
			fecha_alta_inicio: (filtro.fecha_alta_inicio || null) ? DateFormat.FormatSql(filtro.fecha_alta_inicio) : null,
			fecha_alta_fin: (filtro.fecha_alta_fin || null) ? DateFormat.FormatSql(filtro.fecha_alta_fin) : null,
			importe: filtro.importe || null,
			activo: filtro.activo || null,
			
		};
		HttpRequest.export('comprobante_nomina', ['comprobante_nomina'], {
			auth: auth,
			archivo: archivo,
			filtro: filter
		}, false, false, false, archivo === "EXCEL")
	};
	
	
	ListarDonwload = () => {
		ComprobantesNominaService.ListarDownload(this.state.filtro).then((response) => {
			let data = response.data;
			if (data.length > 0) {
				this.setState({
					band_download_comprobante_nomina: true,
					directorio: response.data
				});
			} else {
				this.showSnackBars('error', response.mensaje);
			}
			
			/*setTimeout(() => {
				this.exportarDescargar({}, 'ZIP', true)
			}, 1000);*/
			
		}).catch((error) => {
			
			this.setState({listar_facturacion: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	cancelModalDescarga = () => {
		this.setState({
			band_download_comprobante_nomina: false
		});
	}
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'Comprobantes de Nómina'}
					/*botonPDF={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaPDF onClick={() => {
								
								}}/>
							) : ''}
						</Fragment>
					}*/
					botonDropbox={
						<Fragment>
							{FieldsJs.inArray([1, 2, 4, 6, 9, 13, 14], this.Usr.id_cat_tipo_usuario) ? (
								<Fab className={'vertical-inline'} variant="extended" size="small" color="primary" style={{backgroundColor: "rgba(130,38,27,0.8)"}}
								     onClick={() => this.ListarDonwload()}>
									{/*<img className={'v-center padding-6-R'} alt={'Chat'} src={icon_integraciones} width="25px" height="auto"/>*/}
									<PostAdd className={'v-center margin-5-R px-15'}/>
									<div className={'v-center px-13 text-white'}>
										Descargar
									</div>
								</Fab>
							) : null}
						</Fragment>
					}
					botonEXCEL={
						<Fragment>
							<BotonExportarListaExcel onClick={() => this.exportar(this.state.filtro, 'EXCEL', true)}/>
						</Fragment>
					}
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() => {
								this.Listar();
							}}/>
						</Fragment>
					}
				/>
				
				<FiltrosComprobantesNomina
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
					ListEmpresa={this.ListEmpresa}
					lista_empresa={this.state.lista_empresa}
				/>
				
				{this.state.band_download_comprobante_nomina ? (
					<Fragment>
						<ModalDescargaComprobantesNomina
							open={this.state.band_download_comprobante_nomina}
							item={this.state}
							cancelModalDescarga={this.cancelModalDescarga}
						/>
					</Fragment>
				) : null
				}
				
				<div className={'form margin-30-B'}>
					
					<Typography variant={'h6'} className={'margin-30-T margin-30-B'}>
					
					</Typography>
					
					{this.state.listar_cronograma.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_cronograma}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
								ListEmpresa={this.ListEmpresa}
								listar_usuarios = {this.state.listar_usuarios}
								lista_empresa={this.state.lista_empresa}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				<ModalComprobantesNomina
					tipo={'add'}
					ListEmpresa={this.ListEmpresa}
					lista_empresa={this.state.lista_empresa}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>
			
			</div>
		);
	}
}

ComprobantesNomina.propTypes = {};

export default IntegrationNotistack(ComprobantesNomina);
