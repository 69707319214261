import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {AccountTree, DateRangeOutlined, HdrStrong, MailOutline, DateRange, PersonOutlined, SearchOutlined, ViewDay, Apartment} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {CatService as TipoUsuarioServiceService, CatService} from "../../../../services/_Cat/CatService/CatService";
import {TipoUsuarioService} from "../../../../services/_Cat/TipoUsuarioService/TipoUsuarioService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import Input from "@material-ui/core/Input";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ModalProyecto from "../../Cat/Proyecto/Includes/ModalProyecto";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Add from "@material-ui/icons/Add";
import ModalModulo from "../../Cat/Modulo/Includes/ModalModulo";
import {ProyectoService} from "../../../../services/_Cat/ProyectoService/ProyectoService";
import {ModuloService} from "../../../../services/_Cat/ModuloService/ModuloService";
import TableCell from "@material-ui/core/TableCell";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";


class FiltrosVacaciones extends Component {
	
	Usr = {};
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			usuario: '',
			id_empresa: '',
			dias_disponibles_vacaciones: '',
			fecha_inicio: null,
			fecha_fin: null,
			activo: '',
			
			lista_empresas: [],
			
		};
		
		this.ListEmpresa();
	}
	
	ListEmpresa = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpresa().then((response) => {
				this.setState({
					lista_empresas: response.data
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_empresas: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.usuario = this.state.usuario || undefined;
		filtro.dias_disponibles_vacaciones = this.state.dias_disponibles_vacaciones || undefined;
		filtro.id_empresa = this.state.id_empresa || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					{ FieldsJs.inArray([1,2,13], this.Usr.id_cat_tipo_usuario) ? (
						<Fragment>
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3} align={'left'}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PersonOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="usuario"
											onChange={this.handleChange}
											label="Usuario"
											autoComplete={'off'}
											value={this.state.usuario}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2} align={'left'}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<DateRange className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="dias_disponibles_vacaciones"
											onChange={this.handleChange}
											label="Días disponibles"
											autoComplete={'off'}
											value={this.state.dias_disponibles_vacaciones}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Fragment>
					) : null }
					
					<Grid item className={'text-left'} xs={6} sm={6} md={3} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Apartment className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth margin="dense" className={'margin-0'}>
									<InputLabel>Empresa</InputLabel>
									<Select
										native
										value={this.state.id_empresa}
										onChange={(e) => {
											this.handleChange(e);
										}}
										name="id_empresa"
										input={<Input/>}
									>
										<option value=""/>
										{this.state.lista_empresas.map(option => (
											<option key={option.id_empresa}
											        value={option.id_empresa}>
												{option.razon_social}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					{/*<Grid item xs={6} sm={6} md={2} lg={2} xl={2} align={'left'}>
						<MDDatePicker
							type={'range'}
							component={(
								<TextField
									fullWidth
									label="Fecha"
									value={(this.state.fecha_inicio && this.state.fecha_fin) ? (DateFormat.FormatText(this.state.fecha_inicio) + " al " + DateFormat.FormatText(this.state.fecha_fin)) : ''}
									readOnly
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton aria-label="Fecha">
													<DateRangeOutlined/>
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							)}
							startDate={this.state.fecha_inicio}
							endDate={this.state.fecha_fin}
							onChange={(range) => {
								console.log("RANGE", range);
								this.setState({
									fecha_inicio: range.startDate,
									fecha_fin: range.endDate,
								});
								setTimeout(() => this.HandleFiltro());
							}}
						/>
					</Grid>*/}
					
					
					
					
					{/*<Grid item className={'text-left'} xs={6} sm={6} md={1} lg={1} xl={1}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth margin="dense" className={'margin-0'}>
									<InputLabel>Estatus</InputLabel>
									<Select
										native
										value={this.state.id_cat_cronograma_estatus}
										onChange={(e) => {
											this.handleChange(e);
										}}
										name="id_cat_cronograma_estatus"
										input={<Input/>}
									>
										<option value=""/>
										{this.state.lista_cronograma_estatus.map(option => (
											<option key={option.id_cat_cronograma_estatus}
											        value={option.id_cat_cronograma_estatus}>
												{option.cronograma_estatus}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>*/}
					
					{/*<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel htmlFor="age-native-activo">Activo</InputLabel>
									<Select
										native
										value={this.state.activo}
										onChange={this.handleChange}
										inputProps={{
											name: 'activo',
											id: 'age-native-activo',
										}}
									>
										<option value={'all'}>Todos</option>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>*/}
					
					<Grid item xs={12} sm={12} md={12} lg={2} xl={2} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosVacaciones.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosVacaciones;
