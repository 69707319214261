import React, {Component, Fragment} from 'react';
import ListaTabla from './Includes/ListaTabla';

import {QuinielaService} from '../../../services/QuinielaService/QuinielaService';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {AttachMoney, Sync} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";
import ModalPagos from "./Includes/ModalPagos";
import ModalMiembro from "./Includes/ModalMiembro";
import ListaTablaIngresos from "./Includes/ListaTablaIngresos";
import ListaTablaEgresos from "./Includes/ListaTablaEgresos";

class VerQuiniela extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		const params = props.params || {};
		
		this.state = {
			id_quiniela: params.id_quiniela || '',
			lista_miembros: [],
			lista_ingresos: [],
			lista_egresos: [],
			
			detalle: {},
			
			
		};
		
		this.Listar();
		this.RefreshListPago();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	Detalle = () => {
		QuinielaService.Detalle(this.state.id_quiniela).then((response) => {
			this.setState({
				detalle: response.data
			});
		}).catch((error) => {
			this.setState({
				detalle: {}
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	Listar = () => {
		this.Detalle();
		
		QuinielaService.ListarMiembro(this.state.id_quiniela).then((response) => {
			this.setState({
				lista_miembros: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_miembros: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	RefreshListPago = () => {
		this.ListarIngresos();
		this.ListarEgresos();
	};
	
	ListarIngresos = () => {
		this.Detalle();
		
		QuinielaService.ListarIngresos(this.state).then((response) => {
			this.setState({
				lista_ingresos: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_ingresos: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListarEgresos = () => {
		this.Detalle();
		
		QuinielaService.ListarEgresos(this.state).then((response) => {
			this.setState({
				lista_egresos: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_egresos: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	exportar = (item, archivo, auth) => {
		let filtro = {
			id_adquisicion: item.id_adquisicion || '',
			id_usuario: item.id_usuario || '',
		};
		console.log('EXPORTAR::: ', item);
		HttpRequest.export('adquisicion', ['cuenta_pago'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, false, false, false, archivo === "EXCEL")
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<Grid container direction="row" justify="space-between" alignItems="center" spacing={2}
				      style={{marginTop: 20, marginBottom: 20}}>
					<Grid item>
						<Typography variant={'h5'} className={'text-left'}>
							{this.state.detalle.nombre || 'Detalle de quiniela' }
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant={'h4'} className={'text-left'} style={{color: 'rgb(130, 38, 27)'}}>
							{'Saldo: $' + FieldsJs.Currency(this.state.detalle.saldo_total || 0)}
						</Typography>
					</Grid>
					<Grid item>
						<div className={'vertical-inline w-100'}>
							<div className={'v-center'}>
								<ModalPagos
									id_quiniela={this.state.id_quiniela}
									tipo={'add'}
									componente={
										<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#2884a5", marginRight: 15}}>
											<AttachMoney style={{fontSize: 14, marginRight: 5}}/> Nuevo Ingreso
										</Fab>
									}
									lista_miembros={this.state.lista_miembros}
									RefreshList={this.RefreshListPago}
									showSnackBars={this.showSnackBars}
								/>
							</div>
							<div className={'v-center'}>
								<ModalPagos
									id_quiniela={this.state.id_quiniela}
									tipo={'edit'}
									componente={
										<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#82261b", marginRight: 15}}>
											<AttachMoney style={{fontSize: 14, marginRight: 5}}/> Nuevo Egreso
										</Fab>
									}
									lista_miembros={this.state.lista_miembros}
									RefreshList={this.RefreshListPago}
									showSnackBars={this.showSnackBars}
								/>
							</div>
							{/*<div className={'v-center'}>
								<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#006300", marginRight: 15}} onClick={() => this.exportar({}, 'EXCEL', true)}>
									<EventNote style={{fontSize: 14, marginRight: 5}}/> Excel
								</Fab>
							</div>*/}
							<div className={'v-center'}>
								<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#3f50b6"}} onClick={() => this.Listar()}>
									<Sync style={{fontSize: 14, marginRight: 5}}/> Actualizar
								</Fab>
							</div>
						</div>
					</Grid>
				</Grid>
				
				{/*<FiltrosQuiniela
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>*/}
				
				<div className={'form margin-30-B'} style={{marginTop: '20px'}}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<div className={'bg-lightwhite b-r-5 padding-10'}>
								{this.state.lista_miembros.length > 0 ? (
									<Fragment>
										
										<div className={''}>
											<Typography className={'text-left px-14'}>
												{'Miembros'}
											</Typography>
											
											<div className={'padding-10-T'}>
												<ListaTabla
													lista={this.state.lista_miembros}
													RefreshList={this.RefreshList}
													showSnackBars={this.showSnackBars}
													exportar={this.exportar}
												/>
											</div>
										</div>
									
									</Fragment>
								) : (
									<VistaVacia
										numero={0}
										height={100}
										width={100}
										mensaje={'No se encontraron miembros.'}
									/>
								)}
							</div>
						</Grid>
						
						<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
							<div className={'bg-lightwhite b-r-5 padding-10'}>
								{this.state.lista_ingresos.length > 0 ? (
									<Fragment>
										
										<div className={''}>
											<div className={'vertical-inline w-100 row-flex margin-10-B'}>
												<div className={'w-50 text-left'}>
													<Typography className={'px-14'}>
														{'Ingresos'}
													</Typography>
												</div>
												<div className={'w-50 text-right'}>
													<Typography className={'px-14'}>
														{'Total: ' + FieldsJs.Currency(this.state.detalle.total_ingresos || 0)}
													</Typography>
												</div>
											</div>
											
											<ListaTablaIngresos
												lista={this.state.lista_ingresos}
												RefreshList={this.RefreshList}
												showSnackBars={this.showSnackBars}
												exportar={this.exportar}
											/>
										</div>
									
									</Fragment>
								) : (
									<VistaVacia
										numero={0}
										height={100}
										width={100}
										mensaje={'No se encontraron ingresos.'}
									/>
								)}
							</div>
						</Grid>
						
						<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
							<div className={'bg-lightwhite b-r-5 padding-10'}>
								{this.state.lista_egresos.length > 0 ? (
									<Fragment>
										
										<div className={''}>
											<div className={'vertical-inline w-100 row-flex margin-10-B'}>
												<div className={'w-50 text-left'}>
													<Typography className={'px-14'}>
														{'Egresos'}
													</Typography>
												</div>
												<div className={'w-50 text-right'}>
													<Typography className={'px-14'}>
														{'Total: ' + FieldsJs.Currency(this.state.detalle.total_egresos || 0)}
													</Typography>
												</div>
											</div>
											
											<ListaTablaEgresos
												lista={this.state.lista_egresos}
												RefreshList={this.RefreshList}
												showSnackBars={this.showSnackBars}
												exportar={this.exportar}
											/>
										</div>
									</Fragment>
								) : (
									<VistaVacia
										numero={0}
										height={100}
										width={100}
										mensaje={'No se encontraron egresos.'}
									/>
								)}
							</div>
						</Grid>
					</Grid>
				</div>
				
				{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (
					<ModalMiembro
						tipo={'add'}
						item={this.state.detalle}
						RefreshList={this.RefreshList}
						showSnackBars={this.showSnackBars}
					/>
				) : null}
			</div>
		);
	}
}

VerQuiniela.propTypes = {};

export default IntegrationNotistack(VerQuiniela);
