import React, {Component, Fragment} from 'react';
import Typography from "@material-ui/core/Typography/index";
import Sync from '@material-ui/icons/Sync';
import ListaTabla from './Includes/ListaTabla';
import {AccesoService} from '../../../services/AccesoService/AccesoService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Fab from "@material-ui/core/Fab/index";
import VistaVacia from "../../Include/VistaVacia/VistaVacia";
import usuario_vacio from "../../../assets/img/logo_acresco.svg";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import {CONFIG} from "../../../settings/Config/Config";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import {RepositorioContableService} from "../../../services/RepositorioContableService/RepositorioContableService";
import {FieldsJs} from "../../../settings/General/General";
import {DropboxApi} from "../../../settings/Dropbox/DropboxApi";
import {CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import Grid from "@material-ui/core/Grid";


class RepositorioContableListar extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		let ids_expandido = ReactLocalStorageService.get('ids_expandido');
		
		
		this.state = {
			
			filtro: {
				autorizado: 1,
			},
			
			paginacion: {
				total: 0,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			listar_repositorio: [],
			
			ids_expandido: ids_expandido || [],
			routes: [],
			
			ruta: FieldsJs.inArray([1, 2, 3, 4, 6, 9, 10, 13, 14], this.Usr.id_cat_tipo_usuario) ? "/Repositorio Contable":'/Repositorio Contable/' + this.Usr.id_usuario + '-' + this.Usr.nombre_completo,
			
		};
		
		this.Listar(FieldsJs.inArray([1, 2, 3, 4, 6, 9, 10, 13, 14], this.Usr.id_cat_tipo_usuario) ? "/Repositorio Contable":'/Repositorio Contable/' + this.Usr.id_usuario + '-' + this.Usr.nombre_completo);
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = (ruta) => {
		this.Listar(ruta);
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	
	Listar = () => {
		RepositorioContableService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			
			let listar_repositorio = response.data;
			
			this.setState({
				listar_repositorio: listar_repositorio,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			
		}).catch((error) => {
			
			this.setState({listar_repositorio: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	delete = (item) => {
		
		let msg = `¿Deseas eliminar la factura?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				AccesoService.Eliminar(item.id_facturacion).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	exportar = (item, archivo, auth) => {
		if (item.id_facturacion > 0) {
			let tipo = 2;
			HttpRequest.export('cxid', ['facturacion'], {
				id_facturacion: item.id_facturacion,
				tipo: tipo,
			}, !(tipo === 3))
		} else {
			let fecha_alta = (this.state.filtro.fecha_alta || null) ? DateFormat.FormatSql(this.state.filtro.fecha_alta) : null;
			
			let filtro = {
				id_facturacion: this.state.filtro.id_facturacion || null,
				cliente: this.state.filtro.cliente || null,
				id_cat_forma_pago: this.state.filtro.id_cat_forma_pago || null,
				id_cat_metodo_pago: this.state.filtro.id_cat_metodo_pago || null,
				fecha_alta: fecha_alta,
				ordenar_fecha_alta: this.state.filtro.ordenar_fecha_alta || null,
				
			};
			HttpRequest.export('c', ['facturacion'], {
				auth: auth,
				archivo: archivo,
				filtro: filtro
			}, false, false, false, archivo === "EXCEL")
		}
	};
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	ShowSubTabla = (item, key) => {
		let expandir = !!!item.expandir;
		this.handleChange({
			target: {
				value: expandir,
				name: 'expandir__' + key,
				type: 'text'
			}
		}, 'listar_repositorio', 'expandir');
		let ids_temp = this.state.ids_expandido;
		let ids_expandido = [];
		if (expandir) {
			ids_expandido = ids_temp;
			ids_expandido.push({
				id_facturacion: item.id_facturacion
			});
		} else {
			for (let i = 0; i < ids_temp.length; i++) {
				if (Number(ids_temp[i].id_facturacion) !== Number(item.id_facturacion)) {
					ids_expandido.push({
						id_facturacion: ids_temp[i].id_facturacion
					})
				}
			}
			
		}
		ReactLocalStorageService.set('ids_expandido', ids_expandido);
		this.setState({
			ids_expandido: ids_expandido
		});
	};
	
	SubTabla = (item, key) => {
		this.ShowSubTabla(item, key);
	};
	
	descargarZip = (path) => {
		//path = CONFIG.src + path;
		// let path = this.state.ficha_tecnica_expediente_trabajador[1].archivo[0].archivo;
		console.log("PATH ::: ", path);
		DropboxApi.GenerateZip(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				<Grid container direction="row" justify="space-between" alignItems="center" spacing={2}
				      style={{marginTop: 20, marginBottom: 20}}>
					<Grid item>
						<div className={'vertical-inline w-100'}>
							<Typography variant={'h5'} className={'v-center text-left cursor-pointer'} onClick={() => this.Listar()}>
								Repositorio Contable
							</Typography>
							{this.state.routes.map((item, key) => (
								<p key={key} className={'v-center margin-0 cursor-pointer'} style={{color: 'rgb(142, 30, 50)'}} onClick={() => this.Listar(item.path_display, item.name)}>/{item.name}</p>
							))}
						</div>
					</Grid>
					<Grid item>
						<Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
							{/*<Grid item>
								<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#8e1e32"}} onClick={() => this.Listar()}>
									<CloudUpload style={{fontSize: 14, marginRight: 5}}/> Subir Archivo
								</Fab>
							</Grid>*/}
							<Grid item>
								<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#3f50b6"}} onClick={() => this.Listar(this.state.ruta)}>
									<Sync style={{fontSize: 14, marginRight: 5}}/> Actualizar
								</Fab>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				
				{/*<FiltrosRepositorioContable
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				*/}
				<div className={'form margin-30-B'}>
					
					{this.state.listar_repositorio.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_repositorio}
								ruta={this.state.ruta}
								delete={this.delete}
								filtros={this.state.filtro}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
								SubTabla={this.SubTabla}
								openFolder={this.Listar}
								descargarZip={this.descargarZip}
							/>
							{/*<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>*/}
						</Fragment>
					) : (
						<VistaVacia
							numero={null}
							mensaje={'No se encontraron datos.'}
							image={usuario_vacio}
						/>
					)}
				
				</div>
			</div>
		);
	}
}

RepositorioContableListar.propTypes = {};

export default IntegrationNotistack(RepositorioContableListar);
