import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const LineaCapturaService = {
	Listar: (filtro, paginacion) => {
		let fecha_alta = (filtro.fecha_alta || null) ? DateFormat.FormatSql(filtro.fecha_alta) : null;
		
		let params = {
			filtro: {
				id_linea_captura: filtro.id_linea_captura || null,
				usuario: filtro.usuario || null,
				id_empresa: filtro.id_empresa || null,
				ejercicio: filtro.ejercicio || null,
				periodo: filtro.periodo || null,
				id_cat_tipo_impuesto: filtro.id_cat_tipo_impuesto || null,
				id_cat_tipo_declaracion: filtro.id_cat_tipo_declaracion || null,
				estatus: filtro.estatus || null,
				order_estatus: filtro.order_estatus || null,
				estatus_pago: filtro.estatus_pago || null,
				fecha_alta: fecha_alta || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Linea_Captura_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form, forzado) => {
		let fecha_vigencia = form.fecha_vigencia ? DateFormat.FormatSql(form.fecha_vigencia) : null;
		
		let params = {
			id_linea_captura: null,
			id_empresa: form.id_empresa,
			ejercicio: form.ejercicio,
			periodo: form.periodo,
			id_cat_tipo_impuesto: form.id_cat_tipo_impuesto,
			id_cat_tipo_declaracion: form.id_cat_tipo_declaracion,
			fecha_vigencia: fecha_vigencia,
			observaciones: form.observaciones,
			importe: form.importe,
			recargo: form.recargo,
			id_cat_linea_captura_impuesto: form.id_cat_linea_captura_impuesto,
			archivos: form.archivos,
			archivo: form.archivo,
			archivo_formato: form.archivo_formato,
			archivo_nombre: form.archivo_nombre,
			impuestos: form.impuestos,
			forzado: forzado ? 1 : 0,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Linea_Captura_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_vigencia = form.fecha_vigencia ? DateFormat.FormatSql(form.fecha_vigencia) : null;
		
		let params = {
			id_linea_captura: form.id_linea_captura,
			id_empresa: form.id_empresa,
			ejercicio: form.ejercicio,
			periodo: form.periodo,
			id_cat_tipo_impuesto: form.id_cat_tipo_impuesto,
			id_cat_tipo_declaracion: form.id_cat_tipo_declaracion,
			fecha_vigencia: fecha_vigencia,
			observaciones: form.observaciones,
			importe: form.importe,
			recargo: form.recargo,
			id_cat_linea_captura_impuesto: form.id_cat_linea_captura_impuesto,
			archivos: form.archivos,
			impuestos: form.impuestos,
			activo: form.activo ? 1 : 0,
		};
		
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Linea_Captura_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_linea_captura) => {
		let params = {
			id_linea_captura: id_linea_captura
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Linea_Captura_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	EliminarAcuse: (id_linea_captura) => {
		let params = {
			id_linea_captura: id_linea_captura
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Linea_Captura_Eliminar_Acuse', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	MotivoCancelacion: (form) => {
		let params = {
			id_linea_captura: form.id_linea_captura,
			id_cat_linea_captura_motivo_cancelacion: form.id_cat_linea_captura_motivo_cancelacion,
			observaciones: form.observaciones
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Linea_Captura_Motivo_Cancelacion', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ComprobantePago: (form) => {
		let params = {
			id_linea_captura: form.id_linea_captura,
			observaciones: form.observaciones,
			comprobante_pago: form.comprobante_pago || 0,
			archivos: form.archivos,
			archivo: form.archivo,
			archivo_formato: form.archivo_formato,
			archivo_nombre: form.archivo_nombre,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Linea_Captura_Comprobante_Pago', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SendMail: (form) => {
		let params = {
			id_linea_captura: form.id_linea_captura,
			id_usuario: form.id_usuario,
			usuarios_notificar: form.usuarios_notificar,
			mensaje: form.mensaje,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Linea_Captura_SendRocketChat', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarDownload: (filtro) => {
		console.log('FILTRO', filtro);
		let params = {
			filtro: {
				id_empresa: filtro.id_empresa,
				ejercicio: filtro.ejercicio,
				periodo: filtro.periodo,
				id_cat_tipo_impuesto: filtro.id_cat_tipo_impuesto,
				id_cat_tipo_declaracion: filtro.id_cat_tipo_declaracion,
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Linea_Captura_Donwload', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
