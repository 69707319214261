import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const EmpresaSucursalService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Empresa_Sucursal_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			nombre: form.nombre,
			direccion: form.direccion,
			abreviacion: form.abreviacion,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Empresa_Sucursal_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_empresa_sucursal: form.id_cat_empresa_sucursal,
			nombre: form.nombre,
			direccion: form.direccion,
			abreviacion: form.abreviacion,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Empresa_Sucursal_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_empresa_sucursal) => {
		let params = {
			id_cat_empresa_sucursal: id_cat_empresa_sucursal
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Empresa_Sucursal_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
