import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import ModalQuiniela from "./ModalQuiniela";
import {CONFIG} from "../../../../settings/Config/Config";
import {Ballot, GetApp, Group, PeopleAlt} from "@material-ui/icons";
import {AdquisicionService} from "../../../../services/AdquisicionService/AdquisicionService";
import {FieldsJs} from "../../../../settings/General/General";
import {BotonExportarListaExcel} from "../../../Include/MiniComponents/GlobalComponent";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import IconButton from "@material-ui/core/IconButton/index";
import $State from "../../../../settings/$State/$State";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar la adquisición: ${item.nombre}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				AdquisicionService.Eliminar(item.id_adquisicion).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	stateGo = (item) => {
		$State.go(this.props, 'verquiniela', {id_quiniela: item.id_quiniela});
	};
	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'}>ID</TableCell>
							<TableCell component="th" align={'left'}>Nombre</TableCell>
							<TableCell component="th" align={'center'}>Fecha inicio</TableCell>
							<TableCell component="th" align={'center'}>Fecha fin</TableCell>
							<TableCell component="th" align={'left'}>Responsable</TableCell>
							<TableCell component="th" align={'left'}>Celular</TableCell>
							<TableCell component="th" align={'left'}>Correo Electrónico</TableCell>
							<TableCell component="th" align={'left'}>Saldo</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<TableRow key={key}>
								<TableCell align={'center'}>{item.id_quiniela}</TableCell>
								<TableCell align={'left'}>{item.nombre}</TableCell>
								<TableCell align={'center'}>{item.fecha_inicio ? DateFormat.getDateToLocaleDateString(item.fecha_inicio) : 'Sin registrar'}</TableCell>
								<TableCell align={'center'}>{item.fecha_fin ? DateFormat.getDateToLocaleDateString(item.fecha_fin) : 'Sin registrar'}</TableCell>
								<TableCell align={'left'}>{item.responsable || 'Sin registrar'}</TableCell>
								<TableCell align={'left'}>{item.celular || 'Sin registrar'}</TableCell>
								<TableCell align={'left'}>{item.correo_electronico || 'Sin registrar'}</TableCell>
								<TableCell align={'left'}><strong>${FieldsJs.Currency(item.saldo_total)}</strong></TableCell>
								<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
									<div className={'w-auto vertical-inline'}>
										
										{/*{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (
											<div className={'v-center'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Edo Cta General">
													<IconButton aria-label="Edo Cta General" onClick={() => this.props.exportar(item, 'EXCEL', true)}>
														<GetApp/>
													</IconButton>
												</Tooltip>
											</div>
										) : ''}
										
										<div className={'v-center'}>
											<ModalPagos
												tipo={'add'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>*/}
										
										{/*<div className={'v-center'}>
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
												<IconButton aria-label="Eliminar"
												            onClick={() => this.eliminar(item)}>
													<DeleteOutlined/>
												</IconButton>
											</Tooltip>
										</div>*/}
										
										<div className={'v-center'}>
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Miembros">
												<IconButton aria-label="Miembros" onClick={() => this.stateGo(item)}>
													<Ballot/>
												</IconButton>
											</Tooltip>
										</div>
										
										
										{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) || Number(this.Usr.id_usuario) === Number(item.id_usuario) ? (
											<Fragment>
												<div className={'v-center'}>
													<ModalQuiniela
														tipo={'edit'}
														item={item}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
												
												<div className={'v-center'}>
													<ModalQuiniela
														tipo={'view'}
														item={item}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
											</Fragment>
										):null}
										
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	exportar: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(ListaTabla));
