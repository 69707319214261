import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {ResguardoService} from '../../../../services/ResguardoService/ResguardoService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";
import ModalEmpresa from "../../Cat/Empresa/Includes/ModalEmpresa";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import ModalRecordatorios from "../../Reccordatorios/Includes/ModalRecordatorios";
import {AddOutlined, Business, CancelOutlined, DeleteOutlined, Group, SaveOutlined, SpeakerNotes, Sync} from "@material-ui/icons";
import {DropzoneArea} from "material-ui-dropzone";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ModalProyecto from "../../Cat/Proyecto/Includes/ModalProyecto";
import ModalModulo from "../../Cat/Modulo/Includes/ModalModulo";
import {ProyectoService} from "../../../../services/_Cat/ProyectoService/ProyectoService";
import {ModuloService} from "../../../../services/_Cat/ModuloService/ModuloService";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {TicketsService} from "../../../../services/TicketsService/TicketsService";
import DocSvg from "../../../../assets/img/icons/doc.svg";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import FormGroup from "@material-ui/core/FormGroup";
import {CONFIG} from "../../../../settings/Config/Config";
import ModalEquipo from "../../Cat/Equipo/Includes/ModalEquipo";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import carrito_de_compra_3 from "../../../../assets/img/icons/carro-de-la-compra-3.svg";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import moment from "moment";


class ModalResguardo extends Component {
	
	Usr = {};
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_resguardo: (props.item || '').id_resguardo,
			tipo: props.tipo,
			id_empresa: '',
			id_usuario: '',
			puesto: '',
			id_cat_equipo: '',
			cantidad: '',
			marca: '',
			modelo: '',
			serie: '',
			color: '',
			id_recibio_recepcion: '',
			id_estado_recepcion: '',
			direccion_recepcion: '',
			id_estado_actual: '',
			direccion_actual: '',
			procesador: '',
			ram: '',
			almacenamiento: '',
			accesorios: '',
			observaciones: '',
			
			software: '',
			software_marca: '',
			software_version: '',
			fecha_recepcion: null,
			
			activo: true,
			files: [],
			archivos: [],
			
			
			lista_empresas: [],
			lista_usuarios: [],
			lista_cat_equipo: [],
			lista_cat_estado_equipo: [],
			lista_software: [],
		};
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	
	ListUsuarios = () => {
		return new Promise((resolve, reject) => {
			CatService.ListUsuario().then((response) => {
				let usuarios = [];
				let notificar = [];
				if (!FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario)) {
					for (let i = 0; i < response.data.length; i++) {
						console.log(Number(this.Usr.id_cat_tipo_usuario) === Number(response.data[i].id_cat_tipo_usuario), Number(this.Usr.id_cat_tipo_usuario), response.data[i]);
						if (Number(this.Usr.id_cat_tipo_usuario) === Number(response.data[i].id_cat_tipo_usuario)) {
							usuarios.push(response.data[i]);
						}
						if (Number(this.Usr.id_cat_tipo_usuario) === Number(response.data[i].id_cat_tipo_usuario) || Number(response.data[i].id_cat_tipo_usuario) === 13) {
							notificar.push(response.data[i]);
						}
					}
				} else {
					usuarios = response.data;
					notificar = response.data;
				}
				this.setState({
					lista_usuarios: usuarios,
					lista_usuario_autorizado: notificar
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_usuarios: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListEmpresas = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpresa().then((response) => {
				this.setState({
					lista_empresas: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_empresas: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListEstadoEquipo = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEstadoEquipo().then((response) => {
				this.setState({
					lista_cat_estado_equipo: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_cat_estado_equipo: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListEquipo = (id_cat_equipo) => {
		return new Promise((resolve, reject) => {
			CatService.ListEquipo().then((response) => {
				if (id_cat_equipo > 0 && FieldsJs.Field(id_cat_equipo)) {
					this.setState({
						lista_cat_equipo: response.data,
						id_cat_equipo: id_cat_equipo
					});
				} else {
					this.setState({
						lista_cat_equipo: response.data,
					});
				}
				resolve(response);
			}).catch((error) => {
				this.setState({lista_cat_equipo: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	RefreshListProyecto = (id_cat_proyecto) => {
		this.ListProyectos();
		this.setState({
			id_cat_proyecto: id_cat_proyecto
		});
	};
	
	RefreshListProyectoModulo = (id_cat_proyecto, id_cat_proyecto_modulo) => {
		this.ListProyectoModulos(id_cat_proyecto);
		this.setState({
			id_cat_proyecto: id_cat_proyecto,
			id_cat_proyecto_modulo: id_cat_proyecto_modulo
		});
	};
	
	guardarDocTimbradoDbx = () => {
		
		let files = this.state.files;
		
		return new Promise((resolve, reject) => {
			let band = files.length;
			let band2 = 0;
			let archives = this.state.archivos;
			let file;
			
			for (let i = 0; i < files.length; i++) {
				console.log("2 FTAR::: ", files);
				file = files[i];
				
				let ruta = '/Resguardo/' + this.Usr.id_usuario + '-' + this.Usr.nombre_completo + '/' + file.name;
				
				DropboxApi.Upload(ruta, file).then(function (response) {
					archives.push({archivo: response.path_display});
					
					band2++;
					
					console.log("DROPBOX UPLOAD: ", response, band, band2);
					
					if (band === band2) {
						resolve(archives);
					}
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	save = () => {
		
		try {
			
			if (!FieldsJs.Field(this.state.id_empresa)) {
				throw Object({
					status: false, mensaje: 'Campo Centro de trabajo / Empresa es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.id_cat_equipo)) {
				throw Object({
					status: false, mensaje: 'Campo Puesto es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.id_cat_equipo)) {
				throw Object({
					status: false, mensaje: 'Campo Equipo es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.cantidad)) {
				throw Object({
					status: false, mensaje: 'Campo Cantidad es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.marca)) {
				throw Object({
					status: false, mensaje: 'Campo Marca es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.modelo)) {
				throw Object({
					status: false, mensaje: 'Campo Modelo es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.serie)) {
				throw Object({
					status: false, mensaje: 'Campo Serie es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.color)) {
				throw Object({
					status: false, mensaje: 'Campo Color es requerido.'
				})
			}
			
			if (this.state.files.length > 0) {
				
				let guardar = new Promise((resolve, reject) => {
					showSpinner('spinner');
					this.guardarDocTimbradoDbx(this.state.files).then(response => {
						this.setState({
							archivos: response,
						});
						resolve(response);
						hideSpinner('spinner', 500);
						console.log("DROPBOX UPLOAD: ", this.state);
					}).catch(error => {
						hideSpinner('spinner', 500);
						reject(error);
					})
				});
				
				guardar.then(response => {
					if (this.state.id_resguardo > 0) {
						ResguardoService.Modificar(this.state).then((response) => {
							this.props.RefreshList();
							this.modalClose();
							this.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.showSnackBars('error', error.mensaje);
						});
					} else {
						ResguardoService.Agregar(this.state).then((response) => {
							this.props.RefreshList();
							this.modalClose();
							this.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.showSnackBars('error', error.mensaje);
						});
					}
				});
				
			} else {
				if (this.state.id_resguardo > 0) {
					ResguardoService.Modificar(this.state).then((response) => {
						this.props.RefreshList();
						this.modalClose();
						this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
					});
				} else {
					ResguardoService.Agregar(this.state).then((response) => {
						this.props.RefreshList();
						this.modalClose();
						this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
					});
				}
			}
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		
		this.setState({
			id_resguardo: '',
			id_empresa: '',
			puesto: '',
			id_cat_equipo: '',
			cantidad: '',
			marca: '',
			modelo: '',
			serie: '',
			color: '',
			id_recibio_recepcion: '',
			id_estado_recepcion: '',
			direccion_recepcion: '',
			id_estado_actual: '',
			direccion_actual: '',
			procesador: '',
			ram: '',
			almacenamiento: '',
			accesorios: '',
			observaciones: '',
			fecha_recepcion: null,
			files: [],
			archivos: [],
			lista_software: [],
			activo: true,
		});
		
		this.ListEmpresas();
		this.ListUsuarios();
		this.ListEquipo();
		this.ListEstadoEquipo();
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		/*this.ListProyectos().then(() => {
			this.setState({
				id_cat_proyecto: item.id_cat_proyecto || '',
			});
			this.ListProyectoModulos(item.id_cat_proyecto).then(() => {
				this.setState({
					id_cat_proyecto_modulo: item.id_cat_proyecto_modulo || '',
				});
			});
		});*/
		
		
		this.ListEmpresas().then(() => {
			this.setState({
				id_empresa: item.id_empresa || '',
			});
		});
		this.ListUsuarios().then(() => {
			this.setState({
				id_usuario: item.id_usuario || '',
			});
		});
		this.ListEquipo().then(() => {
			this.setState({
				id_cat_equipo: item.id_cat_equipo || '',
			});
		});
		this.ListEstadoEquipo().then(() => {
			this.setState({
				id_estado_recepcion: item.id_estado_recepcion || '',
				id_estado_actual: item.id_estado_actual || '',
			});
		});
		
		this.setState({
			id_resguardo: item.id_resguardo || '',
			puesto: item.puesto || '',
			cantidad: item.cantidad || '',
			marca: item.marca || '',
			modelo: item.modelo || '',
			serie: item.serie || '',
			color: item.color || '',
			id_recibio_recepcion: item.id_recibio_recepcion || '',
			direccion_recepcion: item.direccion_recepcion || '',
			direccion_actual: item.direccion_actual || '',
			procesador: item.procesador || '',
			ram: item.ram || '',
			almacenamiento: item.almacenamiento || '',
			accesorios: item.accesorios || '',
			observaciones: item.observaciones || '',
			fecha_recepcion: item.fecha_recepcion ? moment(item.fecha_recepcion) : null,
			//files: [],
			archivos: item.archivos || [],
			lista_software: item.lista_software || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		/*this.ListProyectos().then(() => {
			this.setState({
				id_cat_proyecto: item.id_cat_proyecto || '',
			});
			this.ListProyectoModulos(item.id_cat_proyecto).then(() => {
				this.setState({
					id_cat_proyecto_modulo: item.id_cat_proyecto_modulo || '',
				});
			});
		});*/
		
		this.ListEmpresas().then(() => {
			this.setState({
				id_empresa: item.id_empresa || '',
			});
		});
		this.ListUsuarios().then(() => {
			this.setState({
				id_usuario: item.id_usuario || '',
			});
		});
		this.ListEquipo().then(() => {
			this.setState({
				id_cat_equipo: item.id_cat_equipo || '',
			});
		});
		this.ListEstadoEquipo().then(() => {
			this.setState({
				id_estado_recepcion: item.id_estado_recepcion || '',
				id_estado_actual: item.id_estado_actual || '',
			});
		});
		
		this.setState({
			id_resguardo: item.id_resguardo || '',
			puesto: item.puesto || '',
			
			cantidad: item.cantidad || '',
			marca: item.marca || '',
			modelo: item.modelo || '',
			serie: item.serie || '',
			color: item.color || '',
			id_recibio_recepcion: item.id_recibio_recepcion || '',
			direccion_recepcion: item.direccion_recepcion || '',
			direccion_actual: item.direccion_actual || '',
			procesador: item.procesador || '',
			ram: item.ram || '',
			almacenamiento: item.almacenamiento || '',
			accesorios: item.accesorios || '',
			observaciones: item.observaciones || '',
			fecha_recepcion: item.fecha_recepcion ? moment(item.fecha_recepcion) : null,
			//files: [],
			archivos: item.archivos || [],
			lista_software: item.lista_software || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_resguardo > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar" className={!FieldsJs.Field(this.props.item.id_empresa) || !FieldsJs.Field(this.props.item.fecha_recepcion) ? 'animate__animated animate__bounce animate__delay-2s animate__infinite infinite':''}>
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	descargarZip = (path) => {
		console.log("PATH ::: ", path);
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
	};
	
	asignarUsuarioReporte = (id_usuario, id_usuario_autorizado) => {
		let usuarios = FieldsJs.Copy(this.state.lista_usuario_autorizado);
		let users = FieldsJs.Copy(this.state.usuarios_notificar);
		let banduser = false;
		let userkey = 0;
		for (let i = 0; i < users.length; i++) {
			if (Number(users[i].id_usuario) === Number(id_usuario)) {
				banduser = true;
				userkey = i;
			}
		}
		if (!banduser) {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = true;
					if (id_usuario_autorizado) {
						usuarios[i].id_usuario = id_usuario_autorizado;
					}
					users.push(usuarios[i]);
				}
			}
		} else {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = false;
				}
			}
			users.splice(userkey, 1);
		}
		this.setState({
			lista_usuario_autorizado: usuarios,
			usuarios_notificar: users,
		});
		console.log("ASIGNARUSUARIO", users, usuarios, this.state.usuarios_notificar);
	};
	
	is_edited_software = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	
	edit_software = (value, key) => {
		try {
			
			this.setState({
				key: key,
				software: value.software,
				software_marca: value.software_marca,
				software_version: value.software_version,
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	
	edit_cancel = () => {
		this.setState({
			key: null,
			software: '',
			software_marca: '',
			software_version: '',
		});
	};
	
	add_software = () => {
		
		try {
			
			if (!this.state.software) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Software.'
				})
			}
			
			let lista_software = FieldsJs.Copy(this.state.lista_software || []);
			
			if (this.is_edited_software()) {
				for (let i = 0; i < lista_software.length; i++) {
					let item = lista_software[i];
					if (this.state.key === i) {
						item.software = this.state.software || '';
						item.software_marca = this.state.software_marca || '';
						item.software_version = this.state.software_version || '';
					}
				}
			} else {
				let item;
				item = {
					software: this.state.software || '',
					software_marca: this.state.software_marca || '',
					software_version: this.state.software_version || '',
				};
				lista_software.push(item);
			}
			
			this.setState({
				key: null,
				software: '',
				software_marca: '',
				software_version: '',
				lista_software: lista_software
			});
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el software?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_software = FieldsJs.Copy(this.state.lista_software);
					
					lista_software.splice(key, 1);
					
					this.setState({
						lista_software: lista_software
					});
					
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Resguardo</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormControl fullWidth required>
									<InputLabel>Centro de trabajo / Empresa:</InputLabel>
									<Select
										native
										value={this.state.id_empresa}
										onChange={this.handleChange}
										name="id_empresa"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.state.lista_empresas.map(option => (
											<option key={option.id_empresa}
											        value={option.id_empresa}>
												{option.nombre_comercial}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									fullWidth
									required
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									name="puesto"
									label="Puesto"
									value={this.state.puesto}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<div className={'row-flex'}>
									<div className={'w-50-px v-end'}>
										<ModalEquipo
											tipo={'add'}
											item={{}}
											componente={
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Agregar Equipo">
													<Fab size="small" color="primary" aria-label="Agregar Equipo">
														<Add/>
													</Fab>
												</Tooltip>
											}
											RefreshList={this.ListEquipo}
											showSnackBars={this.props.showSnackBars}
										/>
									</div>
									<div className={'w-100-50-px padding-10-R'}>
										<FormControl fullWidth required>
											<InputLabel>Equipo</InputLabel>
											<Select
												native
												value={this.state.id_cat_equipo}
												onChange={this.handleChange}
												name="id_cat_equipo"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_cat_equipo.map(option => (
													<option key={option.id_cat_equipo}
													        value={option.id_cat_equipo}>
														{option.equipo}
													</option>
												))}
											</Select>
										</FormControl>
									</div>
								</div>
							</Grid>
							
							{/*<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<TextField
									fullWidth
									required
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="otro"
									label="Otro equipo"
									value={this.state.otro}
								/>
							</Grid>*/}
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<TextField
									className={'margin-0'}
									margin="dense"
									type={'text'}
									fullWidth
									required
									name="cantidad"
									onChange={this.handleChange}
									label="Cantidad"
									autoComplete={'off'}
									value={this.state.cantidad}
									onKeyPress={EnteroSolo}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<TextField
									fullWidth
									required
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="marca"
									label="Marca"
									value={this.state.marca}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<TextField
									fullWidth
									required
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="modelo"
									label="Modelo"
									value={this.state.modelo}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<TextField
									fullWidth
									required
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="serie"
									label="Número de serie"
									value={this.state.serie}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<TextField
									fullWidth
									required
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="color"
									label="Color"
									value={this.state.color}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="procesador"
									label="Procesador"
									value={this.state.procesador}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="ram"
									label="Memoria RAM"
									value={this.state.ram}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="almacenamiento"
									label="Almacenamiento"
									value={this.state.almacenamiento}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="accesorios"
									label="Accesorio(s)"
									value={this.state.accesorios}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<fieldset style={{border: '1px solid lightgray', padding: '11px'}}>
									<legend style={{fontWeight: 'bold', fontSize: '14px'}}>Software instalado de acuerdo al perfil de usuario:</legend>
									<Grid container spacing={2} alignItems="flex-end">
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<TextField
												fullWidth
												onChange={this.handleChange}
												disabled={this.state.tipo === 'view'}
												type="text"
												className={'margin-0'}
												name="software"
												label="Software"
												value={this.state.software}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
											<TextField
												fullWidth
												onChange={this.handleChange}
												disabled={this.state.tipo === 'view'}
												type="text"
												className={'margin-0'}
												name="software_marca"
												label="Marca"
												value={this.state.software_marca}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
											<TextField
												fullWidth
												onChange={this.handleChange}
												disabled={this.state.tipo === 'view'}
												type="text"
												className={'margin-0'}
												name="software_version"
												label="Versión"
												value={this.state.software_version}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={2} lg={2} xl={2} align={'center'}>
											<Tooltip
												TransitionComponent={Zoom}
												placement={"top"}
												title="Cancelar edición"
												children={(
													<span>
														<Fab size="small" color="secondary"
														     aria-label="Cancelar edición"
														     onClick={() => this.edit_cancel()}
														     style={{marginLeft: "15px"}}
														     disabled={!this.is_edited_software()}>
																	<CancelOutlined/>
																</Fab>
															</span>
												)}
											/>
											<Tooltip
												TransitionComponent={Zoom}
												placement={"top"}
												title={this.is_edited_software() ? "Actualizar" : "Agregar"}
												children={(
													<Fab size="small" color="secondary"
													     aria-label={this.is_edited_software() ? "Actualizar" : "Agregar"}
													     onClick={() => this.add_software()}
													     style={{marginLeft: "15px"}}>
														{this.is_edited_software() ? (
															<SaveOutlined/>
														) : (
															<AddOutlined/>
														)}
													</Fab>
												)}
											/>
										</Grid>
										
										{this.state.lista_software.length > 0 ? (
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												
												<table className={'desing-mark'}>
													<thead>
													<tr>
														<th>Software</th>
														<th>Marca</th>
														<th>Versión</th>
														{!(this.state.tipo === 'view') ? (
															<th align={'right'} width={'110px'}>Acciones</th>
														) : null}
													</tr>
													</thead>
													<tbody>
													{this.state.lista_software.map((value, key) => (
														<tr key={key}>
															<td>{value.software}</td>
															<td>{value.software_marca}</td>
															<td>{value.software_version}</td>
															{!(this.state.tipo === 'view') ? (
																<td align={'right'}>
																	<Tooltip
																		TransitionComponent={Zoom}
																		placement={"top"}
																		title="Eliminar"
																		children={(
																			<Fab
																				size="small"
																				aria-label="Eliminar"
																				onClick={() => this.delete(value, key)}
																				style={{
																					marginLeft: "10px",
																					backgroundColor: "transparent",
																					color: "#808080",
																					boxShadow: "none",
																				}}
																				children={(
																					<DeleteOutlined/>
																				)}
																			/>
																		)}
																	/>
																	{this.state.key === key ? (
																		<Tooltip
																			TransitionComponent={Zoom}
																			placement={"top"}
																			title="Software en edición"
																			children={(
																				<Fab
																					size="small"
																					aria-label="Editar"
																					onClick={() => this.edit_software(value, key)}
																					style={{
																						marginLeft: "10px",
																						backgroundColor: "#f50057",
																						color: "white",
																					}}
																					children={(
																						<EditOutlined/>
																					)}
																				/>
																			)}
																		/>
																	) : (
																		<Tooltip
																			TransitionComponent={Zoom}
																			placement={"top"}
																			title="Editar software"
																			children={(
																				<Fab
																					size="small"
																					aria-label="Editar"
																					onClick={() => this.edit_software(value, key)}
																					style={{
																						marginLeft: "10px",
																						backgroundColor: "transparent",
																						color: "#808080",
																						boxShadow: "none",
																					}}
																					children={(
																						<EditOutlined/>
																					)}
																				/>
																			)}
																		/>
																	)}
																</td>
															) : null}
														</tr>
													))}
													</tbody>
												</table>
											</Grid>
										) : null}
									</Grid>
								</fieldset>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
								<fieldset style={{border: '1px solid lightgray'}}>
									<legend style={{fontWeight: 'bold', fontSize: '14px'}}>Recepción del equipo:</legend>
									<Grid container spacing={2}>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
											<KeyboardDatePicker
												format="dd/MM/yyyy"
												fullWidth
												required
												clearable
												inputProps={{readOnly: true}}
												/*KeyboardButtonProps={{
													'aria-label': 'Fecha de pago',
												}}*/
												label="Fecha de recepción"
												value={this.state.fecha_recepcion}
												onChange={(date) => {
													this.handleChange(null, null, null, date, 'fecha_recepcion');
												}}
												// minDate={moment(new Date())}
												clearLabel={'Limpiar'}
												okLabel={'Aceptar'}
												cancelLabel={'Cancelar'}
												onError={console.log}
												disabled={this.props.tipo === 'view'}
											/>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
											<FormControl fullWidth required>
												<InputLabel>Lo recibió:</InputLabel>
												<Select
													native
													value={this.state.id_recibio_recepcion}
													onChange={this.handleChange}
													name="id_recibio_recepcion"
													input={<Input/>}
													disabled={this.props.tipo === 'view'}
												>
													<option value=""/>
													<option value={"1"}> {"Nuevo"} </option>
													<option value={"2"}> {"De Uso"} </option>
												</Select>
											</FormControl>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
											<FormControl fullWidth required>
												<InputLabel>Estado del equipo:</InputLabel>
												<Select
													native
													value={this.state.id_estado_recepcion}
													onChange={this.handleChange}
													name="id_estado_recepcion"
													input={<Input/>}
													disabled={this.props.tipo === 'view'}
												>
													<option value=""/>
													{this.state.lista_cat_estado_equipo.map(option => (
														<option key={option.id_cat_estado_equipo}
														        value={option.id_cat_estado_equipo}>
															{option.estado_equipo}
														</option>
													))}
												</Select>
											</FormControl>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<TextField
												fullWidth
												required
												onChange={this.handleChange}
												disabled={this.state.tipo === 'view'}
												type="text"
												className={'margin-0'}
												name="direccion_recepcion"
												label="Dirección"
												value={this.state.direccion_recepcion}
											/>
										</Grid>
									</Grid>
								</fieldset>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<fieldset style={{border: '1px solid lightgray', padding: '11px'}}>
									<legend style={{fontWeight: 'bold', fontSize: '14px'}}>Dirección actual del equipo:</legend>
									<Grid container spacing={2}>
										
										{/*<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
											<FormControl fullWidth required>
												<InputLabel>Estado del equipo:</InputLabel>
												<Select
													native
													value={this.state.id_estado_actual}
													onChange={this.handleChange}
													name="id_estado_actual"
													input={<Input/>}
													disabled={this.props.tipo === 'view'}
												>
													<option value=""/>
													{this.state.lista_cat_estado_equipo.map(option => (
														<option key={option.id_cat_estado_equipo}
														        value={option.id_cat_estado_equipo}>
															{option.estado_equipo}
														</option>
													))}
												</Select>
											</FormControl>
										</Grid>*/}
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField
												fullWidth
												required
												multiline
												rows={4}
												onChange={this.handleChange}
												disabled={this.state.tipo === 'view'}
												type="text"
												className={'margin-0'}
												name="direccion_actual"
												label="Dirección"
												value={this.state.direccion_actual}
											/>
										</Grid>
									</Grid>
								</fieldset>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									fullWidth
									multiline
									rows={2}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="observaciones"
									label="Observaciones"
									value={this.state.observaciones}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
									Anexos:
								</Typography>
								<div className={'vertical-inline'}>
									{this.state.archivos.length > 0 ? (
										<Fragment>
											{this.state.archivos.map((item, key) => (
												<div>
													<div className={'vertical-inline b-r-20 margin-5 margin-0-L'} key={key}>
														{FieldsJs.Field(item.thumb) ? (
															<img className={'v-center cursor-pointer'} src={`data:image/jpeg;base64,${item.thumb.thumbnail}`} alt="Archivo" width={70} height={70} style={{opacity: 0.7, marginRight: '5px'}}
															     onClick={() => this.descargarZip(item.archivo)}/>
														) : (
															<img className={'v-center cursor-pointer'} src={DocSvg} alt="Archivo" width={70} height={70} style={{opacity: 0.7, marginRight: '5px'}} onClick={() => this.descargarZip(item.archivo)}/>
														)}
														
														{this.props.tipo === 'edit' ? (<div className={'vertical-inline v-end b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
															<div className={'padding-3 v-end text-white cursor-pointer'}>
																{this.props.tipo === 'view' ? null : (
																	<DeleteOutlined className={'px-16 text-white'} onClick={() => this.eliminar_documento(key)}/>
																)
																}
															</div>
														</div>) : null}
													</div>
												</div>
											))}
										</Fragment>
									) : null}
								</div>
								
								{this.props.tipo !== 'view' ? (
									<div className={'w-100 dropzone-cronograma'}>
										<div className={'w-100'}>
											<DropzoneArea
												onChange={this.handleChangeDropzoneArea.bind(this)}
												open={this.state.open}
												filesLimit={3}
												dropzoneText={'Seleccione o arrastre la(s) foto(s) del equipo'}
												//acceptedFiles={['text/xml', 'application/pdf']}
												showPreviews={false}
												maxFileSize={5000000}
												showAlerts={false}
												showFileNames={true}
												showFileNamesInPreview={true}
											/>
										</div>
									</div>
								) : null}
							</Grid>
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_resguardo > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalResguardo.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalResguardo);
