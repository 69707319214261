import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import {AttachMoney, CancelPresentation, DeleteOutlined, EditOutlined, HdrStrong, SearchOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DocSvg from "../../../../assets/img/icons/doc.svg";
import {DropzoneArea} from "material-ui-dropzone";
import {LineaCapturaService} from "../../../../services/LineaCapturaService/LineaCapturaService";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {CatService} from "../../../../services/_Cat/CatService/CatService";


class ModalCancelar extends Component {
	
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_linea_captura_comprobante_pago: (props.item || '').id_linea_captura_comprobante_pago,
			id_linea_captura: (props.item || '').id_linea_captura,
			tipo: props.tipo,
			observaciones: '',
			cat_linea_captura_motivo_cancelacion: [],
			id_cat_linea_captura_motivo_cancelacion: ''
		};
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	
	guardarDocTimbradoDbx = () => {
		
		let files = this.state.files;
		
		return new Promise((resolve, reject) => {
			let band = files.length;
			let band2 = 0;
			let archives = this.state.archivos;
			let file;
			
			for (let i = 0; i < files.length; i++) {
				console.log("2 FTAR::: ", files);
				file = files[i];
				
				let ruta = '/Linea_Captura/Comprobantes/' + this.state.id_linea_captura + '_' + file.name;
				
				DropboxApi.Upload(ruta, file).then(function (response) {
					archives.push({archivo: response.path_display});
					
					band2++;
					
					console.log("DROPBOX UPLOAD: ", response, band, band2);
					
					if (band === band2) {
						resolve(archives);
					}
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	save = () => {
		
		try {
			
			/*if (!FieldsJs.Field(this.state.files) && this.state.files.length === 0) {
				throw Object({
					status: false, mensaje: 'No se ha seleccionado el archivo.'
				})
			}*/
			
			LineaCapturaService.MotivoCancelacion(this.state).then((response) => {
				this.props.RefreshList();
				this.modalClose();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	ListImpuesto = () => {
		return new Promise((resolve, reject) => {
			CatService.ListLineaCapturaMotivoCancelacion().then((response) => {
				this.setState({
					cat_linea_captura_motivo_cancelacion: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	add = () => {
		
		console.log(this.props);
		const {item} = this.props;
		
		this.ListImpuesto().then((response) => {
			this.setState({
				id_cat_linea_captura_motivo_cancelacion: item.id_cat_linea_captura_motivo_cancelacion || '',
			});
		});
		
		this.setState({
			id_linea_captura_comprobante_pago: item.id_linea_captura_comprobante_pago || '',
			id_linea_captura: item.id_linea_captura || '',
			observaciones: item.motivo_cancelacion || '',
			comprobante_pago: 1,
			archivos: [],
			activo: true,
		});
		
		this.openModal();
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_linea_captura_comprobante_pago: item.id_linea_captura_comprobante_pago || '',
			id_linea_captura: item.id_linea_captura || '',
			observaciones: item.observaciones || null,
			comprobante_pago: 1,
			archivos: item.archivos || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_linea_captura_comprobante_pago: item.id_linea_captura_comprobante_pago || '',
			id_linea_captura: item.id_linea_captura || '',
			observaciones: item.observaciones || null,
			comprobante_pago: 1,
			archivos: item.archivos || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_linea_captura_comprobante_pago > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Estatus">
						<IconButton aria-label="Estatus" onClick={() => this.edit()}>
							<HdrStrong/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Cancelar">
					<IconButton aria-label="Cancelar" onClick={() => this.add()} style={{color: Number(this.props.item.id_cat_linea_captura_motivo_cancelacion) > 0 ? '#3f51b5':'rgba(0, 0, 0, 0.54)'}}><CancelPresentation/></IconButton>
				</Tooltip>
			);
		}
		return BTN_ACTION;
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{'Agregar Motivo de Cancelación'}</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_linea_captura_motivo_cancelacion"
									label="Motivo de cancelación"
									value={this.state.id_cat_linea_captura_motivo_cancelacion}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.cat_linea_captura_motivo_cancelacion.map(option => (
										<option key={option.id_cat_linea_captura_motivo_cancelacion} value={option.id_cat_linea_captura_motivo_cancelacion}>
											{option.linea_captura_motivo_cancelacion}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									fullWidth
									multiline
									rows={3}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="observaciones"
									label="Observaciones"
									value={this.state.observaciones}
								/>
							</Grid>
						
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_linea_captura_comprobante_pago > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCancelar.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalCancelar);
