import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FieldsJs} from "../../../../settings/General/General";
import TextField from "@material-ui/core/TextField";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Divider from "@material-ui/core/Divider";
import {TicketsService} from "../../../../services/TicketsService/TicketsService";
import {Send} from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import icon_email from "../../../../assets/img/icons/email.svg";
import icon_rocket_chat from "../../../../assets/img/icons/rocketchat.svg";

class ModalSendMail extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			
			tipo: props.tipo,
			item: props.item,
			id_usuario_email: '',
			check_email: true,
			check_rocket_chat: true,
			text_email: '',
			solicitante: '',
			text_email_solicitante: '',
			ticket_folio: props.item.folio || '',
			id_usuario_solicitante: (props.item.requerimientos || '').id_usuario_solicitante,
			listar_requerimientos: props.item.requerimientos || [],
			
			asignados: [],
		};
	}
	
	openModal = () => {
		const {item} = this.props;
		console.log("ITEM:::", item);
		let asignados = [];
		for (let i = 0; i < item.requerimientos.length; i++) {
			let band = false;
			for (let x = 0; x < asignados; x++) {
				if (item.requerimientos[i].id_usuario_asignado === asignados[x].id_usuario_asignado) {
					band = true;
				}
			}
			
			if (!band) {
				asignados.push({id_usuario_asignado: item.requerimientos[i].id_usuario_asignado});
			}
		}
		
		
		this.setState({
			open: true,
			scroll: 'paper',
			item: item,
			id_usuario_email: asignados.length === 1 ? asignados[0].id_usuario_asignado : '',
			check_email: true,
			check_rocket_chat: true,
			text_email: '',
			text_email_solicitante: '',
			ticket_folio: item.folio || '',
			solicitante: item.nombre_completo_solicitante || '',
			id_usuario_solicitante: item.id_usuario_solicitante || '',
			listar_requerimientos: item.requerimientos || [],
			
			asignados: asignados
		});
	};
	
	closeModal = () => {
		this.setState({open: false});
	};
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	close = () => {
		this.closeModal();
	};
	
	save = () => {
		TicketsService.SendMail(this.state).then((response) => {
			this.close();
			//this.props.RefreshInit();
			this.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	saveSolicitante = () => {
		TicketsService.SendMail(this.state, true).then((response) => {
			this.close();
			//this.props.RefreshInit();
			this.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	render() {
		
		return (
			<span>
				
				{this.props.component ? (
					<Fragment>
						<span onClick={() => this.openModal('paper')} style={{cursor: 'pointer'}}>
							{this.props.component}
						</span>
					</Fragment>
				) : (
					<Fragment>
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={() => this.openModal('paper')}>
							<Send className={'margin-5-R px-14'}/>
							Enviar Notificación
						</Fab>
					</Fragment>
				)}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.closeModal}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal} className={'sendMessage-ticket'}
				>
					
					<DialogTitle style={{color: 'gray'}}>Enviar Notificación</DialogTitle>
					
					<DialogContent>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={2} className={'h-100'}>
									
									
									
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'text-center px-13'} style={{fontWeight: 'bold'}}>
											Seleccionar el tipo de notificación y el usuario a quien va dirigido el ticket
										</Typography>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={0}>
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												<div className={'borde-punteado-lightgray-1 card-0 text-center margin-10-R cursor-pointer'} onClick={() => {
													this.setState({
														check_email: !this.state.check_email
													})
												}}>
													<div className={'w-100 text-center padding-10-B padding-10-T'}>
														<span style={{cursor: 'pointer', fontSize: 14}}>
															Correo Electrónico
														</span>
													</div>
													<div className={'w-100 text-center check-notificacion'}>
														<div className={'w-100'}>
															<img className={'v-center'} src={icon_email} alt="add" width={80} height={80} style={{opacity: 0.7}}/>
														<FormGroup row className={'text-center'}>
															<FormControlLabel
																control={
																	<Checkbox type="checkbox" name='check_email' checked={this.state.check_email}
																	          onChange={this.handleChange} value="activo" color="primary"
																	          disabled/>
																}
																label={''}
															/>
														</FormGroup>
														</div>
													</div>
												</div>
											</Grid>
									
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												<div className={'borde-punteado-lightgray-1 card-0 text-center margin-10-L cursor-pointer'} onClick={() => {
													this.setState({
														check_rocket_chat: !this.state.check_rocket_chat
													})
												}}>
													<div className={'w-100 text-center padding-10-B padding-10-T'}>
														<span style={{cursor: 'pointer', fontSize: 14}}>
															Rocket Chat
														</span>
													</div>
													<div className={'w-100 text-center check-notificacion'}>
														<div className={'w-100'}>
															<img className={'v-center'} src={icon_rocket_chat} alt="add" width={80} height={80} style={{opacity: 0.7}}/>
														<FormGroup row className={'text-center'}>
															<FormControlLabel
																control={
																	<Checkbox type="checkbox" name='check_rocket_chat' checked={this.state.check_rocket_chat}
																	          onChange={this.handleChange} value="activo" color="primary"
																	          disabled/>
																}
																label={''}
															/>
														</FormGroup>
														</div>
													</div>
												</div>
											</Grid>
										</Grid>
									</Grid>
									
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<fieldset className={'borde-punteado-lightgray-2 padding-20 padding-10-B'}>
										<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Mensaje para el Asignado</legend>
											<Grid container spacing={2}>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<TextField
														select
														fullWidth
														required
														margin="none"
														onChange={this.handleChange}
														disabled={this.is_view()}
														SelectProps={{
															native: true,
															MenuProps: {
																className: '',
															},
														}}
														name="id_usuario_email"
														label="Usuario asignado al ticket"
														value={this.state.id_usuario_email}
													>
														<option key={0} value={''}>&nbsp;</option>
														{this.state.listar_requerimientos.map(option => (
															<option key={option.id_usuario_asignado} value={option.id_usuario_asignado}>
																{option.usuario_asignado}
															</option>
														))}
																	</TextField>
												</Grid>
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: '10px'}}>
													<TextField
														multiline
														required
														fullWidth
														variant={"outlined"}
														name={"text_email"}
														onChange={this.handleChange}
														type="text"
														margin="none"
														helperText=""
														label="Mensaje"
														value={this.state.text_email}
													/>
												</Grid>
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
													<Button onClick={() => this.save()} variant="contained" color="primary" size={"small"} className={'padding-15-L padding-15-R'}>
														{'Enviar'}
													</Button>
												</Grid>
											</Grid>
										</fieldset>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<fieldset className={'borde-punteado-lightgray-2 padding-20 padding-10-B'}>
										<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Mensaje para el Solicitante</legend>
											<Grid container spacing={2}>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<TextField
														multiline
														required
														fullWidth
														variant={"outlined"}
														name={"solicitante"}
														onChange={this.handleChange}
														type="text"
														margin="none"
														helperText=""
														label="Solicitante"
														disabled
														value={this.state.solicitante}
													/>
												</Grid>
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<TextField
														multiline
														required
														fullWidth
														variant={"outlined"}
														name={"text_email_solicitante"}
														onChange={this.handleChange}
														type="text"
														margin="none"
														helperText=""
														label="Mensaje"
														value={this.state.text_email_solicitante}
													/>
												</Grid>
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
													<Button onClick={() => this.saveSolicitante()} variant="contained" color="primary" size={"small"} className={'padding-15-L padding-15-R'}>
														{'Enviar'}
													</Button>
												</Grid>
											</Grid>
										</fieldset>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={0}>
							<Grid item xs={12} align={'right'}>
								<Button onClick={() => this.close()} color="primary">
									{!this.is_view() ? "Cancelar" : "Cerrar"}
								</Button>
							</Grid>
							{/*<Grid item xs={6} align={'right'}>
								<Button onClick={() => this.save()} color="primary">
									{this.is_edit() > 0 ? 'Actualizar' : 'Enviar'}
								</Button>
							</Grid>*/}
						</Grid>
					</DialogActions>
				</Dialog>
			
			</span>
		);
	}
}

ModalSendMail.propTypes = {
	tipo: PropTypes.oneOf([
		"add",
		"view",
		"edit",
	]),
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	component: PropTypes.element,
	//RefreshInit: PropTypes.func.isRequired,
};

export default ModalSendMail;
