import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const CitaService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				cliente: filtro.cliente || null,
				rfc: filtro.rfc || null,
				correo_electronico: filtro.correo_electronico || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cita_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_cita = form.fecha_cita ? DateFormat.FormatSql(form.fecha_cita) : '';
		let fecha_fisica = form.fecha_fisica ? DateFormat.FormatSql(form.fecha_fisica) : '';
		
		let params = {
			id_cita: null,
			id_empresa: form.id_empresa,
			nombre_acude: form.nombre_acude,
			lugar_cita: form.lugar_cita,
			fecha_cita: form.fecha_cita,
			fecha_fisica: form.fecha_fisica,
			nombre_autoridad: form.nombre_autoridad,
			telefono: form.telefono,
			correo_electronico: form.correo_electronico,
			nombre_contador: form.nombre_contador,
			descripcion: form.descripcion,
			plazo: form.plazo,
			diferencias: form.diferencias,
			atencion: form.atencion,
			archivos: form.archivos,
			activo: form.activo ? 1 : 0,
			url: form.url,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cita_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_cita = form.fecha_cita ? DateFormat.FormatSql(form.fecha_cita) : '';
		let fecha_fisica = form.fecha_fisica ? DateFormat.FormatSql(form.fecha_fisica) : '';
		
		let params = {
			id_cita: form.id_cita,
			id_empresa: form.id_empresa,
			nombre_acude: form.nombre_acude,
			lugar_cita: form.lugar_cita,
			fecha_cita: form.fecha_cita,
			fecha_fisica: form.fecha_fisica,
			nombre_autoridad: form.nombre_autoridad,
			telefono: form.telefono,
			correo_electronico: form.correo_electronico,
			nombre_contador: form.nombre_contador,
			descripcion: form.descripcion,
			plazo: form.plazo,
			diferencias: form.diferencias,
			atencion: form.atencion,
			archivos: form.archivos,
			activo: form.activo ? 1 : 0,
			url: form.url,
		};
		
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cita_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cita) => {
		let params = {
			id_cita: id_cita
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cita_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
