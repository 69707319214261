import React, {Component, Fragment} from "react";

import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {EnteroSolo} from "../../../../settings/General/General";
import Button from "@material-ui/core/Button";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import ArchivosExpedientes from "./ArchivosExpedientes";
import {DeleteOutlined} from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import ModalSeleccionarArchivoExpedienteTrabajador from "./ModalSeleccionarArchivoExpedienteTrabajador";

import icon_expediente from '../../../../assets/img/icons/expediente.svg';

class SeccionPromotorIntegracionDelExpediente extends Component {
	
	state = {};
	
	Usr = {};
	handleChange = this.props.handleChange;
	showSnackBars = this.props.showSnackBars;
	fileSelect = this.props.fileSelect;
	getBase64 = this.props.getBase64;
	agregar_ficha_tecnica_cantidad_empleado_banco = this.props.agregar_ficha_tecnica_cantidad_empleado_banco;
	eliminar_ficha_tecnica_cantidad_empleado_banco = this.props.eliminar_ficha_tecnica_cantidad_empleado_banco;
	agregar_ficha_tecnica_cantidad_empleado_esquema = this.props.agregar_ficha_tecnica_cantidad_empleado_esquema;
	eliminar_ficha_tecnica_cantidad_empleado_esquema = this.props.eliminar_ficha_tecnica_cantidad_empleado_esquema;
	RefreshFichaTecnica = this.props.RefreshFichaTecnica;
	guardarExpediente = this.props.guardarExpediente;
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		const params = props.params || {};
		
		this.state = {
			params: params,
			active_modal: (props.state || false).active_modalactive_modal,
			lista_banco: props.state.lista_bancos || [],
			lista_esquema: props.state.lista_esquemas || []
		};
		
	}
	
	guardar = (asimilados_salario) => {
		try {
			if (asimilados_salario) {
				if (!this.props.state.fecha_alta_imss) {
					throw Object({
						status: 400,
						mensaje: "El campo fecha de alta IMSS es requerido cuando es asimilados a salarios"
					});
				}
			}
			
			this.guardarExpediente(true);
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	
	render() {
		
		const asimilados_salario = !this.props.state.asimilados_salario;
		const disabledFieldPromotorIntegracionExpediente = this.props.disabledFieldPromotorIntegracionExpediente;
		
		return (
			<Fragment>
				
				<Typography variant={'h5'} className={'text-center text-black margin-30-T margin-10-B'}>
					Promotor
				</Typography>
				
				<article className={'borde-punteado-lightgray-2 card-0 bg-white padding-20 margin-30-T'}>
					
					<Grid container spacing={2} alignItems={"flex-start"} alignContent={"flex-end"}>
						
						<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography variant={'h6'} className={'text-left'}>
								Integración del expediente
							</Typography>
						</Grid>
						
						<Grid item className={'text-left'} xs={12} sm={5} md={4} lg={3} xl={2}>
							
							<KeyboardDatePicker
								format={'dd/MM/yyyy'} fullWidth required clearable
								inputProps={{readOnly: true}}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								helperText={'Indispensable'}
								label="Fecha firma de contrato"
								value={this.props.state.fecha_firma_contrato}
								onChange={(date) => {
									this.handleChange(null, null, null, date, 'fecha_firma_contrato');
								}}
								disabled={disabledFieldPromotorIntegracionExpediente}
								clearLabel={'Limpiar'}
								okLabel={'Aceptar'}
								cancelLabel={'Cancelar'}
							/>
						
						</Grid>
						
						<Grid item className={'text-left'} xs={12} sm={7} md={8} lg={9} xl={10}/>
						
						<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
							
							<Grid container spacing={2}>
								
								{this.props.state.ficha_tecnica_archivo_integracion_expediente.map((item, key) => (
									<Grid key={key} item className={'text-left'} xs={6} sm={3} md={3} lg={2}
									      xl={1}>
										<ArchivosExpedientes
											index={key}
											variable={'archivo_integracion_expediente'}
											texto={item.archivo_integracion_expediente}
											id_ficha_tecnica_archivo_integracion_expediente={item.id_ficha_tecnica_archivo_integracion_expediente || ""}
											id_cat_archivo_integracion_expediente={item.id_cat_archivo_integracion_expediente || ""}
											item={{
												loading: !!item.loading,
												base64: item.base64,
												formato: item.formato,
												archivo: item.archivo,
												is_required: item.is_required,
											}}
											fileSelect={this.fileSelect}
											getBase64={this.getBase64}
											disabledSeleccionarCambiarArchivo={disabledFieldPromotorIntegracionExpediente}
										/>
									</Grid>
								))}
							
							</Grid>
						
						</Grid>
						
						<div className={'w-100 padding-7'}/>
						
						<Grid item className={'text-left'} xs={12} sm={2} md={2} lg={2} xl={2}>
							
							<KeyboardDatePicker
								format={'dd/MM/yyyy'} fullWidth required={!!asimilados_salario} clearable
								inputProps={{readOnly: true}}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								label="Fecha de alta IMSS"
								value={this.props.state.fecha_alta_imss}
								onChange={(date) => {
									this.handleChange(null, null, null, date, 'fecha_alta_imss');
								}}
								disabled={disabledFieldPromotorIntegracionExpediente}
								clearLabel={'Limpiar'}
								okLabel={'Aceptar'}
								cancelLabel={'Cancelar'}
							/>
						
						</Grid>
						
						<Grid item className={'text-left'} xs={12} sm={2} md={2} lg={2} xl={2}>
							
							<TextField
								select
								fullWidth
								margin="none"
								onChange={this.handleChange}
								SelectProps={{
									native: true,
									MenuProps: {},
								}}
								
								helperText=""
								name="id_cat_periodo_pago_tipo_nomina"
								label="Tipo de nómina"
								value={this.props.state.id_cat_periodo_pago_tipo_nomina}
								disabled={disabledFieldPromotorIntegracionExpediente}
								required
							>
								<option value={''}>&nbsp;</option>
								{this.props.state.lista_cat_periodo_pago.map((item, key) => (
									<option key={key} value={item.id_cat_periodo_pago}>
										{item.periodo_pago}
									</option>))}
							</TextField>
						
						</Grid>
						
						<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
							
							<KeyboardDatePicker
								format={'dd/MM/yyyy'} fullWidth required clearable
								inputProps={{readOnly: true}}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								label="Fecha de la primera dispersion"
								value={this.props.state.confirmacion_fecha_primera_dispersion}
								onChange={(date) => {
									this.handleChange(null, null, null, date, 'confirmacion_fecha_primera_dispersion');
								}}
								disabled={disabledFieldPromotorIntegracionExpediente}
								clearLabel={'Limpiar'}
								okLabel={'Aceptar'}
								cancelLabel={'Cancelar'}
							/>
						
						</Grid>
						
						<Grid item className={'text-left'} xs={12} sm={4} md={3} lg={3} xl={2}>
							
							<TextField
								fullWidth
								onChange={this.handleChange}
								type="text"
								margin="none"
								
								helperText=""
								name="comision_propuesta"
								label="Comisión propuesta"
								value={this.props.state.comision_propuesta}
								disabled={disabledFieldPromotorIntegracionExpediente}
								InputProps={{
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
								}}
								required
							/>
						
						</Grid>
						
						<Grid item className={'text-left'} xs={12} sm={4} md={3} lg={3} xl={2}>
							
							<TextField
								fullWidth
								onChange={this.handleChange}
								type="text"
								margin="none"
								
								helperText=""
								name="iva"
								label="I.V.A."
								value={this.props.state.iva}
								disabled={disabledFieldPromotorIntegracionExpediente}
								InputProps={{
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
								}}
								required
							/>
						
						</Grid>
						
						<Grid item className={'text-left'} xs={12} sm={4} md={3} lg={3} xl={2}>
							
							<TextField
								fullWidth
								onChange={this.handleChange}
								type="text"
								margin="none"
								
								helperText=""
								name="retencion_isr_en_caso_de_ias"
								label="Retención ISR en caso de IAS"
								value={this.props.state.retencion_isr_en_caso_de_ias}
								disabled={disabledFieldPromotorIntegracionExpediente}
								InputProps={{
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
								}}
							/>
						
						</Grid>
						
						<Grid item className={'text-left'} xs={12} sm={4} md={3} lg={3} xl={2}>
							
							<TextField
								fullWidth
								onChange={this.handleChange}
								type="text"
								margin="none"
								
								helperText=""
								name="retencion_isn_en_caso_de_ias"
								label="Retención ISN en caso de IAS"
								value={this.props.state.retencion_isn_en_caso_de_ias}
								disabled={disabledFieldPromotorIntegracionExpediente}
								InputProps={{
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
								}}
							/>
						
						</Grid>
						
						<Grid item className={'text-left'} xs={12} sm={4} md={2} lg={2} xl={2}>
							
							
							<ModalSeleccionarArchivoExpedienteTrabajador
								tipo={'add'}
								componente={
									<Button variant="outlined" size="large" color="secundary" fullWidth className={'bg_white text-transform-none'}>
										<div className={'bg_gray vertical-inline w-100 h-100'}>
											<img className={'v-center padding-10-R'} src={icon_expediente} alt="add" width={50} height={50}/>
											<p className={'v-center margin-0'} style={{color: '#3f51b5'}}>Expedientes del Trabajador </p>
										</div>
									</Button>
								}
								state={this.props.state}
								item={this.state}
								RefreshList={this.props.RefreshList}
								showSnackBars={this.props.showSnackBars}
								RefreshFichaTecnica={this.RefreshFichaTecnica}
							/>
						
						
						</Grid>
						
						
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Grid container spacing={2}>
								
								<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
									<fieldset className={'borde-punteado-lightgray-2'}>
										<legend align={'left'} style={{color: 'dimgrey'}}> Esquemas</legend>
										<Grid container spacing={2}>
											<Grid item className={'text-left'} xs={12} sm={12} md={5} lg={5} xl={5}>
												
												<TextField
													select
													fullWidth
													margin="none"
													onChange={this.handleChange}
													disabled={disabledFieldPromotorIntegracionExpediente}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}
													//helperText="Requerido"
													name="front_id_esquema"
													label="Esquema"
													value={this.props.state.front_id_esquema}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.lista_esquema.map(option => (
														<option key={option.id_cat_esquema} value={option.id_cat_esquema}>
															{option.abreviatura + ' - ' + option.esquema}
														</option>
													))}
												</TextField>
												
												{/*<TextField
													fullWidth
													onChange={this.handleChange}
													type="text"
													margin="none"
													
													helperText=""
													name="front_nombre_banco"
													label="Esquema"
													value={this.props.state.front_esquema}
													disabled={disabledFieldPromotorIntegracionExpediente}
												/>*/}
											
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={5} lg={5} xl={5}>
												
												<TextField
													fullWidth
													onChange={this.handleChange}
													type="text"
													margin="none"
													
													helperText=""
													name="front_cantidad_empleado_esquema"
													label="Cant. de empleados"
													value={this.props.state.front_cantidad_empleado_esquema}
													disabled={disabledFieldPromotorIntegracionExpediente}
													onKeyPress={EnteroSolo}
													inputProps={{maxLength: 8}}
												/>
											
											</Grid>
											
											<Grid item className={'text-left h-64-px v-center'} xs={12} sm={12}
											      md={2} lg={2} xl={2} style={{
												height: "64px",
												paddingTop: "20px"
											}}>
												
												<Button variant="contained" color="default" fullWidth
												        onClick={() => this.agregar_ficha_tecnica_cantidad_empleado_esquema()}
												        className={'text-transform-none'}
												        disabled={disabledFieldPromotorIntegracionExpediente}
												>
													Agregar
												</Button>
											
											</Grid>
											
											{this.props.state.ficha_tecnica_cantidad_empleado_esquema.length > 0 ? (
												<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
													
													<div className={'w-100 text-left'}>
														
														{this.props.state.ficha_tecnica_cantidad_empleado_esquema.map((item, key) => (
															<div
																className={'vertical-inline b-r-20 margin-5 ' + (disabledFieldPromotorIntegracionExpediente ? 'bg-gray' : 'bg-danger')}
																key={key}>
																<div
																	className={'padding-3 padding-10-L padding-10-R v-center text-white'}>
																	{item.esquema} ({item.cantidad_empleado_esquema} Empleados)
																</div>
																<div className={'padding-3 padding-10-R v-center text-white'}>
																	{!disabledFieldPromotorIntegracionExpediente ? (
																		<DeleteOutlined className={'px-18'} onClick={() => this.eliminar_ficha_tecnica_cantidad_empleado_esquema(key)}/>
																	) : ''}
																</div>
															</div>
														))}
													
													</div>
												
												</Grid>
											) : ''}
										</Grid>
									</fieldset>
								</Grid>
								
								
								<Grid item className={'text-left'} xs={12} sm={12} md={7} lg={7} xl={7}>
									<fieldset className={'borde-punteado-lightgray-2'}>
										<legend align={'left'} style={{color: 'dimgrey'}}> Bancos</legend>
										<Grid container spacing={2}>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
												
												<TextField
													select
													fullWidth
													margin="none"
													onChange={this.handleChange}
													disabled={disabledFieldPromotorIntegracionExpediente || this.props.state.ficha_tecnica_cantidad_empleado_esquema.length === 0}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}
													//helperText="Requerido"
													name="front_id_esquema_banco"
													label="Esquema"
													value={this.props.state.front_id_esquema_banco}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.props.state.ficha_tecnica_cantidad_empleado_esquema.map(option => (
														<option key={option.id_cat_esquema} value={option.id_cat_esquema}>
															{option.esquema}
														</option>
													))}
												</TextField>
											
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
												
												<TextField
													select
													fullWidth
													margin="none"
													onChange={this.handleChange}
													disabled={disabledFieldPromotorIntegracionExpediente || this.props.state.ficha_tecnica_cantidad_empleado_esquema.length === 0}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}
													//helperText="Requerido"
													name="front_id_banco"
													label="Banco"
													value={this.props.state.front_id_banco}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.lista_banco.map(option => (
														<option key={option.id_cat_banco} value={option.id_cat_banco}>
															{option.banco}
														</option>
													))}
												</TextField>
												
												{/*<TextField
													fullWidth
													onChange={this.handleChange}
													type="text"
													margin="none"
													
													helperText=""
													name="front_nombre_banco"
													label="Banco"
													value={this.props.state.front_nombre_banco}
													disabled={disabledFieldPromotorIntegracionExpediente}
												/>*/}
											
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
												
												<TextField
													fullWidth
													onChange={this.handleChange}
													type="text"
													margin="none"
													
													helperText=""
													name="front_cantidad_empleado"
													label="Cant. de empleados"
													value={this.props.state.front_cantidad_empleado}
													disabled={disabledFieldPromotorIntegracionExpediente || this.props.state.ficha_tecnica_cantidad_empleado_esquema.length === 0}
													onKeyPress={EnteroSolo}
													inputProps={{maxLength: 8}}
												/>
											
											</Grid>
											
											<Grid item className={'text-left h-64-px v-center'} xs={12} sm={12}
											      md={2} lg={2} xl={2} style={{
												height: "64px",
												paddingTop: "20px"
											}}>
												
												<Button variant="contained" color="default" fullWidth
												        onClick={() => this.agregar_ficha_tecnica_cantidad_empleado_banco()}
												        className={'text-transform-none'}
												        disabled={disabledFieldPromotorIntegracionExpediente || this.props.state.ficha_tecnica_cantidad_empleado_esquema.length === 0}
												>
													Agregar
												</Button>
											
											</Grid>
											
											{this.props.state.ficha_tecnica_cantidad_empleado_banco.length > 0 ? (
												<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
													
													<div className={'w-100 text-left'}>
														
														{this.props.state.ficha_tecnica_cantidad_empleado_banco.map((item, key) => (
															<div
																className={'vertical-inline b-r-20 margin-5 ' + (disabledFieldPromotorIntegracionExpediente ? 'bg-gray' : 'bg-danger')}
																key={key}>
																<div
																	className={'padding-3 padding-10-L padding-10-R v-center text-white'}>
																	{item.esquema} | {item.banco} ({item.cantidad_empleado} Empleados)
																</div>
																<div className={'padding-3 padding-10-R v-center text-white'}>
																	{!disabledFieldPromotorIntegracionExpediente ? (
																		<DeleteOutlined className={'px-18'}
																		                onClick={() => this.eliminar_ficha_tecnica_cantidad_empleado_banco(key)}/>
																	) : ''}
																</div>
															</div>
														))}
													
													</div>
												
												</Grid>
											) : ''}
										</Grid>
									</fieldset>
								</Grid>
								
								
								{/*<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
									<fieldset className={'borde-punteado-danger-2'}>
										<legend align={'left'} style={{color: 'dimgrey'}}> Expedientes del trabajador</legend>
										<Grid container spacing={2}>
											<Grid item className={'text-left'} xs={12} sm={12} md={5} lg={5} xl={5}>
												
												<TextField
													fullWidth
													onChange={this.handleChange}
													type="text"
													margin="none"
													
													helperText=""
													name="front_nombre_banco"
													label="Banco"
													value={this.props.state.front_nombre_banco}
													disabled={disabledFieldPromotorIntegracionExpediente}
												/>
											
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={5} lg={5} xl={5}>
												
												<TextField
													fullWidth
													onChange={this.handleChange}
													type="text"
													margin="none"
													
													helperText=""
													name="front_cantidad_empleado"
													label="Cantidad de empleados"
													value={this.props.state.front_cantidad_empleado}
													disabled={disabledFieldPromotorIntegracionExpediente}
													onKeyPress={EnteroSolo}
													inputProps={{maxLength: 8}}
												/>
											
											</Grid>
											
											<Grid item className={'text-left h-64-px v-center'} xs={12} sm={12}
											      md={2} lg={2} xl={2} style={{
												height: "64px",
												paddingTop: "20px"
											}}>
												
												<Button variant="contained" color="default" fullWidth
												        onClick={() => this.agregar_ficha_tecnica_cantidad_empleado_banco()}
												        className={'text-transform-none'}
												        disabled={disabledFieldPromotorIntegracionExpediente}
												>
													Agregar
												</Button>
											
											</Grid>
											
											{this.props.state.ficha_tecnica_cantidad_empleado_banco.length > 0 ? (
												<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
													
													<div className={'w-100 text-left'}>
														
														{this.props.state.ficha_tecnica_cantidad_empleado_banco.map((item, key) => (
															<div
																className={'vertical-inline b-r-20 margin-5 ' + (disabledFieldPromotorIntegracionExpediente ? 'bg-gray' : 'bg-danger')}
																key={key}>
																<div
																	className={'padding-3 padding-10-L padding-10-R v-center text-white'}>
																	{item.banco} ({item.cantidad_empleado} Empleados)
																</div>
																<div className={'padding-3 padding-10-R v-center text-white'}>
																	{!disabledFieldPromotorIntegracionExpediente ? (
																		<DeleteOutlined className={'px-18'}
																		                onClick={() => this.eliminar_ficha_tecnica_cantidad_empleado_banco(key)}/>
																	) : ''}
																</div>
															</div>
														))}
													
													</div>
												
												</Grid>
											) : ''}
										</Grid>
									</fieldset>
								</Grid>*/}
							</Grid>
						</Grid>
						
						
						<Grid item className={'text-center padding-10'} xs={12} sm={12} md={12} lg={12}
						      xl={12}>
							<div className={'margin-20-T margin-10-B'}>
								{!disabledFieldPromotorIntegracionExpediente ? (
									<Button variant="contained" color="primary"
									        onClick={() => this.guardar(asimilados_salario)}
									        className={'btn-default-primary text-transform-none'}>
										Guardar Expediente
									</Button>
								) : ''}
							</div>
						</Grid>
					
					</Grid>
				
				</article>
			
			</Fragment>
		)
	}
}


SeccionPromotorIntegracionDelExpediente.propTypes = {
	disabledFieldPromotorIntegracionExpediente: PropTypes.bool.isRequired,
	state: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	fileSelect: PropTypes.func.isRequired,
	getBase64: PropTypes.func.isRequired,
	agregar_ficha_tecnica_cantidad_empleado_banco: PropTypes.func.isRequired,
	eliminar_ficha_tecnica_cantidad_empleado_banco: PropTypes.func.isRequired,
	agregar_ficha_tecnica_cantidad_empleado_esquema: PropTypes.func.isRequired,
	eliminar_ficha_tecnica_cantidad_empleado_esquema: PropTypes.func.isRequired,
	guardarExpediente: PropTypes.func.isRequired,
	RefreshFichaTecnica: PropTypes.func.isRequired,
};


export default SeccionPromotorIntegracionDelExpediente;


