import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const ResguardoService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				id_resguardo: filtro.id_resguardo || null,
				id_cat_equipo: filtro.id_cat_equipo || null,
				puesto: filtro.puesto || null,
				marca: filtro.marca || null,
				modelo: filtro.modelo || null,
				id_estado_recepcion: filtro.id_estado_recepcion || null,
				id_estado_actual: filtro.id_estado_actual || null,
				id_usuario: filtro.id_usuario || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Resguardo_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_recepcion = (form.fecha_recepcion || null) ? DateFormat.FormatSql(form.fecha_recepcion) : null;
		
		let params = {
			id_resguardo: form.id_resguardo || '',
			id_empresa: form.id_empresa || '',
			puesto: form.puesto || '',
			id_cat_equipo: form.id_cat_equipo || '',
			cantidad: form.cantidad || '',
			marca: form.marca || '',
			modelo: form.modelo || '',
			serie: form.serie || '',
			color: form.color || '',
			id_recibio_recepcion: form.id_recibio_recepcion || '',
			id_estado_recepcion: form.id_estado_recepcion || '',
			direccion_recepcion: form.direccion_recepcion || '',
			id_estado_actual: form.id_estado_actual || '',
			direccion_actual: form.direccion_actual || '',
			procesador: form.procesador || '',
			ram: form.ram || '',
			almacenamiento: form.almacenamiento || '',
			accesorios: form.accesorios || '',
			observaciones: form.observaciones || '',
			archivos: form.archivos || [],
			softwares: form.lista_software || [],
			fecha_recepcion: fecha_recepcion || null,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Resguardo_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_recepcion = (form.fecha_recepcion || null) ? DateFormat.FormatSql(form.filtro.fecha_recepcion) : null;
		
		let params = {
			id_resguardo: form.id_resguardo || '',
			id_usuario: form.id_usuario || '',
			id_empresa: form.id_empresa || '',
			puesto: form.puesto || '',
			id_cat_equipo: form.id_cat_equipo || '',
			cantidad: form.cantidad || '',
			marca: form.marca || '',
			modelo: form.modelo || '',
			serie: form.serie || '',
			color: form.color || '',
			id_recibio_recepcion: form.id_recibio_recepcion || '',
			id_estado_recepcion: form.id_estado_recepcion || '',
			direccion_recepcion: form.direccion_recepcion || '',
			id_estado_actual: form.id_estado_actual || '',
			direccion_actual: form.direccion_actual || '',
			procesador: form.procesador || '',
			ram: form.ram || '',
			almacenamiento: form.almacenamiento || '',
			accesorios: form.accesorios || '',
			observaciones: form.observaciones || '',
			archivos: form.archivos || [],
			softwares: form.lista_software || [],
			fecha_recepcion: fecha_recepcion || null,
			activo: form.activo ? 1 : 0,
		};
		
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Resguardo_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_resguardo) => {
		let params = {
			id_resguardo: id_resguardo
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Resguardo_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SubirPDF: (form) => {
		let params = {
			id_resguardo: form.id_resguardo,
			archivos: form.archivos,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Resguardo_Firmado', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
