import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../../settings/General/General";
import {AttachFile, AttachMoney, CloudDownload, DeleteOutlined, Description, EditOutlined, HdrStrong, Room, SearchOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DocSvg from "../../../../../assets/img/icons/doc.svg";
import {DropzoneArea} from "material-ui-dropzone";
import {DropboxApi} from "../../../../../settings/Dropbox/DropboxApi";
import ClienteReceptoraPagoService from "../../../../../services/_Cat/ClienteReceptoraPagoService/ClienteReceptoraPagoService";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import BarraProgreso from "../../../Tickets/Includes/BarraProgreso";
import ModalRevocado from "../../../RepositorioJuridico/Includes/ModalRevocado";
import ModalCertificado from "../../EmpresaAcrescoCertificado/Includes/ModalCertificado";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import {CONFIG} from "../../../../../settings/Config/Config";
import VistaVacia from "../../../../Include/VistaVacia/VistaVacia";


class ModalDomicilioFiscal extends Component {
	
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_empresa_archivo: (props.item || '').id_empresa_archivo,
			id_empresa: (props.item || '').id_empresa,
			tipo: props.tipo,
			tipo_archivo: 1,
			observaciones: '',
			fecha_emision: null,
			comprobante_pago: 1,
			files: [],
			archivos: (props.item || []).archivos_cif,
		};
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	
	guardarDocTimbradoDbx = () => {
		
		let files = this.state.files;
		
		return new Promise((resolve, reject) => {
			let band = files.length;
			let band2 = 0;
			let archives = this.state.archivos;
			let file;
			
			for (let i = 0; i < files.length; i++) {
				console.log("2 FTAR::: ", files);
				file = files[i];
				
				let ruta = '/Empresas/CIF/' + this.state.id_empresa + '_' + file.name;
				
				DropboxApi.Upload(ruta, file).then(function (response) {
					archives.push({archivo: response.path_display});
					
					band2++;
					
					console.log("DROPBOX UPLOAD: ", response, band, band2);
					
					if (band === band2) {
						resolve(archives);
					}
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	save = () => {
		
		try {
			
			if (!FieldsJs.Field(this.state.fecha_emision)) {
				throw Object({
					status: false, mensaje: 'Campo fecha emisión es requerido.'
				})
			}
			
			if (!FieldsJs.Field(this.state.files) && this.state.files.length === 0 || !FieldsJs.Field(this.state.files) || this.state.files.length === 0) {
				throw Object({
					status: false, mensaje: 'No se ha seleccionado el archivo.'
				})
			}
			
			let guardar = new Promise((resolve, reject) => {
				showSpinner('spinner');
				this.guardarDocTimbradoDbx(this.state.files).then(response => {
					this.setState({
						archivos: response,
					});
					resolve(response);
					console.log("DROPBOX UPLOAD: ", this.state);
				}).catch(error => {
					reject(error);
				})
			});
			
			guardar.then(response => {
				if (this.state.id_empresa_archivo > 0) {
					ClienteReceptoraPagoService.Archivo(this.state).then((response) => {
						this.props.RefreshList();
						this.modalClose();
						hideSpinner('spinner', 500);
						this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
					});
				} else {
					ClienteReceptoraPagoService.Archivo(this.state).then((response) => {
						this.props.RefreshList();
						this.modalClose();
						this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						hideSpinner('spinner', 500);
						this.showSnackBars('error', error.mensaje);
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		const {item} = this.props;
		
		this.setState({
			id_empresa_archivo: item.id_empresa_archivo || '',
			id_empresa: item.id_empresa || '',
			tipo_archivo: 1,
			observaciones: '',
			fecha_emision: null,
			comprobante_pago: 1,
			archivos: item.archivos_cif || [],
			activo: true,
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_empresa_archivo: item.id_empresa_archivo || '',
			id_empresa: item.id_empresa || '',
			tipo_archivo: item.tipo_archivo || '',
			fecha_emision: item.fecha_emision ? moment(item.fecha_emision) : null,
			observaciones: item.observaciones || null,
			comprobante_pago: 1,
			archivos: item.archivos_cif || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_empresa_archivo: item.id_empresa_archivo || '',
			id_empresa: item.id_empresa || '',
			tipo_archivo: item.tipo_archivo || '',
			observaciones: item.observaciones || null,
			fecha_emision: item.fecha_emision ? moment(item.fecha_emision) : null,
			comprobante_pago: 1,
			archivos: item.archivos_cif || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_empresa_archivo > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Estatus">
						<IconButton aria-label="Estatus" onClick={() => this.edit()}>
							<HdrStrong/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Domicilios Fiscales">
					<IconButton aria-label="Domicilios Fiscales" onClick={() => this.add()} style={{color: this.props.item.archivos_cif.length > 0 ? '#3f51b5' : 'rgba(0, 0, 0, 0.54)'}}><Room/></IconButton>
				</Tooltip>
			);
		}
		return BTN_ACTION;
	};
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Open(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{'Domicilios Fiscales'}</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{this.props.item.domicilios_fiscales.length > 0 ? (
									<Paper style={{overflowX: "auto"}}>
										<Table className={'desing-acresco'}>
											<TableHead>
												<TableRow>
													<TableCell component="th" align={'center'} className={'w-10-px padding-20-L padding-10-R'}>
														ID
													</TableCell>
													<TableCell component="th" align={'left'}>Logo</TableCell>
													<TableCell component="th" align={'center'}>Empresa</TableCell>
													<TableCell component="th" align={'center'}>Domicilio</TableCell>
													<TableCell component="th" align={'center'}>Teléfono</TableCell>
													<TableCell component="th" align={'center'}>Activo</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.props.item.domicilios_fiscales.map((item, key) => (
													<TableRow key={key}>
														<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
															{item.id_empresa_domicilio_fiscal}
														</TableCell>
														<TableCell align={'left'}>
															<div className={'card-0 b-r-100 w-40-px h-40-px'}>
																<Avatar
																	style={{
																		width: 40,
																		height: 40
																	}}
																	alt={"Logo"}
																	src={item.logo ? CONFIG.src + item.logo:null}
																/>
															</div>
														</TableCell>
														<TableCell align={'center'}>{item.razon_social || item.nombre_comercial}</TableCell>
														<TableCell align={'left'}>{item.domicilio_fiscal}</TableCell>
														<TableCell align={'left'}>{item.telefono}</TableCell>
														<TableCell align={'center'}>
															{item.activo === 1 ? (
																<div style={{
																	width: "50px",
																	color: "white",
																	borderRadius: "3px",
																	padding: "2px 10px",
																	background: "darkgreen",
																	display: "inline-grid",
																}}>Activo</div>
															) : (
																<div style={{
																	width: "50px",
																	color: "white",
																	borderRadius: "3px",
																	padding: "2px 10px",
																	background: "#901C30",
																	display: "inline-grid",
																}}>Inactivo</div>
															)}
														</TableCell>
													
													</TableRow>
												))}
											</TableBody>
										</Table>
									</Paper>
								) : (
									<VistaVacia
										width={70}
										height={70}
										numero={0}
										mensaje={'No se encontraron datos.'}
									/>
								) }
								
							</Grid>
						
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalDomicilioFiscal.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalDomicilioFiscal);
