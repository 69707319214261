import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import {FichaTecnicaService} from "../../../../services/FichaTecnicaService/FichaTecnicaService";
import ClienteReceptoraPagoService
	from '../../../../services/_Cat/ClienteReceptoraPagoService/ClienteReceptoraPagoService';
import {FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Fab from "@material-ui/core/Fab";
import {
	AddOutlined,
	ArrowForwardOutlined,
	CancelOutlined,
	DeleteOutlineOutlined,
	DoneOutlined,
	EditOutlined,
	PictureAsPdfOutlined,
	SearchOutlined,
	SendOutlined,
	SyncOutlined,
} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import ModalClienteReceptoraPago from "../../Cat/ClienteReceptoraPago/Includes/ModalClienteReceptoraPago";
import {ClientePotencialService} from "../../../../services/ClientePotencialService/ClientePotencialService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import ModalClienteReceptoraPagoDatoBancario
	from "../../Cat/ClienteReceptoraPagoDatoBancario/Includes/ModalClienteReceptoraPagoDatoBancario";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import EnviarCorreoDatosBancariosClientesReceptorasPago from "./EnviarCorreoDatosBancariosClientesReceptorasPago";


class DatosBancariosClientesReceptorasPago extends Component {
	
	state = {};
	
	Usr = {};
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			checked: false,
			
			id_cliente: props.id_cliente,
			
			cliente: {},
			
			id_ficha_tecnica: '',
			id_cat_ficha_tecnica_status: '',
			
			cliente_receptora_pago: [],
			
			cat_cliente_receptora_pago: [],
			info: {},
		};
		
		console.log("PROPS.ID_CLIENTE::: ", props.id_cliente);
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	open = () => {
		this.init().then(r => {
			
			this.ListaClienteReceptoraPago();
			
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			FichaTecnicaService.InitDato(this.state.id_cliente).then((response) => {
				
				let dato = FieldsJs.Copy(response.data);
				
				if (response.visible === 0) {
					
					this.setState({
						visible: 0,
						mensaje: response.message
					});
					
				} else {
					
					let cliente = dato.cliente || {};
					let ficha_tecnica_prestacion = dato.ficha_tecnica_prestacion;
					let ft = dato.ficha_tecnica || {};
					
					for (let i = 0; i < ficha_tecnica_prestacion.length; i++) {
						var it = ficha_tecnica_prestacion[i];
						it.porcentaje = it.porcentaje || '';
						it.pago_efectivo = (it.pago_efectivo === 1);
						it.vales = (it.vales === 1);
						it.prestaciones = (it.prestaciones === 1);
						it.frecuencia = (it.frecuencia === 1);
						it.fijo_variable = it.fijo_variable || '';
						it.integra_sbc = (it.integra_sbc === 1);
					}
					
					this.setState({
						
						cliente: cliente,
						
						id_ficha_tecnica: ft.id_ficha_tecnica ? Number(ft.id_ficha_tecnica) : '',
						id_cat_ficha_tecnica_status: ft.id_cat_ficha_tecnica_status ? Number(ft.id_cat_ficha_tecnica_status) : '',
						
						cliente_receptora_pago: dato.cliente_receptora_pago || [],
					});
					
				}
				
				setTimeout(() => this.openModal());
				
				resolve(true);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
				reject(false);
			});
		});
	};
	
	ListaClienteReceptoraPago = () => {
		ClienteReceptoraPagoService.Listar().then((response) => {
			let data = response.data || [];
			let info = {};
			for (let i = 0; i < data.length; i++) {
				let item = data[i];
				if (this.state.id_empresa === item.id_empresa) {
					info = item;
				}
			}
			this.setState({
				cat_cliente_receptora_pago: data,
				info: info
			})
		}).catch((error) => {
			this.setState({
				cat_cliente_receptora_pago: []
			})
		});
	};
	
	SeleccionarClienteReceptora = () => {
		let data = {
			id_cliente: this.state.id_cliente,
			id_empresa: this.state.id_empresa,
			activo: 1
		};
		ClientePotencialService.ClienteReceptoraPagoAgregar(data).then((response) => {
			this.showSnackBars('success', response.mensaje);
			this.RefreshInit();
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	EliminarClienteReceptora = (value) => {
		let mensaje = `¿Estas seguro de eliminar la empresa ${value.razon_social} receptora de pago, para el cliente ${this.state.cliente.razon_social}?`;
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, mensaje).then((r) => {
			if (r.button === 'Aceptar') {
				let data = {
					id_cliente_receptora_pago: value.id_cliente_receptora_pago,
				};
				ClientePotencialService.ClienteReceptoraPagoEliminar(data).then((response) => {
					this.showSnackBars('success', response.mensaje);
					this.RefreshInit();
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	SeleccionarClienteReceptoraDatoBancario = (value, item) => {
		let data = {
			id_cliente_receptora_pago: value.id_cliente_receptora_pago,
			id_empresa_dato_bancario: item.id_empresa_dato_bancario,
			activo: 1
		};
		ClientePotencialService.ClienteReceptoraPagoAgregarDatoBancario(data).then((response) => {
			this.showSnackBars('success', response.mensaje);
			this.RefreshInit();
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	EliminarClienteReceptoraDatoBancario = (value, item) => {
		let mensaje = `¿Deseas desactivar la cuenta bancaria de la empresa ${value.razon_social} receptora de pago, para el cliente ${this.state.cliente.razon_social}?`;
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, mensaje).then((r) => {
			if (r.button === 'Aceptar') {
				let data = {
					id_empresa_dato_bancario: item.id_empresa_dato_bancario,
				};
				ClientePotencialService.ClienteReceptoraPagoEliminarDatoBancario(data).then((response) => {
					this.showSnackBars('success', response.mensaje);
					this.RefreshInit();
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	disabledFieldTesoreriaDatosPromotorBolean = () => {
		
		let disabledFieldTesoreriaDatosPromotor = true;
		
		if (FieldsJs.inArray([1, 2, 6], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldTesoreriaDatosPromotor = false;
		}
		
		return disabledFieldTesoreriaDatosPromotor;
	};
	
	tachado = (item) => {
		return !(item.id_empresa_dato_bancario > 0);
	};
	
	exportar = (item, archivo, auth, email) => {
		let params = {
			auth: auth,
			archivo: archivo,
			id_cliente: this.state.id_cliente,
			email: email,
			to: null,
			cc: null,
			to_name: null,
			subject: null,
			mensaje: null,
		};
		HttpRequest.export('erdp', ['empresa', 'de', 'receptora', 'pago'], params, false, false, false, archivo === "EXCEL");
	};
	
	render() {
		
		const disabledFieldTesoreriaDatosPromotor = this.disabledFieldTesoreriaDatosPromotorBolean();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Typography className={'px-20'}>
									<Fab size="small" color="secondary" aria-label="Actualizar"
									     onClick={() => this.RefreshInit()} style={{marginRight: '15px'}}>
										<SyncOutlined/>
									</Fab>
									{this.props.titulo}
								</Typography>
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								
								<Grid container spacing={0} alignContent={"center"} alignItems={"center"}>
									<Grid item style={{width: '48px'}}>
										<ModalClienteReceptoraPago
											tipo={'add'}
											item={{id_cliente: this.props.id_cliente}}
											RefreshList={(id) => {
												this.setState({
													id_empresa: id > 0 ? id : ''
												});
												this.ListaClienteReceptoraPago();
											}}
											showSnackBars={this.showSnackBars}
											componente={(
												<IconButton aria-label="Agregar">
													<AddOutlined/>
												</IconButton>
											)}
										/>
									</Grid>
									<Grid item style={{width: '48px'}}>
										{FieldsJs.Array(this.state.info) ? (
											<ModalClienteReceptoraPago
												tipo={'edit'}
												item={this.state.info || {}}
												RefreshList={() => {
													this.ListaClienteReceptoraPago();
												}}
												showSnackBars={this.props.showSnackBars}
												componente={(
													<IconButton aria-label="Editar">
														<EditOutlined/>
													</IconButton>
												)}
											/>
										) : (
											<IconButton aria-label="Editar" disabled>
												<EditOutlined/>
											</IconButton>
										)}
									</Grid>
									<Grid item style={{width: '48px'}}>
										{FieldsJs.Array(this.state.info) ? (
											<ModalClienteReceptoraPago
												tipo={'view'}
												item={this.state.info || {}}
												RefreshList={() => {
													this.ListaClienteReceptoraPago();
												}}
												showSnackBars={this.props.showSnackBars}
												componente={(
													<IconButton aria-label="Ver detalles">
														<SearchOutlined/>
													</IconButton>
												)}
											/>
										) : (
											<IconButton aria-label="Ver detalles" disabled>
												<SearchOutlined/>
											</IconButton>
										)}
									</Grid>
									
									<Grid item style={{width: 'calc(100% - 192px)'}}>
										
										<FormControl fullWidth>
											<InputLabel>Empresa receptora de pago</InputLabel>
											<Select
												native
												value={this.state.id_empresa || ''}
												name={"id_empresa"}
												onChange={(e) => {
													let info = {};
													for (let i = 0; i < this.state.cat_cliente_receptora_pago.length; i++) {
														let item = this.state.cat_cliente_receptora_pago[i];
														if (Number(e.target.value) === Number(item.id_empresa)) {
															info = item;
														}
													}
													this.setState({
														info: info
													});
													this.handleChange(e);
												}}
												input={<Input/>}
												disabled={disabledFieldTesoreriaDatosPromotor}
											>
												<option value=""/>
												{this.state.cat_cliente_receptora_pago.map(option => (
													<option
														key={option.id_empresa}
														value={option.id_empresa}
														children={option.razon_social}
													/>
												))}
											</Select>
										</FormControl>
									
									</Grid>
									<Grid item style={{width: '48px'}}>
										<IconButton aria-label="Seleccionar" style={{backgroundColor: '#901b30', color: '#ffffff'}} onClick={() => this.SeleccionarClienteReceptora()}>
											<ArrowForwardOutlined/>
										</IconButton>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{
								background: '#cecece',
								padding: 10,
								borderRadius: 7,
							}}>
								<Typography style={{fontSize: 14, textAlign: "center"}}>
									Datos bancarios
								</Typography>
								<Grid container spacing={2} direction="row" justify="center" alignItems="center">
									<Grid item>
										<Fab
											variant="extended"
											size="small"
											color="secondary"
											aria-label="add"
											style={{
												backgroundColor: '#901b30',
												fontSize: 12,
												paddingRight: 15,
												paddingLeft: 15
											}}
											onClick={() => this.exportar({}, 'PDF', true, 0)}
										>
											Ver PDF <PictureAsPdfOutlined style={{marginLeft: 10, fontSize: 20}}/>
										</Fab>
									</Grid>
									<Grid item>
										<EnviarCorreoDatosBancariosClientesReceptorasPago
											id_cliente={this.state.id_cliente}
											componente={(
												<Fab
													variant="extended"
													size="small"
													color="secondary"
													aria-label="add"
													style={{
														backgroundColor: '#4CAF50',
														fontSize: 12,
														paddingRight: 15,
														paddingLeft: 15
													}}
												>
													Enviar por correo electrónico <SendOutlined
													style={{marginLeft: 10, fontSize: 20}}/>
												</Fab>
											)}
											showSnackBars={this.showSnackBars}
										/>
									</Grid>
								</Grid>
							
							</Grid>
						
						</Grid>
						
						<div style={{width: '100%', height: 20}}/>
						
						<Grid container spacing={0} alignContent={"center"} alignItems={"center"}>
							
							{(this.state.cliente_receptora_pago || []).map((value, index) => (
								<Grid key={index} item xs={12} sm={12} md={12} lg={12} xl={12}>
									
									<fieldset style={{
										marginTop: "20px",
										borderColor: "#3e3e3e",
										borderStyle: "solid",
										borderWidth: "1px",
										borderRadius: "10px",
									}}>
										
										<legend>
											<h2 style={{
												background: "black",
												color: "white",
												padding: "3px 10px 3px 10px",
												borderRadius: "20px",
												fontSize: "16px",
												margin: "0px"
											}}>
												{value.razon_social}
											</h2>
										</legend>
										
										<Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
											
											<Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
												
												<Typography style={{fontSize: 14}}>
													RFC: {value.rfc || 'No registrado'}
												</Typography>
											
											</Grid>
											
											<Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
												
												<Typography style={{fontSize: 14}}>
													Razón Social: {value.razon_social || 'No registrado'}
												</Typography>
											
											</Grid>
											
											<Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
												
												<Typography style={{fontSize: 14}}>
													Correo electrónico: {value.correo_electronico || 'No registrado'}
												</Typography>
											
											</Grid>
											
											<Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
												
												<Typography style={{fontSize: 14}}>
													Teléfono: {value.telefono || 'No registrado'}
												</Typography>
											
											</Grid>
											
											<Grid item xs={12} sm={1} md={1} lg={1} xl={1} align={"right"}>
												
												<Fab size="small" color="secondary" aria-label="Eliminar"
												     onClick={() => this.EliminarClienteReceptora(value)}>
													<DeleteOutlineOutlined/>
												</Fab>
											
											</Grid>
											
											<Grid item xs={12} sm={1} md={1} lg={1} xl={1} align={"right"}>
												<ModalClienteReceptoraPagoDatoBancario
													tipo={'edit'}
													item={value}
													RefreshList={() => {
														this.RefreshInit();
													}}
													showSnackBars={this.props.showSnackBars}
													componente={(
														<Fab size="small" color="secondary"
														     aria-label="Agregar cuentas bancarias">
															<AddOutlined/>
														</Fab>
													)}
												/>
											</Grid>
										
										</Grid>
										
										<Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												
												<table className={'desing-acresco'}>
													
													<thead>
													
													<tr>
														<th className={'padding-10'}>Banco</th>
														<th className={'padding-10'}>Sucursal</th>
														<th className={'padding-10'}>No. cliente</th>
														<th className={'padding-10'}>Tipo de cuenta</th>
														<th className={'padding-10'}>No. cuenta</th>
														<th className={'padding-10'}>Clabe interbancaria</th>
														<th className={'padding-10'}>Acciones</th>
													</tr>
													
													</thead>
													
													<tbody>
													
													{(value.cat_cliente_receptora_pago_dato_bancario || []).map((item, key) => (
														<tr key={key}>
															<th className={`padding-10 ${this.tachado(item) ? 'text-tachado' : ''}`}>
																<div className={'vertical-inline v-center'} style={{
																	padding: "1px 7px 1px 7px",
																	background: (!this.tachado(item) ? 'green' : ''),
																	color: (!this.tachado(item) ? 'white' : ''),
																	borderRadius: 7,
																}}>
																	{item.banco}
																</div>
															</th>
															<th className={`padding-10 ${this.tachado(item) ? 'text-tachado' : ''}`}>
																{item.banco_sucursal}
															</th>
															<th className={`padding-10 ${this.tachado(item) ? 'text-tachado' : ''}`}>
																{item.numero_cliente}
															</th>
															<th className={`padding-10 ${this.tachado(item) ? 'text-tachado' : ''}`}>
																{item.banco_tipo_cuenta}
															</th>
															<th className={`padding-10 ${this.tachado(item) ? 'text-tachado' : ''}`}>
																{item.numero_cuenta}
															</th>
															<th className={`padding-10 ${this.tachado(item) ? 'text-tachado' : ''}`}>
																{item.clabe_interbancaria}
															</th>
															<th className={`padding-10 ${this.tachado(item) ? 'text-tachado' : ''}`}>
																{!disabledFieldTesoreriaDatosPromotor ? (
																	<Fragment>
																		<Grid
																			container
																			direction="row"
																			justify="space-evenly"
																			alignItems="center"
																		>
																			
																			<CancelOutlined
																				className={'cursor-pointer'}
																				style={{color: (!this.tachado(item) ? "red" : "gray")}}
																				onClick={() => this.EliminarClienteReceptoraDatoBancario(value, item)}
																			/>
																			
																			<DoneOutlined
																				className={'cursor-pointer'}
																				style={{color: (this.tachado(item) ? "blue" : "gray")}}
																				onClick={() => this.SeleccionarClienteReceptoraDatoBancario(value, item)}
																			/>
																		
																		</Grid>
																	
																	</Fragment>
																) : ''}
															</th>
														</tr>
													))}
													
													{(value.cat_cliente_receptora_pago_dato_bancario || []).length <= 0 ? (
														<tr>
															<th colSpan={7} className={'text-gray text-center'}>
																No se han registrado datos bancarios
															</th>
														</tr>
													) : null}
													
													</tbody>
												
												</table>
											
											</Grid>
										
										</Grid>
									
									</fieldset>
								
								</Grid>
							))}
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={1}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
							
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

DatosBancariosClientesReceptorasPago.propTypes = {
	titulo: PropTypes.string.isRequired,
	id_cliente: PropTypes.number.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default DatosBancariosClientesReceptorasPago;
