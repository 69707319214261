import React, {Component, Fragment} from 'react';
import Typography from "@material-ui/core/Typography/index";
import Sync from '@material-ui/icons/Sync';
import {AccesoService} from '../../../services/AccesoService/AccesoService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Fab from "@material-ui/core/Fab/index";
import VistaVacia from "../../Include/VistaVacia/VistaVacia";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import {CONFIG} from "../../../settings/Config/Config";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import {RepositorioContableService} from "../../../services/RepositorioContableService/RepositorioContableService";
import {FieldsJs, hideSpinner, showSpinner} from "../../../settings/General/General";
import {DropboxApi} from "../../../settings/Dropbox/DropboxApi";
import {CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {CreditCard, RadioButtonChecked, RadioButtonUnchecked} from "@material-ui/icons";
import "./RepositorioContableSubir.css";
import PropTypes from "prop-types";
import ModalSubirInfo from "./Includes/ModalSubirInfo";
import ListaTabla from "./Includes/ListaTabla";
import Paginacion from "../../Include/Paginacion/Paginacion";


class RepositorioContableSubir extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		let ids_expandido = ReactLocalStorageService.get('ids_expandido');
		
		
		this.state = {
			
			filtro: {
				autorizado: '',
			},
			
			paginacion: {
				total: 0,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			listar_repositorio: [],
			
			ids_expandido: ids_expandido || [],
		};
		
		this.Listar();
	}
	
	handleChangeDropzoneArea = (files) => {
		this.setState({
			files: files
		});
	};
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	
	RefreshList = (ruta) => {
		
		this.Listar(ruta);
	};
	
	
	
	Listar = () => {
		RepositorioContableService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			let listar_repositorio = response.data;
			
			this.setState({
				listar_repositorio: listar_repositorio,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			this.setState({listar_repositorio: []});
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	delete = (item) => {
		
		let msg = `¿Deseas eliminar la factura?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				AccesoService.Eliminar(item.id_facturacion).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	exportar = (item, archivo, auth) => {
		if (item.id_facturacion > 0) {
			let tipo = 2;
			HttpRequest.export('cxid', ['facturacion'], {
				id_facturacion: item.id_facturacion,
				tipo: tipo,
			}, !(tipo === 3))
		} else {
			let fecha_alta = (this.state.filtro.fecha_alta || null) ? DateFormat.FormatSql(this.state.filtro.fecha_alta) : null;
			
			let filtro = {
				id_facturacion: this.state.filtro.id_facturacion || null,
				cliente: this.state.filtro.cliente || null,
				id_cat_forma_pago: this.state.filtro.id_cat_forma_pago || null,
				id_cat_metodo_pago: this.state.filtro.id_cat_metodo_pago || null,
				fecha_alta: fecha_alta,
				ordenar_fecha_alta: this.state.filtro.ordenar_fecha_alta || null,
				
			};
			HttpRequest.export('c', ['facturacion'], {
				auth: auth,
				archivo: archivo,
				filtro: filtro
			}, false, false, false, archivo === "EXCEL")
		}
	};
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	ShowSubTabla = (item, key) => {
		let expandir = !!!item.expandir;
		this.handleChange({
			target: {
				value: expandir,
				name: 'expandir__' + key,
				type: 'text'
			}
		}, 'listar_repositorio', 'expandir');
		let ids_temp = this.state.ids_expandido;
		let ids_expandido = [];
		if (expandir) {
			ids_expandido = ids_temp;
			ids_expandido.push({
				id_facturacion: item.id_facturacion
			});
		} else {
			for (let i = 0; i < ids_temp.length; i++) {
				if (Number(ids_temp[i].id_facturacion) !== Number(item.id_facturacion)) {
					ids_expandido.push({
						id_facturacion: ids_temp[i].id_facturacion
					})
				}
			}
			
		}
		ReactLocalStorageService.set('ids_expandido', ids_expandido);
		this.setState({
			ids_expandido: ids_expandido
		});
	};
	
	SubTabla = (item, key) => {
		this.ShowSubTabla(item, key);
	};
	
	descargarZip = (path) => {
		//path = CONFIG.src + path;
		// let path = this.state.ficha_tecnica_expediente_trabajador[1].archivo[0].archivo;
		console.log("PATH ::: ", path);
		DropboxApi.GenerateZip(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	

	

	
	render() {
		const filterOptions = createFilterOptions({
			matchFrom: 'start',
			stringify: option => option.rfc,
		});
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				<CabeceraTituloPdfExcelLista
					titulo={'Subir Información'}
					botonReadXML={
						<Fragment>
							<ModalSubirInfo
								tipo={'add'}
								componente={
									<Fab variant="extended" size="small" style={{backgroundColor: '#2884a5', color: 'white'}} aria-label="Add">
										<CreditCard className={'margin-5-R px-14'}/>
										Subir Info
									</Fab>
								}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
						</Fragment>
					}
					
					
					/*botonValida={
						<Fragment>
							<ModalNuevaCuenta
								tipo={'add'}
								item={this.state}
								componente={
									<Fab variant="extended" size="small" style={{backgroundColor: '#7fccab', color: 'white'}} aria-label="Add">
										<CreditCard className={'margin-5-R px-14'}/>
										Nueva Cuenta
									</Fab>
								}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
						</Fragment>
					}
					
					botonEXCEL={
						<Fragment>
							<ModalCargaArchivos
								tipo={'add'}
								item={this.state}
								componente={
									<Fab variant="extended" size="small" color="default" aria-label="Add">
										<Description className={'margin-5-R px-14'}/>
										Subir archivo
									</Fab>
								}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
						</Fragment>
					}
					
					botonPDF={
						<Fragment>
							<ModalCrearCarpeta
								tipo={'add'}
								item={this.state}
								componente={
									<Fab variant="extended" size="small" style={{backgroundColor: '#901C30', color: 'white'}} aria-label="Add">
										<Folder className={'margin-5-R px-14'}/>
										Crear carpeta
									</Fab>
								}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
						</Fragment>
					}*/
					
					botonLISTA={
						<Fab variant="extended" size="small" color="primary" aria-label="Add" onClick={() => this.Listar(this.state.ruta)}>
							<Sync className={'margin-5-R px-14'}/>
							Actualizar
						</Fab>
					}
				/>
				
				{/*<FiltrosRepositorioContable
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				*/}
				<div className={'form margin-30-B'}>
					
						<ListaTabla
								lista={this.state.listar_repositorio}
								delete={this.delete}
								filtros={this.state.filtro}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
								SubTabla={this.SubTabla}
								openFolder={this.Listar}
								descargarZip={this.descargarZip}
							/>
						<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
				</div>
				
			</div>
		);
	}
}

RepositorioContableSubir.propTypes = {};

export default IntegrationNotistack(RepositorioContableSubir);
