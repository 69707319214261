import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {RepositorioJuridicoService} from '../../../../services/RepositorioJuridicoService/RepositorioJuridicoService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {Group, CompareArrows, CloudDownload, Cancel} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {CONFIG} from "../../../../settings/Config/Config";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {KeyboardDatePicker} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import ModalCompartir from "./ModalCompartir";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import ModalRepositorioJuridico from "./ModalRepositorioJuridico";
import Table from "@material-ui/core/Table";
import DocSvg from "../../../../assets/img/icons/doc.svg";
import {DropzoneArea} from "material-ui-dropzone";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {LineaCapturaService} from "../../../../services/LineaCapturaService/LineaCapturaService";


class ModalRevocado extends Component {
	
	Usr = {};
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			open: false,
			scroll: 'paper',
			tipo: props.tipo,
			id_repositorio_juridico: (props.item || '').id_repositorio_juridico,
			fecha: null,
			motivo: '',
			id_cat_repositorio_juridico_estatus: '',
			activo: true,
			
			files: [],
			archivos: [],
			
			lista_revocaciones: [],
			lista_cat_repositorio_juridico_estatus: [],
		};
		
		this.listEstatus();
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.listaRepositorioJuridicoEstatus();
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	RefreshList = () => {
		this.props.RefreshList();
		this.listEstatus();
	}
	
	listaRepositorioJuridicoEstatus = () => {
		return new Promise((resolve, reject) => {
			CatService.ListRepositorioJuridicoEstatus(this.state).then((response) => {
				this.setState({
					lista_cat_repositorio_juridico_estatus: response.data
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					lista_cat_repositorio_juridico_estatus: []
				});
			});
		});
	};
	
	listEstatus = () => {
		return new Promise((resolve, reject) => {
			RepositorioJuridicoService.ListarEstatus(this.state).then((response) => {
				this.setState({
					lista_revocaciones: response.data
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					lista_revocaciones: []
				});
				//this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	guardarDocTimbradoDbx = () => {
		
		let files = this.state.files;
		
		return new Promise((resolve, reject) => {
			let band = files.length;
			let band2 = 0;
			let archives = this.state.archivos;
			let file;
			
			for (let i = 0; i < files.length; i++) {
				console.log("2 FTAR::: ", files);
				file = files[i];
				
				let ruta = '/Repositorio_Juridico/Acuses/' + file.name;
				
				DropboxApi.Upload(ruta, file).then(function (response) {
					archives.push({archivo: response.path_display});
					
					band2++;
					
					console.log("DROPBOX UPLOAD: ", response, band, band2);
					
					if (band === band2) {
						resolve(archives);
					}
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	save = () => {
		try {
			if (!FieldsJs.Field(this.state.id_cat_repositorio_juridico_estatus)) {
				throw Object({
					status: false, mensaje: 'Campo estatus es requerido.'
				})
			}
			
			if (!FieldsJs.Field(this.state.fecha)) {
				throw Object({
					status: false, mensaje: 'Campo fecha es requerido.'
				})
			}
			
			if (!FieldsJs.Field(this.state.motivo)) {
				throw Object({
					status: false, mensaje: 'Campo motivo es requerido.'
				})
			}
			if (FieldsJs.inArray(this.state.id_cat_repositorio_juridico_estatus, [3, 5])) {
				if (this.state.files.length === 0) {
					throw Object({
						status: false, mensaje: 'Campo archivo acuse es requerido.'
					})
				}
			}
			
			console.log(this.state.id_cat_repositorio_juridico_estatus, FieldsJs.inArray(this.state.id_cat_repositorio_juridico_estatus, [3, 5]))
			
			if (this.state.files.length > 0) {
				let guardar = new Promise((resolve, reject) => {
					showSpinner('spinner');
					this.guardarDocTimbradoDbx(this.state.files).then(response => {
						this.setState({
							archivos: response,
						});
						resolve(response);
						console.log("DROPBOX UPLOAD: ", this.state);
					}).catch(error => {
						reject(error);
					})
				});
				
				guardar.then(response => {
					RepositorioJuridicoService.EstatusAgregar(this.state).then((response) => {
						this.modalClose();
						this.RefreshList();
						this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
					});
				});
			} else {
				RepositorioJuridicoService.EstatusAgregar(this.state).then((response) => {
					this.modalClose();
					this.RefreshList();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
			
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_repositorio_juridico: item.id_repositorio_juridico || '',
			fecha: null,
			motivo: '',
			id_cat_repositorio_juridico_estatus: '',
			files: [],
			archivos: [],
			activo: true,
		});
		
		this.listEstatus();
		
		this.openModal();
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		const {item} = this.props;
		
		if (this.state.id_repositorio_juridico > 0) {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Cambiar estatus">
					<Button aria-label="Cambiar estatus" onClick={() => this.add()} className={'px-12'} style={{color: '#3e3e3e', fontWeight: 500, textTransform: 'capitalize'}}>
						{item.estatus}
					</Button>
				</Tooltip>
				/*<Tooltip TransitionComponent={Zoom} placement={"top"} title="Compartir">
					<IconButton aria-label="Compartir" onClick={() => this.add()}>
						<CompareArrows/>
					</IconButton>
				</Tooltip>*/
			);
		}
		return BTN_ACTION;
	};
	
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	descargarZip = (path) => {
		console.log("PATH ::: ", path);
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
	};
	
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{'Estatus'}</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<FormControl fullWidth required>
									<InputLabel>Estatus</InputLabel>
									<Select
										native
										value={this.state.id_cat_repositorio_juridico_estatus}
										onChange={this.handleChange}
										name="id_cat_repositorio_juridico_estatus"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.state.lista_cat_repositorio_juridico_estatus.map(option => (
											<option key={option.id_cat_repositorio_juridico_estatus}
											        value={option.id_cat_repositorio_juridico_estatus}>
												{option.repositorio_juridico_estatus}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<KeyboardDatePicker
									format="dd/MM/yyyy"
									fullWidth
									required
									clearable
									inputProps={{readOnly: true}}
									/*KeyboardButtonProps={{
										'aria-label': 'Fecha de pago',
									}}*/
									label={"Fecha"}
									value={this.state.fecha}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									fullWidth
									multiline
									rows={3}
									required
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="motivo"
									label="Motivo"
									value={this.state.motivo}
								/>
							</Grid>
							
							{this.state.lista_revocaciones.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Table className={classes.table + ' desing-acresco'}>
										<TableHead>
											<TableRow>
												<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Estatus</TableCell>
												<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Fecha</TableCell>
												<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Motivo</TableCell>
												<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Acuse</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.lista_revocaciones.map((item, key) => {
												return (
													<Fragment key={key}>
														<TableRow>
															<TableCell align={'left'} style={{paddingLeft: '15px'}}>{item.estatus}</TableCell>
															<TableCell align={'left'} style={{paddingLeft: '15px'}}>{item.fecha}</TableCell>
															<TableCell align={'left'} style={{paddingLeft: '15px'}}>{item.motivo}</TableCell>
															<TableCell align={'left'} style={{paddingLeft: '15px'}}>
																<div className={'vertical-inline b-r-20 margin-5 margin-0-L'}>
																	{item.archivos.length > 0 ? (
																		item.archivos.map((itema) => (
																			<img className={'v-center cursor-pointer'} src={DocSvg} alt="add" width={40} height={40} style={{opacity: 0.7, marginRight: '5px'}} onClick={() => this.descargarZip(itema.archivo)}/>
																		))
																	
																	) : "-"}
																	
																	
																	{/*{this.props.tipo === 'edit' ? (<div className={'vertical-inline v-end b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
																			<div className={'padding-3 v-end text-white cursor-pointer'}>
																				{this.props.tipo === 'view' ? null : (
																					<DeleteOutlined className={'px-16 text-white'} onClick={() => this.eliminar_documento(key)}/>
																				)
																				}
																			</div>
																		</div>) : null}*/}
																</div>
															</TableCell>
														</TableRow>
													</Fragment>
												)
											})
											}
										</TableBody>
									</Table>
								</Grid>
							) : null}
							
							{this.props.tipo !== 'view' ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'dropzone-cronograma margin-15-T'}>
									<div className={'w-100'}>
										<DropzoneArea
											onChange={this.handleChangeDropzoneArea.bind(this)}
											open={this.state.open}
											filesLimit={3}
											dropzoneText={'Arrastre y suelte el archivo de acuse aquí o haga clic'}
											acceptedFiles={[]}
											//acceptedFiles={['application/pdf']}
											showPreviews={false}
											maxFileSize={5000000}
											showAlerts={false}
											showFileNames={true}
											showFileNamesInPreview={true}
										/>
									</div>
								</Grid>
							) : null}
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{'Guardar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalRevocado.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalRevocado);
