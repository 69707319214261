import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DivSvg from "../../../Include/MiniComponents/DivSvg";
import alojamiento_bube from "../../../../assets/img/icons/alojamiento_nube.svg";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {CloudDownload, Folder} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";

class ModalComprobantesNomina extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			tipo: props.tipo,
			project: props.tipo === 'acresco' ? 1 : 0,
			select: '',
		};
	}
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	render() {
		
		
		return (
			<div>
				
				<Dialog open={this.props.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'margin-15-B text-center px-16'} style={{color: 'rgba(22, 91, 165, 0.8)'}}>
									Historial Comprobantes de Nómina
								</Typography>
							</Grid>
							
							<Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
								<Fragment>
									<div className={'w-auto'} align={'center'}>
										<DivSvg img={alojamiento_bube} height={'100px'} width={'100px'} opacity={true}/>
									</div>
								</Fragment>
							</Grid>
							
							<Grid item xs={8} sm={8} md={9} lg={9} xl={9}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<p className={'margin-15-B text-center'} style={{color: 'gray'}}>
											<i style={{color: 'rgba(165,17,19,0.8)'}}> Importante:</i> Las siguientes solicitudes de descarga tienen un tiempo de 72 horas de existencia.
										</p>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Table className={'desing-acresco'}>
									<TableHead>
										<TableRow>
											<TableCell component="th" align={'left'} className={'padding-20-L padding-20-R'}>Id</TableCell>
											<TableCell component="th" align={'center'}>Folio de Descarga</TableCell>
											<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.props.listaComprobantesNomina.map((item, key) => (
											<TableRow key={key}>
												<TableCell align={'left'} className={'padding-20-L padding-20-R'}>
													{key + 1}
												</TableCell>
												
												<TableCell align={'left'} className={'padding-20-L padding-20-R'}>
													<div className={'vertical-inline'}>
														<div className={'v-center padding-15-R'}>
															{item.thumb ? <img src={`data:image/jpeg;base64,${item.thumb.thumbnail}`} width={32}/> : <Folder style={{fontSize: '32px', color: 'rgb(142, 30, 50)'}}/>}
														</div>
														<div className={'v-center'}>
															{item.carpeta}
														</div>
													</div>
												</TableCell>
												
												<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
													<div className={'w-auto vertical-inline'}>
														<div className={'v-center'}>
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Descargar">
																<IconButton aria-label="Descargar" onClick={() => this.props.descargarZipComprobantesNomina(item.carpeta, true)}>
																	<CloudDownload style={{color: 'rgb(142, 30, 50)'}}/>
																</IconButton>
															</Tooltip>
														</div>
													</div>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.props.cancelModalComprobantesNomina} color="primary">
								{'Cerrar'}
							</Button>
						</Fragment>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalComprobantesNomina.propTypes = {
	item: PropTypes.object.isRequired,
	cancelModalComprobantesNomina: PropTypes.func.isRequired,
	descargarZipComprobantesNomina: PropTypes.func.isRequired,
	listaComprobantesNomina: PropTypes.array.isRequired,
};

export default ModalComprobantesNomina;
