import React, {Component, Fragment} from 'react';

import Typography from "@material-ui/core/Typography";
import ListaTabla from './Includes/ListaTabla';
import ModalCronograma from './Includes/ModalCronograma';

import {CronogramaService} from '../../../services/CronogramaService/CronogramaService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../settings/Config/Config";
import Paginacion from "../../Include/Paginacion/Paginacion";
import FiltrosCronograma from "./Includes/FiltrosCronograma";
import {BotonActualizarLista, BotonExportarListaExcel, BotonExportarListaPDF, CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import {ProyectoService} from "../../../services/_Cat/ProyectoService/ProyectoService";
import {ModuloService} from "../../../services/_Cat/ModuloService/ModuloService";
import Sync from "@material-ui/icons/Sync";
import Fab from "@material-ui/core/Fab";
import {Publish} from "@material-ui/icons";
import ModalMasivo from "./Includes/ModalMasivo";


class Cronograma extends Component {
	
	Usr = {};
	
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			listar_cronograma: [],
			filtro: {
				id_cronograma: '',
				id_cat_proyecto: '',
				id_cat_proyecto_modulo: '',
				id_cat_cronograma_estatus: '',
				id_cat_tipo_usuario: '',
				usuario_asignado: '',
				activo: 1
			},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
		};
		
		this.RefreshList();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	Listar = () => {
		CronogramaService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				listar_cronograma: response.data,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			this.setState({listar_cronograma: []});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	Eliminar = (item) => {
		
		let msg = `¿Deseas eliminar el usuario ${item.nombre} ${item.apellido_paterno} ${item.apellido_materno}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				CronogramaService.Eliminar(item.id_usuario).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'Cronograma de Actividades'}
					/*botonPDF={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaPDF onClick={() => {
								
								}}/>
							) : ''}
						</Fragment>
					}*/
					botonEXCEL={
						<ModalMasivo
							componente={
								<Fab variant="extended" size="small" color="primary" aria-label="Add" style={{backgroundColor: "#006300"}} className={'margin-10-L'} onClick={this.RefreshList}>
									<Publish style={{fontSize: 15, marginRight: 5}}/> Importar Actividades
								</Fab>
							}
							RefreshList={this.RefreshList}
							showSnackBars={this.showSnackBars}
						/>
					}
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() => {
								this.Listar();
							}}/>
						</Fragment>
					}
				/>
				
				<FiltrosCronograma
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				
				<div className={'form margin-30-B'}>
					
					<Typography variant={'h6'} className={'margin-30-T margin-30-B'}>
					
					</Typography>
					
					{this.state.listar_cronograma.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_cronograma}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
								//lista_usuarios={this.state.lista_usuarios}
								//lista_proyectos={this.state.lista_proyectos}
								//lista_proyectos_modulos={this.state.lista_proyectos_modulos}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				<ModalCronograma
					tipo={'add'}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>
			
			</div>
		);
	}
}

Cronograma.propTypes = {};

export default IntegrationNotistack(Cronograma);
