import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const BitacoraService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				id_usuario: filtro.id_usuario || null,
				ws: filtro.ws || null,
				accion: filtro.accion || null,
				mensaje: filtro.mensaje || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Bitacora_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
