import React, {Component, Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {$cWarn, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import PictureAsXml from "@material-ui/icons/InsertDriveFile";
import Fab from "@material-ui/core/Fab";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {CONFIG} from "../../../../settings/Config/Config";
import ModalTickets from "./ModalTickets";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import {AssignmentTurnedIn, Cloud, SearchOutlined} from "@material-ui/icons";
import {PopupService} from "../../../../settings/PoPup/PoPup";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 600,
	},
});


class SubTablaClienteClientePotencial extends Component {
	state = {};
	Usr = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			folio: props.item.folio,
			item: props.item,
			listar_requerimiento: props.item.requerimientos || [],
		}
	}
	
	componentDidMount() {
		$cWarn("componentDidMount");
	}
	
	componentWillUnmount() {
		$cWarn("componentWillUnmount");
	}
	
	pasos = () => {
		let curculo = [
			{
				fecha_alta: "12/12/2019",
				serie: '1111111',
				folio: '2222222',
				folio_fiscal: '3333333',
				url_xml_factura: 'http://localhost:3000/facturacion',
				url_pdf_factura: 'http://localhost:3000/facturacion',
			},
			{
				fecha_alta: "12/12/2019",
				serie: '1111111',
				folio: '2222222',
				folio_fiscal: '3333333',
				url_xml_factura: 'http://localhost:3000/facturacion',
				url_pdf_factura: 'http://localhost:3000/facturacion',
			},
		
		];
		return curculo;
	};
	
	
	exportar = (url) => {
		try {
			if (!url) {
				throw Object({
					status: false, mensaje: 'URL Anexo no registrada.'
				})
			}
			
			window.open(url, '_blank');
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	render() {
		
		
		const {classes} = this.props;
		
		return (
			<Fragment>
				<div className={'bg-white card-1'}>
					
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={'px-14 text-center bg-white padding-5'}>
							Ticket: {this.state.folio}
						</Typography>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Table className={classes.table + ' desing-integraciones'}>
							<TableHead>
								<TableRow>
									<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Fecha Solicitud</TableCell>
									<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Fecha Agendada</TableCell>
									<TableCell component="th" align={'left'}>Usuario Asignado / Solicitante</TableCell>
									<TableCell component="th" align={'left'}>Estatus</TableCell>
									<TableCell component="th" align={'left'}>Prioridad</TableCell>
									<TableCell component="th" align={'left'}>Empresa Relacionada</TableCell>
									<TableCell component="th" align={'left'}>Requerimiento</TableCell>
									<TableCell component="th" align={'center'}>URL Anexo</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{this.state.listar_requerimiento.map((item, key) => {
									return (
										<Fragment key={key}>
											<TableRow>
												<TableCell align={'left'} style={{paddingLeft: '15px'}}>{DateFormat.getHoraFecha(this.state.item.fecha_solucion)}</TableCell>
												<TableCell align={'left'} style={{paddingLeft: '15px'}}>{DateFormat.getHoraFecha(item.fecha_solicitud)}</TableCell>
												<TableCell align={'left'}>{item.is_asignado ? item.nombre_completo_solicitante:item.usuario_asignado}</TableCell>
												<TableCell align={'left'}>{item.ticket_status}</TableCell>
												<TableCell align={'left'}>{item.prioridad}</TableCell>
												<TableCell align={'left'}>{item.nombre_comercial}</TableCell>
												<TableCell align={'left'}>{item.requerimiento.substring(0, 30)}</TableCell>
												<TableCell align={'center'}>
													<Tooltip TransitionComponent={Zoom} placement={"top"} onClick={() => this.exportar(item.url_ticket_requerimiento)}
													         title="URL Anexo">
														<IconButton aria-label="URL Anexo">
															<Cloud/>
														</IconButton>
													</Tooltip>
												</TableCell>
											</TableRow>
										</Fragment>
									);
								})
								}
							</TableBody>
						</Table>
					</Grid>
				
				</div>
			</Fragment>
		);
	}
}

SubTablaClienteClientePotencial.propTypes = {
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired
};

export default withStyles(styles)(SubTablaClienteClientePotencial);
