import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {EmpresaPlazaOficinaService} from "../../../../services/ComprasService/EmpresaPlazaOficinaService";
import {
    BotonAccionAbrirModal,
    BotonAccionFooterModal,
    TituloHeaderModal
} from "../../../Include/MiniComponents/GlobalComponent";
import {
    AlternateEmailOutlined,
    Business,
    HdrStrongOutlined,
    HowToVoteOutlined,
    PhoneOutlined,
    TurnedInNotOutlined
} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {GiroClienteService} from "../../../../services/_Cat/GiroClienteService/GiroClienteService";
import {EmpresaService} from "../../../../services/_Cat/EmpresaService/EmpresaService";
import {CatService} from "../../../../services/_Cat/CatService/CatService";

class ModalEmpresa extends Component {

    state = {};

    constructor(props) {
        super(props);

        this.state = {
            modal: {
                open: false
            },

            tipo: props.tipo,

            id_empresa: '',
            plaza_direccion: (props.item || {}).plaza_direccion,
            nombre_comercial: '',
            razon_social: '',
            rfc: '',
            id_plaza: '',
            id_plaza_direccion: '',
            correo_electronico: '',
            telefono: '',
            id_cat_giro_cliente: '',

            listar_cat_giro: [],
            listar_empresa: [],
            disabledForm: false,

            activo: true,
        };
    }

    handleChange = (e, variable, campo, date, input) => {
        FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
            [r.name]: r.value
        }));
    };

    handleChangeEmpresa = (id_empresa) => {
        var empresa;
        console.log('empresa', empresa);

        console.log(this.state.id_empresa);
        for (let i = 0; i < this.state.listar_empresa.length; i++) {
            if (Number(this.state.listar_empresa[i].id_empresa) === Number(id_empresa)) {
                empresa = this.state.listar_empresa[i];
            }
        }
        console.log('empresa', empresa);
        if (empresa) {
            this.setState({
                nombre_comercial: empresa.nombre_comercial,
                rfc: empresa.rfc,
                razon_social: empresa.razon_social,
                correo_electronico: empresa.correo_electronico,
                id_cat_giro_cliente: empresa.id_cat_giro_cliente,
                telefono: empresa.telefono,
                disabledForm: true
            });
        } else {
            this.setState({
                nombre_comercial: '',
                rfc: '',
                razon_social: '',
                correo_electronico: '',
                id_cat_giro_cliente: '',
                telefono: '',
                disabledForm: false
            });
        }
    };

    changeValue = (arr_name__key, var_name, var_value) => {
        FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
            [r.name]: r.value
        }), this.state);
    };

    closeModal = () => {
        this.setState({
            modal: {
                open: false
            }
        });
    };

    close = () => {
        this.closeModal();
    };

    openModal = () => {
        const props = this.props;
        this.setState({
            modal: {
                open: true
            }
        });
        console.log(this.state, props);
    };

    open = () => {
        const {item} = this.props;
        console.log('PROPS', item);
        this.ListaEmpresa();

        if (item.id_plaza > 0) {
            if (item.id_plaza_direccion > 0) {
                this.init().then(r => {
                    setTimeout(() => this.openModal());
                    this.ListarGiros();
                }).catch(e => {
                    console.log(e);
                });
            } else {
                this.props.showSnackBars('error', 'Oficina requerida.');
            }
        } else {
            this.props.showSnackBars('error', 'Plaza requerida.');
        }

    };

    ListarGiros = () => {
        GiroClienteService.Listar().then((response) => {
            this.setState({
                listar_cat_giro: response.data
            });
        }).catch((error) => {
            this.setState({
                listar_cat_giro: []
            });
            this.showSnackBars('error', error.mensaje);
        });
    };


    ListaEmpresa = () => {
        CatService.ListEmpresa().then(value => {
            this.setState({
                listar_empresa: value.data
            });
        }).catch(reason => {
            this.setState({
                listar_empresa: []
            });
        });
    };


    RefreshInit = () => {
        this.init();
    };

    init = () => {
        return new Promise((resolve) => {
            resolve(true);
        });
    };

    save = () => {
        EmpresaPlazaOficinaService.Guardar(this.state).then((response) => {
            this.close();
            this.props.RefreshList(response.data);
            this.props.showSnackBars('success', response.mensaje);
        }).catch((error) => {
            this.props.showSnackBars('error', error.mensaje);
        });
    };

    add = () => {
        console.log(this.props);
        this.setState({
            id_empresa: '',
            id_plaza: this.props.item.id_plaza,
            id_plaza_direccion: this.props.item.id_plaza_direccion,
            plaza_direccion: this.props.item.plaza_direccion,
            nombre_comercial: '',
            razon_social: '',
            rfc: '',
            correo_electronico: '',
            telefono: '',
            id_cat_giro_cliente: '',
            activo: true,
        });
        this.open();
    };

    edit = () => {
        const {item} = this.props;
        console.log(this.props);
        this.setState({
            id_empresa: item.id_empresa || '',
            nombre_comercial: item.nombre_comercial || '',
            razon_social: item.razon_social || '',
            rfc: item.rfc || '',
            correo_electronico: item.correo_electronico || '',
            telefono: item.telefono || '',
            id_cat_giro_cliente: item.id_cat_giro_cliente || '',

            activo: (item.activo === 1),
        });
        this.open();
    };

    view = () => {
        const {item} = this.props;
        console.log(this.props);
        this.setState({
            id_empresa: item.id_empresa || '',
            nombre_comercial: item.nombre_comercial || '',
            razon_social: item.razon_social || '',
            rfc: item.rfc || '',
            correo_electronico: item.correo_electronico || '',
            telefono: item.telefono || '',
            id_cat_giro_cliene: item.id_cat_giro_cliente || '',
            activo: (item.activo === 1),
        });
        this.open();
    };

    render() {

        let funcion;

        switch (this.state.tipo) {
            case 'edit':
                funcion = this.edit;
                break;
            case 'add':
                funcion = this.add;
                break;
            case 'view':
                funcion = this.view;
                break;
            default:
                funcion = () => {
                };
        }

        return (
            <div>

                {this.props.componente ? (
                    <Fragment>
						<span
                            style={{cursor: 'pointer'}}
                            children={this.props.componente}
                            onClick={() => funcion()}
                        />
                    </Fragment>
                ) : (
                    <BotonAccionAbrirModal
                        id={this.state.id_empresa}
                        tipo={this.state.tipo}
                        onClick={() => funcion()}
                    />
                )}

                <Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
                        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
                        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
                >

                    <DialogTitle>
                        <TituloHeaderModal
                            tipo={this.state.tipo}
                            titulo={'Empresa'}
                        />
                    </DialogTitle>

                    <DialogContent>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
                                    <Grid item style={{textAlign: 'center', color: '#3f51b5', width: 'calc(100%)'}}>

                                        <label>{this.state.plaza_direccion}</label>

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container spacing={1} alignItems={"flex-end"}>
                                    <Grid item className={'w-30-px'}>
                                        <Business className={'w-100 text-gray'}/>
                                    </Grid>
                                    <Grid item className={'w-100-30-px'}>
                                        <FormControl fullWidth>
                                            <InputLabel>Empresa</InputLabel>
                                            <Select
                                                native
                                                value={this.state.id_empresa}
                                                onChange={(e) => {
                                                    this.handleChange(e);
                                                    this.handleChangeEmpresa(e.target.value);
                                                }}
                                                name="id_empresa"
                                                input={<Input/>}
                                                required
                                                disabled={this.props.tipo === 'view'}
                                            >
                                                <option value=""/>
                                                {this.state.listar_empresa.map(option => (
                                                    <option key={option.id_empresa}
                                                            value={option.id_empresa}>
                                                        {option.nombre_comercial}
                                                    </option>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Grid container spacing={1} alignItems={"flex-end"}>
                                    <Grid item className={'w-30-px'}>
                                        <TurnedInNotOutlined className={'w-100 text-gray'}/>
                                    </Grid>
                                    <Grid item className={'w-100-30-px'}>
                                        <TextField type={'text'} fullWidth name="nombre_comercial"
                                                   onChange={this.handleChange}
                                                   label="Nombre comercial" autoComplete={'off'}
                                                   value={this.state.nombre_comercial}
                                                   required
                                                   disabled={this.state.disabledForm}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Grid container spacing={1} alignItems={"flex-end"}>
                                    <Grid item className={'w-30-px'}>
                                        <TurnedInNotOutlined className={'w-100 text-gray'}/>
                                    </Grid>
                                    <Grid item className={'w-100-30-px'}>
                                        <TextField type={'text'} fullWidth name="razon_social"
                                                   onChange={this.handleChange}
                                                   label="Razón social" autoComplete={'off'}
                                                   value={this.state.razon_social}
                                                   required
                                                   disabled={this.state.disabledForm}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Grid container spacing={1} alignItems={"flex-end"}>
                                    <Grid item className={'w-30-px'}>
                                        <AlternateEmailOutlined className={'w-100 text-gray'}/>
                                    </Grid>
                                    <Grid item className={'w-100-30-px'}>
                                        <TextField type={'text'} fullWidth name="correo_electronico"
                                                   onChange={this.handleChange}
                                                   label="Correo electrónico" autoComplete={'off'}
                                                   value={this.state.correo_electronico}
                                                   required
                                                   // disabled={this.state.disabledForm}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Grid container spacing={1} alignItems={"flex-end"}>
                                    <Grid item className={'w-30-px'}>
                                        <PhoneOutlined className={'w-100 text-gray'}/>
                                    </Grid>
                                    <Grid item className={'w-100-30-px'}>
                                        <TextField type={'text'} fullWidth name="telefono"
                                                   onChange={this.handleChange}
                                                   inputProps={{maxLength: 10}}
                                                   onKeyPress={EnteroSolo}
                                                   label="Teléfono" autoComplete={'off'}
                                                   value={this.state.telefono}
                                                   disabled={this.state.disabledForm}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Grid container spacing={1} alignItems={"flex-end"}>
                                    <Grid item className={'w-30-px'}>
                                        <HowToVoteOutlined className={'w-100 text-gray'}/>
                                    </Grid>
                                    <Grid item className={'w-100-30-px'}>
                                        <TextField type={'text'} fullWidth name="rfc"
                                                   onChange={this.handleChange}
                                                   inputProps={{maxLength: 13}}
                                                   label="R.F.C." autoComplete={'off'}
                                                   value={this.state.rfc}
                                                   required
                                                   disabled={this.state.disabledForm}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item className={'text-left'} xs={12} sm={6} md={12} lg={12} xl={12}>
                                <Grid container spacing={1} alignItems={"flex-end"}>
                                    <Grid item className={'w-30-px'}>
                                        <HdrStrongOutlined className={'w-100 text-gray'}/>
                                    </Grid>
                                    <Grid item className={'w-100-30-px'}>

                                        <FormControl fullWidth>
                                            <InputLabel>Giro</InputLabel>
                                            <Select
                                                native
                                                value={this.state.id_cat_giro_cliente}
                                                onChange={this.handleChange}
                                                name="id_cat_giro_cliente"
                                                input={<Input/>}
                                                disabled={this.state.disabledForm}
                                            >
                                                <option value=""/>
                                                {this.state.listar_cat_giro.map(option => (
                                                    <option key={option.id_cat_giro_cliente}
                                                            value={option.id_cat_giro_cliente}>
                                                        {option.giro_cliente}
                                                    </option>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <FormGroup row className={'margin-3-L'}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                type="checkbox"
                                                disabled={this.state.disabledForm}
                                                onChange={this.handleChange}
                                                color="primary"
                                                name='activo'
                                                checked={this.state.activo}
                                                value="activo"
                                            />
                                        }
                                        label={'Activo'}
                                    />
                                </FormGroup>

                            </Grid>

                        </Grid>

                    </DialogContent>

                    <Divider/>

                    <DialogActions>

                        <BotonAccionFooterModal
                            id={''}
                            tipo={''}
                            close={this.close}
                            save={this.save}
                        />

                    </DialogActions>

                </Dialog>

            </div>
        );
    }
}

ModalEmpresa.propTypes = {
    componente: PropTypes.element,
    tipo: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    RefreshList: PropTypes.func.isRequired,
    showSnackBars: PropTypes.func.isRequired,
};

export default ModalEmpresa;
