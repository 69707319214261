import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import {FichaTecnicaService} from "../../../../services/FichaTecnicaService/FichaTecnicaService";
import ClienteReceptoraPagoService
	from '../../../../services/_Cat/ClienteReceptoraPagoService/ClienteReceptoraPagoService';
import {FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Fab from "@material-ui/core/Fab";
import {
	AddOutlined,
	ArrowForwardOutlined,
	CancelOutlined,
	DeleteOutlineOutlined,
	DoneOutlined,
	EditOutlined,
	PictureAsPdfOutlined,
	SearchOutlined,
	SendOutlined,
	SyncOutlined,
} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import ModalClienteReceptoraPago from "../../Cat/ClienteReceptoraPago/Includes/ModalClienteReceptoraPago";
import {ClientePotencialService} from "../../../../services/ClientePotencialService/ClientePotencialService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import ModalClienteReceptoraPagoDatoBancario
	from "../../Cat/ClienteReceptoraPagoDatoBancario/Includes/ModalClienteReceptoraPagoDatoBancario";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import TextField from "@material-ui/core/TextField";


class EnviarCorreoDatosBancariosClientesReceptorasPago extends Component {
	
	state = {};
	
	Usr = {};
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			id_cliente: props.id_cliente,
			
			to: '',
			cc: '',
			to_name:'',
			subject:'',
			mensaje:'',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
			id_cliente: this.props.id_cliente
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	open = () => {
		setTimeout(() => this.openModal());
	};
	
	close = () => {
		this.closeModal();
	};
	
	exportaryenviar = (item, archivo, auth, email) => {
		try {
			if (!this.state.to) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Para"
				});
			}
			if (!this.state.to_name) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Nombre completo"
				});
			}
			if (!this.state.subject) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Asunto"
				});
			}
			if (!this.state.mensaje) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Mensaje"
				});
			}
			let params = {
				auth: auth,
				archivo: archivo,
				id_cliente: this.state.id_cliente,
				email: email,
				to: this.state.to || '',
				cc: this.state.cc || '',
				to_name: this.state.to_name || '',
				subject: this.state.subject || '',
				mensaje: this.state.mensaje || '',
			};
			HttpRequest.export('erdp', ['empresa', 'de', 'receptora', 'pago'], params, false, false, false, archivo === "EXCEL", (response) => {
				this.showSnackBars('success', response.message);
				this.setState({
					to: '',
					cc: '',
					to_name:'',
					subject:'',
					mensaje:'',
				});
			}, (error) => {
				this.showSnackBars('error', error.message);
			});
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	render() {
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.componente}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Typography className={'px-20'}>
									Enviar por correo electrónico
								</Typography>
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								
								<TextField
									margin="none"
									name="to"
									label="Para"
									type="text"
									fullWidth
									value={this.state.to}
									onChange={this.handleChange}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								
								<TextField
									margin="none"
									name="to_name"
									label="Nombre completo"
									type="text"
									fullWidth
									value={this.state.to_name}
									onChange={this.handleChange}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								
								<TextField
									margin="none"
									name="cc"
									label="CC"
									type="text"
									fullWidth
									value={this.state.cc}
									onChange={this.handleChange}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								
								<TextField
									margin="none"
									name="subject"
									label="Asunto"
									type="text"
									fullWidth
									value={this.state.subject}
									onChange={this.handleChange}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									multiline
									margin="none"
									name="mensaje"
									label="Mensaje"
									type="text"
									fullWidth
									value={this.state.mensaje}
									onChange={this.handleChange}
								/>
							
							</Grid>
							
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={1}>
							<Grid item xs={6} align={'left'}>
								<Button
									color="primary"
									onClick={() => this.close()}
								>
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								<Button
									color="secondary"
									onClick={() => this.exportaryenviar({}, 'PDF', true, 1)}
								>
									Enviar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

EnviarCorreoDatosBancariosClientesReceptorasPago.propTypes = {
	id_cliente: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	componente: PropTypes.element.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default EnviarCorreoDatosBancariosClientesReceptorasPago;
