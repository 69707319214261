import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {AccountTree, DateRangeOutlined, HdrStrong, MailOutline, PersonOutlined, SearchOutlined, ViewDay} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {CatService as TipoUsuarioServiceService, CatService} from "../../../../services/_Cat/CatService/CatService";
import {TipoUsuarioService} from "../../../../services/_Cat/TipoUsuarioService/TipoUsuarioService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import Input from "@material-ui/core/Input";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ModalProyecto from "../../Cat/Proyecto/Includes/ModalProyecto";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Add from "@material-ui/icons/Add";
import ModalModulo from "../../Cat/Modulo/Includes/ModalModulo";
import {ProyectoService} from "../../../../services/_Cat/ProyectoService/ProyectoService";
import {ModuloService} from "../../../../services/_Cat/ModuloService/ModuloService";
import TableCell from "@material-ui/core/TableCell";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";


class FiltrosCronograma extends Component {
	
	Usr = {};
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			usuario: '',
			correo_electronico: '',
			id_cat_tipo_usuario: '',
			id_cat_proyecto: '',
			id_cat_proyecto_modulo: '',
			id_cat_cronograma_estatus: '',
			usuario_asignado: '',
			fecha_inicio: null,
			fecha_fin: null,
			activo: '',
			
			lista_cat_tipo_usuario: [],
			lista_proyectos: [],
			lista_proyectos_modulos: [],
			lista_cronograma_estatus: [],
			
		};
		
		this.ListaTipoUsuario();
		this.ListProyectos();
		this.ListCronogramaEstatus();
	}
	
	ListaTipoUsuario = () => {
		TipoUsuarioService.Listar().then((response) => {
			this.setState({
				lista_cat_tipo_usuario: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_cat_tipo_usuario: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListCronogramaEstatus = () => {
		return new Promise((resolve, reject) => {
			CatService.ListCronogramaEstatus().then((response) => {
				this.setState({
					lista_cronograma_estatus: response.data,
					id_cat_cronograma_estatus: ''
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_cronograma_estatus: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListProyectos = () => {
		return new Promise((resolve, reject) => {
			CatService.ListProyecto().then((response) => {
				this.setState({
					lista_proyectos: response.data,
					id_cat_proyecto_modulo: ''
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_proyectos: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListProyectoModulos = (id_cat_proyecto) => {
		return new Promise((resolve, reject) => {
			CatService.ListProyectoModulo(id_cat_proyecto).then((response) => {
				this.setState({
					lista_proyectos_modulos: response.data
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_proyectos_modulos: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.usuario = this.state.usuario || undefined;
		filtro.correo_electronico = this.state.correo_electronico || undefined;
		filtro.id_cat_tipo_usuario = this.state.id_cat_tipo_usuario || undefined;
		filtro.id_cat_proyecto = this.state.id_cat_proyecto || undefined;
		filtro.id_cat_proyecto_modulo = this.state.id_cat_proyecto_modulo || undefined;
		filtro.id_cat_cronograma_estatus = this.state.id_cat_cronograma_estatus || undefined;
		filtro.activo = this.state.activo || undefined;
		filtro.fecha_inicio = this.state.fecha_inicio || undefined;
		filtro.fecha_fin = this.state.fecha_fin || undefined;
		filtro.usuario_asignado = this.state.usuario_asignado || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					{ FieldsJs.inArray([1,2,13], this.Usr.id_cat_tipo_usuario) ? (
						<Grid item xs={6} sm={6} md={2} lg={2} xl={2} align={'left'}>
							<Grid container spacing={1} alignItems={"flex-end"}>
								<Grid item className={'w-30-px'}>
									<AccountTree className={'w-100 text-gray'}/>
								</Grid>
								<Grid item className={'w-100-30-px'}>
									<FormControl fullWidth>
										<InputLabel htmlFor="age-native-activo">Área</InputLabel>
										<Select
											native
											value={this.state.id_cat_tipo_usuario}
											onChange={(e) => {
												this.handleChange(e);
											}}
											name="id_cat_tipo_usuario"
											input={<Input/>}
										>
											<option value=""/>
											{this.state.lista_cat_tipo_usuario.map(option => (
												<option key={option.id_cat_tipo_usuario}
												        value={option.id_cat_tipo_usuario}>
													{option.tipo_usuario}
												</option>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
					) : null }
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2} align={'left'}>
						<MDDatePicker
							type={'range'}
							component={(
								<TextField
									fullWidth
									label="Fecha"
									value={(this.state.fecha_inicio && this.state.fecha_fin) ? (DateFormat.FormatText(this.state.fecha_inicio) + " al " + DateFormat.FormatText(this.state.fecha_fin)) : ''}
									readOnly
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton aria-label="Fecha">
													<DateRangeOutlined/>
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							)}
							startDate={this.state.fecha_inicio}
							endDate={this.state.fecha_fin}
							onChange={(range) => {
								console.log("RANGE", range);
								this.setState({
									fecha_inicio: range.startDate,
									fecha_fin: range.endDate,
								});
								setTimeout(() => this.HandleFiltro());
							}}
						/>
					</Grid>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={1} lg={1} xl={1}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth margin="dense" className={'margin-0'}>
									<InputLabel>Proyecto</InputLabel>
									<Select
										native
										value={this.state.id_cat_proyecto}
										onChange={(e) => {
											this.handleChange(e);
											this.ListProyectoModulos(e.target.value);
										}}
										name="id_cat_proyecto"
										input={<Input/>}
									>
										<option value=""/>
										{this.state.lista_proyectos.map(option => (
											<option key={option.id_cat_proyecto}
											        value={option.id_cat_proyecto}>
												{option.proyecto}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth margin="dense" className={'margin-0'}>
									<InputLabel>Módulo</InputLabel>
									<Select
										native
										value={this.state.id_cat_proyecto_modulo}
										onChange={(e) => {
											this.handleChange(e);
										}}
										name="id_cat_proyecto_modulo"
										input={<Input/>}
										disabled={ !this.state.id_cat_proyecto}
									>
										<option value=""/>
										{this.state.lista_proyectos_modulos.map(option => (
											<option key={option.id_cat_proyecto_modulo}
											        value={option.id_cat_proyecto_modulo}>
												{option.proyecto_modulo}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth margin="dense" className={'margin-0'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											className={'margin-0'}
											margin="dense"
											name="usuario_asignado"
											label="Usuario Asignado"
											value={this.state.usuario_asignado}
										/>
									</Grid>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={1} lg={1} xl={1}>
						<Grid container spacing={2} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth margin="dense" className={'margin-0'}>
									<InputLabel>Estatus</InputLabel>
									<Select
										native
										value={this.state.id_cat_cronograma_estatus}
										onChange={(e) => {
											this.handleChange(e);
										}}
										name="id_cat_cronograma_estatus"
										input={<Input/>}
									>
										<option value=""/>
										{this.state.lista_cronograma_estatus.map(option => (
											<option key={option.id_cat_cronograma_estatus}
											        value={option.id_cat_cronograma_estatus}>
												{option.cronograma_estatus}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					{/*<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel htmlFor="age-native-activo">Activo</InputLabel>
									<Select
										native
										value={this.state.activo}
										onChange={this.handleChange}
										inputProps={{
											name: 'activo',
											id: 'age-native-activo',
										}}
									>
										<option value={'all'}>Todos</option>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>*/}
					
					<Grid item xs={12} sm={12} md={12} lg={2} xl={2} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosCronograma.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosCronograma;
