import React, {Component} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import {ComprasService} from "../../../../services/ComprasService/ComprasService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import Avatar from "@material-ui/core/Avatar";
import {Icono} from "../../../Include/Icono/Icono";
import ModalImage from "../../../Include/ModalImage/ModalImage";
import Box from "@material-ui/core/Box";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";

import lineas_de_texto_svg from '../../../../assets/img/icons/lineas-de-texto.svg'
import {KeyboardDatePicker} from "@material-ui/pickers";


class ModalComprobantes extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			id_compra: props.id_compra,
			
			descripcion: '',
			fecha_pago: null,
			total: '',
			
			comprobante_base64: '',
			comprobante_base64Tipo: '',
			comprobante_archivo: '',
			comprobante_formato: '',
			
			compra_producto: [],
			
			compra_comprobante: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.setState({
			comprobante_base64: '',
			comprobante_base64Tipo: '',
			comprobante_archivo: '',
			comprobante_formato: '',
		});
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			if (this.props.id_compra > 0) {
				ComprasService.Detalles(this.props.id_compra).then((response) => {
					let item = FieldsJs.Copy(response.data);
					let fecha_pago = item.fecha_pago ? moment(item.fecha_pago) : null;
					this.setState({
						id_compra: item.id_compra,
						
						descripcion: item.descripcion || '',
						fecha_pago: fecha_pago,
						total: item.total || '',
						
						compra_producto: item.compra_producto || [],
						
						compra_comprobante: item.compra_comprobante || [],
					});
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false)
				});
			} else {
				resolve(true);
			}
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	
	subir_comprobante = () => {
		try {
			
			if (!this.state.comprobante_formato) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Selecciona el comprobante.'
				})
			}
			
			if (!this.state.comprobante_archivo) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Selecciona un comprobante.'
				})
			}
			
			try {
				ComprasService.SubirComprobante(this.state).then((response) => {
					this.props.RefreshList();
					this.RefreshInit();
					this.setState({
						comprobante_base64: '',
						comprobante_base64Tipo: '',
						comprobante_archivo: '',
						comprobante_formato: '',
					});
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			} catch (e) {
				this.props.showSnackBars('error', e.mensaje);
			}
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	render() {
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Comprobante de Pago
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={"center"}>
								{this.state.compra_comprobante.length > 0 ? (
									<Box
										display="flex"
										flexWrap="wrap"
										alignContent="flex-start"
										justifyContent="center"
										alignItems="center"
										p={1}
										m={0}
										css={{
											background: "#c2ecff",
											borderRadius: "10px",
											borderStyle: "dashed",
											borderWidth: "1px !important",
											borderColor: "#3f51b5",
										}}
									>
										{this.state.compra_comprobante.map((value, index) => (
											<Box key={index} p={1} bgcolor="blue.300">
												<Avatar style={{width: 80, height: 80, backgroundColor: "ghostwhite"}}>
													<div align={"center"}>
														{Icono.get(value.comprobante_formato, '30px', '30px')}
														<br/>
														<ModalImage
															tipo={'view'} desing={3}
															item={{
																base64: value.comprobante_base64 || '',
																base64Tipo: value.comprobante_base64Tipo || '',
																archivo: value.comprobante_archivo || '',
																formato: value.comprobante_formato || '',
															}}
														/>
													</div>
												</Avatar>
											</Box>
										))}
									</Box>
								) : (
									<VistaVacia
										numero={null}
										mensaje={'No se encontraron comprobantes.'}
										image={lineas_de_texto_svg}
										padding={'10px'}
										paddingText={'10px'}
										height={'50px'}
										width={'50px'}
									/>
								)}
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<KeyboardDatePicker
									format={'dd/MM/yyyy'}
									fullWidth
									clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha pago',
									}}
									label={"Fecha pago"}
									value={this.state.fecha_pago}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_pago');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									disabled={this.is_view()}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<BoxSelectFile
									MegaByte={5}
									// button={(
									// 	<AddOutlined/>
									// )}
									label={(
										<b className={'vertical-inline v-center text-blue'}>Comprobante</b>
									)}
									style={{
										color: 'black',
										height: '115px'
									}}
									item={{
										base64: this.state.comprobante_base64,
										base64Tipo: this.state.comprobante_base64Tipo,
										archivo: this.state.comprobante_archivo,
										formato: this.state.comprobante_formato,
									}}
									onChange={(r) => {
										this.handleChange({
											target: {
												name: 'comprobante_base64',
												value: r.base64
											}
										});
										this.handleChange({
											target: {
												name: 'comprobante_base64Tipo',
												value: r.base64Tipo
											}
										});
										this.handleChange({
											target: {
												name: 'comprobante_archivo',
												value: r.archivo
											}
										});
										this.handleChange({
											target: {
												name: 'comprobante_formato',
												value: r.formato
											}
										});
									}}
									showSnackBars={this.props.showSnackBars}
								/>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								<Button onClick={() => this.subir_comprobante()} color="primary" variant={"contained"}>
									Subir
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ModalComprobantes.propTypes = {
	id_compra: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalComprobantes;
