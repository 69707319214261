import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../../settings/DateFormat/DateFormat";

class EmpresaAcrescoCertificadoService {
	static Listar = (id_empresa) => {
		let params = {
			id_empresa: id_empresa
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Empresa_Acresco_Certificado_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Agregar = (form) => {
		let fecha_emision = form.fecha_emision ? DateFormat.FormatSql(form.fecha_emision) : null;
		let fecha_apertura = form.fecha_apertura ? DateFormat.FormatSql(form.fecha_apertura) : null;
		
		let params = {
			id_empresa_sucursal: null,
			id_empresa: form.id_empresa,
			fecha_emision: fecha_emision || '',
			fecha_apertura: fecha_apertura || '',
			archivo: form.archivos.length > 0 ? form.archivos[0].archivo : '',
			id_cat_empresa_sucursal: form.id_cat_empresa_sucursal || '',
			direccion: form.direccion || '',
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Empresa_Acresco_Certificado_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Modificar = (form) => {
		let fecha_emision = form.fecha_emision ? DateFormat.FormatSql(form.fecha_emision) : null;
		let fecha_apertura = form.fecha_apertura ? DateFormat.FormatSql(form.fecha_apertura) : null;
		
		let params = {
			id_empresa_sucursal: form.id_empresa_sucursal,
			id_empresa: form.id_empresa,
			fecha_emision: fecha_emision || '',
			fecha_apertura: fecha_apertura || '',
			archivo: form.archivos.length > 0 ? form.archivos[0].archivo : '',
			id_cat_empresa_sucursal: form.id_cat_empresa_sucursal || '',
			direccion: form.direccion || '',
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Empresa_Acresco_Certificado_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	static Eliminar = (id_repositorio_juridico, id_empresa) => {
		let params = {
			id_repositorio_juridico: id_repositorio_juridico,
			id_empresa: id_empresa
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Empresa_Acresco_Certificado_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
}

export default EmpresaAcrescoCertificadoService;
