import React, {Component, Fragment} from 'react';

import Typography from "@material-ui/core/Typography";
import ListaTabla from './Includes/ListaTabla';
import ModalRepse from './Includes/ModalRepse';

import {RepseService} from '../../../services/RepseService/RepseService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../settings/Config/Config";
import Paginacion from "../../Include/Paginacion/Paginacion";
import FiltrosRepse from "./Includes/FiltrosRepse";
import {BotonActualizarLista, BotonExportarListaExcel, BotonExportarListaPDF, CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import {CargaCFDIService} from "../../../services/CargaCFDIService/CargaCFDIService";
import Fab from "@material-ui/core/Fab";
import {PostAdd} from "@material-ui/icons";
import ModalDescargaCFDI from "../CargaCFDI/Includes/ModalDescargaCFDI";
import ModalDescargaRepse from "./Includes/ModalDescargaRepse";
import ModalRepseAcuse from "./Includes/ModalRepseAcuse";


class Repse extends Component {
	
	Usr = {};
	
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			listar_cronograma: [],
			filtro: {
				id_empresa: '',
				rfc: '',
				tipo: '',
				numero_registro: '',
				fecha_registro: null,
				ejercicio: '',
				periodo: '',
				id_cat_tipo_impuesto: '',
				id_cat_tipo_declaracion: '',
				activo: 1
			},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			lista_empresa: [],
			listar_usuarios: [],
			
			band_download_linea_captura: false,
			directorio: ''
		};
		
		this.RefreshList();
		this.ListEmpresa();
		this.listarUsuarios();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	listarUsuarios = () => {
		CatService.ListUsuario().then(response => {
			this.setState({
				listar_usuarios: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_usuarios: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	Listar = () => {
		RepseService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				listar_cronograma: response.data,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			this.setState({listar_cronograma: []});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	Eliminar = (item) => {
		
		let msg = `¿Deseas eliminar el usuario ${item.nombre} ${item.apellido_paterno} ${item.apellido_materno}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				RepseService.Eliminar(item.id_usuario).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	ListEmpresa = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpresa().then((response) => {
				this.setState({
					lista_empresa: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	exportar = (item, archivo, auth) => {
		let filtro = {
			id_empresa: this.state.id_empresa || null,
			ejercicio: this.state.ejercicio || null,
			periodo: this.state.periodo || null,
			id_cat_tipo_impuesto: this.state.id_cat_tipo_impuesto || null,
			id_cat_tipo_declaracion: this.state.id_cat_tipo_declaracion || null,
			rfc: this.state.rfc || null,
			tipo: this.state.tipo || null,
			fecha_registro: this.state.fecha_registro || null,
			numero_registro: this.state.numero_registro || null,
			activo: this.state.activo || null
		};
		console.log('EXPORTAR::: ', item);
		HttpRequest.export('repse', ['repse'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, false, false, false, archivo === "EXCEL")
	};
	
	ListarDonwload = () => {
		RepseService.ListarDownload(this.state.filtro).then((response) => {
			let data = response.data;
			if (data.length > 0) {
				this.setState({
					band_download_linea_captura: true,
					directorio: response.data
				});
			} else {
				this.showSnackBars('error', response.mensaje);
			}
			
			/*setTimeout(() => {
				this.exportarDescargar({}, 'ZIP', true)
			}, 1000);*/
			
		}).catch((error) => {
			
			this.setState({listar_facturacion: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	cancelModalDescarga = () => {
		this.setState({
			band_download_linea_captura: false
		});
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'REPSE'}
					botonEXCEL={
						<Fragment>
							<BotonExportarListaExcel onClick={() => this.exportar(this.state.filtro, 'EXCEL', true)}/>
						</Fragment>
					}
					/*botonDropbox={
						<Fragment>
							<Fab className={'vertical-inline'} variant="extended" size="small" color="primary" style={{backgroundColor: "rgba(130,38,27,0.8)"}}
							     onClick={() => this.ListarDonwload()}>
								<PostAdd className={'v-center margin-5-R px-15'}/>
								<div className={'v-center px-13 text-white'}>
									Descargar Acuses
								</div>
							</Fab>
						</Fragment>
					}*/
					botonValida={
						<Fragment>
							<ModalRepse
								tipo={'add'}
								item={{}}
								componente={
									<Fab className={'vertical-inline'} variant="extended" size="small" color="primary" style={{backgroundColor: "rgba(130,38,27,0.8)"}}>
										<PostAdd className={'v-center margin-5-R px-15'}/>
										<div className={'v-center px-13 text-white'}>
											Aviso de registro
										</div>
									</Fab>
								}
								ListEmpresa={this.ListEmpresa}
								lista_empresa={this.state.lista_empresa}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
						</Fragment>
					}
					botonReadXML={
						<Fragment>
							<ModalRepseAcuse
								tipo={'add'}
								item={{}}
								componente={
									<Fab className={'vertical-inline'} variant="extended" size="small" color="primary" style={{backgroundColor: "rgba(27,101,130,0.8)"}}>
										<PostAdd className={'v-center margin-5-R px-15'}/>
										<div className={'v-center px-13 text-white'}>
											Acuse
										</div>
									</Fab>
								}
								ListEmpresa={this.ListEmpresa}
								lista_empresa={this.state.lista_empresa}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
						</Fragment>
					}
				
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() => {
								this.Listar();
							}}/>
						</Fragment>
					}
				/>
				
				<FiltrosRepse
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
					ListEmpresa={this.ListEmpresa}
					lista_empresa={this.state.lista_empresa}
				/>
				
				{this.state.band_download_linea_captura ? (
					<Fragment>
						<ModalDescargaRepse
							open={this.state.band_download_linea_captura}
							item={this.state}
							cancelModalDescarga={this.cancelModalDescarga}
						/>
					</Fragment>
				) : null
				}
				
				<div className={'form margin-30-B'}>
					
					<Typography variant={'h6'} className={'margin-30-T margin-30-B'}>
					
					</Typography>
					
					{this.state.listar_cronograma.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_cronograma}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
								ListEmpresa={this.ListEmpresa}
								listar_usuarios = {this.state.listar_usuarios}
								lista_empresa={this.state.lista_empresa}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
			
			</div>
		);
	}
}

Repse.propTypes = {};

export default IntegrationNotistack(Repse);
