import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {TituloHeaderModal} from "../../../../Include/MiniComponents/GlobalComponent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import {HttpRequest} from "../../../../../settings/HttpRequest/HttpRequest";
import {CatService} from "../../../../../services/_Cat/CatService/CatService";
import {Business, FontDownload, HdrStrong, HomeWork, LooksOne, Person} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {DateFormat} from "../../../../../settings/DateFormat/DateFormat";


class ModalExcel extends Component {
	
	state = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			re_id: true,
			re_rfc: true,
			re_razon_social: true,
			re_domicilio_fiscal: true,
			re_telefono: true,
			re_correo_electronico: true,
			re_administrador_unico: true,
			re_constitutiva: true,
			re_poder_notarial: true,
			re_nrp: true,
			re_vigencia: true,
			re_domicilio_patronal: true,
			re_rl_patronal: true,
			re_cif: true,
			re_fiel: true,
			re_csd: true,
			re_comprobante_domicilio: true,
			re_observaciones: true,
			reporte_empresas_acresco: false,
			
			rss_id: true,
			rss_sucursal: true,
			rss_rfc: true,
			rss_razon_social: true,
			rss_domicilio: true,
			rss_comprobante: true,
			reporte_sucursales_sat_acresco: false,
			
			rt_id: true,
			rt_rfc: true,
			rt_banco: true,
			rt_sucursal: true,
			rt_tipo_cuenta: true,
			rt_numero_cliente: true,
			rt_numero_cuenta: true,
			rt_clave: true,
			rt_apoderado: true,
			rt_autorizados: true,
			rt_observaciones: true,
			reporte_tesoreria_acresco: false,
			
			rc_id: true,
			rc_rfc: true,
			rc_razon_social: true,
			rc_tipo_certificado: true,
			rc_vigencia: true,
			rc_responsable: true,
			rc_observaciones: true,
			reporte_certificados_acresco: false,
			
			fecha_inicio: '',
			fecha_fin: '',
			anio: '',
			empresa: '',
			cliente: '',
			uuid: '',
			id_cat_facturacion_status_sat: '',
			id_cat_facturacion_status_pago: '',
			id_cat_facturacion_plaza: '',
			
			listar_facturacion_plaza: [],
			listar_facturacion_estatus_sat: [],
			listar_facturacion_estatus_pago: [],
			
		};
		
		this.ListaFacturacionPlaza();
		this.ListaFacturacionStatusSat();
		this.ListaFacturacionStatusPago();
		
	}
	
	ListaFacturacionPlaza = () => {
		CatService.ListFacturacionPlaza({}, {}).then((response) => {
			this.setState({
				listar_facturacion_plaza: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_facturacion_plaza: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaFacturacionStatusSat = () => {
		CatService.ListFacturacionStatusSat({}, {}).then((response) => {
			this.setState({
				listar_facturacion_estatus_sat: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_facturacion_estatus_sat: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaFacturacionStatusPago = () => {
		CatService.ListFacturacionStatusPago({}, {}).then((response) => {
			this.setState({
				listar_facturacion_estatus_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_facturacion_estatus_pago: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
			
			fecha_inicio: (props.filtro || null).fecha_inicio,
			fecha_fin: (props.filtro || null).fecha_fin,
			empresa: (props.filtro || null).empresa,
			cliente: (props.filtro || null).cliente,
			id_cat_facturacion_status_sat: (props.filtro || null).id_cat_facturacion_status_sat,
			id_cat_facturacion_status_pago: (props.filtro || null).id_cat_facturacion_status_pago,
			id_cat_facturacion_plaza: (props.filtro || null).id_cat_facturacion_plaza,
			anio: (props.filtro || null).anio || null,
			periodo: (props.filtro || null).periodo || null,
			serie: (props.filtro || null).serie || null,
			uuid: (props.filtro || null).uuid || null,
			folio: (props.filtro || null).folio || null,
		});
		console.log("THIS.PROPS.FILTRO:::", props.filtro);
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	exportaryenviar = (item, archivo, auth, email) => {
		try {
			/*if (!this.state.c_plaza && !this.state.c_rfc_emisor && !this.state.c_empresa_facturadora && !this.state.c_rfc_receptor &&
				!this.state.c_cliente && !this.state.c_serie && !this.state.c_folio && !this.state.c_status_sat && !this.state.c_status_pago &&
				!this.state.c_fecha_emision && !this.state.c_fecha_pago && !this.state.c_subtotal && !this.state.c_iva &&
				!this.state.c_total_facturado && !this.state.c_concepto && !this.state.c_clave_unidad && !this.state.c_clave_concepto) {
				throw Object({
					status: false,
					mensaje: "Selecciona mínimo una columna para exportar."
				});
			}*/
			
			if (!this.state.reporte_empresas_acresco && !this.state.reporte_sucursales_sat_acresco && !this.state.reporte_tesoreria_acresco && !this.state.reporte_certificados_acresco) {
				throw Object({
					status: false,
					mensaje: "Selecciona mínimo un reporte para exportar."
				});
			}
			let fecha_inicio = this.state.fecha_inicio ? DateFormat.FormatSql(this.state.fecha_inicio) : null;
			let fecha_fin = this.state.fecha_fin ? DateFormat.FormatSql(this.state.fecha_fin) : null;
			
			
			let filtro = {
				fecha_inicio: fecha_inicio,
				fecha_fin: fecha_fin,
				empresa: this.state.empresa || null,
				cliente: this.state.cliente || null,
				id_cat_facturacion_status_sat: this.state.id_cat_facturacion_status_sat || null,
				id_cat_facturacion_status_pago: this.state.id_cat_facturacion_status_pago || null,
				id_cat_facturacion_plaza: this.state.id_cat_facturacion_plaza || null,
				anio: this.state.anio || null,
				periodo: this.state.periodo || null,
				serie: this.state.serie || null,
				uuid: this.state.uuid || null,
				folio: this.state.folio || null,
			};
			
			let reports = {
				reporte_empresas_acresco: this.state.reporte_empresas_acresco,
				reporte_sucursales_sat_acresco: this.state.reporte_sucursales_sat_acresco,
				reporte_tesoreria_acresco: this.state.reporte_tesoreria_acresco,
				reporte_certificados_acresco: this.state.reporte_certificados_acresco,
			}
			
			let columns = {
				re_id: this.state.re_id,
				re_rfc: this.state.re_rfc,
				re_razon_social: this.state.re_razon_social,
				re_domicilio_fiscal: this.state.re_domicilio_fiscal,
				re_telefono: this.state.re_telefono,
				re_correo_electronico: this.state.re_correo_electronico,
				re_administrador_unico: this.state.re_administrador_unico,
				re_constitutiva: this.state.re_constitutiva,
				re_poder_notarial: this.state.re_poder_notarial,
				re_nrp: this.state.re_nrp,
				re_vigencia: this.state.re_vigencia,
				re_domicilio_patronal: this.state.re_domicilio_patronal,
				re_rl_patronal: this.state.re_rl_patronal,
				re_cif: this.state.re_cif,
				re_fiel: this.state.re_fiel,
				re_csd: this.state.re_csd,
				re_comprobante_domicilio: this.state.re_comprobante_domicilio,
				re_observaciones: this.state.re_observaciones,
				
				rss_id: this.state.rss_id,
				rss_sucursal: this.state.rss_sucursal,
				rss_rfc: this.state.rss_rfc,
				rss_razon_social: this.state.rss_razon_social,
				rss_domicilio: this.state.rss_domicilio,
				rss_comprobante: this.state.rss_comprobante,
				
				rt_id: this.state.rt_id,
				rt_rfc: this.state.rt_rfc,
				rt_banco: this.state.rt_banco,
				rt_sucursal: this.state.rt_sucursal,
				rt_tipo_cuenta: this.state.rt_tipo_cuenta,
				rt_numero_cliente: this.state.rt_numero_cliente,
				rt_numero_cuenta: this.state.rt_numero_cuenta,
				rt_clave: this.state.rt_clave,
				rt_apoderado: this.state.rt_apoderado,
				rt_autorizados: this.state.rt_autorizados,
				rt_observaciones: this.state.rt_observaciones,
				
				rc_id: this.state.rc_id,
				rc_rfc: this.state.rc_rfc,
				rc_razon_social: this.state.rc_razon_social,
				rc_tipo_certificado: this.state.rc_tipo_certificado,
				rc_vigencia: this.state.rc_vigencia,
				rc_responsable: this.state.rc_responsable,
				rc_observaciones: this.state.rc_observaciones,
			};
			
			let params = {
				auth: auth,
				archivo: archivo,
				filtro: filtro,
				columns: columns,
				reports: reports
			};
			
			HttpRequest.export('domicilio_fiscal', ['domicilio_fiscal'], params, false, false, false, archivo === "EXCEL")
			
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	
	render() {
		
		return (
			<div>
				
				<Fragment>
					<span style={{cursor: 'pointer'}} children={this.props.componente} onClick={() => this.open()}/>
				</Fragment>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Exportar Empresas Acresco'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1} className={'desing-acresco-excel-export'}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'margin-15-B'} style={{color: 'gray', opacity: '0.8'}}>
									Selecciona el reporte y las columnas que deseas exportar:
								</Typography>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												onChange={this.handleChange}
												color="primary"
												name='reporte_empresas_acresco'
												checked={this.state.reporte_empresas_acresco}
												value="reporte_empresas_acresco"
											/>
										}
										label={<div className={'text-bold'}>Reporte Empresas Acresco</div>}
									/>
								</FormGroup>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider/>
							</Grid>
							
							{this.state.reporte_empresas_acresco ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																onChange={this.handleChange}
																color="primary"
																name='re_id'
																checked={this.state.re_id}
																value="re_id"
															/>
														}
														label={'ID'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_rfc'
																checked={this.state.re_rfc}
																value="re_rfc"
															/>
														}
														label={'RFC'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_razon_social'
																checked={this.state.re_razon_social}
																value="re_razon_social"
															/>
														}
														label={'Razón Social'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_domicilio_fiscal'
																checked={this.state.re_domicilio_fiscal}
																value="re_domicilio_fiscal"
															/>
														}
														label={'Domicilio Fiscal'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_telefono'
																checked={this.state.re_telefono}
																value="re_telefono"
															/>
														}
														label={'Teléfono'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_correo_electronico'
																checked={this.state.re_correo_electronico}
																value="re_correo_electronico"
															/>
														}
														label={'Correo Electrónico'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_administrador_unico'
																checked={this.state.re_administrador_unico}
																value="re_administrador_unico"
															/>
														}
														label={'Administrador Único'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_constitutiva'
																checked={this.state.re_constitutiva}
																value="re_constitutiva"
															/>
														}
														label={'R.L. Constitutiva'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_poder_notarial'
																checked={this.state.re_poder_notarial}
																value="re_poder_notarial"
															/>
														}
														label={'Poder Notarial'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_nrp'
																checked={this.state.re_nrp}
																value="re_nrp"
															/>
														}
														label={'N.R.P.'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_vigencia'
																checked={this.state.re_vigencia}
																value="re_vigencia"
															/>
														}
														label={'Vigencia TP'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_domicilio_patronal'
																checked={this.state.re_domicilio_patronal}
																value="re_domicilio_patronal"
															/>
														}
														label={'Domicilio Patronal'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_rl_patronal'
																checked={this.state.re_rl_patronal}
																value="re_rl_patronal"
															/>
														}
														label={'R.L. Patronal'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_cif'
																checked={this.state.re_cif}
																value="re_cif"
															/>
														}
														label={'CIF'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_fiel'
																checked={this.state.re_fiel}
																value="re_fiel"
															/>
														}
														label={'FIEL'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_csd'
																checked={this.state.re_csd}
																value="re_csd"
															/>
														}
														label={'CSD'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_comprobante_domicilio'
																checked={this.state.re_comprobante_domicilio}
																value="re_comprobante_domicilio"
															/>
														}
														label={'Comprobante de domicilio'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='re_observaciones'
																checked={this.state.re_observaciones}
																value="re_observaciones"
															/>
														}
														label={'Observaciones'}
													/>
												</FormGroup>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Divider/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Paper style={{overflowX: "auto"}}>
											<Table>
												<TableBody>
													<TableRow>
														{this.state.re_id ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>ID</TableCell>) : null}
														{this.state.re_rfc ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>RFC</TableCell>) : null}
														{this.state.re_razon_social ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Razón Social</TableCell>) : null}
														{this.state.re_domicilio_fiscal ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Domicilio Fiscal</TableCell>) : null}
														{this.state.re_telefono ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Teléfono</TableCell>) : null}
														{this.state.re_correo_electronico ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Correo Electrónico</TableCell>) : null}
														{this.state.re_administrador_unico ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Administrador Único</TableCell>) : null}
														{this.state.re_constitutiva ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>R.L. Constitutiva</TableCell>) : null}
														{this.state.re_poder_notarial ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Poder Notarial</TableCell>) : null}
														{this.state.re_nrp ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>R.P.</TableCell>) : null}
														{this.state.re_vigencia ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Vigencia TP</TableCell>) : null}
														{this.state.re_domicilio_patronal ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Domicilio Patronal</TableCell>) : null}
														{this.state.re_rl_patronal ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>R.L. Patronal</TableCell>) : null}
														{this.state.re_cif ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>CIF</TableCell>) : null}
														{this.state.re_fiel ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>FIEL</TableCell>) : null}
														{this.state.re_csd ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>CSD</TableCell>) : null}
														{this.state.re_comprobante_domicilio ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Comprobante Domicilio</TableCell>) : null}
														{this.state.re_observaciones ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Observaciones</TableCell>) : null}
													</TableRow>
													
													<TableRow>
														{this.state.re_id ? (<TableCell align={'left'}>1</TableCell>) : null}
														{this.state.re_rfc ? (<TableCell align={'left'}>RFC</TableCell>) : null}
														{this.state.re_razon_social ? (<TableCell align={'left'}>Razón Social</TableCell>) : null}
														{this.state.re_domicilio_fiscal ? (<TableCell align={'left'}>Domicilio Fiscal</TableCell>) : null}
														{this.state.re_telefono ? (<TableCell align={'left'}>Teléfono</TableCell>) : null}
														{this.state.re_correo_electronico ? (<TableCell align={'left'}>Correo Electrónico</TableCell>) : null}
														{this.state.re_administrador_unico ? (<TableCell align={'left'}>Administrador Único</TableCell>) : null}
														{this.state.re_constitutiva ? (<TableCell align={'left'}>R.L. Constitutiva</TableCell>) : null}
														{this.state.re_poder_notarial ? (<TableCell align={'left'}>Poder Notarial</TableCell>) : null}
														{this.state.re_nrp ? (<TableCell align={'left'}>R.P.</TableCell>) : null}
														{this.state.re_vigencia ? (<TableCell align={'left'}>Vigencia TP</TableCell>) : null}
														{this.state.re_domicilio_patronal ? (<TableCell align={'left'}>Domicilio Patronal</TableCell>) : null}
														{this.state.re_rl_patronal ? (<TableCell align={'left'}>R.L. Patronal</TableCell>) : null}
														{this.state.re_cif ? (<TableCell align={'left'}>CIF</TableCell>) : null}
														{this.state.re_fiel ? (<TableCell align={'left'}>FIEL</TableCell>) : null}
														{this.state.re_csd ? (<TableCell align={'left'}>CSD</TableCell>) : null}
														{this.state.re_comprobante_domicilio ? (<TableCell align={'left'}>Comprobante Domicilio</TableCell>) : null}
														{this.state.re_observaciones ? (<TableCell align={'left'}>Observaciones</TableCell>) : null}
													</TableRow>
												
												</TableBody>
											</Table>
										</Paper>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Divider/>
									</Grid>
								</Fragment>
							) : null}
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												onChange={this.handleChange}
												color="primary"
												name='reporte_sucursales_sat_acresco'
												checked={this.state.reporte_sucursales_sat_acresco}
												value="reporte_sucursales_sat_acresco"
											/>
										}
										label={<div className={'text-bold'}>Reporte Sucursales SAT Acresco</div>}
									/>
								</FormGroup>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider/>
							</Grid>
							
							{this.state.reporte_sucursales_sat_acresco ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																onChange={this.handleChange}
																color="primary"
																name='rss_id'
																checked={this.state.rss_id}
																value="rss_id"
															/>
														}
														label={'ID'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rss_sucursal'
																checked={this.state.rss_sucursal}
																value="rss_sucursal"
															/>
														}
														label={'Sucursal'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rss_rfc'
																checked={this.state.rss_rfc}
																value="rss_rfc"
															/>
														}
														label={'RFC'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rss_razon_social'
																checked={this.state.rss_razon_social}
																value="rss_razon_social"
															/>
														}
														label={'Razón Social'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rss_domicilio'
																checked={this.state.rss_domicilio}
																value="rss_domicilio"
															/>
														}
														label={'Domicilio Sucursal'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rss_comprobante'
																checked={this.state.rss_comprobante}
																value="rss_comprobante"
															/>
														}
														label={'Comprobante Domicilio'}
													/>
												</FormGroup>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Divider/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Paper style={{overflowX: "auto"}}>
											<Table>
												<TableBody>
													<TableRow>
														{this.state.rss_id ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>ID</TableCell>) : null}
														{this.state.rss_sucursal ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Sucursal</TableCell>) : null}
														{this.state.rss_rfc ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>RFC</TableCell>) : null}
														{this.state.rss_razon_social ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Razón Social</TableCell>) : null}
														{this.state.rss_domicilio ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Domicilio Sucursal</TableCell>) : null}
														{this.state.rss_comprobante ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Comprobante Domicilio</TableCell>) : null}
													</TableRow>
													
													<TableRow>
														{this.state.rss_id ? (<TableCell align={'left'}>ID</TableCell>) : null}
														{this.state.rss_sucursal ? (<TableCell align={'left'}>Sucursal</TableCell>) : null}
														{this.state.rss_rfc ? (<TableCell align={'left'}>RFC</TableCell>) : null}
														{this.state.rss_razon_social ? (<TableCell align={'left'}>Razón Social</TableCell>) : null}
														{this.state.rss_domicilio ? (<TableCell align={'left'}>Domicilio Sucursal</TableCell>) : null}
														{this.state.rss_comprobante ? (<TableCell align={'left'}>Comprobante Domicilio</TableCell>) : null}
													</TableRow>
												
												</TableBody>
											</Table>
										</Paper>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Divider/>
									</Grid>
								</Fragment>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												onChange={this.handleChange}
												color="primary"
												name='reporte_tesoreria_acresco'
												checked={this.state.reporte_tesoreria_acresco}
												value="reporte_tesoreria_acresco"
											/>
										}
										label={<div className={'text-bold'}>Reporte Tesorería Acresco</div>}
									/>
								</FormGroup>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider/>
							</Grid>
							
							{this.state.reporte_tesoreria_acresco ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																onChange={this.handleChange}
																color="primary"
																name='rt_id'
																checked={this.state.rt_id}
																value="rt_id"
															/>
														}
														label={'ID'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rt_rfc'
																checked={this.state.rt_rfc}
																value="rt_rfc"
															/>
														}
														label={'RFC'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rt_banco'
																checked={this.state.rt_banco}
																value="rt_banco"
															/>
														}
														label={'Banco'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rt_sucursal'
																checked={this.state.rt_sucursal}
																value="rt_sucursal"
															/>
														}
														label={'Sucursal'}
													/>
												</FormGroup>
											</Grid>
											
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rt_tipo_cuenta'
																checked={this.state.rt_tipo_cuenta}
																value="rt_tipo_cuenta"
															/>
														}
														label={'Tipo Cuenta'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rt_numero_cliente'
																checked={this.state.rt_numero_cliente}
																value="rt_numero_cliente"
															/>
														}
														label={'No. Cliente'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rt_numero_cuenta'
																checked={this.state.rt_numero_cuenta}
																value="rt_numero_cuenta"
															/>
														}
														label={'No. Cuenta'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rt_clave'
																checked={this.state.rt_clave}
																value="rt_clave"
															/>
														}
														label={'CLABE INTERBANCARIA'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rt_apoderado'
																checked={this.state.rt_apoderado}
																value="rt_apoderado"
															/>
														}
														label={'Apoderado'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rt_autorizados'
																checked={this.state.rt_autorizados}
																value="rt_autorizados"
															/>
														}
														label={'Autorizados'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rt_observaciones'
																checked={this.state.rt_observaciones}
																value="rt_observaciones"
															/>
														}
														label={'Observaciones'}
													/>
												</FormGroup>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Divider/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Paper style={{overflowX: "auto"}}>
											<Table>
												<TableBody>
													<TableRow>
														{this.state.rt_id ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>ID</TableCell>) : null}
														{this.state.rt_rfc ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>RFC</TableCell>) : null}
														{this.state.rt_banco ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Banco</TableCell>) : null}
														{this.state.rt_sucursal ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Sucursal</TableCell>) : null}
														{this.state.rt_tipo_cuenta ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Tipo Cuenta</TableCell>) : null}
														{this.state.rt_numero_cliente ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>No. Cliente</TableCell>) : null}
														{this.state.rt_numero_cuenta ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>No. Cuenta</TableCell>) : null}
														{this.state.rt_clave ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>CLABE INTERBANCARIA</TableCell>) : null}
														{this.state.rt_apoderado ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Apoderado</TableCell>) : null}
														{this.state.rt_autorizados ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Autorizados</TableCell>) : null}
														{this.state.rt_observaciones ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Observaciones</TableCell>) : null}
													</TableRow>
													
													<TableRow>
														{this.state.rt_id ? (<TableCell align={'left'}>ID</TableCell>) : null}
														{this.state.rt_rfc ? (<TableCell align={'left'}>RFC</TableCell>) : null}
														{this.state.rt_banco ? (<TableCell align={'left'}>Banco</TableCell>) : null}
														{this.state.rt_sucursal ? (<TableCell align={'left'}>Sucursal</TableCell>) : null}
														{this.state.rt_tipo_cuenta ? (<TableCell align={'left'}>Tipo Cuenta</TableCell>) : null}
														{this.state.rt_numero_cliente ? (<TableCell align={'left'}>No. Cliente</TableCell>) : null}
														{this.state.rt_numero_cuenta ? (<TableCell align={'left'}>No. Cuenta</TableCell>) : null}
														{this.state.rt_clave ? (<TableCell align={'left'}>CLABE INTERBANCARIA</TableCell>) : null}
														{this.state.rt_apoderado ? (<TableCell align={'left'}>Apoderado</TableCell>) : null}
														{this.state.rt_autorizados ? (<TableCell align={'left'}>Autorizados</TableCell>) : null}
														{this.state.rt_observaciones ? (<TableCell align={'left'}>Observaciones</TableCell>) : null}
													</TableRow>
												
												</TableBody>
											</Table>
										</Paper>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Divider/>
									</Grid>
								</Fragment>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												onChange={this.handleChange}
												color="primary"
												name='reporte_certificados_acresco'
												checked={this.state.reporte_certificados_acresco}
												value="reporte_certificados_acresco"
											/>
										}
										label={<div className={'text-bold'}>Reporte Certificados Acresco</div>}
									/>
								</FormGroup>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider/>
							</Grid>
							
							{this.state.reporte_certificados_acresco ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																onChange={this.handleChange}
																color="primary"
																name='rc_id'
																checked={this.state.rc_id}
																value="rc_id"
															/>
														}
														label={'ID'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rc_rfc'
																checked={this.state.rc_rfc}
																value="rc_rfc"
															/>
														}
														label={'RFC'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rc_razon_social'
																checked={this.state.rc_razon_social}
																value="rc_razon_social"
															/>
														}
														label={'Razón Social'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rc_tipo_certificado'
																checked={this.state.rc_tipo_certificado}
																value="rc_tipo_certificado"
															/>
														}
														label={'Tipo Certificado'}
													/>
												</FormGroup>
											</Grid>
											
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rc_vigencia'
																checked={this.state.rc_vigencia}
																value="rc_vigencia"
															/>
														}
														label={'Vigencia'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rc_responsable'
																checked={this.state.rc_responsable}
																value="rc_responsable"
															/>
														}
														label={'Responsable'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																disabled={this.state.tipo === 'view'}
																onChange={this.handleChange}
																color="primary"
																name='rc_observaciones'
																checked={this.state.rc_observaciones}
																value="rc_observaciones"
															/>
														}
														label={'Observaciones'}
													/>
												</FormGroup>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Divider/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Paper style={{overflowX: "auto"}}>
											<Table>
												<TableBody>
													<TableRow>
														{this.state.rc_id ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>ID</TableCell>) : null}
														{this.state.rc_rfc ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>RFC</TableCell>) : null}
														{this.state.rc_razon_social ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Razón Social</TableCell>) : null}
														{this.state.rc_tipo_certificado ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Tipo Certificado</TableCell>) : null}
														{this.state.rc_vigencia ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Vigencia</TableCell>) : null}
														{this.state.rc_responsable ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Responsable</TableCell>) : null}
														{this.state.rc_observaciones ? (<TableCell align={'left'} style={{fontWeight: 'bold'}}>Observaciones</TableCell>) : null}
													</TableRow>
													
													<TableRow>
														{this.state.rc_id ? (<TableCell align={'left'}>ID</TableCell>) : null}
														{this.state.rc_rfc ? (<TableCell align={'left'}>RFC</TableCell>) : null}
														{this.state.rc_razon_social ? (<TableCell align={'left'}>Razón Social</TableCell>) : null}
														{this.state.rc_tipo_certificado ? (<TableCell align={'left'}>Tipo Certificado</TableCell>) : null}
														{this.state.rc_vigencia ? (<TableCell align={'left'}>Vigencia</TableCell>) : null}
														{this.state.rc_responsable ? (<TableCell align={'left'}>Responsable</TableCell>) : null}
														{this.state.rc_observaciones ? (<TableCell align={'left'}>Observaciones</TableCell>) : null}
													</TableRow>
												
												</TableBody>
											</Table>
										</Paper>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Divider/>
									</Grid>
								</Fragment>
							) : null}
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close} color="primary">
								Cancelar
							</Button>
							<Button onClick={() => this.exportaryenviar({}, 'EXCEL')} color="primary">
								Exportar
							</Button>
						</Fragment>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalExcel.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string,
	item: PropTypes.object.isRequired,
	filtro: PropTypes.object,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalExcel;
