import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {CronogramaService} from '../../../../services/CronogramaService/CronogramaService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";
import ModalEmpresa from "../../Cat/Empresa/Includes/ModalEmpresa";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import ModalRecordatorios from "../../Reccordatorios/Includes/ModalRecordatorios";
import {Business, DeleteOutlined, Group, SpeakerNotes, Sync} from "@material-ui/icons";
import {DropzoneArea} from "material-ui-dropzone";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ModalProyecto from "../../Cat/Proyecto/Includes/ModalProyecto";
import ModalModulo from "../../Cat/Modulo/Includes/ModalModulo";
import {ProyectoService} from "../../../../services/_Cat/ProyectoService/ProyectoService";
import {ModuloService} from "../../../../services/_Cat/ModuloService/ModuloService";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {TicketsService} from "../../../../services/TicketsService/TicketsService";
import DocSvg from "../../../../assets/img/icons/doc.svg";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import FormGroup from "@material-ui/core/FormGroup";
import {CONFIG} from "../../../../settings/Config/Config";


class ModalCronograma extends Component {
	
	Usr = {};
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_cronograma: (props.item || '').id_cronograma,
			tipo: props.tipo,
			id_cat_proyecto: '',
			id_cat_proyecto_modulo: '',
			fecha: null,
			horas: '',
			id_usuario: '',
			actividad: '',
			files: [],
			archivos: [],
			lista_proyectos: [],
			lista_proyectos_modulos: [],
			lista_usuarios: [],
			usuarios: [],
			lista_usuario_autorizado: [],
			usuarios_notificar: [],
		};
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	ListProyectos = () => {
		return new Promise((resolve, reject) => {
			CatService.ListProyecto().then((response) => {
				this.setState({
					lista_proyectos: response.data,
					id_cat_proyecto_modulo: ''
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_proyectos: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListProyectoModulos = (id_cat_proyecto) => {
		return new Promise((resolve, reject) => {
			CatService.ListProyectoModulo(id_cat_proyecto).then((response) => {
				this.setState({
					lista_proyectos_modulos: response.data
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_proyectos_modulos: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListUsuarios = () => {
		return new Promise((resolve, reject) => {
			CatService.ListUsuario().then((response) => {
				let usuarios = [];
				let notificar = [];
				if (!FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario)) {
					for (let i = 0; i < response.data.length; i++) {
						console.log(Number(this.Usr.id_cat_tipo_usuario) === Number(response.data[i].id_cat_tipo_usuario), Number(this.Usr.id_cat_tipo_usuario), response.data[i]);
						if (Number(this.Usr.id_cat_tipo_usuario) === Number(response.data[i].id_cat_tipo_usuario)) {
							usuarios.push(response.data[i]);
						}
						if (Number(this.Usr.id_cat_tipo_usuario) === Number(response.data[i].id_cat_tipo_usuario) || Number(response.data[i].id_cat_tipo_usuario) === 13) {
							notificar.push(response.data[i]);
						}
					}
				} else {
					usuarios = response.data;
					notificar = response.data;
				}
				this.setState({
					lista_usuarios: usuarios,
					lista_usuario_autorizado: notificar
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_usuarios: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	RefreshListProyecto = (id_cat_proyecto) => {
		this.ListProyectos();
		this.setState({
			id_cat_proyecto: id_cat_proyecto
		});
	};
	
	RefreshListProyectoModulo = (id_cat_proyecto, id_cat_proyecto_modulo) => {
		this.ListProyectoModulos(id_cat_proyecto);
		this.setState({
			id_cat_proyecto: id_cat_proyecto,
			id_cat_proyecto_modulo: id_cat_proyecto_modulo
		});
	};
	
	guardarDocTimbradoDbx = () => {
		
		let files = this.state.files;
		
		return new Promise((resolve, reject) => {
			let band = files.length;
			let band2 = 0;
			let archives = this.state.archivos;
			let file;
			
			for (let i = 0; i < files.length; i++) {
				console.log("2 FTAR::: ", files);
				file = files[i];
				
				let ruta = '/Cronograma_Actividades/' + file.name;
				
				DropboxApi.Upload(ruta, file).then(function (response) {
					archives.push({archivo: response.path_display});
					
					band2++;
					
					console.log("DROPBOX UPLOAD: ", response, band, band2);
					
					if (band === band2) {
						resolve(archives);
					}
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	save = () => {
		
		try {
			
			if (!FieldsJs.Field(this.state.id_cat_proyecto)) {
				throw Object({
					status: false, mensaje: 'Campo proyecto es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.id_cat_proyecto_modulo)) {
				throw Object({
					status: false, mensaje: 'Campo módulo es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.fecha)) {
				throw Object({
					status: false, mensaje: 'Campo día es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.horas)) {
				throw Object({
					status: false, mensaje: 'Campo hora(s) es requerido.'
				})
			}
			/*if (this.state.usuarios.length === 0) {
				throw Object({
					status: false, mensaje: 'El campo usuario(s) asignado(s) debe tener almenos 1 elemento.'
				})
			}*/
			if (!FieldsJs.Field(this.state.actividad)) {
				throw Object({
					status: false, mensaje: 'Campo actividad es requerido.'
				})
			}
			
			if (this.state.files.length > 0) {
				
				let guardar = new Promise((resolve, reject) => {
					showSpinner('spinner');
					this.guardarDocTimbradoDbx(this.state.files).then(response => {
						this.setState({
							archivos: response,
						});
						resolve(response);
						hideSpinner('spinner', 500);
						console.log("DROPBOX UPLOAD: ", this.state);
					}).catch(error => {
						hideSpinner('spinner', 500);
						reject(error);
					})
				});
				
				guardar.then(response => {
					if (this.state.id_cronograma > 0) {
						CronogramaService.Modificar(this.state).then((response) => {
							this.props.RefreshList();
							this.modalClose();
							this.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.showSnackBars('error', error.mensaje);
						});
					} else {
						CronogramaService.Agregar(this.state).then((response) => {
							this.props.RefreshList();
							this.modalClose();
							this.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.showSnackBars('error', error.mensaje);
						});
					}
				});
				
			} else {
				if (this.state.id_cronograma > 0) {
					CronogramaService.Modificar(this.state).then((response) => {
						this.props.RefreshList();
						this.modalClose();
						this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
					});
				} else {
					CronogramaService.Agregar(this.state).then((response) => {
						this.props.RefreshList();
						this.modalClose();
						this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
					});
				}
			}
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		
		this.setState({
			id_cronograma: '',
			id_cat_proyecto: '',
			id_cat_proyecto_modulo: '',
			fecha: null,
			horas: '',
			id_usuario: '',
			actividad: '',
			files: [],
			archivos: [],
			usuarios: [],
			activo: true,
		});
		
		this.ListProyectos();
		this.ListUsuarios();
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		this.ListProyectos().then(() => {
			this.setState({
				id_cat_proyecto: item.id_cat_proyecto || '',
			});
			this.ListProyectoModulos(item.id_cat_proyecto).then(() => {
				this.setState({
					id_cat_proyecto_modulo: item.id_cat_proyecto_modulo || '',
				});
			});
		});
		
		this.ListUsuarios();
		
		this.setState({
			id_cronograma: item.id_cronograma || '',
			fecha: item.fecha || null,
			horas: item.tiempo || '',
			actividad: item.actividad || '',
			id_usuario: item.id_usuario || '',
			//files: [],
			archivos: item.archivos || [],
			usuarios: item.usuarios || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.ListProyectos().then(() => {
			this.setState({
				id_cat_proyecto: item.id_cat_proyecto || '',
			});
			this.ListProyectoModulos(item.id_cat_proyecto).then(() => {
				this.setState({
					id_cat_proyecto_modulo: item.id_cat_proyecto_modulo || '',
				});
			});
		});
		
		this.ListUsuarios();
		
		this.setState({
			id_cronograma: item.id_cronograma || '',
			fecha: item.fecha || null,
			horas: item.tiempo || '',
			actividad: item.actividad || '',
			id_usuario: item.id_usuario || '',
			//files: [],
			archivos: item.archivos || [],
			usuarios: item.usuarios || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_cronograma > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	agregar_usuario = () => {
		
		try {
			
			if (this.state.id_usuario <= 0) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("Selecciona por lo menos un usuario.")
				});
			}
			
			let arr_temp = this.state.usuarios;
			let id_usuario = this.state.id_usuario;
			let id;
			let nombre_completo;
			let exist_empresa = false;
			
			for (let i = 0; i < this.state.lista_usuarios.length; i++) {
				if (Number(this.state.lista_usuarios[i].id_usuario) === Number(id_usuario)) {
					id = this.state.lista_usuarios[i].id_usuario;
					nombre_completo = this.state.lista_usuarios[i].nombre_completo;
				}
			}
			
			for (let i = 0; i < this.state.usuarios.length; i++) {
				if (Number(this.state.usuarios[i].id_usuario) === Number(id_usuario)) {
					exist_empresa = true;
				}
			}
			
			if (exist_empresa) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("El usuario ya se encuentra seleccionado.")
				});
			}
			
			arr_temp.push({
				id_usuario: id,
				nombre_completo: nombre_completo,
			});
			
			this.setState({
				usuarios: arr_temp,
				id_usuario: '',
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	eliminar_usuario = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.usuarios);
		arr_temp.splice(key, 1);
		this.setState({
			usuarios: arr_temp
		});
	};
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	descargarZip = (path) => {
		console.log("PATH ::: ", path);
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
	};
	
	asignarUsuarioReporte = (id_usuario, id_usuario_autorizado) => {
		let usuarios = FieldsJs.Copy(this.state.lista_usuario_autorizado);
		let users = FieldsJs.Copy(this.state.usuarios_notificar);
		let banduser = false;
		let userkey = 0;
		for (let i = 0; i < users.length; i++) {
			if (Number(users[i].id_usuario) === Number(id_usuario)) {
				banduser = true;
				userkey = i;
			}
		}
		if (!banduser) {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = true;
					if (id_usuario_autorizado) {
						usuarios[i].id_usuario = id_usuario_autorizado;
					}
					users.push(usuarios[i]);
				}
			}
		} else {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = false;
				}
			}
			users.splice(userkey, 1);
		}
		this.setState({
			lista_usuario_autorizado: usuarios,
			usuarios_notificar: users,
		});
		console.log("ASIGNARUSUARIO", users, usuarios, this.state.usuarios_notificar);
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Actividad</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={8} lg={8} xl={8}>
								
								<Grid container spacing={2}>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={2} alignItems={"flex-end"}>
											<Grid item className={'w-50-px text-left'}>
												<ModalProyecto
													tipo={'add'}
													item={{}}
													componente={
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Agregar proyecto">
															<Fab size="small" color="primary" aria-label="Agregar proyecto">
																<Add/>
															</Fab>
														</Tooltip>
													}
													RefreshList={this.RefreshListProyecto}
													showSnackBars={this.props.showSnackBars}
												/>
											</Grid>
											<Grid item className={'w-100-50-px'}>
												<FormControl fullWidth required margin="dense" className={'margin-0'}>
													<InputLabel>Proyecto</InputLabel>
													<Select
														native
														value={this.state.id_cat_proyecto}
														onChange={(e) => {
															this.handleChange(e);
															this.ListProyectoModulos(e.target.value);
														}}
														name="id_cat_proyecto"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.lista_proyectos.map(option => (
															<option key={option.id_cat_proyecto}
															        value={option.id_cat_proyecto}>
																{option.proyecto}
															</option>
														))}
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={2} alignItems={"flex-end"}>
											<Grid item className={'w-50-px text-left'}>
												<ModalModulo
													tipo={'add'}
													item={{}}
													componente={
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Agregar módulo">
															<Fab size="small" color="primary" aria-label="Agregar módulo">
																<Add/>
															</Fab>
														</Tooltip>
													}
													RefreshList={this.RefreshListProyectoModulo}
													showSnackBars={this.props.showSnackBars}
												/>
											</Grid>
											<Grid item className={'w-100-50-px'}>
												<FormControl fullWidth required margin="dense" className={'margin-0'}>
													<InputLabel>Módulo</InputLabel>
													<Select
														native
														value={this.state.id_cat_proyecto_modulo}
														onChange={(e) => {
															this.handleChange(e);
														}}
														name="id_cat_proyecto_modulo"
														input={<Input/>}
														disabled={this.props.tipo === 'view' || !this.state.id_cat_proyecto}
													>
														<option value=""/>
														{this.state.lista_proyectos_modulos.map(option => (
															<option key={option.id_cat_proyecto_modulo}
															        value={option.id_cat_proyecto_modulo}>
																{option.proyecto_modulo}
															</option>
														))}
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
										<KeyboardDatePicker
											format={'dd/MM/yyyy'}
											fullWidth
											required
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'Fecha de compra',
											}}
											label="Día"
											value={this.state.fecha}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha');
											}}
											// minDate={moment(new Date())}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											onError={console.log}
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
										<TextField
											className={'margin-0'}
											margin="dense"
											type={'text'}
											fullWidth
											required
											name="horas"
											onChange={this.handleChange}
											label="Hora(s)"
											autoComplete={'off'}
											value={this.state.horas}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
									
									{this.Usr.isjefe ? (
										<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
											<div className={'row-flex'}>
												<div className={'w-100-80-px padding-10-R'}>
													<FormControl fullWidth required>
														<InputLabel>Usuario(s) asignado(s)</InputLabel>
														<Select
															native
															value={this.state.id_usuario}
															onChange={this.handleChange}
															name="id_usuario"
															input={<Input/>}
															disabled={this.props.tipo === 'view'}
														>
															<option value=""/>
															{this.state.lista_usuarios.map(option => (
																<option key={option.id_usuario}
																        value={option.id_usuario}>
																	{option.nombre_completo}
																</option>
															))}
														</Select>
													</FormControl>
												</div>
												<div className={'w-80-px v-end'}>
													<Button size={'small'} variant="contained"
													        onClick={this.agregar_usuario}
													        className={'text-white text-transform-none ' + (this.props.tipo === 'view' ? 'btn-gray' : 'btn-default-secondary')}
													        disabled={this.props.tipo === 'view'}>
														Agregar
													</Button>
												</div>
											</div>
										</Grid>
									) : null}
									
									{this.state.usuarios.length > 0 ? (
										<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
											<div className={'w-100 text-left'}>
												{this.state.usuarios.map((item, key) => (
													<div className={'vertical-inline b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
														<div className={'padding-3 padding-10-L padding-10-R v-center text-white'}>
															{item.nombre_completo}
														</div>
														<div className={'padding-3 padding-10-R v-center text-white'}>
															{this.props.tipo === 'view' ? null : (
																<DeleteOutlined className={'px-18 text-white'} onClick={() => this.eliminar_usuario(key)}/>
															)
															}
														</div>
													</div>
												))
												}
											</div>
										</Grid>
									) : ''}
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											fullWidth
											required
											multiline
											rows={3}
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											className={'margin-0'}
											margin="dense"
											name="actividad"
											label="Actividad"
											value={this.state.actividad}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container className={'text-left borde-punteado-lightgray-2 padding-10'} alignItems={"flex-center"}>
									<Grid className={'w-30-px'}>
										<Group className={'w-100 text-gray'}/>
									</Grid>
									<Grid className={'w-100-30-px'}>
										<Typography className={'px-14 text-left text-gray margin-2-T'}>
											{'Notificar a:'}
										</Typography>
									</Grid>
									<Grid className={'w-100'}>
										<div style={{maxHeight: 280, overflowY: 'auto'}}>
											{this.state.lista_usuario_autorizado.map((item, index) => (
												<Fragment key={index}>
													<div className={'vertical-inline w-95 cursor-pointer'} onClick={() => {
														if (this.props.tipo !== 'view') {
															this.asignarUsuarioReporte(item.id_usuario);
														}
													}}>
														<div className='v-center padding-10-L'>
															<FormGroup row className={'text-center check-recuser'}>
																<FormControlLabel
																	control={
																		<Checkbox
																			type="checkbox"
																			color="primary"
																			checked={item.check}
																			value={item.check}
																			disabled={this.props.tipo === 'view'}
																		/>
																	}
																	label={''}
																/>
															</FormGroup>
														</div>
														
														<div className={'v-center vertical-inline'}>
															<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
															     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
															</div>
															<Typography variant={'h5'} className={'v-center padding-15-L margin-0 text-black px-13'}>
																{item.nombre_completo}
															</Typography>
														</div>
													</div>
												</Fragment>
											))}
										</div>
									</Grid>
								</Grid>
							</Grid>
							
							{this.state.archivos.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
										Documento(s) Anexo:
									</Typography>
									<div className={'vertical-inline'}>
										{this.state.archivos.map((item, key) => (
											<div>
												<div className={'vertical-inline b-r-20 margin-5 margin-0-L'} key={key}>
													<img className={'v-center cursor-pointer'} src={DocSvg} alt="add" width={40} height={40} style={{opacity: 0.7, marginRight: '5px'}} onClick={() => this.descargarZip(item.archivo)}/>
													
													
													{this.props.tipo === 'edit' ? (<div className={'vertical-inline v-end b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
														<div className={'padding-3 v-end text-white cursor-pointer'}>
															{this.props.tipo === 'view' ? null : (
																<DeleteOutlined className={'px-16 text-white'} onClick={() => this.eliminar_documento(key)}/>
															)
															}
														</div>
													</div>) : null}
												</div>
											
											
											</div>
										))}
									</div>
								</Grid>
							) : null}
							
							{this.props.tipo !== 'view' ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'dropzone-cronograma'}>
									<div className={'w-100'}>
										<DropzoneArea
											onChange={this.handleChangeDropzoneArea.bind(this)}
											open={this.state.open}
											//filesLimit={2}
											dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
											//acceptedFiles={['text/xml', 'application/pdf']}
											showPreviews={false}
											maxFileSize={5000000}
											showAlerts={false}
											showFileNames={true}
											showFileNamesInPreview={true}
										/>
									</div>
								</Grid>
							) : null}
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_cronograma > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCronograma.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalCronograma);
