import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import ModalCita from "./ModalCita";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {CitaService} from "../../../../services/CitaService/CitaService";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar la cita de ${item.nombre_acude}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				CitaService.Eliminar(item.id_cita).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					
					this.props.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'}>Empresa</TableCell>
							<TableCell component="th" align={'left'}>Nombre acude</TableCell>
							<TableCell component="th" align={'left'}>Lugar cita</TableCell>
							<TableCell component="th" align={'center'}>Fecha cita</TableCell>
							<TableCell component="th" align={'center'}>Próxima cita</TableCell>
							<TableCell component="th" align={'center'}>Nombre autoridad</TableCell>
							<TableCell component="th" align={'center'}>Teléfono</TableCell>
							<TableCell component="th" align={'center'}>Nombre contador</TableCell>
							<TableCell component="th" align={'center'}>Descripción</TableCell>
							<TableCell component="th" align={'center'}>Plazo</TableCell>
							<TableCell component="th" align={'center'}>Diferencias</TableCell>
							{/*<TableCell component="th" align={'center'}>Atención</TableCell>*/}
							<TableCell component="th" align={'center'}>Grabación entrevista</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							lista.map((item, key) => {
								return (
									<TableRow key={key}>
										
										<TableCell align={'left'}>{item.nombre_comercial}</TableCell>
										<TableCell align={'left'}>{item.nombre_acude}</TableCell>
										<TableCell align={'left'}>{item.lugar_cita}</TableCell>
										<TableCell align={'left'}>{item.fecha_cita || 'Sin registrar'}</TableCell>
										<TableCell align={'left'}>{item.fecha_fisica || 'Sin registrar'}</TableCell>
										<TableCell align={'left'}>{item.nombre_autoridad || 'Sin registrar'}</TableCell>
										<TableCell align={'left'}>{item.telefono || 'Sin registrar'}</TableCell>
										<TableCell align={'left'}>{item.nombre_contador || 'Sin registrar'}</TableCell>
										<TableCell align={'left'}>{item.descripcion || 'Sin registrar'}</TableCell>
										<TableCell align={'left'}>{item.plazo || 'Sin registrar'}</TableCell>
										<TableCell align={'left'}>{item.diferencias || 'Sin registrar'}</TableCell>
										{/*<TableCell align={'left'}>{item.atencion || 'Sin registrar'}</TableCell>*/}
										<TableCell align={'left'}>{item.url ? (<a href={item.url}  target="_blank" style={{color: "#3f51b5"}}>Reproducir</a>) : 'Sin registrar'}</TableCell>
										<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
											
											<div className={'w-auto vertical-inline'}>
												{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
															<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}><DeleteOutlined/></IconButton>
														</Tooltip>
													</div>
												) : ''}
												{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<ModalCita
															tipo={'edit'}
															item={item}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
															lista_empresa={this.props.lista_empresa}
														/>
													</div>
												) : ''}
												<div className={'v-center'}>
													<ModalCita
														tipo={'view'}
														item={item}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
														lista_empresa={this.props.lista_empresa}
													/>
												</div>
											</div>
										
										</TableCell>
									
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	lista_empresa: PropTypes.array.isRequired,
};

export default withStyles(styles)(ListaTabla);
