import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const ClientePotencialService = {
	Listar: (filtro, paginacion, tipo_vista) => {
		let params = {
			filtro: {
				id_cat_ficha_tecnica_status: filtro.filtro_id_cat_ficha_tecnica_status || null,
				tipo_vista: tipo_vista
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Potencial_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			select_cliente: form.select_cliente,
			nombre_comercial: form.nombre_comercial,
			razon_social: form.razon_social,
			rfc: form.rfc,
			correo_electronico: form.correo_electronico,
			telefono: form.telefono,
			id_cat_giro_cliente: form.id_cat_giro_cliente,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Potencial_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			select_cliente: form.select_cliente,
			id_cliente: form.id_cliente,
			nombre_comercial: form.nombre_comercial,
			razon_social: form.razon_social,
			rfc: form.rfc,
			correo_electronico: form.correo_electronico,
			telefono: form.telefono,
			id_cat_giro_cliente: form.id_cat_giro_cliente,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Potencial_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cliente) => {
		let params = {
			id_cliente: id_cliente
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Potencial_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ReasignarPromotor: (id_cliente, id_usuario) => {
		let params = {
			id_cliente: id_cliente,
			id_usuario: id_usuario,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cliente_Potencial_Reasignar_Promotor', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarDatosClientes: (form) => {
		let fecha_inicio_empresa_pagan = (form.fecha_inicio_empresa_pagan || null) ? DateFormat.FormatSql(form.fecha_inicio_empresa_pagan) : null;
		let fecha_fin_empresa_pagan = (form.fecha_fin_empresa_pagan || null) ? DateFormat.FormatSql(form.fecha_fin_empresa_pagan) : null;
		let fecha_inicio_empresa_factura = (form.fecha_inicio_empresa_factura || null) ? DateFormat.FormatSql(form.fecha_inicio_empresa_factura) : null;
		let fecha_fin_empresa_factura = (form.fecha_fin_empresa_factura || null) ? DateFormat.FormatSql(form.fecha_fin_empresa_factura) : null;
		
		let params = {
			
			id_cliente: form.id_cliente,
			
			id_ficha_tecnica: form.id_ficha_tecnica,
			
			cliente_que_paga: form.cliente_que_paga,
			id_ejecutivo_asignado_tesoreria: form.id_ejecutivo_asignado_tesoreria,
			cliente_que_factura: form.cliente_que_factura,
			
			comentario_observacion_desarrollador: form.comentario_observacion_desarrollador,
			
			fecha_inicio_empresa_pagan: fecha_inicio_empresa_pagan,
			fecha_fin_empresa_pagan: fecha_fin_empresa_pagan,
			fecha_inicio_empresa_factura: fecha_inicio_empresa_factura,
			fecha_fin_empresa_factura: fecha_fin_empresa_factura,
			
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Fiscal_Clientes_Pagadoras_Clientes_Facturadoras_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarContratosIndividualesGuardar: (form, total_contratos_individuales) => {
		let params = {
			id_cliente: form.id_cliente,
			id_ficha_tecnica: form.id_ficha_tecnica,
			
			total_contratos_individuales: total_contratos_individuales,
			
			cantidad_elaborado: form.cantidad_elaborado,
			
			excel_elaborado_archivo: form.excel_elaborado_archivo,
			excel_elaborado_formato: form.excel_elaborado_formato,
			
			excel_pendiente_archivo: form.excel_pendiente_archivo,
			excel_pendiente_formato: form.excel_pendiente_formato,
			
			comentario_general: form.comentario_general,
			esquema: form.esquema,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Juridico_Contratos_Individuales_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarContratosIndividualesFirmadoGuardar: (form, total_contratos_individuales) => {
		let params = {
			id_cliente: form.id_cliente,
			id_ficha_tecnica: form.id_ficha_tecnica,
			
			total_contratos_individuales: total_contratos_individuales,
			
			cantidad_elaborado: form.cantidad_elaborado,
			
			excel_elaborado_archivo: form.excel_elaborado_archivo,
			excel_elaborado_formato: form.excel_elaborado_formato,
			
			excel_pendiente_archivo: form.excel_pendiente_archivo,
			excel_pendiente_formato: form.excel_pendiente_formato,
			
			comentario_general: form.comentario_general,
			esquema: form.esquema,
			lugar_resguardo: form.lugar_resguardo,
			id_responsable_resguardo: form.id_responsable_resguardo,
			id_plaza_resguardo: form.id_plaza_resguardo,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Juridico_Contratos_Individuales_Firmados_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarContratosIndividualesEliminar: (form, id_cliente, id_ficha_tecnica) => {
		let params = {
			id_cliente: id_cliente,
			id_ficha_tecnica: id_ficha_tecnica,
			
			id_cliente_contratos_individuales: form.id_cliente_contratos_individuales
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Juridico_Contratos_Individuales_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarContratosIndividualesFirmadosEliminar: (form, id_cliente, id_ficha_tecnica) => {
		let params = {
			id_cliente: id_cliente,
			id_ficha_tecnica: id_ficha_tecnica,
			
			id_cliente_contratos_individuales_firmados: form.id_cliente_contratos_individuales_firmados
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Juridico_Contratos_Individuales_Firmados_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarContratosIndividualesFirmadosConfirmarResguardo: (form, comentario) => {
		
		let params = {
			id_cliente: form.id_cliente,
			id_cliente_contratos_individuales_firmados: form.id_cliente_contratos_individuales_firmados,
			id_ficha_tecnica: form.id_ficha_tecnica,
			confirmar: 1,
			comentario: comentario || ''
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Juridico_Contratos_Individuales_Firmados_Confirmar_Resguardo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarContratosIndividualesConfirmar: (form) => {
		
		let params = {
			
			id_cliente: form.id_cliente,
			
			id_ficha_tecnica: form.id_ficha_tecnica,
			
			comentario_juridico_contratos_individuales: form.comentario_juridico_contratos_individuales,
			
			comentario_observacion_desarrollador: form.comentario_observacion_desarrollador
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Juridico_Contratos_Individuales_Confirmar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarFechaFirmaContratosIndividualesGuardar: (form, id_cliente, id_ficha_tecnica) => {
		let params = {
			id_cliente: id_cliente,
			
			id_cliente_agendar_firma_contrato: form.id_cliente_agendar_firma_contrato,
			id_ficha_tecnica: id_ficha_tecnica,
			agendar_cantidad_empleado: form.agendar_cantidad_empleado,
			
			agendar_domicilio: form.agendar_domicilio,
			
			agendar_fecha_hora: form.agendar_fecha_hora,
			observaciones: form.observaciones,
			
			comentario_observacion_desarrollador: '',
		};
		let url = '';
		if (form.id_cliente_agendar_firma_contrato) {
			url = '_Promotor_Fecha_Firma_Contratos_Individuales_Actualizar';
		} else {
			url = '_Promotor_Fecha_Firma_Contratos_Individuales_Guardar';
		}
		return new Promise((resolve, reject) => {
			HttpRequest.post(url, params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarFechaFirmaContratosIndividualesGuardarIAS: (form, id_cliente, id_ficha_tecnica) => {
		let params = {
			id_cliente: id_cliente,
			
			id_cliente_agendar_firma_contrato_ias: form.id_cliente_agendar_firma_contrato_ias,
			id_ficha_tecnica: id_ficha_tecnica,
			agendar_cantidad_empleado: form.agendar_cantidad_empleado,
			
			agendar_domicilio: form.agendar_domicilio,
			
			agendar_fecha_hora: form.agendar_fecha_hora,
			observaciones: form.observaciones,
			
			comentario_observacion_desarrollador: '',
		};
		let url = '';
		if (form.id_cliente_agendar_firma_contrato_ias) {
			url = '_Promotor_Fecha_Firma_Contratos_Individuales_Actualizar_IAS';
		} else {
			url = '_Promotor_Fecha_Firma_Contratos_Individuales_Guardar_IAS';
		}
		return new Promise((resolve, reject) => {
			HttpRequest.post(url, params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarFechaFirmaContratosIndividualesEliminar: (form, id_cliente) => {
		let params = {
			id_cliente: id_cliente,
			
			id_cliente_agendar_firma_contrato: form.id_cliente_agendar_firma_contrato,
			
			comentario_observacion_desarrollador: '',
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post("_Promotor_Fecha_Firma_Contratos_Individuales_Eliminar", params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarFechaFirmaContratosIndividualesEliminarIAS: (form, id_cliente) => {
		let params = {
			id_cliente: id_cliente,
			
			id_cliente_agendar_firma_contrato_ias: form.id_cliente_agendar_firma_contrato_ias,
			
			comentario_observacion_desarrollador: '',
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post("_Promotor_Fecha_Firma_Contratos_Individuales_Eliminar_IAS", params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarFechaFirmaContratosIndividualesCompletado: (form, id_cliente) => {
		let params = {
			id_cliente: id_cliente,
			
			id_cliente_agendar_firma_contrato: form.id_cliente_agendar_firma_contrato,
			
			comentario_observacion_desarrollador: '',
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post("_Promotor_Fecha_Firma_Contratos_Individuales_Completado", params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarFechaFirmaContratosIndividualesCompletadoIAS: (form, id_cliente) => {
		let params = {
			id_cliente: id_cliente,
			
			id_cliente_agendar_firma_contrato_ias: form.id_cliente_agendar_firma_contrato_ias,
			
			comentario_observacion_desarrollador: '',
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post("_Promotor_Fecha_Firma_Contratos_Individuales_Completado_IAS", params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarFechaFirmaContratosIndividualesConfirmar: (id_cliente, id_ficha_tecnica) => {
		let params = {
			id_cliente: id_cliente,
			id_ficha_tecnica: id_ficha_tecnica,
			comentario_observacion_desarrollador: '',
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post("_Promotor_Fecha_Firma_Contratos_Individuales_Confirmar", params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarFechaFirmaContratosIndividualesConfirmarIAS: (id_cliente, id_ficha_tecnica) => {
		let params = {
			id_cliente: id_cliente,
			id_ficha_tecnica: id_ficha_tecnica,
			comentario_observacion_desarrollador: '',
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post("_Promotor_Fecha_Firma_Contratos_Individuales_Confirmar_IAS", params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarConfirmacionClientesPagadoras: (data) => {
		let params = {
			id_cliente: data.id_cliente,
			id_ficha_tecnica: data.id_ficha_tecnica,
			cliente_que_paga: data.cliente_que_paga,
			comentario_observacion_desarrollador: '',
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post("_Tesoreria_Clientes_Pagadoras_Confirmar", params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarDatosPromotor: (form) => {
		
		let params = {
			
			id_cliente: form.id_cliente,
			
			id_ficha_tecnica: form.id_ficha_tecnica,
			
			concepto_de_facturacion: form.concepto_de_facturacion,
			id_cat_banco_deposita_cliente: form.id_cat_banco_deposita_cliente,
			id_cat_banco_depositos_personal: form.id_cat_banco_depositos_personal,
			datos_especiales_para_la_operacion: form.datos_especiales_para_la_operacion,
			promotor_id_usuario_nombre_completo: form.promotor_id_usuario_nombre_completo,
			promotor_no_cuenta_clabe_interbancaria: form.promotor_no_cuenta_clabe_interbancaria,
			promotor_id_cat_banco: form.promotor_id_cat_banco,
			promotor_comision: form.promotor_comision,
			promotor_telefono: form.promotor_telefono,
			promotor_celular: form.promotor_celular,
			promotor_correo_electronico: form.promotor_correo_electronico,
			promotor_comentarios_especiales_manejo_cuenta_cliente: form.promotor_comentarios_especiales_manejo_cuenta_cliente,
			
			comentario_observacion_desarrollador: form.comentario_observacion_desarrollador
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Tesoreria_Datos_Cliente_Datos_Promotor_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SubirContratoPrestacionServicioPromotor: (form) => {
		
		let params = {
			
			id_cliente: form.id_cliente,
			
			contrato_prestacion_servicio_archivo: "form.formato_alta_baja_empleado_archivo",
			contrato_prestacion_servicio_formato: form.contrato_prestacion_servicio_formato,
			archivo: form.archivo,
			observaciones: form.observaciones,
			lugar_resguardo: form.lugar_resguardo,
			responsable_resguardo: form.responsable_resguardo,
			
			comentario_observacion_desarrollador: ''
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Promotor_Subir_Contrato_Prestacion_Servicio', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	DesactivarContratoPrestacionServicioPromotor: (item, key) => {
		let params = {
			id_cliente: item.id_cliente,
			id_contrato_prestacion_servicio: item.id_contrato_prestacion_servicio,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Promotor_Desactivar_Contrato_Prestacion_Servicio', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SubirFormatoAltaBajaDeEmpleados: (form) => {
		
		let params = {
			
			id_cliente: form.id_cliente,
			
			formato_alta_baja_empleado_archivo: "form.formato_alta_baja_empleado_archivo",
			formato_alta_baja_empleado_formato: form.formato_alta_baja_empleado_formato,
			archivo: form.archivo,
			observaciones: form.observaciones,
			
			comentario_observacion_desarrollador: ''
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Promotor_Subir_Formato_Alta_Baja_Empleado', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	DesactivarFormatoAltaBajaDeEmpleados: (item, key) => {
		let params = {
			id_cliente: item.id_cliente,
			id_formato_alta_baja_empleado: item.id_formato_alta_baja_empleado,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Promotor_Desactivar_Formato_Alta_Baja_Empleado', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarAcuseComprobanteAltaIMSS: (form) => {
		let params = {
			id_nomina_empleado_alta_imss: form.id_nomina_empleado_alta_imss,
			id_cliente: form.id_cliente,
			acuse_archivo: form.acuse_archivo,
			acuse_formato: form.acuse_formato,
			cantidad_empleado: form.cantidad_empleado,
			observaciones: form.observaciones,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recursos_Humanos_Acuse_Comprobante_Alta_IMSS', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarNominaEmpleadosAltaIMSSPendiente: (form) => {
		
		let params = {
			
			id_cliente: form.id_cliente,
			
			excel_nomina_empleado_pendiente_archivo: form.excel_nomina_empleado_pendiente_archivo,
			excel_nomina_empleado_pendiente_formato: form.excel_nomina_empleado_pendiente_formato,
			
			comentario_pendiente: form.comentario_pendiente,
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recursos_Humanos_Subir_Nomina_De_Empleados_Pendientes', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarFormatoAltaEmpleado: (form) => {
		
		let params = {
			
			id_cliente: form.id_cliente,
			
			excel_formato_alta_empleado_archivo: form.excel_formato_alta_empleado_archivo,
			excel_formato_alta_empleado_formato: form.excel_formato_alta_empleado_formato,
			
			observaciones: form.observaciones,
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recursos_Humanos_Subir_Formato_Alta_Empleado', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ClienteFechaPrenominaGuardar: (form) => {
		
		let fecha_inicio = DateFormat.FormatSql(form.fecha_inicio);
		
		let fecha_fin = DateFormat.FormatSql(form.fecha_fin);
		
		let params = {
			
			id_cliente: form.id_cliente,
			id_fecha_prenomina: form.id_fecha_prenomina,
			
			fecha_inicio: fecha_inicio,
			fecha_fin: fecha_fin,
			editfecha: form.editfecha,
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recursos_Humanos_Fecha_Prenomina_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ClientePrenominaGuradar: (form) => {
		
		let params = {
			
			id_cliente: form.id_cliente,
			
			id_fecha_prenomina: form.id_fecha_prenomina,
			
			prenomina_archivo: form.prenomina_archivo,
			prenomina_formato: form.prenomina_formato,
			
			comentario: form.comentario,
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recursos_Humanos_Prenomina_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ClientePrenominaComentarioGuardar: (form) => {
		
		let id_cat_prenomina_status = null;
		
		if (form.checkbox_rechazado_si) {
			id_cat_prenomina_status = 4;
		}
		
		if (form.checkbox_rechazado_no) {
			id_cat_prenomina_status = 2;
		}
		
		let params = {
			
			id_cliente: form.id_cliente,
			
			id_prenomina: form.id_prenomina,
			
			id_cat_prenomina_status: id_cat_prenomina_status,
			
			comentario: form.motivo_comentario,
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recursos_Humanos_Y_Promotor_Prenomina_Comentario_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	PrenominaAprobarYBloquear: (form) => {
		
		let params = {
			
			id_cliente: form.id_cliente,
			
			id_fecha_prenomina: form.id_fecha_prenomina,
			
			id_prenomina: form.id_prenomina,
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Promotor_Prenomina_Aprobar_Y_Bloquear', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SubirContratoPrestacionServicio: (form) => {
		
		let params = {
			
			id_cliente_contrato_prestacion_servicio: null,
			id_cliente: form.id_cliente,
			
			contrato_prestacion_servicio_archivo: "form.contrato_prestacion_servicio_archivo",
			contrato_prestacion_servicio_formato: form.contrato_prestacion_servicio_formato,
			archivo: form.archivo,
			observaciones: form.observaciones,
			
			comentario_observacion_desarrollador: ''
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Juridico_Subir_Contrato_Prestacion_Servicio', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	DesactivarContratoPrestacionServicio: (item, key) => {
		let params = {
			id_cliente_contrato_prestacion_servicio: item.id_cliente_contrato_prestacion_servicio,
			id_cliente: item.id_cliente,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Juridico_Desactivar_Contrato_Prestacion_Servicio', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ClienteReceptoraPagoAgregar: (item) => {
		let params = {
			id_cliente_receptora_pago: item.id_cliente_receptora_pago,
			id_cliente: item.id_cliente,
			id_cat_cliente_receptora_pago: item.id_cat_cliente_receptora_pago,
			activo: item.activo || 1,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Tesoreria_Cliente_Receptora_Pago_Agregar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ClienteReceptoraPagoEliminar: (item) => {
		let params = {
			id_cliente_receptora_pago: item.id_cliente_receptora_pago,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Tesoreria_Cliente_Receptora_Pago_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ClienteReceptoraPagoAgregarDatoBancario: (item) => {
		let params = {
			id_cliente_receptora_pago: item.id_cliente_receptora_pago,
			id_cat_cliente_receptora_pago_dato_bancario: item.id_cat_cliente_receptora_pago_dato_bancario,
			activo: item.activo || 1
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Tesoreria_Cliente_Receptora_Pago_Dato_Bancario_Agregar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ClienteReceptoraPagoEliminarDatoBancario: (item) => {
		let params = {
			id_cliente_receptora_pago_dato_bancario: item.id_cliente_receptora_pago_dato_bancario,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Tesoreria_Cliente_Receptora_Pago_Dato_Bancario_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
