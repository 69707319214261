import React, {Component, Fragment} from 'react';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {BorderColor, Mail, Person, Phone, Vignette} from "@material-ui/icons";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import * as PropTypes from "prop-types";
import {QuinielaService} from "../../../../services/QuinielaService/QuinielaService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

class ModalQuiniela extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			//Adquisicion
			id_quiniela: (props.item || '').id_quiniela,
			nombre: '',
			id_usuario: '',
			fecha_inicio: null,
			fecha_fin: null,
			responsable: '',
			correo_electronico: '',
			celular: '',
			observacion: '',
			
			activo: true,
			
			cat_escuderia: [],
			cat_piloto: [],
			
			cat_tipo_adquisicion: [],
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	listaUsuarioQuiniela = () => {
		CatService.ListUsuarioQuiniela().then((response) => {
			this.setState({
				lista_usuario: response.data
			});
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	save = () => {
		if (this.state.id_quiniela > 0) {
			QuinielaService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			QuinielaService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		const {item} = this.props;
		
		this.listaUsuarioQuiniela();
		
		this.setState({
			//Adquisicion
			nombre: '',
			id_usuario: '',
			fecha_inicio: null,
			fecha_fin: null,
			responsable: '',
			correo_electronico: '',
			celular: '',
			observacion: '',
			activo: true,
			
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		
		this.listaUsuarioQuiniela();
		
		this.setState({
			//Adquisicion
			id_quiniela: item.id_quiniela || '',
			nombre: item.nombre || '',
			id_usuario: item.id_usuario || '',
			fecha_inicio: item.fecha_inicio || null,
			fecha_fin: item.fecha_fin || null,
			responsable: item.responsable || '',
			correo_electronico: item.correo_electronico || '',
			celular: item.celular || '',
			observacion: item.observacion || '',
			activo: true,
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		
		this.listaUsuarioQuiniela();
		
		this.setState({
			//Adquisicion
			id_quiniela: item.id_quiniela || '',
			nombre: item.nombre || '',
			id_usuario: item.id_usuario || '',
			fecha_inicio: item.fecha_inicio || null,
			fecha_fin: item.fecha_fin || null,
			responsable: item.responsable || '',
			correo_electronico: item.correo_electronico || '',
			celular: item.celular || '',
			observacion: item.observacion || '',
			activo: true,
			
		});
		this.open();
	};
	
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_quiniela}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Quiniela'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Vignette className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											required
											name="nombre"
											label="Nombre"
											value={this.state.nombre}
										/>
									
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<BorderColor className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											rows={2}
											name="observacion"
											onChange={this.handleChange}
											label="Observación"
											autoComplete={'off'}
											value={this.state.observacion}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<KeyboardDatePicker
									fullWidth
									clearable
									format={'dd/MM/yyyy'}
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									label="Fecha inicio"
									value={this.state.fecha_inicio}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_inicio');
									}}
									required
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<KeyboardDatePicker
									fullWidth
									clearable
									format={'dd/MM/yyyy'}
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									label="Fecha fin"
									value={this.state.fecha_fin}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_fin');
									}}
									required
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							{/*<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-50-px'}>
										<ModalUsuarios
											tipo={'add'}
											RefreshList={this.props.RefreshList}
											showSnackBars={this.props.showSnackBars}
											listaUsuarioSocio={this.listaUsuarioSocio}
										/>
									</Grid>
									<Grid item className={'w-100-50-px'}>
										<FormControl fullWidth required>
											<InputLabel>Encargado</InputLabel>
											<Select
												native
												value={this.state.id_usuario}
												onChange={this.handleChange}
												name="id_usuario"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_escuderia.map(option => (
													<option key={option.id_usuario}
													        value={option.id_usuario}>
														{option.nombre_completo}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>*/}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider/>
								<Typography className={'text-left px-13 margin-15-T'}>
									Responsable
								</Typography>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Person className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											required
											name="responsable"
											label="Nombre completo"
											value={this.state.responsable}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Mail className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											name="correo_electronico"
											label="Correo electrónico"
											value={this.state.correo_electronico}
										/>
									
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Phone className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="celular"
											onChange={this.handleChange}
											inputProps={{maxLength: 10}}
											onKeyPress={EnteroSolo}
											label="Celular"
											autoComplete={'off'}
											value={this.state.celular}
											disabled={this.props.tipo === 'view'}
										/>
									
									</Grid>
								</Grid>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<BotonAccionFooterModal
							id={this.state.id_quiniela}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalQuiniela.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalQuiniela;
