import React, {Component, Fragment} from 'react';

import Typography from "@material-ui/core/Typography";
import ListaTabla from './Includes/ListaTabla';
import ModalImportesNetos from './Includes/ModalImportesNetos';

import {ImportesNetosService} from '../../../services/ImportesNetosService/ImportesNetosService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../settings/Config/Config";
import Paginacion from "../../Include/Paginacion/Paginacion";
import FiltrosImportesNetos from "./Includes/FiltrosImportesNetos";
import {BotonActualizarLista, BotonExportarListaExcel, BotonExportarListaPDF, CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import Fab from "@material-ui/core/Fab";
import {PostAdd} from "@material-ui/icons";
import ModalImportarArchivo from "./Includes/ModalImportarArchivo";


class ImportesNetos extends Component {
	
	Usr = {};
	
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		let ids_expandido = ReactLocalStorageService.get('ids_expandido');

		this.state = {
			listar_importes_netos: [],
			filtro: {
				id_importe_neto: '',
				id_empresa: '',
				fecha_inicio: null,
				fecha_fin: null,
				fecha_envio: null,
				estatus: '',
				activo: 1
			},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			lista_empresa: [],
			
			ids_expandido: ids_expandido || []
			
		};
		
		this.RefreshList();
		this.ListEmpresa();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	Listar = () => {
		ImportesNetosService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				listar_importes_netos: response.data,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			this.setState({listar_importes_netos: []});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	Eliminar = (item) => {
		
		let msg = `¿Deseas eliminar el usuario ${item.nombre} ${item.apellido_paterno} ${item.apellido_materno}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				ImportesNetosService.Eliminar(item.id_usuario).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	ListEmpresa = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpresa().then((response) => {
				this.setState({
					lista_empresa: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	ShowSubTabla = (item, key) => {
		let expandir = !!!item.expandir;
		this.handleChange({
			target: {
				value: expandir,
				name: 'expandir__' + key,
				type: 'text'
			}
		}, 'listar_importes_netos', 'expandir');
		let ids_temp = this.state.ids_expandido;
		let ids_expandido = [];
		if (expandir) {
			ids_expandido = ids_temp;
			ids_expandido.push({
				id_importe_neto: item.id_importe_neto
			});
		} else {
			for (let i = 0; i < ids_temp.length; i++) {
				if (Number(ids_temp[i].id_importe_neto) !== Number(item.id_importe_neto)) {
					ids_expandido.push({
						id_importe_neto: ids_temp[i].id_importe_neto
					})
				}
			}
			
		}
		ReactLocalStorageService.set('ids_expandido', ids_expandido);
		this.setState({
			ids_expandido: ids_expandido
		});
	};
	
	SubTabla = (item, key) => {
		this.ShowSubTabla(item, key);
	};
	
	exportar = (item, archivo, auth) => {
		let filtro = {
			id_importe_neto: this.state.id_importe_neto || null,
			id_empresa: this.state.id_empresa || null,
			fecha_inicio: this.state.fecha_inicio || null,
			fecha_fin: this.state.fecha_fin || null,
			fecha_envio: this.state.fecha_envio || null,
			estatus: this.state.estatus || null,
			activo: this.state.activo || null
		};
		console.log('EXPORTAR::: ', item);
		HttpRequest.export('importes_netos', ['importes_netos'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, false, false, false, archivo === "EXCEL")
	};
	
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'Importes Netos'}
					/*botonPDF={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaPDF onClick={() => {
								
								}}/>
							) : ''}
						</Fragment>
					}*/
					/*botonValida={
						<Fragment>
							<ModalImportarArchivo
								item={{}}
								componente={
									<Fab className={'vertical-inline'} variant="extended" size="small" color="primary" style={{backgroundColor: "rgba(130,38,27,0.8)"}}>
										<PostAdd className={'v-center margin-5-R px-15'}/>
										<div className={'v-center px-13 text-white '}>
											Importar Archivo
										</div>
									</Fab>
								}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
						</Fragment>
					}*/
					botonEXCEL={
						<Fragment>
							<BotonExportarListaExcel onClick={() => this.exportar(this.state.filtro, 'EXCEL', true)}/>
						</Fragment>
					}
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() => {
								this.Listar();
							}}/>
						</Fragment>
					}
				/>
				
				<FiltrosImportesNetos
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
					ListEmpresa={this.ListEmpresa}
					lista_empresa={this.state.lista_empresa}
				/>
				
				<div className={'form margin-30-B'}>
					
					<Typography variant={'h6'} className={'margin-30-T margin-30-B'}>
					
					</Typography>
					
					{this.state.listar_importes_netos.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_importes_netos}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
								ListEmpresa={this.ListEmpresa}
								lista_empresa={this.state.lista_empresa}
								SubTabla={this.SubTabla}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				<ModalImportesNetos
					tipo={'add'}
					ListEmpresa={this.ListEmpresa}
					lista_empresa={this.state.lista_empresa}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>
			
			</div>
		);
	}
}

ImportesNetos.propTypes = {};

export default IntegrationNotistack(ImportesNetos);
