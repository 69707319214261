import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const RepseService = {
	Listar: (filtro, paginacion) => {
		let fecha_alta = (filtro.fecha_alta || null) ? DateFormat.FormatSql(filtro.fecha_alta) : null;
		let fecha_registro = filtro.fecha_registro ? DateFormat.FormatSql(filtro.fecha_registro) : null;
		
		let params = {
			filtro: {
				id_repse: filtro.id_repse || null,
				id_empresa: filtro.id_empresa || null,
				ejercicio: filtro.ejercicio || null,
				rfc: filtro.rfc || null,
				tipo: filtro.tipo || null,
				numero_registro: filtro.numero_registro || null,
				periodo: filtro.periodo || null,
				id_cat_tipo_impuesto: filtro.id_cat_tipo_impuesto || null,
				id_cat_tipo_declaracion: filtro.id_cat_tipo_declaracion || null,
				fecha_alta: fecha_alta || null,
				fecha_registro: fecha_registro,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repse_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_vigencia = form.fecha_vigencia ? DateFormat.FormatSql(form.fecha_vigencia) : null;
		let fecha_registro = form.fecha_registro ? DateFormat.FormatSql(form.fecha_registro) : null;
		
		let params = {
			id_repse: null,
			id_empresa: form.id_empresa,
			ejercicio: form.ejercicio,
			periodo: form.periodo,
			id_cat_tipo_impuesto: form.id_cat_tipo_impuesto,
			id_cat_tipo_declaracion: form.id_cat_tipo_declaracion,
			fecha_vigencia: fecha_vigencia,
			observaciones: form.observaciones,
			importe: form.importe,
			tipo_documento: form.tipo_documento,
			numero_registro: form.numero_registro,
			fecha_registro: fecha_registro,
			recargo: form.recargo,
			archivos: form.archivos,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repse_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_vigencia = form.fecha_vigencia ? DateFormat.FormatSql(form.fecha_vigencia) : null;
		let fecha_registro = form.fecha_registro ? DateFormat.FormatSql(form.fecha_registro) : null;
		
		let params = {
			id_repse: form.id_repse,
			id_empresa: form.id_empresa,
			ejercicio: form.ejercicio,
			periodo: form.periodo,
			id_cat_tipo_impuesto: form.id_cat_tipo_impuesto,
			id_cat_tipo_declaracion: form.id_cat_tipo_declaracion,
			fecha_vigencia: fecha_vigencia,
			observaciones: form.observaciones,
			numero_registro: form.numero_registro,
			fecha_registro: fecha_registro,
			tipo_documento: form.tipo_documento,
			importe: form.importe,
			recargo: form.recargo,
			archivos: form.archivos,
			activo: form.activo ? 1 : 0,
		};
		
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repse_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_repse) => {
		let params = {
			id_repse: id_repse
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repse_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ComprobantePago: (form) => {
		let params = {
			id_repse: form.id_repse,
			observaciones: form.observaciones,
			comprobante_pago: form.comprobante_pago || 0,
			archivos: form.archivos
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repse_Comprobante_Pago', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SendMail: (form) => {
		let params = {
			id_repse: form.id_repse,
			id_usuario: form.id_usuario,
			mensaje: form.mensaje,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repse_SendRocketChat', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarDownload: (filtro) => {
		console.log('FILTRO', filtro);
		let params = {
			filtro: {
				id_empresa: filtro.id_empresa,
				ejercicio: filtro.ejercicio,
				periodo: filtro.periodo,
				id_cat_tipo_impuesto: filtro.id_cat_tipo_impuesto,
				id_cat_tipo_declaracion: filtro.id_cat_tipo_declaracion,
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repse_Donwload', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
