import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const RepositorioJuridicoService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				id_repositorio_juridico: filtro.id_repositorio_juridico || '',
				empresa: filtro.empresa || '',
				id_cat_tipo_documento_repositorio_juridico: filtro.id_cat_tipo_documento_repositorio_juridico || '',
				id_cat_centro_trabajo_repositorio_juridico: filtro.id_cat_centro_trabajo_repositorio_juridico || '',
				responsable: filtro.responsable || '',
				id_cat_tipo_usuario: filtro.id_cat_tipo_usuario || '',
				representante_legal: filtro.representante_legal || '',
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : 'all',
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Juridico_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_vencimiento = form.fecha_vencimiento && form.fecha_vencimiento !== 'NaN-NaN-NaN' ? DateFormat.FormatSql(form.fecha_vencimiento) : '';
		
		let params = {
			id_repositorio_juridico: form.id_repositorio_juridico || '',
			id_cat_tipo_documento_repositorio_juridico: form.id_cat_tipo_documento_repositorio_juridico || '',
			id_cat_centro_trabajo_repositorio_juridico: form.id_cat_centro_trabajo_repositorio_juridico || '',
			id_empresa: form.id_empresa || '',
			direccion: form.direccion || '',
			responsable: form.responsable || '',
			representante_legal: form.representante_legal || '',
			administrador_unico: form.administrador_unico || '',
			observaciones: form.observaciones || '',
			numero_registro_patronal: form.numero_registro_patronal || '',
			fecha_vencimiento: fecha_vencimiento,
			direccion_rp: form.direccion_rp || '',
			password: form.password || '',
			archivos: form.archivos || [],
			id_cliente: form.id_cliente || '',
			rfc: form.rfc || '',
			nombre: form.nombre || '',
			codigo_postal: form.codigo_postal || '',
			id_cat_regimen_fiscal: form.id_cat_regimen_fiscal || '',
			fisicamente_rl: form.fisicamente_rl || '',
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Juridico_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_vencimiento = form.fecha_vencimiento && form.fecha_vencimiento !== 'NaN-NaN-NaN' ? DateFormat.FormatSql(form.fecha_vencimiento) : '';
		
		let params = {
			id_repositorio_juridico: form.id_repositorio_juridico || '',
			id_cat_tipo_documento_repositorio_juridico: form.id_cat_tipo_documento_repositorio_juridico || '',
			id_cat_centro_trabajo_repositorio_juridico: form.id_cat_centro_trabajo_repositorio_juridico || '',
			id_empresa: form.id_empresa || '',
			direccion: form.direccion || '',
			responsable: form.responsable || '',
			representante_legal: form.representante_legal || '',
			administrador_unico: form.administrador_unico || '',
			observaciones: form.observaciones || '',
			numero_registro_patronal: form.numero_registro_patronal || '',
			fecha_vencimiento: fecha_vencimiento,
			direccion_rp: form.direccion_rp || '',
			password: form.password || '',
			archivos: form.archivos || [],
			id_cliente: form.id_cliente || '',
			rfc: form.rfc || '',
			nombre: form.nombre || '',
			codigo_postal: form.codigo_postal || '',
			id_cat_regimen_fiscal: form.id_cat_regimen_fiscal || '',
			fisicamente_rl: form.fisicamente_rl || '',
			activo: form.activo ? 1 : 0,
		};
		
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Juridico_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_repositorio_juridico) => {
		let params = {
			id_repositorio_juridico: id_repositorio_juridico
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Juridico_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Compartir: (form) => {
		let params = {
			id_repositorio_juridico: form.id_repositorio_juridico || '',
			usuarios_compartir: form.usuarios_compartir || []
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Juridico_Compartir', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Descompartir: (form) => {
		let params = {
			id_repositorio_juridico: form.id_repositorio_juridico || '',
			id_usuario: form.id_usuario || []
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Juridico_Descompartir', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	UsuariosCompartidos: (form) => {
		let params = {
			id_repositorio_juridico: form.id_repositorio_juridico || '',
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Juridico_Usuarios_Compartidos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	EstatusAgregar: (form) => {
		let fecha = form.fecha && form.fecha !== 'NaN-NaN-NaN' ? DateFormat.FormatSql(form.fecha) : '';
		let params = {
			id_repositorio_juridico: form.id_repositorio_juridico || '',
			fecha: fecha,
			motivo: form.motivo || '',
			id_cat_repositorio_juridico_estatus: form.id_cat_repositorio_juridico_estatus || '',
			archivos: form.archivos,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Juridico_Estatus_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarEstatus: (form) => {
		let params = {
			id_repositorio_juridico: form.id_repositorio_juridico || '',
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Juridico_Estatus_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Bitacora: (filtro, paginacion) => {
		let fecha = filtro.fecha && filtro.fecha !== 'NaN-NaN-NaN' ? DateFormat.FormatSql( filtro.fecha) : '';
		
		let params = {
			filtro: {
				id_repositorio_juridico: filtro.id_repositorio_juridico || '',
				usuario: filtro.usuario || '',
				fecha: fecha || '',
				actividad: filtro.actividad || '',
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : 'all',
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Juridico_Bitacora', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Descarga: (form) => {
		let params = {
			id_repositorio_juridico: form.id_repositorio_juridico || '',
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Repositorio_Juridico_Descarga', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
