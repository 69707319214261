import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import moment from "moment";
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const TemporalFacturacionExtService = {
	Listar: (filtro, paginacion) => {
		let fecha_inicio = (filtro.fecha_inicio || null) ? DateFormat.FormatSql(filtro.fecha_inicio) : null;
		let fecha_fin = (filtro.fecha_fin || null) ? DateFormat.FormatSql(filtro.fecha_fin) : null;
		
		let params = {
			filtro: {
				id_proyecto: 2,
				id_usuario: filtro.id_usuario || "",
				id_plaza: filtro.id_plaza || "",
				folio: filtro.folio || "",
				serie: filtro.serie || "",
				empresa: filtro.empresa || "",
				cliente: filtro.cliente || "",
				lugar_expedicion: filtro.lugar_expedicion || "",
				fecha_inicio: fecha_inicio || "",
				fecha_fin: fecha_fin || "",
				id_cat_forma_pago: filtro.id_cat_forma_pago || "",
				id_cat_metodo_pago: filtro.id_cat_metodo_pago || "",
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Temporal_Datos', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_proyecto: 2,
			rfc_emisor: form.rfc_emisor || '',
			sucursal_emisor: form.sucursal_emisor || '',
			certificado_emisor: form.certificado_emisor || '',
			observaciones_emisor: form.observaciones_emisor || '',
			enviar_a: form.enviar_a || '',
			enviar_cc: form.enviar_cc || '',
			enviar_cco: form.enviar_cco || '',
			id_empresa: form.id_empresa || '',
			id_cliente: form.id_cliente || '',
			id_empresa_sucursal: form.id_empresa_sucursal || '',
			id_cat_regimen_fiscal: form.id_cat_regimen_fiscal || '',
			lugar_expedicion: form.lugar_expedicion || '',
			cliente_regimen_fiscal: form.cliente_regimen_fiscal || '',
			cliente_numero_registro_if: form.cliente_numero_registro_if || '',
			partidas: form.partidas || [],
			desglose: form.desglose || '',
			id_cat_tipo_cfdi: form.id_cat_tipo_cfdi || '',
			id_cat_metodo_pago: form.id_cat_metodo_pago || '',
			id_cat_forma_pago: form.id_cat_forma_pago || '',
			id_cat_moneda: form.id_cat_moneda || '',
			id_cat_uso_cfdi: form.id_cat_uso_cfdi || '',
			id_empresa_sucursal_serie: form.id_empresa_sucursal_serie || '',
			fecha: form.fecha || '',
			serie: form.serie || '',
			folio: form.folio || '',
			condiciones_pago: form.condiciones_pago || '',
			clave_confirmacion: form.clave_confirmacion || '',
			tipo_cambio: form.tipo_cambio || '',
			produccion: form.produccion ? 1 : 0,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Temporal_Guardar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_proyecto: 2,
			id_empresa: form.id_empresa || '',
			id_cat_tipo_persona: form.id_cat_tipo_persona || '',
			nombre_razon_social: form.nombre_razon_social || '',
			rfc: form.rfc || '',
			sucursal: form.sucursal || '',
			correo_electronico: form.correo_electronico || '',
			telefono: form.telefono || '',
			id_cat_pais: form.id_cat_pais || '',
			codigo_postal: form.codigo_postal || '',
			password: form.password || '',
			pagina_web: form.pagina_web || '',
			serie: form.serie || '',
			folio_inicial: form.folio_inicial || '',
			
			logo_base64Tipo: form.logo_base64Tipo || '',
			logo_base64: form.logo_base64 || '',
			logo_archivo: form.logo_archivo || '',
			logo_formato: form.logo_formato || '',
			
			cer_base64Tipo: form.cer_base64Tipo || '',
			cer_base64: form.cer_base64 || '',
			cer_archivo: form.cer_archivo || '',
			cer_formato: form.cer_formato || '',
			
			key_base64Tipo: form.key_base64Tipo || '',
			key_base64: form.key_base64 || '',
			key_archivo: form.key_archivo || '',
			key_formato: form.key_formato || '',
			
			terminos_condiciones: form.terminos_condiciones ? 1 : 0,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Temporal_Modificar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_facturacion_cfdi_temporal) => {
		let params = {
			id_proyecto: 2,
			id_facturacion_cfdi_temporal: id_facturacion_cfdi_temporal
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Temporal_Eliminar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	CFDITemporalFac: (form) => {
		let params = {
			id_proyecto: 2,
			id_facturacion_cfdi_temporal: form.id_facturacion_cfdi_temporal
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Temporal_Xid', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
