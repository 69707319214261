import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Typography from "@material-ui/core/Typography";
import Fab from '@material-ui/core/Fab';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import EventNote from '@material-ui/icons/EventNote';
import Sync from '@material-ui/icons/Sync';
import ListaTabla from './Includes/ListaTabla';
import ModalClientePotencial from './Includes/ModalClientePotencial';
import ModalMessageConfirmation from '../../../componets/Include/ModalMessageConfirmation/ModalMessageConfirmation';

import {ClientePotencialService} from '../../../services/ClientePotencialService/ClientePotencialService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import ListaVaciaClienteClientePotencialSvg from "../../../assets/img/vacio/lista-clientes-potenciales.svg";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Paginacion from "../../Include/Paginacion/Paginacion";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../settings/Config/Config";
import {ClienteService} from "../../../services/ClienteService/ClienteService";


class ClienteClientePotencial extends Component {
	
	Usr = {};
	
	state = {};
	
	constructor(props) {
		super(props);
		
		let ids_expandido = ReactLocalStorageService.get('ids_expandido');
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			filtro: {
				filtro_id_cat_ficha_tecnica_status: null
			},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			modal: {
				open: false,
				title: '',
				message: '',
				bg: '',
				button: ''
			},
			cat_ficha_tecnica_status: [],
			listar_cliente_cliente_potencial: [],
			
			ids_expandido: ids_expandido || [],
			
			tipo_vista: props.tipo_vista,
			
			listar_cat_cliente: []
		};
		
		this.Listar();
		this.ListarClientes();
	}
	
	ListarClientes = () => {
		ClienteService.ListarTodo().then((response) => {
			this.setState({
				listar_cat_cliente: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_cliente: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	RefreshList = () => {
		this.Listar();
		this.ListarClientes();
	};
	
	$BroadcastModalClientePotencial = (data) => {
		
		switch (data.accion) {
			case 'delete':
				if (data.status === true) {
					this.Eliminar(data.item);
				}
				break;
			case 'list':
				if (data.status === true) {
					this.Listar();
					if (data.openmodal === true) {
						this.openModal(1);
					}
				}
				break;
			default:
				if (data.status === true) {
					this.Listar();
				}
		}
	};
	
	Listar = () => {
		ClientePotencialService.Listar(this.state.filtro, this.state.paginacion, this.state.tipo_vista).then((response) => {
			let data = response.data;
			let cat_ficha_tecnica_status = data.cat_ficha_tecnica_status || [];
			let listar_cliente_cliente_potencial = data.cliente || [];
			this.setState({
				cat_ficha_tecnica_status: cat_ficha_tecnica_status || [],
				listar_cliente_cliente_potencial: listar_cliente_cliente_potencial || [],
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			this.UpdateExpandir()
		}).catch((error) => {
			this.setState({
				listar_cliente_cliente_potencial: [],
				cat_ficha_tecnica_status: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	UpdateExpandir = () => {
		let listar_cliente_cliente_potencial = this.state.listar_cliente_cliente_potencial || [];
		let ids_expandido = this.state.ids_expandido || [];
		for (let i = 0; i < listar_cliente_cliente_potencial.length; i++) {
			for (let j = 0; j < ids_expandido.length; j++) {
				if (Number(ids_expandido[j].id_cliente) === Number(listar_cliente_cliente_potencial[i].id_cliente)) {
					listar_cliente_cliente_potencial[i].expandir = true;
				}
			}
		}
		this.setState({
			listar_cliente_cliente_potencial: listar_cliente_cliente_potencial || [],
		});
	};
	
	Eliminar = (item) => {
		
		let msg = `¿Deseas eliminar el cliente ${item.nombre_comercial}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				ClientePotencialService.Eliminar(item.id_cliente).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	filtro = (item) => {
		let filtro = this.state.filtro;
		filtro.filtro_id_cat_ficha_tecnica_status = item.id_cat_ficha_tecnica_status;
		this.setState({
			filtro: filtro
		});
		setTimeout(() => {
			this.Listar();
		}, 100);
	};
	
	openModal = tipo => {
		switch (tipo) {
			case 1:
				this.setState({
					modal: {
						open: true,
						title: 'El cliente fue creado con éxito',
						message: 'Continua agregando la ficha técnica, en el icono de crear ficha técnica en la lista de clientes',
						bg: 1,
						button: 'Aceptar'
					}
				});
				break;
			default:
				this.setState({
					modal: {
						open: true,
						title: 'Title',
						message: 'Message',
						bg: 1,
						button: 'Aceptar'
					}
				});
		}
		
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false,
				title: '',
				message: '',
				bg: 1,
				button: ''
			}
		})
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	ShowSubTabla = (item, key) => {
		let expandir = !!!item.expandir;
		this.handleChange({
			target: {
				value: expandir,
				name: 'expandir__' + key,
				type: 'text'
			}
		}, 'listar_cliente_cliente_potencial', 'expandir');
		let ids_temp = this.state.ids_expandido;
		let ids_expandido = [];
		if (expandir) {
			ids_expandido = ids_temp;
			ids_expandido.push({
				id_cliente: item.id_cliente
			});
		} else {
			for (let i = 0; i < ids_temp.length; i++) {
				if (Number(ids_temp[i].id_cliente) !== Number(item.id_cliente)) {
					ids_expandido.push({
						id_cliente: ids_temp[i].id_cliente
					})
				}
			}
			
		}
		ReactLocalStorageService.set('ids_expandido', ids_expandido);
		this.setState({
			ids_expandido: ids_expandido
		});
	};
	
	SubTabla = (item, key) => {
		this.ShowSubTabla(item, key);
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				
				{/*<Button color='primary' onClick={this.openModal}>Open</Button>*/}
				
				<ModalMessageConfirmation modal={this.state.modal} closeModal={this.closeModal}/>
				
				<div className={'margin-auto'}>
					<div className={'grid-10-70-px'}>
						
						<Fragment>
							<div align={'left'}>
								<div className={'w-auto h-20-px b-r-3 cursor-pointer text-white px-12 vertical-inline'}
								     style={{background: '#3f51b5'}} onClick={() => this.filtro({})}>
									<div className={'v-center w-100 text-center padding-7-R padding-7-L'}>Todos</div>
								</div>
							</div>
						</Fragment>
						
						{this.state.cat_ficha_tecnica_status.map((item, key) => (
							<Fragment key={key}>
								<div align={'center'}>
									<Tooltip TransitionComponent={Zoom} placement={"bottom"}
									         title={item.ficha_tecnica_status}>
										<div className={'w-20-px h-20-px b-r-100 cursor-pointer'} style={{
											background: `${item.color}`
										}} onClick={() => this.filtro(item)}/>
									</Tooltip>
								</div>
							</Fragment>
						))}
					</div>
				</div>
				
				<div className={'row-flex margin-30-T margin-30-B'}>
					<div className={'w-100-300-px'}>
						<Typography variant={'h5'} className={'text-left'}>
							{this.props.tipo_vista === 'cliente_potencial' ? 'Clientes potenciales' : (this.props.tipo_vista === 'cliente_cliente' ? 'Empresas clientes' : '')}
						</Typography>
					</div>
					<div className={'w-300-px text-right'}>
						{/*{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
							<Fab variant="extended" size="small" color="primary" aria-label="PDF"
							     className={'margin-10-L bg-danger'}>
								<PictureAsPdf className={'margin-5-R px-14'}/>
								PDF
							</Fab>
						) : ''}*/}
						{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
							<Fab variant="extended" size="small" color="primary" aria-label="Excel"
							     className={'margin-10-L bg-green'}>
								<EventNote className={'margin-5-R px-14'}/>
								Excel
							</Fab>
						) : ''}
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={this.Listar}>
							<Sync className={'margin-5-R px-14'}/>
							Actualizar
						</Fab>
					</div>
				</div>
				
				<div className={'form margin-30-B'}>
					
					<Typography variant={'h6'} className={'margin-30-T margin-30-B'}>
					
					</Typography>
					
					{this.state.listar_cliente_cliente_potencial.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_cliente_cliente_potencial}
								listaClientes={this.state.listar_cat_cliente}
								estatus={this.state.cat_ficha_tecnica_status}
								$BroadcastModalClientePotencial={this.$BroadcastModalClientePotencial}
								showSnackBars={this.showSnackBars}
								RefreshList={this.RefreshList}
								tipo_vista={this.props.tipo_vista}
								SubTabla={this.SubTabla}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					
					) : (
						<div className={'opacity-lista-vacia'}>
							<div className={'padding-10'} align={'center'}>
								<img src={ListaVaciaClienteClientePotencialSvg} alt="" width={'200px'} height={'auto'}/>
							</div>
							<h4>
								No se encontraron datos para mostrar
							</h4>
						</div>
					)}
				
				</div>
				
				{this.props.tipo_vista === 'cliente_potencial' && FieldsJs.inArray([1, 2, 3, 13], this.Usr.id_cat_tipo_usuario) ? (
					<ModalClientePotencial
						tipo_vista={this.props.tipo_vista}
						id_cliente={null}
						tipo={'add'}
						item={{}}
						listaClientes={this.state.listar_cat_cliente}
						$BroadcastModalClientePotencial={this.$BroadcastModalClientePotencial}
						RefreshList={this.RefreshList}
						showSnackBars={this.showSnackBars}
					/>
				) : ''}
			
			</div>
		);
	}
}

ClienteClientePotencial.propTypes = {
	tipo_vista: PropTypes.string.isRequired,
};

export default IntegrationNotistack(ClienteClientePotencial);
