import React, {Component, Fragment} from "react";

import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {Send} from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import {CONFIG} from "../../../../settings/Config/Config";
import ListItemText from "@material-ui/core/ListItemText";
import {FieldsJs} from "../../../../settings/General/General";
import {ClienteService} from "../../../../services/ClienteService/ClienteService";

class ChatFichaTecnica extends Component {
	
	state = {};
	
	Usr = {};
	showSnackBars = this.props.showSnackBars;
	
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		const params = props.params || {};
		
		this.state = {
			params: params,
			id_cliente: this.props.state.id_cliente || '',
			id_ficha_tecnica: this.props.state.id_ficha_tecnica || '',
			
			sms: '',
			modulo: this.props.modulo || 0,
			mensajes: [
				//{emisor: 1, nombre_completo_emisor: "Carlos Alberto Medina A.", nombre_completo_remitente: "Obed Gomez", mensaje: "Favor de checar si esta bien este chat?"},
				//{emisor: 0, nombre_completo_emisor: "Carlos Alberto Medina A.", nombre_completo_remitente: "Obed Gomez", mensaje: "Excelente, muy profesional."}
			]
		};
		console.log("CHAT MENSAJES ACTUALIZADOS");
		this.listarChat(this.props.state.id_cliente);
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	listarChat = () => {
		try {
			ClienteService.ChatDatos(this.state, this.state.modulo).then((response) => {
				
				this.setState({
					mensajes: response.data
				});
				
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	guardar = () => {
		try {
			if (!this.state.sms) {
				throw Object({
					status: 400,
					mensaje: "El campo mensaje es requerido."
				});
			}
			
			ClienteService.ChatGuardar(this.state).then((response) => {
				
				this.showSnackBars('success', response.mensaje);
				
				this.setState({
					sms: ''
				});
				
				this.listarChat(this.props.state.id_cliente);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	
	render() {
		
		return (
			<Fragment>
				<article className={'borde-punteado-lightgray-2 card-0 bg-white padding-10 margin-10-T'}>
					
					<Grid container spacing={2} alignItems={"flex-start"} alignContent={"flex-end"} className={'padding-20 bg-image-chat'}>
						
						<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
							<div className={'w-100 vertical-inline'}>
								<div className={'w-100-70-px v-center'}>
									<TextField
										fullWidth
										onChange={this.handleChange}
										type="text"
										margin="none"
										multiline
										helperText=""
										name="sms"
										label="Escribe un mensaje aquí"
										value={this.state.sms}
										//disabled={disabledFieldPromotorIntegracionExpediente}
										InputProps={{
											//endAdornment: <InputAdornment position="end">%</InputAdornment>,
										}}
										disabled={this.props.disabled}
									/>
								</div>
								<div className={'w-70-px v-end'}>
									<Button variant="contained" color="primary" onClick={() => this.guardar()} disabled={this.props.disabled}
									        className={'btn-default-primary text-transform-none'}>
										<Send/>
									</Button>
								</div>
							</div>
						</Grid>
						
						<Grid item className={'text-center padding-10'} xs={12} sm={12} md={12} lg={12} xl={12}>
							<div className={'margin-20-T margin-10-B avatar-right'}>
								
								{/*{this.props.disabled ? (
									<p>Por el momento no se puede</p>
								) : null}*/}
								
								{this.state.mensajes.map((item, key) => (
									<Fragment key={key}>
										<ListItem>
											<ListItemAvatar>
												<Avatar
													alt={item.nombre_completo_emisor}
													src={item.foto ? CONFIG.src + item.foto : CONFIG.foto_default}
													className={'margin-0'}
													style={{
														height: '35px',
														width: '35px',
														marginTop: '2px',
														textAlign: 'left',
													}}
												/>
											</ListItemAvatar>
											
											<ListItemText
												style={{
													fontSize: '12px !important',
													margin: 0,
													padding: 0,
													textAlign: 'left',
													backgroundColor: 'rgba(236,236,236,0.59)'
												}}
												primary={
													<Typography type="body2" style={{fontSize: '11px', margin: 0, padding: 0}}>
													{item.nombre_completo}
													</Typography>}
												secondary={
													<Fragment>
														<Typography
															component="span"
															variant="body2"
															color="textPrimary"
															style={{fontSize: '11px'}}
														>
															Mensaje:
														</Typography>
														{" — " + item.sms}
													</Fragment>
												}
											/>
										</ListItem>
									</Fragment>
								))}
							</div>
						</Grid>
					
					</Grid>
				
				</article>
			
			</Fragment>
		)
	}
}


ChatFichaTecnica.propTypes = {
	disabled: PropTypes.bool,
	state: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	modulo: PropTypes.number
};


export default ChatFichaTecnica;


