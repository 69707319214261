import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {FichaTecnicaService} from "../../../../services/FichaTecnicaService/FichaTecnicaService";
import {FieldsJs, hideSpinner} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import TextField from "@material-ui/core/TextField";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {Icono} from "../../../Include/Icono/Icono";
import ModalImage from "../../../Include/ModalImage/ModalImage";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {ClientePotencialService} from "../../../../services/ClientePotencialService/ClientePotencialService";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import Fab from "@material-ui/core/Fab";
import {SyncOutlined} from "@material-ui/icons";
import {CONFIG} from "../../../../settings/Config/Config";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";

class SubirFormatoAltaBajaDeEmpleados extends Component {
	
	state = {};
	
	Usr = {};
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			id_cliente: props.id_cliente,
			
			cliente: {},
			
			id_cat_ficha_tecnica_status: '',
			
			formato_alta_baja_empleado_base64: '',
			formato_alta_baja_empleado_base64Tipo: '',
			formato_alta_baja_empleado_archivo: '',
			formato_alta_baja_empleado_formato: '',
			archivo: '',
			
			observaciones: '',
			
			formato_alta_baja_empleado: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
		this.props.RefreshList();
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			FichaTecnicaService.InitDato(this.state.id_cliente).then((response) => {
				
				let dato = FieldsJs.Copy(response.data);
				
				if (response.visible === 0) {
					
					this.setState({
						visible: 0,
						mensaje: response.message
					});
					
				} else {
					
					let formato_alta_baja_empleado = dato.formato_alta_baja_empleado || [];
					
					this.setState({
						
						visible: 1,
						
						cliente: dato.cliente,
						
						id_cat_ficha_tecnica_status: (dato.ficha_tecnica || {}).id_cat_ficha_tecnica_status || '',
						
						formato_alta_baja_empleado: formato_alta_baja_empleado,
						
					});
					
				}
				resolve(true);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
				reject(false)
			});
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	getBase64 = (e, dropzone) => {
		return new Promise((resolve, reject) => {
			let files;
			if (dropzone) {
				files = e;
			} else {
				console.log(e.target);
				files = e.target.files;
			}
			
			let namearchivo = (this.state.formato_alta_baja_empleado.length === 0 ? "Formato_Alta_Baja_Empleado":"Formato_Alta_Baja_Empleado_"+(Number(this.state.formato_alta_baja_empleado.length)+1))+'.'+this.state.formato_alta_baja_empleado_formato;
			
			let ruta = '/' + this.state.cliente.rfc + '-' + FieldsJs.getClienteTipoPersona(this.state.cliente) + '/expediente/' + namearchivo;
			
			console.log(ruta, files);
			
			DropboxApi.Upload(ruta, files).then(function (response) {
				
				resolve(response.path_display);
				
			}).catch(function (error) {
				reject(error);
			});
		});
	};
	
	save = () => {
		try {
			
			console.log("SAVE FORMATO ALTA ::: ", this.state);
			
			this.getBase64(this.state.file[0], true).then(response => {
				let archivos = FieldsJs.Copy(this.state.archivo);
				
				this.setState({
					archivo: response
				});
				
				console.log("SAVE 222 FORMATO ALTA ::: ", this.state);
				
				hideSpinner('spinner', 600);
				
				ClientePotencialService.SubirFormatoAltaBajaDeEmpleados(this.state).then((response) => {
					
					this.RefreshInit();
					
					//this.close();
					
					this.showSnackBars('success', response.mensaje);
					
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
				
			});
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	desactivar_nomina_empleados = (item, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Estas seguro de desactivar el archivo formato de alta y baja de empleado del cliente?').then((r) => {
				if (r.button === 'Aceptar') {
					ClientePotencialService.DesactivarFormatoAltaBajaDeEmpleados(item, key).then((response) => {
						
						this.RefreshInit();
						
						this.showSnackBars('success', response.mensaje);
						
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				}
			});
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	disabledFieldPromotorSubirFormatoAltaBajaEmpleadoBolean = () => {
		
		let disabledFieldPromotorSubirFormatoAltaBajaEmpleado = true;
		
		if (FieldsJs.inArray([1, 2, 3], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldPromotorSubirFormatoAltaBajaEmpleado = false;
		}
		
		return disabledFieldPromotorSubirFormatoAltaBajaEmpleado;
	};
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	render() {
		
		const disabledFieldPromotorSubirFormatoAltaBajaEmpleado = this.disabledFieldPromotorSubirFormatoAltaBajaEmpleadoBolean();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Typography className={'px-20'}>
									<Fab size="small" color="secondary" aria-label="Actualizar" onClick={() => this.RefreshInit()} style={{marginRight: '15px'}}>
										<SyncOutlined/>
									</Fab>
									{this.props.titulo}
								</Typography>
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
								
								<Card>
									<CardContent style={{background: 'lightgray', padding: '15px'}}>
										
										<Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
											
											{this.state.formato_alta_baja_empleado.map((item, key) => (
												<Fragment key={key}>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														
														<Card style={{height: '100%'}}>
															<CardContent style={{padding: '10px'}}>
																
																<div align={'right'}
																     style={{
																	     width: '100%',
																	     height: 0
																     }}
																>
																	{!disabledFieldPromotorSubirFormatoAltaBajaEmpleado ? (
																		<Fragment>
																			<div
																				className={'cursor-pointer'}
																				style={{
																					textAlign: 'center',
																					marginLeft: 5,
																					float: 'right',
																					padding: '6px',
																					background: 'red',
																					height: '6px',
																					width: '6px',
																					lineHeight: '6px',
																					color: 'white',
																					borderRadius: '100%',
																					fontSize: '12px',
																				}}
																				onClick={() => this.desactivar_nomina_empleados(item, key)}
																				children={'x'}
																			/>
																		</Fragment>
																	) : null}
																</div>
																
																<Typography
																	className={'text-right text-black margin-0 padding-0 px-14 h-0-px'}>
																	{item.created_at ? DateFormat.FechaHoraFormatText(item.created_at) : ''}
																</Typography>
																
																{item.formato_alta_baja_empleado_archivo ? (
																	<div className="vertical-inline">
																		<div className={'v-center'}>
																			<Typography
																				className={'text-left text-black margin-0 padding-0 px-14'}>
																				Formato alta/baja de empleado:
																			</Typography>
																		</div>
																		<div
																			className={'padding-5-L padding-5-R v-center'}>
																			{Icono.get(item.formato_alta_baja_empleado_formato, '20px', '20px')}
																		</div>
																		<div className={'v-center padding-10-R'}>
																			<Typography className={'px-13 margin-0 cursor-pointer'} style={{color: "blue"}} onClick={() => this.exportar(item.formato_alta_baja_empleado)}>
																				Descargar
																			</Typography>
																			{/*<Fragment>
																				<ModalImage tipo={'view'} desing={3} item={{
																					base64: item.formato_alta_baja_empleado_base64 || '',
																					base64Tipo: item.formato_alta_baja_empleado_base64Tipo || '',
																					archivo: item.formato_alta_baja_empleado_archivo || '',
																					formato: item.formato_alta_baja_empleado_formato || '',
																				}}/>
																			</Fragment>*/}
																		</div>
																	</div>
																) : ''}
																
																<Typography className={'text-left text-black margin-0 padding-0'}>
																	Observaciones: {item.observaciones || 'Sin observaciones'}
																</Typography>
															
															</CardContent>
														</Card>
													
													</Grid>
												</Fragment>
											))}
											
											{this.state.formato_alta_baja_empleado.length <= 0 ? (
												<Fragment>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<VistaVacia
															paddingText={'10px'}
															padding={'10px'}
															numero={0}
															mensaje={'No se ha registrado formato de alta y baja de empleado.'}
															height={'70px'}
															width={'70px'}
														/>
													</Grid>
												</Fragment>
											) : ''}
										
										</Grid>
										
									</CardContent>
								</Card>
							
							</Grid>
							
							{!disabledFieldPromotorSubirFormatoAltaBajaEmpleado ? (
								<Fragment>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										
										<BoxSelectFile
											style={{height: '120px'}}
											label={(
												<div>
													Formato de alta y baja de empleado <b className={'vertical-inline v-center text-blue'}>(Requerido)</b>
												</div>
											)}
											item={{
												base64: this.state.formato_alta_baja_empleado_base64,
												base64Tipo: this.state.formato_alta_baja_empleado_base64Tipo,
												archivo: this.state.formato_alta_baja_empleado_archivo,
												formato: this.state.formato_alta_baja_empleado_formato,
											}}
											onChange={(r) => {
												this.handleChange({
													target: {
														name: 'formato_alta_baja_empleado_base64',
														value: r.base64
													}
												});
												this.handleChange({
													target: {
														name: 'file',
														value: r.file
													}
												});
												this.handleChange({
													target: {
														name: 'formato_alta_baja_empleado_base64Tipo',
														value: r.base64Tipo
													}
												});
												this.handleChange({
													target: {
														name: 'formato_alta_baja_empleado_archivo',
														value: r.archivo
													}
												});
												this.handleChange({
													target: {
														name: 'formato_alta_baja_empleado_formato',
														value: r.formato
													}
												});
											}}
											showSnackBars={this.showSnackBars}
											disabled={disabledFieldPromotorSubirFormatoAltaBajaEmpleado}
										/>
									
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										
										<TextField
											multiline
											fullWidth
											onChange={this.handleChange}
											type="text"
											margin="dense"
											//required
											helperText=""
											name="observaciones"
											label="Observaciones"
											value={this.state.observaciones}
											disabled={disabledFieldPromotorSubirFormatoAltaBajaEmpleado}
										/>
									
									</Grid>
								
								</Fragment>
							) : null}
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={1}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!disabledFieldPromotorSubirFormatoAltaBajaEmpleado ? (
									<Button
										variant="contained"
										color="primary"
										onClick={() => this.save()}
										className={'btn-default-primary text-transform-none'}
										disabled={disabledFieldPromotorSubirFormatoAltaBajaEmpleado}
									>
										Subir formato de alta y baja de empleado
									</Button>
								) : ''}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

SubirFormatoAltaBajaDeEmpleados.propTypes = {
	titulo: PropTypes.string.isRequired,
	id_cliente: PropTypes.number.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default SubirFormatoAltaBajaDeEmpleados;
