import React, {Component} from 'react';
import ListaTabla from './Includes/ListaTabla';
import ModalNube from './Includes/ModalNube';

import {NubeService} from '../../../services/NubeService/NubeService';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../settings/Config/Config";
import {Dropbox} from 'dropbox';
import {Sync} from "@material-ui/icons";
import moment from "moment";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {DropboxApi} from "../../../settings/Dropbox/DropboxApi";
import ModalHistorial from "./Includes/ModalHistorial";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import ModalAcuses from "./Includes/ModalAcuses";
import ModalFacturacion from "./Includes/ModalFacturacion";
import ModalComprobantesNomina from "./Includes/ModalComprobantesNomina";


class Nube extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			
			archivos: [],
			
			routes: [],
			archivos_descarga: [],
			
			open_historial: false,
			lista_historial: [],
			
			lista_acuses: [],
			open_acuses: false,
			
			lista_facturacion: [],
			open_facturacion: false,
			
			lista_comprobantes_nomina: [],
			open_comprobantes_nomina: false
		};
		
		this.Listar();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	cancelModalHistorial = () => {
		this.setState({
			open_historial: false
		});
	};
	
	cancelModalComprobantesNomina = () => {
		this.setState({
			open_comprobantes_nomina: false
		});
	};
	
	cancelModalFacturacion = () => {
		this.setState({
			open_facturacion: false
		});
	};
	
	cancelModalAcuses = () => {
		this.setState({
			open_acuses: false
		});
	};
	
	Listar = (ruta, name) => {
		if (ruta === 'acuses') {
			
			NubeService.ListarAcuses().then((response) => {
				let data = response.data;
				if (data.length === 0) {
					
					this.showSnackBars('error', response.mensaje);
					
				} else {
					this.setState({
						lista_acuses: response.data,
						open_acuses: true
					});
				}
			}).catch((error) => {
				this.setState({
					lista_historial: [],
				});
				this.showSnackBars('error', error.mensaje);
			});
			
		} else if (ruta === 'historial') {
			
			NubeService.ListarHistorial().then((response) => {
				let data = response.data;
				if (data.length === 0) {
					
					this.showSnackBars('error', response.mensaje);
					
				} else {
					this.setState({
						lista_historial: response.data,
						open_historial: true
					});
				}
			}).catch((error) => {
				this.setState({
					lista_historial: [],
				});
				this.showSnackBars('error', error.mensaje);
			});
			
		} else if (ruta === 'facturacion') {
			
			NubeService.ListarFacturacion().then((response) => {
				let data = response.data;
				if (data.length === 0) {
					
					this.showSnackBars('error', response.mensaje);
					
				} else {
					this.setState({
						lista_facturacion: response.data,
						open_facturacion: true
					});
				}
			}).catch((error) => {
				this.setState({
					lista_facturacion: [],
				});
				this.showSnackBars('error', error.mensaje);
			});
			
		} else if (ruta === 'comprobantes_nomina') {
			
			NubeService.ListarComprobantesNomina().then((response) => {
				let data = response.data;
				if (data.length === 0) {
					
					this.showSnackBars('error', response.mensaje);
					
				} else {
					this.setState({
						lista_comprobantes_nomina: response.data,
						open_comprobantes_nomina: true
					});
				}
			}).catch((error) => {
				this.setState({
					lista_comprobantes_nomina: [],
				});
				this.showSnackBars('error', error.mensaje);
			});
			
		} else {
			if (ruta) {
				let rutas = FieldsJs.Copy(this.state.routes);
				
				console.log(name, ruta, rutas);
				let band = false;
				let index = "";
				if (rutas.length > 0) {
					rutas.map((item, key) => {
						if (name === item.name) {
							band = true;
							index = key;
						}
					});
					if (band) {
						rutas.splice(index + 1);
					} else {
						rutas.push({path_display: ruta, name: name});
					}
				} else {
					rutas.push({path_display: ruta, name: name});
				}
				
				this.setState({
					routes: rutas
				})
			} else {
				this.setState({
					routes: []
				})
			}
			NubeService.Listar(ruta).then((response) => {
				this.setState({
					archivos: response.data.entries
				})
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		}
		
	};
	
	nuevaFecha = (d) => {
		let fecha = '';
		if (d) {
			var f = d.split('T');
			fecha = moment(f[0]).format("DD/MM/YYYY");
		}
		return fecha;
	};
	
	/*descargarZip = (ruta) => {
		
		console.log(ruta);
		NubeService.DescargarZip(ruta).then((response) => {
			console.log(response);
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
		
	};*/
	
	descargarZip = (path) => {
		//path = CONFIG.src + path;
		// let path = this.state.ficha_tecnica_expediente_trabajador[1].archivo[0].archivo;
		console.log("PATH ::: ", path);
		DropboxApi.GenerateZip(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	descargarArchivo = (arc) => {
		console.log("DROPBOX DONWLOAD: ", arc);
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({
			accessToken,
			fetch
		});
		dbx.sharingGetSharedLinkFile({path: arc.id})
			.then(function (data) {
				var downloadUrl = URL.createObjectURL(data.fileBlob);
				window.open(downloadUrl);
			})
	};
	
	exportarDescargarCFDI = (archivo, auth) => {
		try {
			let params = {
				auth: auth,
				archivo: archivo,
			};
			HttpRequest.export('_Carga_CFDI_Donwload_Zip', ['_Carga_CFDI_Donwload_Zip'], params, false, false, false, "ZIP");
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	exportarDescargarComprobantesNomina = (archivo, auth) => {
		try {
			let params = {
				auth: auth,
				archivo: archivo,
			};
			HttpRequest.export('_Comprobantes_Nomina_Donwload_Zip', ['_Comprobantes_Nomina_Donwload_Zip'], params, false, false, false, "ZIP");
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	exportarDescargarFacturacion = (archivo, auth) => {
		try {
			let params = {
				auth: auth,
				archivo: archivo
			};
			HttpRequest.exportFacturacion('_Carga_CFDI_Donwload_Zip_Fac', ['_Carga_CFDI_Donwload_Zip_Fac'], params, false, false, false, "ZIP");
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	exportarDescargarLineaCaptura = (archivo, auth) => {
		try {
			let params = {
				auth: auth,
				archivo: archivo,
			};
			HttpRequest.export('_Linea_Captura_Donwload_Zip', ['_Linea_Captura_Donwload_Zip'], params, false, false, false, "ZIP");
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				<Grid container direction="row" justify="space-between" alignItems="center" spacing={2}
				      style={{marginTop: 20, marginBottom: 20}}>
					<Grid item>
						<div className={'vertical-inline w-100'}>
							<Typography variant={'h5'} className={'v-center text-left cursor-pointer'} onClick={() => this.Listar()}>
								Nube Integraciones
							</Typography>
							{this.state.routes.map((item, key) => (
								<p key={key} className={'v-center margin-0 cursor-pointer'} style={{color: 'rgb(142, 30, 50)'}} onClick={() => this.Listar(item.path_display, item.name)}>/{item.name}</p>
							))}
						</div>
					</Grid>
					<Grid item>
						<Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
							{/*<Grid item>
								<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#8e1e32"}} onClick={() => this.Listar()}>
									<CloudUpload style={{fontSize: 14, marginRight: 5}}/> Subir Archivo
								</Fab>
							</Grid>*/}
							<Grid item>
								<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#3f50b6"}} onClick={() => this.Listar()}>
									<Sync style={{fontSize: 14, marginRight: 5}}/> Actualizar
								</Fab>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				
				
				{/*<CabeceraTituloPdfExcelLista
					titulo={'Nube Integraciones'}
					
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() => {
								this.Listar();
							}}/>
						</Fragment>
					}
					marginTop=''
					marginBottom=''
					botonDropbox={
						<Fragment>
							<BotonAutorizarDropbox onClick={() => {
								this.Listar();
							}}/>
						</Fragment>
					}
				/>*/}
				
				{this.state.open_historial ? (
					<ModalHistorial
						open={this.state.open_historial}
						item={this.state}
						cancelModalHistorial={this.cancelModalHistorial}
						descargarZipHistorial={this.exportarDescargarCFDI}
						listaHistorial={this.state.lista_historial}
					/>
				) : null
				}
				
				{this.state.open_comprobantes_nomina ? (
					<ModalComprobantesNomina
						open={this.state.open_comprobantes_nomina}
						item={this.state}
						cancelModalComprobantesNomina={this.cancelModalComprobantesNomina}
						descargarZipComprobantesNomina={this.exportarDescargarComprobantesNomina}
						listaComprobantesNomina={this.state.lista_comprobantes_nomina}
					/>
				) : null
				}
				
				{this.state.open_facturacion ? (
					<ModalFacturacion
						open={this.state.open_facturacion}
						item={this.state}
						cancelModalHistorial={this.cancelModalFacturacion}
						descargarZipHistorial={this.exportarDescargarFacturacion}
						listaHistorial={this.state.lista_facturacion}
					/>
				) : null
				}
				
				{this.state.open_acuses ? (
					<ModalAcuses
						open={this.state.open_acuses}
						item={this.state}
						cancelModalAcuses={this.cancelModalAcuses}
						descargarZipAcuses={this.exportarDescargarLineaCaptura}
						listaAcuses={this.state.lista_acuses}
					/>
				) : null
				}
				
				<div className={'form margin-30-B'}>
					
					{this.state.archivos.length > 0 ? (
						<ListaTabla
							lista={this.state.archivos}
							gerContentFile={this.Listar}
							descargarZip={this.descargarZip}
							RefreshList={this.RefreshList}
							showSnackBars={this.showSnackBars}
						/>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				<ModalNube
					tipo={'add'}
					item={{}}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>
			
			</div>
		);
	}
}

Nube.propTypes = {};

export default IntegrationNotistack(Nube);
