import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {RepositorioJuridicoService} from '../../../../services/RepositorioJuridicoService/RepositorioJuridicoService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";
import ModalEmpresa from "../../Cat/Empresa/Includes/ModalEmpresa";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import ModalRecordatorios from "../../Reccordatorios/Includes/ModalRecordatorios";
import {Business, DeleteOutlined, Edit, Group, PlaylistAddOutlined, SpeakerNotes, Sync} from "@material-ui/icons";
import {DropzoneArea} from "material-ui-dropzone";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ModalProyecto from "../../Cat/Proyecto/Includes/ModalProyecto";
import ModalModulo from "../../Cat/Modulo/Includes/ModalModulo";
import {ProyectoService} from "../../../../services/_Cat/ProyectoService/ProyectoService";
import {ModuloService} from "../../../../services/_Cat/ModuloService/ModuloService";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {TicketsService} from "../../../../services/TicketsService/TicketsService";
import DocSvg from "../../../../assets/img/icons/doc.svg";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import FormGroup from "@material-ui/core/FormGroup";
import {CONFIG} from "../../../../settings/Config/Config";
import ModalEquipo from "../../Cat/Equipo/Includes/ModalEquipo";
import ModalTipoDocumentoRepositorioJuridico from "../../Cat/TipoDocumentoRepositorioJuridico/Includes/ModalTipoDocumentoRepositorioJuridico";
import ModalCentroTrabajoRepositorioJuridico from "../../Cat/CentroTrabajoRepositorioJuridico/Includes/ModalCentroTrabajoRepositorioJuridico";
import moment from "moment";
import ModalCliente from "../../Cliente/Includes/ModalCliente";
import {ClienteService} from "../../../../services/ClienteService/ClienteService";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";

class ModalRepositorioJuridico extends Component {
	
	Usr = {};
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			open: false,
			scroll: 'paper',
			tipo: props.tipo,
			id_repositorio_juridico: (props.item || '').id_repositorio_juridico,
			id_cat_tipo_documento_repositorio_juridico: '',
			id_cat_centro_trabajo_repositorio_juridico: '',
			id_empresa: '',
			direccion: '',
			responsable: '',
			representante_legal: '',
			administrador_unico: '',
			observaciones: '',
			razon_social: '',
			empresa_datos: {},
			fecha_vencimiento: null,
			direccion_rp: '',
			numero_registro_patronal: '',
			password: '',
			fisicamente_rl: '',
			activo: true,
			
			files: [],
			archivos: [],
			
			id_cliente: '',
			rfc: '',
			nombre: '',
			codigo_postal: '',
			id_cat_regimen_fiscal: '',
			
			listar_cliente: [],
			
			
			listar_regimen_fiscal: [],
			lista_empresa: [],
			lista_cat_tipo_documento_rj: [],
			lista_cat_centro_trabajo_rj: [],
		};
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	RefreshListCliente = (id_cliente) => {
		if (id_cliente > 0) {
			this.setState({
				id_cliente: id_cliente
			})
		}
		this.ListaCliente();
	};
	
	ListaCliente = () => {
		return new Promise((resolve, reject) => {
			ClienteService.Listar({}, {}).then((response) => {
				this.setState({
					listar_cliente: response.data,
				});
				resolve(true);
			}).catch((error) => {
				this.setState({
					listar_cliente: []
				});
				this.props.showSnackBars('error', error.mensaje);
				reject(false)
			});
		});
	};
	
	ListEmpresa = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpresa().then((response) => {
				this.setState({
					lista_empresa: response.data
				});
				resolve(response);
			});
		});
	};
	
	empresaRazonSocial = (id_empresa, tipo) => {
		if (tipo) {
			this.ListEmpresa().then((response) => {
				for (let i = 0; i < this.state.lista_empresa.length; i++) {
					if (Number(id_empresa) === Number(this.state.lista_empresa[i].id_empresa)) {
						this.setState({
							razon_social: this.state.lista_empresa[i].razon_social,
							empresa_datos: this.state.lista_empresa[i]
						});
					}
				}
			});
		} else {
			for (let i = 0; i < this.state.lista_empresa.length; i++) {
				if (Number(id_empresa) === Number(this.state.lista_empresa[i].id_empresa)) {
					this.setState({
						razon_social: this.state.lista_empresa[i].razon_social,
						empresa_datos: this.state.lista_empresa[i]
					});
				}
			}
		}
	}
	
	ListDocumentosRJ = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoDocumentoRJ(this.Usr.id_cat_tipo_usuario).then((response) => {
				this.setState({
					lista_cat_tipo_documento_rj: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_cat_tipo_documento_rj: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListCentroTrabajoRJ = () => {
		return new Promise((resolve, reject) => {
			CatService.ListCentroTrabajoRJ().then((response) => {
				this.setState({
					lista_cat_centro_trabajo_rj: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_cat_centro_trabajo_rj: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListRegimenFiscal = () => {
		return new Promise((resolve, reject) => {
			CatService.ListRegimenFiscal().then((response) => {
				this.setState({
					listar_regimen_fiscal: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({listar_regimen_fiscal: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	RefreshListEmpresa = (id_empresa) => {
		this.setState({
			id_empresa: id_empresa || ''
		});
		
		this.empresaRazonSocial(id_empresa);
	};
	
	RefreshListDocumentosRJ = (id_cat_tipo_documento_repositorio_juridico) => {
		this.ListDocumentosRJ().then((response) => {
			this.setState({
				id_cat_tipo_documento_repositorio_juridico: id_cat_tipo_documento_repositorio_juridico
			});
		});
	};
	
	RefreshListCentroTrabajoRJ = (id_cat_centro_trabajo_repositorio_juridico) => {
		this.ListCentroTrabajoRJ().then((response) => {
			let centros = FieldsJs.Copy(this.state.lista_cat_centro_trabajo_rj);
			let direccion = '';
			for (let i = 0; i < centros.length; i++) {
				if (Number(id_cat_centro_trabajo_repositorio_juridico) === Number(centros[i].id_cat_centro_trabajo_repositorio_juridico)) {
					direccion = centros[i].direccion;
				}
			}
			this.setState({
				id_cat_centro_trabajo_repositorio_juridico: id_cat_centro_trabajo_repositorio_juridico,
				direccion: direccion,
			});
		});
	};
	
	guardarDocTimbradoDbx = () => {
		
		let files = this.state.files;
		
		return new Promise((resolve, reject) => {
			let band = files.length;
			let band2 = 0;
			let archives = this.state.archivos;
			let file;
			
			for (let i = 0; i < files.length; i++) {
				console.log("2 FTAR::: ", files);
				file = files[i];
				
				let ruta = '/Repositorio_Juridico/' + this.state.id_empresa + '-' + this.state.razon_social + '/' + file.name;
				
				DropboxApi.Upload(ruta, file).then(function (response) {
					archives.push({archivo: response.path_display});
					
					band2++;
					
					console.log("DROPBOX UPLOAD: ", response, band, band2);
					
					if (band === band2) {
						resolve(archives);
					}
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	save = () => {
		
		try {
			
			if (!FieldsJs.Field(this.state.id_cat_tipo_documento_repositorio_juridico)) {
				throw Object({
					status: false, mensaje: 'Campo tipo documento es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.id_empresa)) {
				throw Object({
					status: false, mensaje: 'Campo empresa es requerido.'
				})
			}
			if (FieldsJs.inArray([1], this.state.id_cat_tipo_documento_repositorio_juridico) && !FieldsJs.Field(this.state.administrador_unico)) {
				throw Object({
					status: false, mensaje: 'Campo administrador único es requerido.'
				})
			}
			if (FieldsJs.inArray([1, 2], this.state.id_cat_tipo_documento_repositorio_juridico) && !FieldsJs.Field(this.state.representante_legal)) {
				throw Object({
					status: false, mensaje: 'Campo representante legal es requerido.'
				})
			}
			if (FieldsJs.inArray([10], this.state.id_cat_tipo_documento_repositorio_juridico) && !FieldsJs.Field(this.state.numero_registro_patronal)) {
				throw Object({
					status: false, mensaje: 'Campo No. de Registro Patronal es requerido.'
				})
			}
			if (FieldsJs.inArray([10], this.state.id_cat_tipo_documento_repositorio_juridico) && !FieldsJs.Field(this.state.representante_legal)) {
				throw Object({
					status: false, mensaje: 'Campo representante legal es requerido.'
				})
			}
			if (FieldsJs.inArray([10], this.state.id_cat_tipo_documento_repositorio_juridico) && !FieldsJs.Field(this.state.fecha_vencimiento)) {
				throw Object({
					status: false, mensaje: 'Campo fecha vencimiento es requerido.'
				})
			}
			if (FieldsJs.inArray([2], this.state.id_cat_tipo_documento_repositorio_juridico) && !FieldsJs.Field(this.state.fecha_vencimiento)) {
				throw Object({
					status: false, mensaje: 'Campo fecha del instrumento norarial es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.id_cat_centro_trabajo_repositorio_juridico)) {
				throw Object({
					status: false, mensaje: 'Campo centro de trabajo es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.responsable)) {
				throw Object({
					status: false, mensaje: 'Campo responsable es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.direccion)) {
				throw Object({
					status: false, mensaje: 'Campo dirección es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.files) && this.state.files.length > 0 && this.state.tipo === 'add') {
				throw Object({
					status: false, mensaje: 'Por lo menos un documento es requerido.'
				})
			}
			
			if (this.state.files.length > 0 && (this.state.tipo === 'add' || this.state.tipo === 'edit')) {
				
				let guardar = new Promise((resolve, reject) => {
					showSpinner('spinner');
					this.guardarDocTimbradoDbx(this.state.files).then(response => {
						this.setState({
							archivos: response,
						});
						resolve(response);
						hideSpinner('spinner', 500);
						console.log("DROPBOX UPLOAD: ", this.state);
					}).catch(error => {
						hideSpinner('spinner', 500);
						reject(error);
					})
				});
				
				guardar.then(response => {
					if (this.state.id_repositorio_juridico > 0) {
						RepositorioJuridicoService.Modificar(this.state).then((response) => {
							this.props.RefreshList();
							this.modalClose();
							this.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.showSnackBars('error', error.mensaje);
						});
					} else {
						RepositorioJuridicoService.Agregar(this.state).then((response) => {
							this.props.RefreshList();
							this.modalClose();
							this.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.showSnackBars('error', error.mensaje);
						});
					}
				});
				
			} else if (this.state.tipo === 'edit') {
				RepositorioJuridicoService.Modificar(this.state).then((response) => {
					this.props.RefreshList();
					this.modalClose();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			} else {
				this.showSnackBars('error', 'Por lo menos un documento es requerido.');
			}
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		
		this.setState({
			id_repositorio_juridico: '',
			id_cat_tipo_documento_repositorio_juridico: '',
			id_cat_centro_trabajo_repositorio_juridico: '',
			id_empresa: '',
			direccion: '',
			responsable: '',
			representante_legal: '',
			administrador_unico: '',
			observaciones: '',
			razon_social: '',
			empresa_datos: {},
			fecha_vencimiento: null,
			direccion_rp: '',
			numero_registro_patronal: '',
			password: '',
			id_cliente: '',
			rfc: '',
			nombre: '',
			codigo_postal: '',
			id_cat_regimen_fiscal: '',
			fisicamente_rl: 'No',
			
			files: [],
			archivos: [],
			activo: true,
		});
		
		
		this.ListEmpresa();
		this.ListDocumentosRJ();
		this.ListCentroTrabajoRJ();
		this.ListaCliente();
		this.ListRegimenFiscal();
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		this.ListEmpresa().then(() => {
			this.setState({
				id_empresa: item.id_empresa || ''
			});
		});
		
		this.ListaCliente().then(() => {
			this.setState({
				id_empresa: item.id_empresa || ''
			});
		});
		
		this.ListRegimenFiscal().then(() => {
			this.setState({
				id_cat_regimen_fiscal: item.id_cat_regimen_fiscal || '',
			});
		});
		
		this.ListDocumentosRJ().then(() => {
			this.setState({
				id_cat_tipo_documento_repositorio_juridico: item.id_cat_tipo_documento_repositorio_juridico || '',
			});
		});
		
		this.ListCentroTrabajoRJ().then(() => {
			this.setState({
				id_cat_centro_trabajo_repositorio_juridico: item.id_cat_centro_trabajo_repositorio_juridico || '',
			});
		});
		
		this.setState({
			id_repositorio_juridico: item.id_repositorio_juridico || '',
			direccion: item.direccion || '',
			responsable: item.responsable || '',
			representante_legal: item.representante_legal || '',
			administrador_unico: item.administrador_unico || '',
			observaciones: item.observaciones || '',
			razon_social: item.razon_social || '',
			fecha_vencimiento: item.fecha_vencimiento ? moment(item.fecha_vencimiento) : null,
			direccion_rp: item.direccion_rp || '',
			numero_registro_patronal: item.numero_registro_patronal || '',
			password: item.password || '',
			//files: [],
			archivos: item.archivos || [],
			fisicamente_rl: item.fisicamente_rl ? item.fisicamente_rl === 'Si' ? 'Si': 'No' : 'No',
			
			rfc: item.rfc || '',
			nombre: item.nombre || '',
			codigo_postal: item.codigo_postal || '',
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.ListaCliente().then(() => {
			this.setState({
				id_cliente: item.id_cliente || '',
			});
		});
		
		this.ListRegimenFiscal().then(() => {
			this.setState({
				id_cat_regimen_fiscal: item.id_cat_regimen_fiscal || '',
			});
		});
		
		this.ListEmpresa().then(() => {
			this.setState({
				id_empresa: item.id_empresa || '',
			});
		});
		
		this.ListDocumentosRJ().then(() => {
			this.setState({
				id_cat_tipo_documento_repositorio_juridico: item.id_cat_tipo_documento_repositorio_juridico || '',
			});
		});
		
		this.ListCentroTrabajoRJ().then(() => {
			this.setState({
				id_cat_centro_trabajo_repositorio_juridico: item.id_cat_centro_trabajo_repositorio_juridico || '',
			});
		});
		
		this.setState({
			id_repositorio_juridico: item.id_repositorio_juridico || '',
			direccion: item.direccion || '',
			responsable: item.responsable || '',
			representante_legal: item.representante_legal || '',
			administrador_unico: item.administrador_unico || '',
			observaciones: item.observaciones || '',
			razon_social: item.razon_social || '',
			fecha_vencimiento: item.fecha_vencimiento ? moment(item.fecha_vencimiento) : null,
			direccion_rp: item.direccion_rp || '',
			numero_registro_patronal: item.numero_registro_patronal || '',
			password: item.password || '',
			//files: [],
			fisicamente_rl: item.fisicamente_rl ? item.fisicamente_rl === 'Si' ? 'Si': 'No' : 'No',
			
			archivos: item.archivos || [],
			rfc: item.rfc || '',
			nombre: item.nombre || '',
			codigo_postal: item.codigo_postal || '',
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_repositorio_juridico > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	descargarZip = (path) => {
		console.log("PATH ::: ", path);
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Repositorio</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<div className={'row-flex'}>
									<div className={'w-50-px v-end'}>
										<ModalEmpresa
											componente={(
												<Tooltip
													TransitionComponent={Zoom}
													placement={"right"}
													title="Agregar empresa"
													children={(
														<Fab size="small" color="primary" aria-label="Agregar nueva empresa">
															<Add/>
														</Fab>
													)}
												/>
											)}
											id_plaza_direccion={null}
											tipo={'add'}
											item={{}}
											RefreshList={this.RefreshListEmpresa}
											showSnackBars={this.props.showSnackBars}
										/>
									</div>
									<div className={'w-100-100-px padding-10-R'}>
										<FormControl fullWidth required>
											<InputLabel>Empresa</InputLabel>
											<Select
												native
												value={this.state.id_empresa}
												onChange={(e) => {
													this.handleChange(e);
													this.empresaRazonSocial(e.target.value);
												}}
												name="id_empresa"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_empresa.map(option => (
													<option key={option.id_empresa}
													        value={option.id_empresa}>
														{option.razon_social ? option.razon_social : option.nombre_comercial}
													</option>
												))}
											</Select>
										</FormControl>
									</div>
									<div className={'w-50-px v-end'}>
										{!FieldsJs.Field(this.state.id_empresa) && this.props.tipo !== 'view' ? (<Tooltip
											TransitionComponent={Zoom}
											placement={"top"}
											title="Editar empresa"
											children={(
												<Fab size="small" color="primary" aria-label="Editar empresa" style={{color: 'rgba(0, 0, 0, 0.26)', backgroundColor: 'rgba(0, 0, 0, 0.12)'}}>
													<Edit/>
												</Fab>
											)}
										/>) : (
											<ModalEmpresa
												componente={(
													<Tooltip
														TransitionComponent={Zoom}
														placement={"top"}
														title="Agregar empresa"
														children={(
															<Fab size="small" color="primary" aria-label="Agregar nueva empresa">
																<Edit/>
															</Fab>
														)}
													/>
												)}
												id_plaza_direccion={null}
												tipo={'edit'}
												item={this.state.empresa_datos}
												RefreshList={this.empresaRazonSocial}
												showSnackBars={this.props.showSnackBars}
											/>
										)}
									</div>
								</div>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<div className={'row-flex'}>
									<div className={'w-50-px v-end'}>
										<ModalTipoDocumentoRepositorioJuridico
											tipo={'add'}
											item={{}}
											componente={
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Agregar Equipo">
													<Fab size="small" color="primary" aria-label="Agregar Equipo">
														<Add/>
													</Fab>
												</Tooltip>
											}
											RefreshList={this.RefreshListDocumentosRJ}
											showSnackBars={this.props.showSnackBars}
										/>
									</div>
									<div className={'w-100-50-px padding-10-R'}>
										<FormControl fullWidth required>
											<InputLabel>Tipo Documento</InputLabel>
											<Select
												native
												value={this.state.id_cat_tipo_documento_repositorio_juridico}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="id_cat_tipo_documento_repositorio_juridico"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_cat_tipo_documento_rj.map(option => (
													<option key={option.id_cat_tipo_documento_repositorio_juridico}
													        value={option.id_cat_tipo_documento_repositorio_juridico}>
														{option.tipo_documento}
													</option>
												))}
											</Select>
										</FormControl>
									</div>
								</div>
							</Grid>
							
							{FieldsJs.inArray([12], this.state.id_cat_tipo_documento_repositorio_juridico) ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
											
											<Grid item style={{width: '50px'}}>
												<ModalCliente
													componente={(
														<Tooltip
															TransitionComponent={Zoom}
															placement={"right"}
															title="Agregar nuevo cliente"
															children={(
																<Fab
																	color="default"
																	size="small"
																	aria-label="Agregar nuevo cliente"
																	children={(
																		<PlaylistAddOutlined/>
																	)}
																/>
															)}
														/>
													)}
													id_cliente={null}
													tipo={'add'}
													item={{}}
													RefreshList={this.RefreshListCliente}
													showSnackBars={this.props.showSnackBars}
												/>
											</Grid>
											
											<Grid item style={{width: 'calc(100% - 50px)'}}>
												<TextField
													select
													fullWidth
													required
													margin="none"
													onChange={this.handleChange}
													disabled={this.state.tipo === 'view'}
													SelectProps={{
														native: true,
														MenuProps: {
															className: '',
														},
													}}
													
													name="id_cliente"
													label="Cliente"
													value={this.state.id_cliente}
												>
													<option key={0} value={''}>&nbsp;</option>
													{this.state.listar_cliente.map(option => (
														<option key={option.id_cliente} value={option.id_cliente}>
															{option.id_cat_tipo_persona === 1 ? option.nombre_completo : option.nombre_comercial}
														</option>
													))}
												</TextField>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<TextField
											fullWidth
											required
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											className={'margin-0'}
											margin="dense"
											name="nombre"
											label="Nombre"
											value={this.state.nombre}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
										<TextField
											type={'text'}
											required
											fullWidth name="rfc"
											onChange={this.handleChange}
											inputProps={{maxLength: 13}}
											label="RFC" autoComplete={'off'}
											value={this.state.rfc}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
										<TextField
											type={'text'}
											required
											fullWidth name="codigo_postal"
											onChange={this.handleChange}
											inputProps={{maxLength: 5}}
											label="Código Postal" autoComplete={'off'}
											value={this.state.codigo_postal}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
										<TextField
											select
											fullWidth
											required
											margin="none"
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											
											name="id_cat_regimen_fiscal"
											label="Régimen Fiscal"
											value={this.state.id_cat_regimen_fiscal}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.listar_regimen_fiscal.map(option => (
												<option key={option.id_cat_regimen_fiscal} value={option.id_cat_regimen_fiscal}>
													{option.descripcion ? option.regimen_fiscal + ' - ' + option.descripcion : option.regimen_fiscal}
												</option>
											))}
										</TextField>
									</Grid>
								</Fragment>
							) : null}
							
							
							{FieldsJs.inArray([10], this.state.id_cat_tipo_documento_repositorio_juridico) ? (
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<TextField
										fullWidth
										required
										onChange={this.handleChange}
										disabled={this.state.tipo === 'view'}
										type="text"
										className={'margin-0'}
										margin="dense"
										name="numero_registro_patronal"
										label="No. de Registro Patronal"
										value={this.state.numero_registro_patronal}
									/>
								</Grid>
							) : null}
							
							{FieldsJs.inArray([1, 7], this.state.id_cat_tipo_documento_repositorio_juridico) ? (
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<TextField
										fullWidth
										required
										onChange={this.handleChange}
										disabled={this.state.tipo === 'view'}
										type="text"
										className={'margin-0'}
										margin="dense"
										name="administrador_unico"
										label="Administrador Único"
										value={this.state.administrador_unico}
									/>
								</Grid>
							) : null}
							
							
							{FieldsJs.inArray([1, 2, 7, 10], this.state.id_cat_tipo_documento_repositorio_juridico) ? (
								<Fragment>
									{FieldsJs.inArray([1, 7, 10], this.state.id_cat_tipo_documento_repositorio_juridico) ? (
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<TextField
												fullWidth
												required
												onChange={this.handleChange}
												disabled={this.state.tipo === 'view'}
												type="text"
												className={'margin-0'}
												margin="dense"
												name="representante_legal"
												label="Nombre del Apoderado"
												value={this.state.representante_legal}
											/>
										</Grid>
									) : (
										<Fragment>
											{FieldsJs.inArray([2], this.state.id_cat_tipo_documento_repositorio_juridico) ? (
												<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
													<TextField
														fullWidth
														required
														onChange={this.handleChange}
														disabled={this.state.tipo === 'view'}
														type="text"
														className={'margin-0'}
														margin="dense"
														name="representante_legal"
														label="Nombre del Apoderado"
														value={this.state.representante_legal}
													/>
												</Grid>
											) : (
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<TextField
														fullWidth
														required
														onChange={this.handleChange}
														disabled={this.state.tipo === 'view'}
														type="text"
														className={'margin-0'}
														margin="dense"
														name="representante_legal"
														label="Nombre del Apoderado"
														value={this.state.representante_legal}
													/>
												</Grid>
											)}
										</Fragment>
									
									)
									}
								</Fragment>
							) : null}
							
							{FieldsJs.inArray([2, 3, 4, 10], this.state.id_cat_tipo_documento_repositorio_juridico) ? (
								<Fragment>
									{FieldsJs.inArray([3, 4], this.state.id_cat_tipo_documento_repositorio_juridico) ? (
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<KeyboardDatePicker
												format="dd/MM/yyyy"
												fullWidth
												required
												clearable
												inputProps={{readOnly: true}}
												/*KeyboardButtonProps={{
													'aria-label': 'Fecha de pago',
												}}*/
												label={Number(this.state.id_cat_tipo_documento_repositorio_juridico) === 2 ? "Fecha del instrumento notarial" : "Fecha de vencimiento"}
												value={this.state.fecha_vencimiento}
												onChange={(date) => {
													this.handleChange(null, null, null, date, 'fecha_vencimiento');
												}}
												// minDate={moment(new Date())}
												clearLabel={'Limpiar'}
												okLabel={'Aceptar'}
												cancelLabel={'Cancelar'}
												onError={console.log}
												disabled={this.state.tipo === 'view'}
											/>
										</Grid>
									) : (
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<KeyboardDatePicker
												format="dd/MM/yyyy"
												fullWidth
												required
												clearable
												inputProps={{readOnly: true}}
												/*KeyboardButtonProps={{
													'aria-label': 'Fecha de pago',
												}}*/
												label={Number(this.state.id_cat_tipo_documento_repositorio_juridico) === 2 ? "Fecha del instrumento notarial" : "Fecha de vencimiento"}
												value={this.state.fecha_vencimiento}
												onChange={(date) => {
													this.handleChange(null, null, null, date, 'fecha_vencimiento');
												}}
												// minDate={moment(new Date())}
												clearLabel={'Limpiar'}
												okLabel={'Aceptar'}
												cancelLabel={'Cancelar'}
												onError={console.log}
												disabled={this.state.tipo === 'view'}
											/>
										</Grid>
									)}
								</Fragment>
							) : null}
							
							{FieldsJs.inArray([2], this.state.id_cat_tipo_documento_repositorio_juridico) ? (
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
									<FormControl component="fieldset">
										<FormLabel component="legend">Se cuenta físicamente con el RL?</FormLabel>
										<RadioGroup row aria-label="position" name="fisicamente_rl"
										            value={this.state.fisicamente_rl}
										            onChange={this.handleChange}
										>
											<FormControlLabel
												labelPlacement="end"
												control={<Radio color="primary"/>}
												value={'Si'}
												label="Si"
												disabled={this.state.tipo === 'view'}
											/>
											<FormControlLabel
												labelPlacement="end"
												control={<Radio color="primary"/>}
												value={'No'}
												label="No"
												disabled={this.state.tipo === 'view'}
											/>
										</RadioGroup>
									</FormControl>
								</Grid>
							) : null}
							
							{FieldsJs.inArray([3, 4], this.state.id_cat_tipo_documento_repositorio_juridico) ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<TextField
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											className={'margin-0'}
											name="password"
											label="Contraseña"
											value={this.state.password}
										/>
									</Grid>
								</Fragment>
							) : null}
							
							{FieldsJs.inArray([10], this.state.id_cat_tipo_documento_repositorio_juridico) ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
										<TextField
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											className={'margin-0'}
											name="direccion_rp"
											label="Dirección"
											value={this.state.direccion_rp}
										/>
									</Grid>
								</Fragment>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<fieldset style={{border: '1px solid lightgray'}}>
									<legend style={{fontWeight: 'bold', fontSize: '14px'}}>Resguardo Físico:</legend>
									<Grid container spacing={2}>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
											<div className={'row-flex'}>
												<div className={'w-50-px v-end'}>
													<ModalCentroTrabajoRepositorioJuridico
														tipo={'add'}
														item={{}}
														componente={
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Agregar Equipo">
																<Fab size="small" color="primary" aria-label="Agregar Equipo">
																	<Add/>
																</Fab>
															</Tooltip>
														}
														RefreshList={this.RefreshListCentroTrabajoRJ}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
												<div className={'w-100-50-px padding-10-R'}>
													<FormControl fullWidth required>
														<InputLabel>Centro de Trabajo:</InputLabel>
														<Select
															native
															value={this.state.id_cat_centro_trabajo_repositorio_juridico}
															onChange={(e) => {
																this.handleChange(e);
																this.RefreshListCentroTrabajoRJ(e.target.value);
															}}
															name="id_cat_centro_trabajo_repositorio_juridico"
															input={<Input/>}
															disabled={this.props.tipo === 'view'}
														>
															<option value=""/>
															{this.state.lista_cat_centro_trabajo_rj.map(option => (
																<option key={option.id_cat_centro_trabajo_repositorio_juridico}
																        value={option.id_cat_centro_trabajo_repositorio_juridico}>
																	{option.centro_trabajo}
																</option>
															))}
														</Select>
													</FormControl>
												</div>
											</div>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<TextField
												fullWidth
												required
												onChange={this.handleChange}
												disabled={this.state.tipo === 'view'}
												type="text"
												className={'margin-0'}
												name="responsable"
												label="Responsable"
												value={this.state.responsable}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField
												fullWidth
												required
												onChange={this.handleChange}
												disabled={this.state.tipo === 'view'}
												type="text"
												className={'margin-0'}
												name="direccion"
												label="Dirección"
												value={this.state.direccion}
											/>
										</Grid>
									</Grid>
								</fieldset>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									fullWidth
									multiline
									rows={2}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="observaciones"
									label="Observaciones"
									value={this.state.observaciones}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'padding-10-T padding-0-B px-14 text-left'} style={{fontWeight: 'bold'}}>
									Documentos:
								</Typography>
								<div className={'vertical-inline'}>
									{this.state.archivos.length > 0 ? (
										<Fragment>
											{this.state.archivos.map((item, key) => (
												<div key={key}>
													<div className={'vertical-inline b-r-20 margin-5 margin-0-L'}>
														{FieldsJs.Field(item.thumb) ? (
															<img className={'v-center cursor-pointer'} src={`data:image/jpeg;base64,${item.thumb.thumbnail}`} alt="Archivo" width={70} height={70} style={{opacity: 0.7, marginRight: '5px'}}
															     onClick={() => this.descargarZip(item.archivo)}/>
														) : (
															<img className={'v-center cursor-pointer'} src={DocSvg} alt="Archivo" width={70} height={70} style={{opacity: 0.7, marginRight: '5px'}} onClick={() => this.descargarZip(item.archivo)}/>
														)}
														
														{this.props.tipo === 'edit' ? (<div className={'vertical-inline v-end b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
															<div className={'padding-3 v-end text-white cursor-pointer'}>
																{this.props.tipo === 'view' ? null : (
																	<DeleteOutlined className={'px-16 text-white'} onClick={() => this.eliminar_documento(key)}/>
																)
																}
															</div>
														</div>) : null}
													</div>
												</div>
											))}
										</Fragment>
									) : null}
								</div>
								
								{this.props.tipo !== 'view' ? (
									<div className={'w-100 dropzone-cronograma'}>
										<div className={'w-100'}>
											<DropzoneArea
												onChange={this.handleChangeDropzoneArea.bind(this)}
												open={this.state.open}
												filesLimit={6}
												dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
												//acceptedFiles={['text/xml', 'application/pdf']}
												showPreviews={false}
												maxFileSize={10000000}
												showAlerts={false}
												showFileNames={true}
												showFileNamesInPreview={true}
											/>
										</div>
									</div>
								) : null}
							</Grid>
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_repositorio_juridico > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalRepositorioJuridico.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalRepositorioJuridico);
