import React, {Component, Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import {$cWarn, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {CheckOutlined} from '@material-ui/icons';

import DivSvg from "../../../Include/MiniComponents/DivSvg";

import ClientesPagadorasFacturadoras from "../Pasos/ClientesPagadorasFacturadoras";
import ContratosIndividualesElaborados from "../Pasos/ContratosIndividualesElaborados";
import AgendarFechaFirmaContrato from "../Pasos/AgendarFechaFirmaContrato";
import ClientesPagadorasConfirmar from "../Pasos/ClientesPagadorasConfirmar";
import DatosBancariosPromotor from "../Pasos/DatosBancariosPromotor";
import DatosBancariosClientesReceptorasPago from "../Pasos/DatosBancariosClientesReceptorasPago";
import SubirFormatoAltaBajaDeEmpleados from "../Pasos/SubirFormatoAltaBajaDeEmpleados";
import SubirPrenominaDeEmpleados from "../Pasos/SubirPrenominaDeEmpleados";
import RevisarNominaDeEmpleadosYSubirAcuse from "../Pasos/RevisarNominaDeEmpleadosYSubirAcuse";
import SubirContratoPrestacionServicio from "../Pasos/SubirContratoPrestacionServicio";

import bloquear from '../../../../assets/img/icons/bloquear.svg';
import subir_contrato_prestacion_servicio from '../../../../assets/img/icon-subtabla/subir_contrato_prestacion_servicio.svg';
import cliente_pagadora_facturadora from '../../../../assets/img/icon-subtabla/cliente_pagadora_facturadora.svg';
import elaborar_contratos_individuales from '../../../../assets/img/icon-subtabla/elaborar_contratos_individuales.svg';
import agendar_firmas_contratos_individuales from '../../../../assets/img/icon-subtabla/agendar_firmas_contratos_individuales.svg';
import confirmacion_cliente_pagadora from '../../../../assets/img/icon-subtabla/confirmacion_cliente_pagadora.svg';
import confirmar_dato_promotor from '../../../../assets/img/icon-subtabla/confirmar_dato_promotor.svg';
import subir_formato_alta_baja_empleado from '../../../../assets/img/icon-subtabla/subir_formato_alta_baja_empleado.svg';
import dato_bancario_cliente_pagadora from '../../../../assets/img/icon-subtabla/dato_bancario_cliente_pagadora.svg';
import revisar_nomina_alta_imss from '../../../../assets/img/icon-subtabla/revisar_nomina_alta_imss.svg';
import subir_confirmar_prenomina from '../../../../assets/img/icon-subtabla/subir_confirmar_prenomina.svg';
import "./../ClientePotencial.css";
import SubirContratoPrestacionServicioPromotor from "../Pasos/SubirContratoPrestacionServicioPromotor";



class SubTablaClientePotencial extends Component {
	state = {};
	Usr = {};
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			id_cat_ficha_tecnica_status: props.item.id_cat_ficha_tecnica_status
		};
	}
	
	componentDidMount() {
		$cWarn("componentDidMount");
	}
	
	componentWillUnmount() {
		$cWarn("componentWillUnmount");
	}
	
	pasos = () => {
		let circulo = [
			{
				id: 1,
				rol: 'Promotor',
				ids_cat_tipo_usuario: [1, 2, 3],
				image: subir_formato_alta_baja_empleado,
			},
			{
				id: 10,
				rol: 'Promotor',
				ids_cat_tipo_usuario: [1, 2, 3],
				image: subir_contrato_prestacion_servicio,
			},
			{
				id: 6,
				rol: 'Promotor',
				ids_cat_tipo_usuario: [1, 2, 3],
				image: agendar_firmas_contratos_individuales,
			},
			{
				id: 9,
				rol: 'Promotor',
				ids_cat_tipo_usuario: [1, 2, 3, 7],
				image: subir_confirmar_prenomina,
			},
			{
				id: 2,
				rol: 'Fiscal',
				ids_cat_tipo_usuario: [1, 2, 4],
				image: cliente_pagadora_facturadora,
			},
			{
				id: 0,
				rol: 'Jurídico',
				ids_cat_tipo_usuario: [1, 2, 5],
				image: subir_contrato_prestacion_servicio,
			},
			{
				id: 5,
				rol: 'Jurídico/Promotor',
				ids_cat_tipo_usuario: [1, 2, 5],
				image: elaborar_contratos_individuales,
			},
			{
				id: 4,
				rol: 'Recursos Humanos',
				ids_cat_tipo_usuario: [1, 2, 7],
				image: revisar_nomina_alta_imss,
			},
			{
				id: 9,
				rol: 'Recursos Humanos',
				ids_cat_tipo_usuario: [1, 2, 3, 7],
				image: subir_confirmar_prenomina,
			},
			{
				id: 3,
				rol: 'Tesorería',
				ids_cat_tipo_usuario: [1, 2, 6],
				image: confirmacion_cliente_pagadora,
			},
			{
				id: 7,
				rol: 'Tesorería',
				ids_cat_tipo_usuario: [1, 2, 6],
				image: confirmar_dato_promotor,
			},
			{
				id: 8,
				rol: 'Tesorería',
				ids_cat_tipo_usuario: [1, 2, 6],
				image: dato_bancario_cliente_pagadora,
			},
		];
		return circulo;
	};
	
	get_component = (id, item, titulo) => {
		let epp = item.cliente_pasos_proceso || {};
		let componente = null;
		switch (id) {
			case 0:
				componente = (
					<Fragment>
						{(FieldsJs.inArray([1, 2, 3, 4, 5, 6, 7], this.Usr.id_cat_tipo_usuario || 0) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status || 0)) ? (
							<SubirContratoPrestacionServicio
								titulo={titulo}
								id_cliente={item.id_cliente}
								icono={
									<Button className={'margin-5-B margin-5-T'} size="small" color="primary"
									        variant={"outlined"}>
										{epp.subir_contrato_prestacion_servicio === 1 ? (
											<CheckOutlined style={{color: 'green', fontSize: '14px'}}/>) : null} Abrir
									</Button>
								}
								RefreshList={this.props.RefreshList}
								showSnackBars={this.props.showSnackBars}
							/>
						) : ''}
					</Fragment>
				);
				break;
			case 1:
				componente = (
					<Fragment>
						{(FieldsJs.inArray([1, 2, 3, 4, 5, 6, 7], this.Usr.id_cat_tipo_usuario || 0) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status || 0)) ? (
							<SubirFormatoAltaBajaDeEmpleados
								titulo={titulo}
								id_cliente={item.id_cliente}
								icono={
									<Button className={'margin-5-B margin-5-T'} size="small" color="primary"
									        variant={"outlined"}>
										{epp.subir_formato_alta_baja_empleado === 1 ? (
											<CheckOutlined style={{color: 'green', fontSize: '14px'}}/>) : null} Abrir
									</Button>
								}
								RefreshList={this.props.RefreshList}
								showSnackBars={this.props.showSnackBars}
							/>
						) : ''}
					</Fragment>
				);
				break;
			case 2:
				componente = (
					<Fragment>
						{(FieldsJs.inArray([1, 2, 3, 4, 5, 6, 7], this.Usr.id_cat_tipo_usuario || 0) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status || 0)) ? (
							<ClientesPagadorasFacturadoras
								titulo={titulo}
								id_cliente={item.id_cliente}
								item={item}
								icono={
									<Button className={'margin-5-B margin-5-T'} size="small" color="primary"
									        variant={"outlined"}>
										{epp.cliente_pagadora_facturadora === 1 ? (
											<CheckOutlined style={{color: 'green', fontSize: '14px'}}/>) : null} Abrir
									</Button>
								}
								RefreshList={this.props.RefreshList}
								showSnackBars={this.props.showSnackBars}
							/>
						) : ''}
					</Fragment>
				);
				break;
			case 3:
				componente = (
					<Fragment>
						{(FieldsJs.inArray([1, 2, 3, 4, 5, 6, 7], this.Usr.id_cat_tipo_usuario || 0) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status || 0)) ? (
							<ClientesPagadorasConfirmar
								titulo={titulo}
								id_cliente={item.id_cliente}
								icono={
									<Button className={'margin-5-B margin-5-T'} size="small" color="primary"
									        variant={"outlined"}>
										{epp.confirmacion_cliente_pagadora === 1 ? (
											<CheckOutlined style={{color: 'green', fontSize: '14px'}}/>) : null} Abrir
									</Button>
								}
								RefreshList={this.props.RefreshList}
								showSnackBars={this.props.showSnackBars}
							/>
						) : ''}
					</Fragment>
				);
				break;
			case 4:
				componente = (
					<Fragment>
						{(FieldsJs.inArray([1, 2, 3, 4, 5, 6, 7], this.Usr.id_cat_tipo_usuario || 0) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status || 0)) ? (
							<RevisarNominaDeEmpleadosYSubirAcuse
								titulo={titulo}
								id_cliente={item.id_cliente}
								icono={
									<Button className={'margin-5-B margin-5-T'} size="small" color="primary"
									        variant={"outlined"}>
										{epp.revisar_nomina_alta_imss === 1 ? (
											<CheckOutlined style={{color: 'green', fontSize: '14px'}}/>) : null} Abrir
									</Button>
								}
								RefreshList={this.props.RefreshList}
								showSnackBars={this.props.showSnackBars}
							/>
						) : ''}
					</Fragment>
				);
				break;
			case 5:
				componente = (
					<Fragment>
						{(FieldsJs.inArray([1, 2, 3, 4, 5, 6, 7], this.Usr.id_cat_tipo_usuario || 0) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status || 0)) ? (
							<ContratosIndividualesElaborados
								titulo={titulo}
								id_cliente={item.id_cliente}
								icono={
									<Button className={'margin-5-B margin-5-T'} size="small" color="primary"
									        variant={"outlined"}>
										{epp.elaborar_contratos_individuales === 1 ? (
											<CheckOutlined style={{color: 'green', fontSize: '14px'}}/>) : null} Abrir
									</Button>
								}
								RefreshList={this.props.RefreshList}
								showSnackBars={this.props.showSnackBars}
							/>
						) : ''}
					</Fragment>
				);
				break;
			case 6:
				componente = (
					<Fragment>
						{(FieldsJs.inArray([1, 2, 3, 4, 5, 6, 7], this.Usr.id_cat_tipo_usuario || 0) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status || 0)) ? (
							<AgendarFechaFirmaContrato
								titulo={titulo}
								id_cliente={item.id_cliente}
								icono={
									<Button className={'margin-5-B margin-5-T'} size="small" color="primary"
									        variant={"outlined"}>
										{epp.agendar_firmas_contratos_individuales === 1 ? (
											<CheckOutlined style={{color: 'green', fontSize: '14px'}}/>) : null} Abrir
									</Button>
								}
								RefreshList={this.props.RefreshList}
								showSnackBars={this.props.showSnackBars}
							/>
						) : ''}
					</Fragment>
				);
				break;
			case 7:
				componente = (
					<Fragment>
						{(FieldsJs.inArray([1, 2, 3, 4, 5, 6, 7], this.Usr.id_cat_tipo_usuario || 0) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status || 0)) ? (
							<DatosBancariosPromotor
								titulo={titulo}
								id_cliente={item.id_cliente}
								icono={
									<Button className={'margin-5-B margin-5-T'} size="small" color="primary"
									        variant={"outlined"}>
										{epp.confirmar_dato_promotor === 1 ? (
											<CheckOutlined style={{color: 'green', fontSize: '14px'}}/>) : null} Abrir
									</Button>
								}
								RefreshList={this.props.RefreshList}
								showSnackBars={this.props.showSnackBars}
							/>
						) : ''}
					</Fragment>
				);
				break;
			case 8:
				componente = (
					<Fragment>
						{(FieldsJs.inArray([1, 2, 3, 4, 5, 6, 7], this.Usr.id_cat_tipo_usuario || 0) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status || 0)) ? (
							<DatosBancariosClientesReceptorasPago
								titulo={titulo}
								id_cliente={item.id_cliente}
								icono={
									<Button className={'margin-5-B margin-5-T'} size="small" color="primary"
									        variant={"outlined"}>
										{epp.dato_bancario_cliente_pagadora === 1 ? (
											<CheckOutlined style={{color: 'green', fontSize: '14px'}}/>) : null} Abrir
									</Button>
								}
								RefreshList={this.props.RefreshList}
								showSnackBars={this.props.showSnackBars}
							/>
						) : ''}
					</Fragment>
				);
				break;
			case 9:
				componente = (
					<Fragment>
						{(FieldsJs.inArray([1, 2, 3, 4, 5, 6, 7], this.Usr.id_cat_tipo_usuario || 0) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status || 0)) ? (
							<SubirPrenominaDeEmpleados
								titulo={titulo}
								id_cliente={item.id_cliente}
								icono={
									<Button className={'margin-5-B margin-5-T'} size="small" color="primary"
									        variant={"outlined"}>
										{epp.subir_confirmar_prenomina === 1 ? (
											<CheckOutlined style={{color: 'green', fontSize: '14px'}}/>) : null} Abrir
									</Button>
								}
								RefreshList={this.props.RefreshList}
								showSnackBars={this.props.showSnackBars}
							/>
						) : ''}
					</Fragment>
				);
				break;
			case 10:
				componente = (
					<Fragment>
						{(FieldsJs.inArray([1, 2, 3, 4, 5, 6, 7], this.Usr.id_cat_tipo_usuario || 0) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status || 0)) ? (
							<SubirContratoPrestacionServicioPromotor
								titulo={titulo}
								id_cliente={item.id_cliente}
								icono={
									<Button className={'margin-5-B margin-5-T'} size="small" color="primary"
									        variant={"outlined"}>
										{epp.subir_contrato_prestacion_servicio_promotor === 1 ? (
											<CheckOutlined style={{color: 'green', fontSize: '14px'}}/>) : null} Abrir
									</Button>
								}
								RefreshList={this.props.RefreshList}
								showSnackBars={this.props.showSnackBars}
							/>
						) : ''}
					</Fragment>
				);
				break;
			default:
				break;
		}
		return componente;
	};
	
	get_data = (value, item) => {
		let epp = item.cliente_pasos_proceso || {};
		let string = {};
		console.log(value.ids_cat_tipo_usuario, this.Usr.id_cat_tipo_usuario, FieldsJs.inArray(value.ids_cat_tipo_usuario, this.Usr.id_cat_tipo_usuario))
		switch (value.id) {
			case 0:
				if (FieldsJs.inArray(value.ids_cat_tipo_usuario, this.Usr.id_cat_tipo_usuario)) {
					if (epp.subir_contrato_prestacion_servicio === 1) {
						string = {
							color: 'rgb(194, 224, 195)',
							alt: 'Contrato de prestación de servicios',
							descripcion: 'Completado',
							titulo: 'Contrato de prestación de servicios',
							bloquear: false
						}
					} else {
						string = {
							color: 'rgba(63, 81, 181, 0.08)',
							alt: 'Contrato de prestación de servicios',
							descripcion: 'Subir contrato de prestación de servicios',
							titulo: 'Subir contrato de prestación de servicios',
							bloquear: false
						}
					}
				} else {
					string = {
						color: 'rgba(63, 81, 181, 0.08)',
						alt: 'Contrato de prestación de servicios',
						descripcion: 'Ver contrato de prestación de servicios',
						titulo: 'Contrato de prestación de servicios',
						bloquear: true
					}
				}
				break;
			case 1:
				if (FieldsJs.inArray(value.ids_cat_tipo_usuario, this.Usr.id_cat_tipo_usuario)) {
					if (epp.subir_formato_alta_baja_empleado === 1) {
						string = {
							color: 'rgb(194, 224, 195)',
							alt: 'Formato de altas y bajas de empleados',
							descripcion: 'Completado',
							titulo: 'Formato de altas y bajas de empleados',
							bloquear: false
						}
					} else {
						string = {
							color: 'rgba(63, 81, 181, 0.08)',
							alt: 'Formato de altas y bajas de empleados',
							descripcion: 'Subir formato de altas y bajas de empleados',
							titulo: 'Subir formato de altas y bajas de empleados',
							bloquear: false
						}
					}
				} else {
					string = {
						color: 'rgba(63, 81, 181, 0.08)',
						alt: 'Formato de altas y bajas de empleados',
						descripcion: 'Ver formato de altas y bajas de empleados',
						titulo: 'Formato de altas y bajas de empleados',
						bloquear: true
					}
				}
				break;
			case 2:
				if (FieldsJs.inArray(value.ids_cat_tipo_usuario, this.Usr.id_cat_tipo_usuario)) {
					if (epp.cliente_pagadora_facturadora === 1) {
						string = {
							color: 'rgb(194, 224, 195)',
							alt: 'Empresas pagadoras y facturadoras',
							descripcion: 'Completado',
							titulo: 'Empresas pagadoras y facturadoras',
							bloquear: false
						}
					} else {
						string = {
							color: 'rgba(63, 81, 181, 0.08)',
							alt: 'Empresas pagadoras y facturadoras',
							descripcion: 'Alta de empresas pagadoras y facturadoras',
							titulo: 'Alta de empresas pagadoras y facturadoras',
							bloquear: false
						}
					}
				} else {
					string = {
						color: 'rgba(63, 81, 181, 0.08)',
						alt: 'Empresas pagadoras y facturadoras',
						descripcion: 'Ver empresas pagadoras y facturadoras',
						titulo: 'Empresas pagadoras y facturadoras',
						bloquear: true
					}
				}
				break;
			case 3:
				if (FieldsJs.inArray(value.ids_cat_tipo_usuario, this.Usr.id_cat_tipo_usuario)) {
					if (epp.confirmacion_cliente_pagadora === 1) {
						string = {
							color: 'rgb(194, 224, 195)',
							alt: 'Empresas pagadoras confirmadas',
							descripcion: 'Completado',
							titulo: 'Empresas pagadoras confirmadas',
							bloquear: false
						}
					} else {
						string = {
							color: 'rgba(63, 81, 181, 0.08)',
							alt: 'Empresas pagadoras confirmadas',
							descripcion: 'Confirmar empresas pagadoras',
							titulo: 'Confirmar empresas pagadoras',
							bloquear: false
						}
					}
				} else {
					string = {
						color: 'rgba(63, 81, 181, 0.08)',
						alt: 'Empresas pagadoras confirmadas',
						descripcion: 'Ver empresas pagadoras confirmadas',
						titulo: 'Empresas pagadoras confirmadas',
						bloquear: true
					}
				}
				break;
			case 4:
				if (FieldsJs.inArray(value.ids_cat_tipo_usuario, this.Usr.id_cat_tipo_usuario)) {
					if (epp.revisar_nomina_alta_imss === 1) {
						string = {
							color: 'rgb(194, 224, 195)',
							alt: 'Formato de altas y bajas y subir acuses',
							descripcion: 'Completado',
							titulo: 'Formato de altas y bajas y subir acuses',
							bloquear: false
						}
					} else {
						string = {
							color: 'rgba(63, 81, 181, 0.08)',
							alt: 'Formato de altas y bajas y subir acuses',
							descripcion: 'Revisar formato de altas y bajas y subir acuses',
							titulo: 'Revisar formato de altas y bajas y subir acuses',
							bloquear: false
						}
					}
				} else {
					string = {
						color: 'rgba(63, 81, 181, 0.08)',
						alt: 'Formato de altas y bajas y subur acuses',
						descripcion: 'Ver formato de altas y bajas y subir acuses',
						titulo: 'Formato de altas y bajas y subir acuses',
						bloquear: true
					}
				}
				/*if (FieldsJs.inArray(value.ids_cat_tipo_usuario, this.Usr.id_cat_tipo_usuario)) {
					if (epp.revisar_nomina_alta_imss === 1) {
						string = {
							color: 'rgb(194, 224, 195)',
							alt: 'Nómina de empleados y acuse de alta (IMSS)',
							descripcion: 'Completado',
							titulo: 'Nómina de empleados y acuse de alta (IMSS)',
							bloquear: false
						}
					} else {
						string = {
							color: 'rgba(63, 81, 181, 0.08)',
							alt: 'Nómina de empleados y acuse de alta (IMSS)',
							descripcion: 'Revisar nómina de empleados y subir acuse de alta (IMSS)',
							titulo: 'Revisar nómina de empleados y subir acuse de alta (IMSS)',
							bloquear: false
						}
					}
				} else {
					string = {
						color: 'rgba(63, 81, 181, 0.08)',
						alt: 'Nómina de empleados y acuse de alta (IMSS)',
						descripcion: 'Ver nómina de empleados y acuse de alta (IMSS)',
						titulo: 'Nómina de empleados y acuse de alta (IMSS)',
						bloquear: true
					}
				}*/
				break;
			case 5:
				if (FieldsJs.inArray(value.ids_cat_tipo_usuario, this.Usr.id_cat_tipo_usuario)) {
					if (epp.elaborar_contratos_individuales === 1) {
						string = {
							color: 'rgb(194, 224, 195)',
							alt: 'Contratos individuales elaborados',
							descripcion: 'Completado',
							titulo: 'Contratos individuales elaborados',
							bloquear: false
						}
					} else {
						string = {
							color: 'rgba(63, 81, 181, 0.08)',
							alt: 'Contratos individuales elaborados',
							descripcion: 'Subir contratos individuales elaborados',
							titulo: 'Subir contratos individuales elaborados',
							bloquear: false
						}
					}
				} else {
					string = {
						color: 'rgba(63, 81, 181, 0.08)',
						alt: 'Contratos individuales elaborados',
						descripcion: 'Ver contratos individuales elaborados',
						titulo: 'Contratos individuales elaborados',
						bloquear: true
					}
				}
				break;
			case 6:
				if (FieldsJs.inArray(value.ids_cat_tipo_usuario, this.Usr.id_cat_tipo_usuario)) {
					if (epp.agendar_firmas_contratos_individuales === 1) {
						string = {
							color: 'rgb(194, 224, 195)',
							alt: 'Firma de contratos individuales',
							descripcion: 'Completado',
							titulo: 'Firma de contratos individuales',
							bloquear: false
						}
					} else {
						string = {
							color: 'rgba(63, 81, 181, 0.08)',
							alt: 'Firma de contratos individuales',
							descripcion: 'Agendar firma de contratos individuales',
							titulo: 'Agendar firma de contratos individuales',
							bloquear: false
						}
					}
				} else {
					string = {
						color: 'rgba(63, 81, 181, 0.08)',
						alt: 'Firma de contratos individuales',
						descripcion: 'Ver agenda de firma de contratos individuales',
						titulo: 'Agenda de firma de contratos individuales',
						bloquear: true
					}
				}
				break;
			case 7:
				if (FieldsJs.inArray(value.ids_cat_tipo_usuario, this.Usr.id_cat_tipo_usuario)) {
					if (epp.confirmar_dato_promotor === 1) {
						string = {
							color: 'rgb(194, 224, 195)',
							alt: 'Datos bancarios del promotor',
							descripcion: 'Completado',
							titulo: 'Datos bancarios del promotor',
							bloquear: false
						}
					} else {
						string = {
							color: 'rgba(63, 81, 181, 0.08)',
							alt: 'Datos bancarios del promotor',
							descripcion: 'Ver o enviar datos bancarios del promotor',
							titulo: 'Ver o enviar datos bancarios del promotor',
							bloquear: false
						}
					}
				} else {
					string = {
						color: 'rgba(63, 81, 181, 0.08)',
						alt: 'Datos bancarios del promotor',
						descripcion: 'Ver datos bancarios del promotor',
						titulo: 'Datos bancarios del promotor',
						bloquear: true
					}
				}
				break;
			case 8:
				if (FieldsJs.inArray(value.ids_cat_tipo_usuario, this.Usr.id_cat_tipo_usuario)) {
					if (epp.dato_bancario_cliente_pagadora === 1) {
						string = {
							color: 'rgb(194, 224, 195)',
							alt: 'Datos bancarios de la empresa receptora de pago',
							descripcion: 'Completado',
							titulo: 'Datos bancarios de la empresa receptora de pago',
							bloquear: false
						}
					} else {
						string = {
							color: 'rgba(63, 81, 181, 0.08)',
							alt: 'Datos bancarios de la empresa receptora de pago',
							descripcion: 'Agregar o enviar datos bancarios de la empresa receptora de pago',
							titulo: 'Agregar o enviar datos bancarios de la empresa receptora de pago',
							bloquear: false
						}
					}
				} else {
					string = {
						color: 'rgba(63, 81, 181, 0.08)',
						alt: 'Datos bancarios de la empresa receptora de pago',
						descripcion: 'Ver datos bancarios de la empresa receptora de pago',
						titulo: 'Datos bancarios de la empresa receptora de pago',
						bloquear: true
					}
				}
				break;
			case 9:
				if (FieldsJs.inArray(value.ids_cat_tipo_usuario, this.Usr.id_cat_tipo_usuario)) {
					if (epp.subir_confirmar_prenomina === 1) {
						string = {
							color: 'rgb(194, 224, 195)',
							alt: 'Pre-nómina',
							descripcion: 'Completado',
							titulo: 'Pre-nómina',
							bloquear: false
						}
					} else {
						string = {
							color: 'rgba(63, 81, 181, 0.08)',
							alt: 'Prenómina',
							descripcion: Number(this.Usr.id_cat_tipo_usuario) === 3 ? 'Confirmar pre-nómina':Number(this.Usr.id_cat_tipo_usuario) === 7 ? 'Subir pre-nómina':'Subir y confirmar prenómina',
							titulo: Number(this.Usr.id_cat_tipo_usuario) === 3 ? 'Confirmar pre-nómina':Number(this.Usr.id_cat_tipo_usuario) === 7 ? 'Subir pre-nómina':'Subir y confirmar prenómina',
							bloquear: false
						}
					}
				} else {
					string = {
						color: 'rgba(63, 81, 181, 0.08)',
						alt: 'Prenómina',
						descripcion: 'Ver pre-nómina',
						titulo: 'Pre-nómina',
						bloquear: true
					}
				}
				break;
			case 10:
				if (FieldsJs.inArray(value.ids_cat_tipo_usuario, this.Usr.id_cat_tipo_usuario)) {
					if (epp.subir_contrato_prestacion_servicio_promotor === 1) {
						string = {
							color: 'rgb(194, 224, 195)',
							alt: 'Contrato de PS firmado',
							descripcion: 'Completado',
							titulo: 'Contrato de PS firmado',
							bloquear: false
						}
					} else {
						string = {
							color: 'rgba(63, 81, 181, 0.08)',
							alt: 'Dubir contrato de PS firmado',
							descripcion: 'Subir contrato de PS firmado',
							titulo: 'Subir contrato de PS firmado',
							bloquear: false
						}
					}
				} else {
					string = {
						color: 'rgba(63, 81, 181, 0.08)',
						alt: 'Contrato de PS firmado',
						descripcion: 'Ver contrato de PS firmado',
						titulo: 'Ver contrato de PS firmado',
						bloquear: true
					}
				}
				break;
			default:
		}
		return string;
	};
	
	render() {
		
		const pasos = this.pasos();
		
		return (
			<Fragment>
				<div className={''}>
					{/*<Grid container spacing={1}>
						<Grid item xs={12} sm={4} md={4} lg={3} xl={3}/>
						<Grid item xs={12} sm={4} md={4} lg={6} xl={6}>
							<Typography className={'px-14 text-center bg-white padding-10'}>
								Alta del cliente
							</Typography>
						</Grid>
						<Grid item xs={12} sm={4} md={4} lg={3} xl={3}/>
					</Grid>*/}
					
					<Grid container spacing={2}>
						{pasos.map((value, index) => (
							<Grid key={index} item xs={4} sm={3} md={2} lg={2} xl={1} align={'center'}>
								
								<div className={'card'} style={{borderRadius: '10px'}}>
									<div className={'margin-10-B text-center container-card-3'}>
										{value.rol}
									</div>
									
									<div align={'right'} style={{padding: "0"}}>
										{this.get_data(value, this.props.item).bloquear ? (
											<div style={{height: '0px', width: '100%'}}>
												<div style={{height: '30px', width: '30px'}}>
													<DivSvg
														img={bloquear}
														height={'30px'}
														width={'30px'}
														opacity={false}
													/>
												</div>
											</div>
										) : null}
									</div>
									
									<div style={{height: '100px', width: '100px'}} title={this.get_data(value, this.props.item).alt}>
										<DivSvg
											img={value.image}
											height={'100px'}
											width={'100px'}
											opacity={this.get_data(value, this.props.item).bloquear}
										/>
									</div>
									
									<div style={{width: '100%', height: '15px'}}/>
									
									<div className={'min-h-60-px vertical-inline'} style={{
										backgroundColor: this.get_data(value, this.props.item).color,
										borderRadius: '0px',
										width: '100%',
										margin: '0px',
										padding: '0px'
									}}>
										<div className={'w-100 v-center padding-10-L padding-10-R'}>
											<p component={'b'} className={'px-14 w-100 margin-0 text-center'} style={{lineHeight: '14px'}} title={value.rol}>
												{this.get_data(value, this.props.item).descripcion}
											</p>
											{this.get_data(value, this.props.item).descripcion === 'Completado' ? (
												<p component={'b'} className={'px-11 w-100 padding-5-T margin-0 text-center'} style={{lineHeight: '14px'}} title={value.rol}>
													{this.get_data(value, this.props.item).titulo}
												</p>
											) : null}
										</div>
									</div>
									
									<div style={{width: '100%', height: '5px'}}/>
									
									{this.get_component(value.id, this.props.item, this.get_data(value, this.props.item).titulo)}
									
									<div style={{width: '100%', height: '5px'}}/>
								
								</div>
								
								
							</Grid>
						))}
					</Grid>
				
				
				</div>
			</Fragment>
		);
	}
}

SubTablaClientePotencial.propTypes = {
	tipo_vista: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired
};


export default SubTablaClientePotencial;
