import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Grid from "@material-ui/core/Grid";
import {withStyles} from '@material-ui/core/styles/index';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import {CloudDone, CloudDownload, Description, Folder} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import {FieldsJs} from "../../../../settings/General/General";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {RepositorioContableService} from "../../../../services/RepositorioContableService/RepositorioContableService";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
		backgroundColor: 'transparent'
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		let rut = this.props.ruta.split('/');
		
		this.state = {
			checkAdmin: FieldsJs.inArray([1, 2, 3, 4, 6, 9, 10, 13, 14], this.Usr.id_cat_tipo_usuario) ? 6 : 12,
			activeList: rut.length >= 3 ? true:false
		};
		
		console.log("STATE::: => ", this.state, rut, rut.length, this.props.ruta);
	}
	
	eliminar = (item) => {
		this.props.$BroadcastModalUsuarios({
			accion: 'delete',
			status: true,
			item: item
		});
	};
	
	openFolder = (item) => {
		if (Object.values(item)[0] === "folder") {
			this.props.openFolder(item.path_display, item.name);
		}
	};
	
	autorizarArchivo = (item) => {
		let msg = `¿Deseas autorizar el documento(s)?`;
		
		PopupService.Confirm(['Cancelar', 'Autorizar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Autorizar') {
				console.log(item);
				RepositorioContableService.AutorizarArchivo(item).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
			
		});
	};
	
	
	render() {
		
		const {classes, lista} = this.props;
		
		
		return (
			
			<Grid container spacing={2} className={'h-100'}>
				
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Grid container spacing={2}>
						<Fragment>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{lista.length > 0 ? (
									<Table className={classes.table + ' desing-acresco margin-20-T'}>
										<TableHead>
											<TableRow>
												<TableCell component="th" align={'left'}>Archivo</TableCell>
												<TableCell component="th" align={'left'}>Nombre completo</TableCell>
												<TableCell component="th" align={'left'}>Correo electrónico</TableCell>
												<TableCell component="th" align={'left'}>Estatus</TableCell>
												<TableCell component="th" align={'center'}>Acciones</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{lista.map((item, key) => {
												return (
													<TableRow key={key}>
														
														<TableCell align={'left'}>
															<div className={Object.values(item)[0] === "folder" ? 'vertical-inline cursor-pointer' : 'vertical-inline'} onClick={() => this.openFolder(item)}>
																<div className={'v-center padding-15-R'}>
																	{item.thumb && Object.values(item.thumb)[0] !== 'failure' ? <img src={`data:image/jpeg;base64,${item.thumb.thumbnail}`} width={32}/>
																		: Object.values(item)[0] === "folder" ?
																			<Folder style={{fontSize: '32px', color: 'rgb(142, 30, 50)'}}/>
																			: <Description style={{fontSize: '32px', color: 'rgba(23,76,145,0.37)'}}/>
																	}
																</div>
																<div className={'v-center'}>
																	{item.nombre_archivo}
																</div>
															</div>
														</TableCell>
														
														<TableCell align={'left'}>
															{item.nombre_completo}
														</TableCell>
														
														<TableCell align={'left'}>
															<div>
																{item.correo_electronico}
															</div>
														</TableCell>
														
														<TableCell align={'left'}>
															<div>
																{item.autorizado ? 'Autorizado' : 'Por autorizar'}
															</div>
														</TableCell>
														
														<TableCell align={'center'}>
															<div className={'v-center'}>
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Descargar">
																	<IconButton aria-label="Descargar" onClick={() => this.props.descargarZip(item.path_display)}>
																		<CloudDownload style={{color: 'rgb(142, 30, 50)'}}/>
																	</IconButton>
																</Tooltip>
															</div>
														</TableCell>
													
													</TableRow>
												)
											})
											}
										</TableBody>
									</Table>
								) : (
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<VistaVacia
											numero={0}
											height={100}
											width={100}
											mensaje={'No se encontraron datos del cliente.'}
										/>
									</Grid>
								)}
								
							</Grid>
						</Fragment>
						
					</Grid>
				</Grid>
			</Grid>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	ruta: PropTypes.string,
	filtros: PropTypes.object,
	openFolder: PropTypes.func.isRequired,
	descargarZip: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default withStyles(styles)(ListaTabla);
