import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs, FloatSolo} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {TicketsService} from "../../../../services/TicketsService/TicketsService";
import {AddOutlined, Business, CancelOutlined, Cloud, DeleteOutlined, EditOutlined, Group, HdrWeakOutlined, Person, SaveOutlined} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Typography from "@material-ui/core/Typography";


import add_cliente_venta from '../../../../assets/img/icons/facturacion.svg';
import edit_cliente_venta from '../../../../assets/img/icons/facturacion.svg';
import view_cliente_venta from '../../../../assets/img/icons/facturacion.svg';
import agregar_producto_requerimiento_adicional from '../../../../assets/img/icons/facturacion.svg';
import carnet_identificacion from '../../../../assets/img/icons/facturacion.svg';
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import {AreaService} from "../../../../services/_Cat/AreaService/AreaService";
import {EmpresaService} from "../../../../services/_Cat/EmpresaService/EmpresaService";

import './../Tickets.css';
import {PrioridadService} from "../../../../services/_Cat/Prioridad/PrioridadService";
import moment from "moment";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import DialogTitle from "@material-ui/core/DialogTitle";
import TicketSvg from "../../../../assets/img/icons/ticket.svg";
import DocSvg from "../../../../assets/img/icons/doc.svg";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import {DropzoneArea} from "material-ui-dropzone";
import {FacturacionService} from "../../../../services/FacturacionService/FacturacionService";
import {CargaCFDIService} from "../../../../services/CargaCFDIService/CargaCFDIService";
import {Dropbox} from "dropbox";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";



const styles = {
	root: {
		background: "transparent",
		color: "black"
	},
	input: {
		color: "black"
	},
	input_number: {
		color: "black",
		textAlign: "right",
		alignText: "right",
	}
};


class ModalTickets extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_ticket: props.id_ticket || null,
			
			key: null,
			area_solucion: '',
			id_usuario_solicitante: '',
			id_cat_area_usuario_solicitante: '',
			id_cat_area_solucion: '',
			id_usuario_asignado: '',
			id_cat_prioridad: '',
			id_empresa: '',
			fecha_solucion: null,
			requerimiento: '',
			url_ticket_requerimiento: '',
			fecha_solicitud: null,
			
			lista_usuario_solicitante: [],
			lista_tipo_usuario_solicitante: [],
			lista_area_solucion: [],
			lista_usuario_asignado: [],
			lista_prioridad: [],
			lista_empresa: [],
			lista_area: [],
			lista_frecuencia_notificacion: [],
			
			ticket_requerimiento: [],
			
			ficha_tecnica_archivo_integracion_expediente: [],
			archivo_integracion_expediente: '',
			id_ficha_tecnica_archivo_integracion_expediente: '',
			id_cat_archivo_integracion_expediente: '',
			loading: '',
			base64: '',
			formato: '',
			archivo: '',
			is_required: '',
			
			observaciones: [],
			fecha_notificacion: null,
			frecuencia_notificacion: '',
			
			usuarios_notificados: [],
			empresas: [],
			lista_usuario_autorizado: [],
			usuarios_notificar: [],
			id_usuario_autorizado: '',
			
			check_anexar_documento: '',
			check_anexar_url: '',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => {
			this.setState({
				[r.name]: r.value
			});
			if (input === 'fecha_notificacion') {
				let fs30 = moment(this.state.fecha_solicitud).subtract(30, 'minutes').toDate().getTime();
				let fn = moment(r.value);
				if (fn > fs30) {
					this.showSnackBars('error', "La fecha de notificación debe tener por lo menos una diferencia de 30 min, antes de la fecha de solución.");
					this.setState({
						fecha_notificacion: null,
					});
				}
			}
		});
		if (input === 'fecha_solicitud') {
			this.setState({
				fecha_notificacion: null,
				//frecuencia_notificacion: '1'
			});
		}
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	ListaUsuarioReporte = () => {
		return new Promise((resolve, reject) => {
			CatService.ListUsuarioTipoReporte().then((response) => {
				this.setState({
					lista_usuario_autorizado: response.data,
				});
				/*const {item} = this.props;
				if (this.props.tipo === 'view' || this.props.tipo === 'edit') {
					setTimeout(() => {
						for (let i = 0; i < item.autorizados.length; i++) {
							this.asignarUsuarioReporte(item.autorizados[i].id_usuario, item.autorizados[i].id_usuario);
						}
					});
				}*/
				resolve(response);
			}).catch((error) => {
				this.setState({
					lista_usuario_autorizado: []
				});
				this.showSnackBars('error', 'Por el momento no hay usuarios registrados');
				reject(false);
			});
		});
	};
	
	asignarUsuarioReporte = (id_usuario, id_usuario_autorizado) => {
		let usuarios = FieldsJs.Copy(this.state.lista_usuario_autorizado);
		let users = FieldsJs.Copy(this.state.usuarios_notificar);
		let banduser = false;
		let userkey = 0;
		for (let i = 0; i < users.length; i++) {
			if (Number(users[i].id_usuario) === Number(id_usuario)) {
				banduser = true;
				userkey = i;
			}
		}
		if (!banduser) {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = true;
					if (id_usuario_autorizado) {
						usuarios[i].id_usuario = id_usuario_autorizado;
					}
					users.push(usuarios[i]);
				}
			}
		} else {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = false;
				}
			}
			users.splice(userkey, 1);
		}
		this.setState({
			lista_usuario_autorizado: usuarios,
			usuarios_notificar: users,
		});
		console.log("ASIGNARUSUARIO", users, usuarios, this.state.usuarios_notificar);
	};
	
	ListaArea = () => {
		AreaService.Listar().then((response) => {
			this.setState({
				lista_area: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_area: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaEmpresa = () => {
		CatService.ListEmpresa().then((response) => {
			this.setState({
				lista_empresa: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_empresa: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaPrioridad = () => {
		PrioridadService.Listar().then((response) => {
			this.setState({
				lista_prioridad: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_prioridad: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaTicketFrecuenciaNotificacion = () => {
		CatService.ListFrecuenciaNotificacion().then((response) => {
			this.setState({
				lista_frecuencia_notificacion: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_frecuencia_notificacion: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaUsuario = (id_cat_tipo_usuario) => {
		return new Promise((resolve, reject) => {
			let params = {
				id_cat_tipo_usuario: id_cat_tipo_usuario,
				id_usuario: this.Usr.id_usuario
			};
			TicketsService.TipoUsuario(params).then((response) => {
				this.setState({
					lista_usuario_asignado: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					lista_usuario_asignado: []
				});
				this.showSnackBars('error', 'Por el momento el area seleccionada no cuenta con personal para dar solución a tickets');
				reject(false);
			});
		});
	};
	
	ListaAreaxid = (id) => {
		let select = "";
		this.state.lista_area.map((item) => {
			if (Number(item.id_cat_tipo_usuario) === Number(id)) {
				select = item.tipo_usuario;
			}
		})
		return select;
	};
	
	ListaUsuarioxid = (id) => {
		let select = "";
		this.state.lista_usuario_asignado.map((item) => {
			if (Number(item.id_usuario) === Number(id)) {
				select = item.name;
			}
		})
		return select;
	};
	
	ListaPrioridadxid = (id) => {
		let select = "";
		this.state.lista_prioridad.map((item) => {
			if (Number(item.id_cat_prioridad) === Number(id)) {
				select = item.prioridad;
			}
		})
		return select;
	};
	
	ListaEmpresaxid = (id) => {
		let select = "";
		if (id) {
			this.state.lista_empresa.map((item) => {
				if (Number(item.id_empresa) === Number(id)) {
					select = item.nombre_comercial;
				}
			})
		} else {
			select = "Sin registrar";
		}
		
		return select;
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		console.log('USER', this.Usr.id_usuario, this.Usr.id_cat_tipo_usuario);
		this.init().then(r => {
			this.ListaArea();
			this.ListaEmpresa();
			this.ListaPrioridad();
			this.ListaUsuarioReporte();
			this.ListaTicketFrecuenciaNotificacion();
			this.setState({
				id_usuario_solicitante: this.Usr.id_usuario,
				id_cat_area_usuario_solicitante: this.Usr.id_cat_tipo_usuario,
				ticket_requerimiento: [],
				
			});
			
			this.init().then(r => {
				setTimeout(() => this.openModal());
			}).catch(e => {
				console.log(e);
			});
			
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			if (this.props.id_ticket > 0) {
				TicketsService.Detalles(this.props.id_ticket).then((response) => {
					let item = FieldsJs.Copy(response.data);
					let fecha_emitir_comprobante = item.fecha_emitir_comprobante ? moment(item.fecha_emitir_comprobante) : null;
					
					this.ListaUsuario(item.id_cat_area_solucion);
					this.setState({
						id_ticket: item.id_ticket,
						id_cat_area_solucion: item.id_cat_area_solucion,
						id_usuario_asignado: item.id_usuario_asignado,
						id_cat_prioridad: item.id_cat_prioridad,
						id_empresa: item.id_empresa,
						fecha_solucion: item.fecha_solucion,
						requerimiento: item.requerimiento,
						ticket_requerimiento: item.requerimientos || [],
						url_ticket_requerimiento: item.url_ticket_requerimiento,
						fecha_solicitud: item.fecha_solicitud,
						usuarios_notificados: item.usuarios_notificados,
					});
					
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false)
				});
			} else {
				this.setState({
					id_cat_area_solucion: '',
					id_usuario_asignado: '',
					id_cat_prioridad: '',
					id_empresa: '',
					fecha_solucion: null,
					requerimiento: '',
					ticket_requerimiento: [],
					url_ticket_requerimiento: '',
					fecha_solicitud: null,
					ficha_tecnica_archivo_integracion_expediente: [
						{id_cat_archivo_integracion_expediente: 1, archivo_integracion_expediente: 'Anexo', is_required: 0}],
					archivo_integracion_expediente: '',
					id_ficha_tecnica_archivo_integracion_expediente: '',
					id_cat_archivo_integracion_expediente: '',
					loading: '',
					base64: '',
					formato: 'png',
					archivo: '',
					is_required: '',
					usuarios_notificados: [],
				});
				
				resolve(true);
			}
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	guardarDocTimbradoDbx = (ftar) => {
		
		console.log("1 FTAR::: ", ftar);
		
		let files;
		
		return new Promise((resolve, reject) => {
			let band = 0;
			let band2 = 0;
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i]) === true) {
					band++;
				}
			}
			let archives = [];
			
			
			for (let i = 0; i < ftar.length; i++) {
				console.log("2 FTAR::: ", ftar);
				files = ftar[i];
				
				let ruta = '/Tickets/' + files.name;
				
				DropboxApi.Upload(ruta, files).then(function (response) {
					archives.push(response.path_display);
					
					band2++;
					
					console.log("DROPBOX UPLOAD: ", response, band, band2);
					
					if (band === band2) {
						resolve(archives);
					}
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	save = () => {
		
		let tickets = this.state.ticket_requerimiento;
		let band_archivo = false;
		
		for (let i = 0; i < tickets.length; i++) {
			if (tickets[i].files.length > 0) {
				band_archivo = true;
			}
		}
		
		if (band_archivo) {
			for (let i = 0; i < tickets.length; i++) {
				
				let guardar = new Promise((resolve, reject) => {
					this.guardarDocTimbradoDbx(tickets[i].files).then(response => {
						tickets[i].archivos = response;
						
						/*this.setState({
							archivos: response,
						});*/
						resolve(response);
						console.log("DROPBOX UPLOAD: ", this.state);
						
					}).catch(error => {
						reject(error);
					})
				});
				
				guardar.then(response => {
					if (i === (tickets.length - 1)) {
						if (Number(this.state.id_ticket) > 0) {
							TicketsService.Modificar(this.state).then((response) => {
								this.close();
								this.props.RefreshList();
								this.props.showSnackBars('success', response.mensaje);
							}).catch((error) => {
								this.props.showSnackBars('error', error.mensaje);
							});
						} else {
							TicketsService.Agregar(this.state).then((response) => {
								this.close();
								this.props.RefreshList(response.data);
								this.props.showSnackBars('success', response.mensaje);
							}).catch((error) => {
								this.props.showSnackBars('error', error.mensaje);
							});
						}
					}
				});
			}
		} else {
			if (Number(this.state.id_ticket) > 0) {
				TicketsService.Modificar(this.state).then((response) => {
					this.close();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			} else {
				TicketsService.Agregar(this.state).then((response) => {
					this.close();
					this.props.RefreshList(response.data);
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		}
		
	};
	
	add = () => {
		this.setState({
			id_usuario_solicitante: this.Usr.id_usuario,
			id_cat_area_usuario_solicitante: this.Usr.id_cat_tipo_usuario,
			id_cat_area_solucion: '',
			id_usuario_asignado: '',
			id_cat_prioridad: '',
			id_empresa: '',
			fecha_solucion: null,
			requerimiento: '',
			url_ticket_requerimiento: '',
			fecha_solicitud: null,
		});
		
		this.open();
	};
	
	add_asignacion = () => {
		try {
			
			let ticket_requerimiento = FieldsJs.Copy(this.state.ticket_requerimiento || []);
			let id_area_usuario = FieldsJs.Copy(this.state.id_cat_area_solucion || '');
			
			if (!this.state.id_cat_area_solucion) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Area de solución.'
				})
			} else {
				let band = false;
				if (ticket_requerimiento.length > 0) {
					for (let i = 0; i < ticket_requerimiento.length; i++) {
						if (Number(ticket_requerimiento[i].id_cat_area_solucion) != Number(id_area_usuario)) {
							band = true;
						}
					}
				}
				if (band) {
					throw Object({
						status: false, mensaje: 'Area de solución debe ser la misma en todos los requerimientos.'
					})
				}
			}
			
			if (!this.state.id_usuario_asignado) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Usuario asignado.'
				})
			}
			
			if (!this.state.id_cat_prioridad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Prioridad.'
				})
			}
			
			/*if (!this.state.id_empresa) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Empresa relacionada.'
				})
			}*/
			
			if (!this.state.requerimiento) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Requerimiento relacionada.'
				})
			}
			
			console.log('this.state.fecha_solicitud', this.state.fecha_solicitud);
			
			if (this.is_edited_ticket()) {
				for (let i = 0; i < ticket_requerimiento.length; i++) {
					let item = ticket_requerimiento[i];
					if (this.state.key === i) {
						//item.id_usuario_solicitante = this.state.id_usuario_solicitante || null;
						//item.id_cat_area_usuario_solicitante = this.state.id_cat_area_usuario_solicitante || null;
						item.id_cat_area_solucion = this.state.id_cat_area_solucion || null;
						item.id_usuario_asignado = this.state.id_usuario_asignado || null;
						item.id_cat_prioridad = this.state.id_cat_prioridad || null;
						//item.id_empresa = this.state.id_empresa || null;
						item.fecha_solucion = this.state.fecha_solucion || null;
						item.requerimiento = this.state.requerimiento || null;
						item.url_ticket_requerimiento = this.state.url_ticket_requerimiento || null;
						item.fecha_solicitud = this.state.fecha_solicitud != null ? moment(this.state.fecha_solicitud).format("YYYY-MM-DD HH:mm:ss") : null;
						item.fecha_notificacion = this.state.fecha_notificacion != null ? moment(this.state.fecha_notificacion).format("YYYY-MM-DD HH:mm:ss") : null;
						item.frecuencia_notificacion = this.state.frecuencia_notificacion || null;
						item.fecha_solicitud_activo = this.state.fecha_solicitud ? 1 : 0;
						item.empresas = this.state.empresas || [];
						item.files = this.state.files || [];
					}
				}
			} else {
				let item;
				item = {
					//id_usuario_solicitante: this.state.id_usuario_solicitante || null,
					//id_cat_area_usuario_solicitante: this.state.id_cat_area_usuario_solicitante || null,
					id_cat_area_solucion: this.state.id_cat_area_solucion || null,
					id_usuario_asignado: this.state.id_usuario_asignado || null,
					id_cat_prioridad: this.state.id_cat_prioridad || null,
					//id_empresa: this.state.id_empresa || null,
					fecha_solucion: this.state.fecha_solucion || null,
					requerimiento: this.state.requerimiento || null,
					url_ticket_requerimiento: this.state.url_ticket_requerimiento || null,
					fecha_solicitud: this.state.fecha_solicitud != null ? moment(this.state.fecha_solicitud).format("YYYY-MM-DD HH:mm:ss") : null,
					fecha_notificacion: this.state.fecha_notificacion != null ? moment(this.state.fecha_notificacion).format("YYYY-MM-DD HH:mm:ss") : null,
					frecuencia_notificacion: this.state.frecuencia_notificacion || null,
					fecha_solicitud_activo: this.state.fecha_solicitud ? 1 : 0,
					empresas: this.state.empresas || [],
					files: this.state.files || []
				};
				
				ticket_requerimiento.push(item);
			}
			
			this.setState({
				key: null,
				//id_usuario_solicitante: '',
				//id_cat_area_usuario_solicitante: '',
				// id_cat_area_solucion: '',
				//fecha_solucion: null,
				id_usuario_asignado: '',
				id_cat_prioridad: '',
				id_empresa: '',
				empresas: [],
				requerimiento: '',
				ticket_requerimiento: ticket_requerimiento,
				url_ticket_requerimiento: '',
				files: [],
				check_anexar_documento: '',
				check_anexar_url: '',
				fecha_solicitud: null,
			});
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
		
		console.log(this.state.ticket_requerimiento);
	};
	
	is_edited_ticket = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	edit = (item, key) => {
		try {
			
			this.setState({
				key: key,
				id_usuario_solicitante: item.id_usuario_solicitante || '',
				id_cat_area_usuario_solicitante: item.id_cat_area_usuario_solicitante || '',
				id_cat_area_solucion: item.id_cat_area_solucion || '',
				id_usuario_asignado: item.id_usuario_asignado || '',
				id_cat_prioridad: item.id_cat_prioridad || '',
				id_empresa: item.id_empresa || '',
				fecha_solucion: item.fecha_solucion || null,
				requerimiento: item.requerimiento || '',
				url_ticket_requerimiento: item.url_ticket_requerimiento || '',
				fecha_solicitud: item.fecha_solicitud || null,
				empresas: item.empresas || [],
				files: item.files || [],
				check_anexar_documento: item.files.length > 0 ? true:false,
				check_anexar_url: item.url_ticket_requerimiento ? true:false
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			id_usuario_solicitante: '',
			id_cat_area_usuario_solicitante: '',
			//id_cat_area_solucion: '',
			id_usuario_asignado: '',
			id_cat_prioridad: '',
			id_empresa: '',
			fecha_solucion: null,
			requerimiento: '',
			url_ticket_requerimiento: '',
			fecha_solicitud: null,
			empresas: [],
			files: [],
			check_anexar_documento: '',
			check_anexar_url: ''
		});
	};
	
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el requerimiento?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_contactos = FieldsJs.Copy(this.state.ticket_requerimiento);
					
					lista_contactos.splice(key, 1);
					
					this.setState({
						ticket_requerimiento: lista_contactos
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		/*this.setState({
			id_usuario_solicitante: item.id_usuario_solicitante || '',
			id_cat_area_usuario_solicitante: item.id_cat_area_usuario_solicitante || '',
			id_cat_area_solucion: item.id_cat_area_solucion === 1 ? 'fisica' : 'moral',
			id_usuario_asignado: item.id_usuario_asignado || '',
			id_cat_prioridad: item.id_cat_prioridad || '',
			id_empresa: item.id_empresa || '',
			fecha_solucion: item.fecha_solucion || null,
			requerimiento: item.requerimiento || '',
			url_ticket_requerimiento: item.url_ticket_requerimiento || '',
			fecha_solicitud: item.fecha_solicitud || null,
		});*/
		this.open();
	};
	
	selectArea = (item) => {
		this.ListaUsuario(item.id_cat_tipo_usuario).then((response) => {
			if (response.data.length > 0) {
				this.setState({
					id_cat_area_solucion: item.id_cat_tipo_usuario,
					area_solucion: item.tipo_usuario
				})
			}
		});
	}
	
	active_inactive_spinner = (active, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente) => {
		/*
		* INICIO: Activar el spinner
		* */
		let arr_temp = this.state.ficha_tecnica_archivo_integracion_expediente;
		if (id_ficha_tecnica_archivo_integracion_expediente || id_cat_archivo_integracion_expediente) {
			let uno = false;
			let dos = false;
			for (let i = 0; i < arr_temp.length; i++) {
				uno = Number(arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente) === Number(id_ficha_tecnica_archivo_integracion_expediente) && Number(id_ficha_tecnica_archivo_integracion_expediente) > 0;
				dos = Number(arr_temp[i].id_cat_archivo_integracion_expediente) === Number(id_cat_archivo_integracion_expediente) && Number(id_cat_archivo_integracion_expediente) > 0;
				if (uno && dos) {
					arr_temp[i].loading = active;
				} else if (uno && !dos) {
					arr_temp[i].loading = active;
				} else if (!uno && dos) {
					arr_temp[i].loading = active;
				}
			}
		}
		this.setState({
			ficha_tecnica_archivo_integracion_expediente: arr_temp
		});
		/*
		* FIN: Activar el spinner
		* */
	};
	
	getBase64 = (e, id, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente) => {
		let formatos = ["image/jpeg", "image/png", "application/pdf", "application/xlsx", "application/docx", "text/txt", "image/svg"];
		FileBase64.Base64(e.target, formatos).then((response) => {
			
			let item = {
				id_ticket: this.state.id_ticket,
				id_ficha_tecnica: this.state.id_ficha_tecnica,
				id_cat_archivo_integracion_expediente: id_cat_archivo_integracion_expediente,
				id_ficha_tecnica_archivo_integracion_expediente: id_ficha_tecnica_archivo_integracion_expediente,
				archivo: response.archivo,
				formato: response.formato
			};
			
			this.active_inactive_spinner(true, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente);
			
			/*
			* Actualizar local variables
			* */
			let arr_temp = this.state.ficha_tecnica_archivo_integracion_expediente;
			if (id_ficha_tecnica_archivo_integracion_expediente || id_cat_archivo_integracion_expediente) {
				let uno = false;
				let dos = false;
				for (let i = 0; i < arr_temp.length; i++) {
					uno = Number(arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente) === Number(id_ficha_tecnica_archivo_integracion_expediente) && Number(id_ficha_tecnica_archivo_integracion_expediente) > 0;
					dos = Number(arr_temp[i].id_cat_archivo_integracion_expediente) === Number(id_cat_archivo_integracion_expediente) && Number(id_cat_archivo_integracion_expediente) > 0;
					if (uno && dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
					} else if (uno && !dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
					} else if (!uno && dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
					}
				}
			} else {
				arr_temp.push({
					loading: false,
					base64: response.base64,
					formato: response.formato,
					archivo: response.archivo,
					id_ficha_tecnica_archivo_integracion_expediente: id_ficha_tecnica_archivo_integracion_expediente || null,
					id_cat_archivo_integracion_expediente: id_cat_archivo_integracion_expediente || null,
				});
			}
			this.setState({
				ficha_tecnica_archivo_integracion_expediente: arr_temp
			});
			
			
			console.log('ARCHIVOS', this.state.ficha_tecnica_archivo_integracion_expediente);
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	fileSelect = (id) => {
		document.getElementById(id).value = '';
		document.getElementById(id).click();
	};
	
	
	disabledFieldPromotorIntegracionExpedienteBolean = () => {
		
		let disabledFieldPromotorIntegracionExpediente = false;
		
		return disabledFieldPromotorIntegracionExpediente;
	};
	
	frecuencia_notificacion = (f) => {
		let fr = '';
		switch (Number(f)) {
			case 1:
				fr = '1 min';
				break;
			case 2:
				fr = '30 min';
				break;
			case 3:
				fr = '1 hora';
				break;
			case 4:
				fr = '3 hrs.';
				break;
			case 5:
				fr = '6 hrs.';
				break;
			case 6:
				fr = '12 hrs.';
				break;
			case 7:
				fr = '24 hrs.';
				break;
			default:
				break;
		}
		return fr;
	};
	
	eliminar_empresa = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.empresas);
		arr_temp.splice(key, 1);
		this.setState({
			empresas: arr_temp
		});
	};
	
	agregar_empresa = () => {
		
		try {
			
			if (this.state.id_empresa <= 0) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("Selecciona la empresa relacionada.")
				});
			}
			
			let arr_temp = this.state.empresas;
			let id_empresa = this.state.id_empresa;
			let id;
			let nombre_comercial;
			let exist_empresa = false;
			
			for (let i = 0; i < this.state.lista_empresa.length; i++) {
				if (Number(this.state.lista_empresa[i].id_empresa) === Number(id_empresa)) {
					id = this.state.lista_empresa[i].id_empresa;
					nombre_comercial = this.state.lista_empresa[i].nombre_comercial;
				}
			}
			
			for (let i = 0; i < this.state.empresas.length; i++) {
				if (Number(this.state.empresas[i].id_empresa) === Number(id_empresa)) {
					exist_empresa = true;
				}
			}
			
			if (exist_empresa) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("La empresa relacionada ya se encuentra seleccionada.")
				});
			}
			
			arr_temp.push({
				id_empresa: id,
				nombre_comercial: nombre_comercial,
			});
			
			this.setState({
				empresas: arr_temp,
				id_empresa: '',
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
		
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	render() {
		
		const is_root = this.is_root();
		
		const disabledFieldPromotorIntegracionExpediente = this.disabledFieldPromotorIntegracionExpedienteBolean();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'new':
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		
		const btn_add = {
			backgroundImage: `url(${add_cliente_venta})`,
			opacity: 1
		};
		
		const btn_edit = {
			backgroundImage: `url(${edit_cliente_venta})`,
			opacity: 1
		};
		
		const btn_view = {
			backgroundImage: `url(${view_cliente_venta})`,
			opacity: 1
		};
		
		const btn_agregar_producto_requerimiento_adicional = {
			backgroundImage: `url(${agregar_producto_requerimiento_adicional})`,
			opacity: 1
		};
		
		const lista_vacia_datos_cliente = {
			backgroundImage: `url(${carnet_identificacion})`,
			opacity: 0.5
		};
		
		const desglose = '';
		
		const {classes} = this.props;
		
		let disabledInputVenta = false;
		
		let disabledInputVentaEditar = false;
		
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				{/*{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_ticket}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}*/}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						
						<div className={'text-gray '}>
							{this.is_view() ? 'Detalle Ticket' : 'Nuevo Ticket'}
						</div>
						
						{/*<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Ticket'}
							
						/>*/}
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-100'}>
								
								<Grid container spacing={0} className={'h-100'}>
									
									{!this.state.id_cat_area_solucion ? (
											(<Fragment>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'min-h-400-px'}>
															<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-left'}>
																	<Divider/>
																</Grid>
																
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'padding-10-T text-left'}>
																	<Typography variant={'h6'} className={'margin-5-L text-black px-13'}>
																		Selecciona el área a quien va dirigido el ticket:
																	</Typography>
																</Grid>
																
																{this.state.lista_area.map((item, key) => (
																	<Grid key={key} item xs={12} sm={6} md={6} lg={6} xl={6} className={'padding-10-T text-left'}>
																		<div className={'vertical-inline cursor-pointer'}
																		     style={{borderBottom: '1px solid rgba(100,100,100,0.2)', width: '100%'}}>
																			<Button className={'w-100'} onClick={() => this.selectArea(item)}>
																				<div className={'margin-10 vertical-inline w-100'}>
																					<div className={'w-90-px'}>
																						<img className={'v-center'} src={CONFIG.src + item.url_icono} alt="add" width={70} height={50} style={{opacity: 0.7}}/>
																					</div>
																					<div className={'w-100-90-px text-left'}>
																						<div className={'h-100 vertical-inline text-left'}>
																							<div className={'v-center text-left'}>
																								<Typography variant={'h6'} className={'text-black px-13'}>
																									{item.tipo_usuario}
																								</Typography>
																								<Typography variant={'h6'} className={'text-gray px-12'}>
																									{item.descripcion}
																								</Typography>
																							</div>
																						</div>
																					</div>
																				</div>
																			</Button>
																		</div>
																	</Grid>
																))}
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Fragment>)
										)
										:
										(<Fragment>
											{!this.is_view() ? <Fragment>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'min-h-300-px padding-10'}>
													<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
														<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
															<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'min-h-200-px'}>
																	<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
																		
																		<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'padding-10-T text-left'}>
																			<Typography variant={'h6'} className={'text-black px-12'}>
																				Información del ticket
																			</Typography>
																		</Grid>
																		
																		<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={'h-60-px text-left'}>
																			<span className={'text-left text-black px-12'}>Solicitante:</span>
																			<Typography variant={"h6"} component={"h6"}
																			            className={'text-left text-black px-14 l-h-16'}>
																				{this.Usr.nombre + ' ' + this.Usr.apellido_paterno + ' ' + this.Usr.apellido_materno}
																			</Typography>
																		</Grid>
																		
																		<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={'h-60-px text-left'}>
																			<span className={'text-left text-black px-12'}>Area del Solicitante:</span>
																			<Typography variant={"h6"} component={"h6"}
																			            className={'text-left text-black px-14 l-h-16'}>
																				{this.Usr.tipo_usuario}
																			</Typography>
																		</Grid>
																		
																		<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={'h-60-px text-left'}>
																			<span className={'text-left text-black px-12'}>Area de Solución:</span>
																			<Typography variant={"h6"} component={"h6"}
																			            className={'text-left text-black px-14 l-h-16'}>
																				{this.state.area_solucion}
																			</Typography>
																		</Grid>
																		
																		<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																			<br/>
																			<Divider/>
																		</Grid>
																		
																		<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
																			<Grid container spacing={1} alignItems={"flex-end"}>
																				<Grid item className={'w-30-px'}>
																					<Person className={'w-100 text-gray'}/>
																				</Grid>
																				<Grid item className={'w-100-30-px'}>
																					<FormControl required fullWidth>
																						<InputLabel>Usuario Asignado</InputLabel>
																						<Select
																							native
																							value={this.state.id_usuario_asignado}
																							onChange={this.handleChange}
																							name="id_usuario_asignado"
																							input={<Input/>}
																							disabled={this.props.tipo === 'view'}
																						>
																							<option value=""/>
																							{this.state.lista_usuario_asignado.map(option => (
																								<option key={option.id_usuario}
																								        value={option.id_usuario}>
																									{option.nombre + ' ' + option.apellido_paterno + ' ' + option.apellido_materno}
																								</option>
																							))}
																						</Select>
																					</FormControl>
																				</Grid>
																			</Grid>
																		</Grid>
																		
																		<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
																			<Grid container spacing={1} alignItems={"flex-end"}>
																				<Grid item className={'w-30-px'}>
																					<HdrWeakOutlined className={'w-100 text-gray'}/>
																				</Grid>
																				<Grid item className={'w-100-30-px'}>
																					<FormControl required fullWidth>
																						<InputLabel>Prioridad</InputLabel>
																						<Select
																							native
																							value={this.state.id_cat_prioridad}
																							onChange={this.handleChange}
																							name="id_cat_prioridad"
																							input={<Input/>}
																							disabled={this.props.tipo === 'view'}
																						>
																							<option value=""/>
																							{this.state.lista_prioridad.map(option => (
																								<option key={option.id_cat_prioridad}
																								        value={option.id_cat_prioridad}>
																									{option.prioridad}
																								</option>
																							))}
																						
																						</Select>
																					</FormControl>
																				</Grid>
																			</Grid>
																		</Grid>
																		
																		<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
																			<KeyboardDateTimePicker
																				format="yyyy/MM/dd hh:mm a"
																				fullWidth
																				clearable
																				inputProps={{readOnly: true}}
																				KeyboardButtonProps={{
																					'aria-label': 'Fecha solicitud',
																				}}
																				label="Fecha de solución"
																				value={this.state.fecha_solicitud}
																				onChange={(date) => {
																					this.handleChange(null, null, null, date, 'fecha_solicitud');
																				}}
																				// minDate={moment(new Date())}
																				clearLabel={'Limpiar'}
																				okLabel={'Aceptar'}
																				cancelLabel={'Cancelar'}
																				onError={console.log}
																				disabled={this.is_view()}
																			/>
																		</Grid>
																		
																		<Grid item className={'text-left'} xs={12} sm={12} md={8} lg={8} xl={8}>
																			<div className={'row-flex'}>
																				<div className={'w-100-80-px padding-10-R'}>
																					<Grid container spacing={1} alignItems={"flex-end"}>
																						<Grid item className={'w-30-px'}>
																							<Business className={'w-100 text-gray'}/>
																						</Grid>
																						<Grid item className={'w-100-30-px'}>
																							<FormControl fullWidth>
																								<InputLabel>Empresa Relacionada</InputLabel>
																								<Select
																									native
																									value={this.state.id_empresa}
																									onChange={this.handleChange}
																									name="id_empresa"
																									input={<Input/>}
																									disabled={this.props.tipo === 'view'}
																								>
																									<option value=""/>
																									{this.state.lista_empresa.map(option => (
																										<option key={option.id_empresa}
																										        value={option.id_empresa}>
																											{option.nombre_comercial}
																										</option>
																									))}
																								</Select>
																							</FormControl>
																						</Grid>
																					</Grid>
																				</div>
																				
																				<div className={'w-80-px v-end'}>
																					<Button size={'small'} variant="contained"
																					        onClick={this.agregar_empresa}
																					        className={'text-white text-transform-none ' + (this.props.tipo === 'view' ? 'btn-gray' : 'btn-default-secondary')}
																					        disabled={this.props.tipo === 'view'}>
																						Agregar
																					</Button>
																				</div>
																			</div>
																		</Grid>
																		
																		{this.state.empresas.length > 0 ? (
																			<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																				<div className={'w-100 text-left'}>
																					{this.state.empresas.map((item, key) => (
																						<div className={'vertical-inline b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
																							<div className={'padding-3 padding-10-L padding-10-R v-center text-white'}>
																								{item.nombre_comercial}
																							</div>
																							<div className={'padding-3 padding-10-R v-center text-white'}>
																								{this.props.tipo === 'view' ? null : (
																									<DeleteOutlined className={'px-18 text-white'} onClick={() => this.eliminar_empresa(key)}/>
																								)
																								}
																							</div>
																						</div>
																					))
																					}
																				</div>
																			</Grid>
																		) : ''}
																	
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
														
														<Grid item className={'text-left borde-punteado-lightgray-2'} xs={12} sm={12} md={4} lg={4} xl={4}>
															<Grid container spacing={1} alignItems={"flex-center"}>
																<Grid className={'w-30-px'}>
																	<Group className={'w-100 text-gray'}/>
																</Grid>
																<Grid className={'w-100-30-px'}>
																	<Typography className={'px-14 text-left text-gray margin-2-T'}>
																		{'Notificar a:'}
																	</Typography>
																</Grid>
																<Grid className={'w-100'}>
																	<div style={{maxHeight: 280, overflowY: 'auto'}}>
																		{this.state.lista_usuario_autorizado.map((item, index) => (
																			<Fragment key={index}>
																				<div className={'vertical-inline w-95 cursor-pointer'} onClick={() => {
																					if (this.props.tipo !== 'view') {
																						this.asignarUsuarioReporte(item.id_usuario);
																					}
																				}}>
																					<div className='v-center padding-10-L'>
																						<FormGroup row className={'text-center check-recuser'}>
																							<FormControlLabel
																								control={
																									<Checkbox
																										type="checkbox"
																										color="primary"
																										checked={item.check}
																										value={item.check}
																										disabled={this.props.tipo === 'view'}
																									/>
																								}
																								label={''}
																							/>
																						</FormGroup>
																					</div>
																					
																					<div className={'v-center vertical-inline'}>
																						<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
																						     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
																						</div>
																						<Typography variant={'h5'} className={'v-center padding-15-L margin-0 text-black px-13'}>
																							{item.nombre_completo}
																						</Typography>
																					</div>
																				</div>
																			</Fragment>
																		))}
																	</div>
																</Grid>
															</Grid>
														</Grid>
														
														{this.state.fecha_solicitud ? (
															<Fragment>
																<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
																	<KeyboardDateTimePicker
																		format="yyyy/MM/dd hh:mm a"
																		fullWidth
																		clearable
																		inputProps={{readOnly: true}}
																		KeyboardButtonProps={{
																			'aria-label': 'Fecha solicitud',
																		}}
																		label="Notificar a partir de:"
																		value={this.state.fecha_notificacion}
																		onChange={(date) => {
																			this.handleChange(null, null, null, date, 'fecha_notificacion');
																		}}
																		// minDate={moment(new Date())}
																		clearLabel={'Limpiar'}
																		okLabel={'Aceptar'}
																		cancelLabel={'Cancelar'}
																		onError={console.log}
																		disabled={this.is_view()}
																	/>
																</Grid>
																
																<Grid item xs={12} sm={12} md={9} lg={9} xl={9} className={'text-left'}>
																	<span className={'text-left text-black px-13'}>Frecuencia de las notificaciones:</span>
																	<Grid item className={'text-left ticketfrecuencia'} xs={12} sm={12} md={12} lg={12} xl={12}>
																		<RadioGroup
																			row aria-label="position"
																			name="frecuencia_notificacion"
																			value={this.state.frecuencia_notificacion}
																			onChange={this.handleChange}>
																			{this.state.lista_frecuencia_notificacion && this.state.lista_frecuencia_notificacion.map((item, key) => (
																				<FormControlLabel
																					style={{color: 'gray', fontSize: '13px'}}
																					key={key}
																					labelPlacement="end"
																					control={<Radio color="primary"/>}
																					value={item.id_cat_ticket_frecuencia_notificacion.toString()}
																					label={item.ticket_frecuencia_notificacion}
																					disabled={this.props.tipo === 'view'}
																				/>
																			))}
																		</RadioGroup>
																	</Grid>
																</Grid>
															</Fragment>
														) : null}
														
														<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
															<TextField
																rows={3}
																inputProps={{
																	style: {
																		fontSize: '13px',
																		lineHeight: '13px',
																		padding: '0px',
																		margin: '0px',
																	}
																}}
																required
																className={'px-12 padding-0 margin-0'}
																multiline
																fullWidth
																value={this.state.requerimiento}
																onChange={this.handleChange}
																type="text"
																margin="none"
																helperText=""
																name="requerimiento"
																label="Requerimiento"
																disabled={this.is_view()}
															/>
														</Grid>
														
														<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
															<div className={'vertical-inline h-100 w-100'}>
																<div className={'v-center h-100'}>
																	<FormControlLabel
																		className={'text-black px-12 text-bold'}
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='check_anexar_url'
																				checked={this.state.check_anexar_url}
																				value="check_anexar_url"
																			/>
																		}
																		label={<div style={{fontWeight: 'bold'}}>Anexar URL.</div>}
																	/>
																</div>
															</div>
														</Grid>
														
														<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
															<div className={'vertical-inline h-100 w-100'}>
																<div className={'v-center h-100'}>
																	<FormControlLabel
																		className={'text-black px-12 text-bold'}
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='check_anexar_documento'
																				checked={this.state.check_anexar_documento}
																				value="check_anexar_documento"
																			/>
																		}
																		label={<div style={{fontWeight: 'bold'}}>Anexar documento.</div>}
																	/>
																</div>
															</div>
														</Grid>
														
														<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={"right"}>
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Cancelar edición"
																children={(
																	<span>
																			<Fab size="small" color="primary"
																			     aria-label="Cancelar edición"
																			     onClick={() => this.edit_cancel()}
																			     style={{marginLeft: "15px"}}
																			     disabled={!this.is_edited_ticket()}>
																				<CancelOutlined/>
																			</Fab>
																		</span>
																)}
															/>
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title={this.is_edited_ticket() ? "Actualizar" : "Agregar"}
																children={(
																	<Fab size="small" color="primary"
																	     aria-label={this.is_edited_ticket() ? "Actualizar" : "Agregar"}
																	     onClick={() => this.add_asignacion()}
																	     style={{marginLeft: "15px"}}>
																		{this.is_edited_ticket() ? (
																			<SaveOutlined/>
																		) : (
																			<AddOutlined/>
																		)}
																	</Fab>
																)}
															/>
														</Grid>
														
														{this.state.check_anexar_url ? (
															<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																<Grid container spacing={1} alignItems={"flex-end"}>
																	<Grid item className={'w-30-px'}>
																		<Cloud className={'w-100 text-gray'}/>
																	</Grid>
																	<Grid item className={'w-100-30-px'}>
																		<TextField type={'text'} fullWidth name="url_ticket_requerimiento"
																		           onChange={this.handleChange}
																		           label="URL Anexo" autoComplete={'off'}
																		           value={this.state.url_ticket_requerimiento}
																		           disabled={this.props.tipo === 'view'}
																		/>
																	</Grid>
																</Grid>
															</Grid>
														) : null
														}
														
														{this.state.check_anexar_documento ? (
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<div className={'w-100'}>
																	<DropzoneArea
																		onChange={this.handleChangeDropzoneArea.bind(this)}
																		open={this.state.check_anexar_documento}
																		filesLimit={5}
																		dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
																		//acceptedFiles={['text/xml', 'application/pdf']}
																		showPreviews={false}
																		maxFileSize={5000000}
																		showAlerts={false}
																		showFileNames={true}
																		showFileNamesInPreview={true}
																	/>
																</div>
															</Grid>
														) : null
														}
													</Grid>
												</Grid>
												
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<br/>
													<Divider/>
												</Grid>
											
											</Fragment> : null}
											{this.state.ticket_requerimiento.length > 0 ? this.state.ticket_requerimiento.map((item, key) => (
												<Fragment>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<div className={'vertical-inline'} style={{borderBottom: '1px solid rgba(100,100,100,0.2)', width: '100%'}}>
															<Button className={'w-100'}>
																<div className={'margin-10 vertical-inline w-100'}>
																	<img className={'v-center margin-15-R'} src={TicketSvg} alt="add" width={85} height={60} style={{opacity: 0.7}}/>
																	
																	<div className={'v-center text-left w-100'}>
																		<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																			<Grid container spacing={1} alignItems={"flex-start"}>
																				<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						Usuario Asignado
																					</Typography>
																					<Typography variant={'h6'} className={'text-gray px-12'}>
																						{this.ListaUsuarioxid(item.id_usuario_asignado)}
																					</Typography>
																				</Grid>
																				<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						Prioridad
																					</Typography>
																					<Typography variant={'h6'} className={'text-gray px-12'}>
																						{this.ListaPrioridadxid(item.id_cat_prioridad)}
																					</Typography>
																				</Grid>
																				<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						Empresa(s) Relacionada(s)
																					</Typography>
																					<Typography variant={'h6'} className={'text-gray px-12'}>
																						{/*{this.ListaEmpresaxid(item.id_empresa)}*/}
																						{item.empresas.map((item, key) => (
																							<div className={'vertical-inline b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
																								<div className={'padding-3 padding-10-L padding-10-R v-center text-white'}>
																									{item.nombre_comercial}
																								</div>
																							</div>
																						))
																						}
																					</Typography>
																				</Grid>
																				{!this.is_view() ? (
																					<Grid item className={'text-right'} xs={12} sm={3} md={3} lg={3} xl={3}>
																						<Tooltip
																							TransitionComponent={Zoom}
																							placement={"top"}
																							title="Eliminar"
																							children={(
																								<Fab
																									size="small"
																									aria-label="Eliminar"
																									onClick={() => this.delete(item, key)}
																									style={{
																										marginLeft: "10px",
																										backgroundColor: "transparent",
																										color: "#808080",
																										boxShadow: "none",
																									}}
																									children={(
																										<DeleteOutlined/>
																									)}
																								/>
																							)}
																						/>
																						{this.state.key === key ? (
																							<Tooltip
																								TransitionComponent={Zoom}
																								placement={"top"}
																								title="Asignacion en edición"
																								children={(
																									<Fab
																										size="small"
																										aria-label="Editar"
																										onClick={() => this.edit(item, key)}
																										style={{
																											marginLeft: "10px",
																											backgroundColor: "#f50057",
																											color: "white",
																										}}
																										children={(
																											<EditOutlined/>
																										)}
																									/>
																								)}
																							/>
																						) : (
																							<Tooltip
																								TransitionComponent={Zoom}
																								placement={"top"}
																								title="Editar Asignación"
																								children={(
																									<Fab
																										size="small"
																										aria-label="Editar"
																										onClick={() => this.edit(item, key)}
																										style={{
																											marginLeft: "10px",
																											backgroundColor: "transparent",
																											color: "#808080",
																											boxShadow: "none",
																										}}
																										children={(
																											<EditOutlined/>
																										)}
																									/>
																								)}
																							/>
																						)}
																					</Grid>
																				) : null}
																				<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						Fecha Solicitud
																					</Typography>
																					<Typography variant={'h6'} className={'text-gray px-12'}>
																						{item.fecha_solicitud ? item.fecha_solicitud : 'sin registrar'}
																					</Typography>
																				</Grid>
																				<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						Fecha Notificación
																					</Typography>
																					<Typography variant={'h6'} className={'text-gray px-12'}>
																						{item.fecha_notificacion ? item.fecha_notificacion : 'sin registrar'}
																					</Typography>
																				</Grid>
																				<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						Frecuencia Notificación
																					</Typography>
																					<Typography variant={'h6'} className={'text-gray px-12'}>
																						{item.ticket_frecuencia_notificacion ? item.ticket_frecuencia_notificacion : 'sin registrar'}
																					</Typography>
																				</Grid>
																				<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						Documento(s) Anexo
																					</Typography>
																					<div className={'vertical-inline'}>
																						{item.documentos ? (
																							<Fragment>
																								{item.documentos.map((item, key) => (
																									<div className={'vertical-inline b-r-20 margin-5 margin-0-L'} key={key}>
																										<img className={'v-center cursor-pointer'} src={DocSvg} alt="add" width={40} height={40} style={{opacity: 0.7, marginRight: '15px'}}
																										     onClick={() => this.descargarZip(item.archivo)}/>
																									</div>
																								))
																								}
																								{item.documentos.length <= 0 ? 'Sin registrar' : ''}
																							</Fragment>
																						):null}
																					</div>
																				</Grid>
																				<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						URL Anexo
																					</Typography>
																					<Typography variant={'h6'} className={'text-gray px-12'}>
																						{item.url_ticket_requerimiento ? item.url_ticket_requerimiento : 'Sin registrar'}
																					</Typography>
																				</Grid>
																				
																				{/*<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						Notificar a:
																					</Typography>
																					<Typography variant={'h6'} className={'text-gray px-12'}>
																						{item.requerimiento}
																					</Typography>
																				</Grid>*/}
																				{this.state.usuarios_notificados.length > 0 ? (
																					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																						<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
																							Usuarios Notificados:
																						</Typography>
																						
																						{this.state.usuarios_notificados.map((item, key) => (
																							<div key={key} className={'vertical-inline margin-15-R'}>
																								<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
																								     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
																								</div>
																								<Typography variant={'h5'} className={'v-center padding-15-L margin-0 text-black px-13'}>
																									{item.nombre_completo}
																								</Typography>
																							</div>
																						))}
																					</Grid>
																				):null}
																				<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						Requerimiento
																					</Typography>
																					<Typography variant={'h6'} className={'text-gray px-12'}>
																						{item.requerimiento}
																					</Typography>
																				</Grid>
																				{this.is_view() ? item.observaciones.length > 0 ? (
																					<Fragment>
																						<Divider/>
																						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																							{item.observaciones.map((value, key) => (
																								<Fragment>
																									<ListItem
																										alignItems="flex-center"
																										style={{padding: 0}}>
																										<ListItemAvatar>
																											<Avatar
																												alt={value.nombre_completo_usuario}
																												src={CONFIG.foto_default}
																												style={{
																													height: '30px',
																													width: '30px',
																													marginTop: '2px'
																												}}
																											/>
																										</ListItemAvatar>
																										<ListItemText
																											style={{
																												fontSize: '12px !important',
																												margin: 0,
																												padding: 0
																											}}
																											primary={<Typography type="body2" style={{fontSize: '10px', margin: 0, padding: 0}}>
																												{value.nombre_completo_usuario}</Typography>}
																											secondary={
																												<Fragment>
																													<Typography
																														component="span"
																														variant="body2"
																														color="textPrimary"
																														style={{fontSize: '10px'}}
																													>
																														Observación:
																													</Typography>
																													{" — " + value.observacion}
																												</Fragment>
																											}
																										/>
																									</ListItem>
																									<Divider/>
																								</Fragment>
																							))}
																						</Grid>
																					</Fragment>
																				) : null : null}
																			
																			</Grid>
																		</Grid>
																	</div>
																</div>
															</Button>
														</div>
													</Grid>
												</Fragment>
											)) : (
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-center'}>
													<VistaVacia numero={0} height={'100px'} width={'100px'}
													            padding={'30px'}
													            mensaje={'Sin requerimientos registrados.'}/>
												
												</Grid>
											)}
										</Fragment>)
									}
								</Grid>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									{!this.is_view() ? "Cancelar" : "Cerrar"}
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() && this.state.id_cat_area_solucion ? (
									<Button onClick={() => this.save()} color="primary">
										{this.is_edit() > 0 ? 'Actualizar' : 'Guardar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalTickets.propTypes = {
	id_ticket: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	classes: PropTypes.object.isRequired,
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};


export default withStyles(styles)(ModalTickets);
