import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {CONFIG} from "../../../../settings/Config/Config";
import {Dropbox} from 'dropbox';
import {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {CargaCFDIService} from "../../../../services/CargaCFDIService/CargaCFDIService";
import Typography from "@material-ui/core/Typography";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {TimbradoFacturacionExtService} from "../../../../services/TimbradoFacturacionExtService/TimbradoFacturacionExtService";
import {ReactMultiEmail} from "react-multi-email";


class ModalEnviarEmailCFDI extends Component {
	
	state = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo,
			id_venta: (props.item || []).id_venta,
			id_facturacion_cfdi: (props.item || '').id_facturacion_cfdi,
			id_facturacion_cfdi_parcial: (props.item || '').id_facturacion_cfdi_parcial,
			id_cliente: (props.item || '').id_cliente,
			uuid: (props.item || '').uuid,
			enviar_a: [],
			enviar_cc: [],
			enviar_cco: [],
		};
	}
	
	ListaCliente = (id_cliente) => {
		return new Promise((resolve, reject) => {
			CatService.ListClienteFac(id_cliente).then((response) => {
				resolve(response);
			}).catch((error) => {
				this.props.showSnackBars('error', "12" + error.mensaje);
				reject(error);
			});
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		console.log("THIS.STATE: ", this.state);
		
		try {
			
			if (!this.state.enviar_a) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Enviar a.'
				})
			}
			
			TimbradoFacturacionExtService.EnviarCFDI(this.state).then((response) => {
				this.closeModal();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	add = () => {
		const {item, tipo} = this.props;
		console.log(this.props);
		this.setState({
			tipo: tipo,
			id_venta: (item || []).id_venta,
			id_facturacion_cfdi: (item || '').id_facturacion_cfdi,
			id_facturacion_cfdi_parcial: (item || '').id_facturacion_cfdi_parcial,
			id_cliente: (item || '').id_cliente,
			uuid: (item || '').uuid,
			enviar_a: [],
			enviar_cc: [],
			enviar_cco: []
		});
		this.ListaCliente(item.id_cliente).then((response) => {
			console.log("ListaCliente >>>> ", response);
			this.setState({
				enviar_a: response.data[0].correo_electronico || [],
				enviar_cc:  response.data[0].correo_electronico_copia || []
			});
		});
			this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			//abreviatura: item.abreviatura || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	getBase64 = (e, dropzone) => {
		return new Promise((resolve) => {
			const accessToken = CONFIG.DROPBOX_TOKEN;
			let files;
			if (dropzone) {
				files = e;
			} else {
				console.log(e.target);
				files = e.target.files;
			}
			
			const dbx = new Dropbox({
				accessToken,
				fetch
			});
			console.log("DROPBOX: ", dbx, files[0], files[0].name);
			dbx.filesUpload({path: '/' + files[0].name, contents: files[0]})
				.then(function (response) {
					console.log("DROPBOX UPLOAD: ", response);
					resolve(true);
				})
				.catch(function (error) {
					console.error(error);
				});
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	ListaCancelacionCFDI = () => {
		CatService.ListCancelacionCFDIFac().then((response) => {
			this.setState({
				listar_cat_cancelacion_cfdi: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_cancelacion_cfdi: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	setEmails = (emails) => {
		this.setState({enviar_a: emails});
	}
	
	setEmailsCC = (emails) => {
		this.setState({enviar_cc: emails});
	}
	
	setEmailsCCO = (emails) => {
		this.setState({enviar_cco: emails});
	}
	
	render() {
		
		const filterOptions = createFilterOptions({
			matchFrom: 'start',
			stringify: option => option.rfc,
		});
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Enviar CFDI
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<fieldset className={'borde-punteado-gray-2 text-left'}>
									<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Datos de envío</legend>
									
									<Grid container spacing={2}>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
											<ReactMultiEmail
												placeholder='Enviar a: '
												emails={this.state.enviar_a}
												onChange={(_emails) => {
													this.setEmails(_emails);
												}}
												autoFocus={false}
												getLabel={(email, index, removeEmail) => {
													return (
														<div data-tag key={index}>
															<div data-tag-item>{email}</div>
															<span data-tag-handle onClick={() => removeEmail(index)}>
															                ×
															              </span>
														</div>
													);
												}}
											/>
											{/*<TextField
												required
												name="enviar_a"
												label="Enviar a: "
												type="text"
												fullWidth
												value={this.state.enviar_a}
												onChange={this.handleChange}
											/>*/}
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<ReactMultiEmail
												placeholder='Cc: '
												emails={this.state.enviar_cc}
												onChange={(_emails) => {
													this.setEmailsCC(_emails);
												}}
												autoFocus={false}
												getLabel={(email, index, removeEmail) => {
													return (
														<div data-tag key={index}>
															<div data-tag-item>{email}</div>
															<span data-tag-handle onClick={() => removeEmail(index)}>
															                ×
															              </span>
														</div>
													);
												}}
											/>
											{/*<TextField
												name="enviar_cc"
												label="Cc:"
												type="text"
												fullWidth
												value={this.state.enviar_cc}
												onChange={this.handleChange}
											/>*/}
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<ReactMultiEmail
													placeholder='Cco: '
													emails={this.state.enviar_cco}
													onChange={(_emails) => {
														this.setEmailsCCO(_emails);
													}}
													autoFocus={false}
													getLabel={(email, index, removeEmail) => {
														return (
															<div data-tag key={index}>
																<div data-tag-item>{email}</div>
																<span data-tag-handle onClick={() => removeEmail(index)}>
															                ×
															              </span>
															</div>
														);
													}}
												/>
											</Grid>
										</Grid>
									
									</Grid>
								
								</fieldset>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close} color="primary">
								Cerrar
							</Button>
							<Button onClick={this.save} color="primary">
								Confirmar
							</Button>
						</Fragment>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalEnviarEmailCFDI.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalEnviarEmailCFDI;
