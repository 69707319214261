import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {LineaCapturaService} from '../../../../services/LineaCapturaService/LineaCapturaService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";
import ModalEmpresa from "../../Cat/Empresa/Includes/ModalEmpresa";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {Business, DeleteOutlined, Group, HdrStrong, SpeakerNotes, Sync} from "@material-ui/icons";
import {DropzoneArea} from "material-ui-dropzone";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import DocSvg from "../../../../assets/img/icons/doc.svg";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import moment from "moment";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";


class ModalLineaCaptura extends Component {
	
	Usr = {};
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_linea_captura: (props.item || '').id_linea_captura,
			tipo: props.tipo,
			id_empresa: '',
			ejercicio: '',
			periodo: '',
			id_cat_tipo_impuesto: '',
			id_cat_tipo_declaracion: '',
			observaciones: '',
			estatus: '',
			fecha_vigencia: null,
			files: [],
			archivos: [],
			importe: '',
			id_cat_linea_captura_impuesto: '',
			check_recargo: false,
			recargo: '',
			
			lista_empresa: this.props.ListEmpresa || [],
			lista_ejercicio: [],
			lista_periodo: [
				{mes: 'Enero'},
				{mes: 'Febrero'},
				{mes: 'Marzo'},
				{mes: 'Abril'},
				{mes: 'Mayo'},
				{mes: 'Junio'},
				{mes: 'Julio'},
				{mes: 'Agosto'},
				{mes: 'Septiembre'},
				{mes: 'Octubre'},
				{mes: 'Noviembre'},
				{mes: 'Diciembre'}
			],
			lista_impuesto: [],
			lista_tipo_impuesto: [],
			lista_tipo_declaracion: [],
			impuestos: [],
			
			archivo_base64: '',
			archivo_base64Tipo: '',
			archivo: '',
			archivo_formato: '',
			archivo_nombre: '',
		};
		
	}
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	ListEjercicio = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEjercicio().then((response) => {
				this.setState({
					lista_ejercicio: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	ListImpuesto = () => {
		return new Promise((resolve, reject) => {
			CatService.ListLineaCapturaImpuesto().then((response) => {
				this.setState({
					lista_impuesto: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	ListTipoImpuesto = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoImpuesto().then((response) => {
				this.setState({
					lista_tipo_impuesto: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	ListDeclaracion = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoDeclaracion().then((response) => {
				this.setState({
					lista_tipo_declaracion: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	RefreshListEmpresa = (id_empresa) => {
		this.props.ListEmpresa().then((response) => {
			this.setState({
				id_empresa: id_empresa
			});
		});
	};
	
	darformato = (file) => {
		return new Promise((resolve, reject) => {
			let formatos = ["text/xml", ".csv", "text/csv", "application/xlsx", "application/pdf", "application/xls", "application/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
			
			let fil = {files: [file]};
			FileBase64.Base64(fil, formatos, this.props.MegaByte || null, true).then((response) => {
				//this.props.onChange(response);
				this.handleChange({
					target: {
						name: 'archivo_base64',
						value: response.base64
					}
				});
				this.handleChange({
					target: {
						name: 'archivo_base64Tipo',
						value: response.base64Tipo
					}
				});
				this.handleChange({
					target: {
						name: 'archivo',
						value: response.archivo
					}
				});
				this.handleChange({
					target: {
						name: 'archivo_formato',
						value: response.formato
					}
				});
				this.handleChange({
					target: {
						name: 'archivo_nombre',
						value: response.name
					}
				});
				resolve(true);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
				reject();
			});
		});
	}
	
	popupRepetido = (error) => {
		let msg = `Al parecer esta línea de captura ya fue cargada anteriormente y se le asignó el ID: ${error.data}; si está seguro de que no se trata de duplicidad, dé click en el botón "CONTINUAR".`;
		
		PopupService.Confirm(['Cancelar', 'Continuar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Continuar') {
				LineaCapturaService.Agregar(this.state, true).then((response) => {
					this.props.RefreshList();
					this.modalClose();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
			
		});
	}
	
	save = () => {
		
		try {
			
			if (!FieldsJs.Field(this.state.id_empresa)) {
				throw Object({
					status: false, mensaje: 'Campo empresa es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.ejercicio)) {
				throw Object({
					status: false, mensaje: 'Campo ejercicio es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.periodo)) {
				throw Object({
					status: false, mensaje: 'Campo periodo es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.id_cat_tipo_impuesto)) {
				throw Object({
					status: false, mensaje: 'Campo tipo impuesto es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.id_cat_tipo_declaracion)) {
				throw Object({
					status: false, mensaje: 'Campo tipo declaración es requerido.'
				})
			}
			if (this.state.check_recargo && !FieldsJs.Field(this.state.recargo)) {
				throw Object({
					status: false, mensaje: 'Campo recargo / actualización es requerido.'
				})
			}
			
			if (this.state.files.length > 0) {
				
				this.darformato(this.state.files[0]).then(() => {
					if (this.state.id_linea_captura > 0) {
						LineaCapturaService.Modificar(this.state).then((response) => {
							this.props.RefreshList();
							this.modalClose();
							hideSpinner('spinner', 500);
							this.props.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.props.showSnackBars('error', error.mensaje);
						});
					} else {
						LineaCapturaService.Agregar(this.state).then((response) => {
							this.props.RefreshList();
							this.modalClose();
							hideSpinner('spinner', 500);
							this.props.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							if (Number(error.response.status) === 401) {
								this.popupRepetido(error.response);
							}
							
							this.props.showSnackBars('error', error.mensaje);
						});
					}
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			} else {
				if (this.state.id_linea_captura > 0) {
					LineaCapturaService.Modificar(this.state).then((response) => {
						this.props.RefreshList();
						this.modalClose();
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				} else {
					LineaCapturaService.Agregar(this.state).then((response) => {
						this.props.RefreshList();
						this.modalClose();
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						console.log(error);
						
						if (Number(error.response.status) === 401) {
							this.popupRepetido(error);
						}
						
						this.props.showSnackBars('error', error.mensaje);
					});
				}
			}
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		this.props.ListEmpresa();
		this.ListImpuesto();
		this.ListTipoImpuesto();
		this.ListDeclaracion();
		this.ListEjercicio();
		
		this.setState({
			id_linea_captura: '',
			id_empresa: '',
			ejercicio: '',
			periodo: '',
			estatus: '',
			id_cat_tipo_impuesto: '',
			id_cat_tipo_declaracion: '',
			observaciones: '',
			importe: '',
			check_recargo: false,
			recargo: '',
			id_cat_linea_captura_impuesto: '',
			files: [],
			archivos: [],
			impuestos: [],
			fecha_vigencia: null,
			archivo_base64: '',
			archivo_base64Tipo: '',
			archivo: '',
			archivo_formato: '',
			archivo_nombre: '',
			activo: true,
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		this.props.ListEmpresa().then((response) => {
			this.setState({
				id_empresa: item.id_empresa || '',
			});
		});
		this.ListImpuesto().then((response) => {
			this.setState({
				id_cat_linea_captura_impuesto: item.id_cat_linea_captura_impuesto || '',
			});
		});
		this.ListTipoImpuesto().then((response) => {
			this.setState({
				id_cat_tipo_impuesto: item.id_cat_tipo_impuesto || '',
			});
		});
		this.ListDeclaracion().then((response) => {
			this.setState({
				id_cat_tipo_declaracion: item.id_cat_tipo_declaracion || '',
			});
		});
		this.ListEjercicio().then((response) => {
			this.setState({
				ejercicio: item.ejercicio || '',
			});
		});
		
		this.setState({
			id_linea_captura: item.id_linea_captura || '',
			importe: item.importe || '',
			check_recargo: item.recargo > 0,
			recargo: item.recargo || '',
			periodo: item.periodo || '',
			estatus: item.estatus || '',
			observaciones: item.observaciones || '',
			//files: [],
			archivos: item.archivos || [],
			impuestos: item.impuestos || [],
			archivo_base64: '',
			archivo_base64Tipo: '',
			archivo: '',
			archivo_formato: '',
			archivo_nombre: '',
			fecha_vigencia: item.fecha_vigencia ? moment(item.fecha_vigencia) : null,
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		this.props.ListEmpresa().then((response) => {
			this.setState({
				id_empresa: item.id_empresa || '',
			});
		});
		this.ListImpuesto().then((response) => {
			this.setState({
				id_cat_linea_captura_impuesto: item.id_cat_linea_captura_impuesto || '',
			});
		});
		this.ListTipoImpuesto().then((response) => {
			this.setState({
				id_cat_tipo_impuesto: item.id_cat_tipo_impuesto || '',
			});
		});
		this.ListDeclaracion().then((response) => {
			this.setState({
				id_cat_tipo_declaracion: item.id_cat_tipo_declaracion || '',
			});
		});
		this.ListEjercicio().then((response) => {
			this.setState({
				ejercicio: item.ejercicio || '',
			});
		});
		
		this.setState({
			id_linea_captura: item.id_linea_captura || '',
			importe: item.importe || '',
			check_recargo: item.recargo > 0,
			recargo: item.recargo || '',
			periodo: item.periodo || '',
			estatus: item.estatus || '',
			observaciones: item.observaciones || '',
			//files: [],
			archivos: item.archivos || [],
			impuestos: item.impuestos || [],
			activo: (item.activo === 1),
			archivo_base64: '',
			archivo_base64Tipo: '',
			archivo: '',
			archivo_formato: '',
			archivo_nombre: '',
			fecha_vigencia: item.fecha_vigencia ? moment(item.fecha_vigencia) : null,
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_linea_captura > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	descargarZip = (path) => {
		console.log("PATH ::: ", path);
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
	};
	
	addImpuesto = () => {
		try {
			
			if (!FieldsJs.Field(this.state.id_cat_linea_captura_impuesto)) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("El campo impuesto es requerido.")
				});
			}
			
			let imp = FieldsJs.Copy(this.state.lista_impuesto);
			let impuestos = FieldsJs.Copy(this.state.impuestos);
			
			let bandimpuesto = false;
			
			for (let i = 0; i < impuestos.length; i++) {
				if (Number(this.state.id_cat_linea_captura_impuesto) === Number(impuestos[i].id_cat_linea_captura_impuesto)) {
					bandimpuesto = true;
				}
			}
			
			if (bandimpuesto) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("El impuesto ya existe.")
				});
			}
			
			for (let i = 0; i < imp.length; i++) {
				if (Number(this.state.id_cat_linea_captura_impuesto) === Number(imp[i].id_cat_linea_captura_impuesto)) {
					impuestos.push({id_cat_linea_captura_impuesto: imp[i].id_cat_linea_captura_impuesto, linea_captura_impuesto: imp[i].linea_captura_impuesto});
				}
			}
			
			this.setState({
				impuestos: impuestos,
				id_cat_linea_captura_impuesto: ''
			});
			
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	}
	
	eliminarImpuesto = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.impuestos);
		arr_temp.splice(key, 1);
		this.setState({
			impuestos: arr_temp
		});
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<Grid>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Linea Captura</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2} alignItems={"flex-end"}>
							
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-50-px padding-10-R'}>
										<ModalEmpresa
											tipo={'add'}
											item={{}}
											componente={
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Agregar empresa">
													<Fab size="small" color="primary" aria-label="Agregar empresa">
														<Add/>
													</Fab>
												</Tooltip>
											}
											RefreshList={this.RefreshListEmpresa}
											showSnackBars={this.props.showSnackBars}
										/>
									</Grid>
									<Grid item className={'w-30-px'}>
										<Business className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-80-px'}>
										<FormControl fullWidth required>
											<InputLabel>Empresa</InputLabel>
											<Select
												native
												value={this.state.id_empresa}
												onChange={this.handleChange}
												name="id_empresa"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.props.lista_empresa.map(option => (
													<option key={option.id_empresa}
													        value={option.id_empresa}>
														{option.nombre_comercial}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrong className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Ejercicio</InputLabel>
											<Select
												native
												value={this.state.ejercicio}
												onChange={this.handleChange}
												name="ejercicio"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_ejercicio.map(option => (
													<option key={option.ejercicio}
													        value={option.ejercicio}>
														{option.ejercicio}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrong className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Periodo</InputLabel>
											<Select
												native
												value={this.state.periodo}
												onChange={this.handleChange}
												name="periodo"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_periodo.map(option => (
													<option key={option.mes}
													        value={option.mes}>
														{option.mes}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							
							</Grid>
							
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrong className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Tipo Impuesto</InputLabel>
											<Select
												native
												value={this.state.id_cat_tipo_impuesto}
												onChange={this.handleChange}
												name="id_cat_tipo_impuesto"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_tipo_impuesto.map(option => (
													<option key={option.id_cat_tipo_impuesto}
													        value={option.id_cat_tipo_impuesto}>
														{option.abreviatura + '-' + option.tipo_impuesto}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrong className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-120-px'}>
										<FormControl fullWidth required>
											<InputLabel>Impuesto</InputLabel>
											<Select
												native
												value={this.state.id_cat_linea_captura_impuesto}
												onChange={this.handleChange}
												name="id_cat_linea_captura_impuesto"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_impuesto.map(option => (
													<option key={option.id_cat_linea_captura_impuesto}
													        value={option.id_cat_linea_captura_impuesto}>
														{option.linea_captura_impuesto}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item className={'w-90-px'}>
										<Button onClick={() => this.addImpuesto()} variant="contained" color="primary" size={'small'}>
											{'Agregar'}
										</Button>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item className={'text-center'} xs={12} sm={12} md={4} lg={4} xl={4}>
								{this.state.impuestos.length > 0 ? (
									<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<div className={'w-100 text-center'}>
											{this.state.impuestos.map((item, key) => (
												<div className={'vertical-inline b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
													<div className={'padding-3 padding-10-L padding-5-R v-center text-white'}>
														{item.linea_captura_impuesto}
													</div>
													<div className={'padding-3 padding-10-R v-center cursor-pointer text-white'}>
														{this.props.tipo === 'view' ? null : (
															<DeleteOutlined className={'px-18 text-white'} onClick={() => this.eliminarImpuesto(key)}/>
														)}
													</div>
												</div>
											))
											}
										</div>
									</Grid>
								) : (
									<Typography className={'padding-10-T padding-10-B px-14 text-center'} style={{color: 'rgba(0, 0, 0, 0.6)'}}>
										No se ha agregado el impuesto
									</Typography>
								)}
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								
								<Grid container spacing={1} alignItems={"flex-end"}>
									{/*<Grid item className={'w-50-px'}>
									
									</Grid>*/}
									<Grid item className={'w-30-px'}>
										<HdrStrong className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Tipo Declaración</InputLabel>
											<Select
												native
												value={this.state.id_cat_tipo_declaracion}
												onChange={this.handleChange}
												name="id_cat_tipo_declaracion"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_tipo_declaracion.map(option => (
													<option key={option.id_cat_tipo_declaracion}
													        value={option.id_cat_tipo_declaracion}>
														{option.abreviatura + '-' + option.tipo_declaracion}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<KeyboardDatePicker
									format="dd/MM/yyyy"
									fullWidth
									required
									clearable
									inputProps={{readOnly: true}}
									/*KeyboardButtonProps={{
										'aria-label': 'Fecha de pago',
									}}*/
									label="Vigencia"
									value={this.state.fecha_vigencia}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_vigencia');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									disabled={this.props.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									margin="none"
									name="importe"
									label="Importe"
									type="text"
									fullWidth
									value={this.state.importe}
									onChange={(e) => {
										this.handleChange(e);
									}}
									onKeyPress={EnteroSolo}
									inputProps={{maxLength: 5}}
									required={true}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												name='check_recargo'
												checked={this.state.check_recargo}
												onChange={this.handleChange}
												value="check_recargo"
												color="primary"
												disabled={this.props.tipo === 'view'}
											/>
										}
										label={'El pago incluye recargo o actualización?'}
									/>
								</FormGroup>
							</Grid>
							
							{this.state.check_recargo ? (
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
									<TextField
										margin="none"
										name="recargo"
										label="Accesorios"
										type="text"
										fullWidth
										value={this.state.recargo}
										onChange={(e) => {
											this.handleChange(e);
										}}
										onKeyPress={EnteroSolo}
										inputProps={{maxLength: 5}}
										required={true}
									/>
								</Grid>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									fullWidth
									multiline
									rows={3}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="observaciones"
									label="Observaciones"
									value={this.state.observaciones}
								/>
							</Grid>
							
							
							{this.state.archivos.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
										Documento(s) Anexo:
									</Typography>
									<div className={'vertical-inline'}>
										{this.state.archivos.map((item, key) => (
											<div>
												<div className={'vertical-inline b-r-20 margin-5 margin-0-L'} key={key}>
													<img className={'v-center cursor-pointer'} src={DocSvg} alt="add" width={40} height={40} style={{opacity: 0.7, marginRight: '5px'}} onClick={() => this.descargarZip(item.archivo)}/>
													
													
													{this.props.tipo === 'edit' ? (<div className={'vertical-inline v-end b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
														<div className={'padding-3 v-end text-white cursor-pointer'}>
															{this.props.tipo === 'view' ? null : (
																<DeleteOutlined className={'px-16 text-white'} onClick={() => this.eliminar_documento(key)}/>
															)
															}
														</div>
													</div>) : null}
												</div>
											
											
											</div>
										))}
									</div>
								</Grid>
							) : null}
							
							{this.props.tipo !== 'view' ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'dropzone-cronograma margin-15-T'}>
									<div className={'w-100'}>
										<DropzoneArea
											onChange={this.handleChangeDropzoneArea.bind(this)}
											open={this.state.open}
											filesLimit={3}
											dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
											acceptedFiles={[]}
											//acceptedFiles={['application/pdf']}
											showPreviews={false}
											maxFileSize={5000000}
											showAlerts={false}
											showFileNames={true}
											showFileNamesInPreview={true}
										/>
									</div>
								</Grid>
							) : null}
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_linea_captura > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</Grid>
		);
	}
}

ModalLineaCaptura.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object,
	ultimoid: PropTypes.number,
	lista_empresa: PropTypes.array.isRequired,
	ListEmpresa: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalLineaCaptura);
