import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import {FichaTecnicaService} from "../../../../services/FichaTecnicaService/FichaTecnicaService";
import {ClientePotencialService} from '../../../../services/ClientePotencialService/ClientePotencialService';
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import moment from "moment";
import {CONFIG} from "../../../../settings/Config/Config";


import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import Fab from "@material-ui/core/Fab";
import {CheckOutlined, DateRangeOutlined, PlaylistAddOutlined, SyncOutlined} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import {EmpresaService} from "../../../../services/_Cat/EmpresaService/EmpresaService";
import ModalEmpresa from "../../Cat/Empresa/Includes/ModalEmpresa";
import {FacturacionService} from "../../../../services/FacturacionService/FacturacionService";
import ModalClaveProductoServicio from "../../Cat/ClaveProductoServicio/Includes/ModalClaveProductoServicio";
import {ClaveProductoServicioService} from "../../../../services/_Cat/ClaveProductoServicioService/ClaveProductoServicioService";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {CatService} from "../../../../services/_Cat/CatService/CatService";

class ClientesPagadorasFacturadoras extends Component {
	
	state = {};
	
	Usr = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			id_cliente: props.id_cliente,
			datos_cliente: props.item,
			
			cliente: {},
			
			id_ficha_tecnica: '',
			id_cat_ficha_tecnica_status: '',
			
			actividad_ante_sat: '',
			rfc: '',
			servicio_requerido_propuesto: '',
			ficha_tecnica_archivo_elaboracion_propuesta: [],
			ficha_tecnica_archivo_integracion_expediente: [],
			ficha_tecnica_archivo_propuesta_fiscal: [],
			
			id_plaza_pertenece_cliente: '',
			id_plaza_opera_nomina: '',
			
			sueldo_salario: false,
			cooperativa: false,
			honorarios: false,
			sociedad_civil: false,
			asimilados_salario: false,
			outsourcing: false,
			otros: '',
			
			id_cat_periodo_pago: '',
			impuesto_sobre_nomina: 0,
			
			otros_impuestos: false,
			otros_impuestos_nombre: '',
			otros_impuestos_porcentaje: '',
			
			ficha_tecnica_otro_impuesto: [],
			
			id_cat_factor_riesgo_imss: '',
			id_cat_estado_entidad_trabajo: '',
			id_cat_municipio_entidad_trabajo: '',
			
			cooperativa_alta_imss: false,
			cooperativa_porcentaje_comision_paga_trabajador: '',
			
			trabajador_entrega_recibo_honorarios: false,
			pago_realiza_fuera_es_no_deducible: false,
			outsourcing_porcentaje_paga_servicio: '',
			asimilados_hace_retencion_isr: false,
			tienen_otras_prestaciones_aparte_salario: false,
			
			vacaciones_ley_o_especiales_cuantos_dias: '',
			prima_vacacional_ley_o_especiales_cuantos_dias: '',
			gratificacion_anual_ley_o_especiales_cuantos_dias: '',
			
			ficha_tecnica_prestacion: [],
			
			comentario_adicional_elaboracion_propuesta: '',
			
			lista_cat_factor_riesgo_imss: [],
			lista_cat_periodo_pago: [],
			lista_plazas: [],
			lista_cat_estado: [],
			lista_cat_municipio: [],
			lista_cat_banco: [],
			lista_cat_promotores: [],
			
			cliente_cancelo: false,
			cliente_acepto: false,
			cliente_requiere_nueva_propuesta: false,
			porque_requiere_nueva_propuesta: '',
			
			fecha_firma_contrato: null,
			
			constancia_de_situacion_fiscal_base64: '',
			constancia_de_situacion_fiscal_formato: '',
			constancia_de_situacion_fiscal: '',
			
			comprobante_de_domicilio_base64: '',
			comprobante_de_domicilio_formato: '',
			comprobante_de_domicilio: '',
			
			acta_constitutiva_de_la_cliente_base64: '',
			acta_constitutiva_de_la_cliente_formato: '',
			acta_constitutiva_de_la_cliente: '',
			
			poder_del_representante_legal_base64: '',
			poder_del_representante_legal_formato: '',
			poder_del_representante_legal: '',
			
			identificacion_del_repesentante_legal_base64: '',
			identificacion_del_repesentante_legal_formato: '',
			identificacion_del_repesentante_legal: '',
			
			fecha_alta_imss: null,
			confirmacion_fecha_primera_dispersion: null,
			id_cat_periodo_pago_tipo_nomina: '',
			comision_propuesta: '',
			front_nombre_banco: '',
			front_cantidad_empleado: '',
			ficha_tecnica_cantidad_empleado_banco: [],
			iva: '',
			retencion_isr_en_caso_de_ias: '',
			retencion_isn_en_caso_de_ias: '',
			
			
			lista_recursos_humanos: [],
			lista_ejecutivo_asignado_tesoreria: [],
			id_ejecutivo_asignado_tesoreria: '',
			
			cat_tipo_pago: [],
			cliente_que_paga: [],
			ep_cliente_que_paga: '',
			ep_tipo_pago: '',
			ep_id_ejecutivo_asignado: '',
			// ep_id_cat_tipo_pago: '',
			
			cliente_que_factura: [],
			ep_cliente_que_factura: '',
			ep_porcentaje: '',
			
			/* J U R I D I C O */
			total_contratos_individuales: '',
			total_contratos_individuales_elaborados: '',
			total_contratos_individuales_pendientes: '',
			
			cantidad_elaborado: '',
			
			excel_elaborado_base64: '',
			excel_elaborado_base64Tipo: '',
			excel_elaborado_archivo: '',
			excel_elaborado_formato: '',
			
			excel_pendiente_base64: '',
			excel_pendiente_base64Tipo: '',
			excel_pendiente_archivo: '',
			excel_pendiente_formato: '',
			
			comentario_general: '',
			
			cliente_contratos_individuales: [],
			
			comentario_juridico_contratos_individuales: '',
			
			cliente_agendar_firma_contrato: [],
			
			
			concepto_de_facturacion: '',
			id_cat_banco_deposita_cliente: '',
			id_cat_banco_depositos_personal: '',
			datos_especiales_para_la_operacion: '',
			promotor_id_usuario_nombre_completo: '',
			promotor_no_cuenta_clabe_interbancaria: '',
			promotor_id_cat_banco: '',
			promotor_comision: '',
			promotor_telefono: '',
			promotor_celular: '',
			promotor_correo_electronico: '',
			promotor_comentarios_especiales_manejo_cuenta_cliente: '',
			
			
			notas: false, // MOSTRAR BLOQUE DE NOTAS PARA EL DESARROLLADOR
			mostrar_notas_desarrollador: false, // MOSTRAR PARA EL DESAROOLLADOR
			comentario_observacion_desarrollador: '', // COMENTARIO PARA EL DESAROOLLADOR
			ficha_tecnica_historial_accion_usuario: [], // HISTORIAL PARA EL DESAROOLLADOR
			
			siroc_active: false,
			siroc: false,
			
			listar_empresa: [],
			listar_clave_producto_servicio: [],
			ids_empresa_eliminada: [],
			
			fecha_inicio_empresa_pagan: null,
			fecha_fin_empresa_pagan: null,
			fecha_inicio_empresa_factura: null,
			fecha_fin_empresa_factura: null,
			
			
		};
		
		
		console.log('|||||||||||', props, this.state.datos_cliente);
	}
	
	RefreshListEmpresa = (id_empresa) => {
		if (id_empresa > 0) {
			this.setState({
				ep_cliente_que_paga: id_empresa
			})
			this.ListaEmpresa();
		}
	};
	
	RefreshListEmpresa2 = (id_empresa) => {
		if (id_empresa > 0) {
			this.setState({
				ef_cliente_que_factura: id_empresa
			})
			this.ListaEmpresa();
		}
	};
	
	ListaEmpresa = () => {
		CatService.ListEmpresa({}, {}).then((response) => {
			this.setState({
				listar_empresa: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_empresa: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaClaveProductoServicio = () => {
		ClaveProductoServicioService.Listar({}, {}).then((response) => {
			this.setState({
				listar_clave_producto_servicio: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_clave_producto_servicio: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	RefreshListClaveProductoServicio = (ef_clave_producto_o_servicio) => {
		if (ef_clave_producto_o_servicio > 0) {
			this.setState({
				ef_clave_producto_o_servicio: ef_clave_producto_o_servicio
			})
		}
		this.ListaClaveProductoServicio();
		// this.ListaClaveUnidadMedida();
		// setTimeout(() => this.colocarValoresEnCampos(ef_clave_producto_o_servicio), 1000);
	};
	/*colocarValoresEnCampos = (id) => {
		let id_cat_clave_producto_servicio = Number(id);
		let id_cat_clave_unidad_medida = '';
		let producto_servicio = '';
		let numero_identificacion = '';
		for (let i = 0; i < this.state.listar_clave_producto_servicio.length; i++) {
			if (Number(this.state.listar_clave_producto_servicio[i].id_cat_clave_producto_servicio) === Number(id_cat_clave_producto_servicio)) {
				id_cat_clave_unidad_medida = this.state.listar_clave_producto_servicio[i].id_cat_clave_unidad_medida;
				producto_servicio = this.state.listar_clave_producto_servicio[i].producto_servicio;
				numero_identificacion = this.state.listar_clave_producto_servicio[i].numero_identificacion;
			}
		}
		this.setState({
			ef_clave_producto_o_servicio: id_cat_clave_unidad_medida,
			descripcion_concepto: producto_servicio,
			numero_identificacion: numero_identificacion
		});
	};*/
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	handleChangeEmpresa = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => {
			this.setState({
				[r.name]: r.value
			})
			
			for (let i = 0; i < this.state.listar_empresa.length; i++) {
				if (Number(this.state.listar_empresa[i].id_empresa) === Number(r.value)) {
					this.setState({
						nombre_comercial: this.state.listar_empresa[i].nombre_comercial
					})
				}
			}
		})
	};
	
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	init = () => {
		
		this.ListaEmpresa();
		this.ListaClaveProductoServicio();
		
		return new Promise((resolve, reject) => {
			FichaTecnicaService.InitDato(this.state.id_cliente).then((response) => {
				
				let dato = FieldsJs.Copy(response.data);
				
				if (response.visible === 0) {
					
					this.setState({
						visible: 0,
						mensaje: response.message
					});
					
				} else {
					
					let cliente = dato.cliente || {};
					let id_ejecutivo_asignado_tesoreria = cliente.id_ejecutivo_asignado_tesoreria || '';
					let ficha_tecnica_prestacion = dato.ficha_tecnica_prestacion;
					let ft = dato.ficha_tecnica || {};
					let ficha_tecnica_archivo_elaboracion_propuesta = dato.ficha_tecnica_archivo_elaboracion_propuesta;
					let ficha_tecnica_archivo_integracion_expediente = dato.ficha_tecnica_archivo_integracion_expediente;
					let ficha_tecnica_archivo_propuesta_fiscal = dato.ficha_tecnica_archivo_propuesta_fiscal;
					let ficha_tecnica_otro_impuesto = dato.ficha_tecnica_otro_impuesto;
					let ficha_tecnica_historial_accion_usuario = dato.ficha_tecnica_historial_accion_usuario || [];
					let lista_recursos_humanos = dato.recursos_humanos || [];
					let lista_ejecutivo_asignado_tesoreria = dato.ejecutivo_asignado_tesoreria || [];
					let ficha_tecnica_cantidad_empleado_banco = dato.ficha_tecnica_cantidad_empleado_banco || [];
					let cliente_contratos_individuales = dato.cliente_contratos_individuales || [];
					let cliente_agendar_firma_contrato = dato.cliente_agendar_firma_contrato || [];
					let cat_tipo_pago = dato.cat_tipo_pago || [];
					
					let id_plaza_pertenece_cliente = '';
					if (FieldsJs.Array(dato.plaza_pertenece_cliente)) {
						id_plaza_pertenece_cliente = dato.plaza_pertenece_cliente.id_plaza;
					}
					
					let id_plaza_opera_nomina = '';
					if (FieldsJs.Array(dato.plaza_opera_nomina)) {
						id_plaza_opera_nomina = dato.plaza_opera_nomina.id_plaza;
					}
					
					for (let i = 0; i < ficha_tecnica_prestacion.length; i++) {
						var it = ficha_tecnica_prestacion[i];
						it.porcentaje = it.porcentaje || '';
						it.pago_efectivo = (it.pago_efectivo === 1);
						it.vales = (it.vales === 1);
						it.prestaciones = (it.prestaciones === 1);
						it.frecuencia = (it.frecuencia === 1);
						it.fijo_variable = it.fijo_variable || '';
						it.integra_sbc = (it.integra_sbc === 1);
					}
					
					this.setState({
						
						cliente: dato.cliente,
						
						id_ficha_tecnica: ft.id_ficha_tecnica ? Number(ft.id_ficha_tecnica) : '',
						id_cat_ficha_tecnica_status: ft.id_cat_ficha_tecnica_status ? Number(ft.id_cat_ficha_tecnica_status) : '',
						
						actividad_ante_sat: ft.actividad_ante_sat || '',
						rfc: ft.rfc || dato.cliente.rfc || '',
						servicio_requerido_propuesto: ft.servicio_requerido_propuesto || '',
						ficha_tecnica_archivo_elaboracion_propuesta: ficha_tecnica_archivo_elaboracion_propuesta || [],
						ficha_tecnica_archivo_integracion_expediente: ficha_tecnica_archivo_integracion_expediente || [],
						ficha_tecnica_archivo_propuesta_fiscal: ficha_tecnica_archivo_propuesta_fiscal || [],
						
						id_plaza_pertenece_cliente: id_plaza_pertenece_cliente || '',
						id_plaza_opera_nomina: id_plaza_opera_nomina || '',
						
						sueldo_salario: (ft.sueldo_salario === 1),
						cooperativa: (ft.cooperativa === 1),
						honorarios: (ft.honorarios === 1),
						sociedad_civil: (ft.sociedad_civil === 1),
						asimilados_salario: (ft.asimilados_salario === 1),
						outsourcing: (ft.outsourcing === 1),
						otros: ft.otros || '',
						
						id_cat_periodo_pago: ft.id_cat_periodo_pago || '',
						impuesto_sobre_nomina: ft.impuesto_sobre_nomina || 0,
						
						otros_impuestos: (ficha_tecnica_otro_impuesto.length > 0),
						otros_impuestos_nombre: '',
						otros_impuestos_porcentaje: '',
						
						ficha_tecnica_otro_impuesto: ficha_tecnica_otro_impuesto || [],
						
						id_cat_factor_riesgo_imss: ft.id_cat_factor_riesgo_imss || '',
						id_cat_estado_entidad_trabajo: ft.id_cat_estado_entidad_trabajo || '',
						id_cat_municipio_entidad_trabajo: ft.id_cat_municipio_entidad_trabajo || '',
						
						cooperativa_alta_imss: (ft.cooperativa_alta_imss === 1),
						cooperativa_porcentaje_comision_paga_trabajador: ft.cooperativa_porcentaje_comision_paga_trabajador || '',
						
						trabajador_entrega_recibo_honorarios: (ft.trabajador_entrega_recibo_honorarios === 1),
						pago_realiza_fuera_es_no_deducible: (ft.pago_realiza_fuera_es_no_deducible === 1),
						outsourcing_porcentaje_paga_servicio: ft.outsourcing_porcentaje_paga_servicio || '',
						asimilados_hace_retencion_isr: (ft.asimilados_hace_retencion_isr === 1),
						tienen_otras_prestaciones_aparte_salario: (ft.tienen_otras_prestaciones_aparte_salario === 1),
						
						vacaciones_ley_o_especiales_cuantos_dias: ft.vacaciones_ley_o_especiales_cuantos_dias || '',
						prima_vacacional_ley_o_especiales_cuantos_dias: ft.prima_vacacional_ley_o_especiales_cuantos_dias || '',
						gratificacion_anual_ley_o_especiales_cuantos_dias: ft.gratificacion_anual_ley_o_especiales_cuantos_dias || '',
						
						ficha_tecnica_prestacion: ficha_tecnica_prestacion || [],
						
						comentario_adicional_elaboracion_propuesta: ft.comentario_adicional_elaboracion_propuesta || '',
						
						lista_cat_factor_riesgo_imss: dato.cat_factor_riesgo_imss,
						lista_cat_periodo_pago: dato.cat_periodo_pago,
						lista_plazas: dato.plazas,
						lista_cat_estado: dato.cat_estado,
						lista_cat_municipio: dato.cat_municipio,
						lista_cat_banco: dato.cat_banco,
						lista_cat_promotores: dato.cat_promotores,
						
						
						fecha_firma_contrato: ft.fecha_firma_contrato ? moment(ft.fecha_firma_contrato) : null,
						
						constancia_de_situacion_fiscal_base64: '',
						constancia_de_situacion_fiscal_formato: (ft.constancia_de_situacion_fiscal || '').split('.')[1],
						constancia_de_situacion_fiscal: ft.constancia_de_situacion_fiscal,
						
						comprobante_de_domicilio_base64: '',
						comprobante_de_domicilio_formato: (ft.comprobante_de_domicilio || '').split('.')[1],
						comprobante_de_domicilio: ft.comprobante_de_domicilio,
						
						acta_constitutiva_de_la_cliente_base64: '',
						acta_constitutiva_de_la_cliente_formato: (ft.acta_constitutiva_de_la_cliente || '').split('.')[1],
						acta_constitutiva_de_la_cliente: ft.acta_constitutiva_de_la_cliente,
						
						poder_del_representante_legal_base64: '',
						poder_del_representante_legal_formato: (ft.poder_del_representante_legal || '').split('.')[1],
						poder_del_representante_legal: ft.poder_del_representante_legal,
						
						identificacion_del_repesentante_legal_base64: '',
						identificacion_del_repesentante_legal_formato: (ft.identificacion_del_repesentante_legal || '').split('.')[1],
						identificacion_del_repesentante_legal: ft.identificacion_del_repesentante_legal,
						
						fecha_alta_imss: ft.fecha_alta_imss ? moment(ft.fecha_alta_imss) : null,
						confirmacion_fecha_primera_dispersion: ft.confirmacion_fecha_primera_dispersion ? moment(ft.confirmacion_fecha_primera_dispersion) : null,
						id_cat_periodo_pago_tipo_nomina: ft.id_cat_periodo_pago_tipo_nomina || '',
						comision_propuesta: ft.comision_propuesta || '',
						ficha_tecnica_cantidad_empleado_banco: ficha_tecnica_cantidad_empleado_banco || [],
						iva: ft.iva || '',
						retencion_isr_en_caso_de_ias: ft.retencion_isr_en_caso_de_ias || '',
						retencion_isn_en_caso_de_ias: ft.retencion_isn_en_caso_de_ias || '',
						
						
						lista_recursos_humanos: lista_recursos_humanos,
						lista_ejecutivo_asignado_tesoreria: lista_ejecutivo_asignado_tesoreria,
						// id_ejecutivo_asignado_tesoreria: cliente.id_ejecutivo_asignado_tesoreria || '',
						id_ejecutivo_asignado_tesoreria: id_ejecutivo_asignado_tesoreria || '',
						
						cat_tipo_pago: cat_tipo_pago,
						cliente_que_paga: dato.cliente_que_paga || [],
						ep_cliente_que_paga: '',
						ep_tipo_pago: '',
						ep_id_ejecutivo_asignado: '',
						// ep_id_cat_tipo_pago: '',
						
						cliente_que_factura: dato.cliente_que_factura || [],
						ef_cliente_que_factura: '',
						ef_clave_producto_o_servicio: '',
						ef_concepto: '',
						ef_porcentaje: '',
						ef_observacion_interna: '',
						// ef_id_ejecutivo_asignado: '',
						
						/* J U R I D I C O */
						total_contratos_individuales: cliente.total_contratos_individuales || '',
						total_contratos_individuales_elaborados: '',
						total_contratos_individuales_pendientes: '',
						cantidad_elaborado: '',
						
						excel_elaborado_base64: '',
						excel_elaborado_base64Tipo: '',
						excel_elaborado_archivo: '',
						excel_elaborado_formato: '',
						
						excel_pendiente_base64: '',
						excel_pendiente_base64Tipo: '',
						excel_pendiente_archivo: '',
						excel_pendiente_formato: '',
						
						comentario_general: '',
						
						cliente_contratos_individuales: cliente_contratos_individuales || [],
						
						comentario_juridico_contratos_individuales: cliente.comentario_juridico_contratos_individuales || '',
						
						cliente_agendar_firma_contrato: cliente_agendar_firma_contrato || [],
						
						
						siroc_active: ((ft.sueldo_salario === 1) || (ft.outsourcing === 1)),
						siroc: (Number(ft.siroc) === 1),
						
						comentario_observacion_desarrollador: '', // COMENTARIO PARA EL DESAROOLLADOR
						ficha_tecnica_historial_accion_usuario: ficha_tecnica_historial_accion_usuario, // HISTORIAL PARA EL DESAROOLLADOR
						
						fecha_inicio_empresa_pagan: dato.cliente_que_paga.length > 0 ? moment(dato.cliente_que_paga[0].periodo_fecha_inicio) : null,
						fecha_fin_empresa_pagan: dato.cliente_que_paga.length > 0 ? moment(dato.cliente_que_paga[0].periodo_fecha_fin) : null,
						fecha_inicio_empresa_factura: dato.cliente_que_factura.length > 0 ? moment(dato.cliente_que_factura[0].periodo_fecha_inicio) : null,
						fecha_fin_empresa_factura: dato.cliente_que_factura.length > 0 ? moment(dato.cliente_que_factura[0].periodo_fecha_fin) : null,
					});
					
				}
				
				setTimeout(() => this.openModal());
				
				resolve(true);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
				reject(false);
			});
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	
	add_empesa_que_paga = () => {
		try {
			if (!this.state.ep_cliente_que_paga) {
				throw Object({
					status: false,
					mensaje: 'La empresa que paga requerida'
				})
			}
			/*if (!this.state.ep_id_cat_tipo_pago) {
				throw Object({
					status: false,
					mensaje: 'Selecciona el tipo de pago'
				})
			}*/
			if (!this.state.ep_tipo_pago) {
				throw Object({
					status: false,
					mensaje: 'Selecciona el tipo de pago'
				})
			}
			if (!this.state.ep_id_ejecutivo_asignado) {
				throw Object({
					status: false,
					mensaje: 'Selecciona un ejecutivo asignado a la empresa pagadora'
				})
			}
			let cliente_que_paga = this.state.cliente_que_paga;
			// let tipo_pago = '';
			// for (let i = 0; i < this.state.cat_tipo_pago.length; i++) {
			// 	if (Number(this.state.cat_tipo_pago[i].id_cat_tipo_pago) === Number(this.state.ep_id_cat_tipo_pago)) {
			// 		tipo_pago = this.state.cat_tipo_pago[i].tipo_pago;
			// 	}
			// }
			let ejecutivo_asignado = '';
			for (let i = 0; i < this.state.lista_ejecutivo_asignado_tesoreria.length; i++) {
				if (Number(this.state.lista_ejecutivo_asignado_tesoreria[i].id_usuario) === Number(this.state.ep_id_ejecutivo_asignado)) {
					ejecutivo_asignado = this.state.lista_ejecutivo_asignado_tesoreria[i].nombre + " " + this.state.lista_ejecutivo_asignado_tesoreria[i].apellido_paterno + " " + this.state.lista_ejecutivo_asignado_tesoreria[i].apellido_materno;
				}
			}
			let cliente_paga = '';
			for (let x = 0; x < this.state.listar_empresa.length; x++) {
				if (Number(this.state.listar_empresa[x].id_empresa) === Number(this.state.ep_cliente_que_paga)) {
					cliente_paga = this.state.listar_empresa[x].nombre_comercial;
				}
			}
			cliente_que_paga.push({
				id_cliente_que_paga: null,
				id_empresa_que_paga: this.state.ep_cliente_que_paga,
				cliente_que_paga: cliente_paga,
				// id_cat_tipo_pago: this.state.ep_id_cat_tipo_pago,
				tipo_pago: this.state.ep_tipo_pago,
				id_ejecutivo_asignado: this.state.ep_id_ejecutivo_asignado,
				ejecutivo_asignado: ejecutivo_asignado
			});
			this.setState({
				ep_cliente_que_paga: '',
				ep_tipo_pago: '',
				ep_id_ejecutivo_asignado: '',
				// ep_id_cat_tipo_pago: '',
				cliente_que_paga: cliente_que_paga
			});
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	add_cliente_que_factura = (datos_factura) => {
		
		if (datos_factura) {
			
			let clave = '';
			let concepto = '';
			for (let i = 0; i < datos_factura.lista_facturacion_producto.length; i++) {
				clave = clave + (datos_factura.lista_facturacion_producto[i].id_cat_clave_producto_servicio + (i < datos_factura.lista_facturacion_producto.length - 1 ? ' | ' : ''));
				concepto = concepto + (datos_factura.lista_facturacion_producto[i].descripcion_concepto + (i < datos_factura.lista_facturacion_producto.length - 1 ? ' | ' : ''));
			}
			
			console.log('Datos Facturacion', datos_factura);
			
			this.setState({
				ef_: datos_factura.nombre_comercial,
				ef_porcentaje: datos_factura.ef_porcentaje,
				ef_clave_producto_o_servicio: clave,
				ef_concepto: concepto,
				ef_id_facturacion: datos_factura.id_facturacion
			})
		}
		
		try {
			
			if (!this.state.ef_cliente_que_factura) {
				throw Object({
					status: false,
					mensaje: 'Escribe el nombre de la empresa que factura'
				})
			}
			if (!this.state.ef_porcentaje) {
				throw Object({
					status: false,
					mensaje: 'Escribe el porcentaje factura'
				})
			}
			if (!(this.state.ef_porcentaje > 0)) {
				throw Object({
					status: false,
					mensaje: 'El porcentaje no es un valor válido'
				})
			}
			// if (!this.state.ef_id_ejecutivo_asignado) {
			// 	throw Object({
			// 		status: false,
			// 		mensaje: 'Selecciona el ejecutivo asignado'
			// 	})
			// }
			if (!this.state.ef_clave_producto_o_servicio) {
				throw Object({
					status: false,
					mensaje: 'Escribe la clave de producto o servicio'
				})
			}
			// if (!this.state.ef_concepto) {
			// 	throw Object({
			// 		status: false,
			// 		mensaje: 'Escribe el concepto'
			// 	})
			// }
			
			let no_name = false;
			for (let i = 0; i < this.state.cliente_que_factura.length; i++) {
				if (this.state.cliente_que_factura[i].id_empresa_factura === this.state.ef_cliente_que_factura) {
					no_name = true;
				}
			}
			if (no_name) {
				throw Object({
					status: false,
					mensaje: 'La empresa ' + this.state.ef_cliente_que_factura + ' ya fue agregado'
				})
			}
			
			let name_empresa = '';
			for (let i = 0; i < this.state.listar_empresa.length; i++) {
				if (Number(this.state.listar_empresa[i].id_empresa) === Number(this.state.ef_cliente_que_factura)) {
					name_empresa = this.state.listar_empresa[i].nombre_comercial;
				}
			}
			
			let cpc = '', ef_concepto = '';
			for (let i = 0; i < this.state.listar_clave_producto_servicio.length; i++) {
				if (Number(this.state.listar_clave_producto_servicio[i].id_cat_clave_producto_servicio) === Number(this.state.ef_clave_producto_o_servicio)) {
					cpc = this.state.listar_clave_producto_servicio[i].clave_producto_servicio;
					ef_concepto = this.state.listar_clave_producto_servicio[i].producto_servicio;
					
				}
			}
			
			
			let cliente_que_factura = this.state.cliente_que_factura;
			
			let porcen = 0;
			for (let i = 0; i < cliente_que_factura.length; i++) {
				let item = cliente_que_factura[i];
				porcen += Number(item.porcentaje || 0);
			}
			if (this.state.ef_porcentaje > (100 - porcen)) {
				throw Object({
					status: false,
					mensaje: 'Estas excediendo del 100% para el porcentaje de facturación'
				})
			}
			// let ejecutivo_asignado = '';
			// for (let i = 0; i < this.state.lista_ejecutivo_asignado_tesoreria.length; i++) {
			// 	if (Number(this.state.lista_ejecutivo_asignado_tesoreria[i].id_ejecutivo_asignado_tesoreria) === Number(this.state.ef_id_ejecutivo_asignado)) {
			// 		ejecutivo_asignado = this.state.lista_ejecutivo_asignado_tesoreria[i].nombre + " " + this.state.lista_ejecutivo_asignado_tesoreria[i].apellido_paterno + " " + this.state.lista_ejecutivo_asignado_tesoreria[i].apellido_materno;
			// 	}
			// }
			cliente_que_factura.unshift({
				id_cliente_que_factura: null,
				cliente_que_factura: name_empresa,
				id_cliente: this.state.id_cliente,
				id_empresa_factura: this.state.ef_cliente_que_factura,
				clave_producto_o_servicio: cpc,
				id_cat_clave_producto_servicio: this.state.ef_clave_producto_o_servicio,
				concepto: ef_concepto,
				porcentaje: this.state.ef_porcentaje,
				observacion_interna: this.state.ef_observacion_interna,
				// id_ejecutivo_asignado: this.state.ef_id_ejecutivo_asignado,
				// ejecutivo_asignado: ejecutivo_asignado
			});
			this.setState({
				ef_cliente_que_factura: '',
				ef_clave_producto_o_servicio: '',
				ef_concepto: '',
				ef_porcentaje: '',
				ef_observacion: '',
				// ef_id_ejecutivo_asignado: '',
				cliente_que_factura: cliente_que_factura
			});
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	
	eliminar_cliente_que_paga = (item, key) => {
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, '¿Estas seguro de eliminar la empresa que paga ' + item.cliente_que_paga + '?').then((r) => {
			if (r.button === 'Aceptar') {
				let cliente_que_paga = this.state.cliente_que_paga;
				cliente_que_paga.splice(key, 1);
				this.setState({
					cliente_que_paga: cliente_que_paga
				});
			}
		});
	};
	
	eliminar_cliente_que_factura = (item, key) => {
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, '¿Estas seguro de eliminar la empresa que factura ' + item.cliente_que_factura + '?').then((r) => {
			if (r.button === 'Aceptar') {
				let cliente_que_factura = this.state.cliente_que_factura;
				cliente_que_factura.splice(key, 1);
				this.setState({
					cliente_que_factura: cliente_que_factura
				});
				if (item.id_facturacion) {
					this.state.ids_empresa_eliminada.push(item.id_facturacion);
				}
			}
		});
	};
	
	guardarFiscalDatosClientes = (eliminar) => {
		
		try {
			
			if (!this.state.id_ejecutivo_asignado_tesoreria) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Ejecutivo asignado de recursos humanos.'
				})
			}
			
			if (!this.state.fecha_inicio_empresa_pagan || !this.state.fecha_fin_empresa_pagan) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Periodo de empresa(s) que pagan.'
				})
			}
			
			if (!this.state.fecha_inicio_empresa_factura || !this.state.fecha_fin_empresa_factura) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Periodo de empresa(s) que factura.'
				})
			}
			
			if (!this.state.cliente_que_paga) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Empresa(s) que pagan.'
				})
			}
			
			if (!this.state.cliente_que_factura) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Empresa que factura.'
				})
			}
			
			let cliente_que_factura = this.state.cliente_que_factura;
			let porcen = 0;
			for (let i = 0; i < cliente_que_factura.length; i++) {
				let item = cliente_que_factura[i];
				porcen += Number(item.porcentaje || 0);
			}
			if (100 > porcen) {
				throw Object({
					status: false,
					mensaje: 'Completa el 100% de porcentaje de facturación'
				})
			}
			
			ClientePotencialService.GuardarDatosClientes(this.state).then((response) => {
				
				if (this.state.ids_empresa_eliminada.length > 0) {
					console.log('aaaaaa');
					FacturacionService.Cerrar(this.state.ids_empresa_eliminada).then((response) => {
						this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
					});
				}
				
				console.log('RESPONSE',response);
				
				this.props.RefreshList();
				
				this.close();
				
				this.showSnackBars('success', response.mensaje);
				
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	disabledFieldFiscalLlenaDatosClienteBolean = () => {
		
		let disabledFieldFiscalLlenaDatosCliente = true;
		
		if (FieldsJs.inArray([1, 2, 4], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldFiscalLlenaDatosCliente = false;
		}
		
		return disabledFieldFiscalLlenaDatosCliente;
	};
	
	render() {
		
		const disabledFieldFiscalLlenaDatosCliente = this.disabledFieldFiscalLlenaDatosClienteBolean();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Typography className={'px-20'}>
									<Fab size="small" color="secondary" aria-label="Actualizar" onClick={() => this.RefreshInit()} style={{marginRight: '15px'}}>
										<SyncOutlined/>
									</Fab>
									{this.props.titulo}
								</Typography>
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={0} alignContent={"center"} alignItems={"center"}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<Grid container spacing={2}>
									
									<Grid item className={'text-left'} xs={12} sm={1} md={2} lg={4} xl={4}/>
									<Grid item className={'text-left'} xs={12} sm={10} md={8} lg={4} xl={4}>
										<TextField
											required
											select
											fullWidth
											margin="none"
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											
											helperText=""
											name="id_ejecutivo_asignado_tesoreria"
											label="Ejecutivo asignado de recursos humanos"
											value={this.state.id_ejecutivo_asignado_tesoreria}
											disabled={disabledFieldFiscalLlenaDatosCliente}
										>
											<option value={''}>&nbsp;</option>
											{this.state.lista_recursos_humanos.map((item, key) => (
												<option key={key} value={item.id_usuario}>
													{item.nombre} {item.apellido_paterno} {item.apellido_materno}
												</option>))}
										</TextField>
									</Grid>
									<Grid item className={'text-left'} xs={12} sm={1} md={2} lg={4} xl={4}/>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<Card>
											<CardContent style={{background: 'lightgray', padding: '15px'}}>
											<MDDatePicker
												type={'range'}
												component={(
													<TextField
														fullWidth
														label="Periodo para las empresa(s) que pagan"
														value={(this.state.fecha_inicio_empresa_pagan && this.state.fecha_fin_empresa_pagan) ? (DateFormat.FormatText(this.state.fecha_inicio_empresa_pagan) + " al " + DateFormat.FormatText(this.state.fecha_fin_empresa_pagan)) : ''}
														readOnly
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton aria-label="Periodo para las empresa(s) que pagan">
																		<DateRangeOutlined/>
																	</IconButton>
																</InputAdornment>
															)
														}}
													/>
												)}
												startDate={this.state.fecha_inicio_empresa_pagan}
												endDate={this.state.fecha_fin_empresa_pagan}
												onChange={(range) => {
													console.log("RANGE", range);
													this.setState({
														fecha_inicio_empresa_pagan: range.startDate,
														fecha_fin_empresa_pagan: range.endDate,
													});
												}}
											/>
											</CardContent>
										</Card>
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<Card>
											<CardContent style={{background: 'lightgray', padding: '15px'}}>
											<MDDatePicker
												type={'range'}
												component={(
													<TextField
														fullWidth
														label="Periodo para las empresa(s) que facturan"
														value={(this.state.fecha_inicio_empresa_factura && this.state.fecha_fin_empresa_factura) ? (DateFormat.FormatText(this.state.fecha_inicio_empresa_factura) + " al " + DateFormat.FormatText(this.state.fecha_fin_empresa_factura)) : ''}
														readOnly
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton aria-label="Periodo para las empresa(s) que facturan">
																		<DateRangeOutlined/>
																	</IconButton>
																</InputAdornment>
															)
														}}
													/>
												)}
												startDate={this.state.fecha_inicio_empresa_factura}
												endDate={this.state.fecha_fin_empresa_factura}
												onChange={(range) => {
													console.log("RANGE", range);
													this.setState({
														fecha_inicio_empresa_factura: range.startDate,
														fecha_fin_empresa_factura: range.endDate,
													});
												}}
											/>
											</CardContent>
										</Card>
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<Card>
											<CardContent style={{background: 'lightgray', padding: '15px'}}>
												<Grid container spacing={1}>
													{!disabledFieldFiscalLlenaDatosCliente ? (
														<Fragment>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
																	{!this.is_view() ? (
																		<Grid item style={{width: '50px'}}>
																			{FieldsJs.inArray([1, 2, 4], this.Usr.id_cat_tipo_usuario) ? (
																				<Fragment>
																					<ModalEmpresa
																						componente={(
																							<Tooltip
																								TransitionComponent={Zoom}
																								placement={"right"}
																								title="Agregar empresa"
																								children={(
																									<Fab
																										color="default"
																										size="small"
																										aria-label="Agregar nueva empresa"
																										children={(
																											<PlaylistAddOutlined/>
																										)}
																									/>
																								)}
																							/>
																						)}
																						tipo={'add'}
																						item={{}}
																						RefreshList={this.RefreshListEmpresa}
																						showSnackBars={this.props.showSnackBars}
																					/>
																				</Fragment>
																			) : ''}
																		</Grid>
																	) : null}
																	<Grid item style={{width: 'calc(100% - 50px)'}}>
																		<TextField
																			select
																			fullWidth
																			required
																			margin="none"
																			onChange={this.handleChange}
																			disabled={this.is_view()}
																			SelectProps={{
																				native: true,
																				MenuProps: {
																					className: '',
																				},
																			}}
																			
																			name="ep_cliente_que_paga"
																			label="Empresa(s) que pagan"
																			value={this.state.ep_cliente_que_paga}
																		>
																			<option key={0} value={''}>&nbsp;</option>
																			{this.state.listar_empresa.map(option => (
																				<option key={option.id_empresa} value={option.id_empresa}>
																					{option.nombre_comercial}
																				</option>
																			))}
																		</TextField>
																	</Grid>
																</Grid>
															</Grid>
															
															
															{/*<Grid item className={'text-left'} xs={12} sm={12} md={12}
															      lg={12}
															      xl={12}>
																<TextField
																	fullWidth
																	onChange={this.handleChange}
																	type="text"
																	margin="none"
																	
																	helperText={'(Nombre completo)'}
																	name="ep_cliente_que_paga"
																	label="Empresa(s) que pagan(n)"
																	value={this.state.ep_cliente_que_paga}
																	disabled={disabledFieldFiscalLlenaDatosCliente}
																/>
															</Grid>*/}
															
															<Grid item className={'text-left'} xs={12} sm={5} md={5}
															      lg={5}
															      xl={5}>
																<TextField
																	fullWidth
																	onChange={this.handleChange}
																	type="text"
																	margin="none"
																	
																	helperText={''}
																	name="ep_tipo_pago"
																	label="Tipo de pago"
																	value={this.state.ep_tipo_pago}
																	disabled={disabledFieldFiscalLlenaDatosCliente}
																/>
															</Grid>
															<Grid item className={'text-left'} xs={12} sm={5} md={5}
															      lg={5}
															      xl={5}>
																<TextField
																	required
																	select
																	fullWidth
																	margin="none"
																	onChange={this.handleChange}
																	SelectProps={{
																		native: true,
																		MenuProps: {},
																	}}
																	
																	name="ep_id_ejecutivo_asignado"
																	label="Ejecutivo asignado"
																	value={this.state.ep_id_ejecutivo_asignado}
																	disabled={disabledFieldFiscalLlenaDatosCliente}
																>
																	<option value={''}>&nbsp;</option>
																	{this.state.lista_ejecutivo_asignado_tesoreria.map((item, key) => (
																		<option key={key}
																		        value={item.id_usuario}>
																			{item.nombre} {item.apellido_paterno} {item.apellido_materno}
																		</option>))}
																</TextField>
															</Grid>
															{/*<Grid item className={'text-left'} xs={12} sm={5} md={5} lg={5} xl={5}>
																<TextField
																	select
																	fullWidth
																	margin="none"
																	onChange={this.handleChange}
																	SelectProps={{
																		native: true,
																		MenuProps: {},
																	}}
																	
																	name="ep_id_cat_tipo_pago"
																	label="Tipo de pago"
																	value={this.state.ep_id_cat_tipo_pago}
																	disabled={disabledFieldFiscalLlenaDatosCliente}
																>
																	<option value={''}>&nbsp;</option>
																	{this.state.cat_tipo_pago.map((item, key) => (
																		<option key={key} value={item.id_cat_tipo_pago}>
																			{item.tipo_pago}
																		</option>))}
																</TextField>
															</Grid>*/}
															<Grid item className={'text-left padding-17-T'} xs={12}
															      sm={2}
															      md={2} lg={2}
															      xl={2}>
																<Fab
																	style={{fontSize: '10px', width: '100%'}}
																	variant="extended"
																	size="small"
																	color="primary"
																	aria-label="Agregar"
																	onClick={() => this.add_empesa_que_paga()}
																>
																	<CheckOutlined style={{fontSize: '12px', marginRight: '5px'}}/>
																	agregar
																</Fab>
															</Grid>
															<Grid item xs={12} style={{height: '20px'}}/>
														</Fragment>
													) : (
														<Fragment>
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<Typography variant={'h5'}
																            className={'text-center px-20'}>
																	Empresa(s) que pagan(n)
																</Typography>
															</Grid>
														</Fragment>
													)}
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Grid container spacing={2}>
															{(this.state.cliente_que_paga || []).map((item, key) => (
																<Grid key={key} item xs={12} sm={12} md={12} lg={12}
																      xl={12}>
																	
																	<Card>
																		<CardContent style={{padding: '10px'}}>
																			
																			<div align={'right'}
																			     style={{
																				     width: '100%',
																				     height: 0
																			     }}
																			>
																				{!disabledFieldFiscalLlenaDatosCliente ? (
																					<Fragment>
																						{FieldsJs.inArray([0, 1], (item.id_cat_cliente_que_paga_status || 0)) ? (
																							<div
																								className={'cursor-pointer'}
																								style={{
																									textAlign: 'center',
																									marginLeft: 5,
																									float: 'right',
																									padding: '6px',
																									background: 'red',
																									height: '6px',
																									width: '6px',
																									lineHeight: '6px',
																									color: 'white',
																									borderRadius: '100%',
																									fontSize: '12px',
																								}}
																								onClick={() => this.eliminar_cliente_que_paga(item, key)}
																								children={'x'}
																							/>
																						) : null}
																					</Fragment>
																				) : null}
																				<div style={{
																					textAlign: 'center',
																					marginLeft: 5,
																					float: 'right',
																					padding: '6px',
																					background: item.color || '#bebebe',
																					height: '6px',
																					width: '60px',
																					lineHeight: '6px',
																					color: 'white',
																					borderRadius: '5px',
																					fontSize: '12px',
																				}}
																				     children={item.cliente_que_paga_status || 'Espera...'}/>
																			</div>
																			
																			<Typography
																				className={'text-left text-black px-16'}>
																				{item.cliente_que_paga}
																			</Typography>
																			
																			<Typography
																				className={'text-left text-gray px-14'}>
																				Tipo de pago: {item.tipo_pago}
																			</Typography>
																			
																			<Typography
																				className={'text-left text-gray px-14'}>
																				Ejecutivo
																				asignado: {item.ejecutivo_asignado}
																			</Typography>
																			
																			{(item.cliente_que_paga_comentario || []).length > 0 ? (
																				<div style={{width: '100%'}}>
																					<List>
																						{(item.cliente_que_paga_comentario || []).map((value, index) => (
																							<Fragment key={index}>
																								<ListItem
																									alignItems="flex-start"
																									style={{padding: 0}}>
																									<ListItemAvatar>
																										<Avatar
																											alt={value.nombre_completo_usuario}
																											src={CONFIG.src + value.foto}
																											style={{
																												height: '30px',
																												width: '30px'
																											}}
																										/>
																									</ListItemAvatar>
																									<ListItemText
																										style={{
																											fontSize: '12px',
																											margin: 0,
																											padding: 0
																										}}
																										primary={value.nombre_completo_usuario}
																										secondary={
																											<Fragment>
																												<Typography
																													component="span"
																													variant="body2"
																													color="textPrimary"
																													style={{fontSize: '10px'}}
																												>
																													Motivo:
																												</Typography>
																												{" — " + value.cliente_que_paga_comentario}
																											</Fragment>
																										}
																									/>
																								</ListItem>
																								<Divider variant="inset"
																								         component="li"/>
																							</Fragment>
																						))}
																					</List>
																				</div>
																			) : null}
																		
																		</CardContent>
																	</Card>
																
																</Grid>
															))}
														
														</Grid>
														{(this.state.cliente_que_paga || []).length <= 0 ? (
															<Fragment>
																<VistaVacia
																	numero={3}
																	mensaje='No se han seleccionado las empresas que pagan'
																	width={'100px'}
																	height={'100px'}
																/>
															</Fragment>
														) : ''}
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<Card>
											<CardContent style={{background: 'lightgray', padding: '15px'}}>
												<Grid container spacing={1}>
													{!disabledFieldFiscalLlenaDatosCliente ? (
														<Fragment>
															<Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
																<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
																	{!this.is_view() ? (
																		<Grid item style={{width: '50px'}}>
																			{FieldsJs.inArray([1, 2, 4], this.Usr.id_cat_tipo_usuario) ? (
																				<Fragment>
																					<ModalEmpresa
																						componente={(
																							<Tooltip
																								TransitionComponent={Zoom}
																								placement={"right"}
																								title="Agregar empresa"
																								children={(
																									<Fab
																										color="default"
																										size="small"
																										aria-label="Agregar nueva empresa"
																										children={(
																											<PlaylistAddOutlined/>
																										)}
																									/>
																								)}
																							/>
																						)}
																						tipo={'add'}
																						item={{}}
																						RefreshList={this.RefreshListEmpresa2}
																						showSnackBars={this.props.showSnackBars}
																					/>
																				</Fragment>
																			) : ''}
																		</Grid>
																	) : null}
																	<Grid item style={{width: 'calc(100% - 50px)'}}>
																		<TextField
																			select
																			fullWidth
																			required
																			margin="none"
																			onChange={(e) => {
																				this.handleChangeEmpresa(e);
																			}}
																			disabled={this.is_view()}
																			SelectProps={{
																				native: true,
																				MenuProps: {
																					className: '',
																				},
																			}}
																			
																			name="ef_cliente_que_factura"
																			label="Empresa(s) que factura"
																			value={this.state.ef_cliente_que_factura}
																		>
																			<option key={0} value={''}>&nbsp;</option>
																			{this.state.listar_empresa.map(option => (
																				<option key={option.id_empresa} value={option.id_empresa}>
																					{option.nombre_comercial}
																				</option>
																			))}
																		</TextField>
																	</Grid>
																</Grid>
															</Grid>
															
															<Grid item className={'text-left'} xs={4} sm={4} md={4} lg={4} xl={4}>
																<TextField
																	fullWidth
																	onChange={this.handleChange}
																	type="text"
																	margin="none"
																	
																	// helperText={'Facturación'}
																	name="ef_porcentaje"
																	label="Porcentaje"
																	value={this.state.ef_porcentaje}
																	disabled={disabledFieldFiscalLlenaDatosCliente}
																	onKeyPress={EnteroSolo}
																/>
															</Grid>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
																	{!this.is_view() ? (
																		<Grid item style={{width: '50px'}}>
																			{FieldsJs.inArray([1, 2, 4], this.Usr.id_cat_tipo_usuario) ? (
																				<ModalClaveProductoServicio
																					componente={(
																						<Tooltip
																							TransitionComponent={Zoom}
																							placement={"right"}
																							title="Clave producto / servicio"
																							children={(
																								<Fab
																									color="default"
																									size="small"
																									aria-label="Agregar nueva clave de producto / servicio"
																									children={(
																										<PlaylistAddOutlined/>
																									)}
																								/>
																							)}
																						/>
																					)}
																					id_cliente={null}
																					tipo={'add'}
																					item={{}}
																					RefreshList={this.RefreshListClaveProductoServicio}
																					showSnackBars={this.props.showSnackBars}
																				/>
																			) : ''}
																		</Grid>
																	) : null}
																	<Grid item style={{width: 'calc(100% - 50px)'}}>
																		<TextField
																			select
																			fullWidth
																			required
																			margin="none"
																			onChange={this.handleChange
																				// this.colocarValoresEnCampos(e.target.value);
																			}
																			disabled={this.is_view()}
																			SelectProps={{
																				native: true,
																				MenuProps: {
																					className: '',
																				},
																			}}
																			name="ef_clave_producto_o_servicio"
																			label="Clave producto / servicio"
																			value={this.state.ef_clave_producto_o_servicio}
																		>
																			<option key={0} value={''}>&nbsp;</option>
																			{this.state.listar_clave_producto_servicio.map(option => (
																				<option key={option.id_cat_clave_producto_servicio} value={option.id_cat_clave_producto_servicio}>
																					{option.clave_producto_servicio} - {option.producto_servicio}
																				</option>
																			))}
																		</TextField>
																	</Grid>
																</Grid>
															</Grid>
															
															<Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
																<TextField
																	rows={3}
																	multiline
																	margin="none"
																	name="ef_observacion_interna"
																	label="Observación interna"
																	type="text"
																	fullWidth
																	value={this.state.ef_observacion_interna}
																	onChange={this.handleChange}
																	disabled={this.is_view()}
																/>
															</Grid>
															
															{/*<Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
																<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
																	{!this.is_view() ? (
																		<Grid item style={{width: '100%'}}>
																			{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
																				<Grid item className={'text-left padding-15-T'} xs={12} sm={12} md={12} lg={12} xl={12}>
																					<ModalFacturacion
																						componente={(
																							<Tooltip
																								TransitionComponent={Zoom}
																								placement={"right"}
																								title="Empresa"
																								children={(
																									<Fab
																										color="default"
																										size="small"
																										aria-label="Agregar empresa"
																										children={(
																											<PlaylistAddOutlined/>
																										)}
																									/>
																								)}
																							/>
																						)}
																						id_empresa={null}
																						datos_cliente={this.props.item}
																						tipo={'add'}
																						id_facturacion={null}
																						item={this.state}
																						datos_facturacion={(response) => {
																							console.log('DATOS DE FACTURACION', response);
																							this.add_cliente_que_factura(response)
																						}}
																						RefreshList={this.RefreshListEmpresa}
																						showSnackBars={this.props.showSnackBars}
																					/>
																				</Grid>
																			) : ''}
																		</Grid>
																	) : null}
																	<Grid item style={{width: 'calc(100% - 50px)'}}>
																		<TextField
																			select
																			fullWidth
																			required
																			margin="none"
																			onChange={this.handleChange}
																			disabled={disabledFieldFiscalLlenaDatosCliente}
																			SelectProps={{
																				native: true,
																				MenuProps: {
																					className: '',
																				},
																			}}
																			name="ef_cliente_que_factura"
																			label="Empresa que factura"
																			value={this.state.ef_cliente_que_factura}
																		>
																			<option key={0} value={''}>&nbsp;</option>
																			{this.state.listar_empresa.map(option => (
																				<option key={option.id_empresa} value={option.id_empresa}>
																					{option.nombre_comercial}
																				</option>
																			))}
																		</TextField>
																	</Grid>
																</Grid>
															</Grid>*/}
															
															{/*<Grid item className={'text-left'} xs={12} sm={8} md={8} lg={8} xl={8}>
																<TextField
																	fullWidth
																	onChange={this.handleChange}
																	type="text"
																	margin="none"
																	
																	helperText={'(Nombre completo)'}
																	name="ef_cliente_que_factura"
																	label="Empresa que factura"
																	value={this.state.ef_cliente_que_factura}
																	disabled={disabledFieldFiscalLlenaDatosCliente}
																/>
															</Grid>*/}
															
															{/*<Grid item className={'text-left'} xs={12} sm={4} md={4} lg={4} xl={4}>
																<TextField
																	required
																	select
																	fullWidth
																	margin="none"
																	onChange={this.handleChange}
																	SelectProps={{
																		native: true,
																		MenuProps: {},
																	}}
																	
																	name="ef_id_ejecutivo_asignado"
																	label="Ejecutivo asignado"
																	value={this.state.ef_id_ejecutivo_asignado}
																	disabled={disabledFieldFiscalLlenaDatosCliente}
																>
																	<option value={''}>&nbsp;</option>
																	{this.state.lista_ejecutivo_asignado_tesoreria.map((item, key) => (
																		<option key={key} value={item.id_ejecutivo_asignado_tesoreria}>
																			{item.nombre} {item.apellido_paterno} {item.apellido_materno}
																		</option>))}
																</TextField>
															</Grid>*/}
															
															
															{/*<Grid item className={'text-left'} xs={12} sm={5} md={5}
															      lg={5}
															      xl={5}>
																<TextField
																	fullWidth
																	onChange={this.handleChange}
																	type="text"
																	margin="none"
																	name="ef_clave_producto_o_servicio"
																	label="CVE. PROD. O SERV."
																	value={this.state.ef_clave_producto_o_servicio}
																	onKeyPress={EnteroSolo}
																	inputProps={{maxLength: 100}}
																	disabled={disabledFieldFiscalLlenaDatosCliente}
																/>
															</Grid>
															<Grid item className={'text-left'} xs={12} sm={5} md={5}
															      lg={5}
															      xl={5}>
																<TextField
																	fullWidth
																	onChange={this.handleChange}
																	type="text"
																	margin="none"
																	name="ef_concepto"
																	label="Concepto"
																	value={this.state.ef_concepto}
																	disabled={disabledFieldFiscalLlenaDatosCliente}
																/>
															</Grid>*/}
															
															
															{/*DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD*/}
															
															
															<Grid item className={'text-left padding-55-T'} xs={12} sm={2} md={2} lg={2} xl={2}>
																<Fab
																	style={{fontSize: '10px', width: '100%'}}
																	variant="extended"
																	size="small"
																	color="primary"
																	aria-label="Agregar"
																	onClick={() => this.add_cliente_que_factura()}
																>
																	<CheckOutlined style={{fontSize: '12px', marginRight: '5px'}}/>
																	agregar
																</Fab>
															</Grid>
															<Grid item xs={12} style={{height: '20px'}}/>
														</Fragment>
													) : (
														<Fragment>
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<Typography variant={'h5'}
																            className={'text-center px-20'}>
																	Clientes que facturan y ejecutivo asignado
																</Typography>
															</Grid>
														</Fragment>
													)}
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Grid container spacing={2}>
															{this.state.cliente_que_factura.map((item, key) => (
																<Grid key={key} item xs={12} sm={12} md={12} lg={12}
																      xl={12}>
																	<Card>
																		<CardContent style={{padding: '10px'}}>
																			
																			<div align={'right'} style={{width: '100%', height: 0}}>
																				{!disabledFieldFiscalLlenaDatosCliente ? (
																					<Fragment>
																						<div className={'w-auto vertical-inline'}>
																							
																							
																							{/*{(<div className={'v-center'}>
																								<ModalFacturacion
																									id_facturacion={item.id_facturacion}
																									icono={(
																										<Tooltip TransitionComponent={Zoom} placement={"top"}
																										         title="Editar">
																											<IconButton aria-label="Editar">
																												<EditOutlined/>
																											</IconButton>
																										</Tooltip>
																									)}
																									id_empresa={null}
																									datos_cliente={this.props.item}
																									tipo={'edit'}
																									item={this.state}
																									datos_facturacion={(response) => {
																										console.log('DATOS DE FACTURACION', response);
																										this.add_cliente_que_factura(response)
																									}}
																									RefreshList={this.RefreshListEmpresa}
																									showSnackBars={this.props.showSnackBars}
																								/>
																							</div>)}
																							<div className={'v-center'}>
																								<ModalFacturacion
																									id_facturacion={item.id_facturacion}
																									item={item}
																									tipo={'view'}
																									datos_cliente={this.props.item}
																									datos_facturacion={(response) => {
																										this.add_cliente_que_factura(response)
																									}}
																									icono={(
																										<Tooltip TransitionComponent={Zoom} placement={"top"}
																										         title="Detalles">
																											<IconButton aria-label="Detalles">
																												<SearchOutlined/>
																											</IconButton>
																										</Tooltip>
																									)}
																									RefreshList={this.RefreshListEmpresa}
																									showSnackBars={this.props.showSnackBars}
																								/>
																							</div>
																							*/}
																							{FieldsJs.inArray([0, 1], (item.id_cat_cliente_que_factura_status || 0)) ? (
																								// <span onClick={() => this.eliminar_cliente_que_factura(item, key)}
																								//       style={{cursor: "pointer"}}>
																								// 	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
																								// 			<IconButton aria-label="Editar">
																								// 				<Close/>
																								// 			</IconButton>
																								// 		</Tooltip>
																								// </span>
																								<div
																									className={'v-center cursor-pointer'}
																									style={{
																										textAlign: 'center',
																										marginLeft: 5,
																										float: 'right',
																										padding: '6px',
																										background: 'red',
																										height: '6px',
																										width: '6px',
																										lineHeight: '6px',
																										color: 'white',
																										borderRadius: '100%',
																										fontSize: '12px',
																									}}
																									onClick={() => this.eliminar_cliente_que_factura(item, key)}
																									children={'x'}
																								/>
																							) : null}
																						
																						</div>
																					</Fragment>
																				) : null}
																			
																			
																			</div>
																			
																			<Typography variant={'h5'}
																			            className={'text-left text-black px-16'}>
																				{item.cliente_que_factura}
																			</Typography>
																			<Typography variant={'h5'}
																			            className={'text-left text-gray px-14'}>
																				Porcentaje de
																				facturación: {item.porcentaje}%
																			</Typography>
																			<Typography variant={'h5'}
																			            className={'text-left text-gray px-14'}>
																				CVE. PROD. O
																				SERV.: {item.clave_producto_o_servicio}
																			</Typography>
																			<Typography variant={'h5'}
																			            className={'text-left text-gray px-14'}>
																				Concepto: {item.concepto}
																			</Typography>
																			{/*<Typography variant={'h5'}
																	            className={'text-left text-gray px-14'}>
																		Ejecutivo asignado: {item.ejecutivo_asignado}
																	</Typography>*/}
																		
																		</CardContent>
																	</Card>
																</Grid>
															))}
														</Grid>
														{this.state.cliente_que_factura.length <= 0 ? (
															<Fragment>
																<VistaVacia
																	numero={3}
																	mensaje='No se ha seleccionado las empresas que facturan'
																	width={'100px'}
																	height={'100px'}
																/>
															</Fragment>
														) : ''}
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
								
								</Grid>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={1}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!disabledFieldFiscalLlenaDatosCliente ? (
									<Button variant="contained" color="primary"
									        onClick={this.guardarFiscalDatosClientes}
									        className={'btn-default-primary text-transform-none'}>
										Guardar y confirmar empresas
									</Button>
								) : ''}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ClientesPagadorasFacturadoras.propTypes = {
	titulo: PropTypes.string.isRequired,
	id_cliente: PropTypes.number.isRequired,
	item: PropTypes.object.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ClientesPagadorasFacturadoras;
