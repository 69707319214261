import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {AccountTree, DateRangeOutlined, HdrStrong, MailOutline, PersonOutlined, SearchOutlined, ViewDay, Business} from "@material-ui/icons";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {CatService as TipoUsuarioServiceService, CatService} from "../../../../services/_Cat/CatService/CatService";
import {TipoUsuarioService} from "../../../../services/_Cat/TipoUsuarioService/TipoUsuarioService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {ProyectoService} from "../../../../services/_Cat/ProyectoService/ProyectoService";
import {ModuloService} from "../../../../services/_Cat/ModuloService/ModuloService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";


class FiltrosLineaCaptura extends Component {
	
	Usr = {};
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			id_linea_captura: '',
			usuario: '',
			id_empresa: '',
			ejercicio: '',
			periodo: '',
			id_cat_tipo_impuesto: '',
			id_cat_tipo_declaracion: '',
			activo: '',
			estatus: '',
			order_estatus: '',
			estatus_pago: '',
			fecha_alta: null,
			
			lista_ejercicio: [],
			lista_periodo: [
				{mes: 'Enero'},
				{mes: 'Febrero'},
				{mes: 'Marzo'},
				{mes: 'Abril'},
				{mes: 'Mayo'},
				{mes: 'Junio'},
				{mes: 'Julio'},
				{mes: 'Agosto'},
				{mes: 'Septiembre'},
				{mes: 'Octubre'},
				{mes: 'Noviembre'},
				{mes: 'Diciembre'}
			],
			lista_tipo_impuesto: [],
			lista_tipo_declaracion: [],
			
		};
		
		this.ListImpuesto();
		this.ListDeclaracion();
		this.ListEjercicio();
	}
	
	ListEjercicio = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEjercicio().then((response) => {
				this.setState({
					lista_ejercicio: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	ListImpuesto = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoImpuesto().then((response) => {
				this.setState({
					lista_tipo_impuesto: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	ListDeclaracion = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoDeclaracion().then((response) => {
				this.setState({
					lista_tipo_declaracion: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.id_linea_captura = this.state.id_linea_captura || undefined;
		filtro.usuario = this.state.usuario || undefined;
		filtro.id_empresa = this.state.id_empresa || undefined;
		filtro.ejercicio = this.state.ejercicio || undefined;
		filtro.periodo = this.state.periodo || undefined;
		filtro.id_cat_tipo_impuesto = this.state.id_cat_tipo_impuesto || undefined;
		filtro.id_cat_tipo_declaracion = this.state.id_cat_tipo_declaracion || undefined;
		filtro.activo = this.state.activo || undefined;
		filtro.estatus = this.state.estatus || undefined;
		filtro.order_estatus = this.state.order_estatus || undefined;
		filtro.estatus_pago = this.state.estatus_pago || undefined;
		filtro.fecha_alta = this.state.fecha_alta || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={6} sm={6} md={2} lg={1} xl={1} align={'left'}>
						<TextField
							type={'text'}
							fullWidth
							name="id_linea_captura"
							onChange={this.handleChange}
							label="ID"
							autoComplete={'off'}
							value={this.state.id_linea_captura}
						/>
					</Grid>
					
					{FieldsJs.inArray([1,2],this.Usr.id_cat_tipo_usuario) ? (
						<Grid item xs={6} sm={6} md={2} lg={2} xl={2} align={'left'}>
							<TextField
								type={'text'}
								fullWidth
								name="usuario"
								onChange={this.handleChange}
								label="Usuario"
								autoComplete={'off'}
								value={this.state.usuario}
							/>
						</Grid>
					):null }
					
					<Grid item xs={6} sm={6} md={3} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Business className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Empresa</InputLabel>
									<Select
										native
										value={this.state.id_empresa}
										onChange={this.handleChange}
										name="id_empresa"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.props.lista_empresa.map(option => (
											<option key={option.id_empresa}
											        value={option.id_empresa}>
												{option.nombre_comercial}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Ejercicio</InputLabel>
									<Select
										native
										value={this.state.ejercicio}
										onChange={this.handleChange}
										name="ejercicio"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.state.lista_ejercicio.map(option => (
											<option key={option.ejercicio}
											        value={option.ejercicio}>
												{option.ejercicio}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Periodo</InputLabel>
									<Select
										native
										value={this.state.periodo}
										onChange={this.handleChange}
										name="periodo"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.state.lista_periodo.map(option => (
											<option key={option.mes}
											        value={option.mes}>
												{option.mes}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px text-left'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Tipo Impuesto</InputLabel>
									<Select
										native
										value={this.state.id_cat_tipo_impuesto}
										onChange={this.handleChange}
										name="id_cat_tipo_impuesto"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.state.lista_tipo_impuesto.map(option => (
											<option key={option.id_cat_tipo_impuesto}
											        value={option.id_cat_tipo_impuesto}>
												{option.abreviatura + '-' + option.tipo_impuesto}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Tipo Declaración</InputLabel>
									<Select
										native
										value={this.state.id_cat_tipo_declaracion}
										onChange={this.handleChange}
										name="id_cat_tipo_declaracion"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.state.lista_tipo_declaracion.map(option => (
											<option key={option.id_cat_tipo_declaracion}
											        value={option.id_cat_tipo_declaracion}>
												{option.abreviatura + '-' + option.tipo_declaracion}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Estatus Pago</InputLabel>
									<Select
										native
										value={this.state.estatus_pago}
										onChange={this.handleChange}
										name="estatus_pago"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
											<option value={"1"}>Pendiente de pago</option>
											<option value={"2"}>Liquidada</option>
											<option value={"3"}>No pagada</option>
											<option value={"4"}>Cancelada</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Estatus</InputLabel>
									<Select
										native
										value={this.state.estatus}
										onChange={this.handleChange}
										name="estatus"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
											<option value={"1"}>Vigente</option>
											<option value={"2"}>Cancelado</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Ordenar por Estatus</InputLabel>
									<Select
										native
										value={this.state.order_estatus}
										onChange={this.handleChange}
										name="order_estatus"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										<option value={"1"}>Pendiente de pago</option>
										<option value={"2"}>Liquidada</option>
										<option value={"3"}>No pagada</option>
										<option value={"4"}>Cancelada</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
						<KeyboardDatePicker
							format="dd/MM/yyyy"
							fullWidth
							clearable
							inputProps={{readOnly: true}}
							/*KeyboardButtonProps={{
								'aria-label': 'Fecha de pago',
							}}*/
							label="Fecha"
							value={this.state.fecha_alta}
							onChange={(date) => {
								this.handleChange(null, null, null, date, 'fecha_alta');
							}}
							// minDate={moment(new Date())}
							clearLabel={'Limpiar'}
							okLabel={'Aceptar'}
							cancelLabel={'Cancelar'}
							onError={console.log}
						/>
					
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={2} xl={2} align={'center'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosLineaCaptura.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	lista_empresa: PropTypes.array.isRequired,
	ListEmpresa: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosLineaCaptura;
