import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton";
import {PersonOutline, WorkOutlineOutlined} from '@material-ui/icons';
import PoPoverClientePotencial from "./PoPoverClientePotencial";

import {FieldsJs} from '../../../../settings/General/General';
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import ModalReasignarPromotor from "./ModalReasignarPromotor";


import icon_mas from '../../../../assets/img/icons/expandir-mas.svg';
import icon_menos from '../../../../assets/img/icons/expandir-menos.svg';
import SubTablaClientePotencial from "./SubTablaClientePotencial";
import $State from "../../../../settings/$State/$State";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.estatus = this.estatus.bind(this);
		this.color = this.color.bind(this);
	}
	
	eliminar = (item) => {
		this.props.$BroadcastModalClientePotencial({
			accion: 'delete',
			status: true,
			item: item
		});
	};
	
	primeravisita = (item) => {
		$State.go(this.props, 'fichatecnica', {id_cliente: item.id_cliente});
	};
	
	estatus(id_cat_ficha_tecnica_status, id_cat_tipo_usuario) {
		let estatus = this.props.estatus || [];
		var t = 'Crear ficha técnica';
		for (let i = 0; i < estatus.length; i++) {
			if (estatus[i].id_cat_ficha_tecnica_status === id_cat_ficha_tecnica_status) {
				switch (id_cat_tipo_usuario) {
					case 1: // root
						t = "Ficha técnica";
						break;
					case 2: // admin
						t = "Ficha técnica";
						break;
					case 3: // promotor
						t = estatus[i].ficha_tecnica_status_tooltip_promotor;
						break;
					case 4: // fiscal
						t = estatus[i].ficha_tecnica_status_tooltip_fiscal;
						break;
					case 5: // juridico
						t = "Juridico: " + estatus[i].ficha_tecnica_status;
						break;
					case 6: // tesoreria
						t = "Tesoreria: " + estatus[i].ficha_tecnica_status;
						break;
					case 7: // recursos humanos
						t = "Recursos humanos: " + estatus[i].ficha_tecnica_status;
						break;
					default:
						t = estatus[i].ficha_tecnica_status;
				}
			}
		}
		return t;
	};
	
	color(id_cat_ficha_tecnica_status) {
		let estatus = this.props.estatus || [];
		var c = 'gray';
		for (let i = 0; i < estatus.length; i++) {
			if (estatus[i].id_cat_ficha_tecnica_status === id_cat_ficha_tecnica_status) {
				c = estatus[i].color;
			}
		}
		return c;
	};
	
	boton_primera_visita = (item) => {
		let BTN_P_V = '';
		let titulo = this.estatus(item.id_cat_ficha_tecnica_status, this.Usr.id_cat_tipo_usuario);
		let color = this.color(item.id_cat_ficha_tecnica_status);
		switch (Number(this.Usr.id_cat_tipo_usuario)) {
			case 1: // root
			case 2: // admin
			case 13: // admin
				BTN_P_V = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title={titulo}>
						<IconButton aria-label={titulo} onClick={() => this.primeravisita(item)}>
							<WorkOutlineOutlined style={{color: color}}/>
						</IconButton>
					</Tooltip>
				);
				break;
			case 3: // promotor
				if (FieldsJs.inArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], (item.id_cat_ficha_tecnica_status || 0))) {
					BTN_P_V = (
						<Tooltip TransitionComponent={Zoom} placement={"top"} title={titulo}>
							<IconButton aria-label={titulo} onClick={() => this.primeravisita(item)}>
								<WorkOutlineOutlined style={{color: color}}/>
							</IconButton>
						</Tooltip>
					);
				}
				break;
			case 4: // fiscal
				if (FieldsJs.inArray([2, 3, 5, 6, 7, 8, 9, 10, 11, 12], (item.id_cat_ficha_tecnica_status || 0))) {
					BTN_P_V = (
						<Tooltip TransitionComponent={Zoom} placement={"top"} title={titulo}>
							<IconButton aria-label={titulo} onClick={() => this.primeravisita(item)}>
								<WorkOutlineOutlined style={{color: color}}/>
							</IconButton>
						</Tooltip>
					);
				}
				break;
			case 5: // juridico
				if (FieldsJs.inArray([7, 8, 9, 10, 11, 12], (item.id_cat_ficha_tecnica_status || 0))) {
					BTN_P_V = (
						<Tooltip TransitionComponent={Zoom} placement={"top"} title={titulo}>
							<IconButton aria-label={titulo} onClick={() => this.primeravisita(item)}>
								<WorkOutlineOutlined style={{color: color}}/>
							</IconButton>
						</Tooltip>
					);
				}
				break;
			case 6: // tesoreria
				if (FieldsJs.inArray([7, 8, 9, 10, 11, 12], (item.id_cat_ficha_tecnica_status || 0))) {
					BTN_P_V = (
						<Tooltip TransitionComponent={Zoom} placement={"top"} title={titulo}>
							<IconButton aria-label={titulo} onClick={() => this.primeravisita(item)}>
								<WorkOutlineOutlined style={{color: color}}/>
							</IconButton>
						</Tooltip>
					);
				}
				break;
			case 7: // recursos humanos
				if (FieldsJs.inArray([7, 8, 9, 10, 11, 12], (item.id_cat_ficha_tecnica_status || 0))) {
					BTN_P_V = (
						<Tooltip TransitionComponent={Zoom} placement={"top"} title={titulo}>
							<IconButton aria-label={titulo} onClick={() => this.primeravisita(item)}>
								<WorkOutlineOutlined style={{color: color}}/>
							</IconButton>
						</Tooltip>
					);
				}
				break;
			default: break;
		}
		return BTN_P_V;
	};
	
	ver_root = () => {
		return this.Usr.id_usuario === 1;
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		return (
			<Paper className={classes.root}>
				
				<Table className={classes.table + ' desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'}>...</TableCell>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<TableCell component="th" align={'left'}>Promotor asignado</TableCell>
							) : null}
							<TableCell component="th" align={'left'}>Nombre comercial</TableCell>
							<TableCell component="th" align={'left'}>Razón social</TableCell>
							<TableCell component="th" align={'center'}>R.F.C.</TableCell>
							<TableCell component="th" align={'center'}>Correo electrónico</TableCell>
							<TableCell component="th" align={'center'}>Teléfono</TableCell>
							<TableCell component="th" align={'left'}>Giro de la empresa</TableCell>
							<TableCell component="th" align={'center'}>Activo</TableCell>
							<TableCell component="th" align={'center'} className={'w-80-px padding-5-L padding-5-R'}>
								Status
							</TableCell>
							<TableCell component="th" align={'center'} className={'w-100-px'}>
								Ficha técnica
							</TableCell>
							<TableCell component="th" align={'center'} className={'w-50-px'}>
								Acciones
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							lista.map((item, key) => {
								return (
									<Fragment key={key}>
										<TableRow>
											
											<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
												{this.props.tipo_vista === "cliente_cliente" ? (
													<div className={'w-100'} align={'center'}>
														<div style={!!item.expandir ? bg_menos : bg_mas}
														     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
														     onClick={() => this.props.SubTabla(item, key)}
														/>
													</div>
												) : null}
											</TableCell>
											
											{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
												<TableCell align={'left'}>
													<div
														className={'padding-3-B padding-3-T padding-10-L padding-10-R bg-lightgray b-r-5'}>
														{item.usuario_alta_cliente}
													</div>
												</TableCell>
											) : null}
											
											<TableCell align={'left'}>
												{Number(item.id_cat_tipo_persona) === 1 ? item.nombre_completo:item.nombre_comercial}
											</TableCell>
											
											<TableCell align={'left'}>
												{Number(item.id_cat_tipo_persona) === 1 ? item.nombre_completo:item.razon_social}
											</TableCell>
											
											<TableCell align={'center'}>
												{item.rfc}
											</TableCell>
											
											<TableCell align={'center'}>
												{item.correo_electronico}
											</TableCell>
											
											<TableCell align={'center'}>
												{item.telefono ? item.telefono:'Sin registrar'}
											</TableCell>
											
											<TableCell align={'left'}>
												{item.giro_cliente}
											</TableCell>
											
											<TableCell align={'center'}>
												{item.activo === 1 ? (
													<div style={{
														width: "50px",
														color: "white",
														borderRadius: "3px",
														padding: "2px 10px",
														background: "darkgreen",
														display: "inline-grid",
													}}>Activo</div>
												) : (
													<div style={{
														width: "50px",
														color: "white",
														borderRadius: "3px",
														padding: "2px 10px",
														background: "#901C30",
														display: "inline-grid",
													}}>Inactivo</div>
												)}
											</TableCell>
											
											<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
												<div className={'w-100'} align={'center'}>
													<Tooltip TransitionComponent={Zoom} placement={"left"}
													         title={this.estatus(item.id_cat_ficha_tecnica_status)}>
														<div className={'w-20-px h-20-px b-r-100 cursor-pointer'}
														     style={{
															     background: this.color(item.id_cat_ficha_tecnica_status)
														     }}/>
													</Tooltip>
												</div>
											</TableCell>
											
											<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
												
												<div className={'w-auto vertical-inline'}>
													
													<div className={'v-center ' + (item.id_cat_ficha_tecnica_status === null ? 'animate__animated animate__bounce animate__delay-2s animate__infinite infinite':'')}>
														{this.boton_primera_visita(item)}
													</div>
													
													{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (
														<ModalReasignarPromotor
															RefreshList={this.props.RefreshList}
															item={item} icono={(
															<div className={'v-center'}>
																<Tooltip TransitionComponent={Zoom} placement={"top"}
																         title={'Reasignar a promotor'}>
																	<IconButton>
																		<PersonOutline/>
																	</IconButton>
																</Tooltip>
															</div>
														)} showSnackBars={this.props.showSnackBars}/>
													) : null}
												
												</div>
											
											</TableCell>
											
											<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
												<div className={'w-auto vertical-inline'}>
													<div className={'v-center'}>
														<PoPoverClientePotencial
															listaClientes={this.props.listaClientes}
															tipo_vista={this.props.tipo_vista}
															eliminar={this.eliminar}
															item={item}
															RefreshList={this.props.RefreshList}
															$BroadcastModalClientePotencial={this.props.$BroadcastModalClientePotencial}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												</div>
											</TableCell>
										
										</TableRow>
										
										{this.props.tipo_vista === "cliente_cliente" ? (
											<Fragment>
												{!!item.expandir ? (
													<TableRow>
														<TableCell align={'center'}
														           className={'padding-20-L padding-20-R padding-20-T padding-20-B'}
														           colSpan={this.ver_root() ? 12 : 12}
														>
															
															<SubTablaClientePotencial
																tipo_vista={this.props.tipo_vista}
																item={item}
																showSnackBars={this.props.showSnackBars}
																RefreshList={this.props.RefreshList}
															/>
														
														</TableCell>
													</TableRow>
												) : null}
											</Fragment>
										) : null}
									
									</Fragment>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	tipo_vista: PropTypes.string.isRequired,
	lista: PropTypes.array.isRequired,
	listaClientes: PropTypes.array.isRequired,
	estatus: PropTypes.array.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	SubTabla: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(ListaTabla));
