import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import {UsuariosService} from '../../../../services/_Sis/UsuariosService/UsuariosService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PlazaService} from "../../../../services/_Cat/Plaza/PlazaService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import moment from "moment";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";

class ModalUsuarios extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			list_cat_tipo_usuario: [],
			list_plaza: [],
			id_usuario: props.id_usuario || 0,
			tipo: props.tipo,
			id_plaza: '',
			id_cat_tipo_usuario: '',
			isjefe: '',
			isjefeplaza: '',
			id_rocket_chat: '',
			usuario_rocket_chat: '',
			fecha_ingreso: null,
			dias_disponibles_vacaciones: ''
			
		};
		
		this.handleChange = this.handleChange.bind(this);
		
		this.listTipoUsuario = this.listTipoUsuario.bind(this);
		this.listPlaza = this.listPlaza.bind(this);
		
		this.save = this.save.bind(this);
		this.add = this.add.bind(this);
		this.edit = this.edit.bind(this);
		this.view = this.view.bind(this);
		
		this.BOTON_ACCION = this.BOTON_ACCION.bind(this);
		
	}
	
	showSnackBars(type, message) {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	listTipoUsuario = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoUsuario().then((response) => {
				this.setState({list_cat_tipo_usuario: response.data});
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		})
	};
	
	listPlaza = () => {
		return new Promise((resolve, reject) => {
			PlazaService.Listar().then((response) => {
				this.setState({list_plaza: response.data});
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	
	save = () => {
		if (this.state.id_usuario > 0) {
			UsuariosService.Modificar(this.state).then((response) => {
				this.modalClose();
				this.props.$BroadcastModalUsuarios({
					accion: 'list',
					status: true
				});
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			UsuariosService.Agregar(this.state).then((response) => {
				this.modalClose();
				this.props.$BroadcastModalUsuarios({
					accion: 'list',
					status: true
				});
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		
		console.log(this.props);
		
		this.listTipoUsuario();
		this.listPlaza();
		
		this.setState({
			id_usuario: '',
			username: '',
			id_rocket_chat: '',
			usuario_rocket_chat: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			correo_electronico: '',
			id_cat_tipo_usuario: '',
			activo: true,
			sendmail: true,
			isjefe: false,
			isjefeplaza: false,
			fecha_ingreso: null,
			dias_disponibles_vacaciones: '',
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {id_usuario, item} = this.props;
		
		this.listTipoUsuario().then((response) => {
			this.setState({
				id_cat_tipo_usuario: item.id_cat_tipo_usuario || ''
			});
		});
		this.listPlaza().then((response) => {
			this.setState({
				id_plaza: item.id_plaza || ''
			});
		});
		
		this.setState({
			id_usuario: id_usuario,
			username: item.username,
			id_rocket_chat: item.id_rocket_chat,
			usuario_rocket_chat: item.usuario_rocket_chat,
			nombre: item.nombre,
			apellido_paterno: item.apellido_paterno,
			apellido_materno: item.apellido_materno,
			correo_electronico: item.correo_electronico,
			activo: (Number(item.activo) === 1),
			sendmail: (Number(item.sendmail) === 1),
			isjefe: (Number(item.isjefe) === 1),
			isjefeplaza: (Number(item.isjefeplaza) === 1),
			fecha_ingreso: item.fecha_ingreso && item.fecha_ingreso !== "0000-00-00" ? moment(item.fecha_ingreso) : null,
			dias_disponibles_vacaciones: item.dias_disponibles_vacaciones ?? ''
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {id_usuario, item} = this.props;
		
		this.listTipoUsuario().then((response) => {
			this.setState({
				id_cat_tipo_usuario: item.id_cat_tipo_usuario || ''
			});
		});
		this.listPlaza().then((response) => {
			this.setState({
				id_plaza: item.id_plaza || ''
			});
		});
		
		this.setState({
			id_usuario: id_usuario,
			username: item.username,
			id_rocket_chat: item.id_rocket_chat,
			usuario_rocket_chat: item.usuario_rocket_chat,
			nombre: item.nombre,
			apellido_paterno: item.apellido_paterno,
			apellido_materno: item.apellido_materno,
			correo_electronico: item.correo_electronico,
			activo: (Number(item.activo) === 1),
			sendmail: (Number(item.sendmail) === 1),
			isjefe: (Number(item.isjefe) === 1),
			isjefeplaza: (Number(item.isjefeplaza) === 1),
			fecha_ingreso: item.fecha_ingreso && item.fecha_ingreso !== "0000-00-00" ? moment(item.fecha_ingreso) : null,
			dias_disponibles_vacaciones: item.dias_disponibles_vacaciones ?? ''
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_usuario > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_admin = () => {
		return FieldsJs.inArray([2], this.Usr.id_cat_tipo_usuario);
	};
	
	generarIDRocketChat = () => {
		console.log('hola');
		try {
			if (!FieldsJs.Field(this.state.usuario_rocket_chat)) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Usuario Rocket Chat"
				});
			}
			
			UsuariosService.idRocketChat(this.state.usuario_rocket_chat).then((response) => {
				this.setState({
					id_rocket_chat: response.data.room.rid
				})
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
		
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Usuario</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									className={'margin-0'}
									autoFocus
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view' || this.state.id_usuario > 0}
									type="text"
									margin="dense"
									helperText="Especifica el correo electrónico, sera el nombre del usuario con el que se identificara en el sistema"
									name="username"
									label="Usuario"
									value={this.state.username}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									helperText="Requerido"
									name="nombre"
									label="Nombre(s)"
									value={this.state.nombre}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									helperText="Requerido"
									name="apellido_paterno"
									label="Apellido Paterno"
									value={this.state.apellido_paterno}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									helperText="Requerido"
									name="apellido_materno"
									label="Apellido Materno"
									value={this.state.apellido_materno}
								/>
							
							</Grid>
							
							{this.state.id_usuario > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									
									<TextField
										className={'margin-0'}
										fullWidth
										onChange={this.handleChange}
										disabled={this.state.tipo === 'view'}
										type="text"
										margin="dense"
										helperText="Requerido"
										name="correo_electronico"
										label="Correo electrónico"
										value={this.state.correo_electronico}
									/>
								
								</Grid>
							) : ''}
							
							<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
								
								<TextField
									className={'margin-0'}
									select
									fullWidth
									margin="dense"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu,
										},
									}}
									helperText="Requerido"
									name="id_cat_tipo_usuario"
									label="Tipo de usuario"
									value={this.state.id_cat_tipo_usuario}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.list_cat_tipo_usuario.map(option => (
										<option key={option.id_cat_tipo_usuario} value={option.id_cat_tipo_usuario}>
											{option.tipo_usuario}
										</option>
									))}
								</TextField>
							
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<KeyboardDatePicker
									format={'dd/MM/yyyy'}
									fullWidth
									clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha de ingreso',
									}}
									label="Fecha de ingreso"
									value={this.state.fecha_ingreso}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_ingreso');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									disabled={this.props.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
								
								<TextField
									className={'margin-0'}
									select
									fullWidth
									margin="dense"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu,
										},
									}}
									helperText="Requerido"
									name="id_plaza"
									label="Plaza"
									value={this.state.id_plaza}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.list_plaza.map(option => (
										<option key={option.id_plaza} value={option.id_plaza}>
											{option.plaza}
										</option>
									))}
								</TextField>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									margin="none"
									name="dias_disponibles_vacaciones"
									label="Días de Vacaciones"
									type="text"
									fullWidth
									value={this.state.dias_disponibles_vacaciones}
									onChange={(e) => {
										this.handleChange(e);
										this.calcularTotalProducto();
									}}
									onKeyPress={EnteroSolo}
									inputProps={{maxLength: 50}}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
								
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									//helperText="Requerido"
									name="usuario_rocket_chat"
									label="Usuario Rocket Chat"
									value={this.state.usuario_rocket_chat}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
								
								<Button onClick={() => this.generarIDRocketChat()} color="primary" size={'small'} variant="contained">
									{'Generar ID'}
								</Button>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
								
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									//helperText="Requerido"
									name="id_rocket_chat"
									label="ID Rocket Chat"
									value={this.state.id_rocket_chat}
								/>
							
							</Grid>
							
							
							{this.is_root() || this.is_admin() ? (<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox type="checkbox" name='isjefe' checked={this.state.isjefe}
											          onChange={this.handleChange} value="sendmail" color="primary"
											          disabled={this.state.tipo === 'view'}/>
										}
										label={'Es jefe de area'}
									/>
								</FormGroup>
							
							</Grid>) : null}
							
							{this.is_root() || this.is_admin() ? (<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox type="checkbox" name='isjefeplaza' checked={this.state.isjefeplaza}
											          onChange={this.handleChange} value="sendmail" color="primary"
											          disabled={this.state.tipo === 'view'}/>
										}
										label={'Es jefe de plaza'}
									/>
								</FormGroup>
							
							</Grid>) : null}
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox type="checkbox" name='sendmail' checked={this.state.sendmail}
											          onChange={this.handleChange} value="sendmail" color="primary"
											          disabled={this.state.tipo === 'view'}/>
										}
										label={'Recibir correos electrónicos'}
									/>
								</FormGroup>
							
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox type="checkbox" name='activo' checked={this.state.activo}
											          onChange={this.handleChange} value="activo" color="primary"
											          disabled={this.state.tipo === 'view'}/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_usuario > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalUsuarios.propTypes = {
	tipo: PropTypes.string.isRequired,
	$BroadcastModalUsuarios: PropTypes.func.isRequired,
};

export default IntegrationNotistack(ModalUsuarios);
