import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import ModalCronograma from "./ModalCronograma";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {CronogramaService} from "../../../../services/CronogramaService/CronogramaService";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import ModalEstatus from "./ModalEstatus";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el cronograma con id ${item.id_cronograma}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				CronogramaService.Eliminar(item.id_cronograma).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					
					this.props.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	
	spanStatus = (item) => {
		let bgg = 'white';
		switch (Number(item.id_cat_cronograma_estatus)) {
			case 1:
				bgg = 'gray';
				break;
			case 2:
				bgg = '#00c1ff';
				break;
			case 3:
				bgg = '#fcee15';
				break;
			case 4:
				bgg = '#c66500';
				break;
			case 5:
				bgg = '#119703';
				break;
			default:
				bgg = '#ccc';
				break;
		}
		
		const foto_default = CONFIG.foto_default;
		
		return (
			<Fragment>
				<div className={'w-100 text-white'} style={{borderRadius: '5px', background: bgg}}>
					{item.cronograma_estatus}
				</div>
			</Fragment>
		);
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'} className={'padding-10-L'}>#Task</TableCell>
							{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (<TableCell component="th" align={'left'}>Área</TableCell>) : null}
							<TableCell component="th" align={'left'}>Fecha</TableCell>
							<TableCell component="th" align={'left'}>Proyecto</TableCell>
							<TableCell component="th" align={'left'}>Módulo</TableCell>
							<TableCell component="th" align={'left'}>Actividad</TableCell>
							<TableCell component="th" align={'left'}>Usuario(s) Asignado(s)</TableCell>
							<TableCell component="th" align={'left'}>Tiempo</TableCell>
							<TableCell component="th" align={'center'} className={'w-100-px'}>Estatus</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							lista.map((item, key) => {
								return (
									<TableRow key={key}>
										<TableCell align={'left'} className={'padding-10-L'}>{item.task}</TableCell>
										{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (<TableCell align={'left'} className={'padding-10-L'}>{item.tipo_usuario}</TableCell>) : null}
										<TableCell align={'left'}>{DateFormat.getHoraFecha(item.fecha)}</TableCell>
										<TableCell align={'left'}>{item.proyecto}</TableCell>
										<TableCell align={'left'}>{item.proyecto_modulo}</TableCell>
										<TableCell align={'left'}>{item.actividad || 'Sin registrar'}</TableCell>
										<TableCell align={'left'}>{item.usuarios.map((item2, key2) => (
												<p key={key2}>
													{item2.nombre_completo}
												</p>
											)
										)}</TableCell>
										<TableCell align={'left'}>{item.tiempo || 'Sin registrar'}</TableCell>
										<TableCell align={'center'}>{this.spanStatus(item) || 'Sin registrar'}</TableCell>
										<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
											<div className={'w-auto vertical-inline'}>
												<div className={'v-center'}>
													<ModalEstatus
														tipo={'edit'}
														item={item}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
												{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) || Number(this.Usr.id_usuario) === Number(item.id_usuario) ? (
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
															<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}><DeleteOutlined/></IconButton>
														</Tooltip>
													</div>
												) : ''}
												{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<ModalCronograma
															tipo={'edit'}
															item={item}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
															lista_empresa={this.props.lista_empresa}
														/>
													</div>
												) : ''}
												<div className={'v-center'}>
													<ModalCronograma
														tipo={'view'}
														item={item}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
														lista_empresa={this.props.lista_empresa}
													/>
												</div>
											</div>
										
										</TableCell>
									
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
