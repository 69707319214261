import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";
import moment from "moment";

export const RecordatoriosService = {
	Listar: (filtro, paginacion) => {
		let fecha_inicio = (filtro.fecha_inicio || null) ? DateFormat.FormatSql(filtro.fecha_inicio) : null;
		let fecha_fin = (filtro.fecha_fin || null) ? DateFormat.FormatSql(filtro.fecha_fin) : null;
		
		let params = {
			filtro: {
				id_recordatorio: filtro.id_recordatorio || null,
				folio: filtro.folio || null,
				id_empresa: filtro.id_empresa || null,
				id_cat_recordatorio: filtro.id_cat_recordatorio|| null,
				id_usuario: filtro.id_usuario|| null,
				fecha_notificacion: filtro.fecha_notificacion|| null,
				fecha_inicio: filtro.fecha_inicio|| null,
				fecha_fin: filtro.fecha_fin|| null,
				id_cat_frecuencia_notificacion: filtro.id_cat_frecuencia_notificacion|| null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recordatorio_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_notificacion = '';
		let fecha_fin_notificacion = '';
		if (form.fecha_notificacion != '') {
			fecha_notificacion = moment(form.fecha_notificacion).format("YYYY-MM-DD HH:mm:ss");
		}if (form.fecha_notificacion != '') {
			fecha_fin_notificacion = moment(form.fecha_fin_notificacion).format("YYYY-MM-DD HH:mm:ss");
		}
		
		
		let params = {
			id_cat_recordatorio: form.id_cat_recordatorio,
			id_empresa: form.id_empresa,
			fecha_notificacion: fecha_notificacion,
			fecha_fin_notificacion: fecha_fin_notificacion,
			id_cat_frecuencia_notificacion: form.frecuencia_notificacion,
			recordatorio: form.recordatorio,
			usuarios_asignados: form.usuarios_asignados,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recordatorio_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_notificacion = '';
		let fecha_fin_notificacion = '';
		if (form.fecha_notificacion != '') {
			fecha_notificacion = moment(form.fecha_notificacion).format("YYYY-MM-DD HH:mm:ss");
		}if (form.fecha_notificacion != '') {
			fecha_fin_notificacion = moment(form.fecha_fin_notificacion).format("YYYY-MM-DD HH:mm:ss");
		}
		
		let params = {
			id_recordatorio: form.id_recordatorio,
			id_cat_recordatorio: form.id_cat_recordatorio,
			id_empresa: form.id_empresa,
			fecha_notificacion: fecha_notificacion,
			fecha_fin_notificacion: fecha_fin_notificacion,
			id_cat_frecuencia_notificacion: form.frecuencia_notificacion,
			recordatorio: form.recordatorio,
			usuarios_asignados: form.usuarios_asignados,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recordatorio_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_recordatorio) => {
		let params = {
			id_recordatorio: id_recordatorio
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recordatorio_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Activo: (id_recordatorio, activo) => {
		let params = {
			id_recordatorio: id_recordatorio,
			activo: activo,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Recordatorio_Activo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
