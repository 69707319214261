import React, {Component, Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';

import App from './componets/App';
import LogInIndex from './componets/PageFull/LogIn';
import RegistroIndex from './componets/PageFull/Registro';
import RegistroDatosPersonalesIndex from './componets/PageFull/RegistroDatosPersonales';
import RecuperarIndex from './componets/PageFull/Recuperar';
import VerificarIndex from './componets/PageFull/Verificar';
import CambiarContrasenaIndex from './componets/PageFull/CambiarContrasena';
import CatalogosIndex from './componets/PageContent/Catalogos';
import EjecutivoAsignadoIndex from './componets/PageContent/Cat/EjecutivoAsignado';
import TipoPagoIndex from './componets/PageContent/Cat/TipoPago';
import TipoPagoComprobantePagoIndex from './componets/PageContent/Cat/TipoPagoComprobantePago';
import SexoIndex from './componets/PageContent/Cat/Sexo';
import RecordatorioIndex from './componets/PageContent/Cat/Reccordatorio';
import BancoTipoCuentaIndex from './componets/PageContent/Cat/BancoTipoCuenta';
import BancoIndex from './componets/PageContent/Cat/Banco';
import EmpresaReceptoraPagoIndex from './componets/PageContent/Cat/ClienteReceptoraPago';
import TipoUsuarioIndex from './componets/PageContent/Cat/TipoUsuario';
import GiroClienteIndex from './componets/PageContent/Cat/GiroCliente';
import FactorRiesgoImssIndex from './componets/PageContent/Cat/FactorRiesgoImss';
import FichaTecnicaStatusIndex from "./componets/PageContent/Cat/FichaTecnicaStatus";
import ArchivoElaboracionPropuestaIndex from './componets/PageContent/Cat/ArchivoElaboracionPropuesta';
import ArchivoIntegracionExpedienteIndex from './componets/PageContent/Cat/ArchivoIntegracionExpediente';
import MenuSubMenuIndex from './componets/PageContent/Cat/MenuSubMenu';
import RolesPermisosIndex from './componets/PageContent/Cat/RolesPermisos';
import FormaPagoIndex from './componets/PageContent/Cat/FormaPago';
import MetodoPagoIndex from './componets/PageContent/Cat/MetodoPago';
import ClaveProductoServicioIndex from './componets/PageContent/Cat/ClaveProductoServicio';
import ClaveUnidadMedidaIndex from './componets/PageContent/Cat/ClaveUnidadMedida';
import FacturacionEstatusIndex from './componets/PageContent/Cat/FacturacionEstatus';
import TipoComprobanteIndex from './componets/PageContent/Cat/TipoComprobante';
import TipoPersonaIndex from './componets/PageContent/Cat/TipoPersona';
import EmpresaIndex from './componets/PageContent/Cat/Empresa';
import PlazaIndex from './componets/PageContent/Cat/Plaza';
import ProyectoIndex from './componets/PageContent/Cat/Proyecto';
import ModuloIndex from './componets/PageContent/Cat/Modulo';
import PlazaDireccionIndex from './componets/PageContent/Cat/PlazaDireccion';
import ClienteIndex from './componets/PageContent/Cliente';
import FacturacionIndex from './componets/PageContent/Facturacion';
import TicketsIndex from './componets/PageContent/Tickets';
import UsuariosIndex from './componets/PageContent/Usuarios';
import AccesoIndex from './componets/PageContent/Acceso';
import RequerimientoPromotorClienteIndex from "./componets/PageContent/FichaTecnica";
import { ClientePotencialCLTPOTIndex, ClientePotencialCLTEMPIndex } from "./componets/PageContent/ClientePotencial";
import HomeIndex from './componets/PageContent/Home';
import PerfilIndex from './componets/PageContent/Perfil';
import UsuarioEnviarReporteIndex from './componets/PageContent/Cat/UsuarioEnviarReporte';
import ComprasIndex from './componets/PageContent/Compras';
import NubeIndex from './componets/PageContent/Nube';
import RecordatoriosIndex from './componets/PageContent/Reccordatorios';
import Page404Index from './componets/PageFull/Page404';
import EventListenerClickDom from './componets/Include/EventListenerClickDom/EventListenerClickDom';
import CargaCFDIIndex from "./componets/PageContent/CargaCFDI";
import CargaCFDINominaIndex from "./componets/PageContent/CargaCFDINomina";
import AdquisicionesIndex from "./componets/PageContent/Adquisiciones";
import CuentaBancariaIndex from "./componets/PageContent/CuentaBancaria";
import QuinielaIndex from "./componets/PageContent/Quiniela";
import VerQuinielaIndex from "./componets/PageContent/VerQuiniela";
import CitaIndex from "./componets/PageContent/Cita";
import RepositorioContableIndex from "./componets/PageContent/RepositorioContable";
import RepositorioContableSubirIndex from "./componets/PageContent/RepositorioContableSubir";
import RepositorioContableListarIndex from "./componets/PageContent/RepositorioContableListar";
import ResguardoIndex from "./componets/PageContent/Resguardo";
import CronogramaIndex from "./componets/PageContent/Cronograma";
import EquipoIndex from "./componets/PageContent/Cat/Equipo";
import RepositorioJuridicoIndex from "./componets/PageContent/RepositorioJuridico";
import NotificacionesIndex from "./componets/PageContent/Notificaciones";
import LineaCapturaIndex from "./componets/PageContent/LineaCaptura";
import EstadosCuentaIndex from "./componets/PageContent/EstadosCuenta";
import ImportesNetosIndex from "./componets/PageContent/ImportesNetos";
import ComprobantesPagoIndex from "./componets/PageContent/ComprobantesPago";
import ComprobantesNominaIndex from "./componets/PageContent/ComprobantesNomina";
import AccesoEspecificoIndex from "./componets/PageContent/Cat/MenuEspecifico";
import FormatosIndex from "./componets/PageContent/Formatos";
import RepseIndex from "./componets/PageContent/Repse";
import VacacionesIndex from "./componets/PageContent/Vacaciones";
import TimbradoIndex from "./componets/PageContent/Timbrado";
import TimbradoJsonIndex from "./componets/PageContent/TimbradoJson";
import ClienteFacturacionIndex from "./componets/PageContent/ClienteFacturacion";
import EmpresaFacturacionIndex from "./componets/PageContent/EmpresaFacturacion";
import TimbradoFacturacionIndex from "./componets/PageContent/TimbradoFacturacion";
import TemporalFacturacionIndex from "./componets/PageContent/TemporalFacturacion";
import TimbradoNominaIndex from "./componets/PageContent/TimbradoNomina";
import BitacoraIndex from "./componets/PageContent/Bitacora";
import CreditoProceaIndex from "./componets/PageContent/CreditoProcea";
import VacacionesInfoIndex from "./componets/PageContent/VacacionesInfo";
import ComprasServiciosIndex from "./componets/PageContent/ComprasServicios";
import SaekoAlumnosIndex from "./componets/PageContent/SaekoAlumnos";


class AppRoutes extends Component {
	
	render() {
		return (
			<Fragment>
				<EventListenerClickDom/>
				<App>
					<Switch>
						<Route exact path="/login" component={LogInIndex}/>
						<Route exact path="/registro" component={RegistroIndex}/>
						<Route exact path="/registrodatospersonales" component={RegistroDatosPersonalesIndex}/>
						<Route exact path="/recuperar" component={RecuperarIndex}/>
						<Route exact path="/verificar" component={VerificarIndex}/>
						<Route exact path="/cambiarcontrasena" component={CambiarContrasenaIndex}/>
						<Route exact path="/catalogo" component={CatalogosIndex}/>
						<Route exact path="/ejecutivoasignado" component={EjecutivoAsignadoIndex}/>
						<Route exact path="/tipopago" component={TipoPagoIndex}/>
						<Route exact path="/tipopagocomprobantepago" component={TipoPagoComprobantePagoIndex}/>
						<Route exact path="/genero" component={SexoIndex}/>
						<Route exact path="/recordatorio" component={RecordatorioIndex}/>
						<Route exact path="/bancotipocuenta" component={BancoTipoCuentaIndex}/>
						<Route exact path="/banco" component={BancoIndex}/>
						<Route exact path="/empresareceptorapago" component={EmpresaReceptoraPagoIndex}/>
						<Route exact path="/giroempresa" component={GiroClienteIndex}/>
						<Route exact path="/tipousuario" component={TipoUsuarioIndex}/>
						<Route exact path="/factorriesgoimss" component={FactorRiesgoImssIndex}/>
						<Route exact path="/statusfichatecnica" component={FichaTecnicaStatusIndex}/>
						<Route exact path="/integracionexpediente" component={ArchivoIntegracionExpedienteIndex}/>
						<Route exact path="/elaboracionpropuesta" component={ArchivoElaboracionPropuestaIndex}/>
						<Route exact path="/menus" component={MenuSubMenuIndex}/>
						<Route exact path="/rolespermisos" component={RolesPermisosIndex}/>
						<Route exact path="/formapago" component={FormaPagoIndex}/>
						<Route exact path="/metodopago" component={MetodoPagoIndex}/>
						<Route exact path="/claveproductoservicio" component={ClaveProductoServicioIndex}/>
						<Route exact path="/claveunidadmedida" component={ClaveUnidadMedidaIndex}/>
						<Route exact path="/facturacionestatus" component={FacturacionEstatusIndex}/>
						<Route exact path="/tipocomprobante" component={TipoComprobanteIndex}/>
						<Route exact path="/tipopersona" component={TipoPersonaIndex}/>
						<Route exact path="/clientes" component={ClienteIndex}/>
						<Route exact path="/empresa" component={EmpresaIndex}/>
						<Route exact path="/plaza" component={PlazaIndex}/>
						<Route exact path="/plaza_direccion" component={PlazaDireccionIndex}/>
						<Route exact path="/facturacion" component={FacturacionIndex}/>
						<Route exact path="/tickets" component={TicketsIndex}/>
						<Route exact path="/usuario" component={UsuariosIndex}/>
						<Route exact path="/acceso" component={AccesoIndex}/>
						<Route exact path="/fichatecnica/:id_cliente" component={RequerimientoPromotorClienteIndex}/>
						<Route exact path="/clientepotencial" component={ClientePotencialCLTPOTIndex}/>
						<Route exact path="/empresacliente" component={ClientePotencialCLTEMPIndex}/>
						<Route exact path="/home" component={HomeIndex}/>
						<Route exact path="/perfil" component={PerfilIndex}/>
						<Route exact path="/recordatorios" component={RecordatoriosIndex}/>
						<Route exact path="/usuarioenviarreporte" component={UsuarioEnviarReporteIndex}/>
						<Route exact path="/gastos" component={ComprasIndex}/>
						<Route exact path="/nube" component={NubeIndex}/>
						<Route exact path="/cfdi" component={CargaCFDIIndex}/>
						<Route exact path="/cfdinomina" component={CargaCFDINominaIndex}/>
						<Route exact path="/controlbienes" component={AdquisicionesIndex}/>
						<Route exact path="/cuentabancaria" component={CuentaBancariaIndex}/>
						<Route exact path="/quiniela" component={QuinielaIndex}/>
						<Route exact path="/verquiniela/:id_quiniela" component={VerQuinielaIndex}/>
						<Route exact path="/citas" component={CitaIndex}/>
						<Route exact path="/repositorio_contable" component={RepositorioContableIndex}/>
						<Route exact path="/repositorio_contable_subir" component={RepositorioContableSubirIndex}/>
						<Route exact path="/repositorio_contable_listar" component={RepositorioContableListarIndex}/>
						<Route exact path="/cronograma" component={CronogramaIndex}/>
						<Route exact path="/proyecto" component={ProyectoIndex}/>
						<Route exact path="/modulo" component={ModuloIndex}/>
						<Route exact path="/resguardo" component={ResguardoIndex}/>
						<Route exact path="/equipo" component={EquipoIndex}/>
						<Route exact path="/repositorio_juridico" component={RepositorioJuridicoIndex}/>
						<Route exact path="/notificaciones" component={NotificacionesIndex}/>
						<Route exact path="/lineacaptura" component={LineaCapturaIndex}/>
						<Route exact path="/importesnetos" component={ImportesNetosIndex}/>
						<Route exact path="/estadoscuenta" component={EstadosCuentaIndex}/>
						<Route exact path="/comprobantespago" component={ComprobantesPagoIndex}/>
						<Route exact path="/comprobantesnomina" component={ComprobantesNominaIndex}/>
						<Route exact path="/accesoespecifico" component={AccesoEspecificoIndex}/>
						<Route exact path="/formatos" component={FormatosIndex}/>
						<Route exact path="/repse" component={RepseIndex}/>
						<Route exact path="/vacaciones" component={VacacionesIndex}/>
						<Route exact path="/timbrado" component={TimbradoIndex}/>
						<Route exact path="/timbrado/:id_facturacion_cfdi_temporal" component={TimbradoIndex}/>
						<Route exact path="/timbradojson" component={TimbradoJsonIndex}/>
						<Route exact path="/clientefacturacion" component={ClienteFacturacionIndex}/>
						<Route exact path="/empresafacturacion" component={EmpresaFacturacionIndex}/>
						<Route exact path="/timbradofacturacion" component={TimbradoFacturacionIndex}/>
						<Route exact path="/temporalfacturacion" component={TemporalFacturacionIndex}/>
						<Route exact path="/timbradonomina" component={TimbradoNominaIndex}/>
						<Route exact path="/bitacora" component={BitacoraIndex}/>
						<Route exact path="/creditoprocea" component={CreditoProceaIndex}/>
						<Route exact path="/vacacionesinfo" component={VacacionesInfoIndex}/>
						<Route exact path="/comprasservicios" component={ComprasServiciosIndex}/>
						
						<Route exact path="/saeko_alumnos" component={SaekoAlumnosIndex}/>
						
						<Route exact path="/" component={LogInIndex}/>
						<Route component={Page404Index}/>
					</Switch>
				</App>
			</Fragment>
		);
	}
}

export default AppRoutes;
