import React, {Component, Fragment} from 'react';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {HdrWeakOutlined, Mail, Person, Phone} from "@material-ui/icons";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import * as PropTypes from "prop-types";
import {QuinielaService} from "../../../../services/QuinielaService/QuinielaService";
import {KeyboardDatePicker} from "@material-ui/pickers";

class ModalQuiniela extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			//Adquisicion
			id_quiniela: (props.item || '').id_quiniela,
			id_quiniela_miembro: (props.item || '').id_quiniela_miembro,
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			correo_electronico: '',
			celular: '',
			id_cat_piloto: '',
			id_cat_escuderia: '',
			fecha_ingreso: null,
			
			activo: true,
			
			cat_escuderia: [],
			cat_piloto: [],
			
			cat_tipo_adquisicion: [],
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
			
			this.ListBanco();
			
			if (this.state.id_cat_estado > 0)
				this.ListMunicipio(null, this.state.id_cat_estado);
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	ListaPiloto = () => {
		CatService.ListPiloto().then((response) => {
			this.setState({
				cat_piloto: response.data,
			});
		});
	};
	
	ListaEscuderia = () => {
		CatService.ListEscuderia().then((response) => {
			this.setState({
				cat_escuderia: response.data,
			});
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	save = () => {
		if (this.state.id_quiniela_miembro > 0) {
			QuinielaService.ModificarMiembro(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			QuinielaService.AgregarMiembro(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		const {item} = this.props;
		
		this.ListaPiloto();
		this.ListaEscuderia();
		
		this.setState({
			//Adquisicion
			id_quiniela: item.id_quiniela || '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			correo_electronico: '',
			celular: '',
			id_cat_piloto: '',
			id_cat_escuderia: '',
			fecha_ingreso: null,
			activo: true,
			
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		
		this.ListaPiloto();
		this.ListaEscuderia();
		
		this.setState({
			//Adquisicion
			id_quiniela: item.id_quiniela || '',
			nombre: item.nombre,
			apellido_paterno: item.apellido_paterno,
			apellido_materno: item.apellido_materno,
			correo_electronico: item.correo_electronico,
			celular: item.celular,
			id_cat_piloto: item.id_cat_piloto,
			id_cat_escuderia: item.id_cat_escuderia,
			activo: true,
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		
		this.ListaPiloto();
		this.ListaEscuderia();
		
		this.setState({
			//Adquisicion
			id_quiniela: item.id_quiniela || '',
			nombre: item.nombre,
			apellido_paterno: item.apellido_paterno,
			apellido_materno: item.apellido_materno,
			correo_electronico: item.correo_electronico,
			celular: item.celular,
			id_cat_piloto: item.id_cat_piloto,
			id_cat_escuderia: item.id_cat_escuderia,
			activo: true,
			
		});
		this.open();
	};
	
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_quiniela_miembro}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Miembro'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Person className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											required
											name="nombre"
											label="Nombre(s)"
											value={this.state.nombre}
										/>
									
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Person className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											required
											name="apellido_paterno"
											label="Apellido Paterno"
											value={this.state.apellido_paterno}
										/>
									
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Person className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											name="apellido_materno"
											label="Apellido Materno"
											value={this.state.apellido_materno}
										/>
									
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Mail className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											name="correo_electronico"
											label="Correo electrónico"
											value={this.state.correo_electronico}
										/>
									
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Phone className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="celular"
											onChange={this.handleChange}
											inputProps={{maxLength: 10}}
											onKeyPress={EnteroSolo}
											label="Celular" autoComplete={'off'}
											value={this.state.celular}
											disabled={this.props.tipo === 'view'}
										/>
									
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								
								<KeyboardDatePicker
									fullWidth
									clearable
									format={'dd/MM/yyyy'}
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									label="Fecha de ingreso"
									value={this.state.fecha_ingreso}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_ingreso');
									}}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Escudería</InputLabel>
											<Select
												native
												value={this.state.id_cat_escuderia}
												onChange={this.handleChange}
												name="id_cat_escuderia"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_escuderia.map(option => (
													<option key={option.id_cat_escuderia}
													        value={option.id_cat_escuderia}>
														{option.escuderia}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Piloto</InputLabel>
											<Select
												native
												value={this.state.id_cat_piloto}
												onChange={this.handleChange}
												name="id_cat_piloto"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_piloto.map(option => (
													<option key={option.id_cat_piloto}
													        value={option.id_cat_piloto}>
														{option.piloto}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<BotonAccionFooterModal
							id={this.state.id_quiniela_miembro}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalQuiniela.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalQuiniela;
