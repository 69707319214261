import React, {Component, Fragment} from 'react';

import Typography from "@material-ui/core/Typography";
import ListaTabla from './Includes/ListaTabla';
import ModalCita from './Includes/ModalCita';

import {CitaService} from '../../../services/CitaService/CitaService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../settings/Config/Config";
import Paginacion from "../../Include/Paginacion/Paginacion";
import FiltrosCita from "./Includes/FiltrosCita";
import {BotonActualizarLista, BotonExportarListaExcel, BotonExportarListaPDF, CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import {CatService} from "../../../services/_Cat/CatService/CatService";


class Cita extends Component {
	
	Usr = {};
	
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			listar_citas: [],
			lista_empresa: [],
			
			filtro: {
				usuario: '',
				correo_electronico: '',
				id_cat_tipo_usuario: '',
				activo: 1
			},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
		};
		
		this.Listar = this.Listar.bind(this);
		this.$BroadcastModalCita = this.$BroadcastModalCita.bind(this);
		
		this.RefreshList();
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
		this.ListEmpresa();
	};
	
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	
	$BroadcastModalCita = (data) => {
		console.warn('$BroadcastModalCita::', data);
		switch (data.accion) {
			case 'delete':
				if (data.status === true) {
					this.Eliminar(data.item);
				}
				break;
			case 'list':
				if (data.status === true) {
					this.Listar();
				}
				break;
			default:
				if (data.status === true) {
					this.Listar();
				}
		}
	};
	
	Listar = () => {
		CitaService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			
			this.setState({listar_citas: response.data});
			
		}).catch((error) => {
			
			this.setState({listar_citas: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	ListEmpresa = () => {
		CatService.ListEmpresa().then((response) => {
			this.setState({
				lista_empresa: response.data
			});
		});
	};
	
	Eliminar = (item) => {
		
		let msg = `¿Deseas eliminar el usuario ${item.nombre} ${item.apellido_paterno} ${item.apellido_materno}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				CitaService.Eliminar(item.id_usuario).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'Entrevistas SAT'}
					/*botonPDF={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaPDF onClick={() => {
								
								}}/>
							) : ''}
						</Fragment>
					}
					botonEXCEL={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaExcel onClick={() => {
								
								}}/>
							) : ''}
						</Fragment>
					}*/
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() => {
								this.Listar();
							}}/>
						</Fragment>
					}
				/>
				{/*<FiltrosCita
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>*/}
				
				<div className={'form margin-30-B'}>
					
					<Typography variant={'h6'} className={'margin-30-T margin-30-B'}>
					
					</Typography>
					
					{this.state.listar_citas.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_citas}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
								lista_empresa={this.state.lista_empresa}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				<ModalCita
					tipo={'add'}
					lista_empresa={this.state.lista_empresa}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>
			
			</div>
		);
	}
}

Cita.propTypes = {};

export default IntegrationNotistack(Cita);
