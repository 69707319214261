import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import {FichaTecnicaService} from "../../../../services/FichaTecnicaService/FichaTecnicaService";
import {ClientePotencialService} from '../../../../services/ClientePotencialService/ClientePotencialService';
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import moment from "moment";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import {SyncOutlined} from "@material-ui/icons";
import {CONFIG} from "../../../../settings/Config/Config";

class AgendarFechaFirmaContrato extends Component {
	
	state = {};
	
	Usr = {};
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			id_cliente: props.id_cliente,
			
			id_ficha_tecnica: '',
			cliente_agendar_firma_contrato: [],
			cliente_agendar_firma_contrato_ias: [],
			total_contratos_individuales: '',
			total_contratos_individuales_ias: '',
			
			id_cliente_agendar_firma_contrato: null,
			agendar_cantidad_empleado: '',
			agendar_domicilio: '',
			agendar_fecha_hora: null,
			observaciones: '',
			
			agendar_cantidad_empleado_ias: '',
			agendar_domicilio_ias: '',
			agendar_fecha_hora_ias: null,
			observaciones_ias: '',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			FichaTecnicaService.InitDato(this.state.id_cliente).then((response) => {
				
				let dato = FieldsJs.Copy(response.data);
				
				if (response.visible === 0) {
					
					this.setState({
						visible: 0,
						mensaje: response.message
					});
					
				} else {
					
					let cliente = dato.cliente || {};
					let ficha_tecnica_prestacion = dato.ficha_tecnica_prestacion;
					let ft = dato.ficha_tecnica || {};
					let ficha_tecnica_archivo_elaboracion_propuesta = dato.ficha_tecnica_archivo_elaboracion_propuesta;
					let ficha_tecnica_archivo_integracion_expediente = dato.ficha_tecnica_archivo_integracion_expediente;
					let ficha_tecnica_archivo_propuesta_fiscal = dato.ficha_tecnica_archivo_propuesta_fiscal;
					let ficha_tecnica_otro_impuesto = dato.ficha_tecnica_otro_impuesto;
					let ficha_tecnica_historial_accion_usuario = dato.ficha_tecnica_historial_accion_usuario || [];
					let lista_ejecutivo_asignado_tesoreria = dato.ejecutivo_asignado_tesoreria || [];
					let ficha_tecnica_cantidad_empleado_banco = dato.ficha_tecnica_cantidad_empleado_banco || [];
					let cliente_contratos_individuales = dato.cliente_contratos_individuales || [];
					let cliente_agendar_firma_contrato = dato.cliente_agendar_firma_contrato || [];
					let cliente_agendar_firma_contrato_ias = dato.cliente_agendar_firma_contrato_ias || [];
					let cat_tipo_pago = dato.cat_tipo_pago || [];
					
					let id_plaza_pertenece_cliente = '';
					if (FieldsJs.Array(dato.plaza_pertenece_cliente)) {
						id_plaza_pertenece_cliente = dato.plaza_pertenece_cliente.id_plaza;
					}
					
					let id_plaza_opera_nomina = '';
					if (FieldsJs.Array(dato.plaza_opera_nomina)) {
						id_plaza_opera_nomina = dato.plaza_opera_nomina.id_plaza;
					}
					
					for (let i = 0; i < ficha_tecnica_prestacion.length; i++) {
						var it = ficha_tecnica_prestacion[i];
						it.porcentaje = it.porcentaje || '';
						it.pago_efectivo = (it.pago_efectivo === 1);
						it.vales = (it.vales === 1);
						it.prestaciones = (it.prestaciones === 1);
						it.frecuencia = (it.frecuencia === 1);
						it.fijo_variable = it.fijo_variable || '';
						it.integra_sbc = (it.integra_sbc === 1);
					}
					
					this.setState({
						
						cliente: dato.cliente,
						
						id_ficha_tecnica: ft.id_ficha_tecnica ? Number(ft.id_ficha_tecnica) : '',
						id_cat_ficha_tecnica_status: ft.id_cat_ficha_tecnica_status ? Number(ft.id_cat_ficha_tecnica_status) : '',
						
						actividad_ante_sat: ft.actividad_ante_sat || '',
						rfc: ft.rfc || dato.cliente.rfc || '',
						servicio_requerido_propuesto: ft.servicio_requerido_propuesto || '',
						ficha_tecnica_archivo_elaboracion_propuesta: ficha_tecnica_archivo_elaboracion_propuesta || [],
						ficha_tecnica_archivo_integracion_expediente: ficha_tecnica_archivo_integracion_expediente || [],
						ficha_tecnica_archivo_propuesta_fiscal: ficha_tecnica_archivo_propuesta_fiscal || [],
						
						id_plaza_pertenece_cliente: id_plaza_pertenece_cliente || '',
						id_plaza_opera_nomina: id_plaza_opera_nomina || '',
						
						sueldo_salario: (ft.sueldo_salario === 1),
						cooperativa: (ft.cooperativa === 1),
						honorarios: (ft.honorarios === 1),
						sociedad_civil: (ft.sociedad_civil === 1),
						asimilados_salario: (ft.asimilados_salario === 1),
						outsourcing: (ft.outsourcing === 1),
						otros: ft.otros || '',
						
						id_cat_periodo_pago: ft.id_cat_periodo_pago || '',
						impuesto_sobre_nomina: ft.impuesto_sobre_nomina || 0,
						
						otros_impuestos: (ficha_tecnica_otro_impuesto.length > 0),
						otros_impuestos_nombre: '',
						otros_impuestos_porcentaje: '',
						
						ficha_tecnica_otro_impuesto: ficha_tecnica_otro_impuesto || [],
						
						id_cat_factor_riesgo_imss: ft.id_cat_factor_riesgo_imss || '',
						id_cat_estado_entidad_trabajo: ft.id_cat_estado_entidad_trabajo || '',
						id_cat_municipio_entidad_trabajo: ft.id_cat_municipio_entidad_trabajo || '',
						
						cooperativa_alta_imss: (ft.cooperativa_alta_imss === 1),
						cooperativa_porcentaje_comision_paga_trabajador: ft.cooperativa_porcentaje_comision_paga_trabajador || '',
						
						trabajador_entrega_recibo_honorarios: (ft.trabajador_entrega_recibo_honorarios === 1),
						pago_realiza_fuera_es_no_deducible: (ft.pago_realiza_fuera_es_no_deducible === 1),
						outsourcing_porcentaje_paga_servicio: ft.outsourcing_porcentaje_paga_servicio || '',
						asimilados_hace_retencion_isr: (ft.asimilados_hace_retencion_isr === 1),
						tienen_otras_prestaciones_aparte_salario: (ft.tienen_otras_prestaciones_aparte_salario === 1),
						
						vacaciones_ley_o_especiales_cuantos_dias: ft.vacaciones_ley_o_especiales_cuantos_dias || '',
						prima_vacacional_ley_o_especiales_cuantos_dias: ft.prima_vacacional_ley_o_especiales_cuantos_dias || '',
						gratificacion_anual_ley_o_especiales_cuantos_dias: ft.gratificacion_anual_ley_o_especiales_cuantos_dias || '',
						
						ficha_tecnica_prestacion: ficha_tecnica_prestacion || [],
						
						comentario_adicional_elaboracion_propuesta: ft.comentario_adicional_elaboracion_propuesta || '',
						
						lista_cat_factor_riesgo_imss: dato.cat_factor_riesgo_imss,
						lista_cat_periodo_pago: dato.cat_periodo_pago,
						lista_plazas: dato.plazas,
						lista_cat_estado: dato.cat_estado,
						lista_cat_municipio: dato.cat_municipio,
						lista_cat_banco: dato.cat_banco,
						lista_cat_promotores: dato.cat_promotores,
						
						
						fecha_firma_contrato: ft.fecha_firma_contrato ? moment(ft.fecha_firma_contrato) : null,
						
						constancia_de_situacion_fiscal_base64: '',
						constancia_de_situacion_fiscal_formato: (ft.constancia_de_situacion_fiscal || '').split('.')[1],
						constancia_de_situacion_fiscal: ft.constancia_de_situacion_fiscal,
						
						comprobante_de_domicilio_base64: '',
						comprobante_de_domicilio_formato: (ft.comprobante_de_domicilio || '').split('.')[1],
						comprobante_de_domicilio: ft.comprobante_de_domicilio,
						
						acta_constitutiva_de_la_cliente_base64: '',
						acta_constitutiva_de_la_cliente_formato: (ft.acta_constitutiva_de_la_cliente || '').split('.')[1],
						acta_constitutiva_de_la_cliente: ft.acta_constitutiva_de_la_cliente,
						
						poder_del_representante_legal_base64: '',
						poder_del_representante_legal_formato: (ft.poder_del_representante_legal || '').split('.')[1],
						poder_del_representante_legal: ft.poder_del_representante_legal,
						
						identificacion_del_repesentante_legal_base64: '',
						identificacion_del_repesentante_legal_formato: (ft.identificacion_del_repesentante_legal || '').split('.')[1],
						identificacion_del_repesentante_legal: ft.identificacion_del_repesentante_legal,
						
						fecha_alta_imss: ft.fecha_alta_imss ? moment(ft.fecha_alta_imss) : null,
						confirmacion_fecha_primera_dispersion: ft.confirmacion_fecha_primera_dispersion ? moment(ft.confirmacion_fecha_primera_dispersion) : null,
						id_cat_periodo_pago_tipo_nomina: ft.id_cat_periodo_pago_tipo_nomina || '',
						comision_propuesta: ft.comision_propuesta || '',
						ficha_tecnica_cantidad_empleado_banco: ficha_tecnica_cantidad_empleado_banco || [],
						iva: ft.iva || '',
						retencion_isr_en_caso_de_ias: ft.retencion_isr_en_caso_de_ias || '',
						retencion_isn_en_caso_de_ias: ft.retencion_isn_en_caso_de_ias || '',
						
						
						lista_ejecutivo_asignado_tesoreria: lista_ejecutivo_asignado_tesoreria,
						id_ejecutivo_asignado_tesoreria: cliente.id_ejecutivo_asignado_tesoreria || '',
						
						cat_tipo_pago: cat_tipo_pago,
						cliente_que_paga: dato.cliente_que_paga || [],
						ep_cliente_que_paga: '',
						ep_tipo_pago: '',
						ep_id_ejecutivo_asignado: '',
						// ep_id_cat_tipo_pago: '',
						
						cliente_que_factura: dato.cliente_que_factura || [],
						ef_cliente_que_factura: '',
						ef_clave_producto_o_servicio: '',
						ef_concepto: '',
						ef_porcentaje: '',
						// ef_id_ejecutivo_asignado: '',
						
						/* J U R I D I C O */
						total_contratos_individuales: cliente.total_contratos_individuales || '',
						total_contratos_individuales_ias: cliente.total_contratos_individuales_ias || '',
						total_contratos_individuales_elaborados: '',
						total_contratos_individuales_pendientes: '',
						cantidad_elaborado: '',
						
						excel_elaborado_base64: '',
						excel_elaborado_base64Tipo: '',
						excel_elaborado_archivo: '',
						excel_elaborado_formato: '',
						
						excel_pendiente_base64: '',
						excel_pendiente_base64Tipo: '',
						excel_pendiente_archivo: '',
						excel_pendiente_formato: '',
						
						comentario_general: '',
						
						cliente_contratos_individuales: cliente_contratos_individuales || [],
						
						comentario_juridico_contratos_individuales: cliente.comentario_juridico_contratos_individuales || '',
						
						cliente_agendar_firma_contrato: cliente_agendar_firma_contrato || [],
						cliente_agendar_firma_contrato_ias: cliente_agendar_firma_contrato_ias || [],
						
						
						siroc_active: ((ft.sueldo_salario === 1) || (ft.outsourcing === 1)),
						siroc: (Number(ft.siroc) === 1),
						
						comentario_observacion_desarrollador: '', // COMENTARIO PARA EL DESAROOLLADOR
						ficha_tecnica_historial_accion_usuario: ficha_tecnica_historial_accion_usuario, // HISTORIAL PARA EL DESAROOLLADOR
						
					});
					
				}
				
				setTimeout(() => this.openModal());
				
				resolve(true);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
				reject(false);
			});
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	agregar_cliente_agendar_firma_contrato = () => {
		
		try {
			
			let arr_temp = FieldsJs.Copy(this.state.cliente_agendar_firma_contrato);
			
			let agendar_cantidad_empleado = this.state.agendar_cantidad_empleado;
			let agendar_domicilio = this.state.agendar_domicilio;
			let agendar_fecha_hora = this.state.agendar_fecha_hora ? DateFormat.FormatSql(this.state.agendar_fecha_hora, true) : null;
			let observaciones = this.state.observaciones;
			
			if (!agendar_cantidad_empleado) {
				throw Object({
					status: false,
					mensaje: "Campo requeridos: Cantidad de empleados"
				});
			}
			
			if (!agendar_domicilio) {
				throw Object({
					status: false,
					mensaje: "Campo requeridos: Dirección / Lugar de firma"
				});
			}
			
			if (!agendar_fecha_hora) {
				throw Object({
					status: false,
					mensaje: "Campo requeridos: Fecha y hora de firma"
				});
			}
			
			/*if (!observaciones) {
				throw Object({
					status: false,
					mensaje: "Campo requeridos: Observaciones"
				});
			}*/
			
			let data = {
				id_cliente_agendar_firma_contrato: null,
				id_cliente: this.state.id_cliente,
				id_cat_cliente_agendar_firma_contrato_status: null,
				cliente_agendar_firma_contrato_status: null,
				color: '',
				agendar_cantidad_empleado: agendar_cantidad_empleado || '',
				agendar_domicilio: agendar_domicilio || '',
				agendar_fecha_hora: agendar_fecha_hora || '',
				observaciones: observaciones || '',
				
			};
			
			ClientePotencialService.GuardarFechaFirmaContratosIndividualesGuardar(data, this.state.id_cliente, this.state.id_ficha_tecnica).then((response) => {
				
				let data = response.data || {};
				
				arr_temp.push(data);
				
				this.setState({
					id_cliente_agendar_firma_contrato: '',
					agendar_cantidad_empleado: '',
					agendar_domicilio: '',
					agendar_fecha_hora: null,
					observaciones: '',
					cliente_agendar_firma_contrato: arr_temp,
				});
				
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
		
	};
	
	agregar_cliente_agendar_firma_contrato_ias = () => {
		
		try {
			
			let arr_temp = FieldsJs.Copy(this.state.cliente_agendar_firma_contrato_ias);
			
			let agendar_cantidad_empleado = this.state.agendar_cantidad_empleado_ias;
			let agendar_domicilio = this.state.agendar_domicilio_ias;
			let agendar_fecha_hora = this.state.agendar_fecha_hora_ias ? DateFormat.FormatSql(this.state.agendar_fecha_hora_ias, true) : null;
			let observaciones = this.state.observaciones_ias;
			
			if (!agendar_cantidad_empleado) {
				throw Object({
					status: false,
					mensaje: "Campo requeridos: Cantidad de empleados"
				});
			}
			
			if (!agendar_domicilio) {
				throw Object({
					status: false,
					mensaje: "Campo requeridos: Dirección / Lugar de firma"
				});
			}
			
			if (!agendar_fecha_hora) {
				throw Object({
					status: false,
					mensaje: "Campo requeridos: Fecha y hora de firma"
				});
			}
			
			/*if (!observaciones) {
				throw Object({
					status: false,
					mensaje: "Campo requeridos: Observaciones"
				});
			}*/
			
			let data = {
				id_cliente_agendar_firma_contrato_ias: null,
				id_cliente: this.state.id_cliente,
				id_cat_cliente_agendar_firma_contrato_status: null,
				cliente_agendar_firma_contrato_status: null,
				color: '',
				agendar_cantidad_empleado: agendar_cantidad_empleado || '',
				agendar_domicilio: agendar_domicilio || '',
				agendar_fecha_hora: agendar_fecha_hora || '',
				observaciones: observaciones || '',
				
			};
			
			ClientePotencialService.GuardarFechaFirmaContratosIndividualesGuardarIAS(data, this.state.id_cliente, this.state.id_ficha_tecnica).then((response) => {
				
				let data = response.data || {};
				
				arr_temp.push(data);
				
				this.setState({
					id_cliente_agendar_firma_contrato_ias: '',
					agendar_cantidad_empleado_ias: '',
					agendar_domicilio_ias: '',
					agendar_fecha_hora_ias: null,
					observaciones_ias: '',
					cliente_agendar_firma_contrato_ias: arr_temp,
				});
				
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
		
	};
	
	eliminar_cliente_agendar_firma_contrato = (item, key) => {
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, "¿Deseas eliminar este registro?").then((r) => {
			if (r.button === 'Aceptar') {
				ClientePotencialService.GuardarFechaFirmaContratosIndividualesEliminar(item, this.state.id_cliente).then((response) => {
					var arr_temp = FieldsJs.Copy(this.state.cliente_agendar_firma_contrato);
					arr_temp.splice(key, 1);
					this.setState({
						cliente_agendar_firma_contrato: arr_temp
					});
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	eliminar_cliente_agendar_firma_contrato_ias = (item, key) => {
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, "¿Deseas eliminar este registro?").then((r) => {
			if (r.button === 'Aceptar') {
				ClientePotencialService.GuardarFechaFirmaContratosIndividualesEliminarIAS(item, this.state.id_cliente).then((response) => {
					var arr_temp = FieldsJs.Copy(this.state.cliente_agendar_firma_contrato_ias);
					arr_temp.splice(key, 1);
					this.setState({
						cliente_agendar_firma_contrato_ias: arr_temp
					});
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	completado_cliente_agendar_firma_contrato = (item, key) => {
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, "¿Deseas completar este registro, confirmando que se realizo la firma de contratos?").then((r) => {
			if (r.button === 'Aceptar') {
				ClientePotencialService.GuardarFechaFirmaContratosIndividualesCompletado(item, this.state.id_cliente).then((response) => {
					let data = response.data || {};
					var arr_temp = FieldsJs.Copy(this.state.cliente_agendar_firma_contrato);
					for (let i = 0; i < arr_temp.length; i++) {
						let item = arr_temp[i];
						if (Number(item.id_cliente_agendar_firma_contrato) === Number(data.id_cliente_agendar_firma_contrato)) {
							arr_temp[i] = data;
						}
					}
					setTimeout(() => this.setState({
						cliente_agendar_firma_contrato: arr_temp
					}));
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	completado_cliente_agendar_firma_contrato_ias = (item, key) => {
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, "¿Deseas completar este registro, confirmando que se realizo la firma de contratos?").then((r) => {
			if (r.button === 'Aceptar') {
				ClientePotencialService.GuardarFechaFirmaContratosIndividualesCompletadoIAS(item, this.state.id_cliente).then((response) => {
					let data = response.data || {};
					var arr_temp = FieldsJs.Copy(this.state.cliente_agendar_firma_contrato_ias);
					for (let i = 0; i < arr_temp.length; i++) {
						let item = arr_temp[i];
						if (Number(item.id_cliente_agendar_firma_contrato_ias) === Number(data.id_cliente_agendar_firma_contrato_ias)) {
							arr_temp[i] = data;
						}
					}
					setTimeout(() => this.setState({
						cliente_agendar_firma_contrato_ias: arr_temp
					}));
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	confirmar_fecha_firma_contratos_individuales = () => {
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, "¿Deseas confirmar las fechas de firma para los contratos individuales?").then((r) => {
			if (r.button === 'Aceptar') {
				ClientePotencialService.GuardarFechaFirmaContratosIndividualesConfirmar(this.state.id_cliente, this.state.id_ficha_tecnica).then((response) => {
					
						//this.props.RefreshList();
					
					//this.close();
					
					this.showSnackBars('success', response.mensaje);
					
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	confirmar_fecha_firma_contratos_individuales_ias = () => {
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, "¿Deseas confirmar las fechas de firma para los contratos?").then((r) => {
			if (r.button === 'Aceptar') {
				ClientePotencialService.GuardarFechaFirmaContratosIndividualesConfirmarIAS(this.state.id_cliente, this.state.id_ficha_tecnica).then((response) => {
					
					//this.props.RefreshList();
					
					//this.close();
					
					this.showSnackBars('success', response.mensaje);
					
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	total_agendados = () => {
		let total = 0;
		for (let i = 0; i < this.state.cliente_agendar_firma_contrato.length; i++) {
			let item = this.state.cliente_agendar_firma_contrato[i];
			total += Number(item.agendar_cantidad_empleado);
		}
		return total;
	};
	
	total_agendados_ias = () => {
		let total = 0;
		for (let i = 0; i < this.state.cliente_agendar_firma_contrato_ias.length; i++) {
			let item = this.state.cliente_agendar_firma_contrato_ias[i];
			total += Number(item.agendar_cantidad_empleado);
		}
		return total;
	};
	
	por_agendar = () => {
		return Number(this.state.total_contratos_individuales) - this.total_agendados();
	};
	
	por_agendar_ias = () => {
		return Number(this.state.total_contratos_individuales_ias) - this.total_agendados_ias();
	};
	
	disabledFieldPromotorAgendarFechaYFirmaContratoIndividualBoolen = () => {
		
		let disabledFieldPromotorAgendarFechaYFirmaContratoIndividual = true;
		
		if (FieldsJs.inArray([1, 2, 3], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldPromotorAgendarFechaYFirmaContratoIndividual = false;
		}
		
		return disabledFieldPromotorAgendarFechaYFirmaContratoIndividual;
	};
	
	render() {
		
		const disabledFieldPromotorAgendarFechaYFirmaContratoIndividual = this.disabledFieldPromotorAgendarFechaYFirmaContratoIndividualBoolen();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Typography className={'px-20'}>
									<Fab size="small" color="secondary" aria-label="Actualizar" onClick={() => this.RefreshInit()} style={{marginRight: '15px'}}>
										<SyncOutlined/>
									</Fab>
									{this.props.titulo}
								</Typography>
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<fieldset className={'borde-punteado-lightgray-2'}>
									<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> SYS</legend>
									
									<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
										
										{!disabledFieldPromotorAgendarFechaYFirmaContratoIndividual ? (
											<Fragment>
												<Grid item className={'text-left'} xs={12} sm={2} md={2} lg={2} xl={2}>
													<TextField
														required
														fullWidth
														onChange={this.handleChange}
														type="text"
														margin="none"
														
														helperText=""
														name="agendar_cantidad_empleado"
														label="Cantidad de empleado"
														value={this.state.agendar_cantidad_empleado}
														disabled={disabledFieldPromotorAgendarFechaYFirmaContratoIndividual}
														onKeyPress={EnteroSolo}
													/>
												</Grid>
												
												<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
													<TextField
														required
														fullWidth
														onChange={this.handleChange}
														type="text"
														margin="none"
														
														helperText=""
														name="agendar_domicilio"
														label="Dirección / Lugar de firma"
														value={this.state.agendar_domicilio}
														disabled={disabledFieldPromotorAgendarFechaYFirmaContratoIndividual}
													/>
												</Grid>
												
												<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
													<KeyboardDateTimePicker
														format="yyyy/MM/dd hh:mm a" fullWidth required clearable
														inputProps={{readOnly: true}}
														KeyboardButtonProps={{
															'aria-label': 'Fecha y hora de firma',
														}}
														label="Fecha y hora de firma"
														value={this.state.agendar_fecha_hora}
														onChange={(date) => {
															this.handleChange(null, null, null, date, 'agendar_fecha_hora');
														}}
														minDate={moment(new Date())}
														disabled={disabledFieldPromotorAgendarFechaYFirmaContratoIndividual}
														clearLabel={'Limpiar'}
														okLabel={'Aceptar'}
														cancelLabel={'Cancelar'}
														onError={console.log}
													
													/>
												</Grid>
												
												<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
													<TextField
														fullWidth
														onChange={this.handleChange}
														type="text"
														margin="none"
														
														helperText=""
														name="observaciones"
														label="Observaciones"
														value={this.state.observaciones}
														disabled={disabledFieldPromotorAgendarFechaYFirmaContratoIndividual}
													/>
												</Grid>
												
												<Grid item className={'text-left'} xs={12} sm={1} md={1} lg={1} xl={1}>
													<Button variant="contained" color="primary" size={"small"}
													        fullWidth
													        onClick={() => this.agregar_cliente_agendar_firma_contrato()}
													        className={'btn-default-primary text-transform-none'}
													        disabled={disabledFieldPromotorAgendarFechaYFirmaContratoIndividual}
													>
														Agregar
													</Button>
												</Grid>
											</Fragment>
										) : ''}
										
										
										<Grid container spacing={2}>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
												
												<table className={'desing-acresco'}>
													<thead>
													<tr>
														<th className={'padding-10 text-left'}>No. empleado</th>
														<th className={'padding-10 text-left'}>Lugar</th>
														<th className={'padding-10 text-left'}>Fecha y hora</th>
														<th className={'padding-10 text-left'}>Observaciones</th>
														<th className={'padding-10 text-left'}>Estatus</th>
														<th className={'padding-10 text-left'}>Acciones</th>
													</tr>
													</thead>
													<tbody>
													{this.state.cliente_agendar_firma_contrato.map((item, key) => (
														<tr key={key}>
															<th className={'padding-10 text-left'}>{item.agendar_cantidad_empleado}</th>
															<th className={'padding-10 text-left'}>{item.agendar_domicilio}</th>
															<th className={'padding-10 text-left'}>{DateFormat.FechaHoraFormatText(item.agendar_fecha_hora)}</th>
															<th className={'padding-10 text-left'}>{item.observaciones ? item.observaciones : 'Sin registrar'}</th>
															<th className={'padding-10 text-left'} align={'center'}>
																<div style={{
																	textAlign: 'center',
																	marginLeft: 0,
																	float: 'left',
																	padding: '6px',
																	background: item.color || '#bebebe',
																	height: '6px',
																	width: '80px',
																	lineHeight: '6px',
																	color: 'white',
																	borderRadius: '5px',
																	fontSize: '12px',
																}}
																     children={item.cliente_agendar_firma_contrato_status || 'Espera...'}/>
															</th>
															<th className={'padding-10'}>
																{!disabledFieldPromotorAgendarFechaYFirmaContratoIndividual ? (
																	<Fragment>
																		<Grid
																			container
																			direction="row"
																			justify="space-evenly"
																			alignItems="center"
																		>
																			
																			{FieldsJs.inArray([1, 3], item.id_cat_cliente_agendar_firma_contrato_status) ? (
																				<Box
																					boxShadow={0}
																					m={0}
																					p={0}
																				>
																					<DoneOutlineOutlinedIcon
																						className={'cursor-pointer'}
																						style={{color: "blue"}}
																						onClick={() => this.completado_cliente_agendar_firma_contrato(item, key)}
																					/>
																				</Box>
																			) : null}
																			
																			{FieldsJs.inArray([1, 2], item.id_cat_cliente_agendar_firma_contrato_status) ? (
																				<Box
																					boxShadow={0}
																					m={0}
																					p={0}
																				>
																					<CancelOutlinedIcon
																						className={'cursor-pointer'}
																						style={{color: "red"}}
																						onClick={() => this.eliminar_cliente_agendar_firma_contrato(item, key)}
																					/>
																				</Box>
																			) : null}
																		
																		</Grid>
																	
																	</Fragment>
																) : ''}
															
															</th>
														</tr>
													))}
													{this.state.cliente_agendar_firma_contrato.length <= 0 ? (
														<tr>
															<th colSpan={6} className={'text-gray text-center'}>No hay
																registros agendados
															</th>
														</tr>
													) : null}
													</tbody>
												</table>
											
											</Grid>
											
											<Grid item className={'text-center padding-10 px-30'} xs={12} sm={12}
											      md={12}
											      lg={12} xl={12}>
												Agendados: {this.total_agendados()}<br/>
												Por agendar: {this.por_agendar()}
											</Grid>
											
											<Grid item xs={12} align={'right'}>
												{!disabledFieldPromotorAgendarFechaYFirmaContratoIndividual ? (
													<Button
														variant="contained"
														color="primary"
														className={'btn-default-primary text-transform-none'}
														onClick={() => this.confirmar_fecha_firma_contratos_individuales()}
														disabled={disabledFieldPromotorAgendarFechaYFirmaContratoIndividual}
													>
														Confirmar fecha firma SYS
													</Button>
												) : ''}
											</Grid>
										</Grid>
									</Grid>
								</fieldset>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<fieldset className={'borde-punteado-lightgray-2'}>
									<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> IAS</legend>
									
									<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
										
										{!disabledFieldPromotorAgendarFechaYFirmaContratoIndividual ? (
											<Fragment>
												<Grid item className={'text-left'} xs={12} sm={2} md={2} lg={2} xl={2}>
													<TextField
														required
														fullWidth
														onChange={this.handleChange}
														type="text"
														margin="none"
														
														helperText=""
														name="agendar_cantidad_empleado_ias"
														label="Cantidad de empleado"
														value={this.state.agendar_cantidad_empleado_ias}
														disabled={disabledFieldPromotorAgendarFechaYFirmaContratoIndividual}
														onKeyPress={EnteroSolo}
													/>
												</Grid>
												
												<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
													<TextField
														required
														fullWidth
														onChange={this.handleChange}
														type="text"
														margin="none"
														
														helperText=""
														name="agendar_domicilio_ias"
														label="Dirección / Lugar de firma"
														value={this.state.agendar_domicilio_ias}
														disabled={disabledFieldPromotorAgendarFechaYFirmaContratoIndividual}
													/>
												</Grid>
												
												<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
													<KeyboardDateTimePicker
														format="yyyy/MM/dd hh:mm a" fullWidth required clearable
														inputProps={{readOnly: true}}
														KeyboardButtonProps={{
															'aria-label': 'Fecha y hora de firma',
														}}
														label="Fecha y hora de firma"
														value={this.state.agendar_fecha_hora_ias}
														onChange={(date) => {
															this.handleChange(null, null, null, date, 'agendar_fecha_hora_ias');
														}}
														minDate={moment(new Date())}
														disabled={disabledFieldPromotorAgendarFechaYFirmaContratoIndividual}
														clearLabel={'Limpiar'}
														okLabel={'Aceptar'}
														cancelLabel={'Cancelar'}
														onError={console.log}
													
													/>
												</Grid>
												
												<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
													<TextField
														fullWidth
														onChange={this.handleChange}
														type="text"
														margin="none"
														
														helperText=""
														name="observaciones_ias"
														label="Observaciones"
														value={this.state.observaciones_ias}
														disabled={disabledFieldPromotorAgendarFechaYFirmaContratoIndividual}
													/>
												</Grid>
												
												<Grid item className={'text-left'} xs={12} sm={1} md={1} lg={1} xl={1}>
													<Button variant="contained" color="primary" size={"small"}
													        fullWidth
													        onClick={() => this.agregar_cliente_agendar_firma_contrato_ias()}
													        className={'btn-default-primary text-transform-none'}
													        disabled={disabledFieldPromotorAgendarFechaYFirmaContratoIndividual}
													>
														Agregar
													</Button>
												</Grid>
											</Fragment>
										) : ''}
										
										
										<Grid container spacing={2}>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
												
												<table className={'desing-acresco'}>
													<thead>
													<tr>
														<th className={'padding-10 text-left'}>No. empleado</th>
														<th className={'padding-10 text-left'}>Lugar</th>
														<th className={'padding-10 text-left'}>Fecha y hora</th>
														<th className={'padding-10 text-left'}>Observaciones</th>
														<th className={'padding-10 text-left'}>Estatus</th>
														<th className={'padding-10 text-left'}>Acciones</th>
													</tr>
													</thead>
													<tbody>
													{this.state.cliente_agendar_firma_contrato_ias.map((item, key) => (
														<tr key={key}>
															<th className={'padding-10 text-left'}>{item.agendar_cantidad_empleado}</th>
															<th className={'padding-10 text-left'}>{item.agendar_domicilio}</th>
															<th className={'padding-10 text-left'}>{DateFormat.FechaHoraFormatText(item.agendar_fecha_hora)}</th>
															<th className={'padding-10 text-left'}>{item.observaciones ? item.observaciones : 'Sin registrar'}</th>
															<th className={'padding-10 text-left'} align={'center'}>
																<div style={{
																	textAlign: 'center',
																	marginLeft: 0,
																	float: 'left',
																	padding: '6px',
																	background: item.color || '#bebebe',
																	height: '6px',
																	width: '80px',
																	lineHeight: '6px',
																	color: 'white',
																	borderRadius: '5px',
																	fontSize: '12px',
																}}
																     children={item.cliente_agendar_firma_contrato_status || 'Espera...'}/>
															</th>
															<th className={'padding-10'}>
																{!disabledFieldPromotorAgendarFechaYFirmaContratoIndividual ? (
																	<Fragment>
																		<Grid
																			container
																			direction="row"
																			justify="space-evenly"
																			alignItems="center"
																		>
																			
																			{FieldsJs.inArray([1, 3], item.id_cat_cliente_agendar_firma_contrato_status) ? (
																				<Box
																					boxShadow={0}
																					m={0}
																					p={0}
																				>
																					<DoneOutlineOutlinedIcon
																						className={'cursor-pointer'}
																						style={{color: "blue"}}
																						onClick={() => this.completado_cliente_agendar_firma_contrato_ias(item, key)}
																					/>
																				</Box>
																			) : null}
																			
																			{FieldsJs.inArray([1, 2], item.id_cat_cliente_agendar_firma_contrato_status) ? (
																				<Box
																					boxShadow={0}
																					m={0}
																					p={0}
																				>
																					<CancelOutlinedIcon
																						className={'cursor-pointer'}
																						style={{color: "red"}}
																						onClick={() => this.eliminar_cliente_agendar_firma_contrato_ias(item, key)}
																					/>
																				</Box>
																			) : null}
																		
																		</Grid>
																	
																	</Fragment>
																) : ''}
															
															</th>
														</tr>
													))}
													{this.state.cliente_agendar_firma_contrato_ias.length <= 0 ? (
														<tr>
															<th colSpan={6} className={'text-gray text-center'}>No hay registros agendados
															</th>
														</tr>
													) : null}
													</tbody>
												</table>
											
											</Grid>
											
											<Grid item className={'text-center padding-10 px-30'} xs={12} sm={12}
											      md={12}
											      lg={12} xl={12}>
												Agendados: {this.total_agendados_ias()}<br/>
												Por agendar: {this.por_agendar_ias()}
											</Grid>
											
											<Grid item xs={12} align={'right'}>
												{!disabledFieldPromotorAgendarFechaYFirmaContratoIndividual ? (
													<Button
														variant="contained"
														color="primary"
														className={'btn-default-primary text-transform-none'}
														onClick={() => this.confirmar_fecha_firma_contratos_individuales_ias()}
														disabled={disabledFieldPromotorAgendarFechaYFirmaContratoIndividual}
													>
														Confirmar fecha firma IAS
													</Button>
												) : ''}
											</Grid>
										</Grid>
									</Grid>
								</fieldset>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={1}>
							<Grid item xs={12} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

AgendarFechaFirmaContrato.propTypes = {
	titulo: PropTypes.string.isRequired,
	id_cliente: PropTypes.number.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default AgendarFechaFirmaContrato;
