import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs, str_search} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {NubeService} from "../../../../services/NubeService/NubeService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {DropzoneArea} from 'material-ui-dropzone';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import axios from "axios";
import {CONFIG} from "../../../../settings/Config/Config";
import {Dropbox} from 'dropbox';


class ModalNube extends Component {
	
	state = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			files: [],
			patch: '/prueba1/',
			archivos: []
		};
	}
	
	
	handleChange(files) {
		
		this.setState({
			files: files[0]
		});
	}
	
	
	/*handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	*/
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		// this.getBase64(files, true);
		
		this.setState({
			files: files
		});
	}
	
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		console.log("THIS.STATE: ", this.state);
		/*if (this.state.files) {
			NubeService.AgregarArchivo(this.state).then((response) => {
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		}*/
		this.getBase64(this.state.files, true).then(response => {
			this.showSnackBars('success', "Archivo guardado con exito");
			this.closeModal();
			this.props.RefreshList();
		});
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_cat_sexo: '',
			sexo: '',
			abreviatura: '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_sexo: item.id_cat_sexo || '',
			sexo: item.sexo || '',
			abreviatura: item.abreviatura || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_sexo: item.id_cat_sexo || '',
			sexo: item.sexo || '',
			abreviatura: item.abreviatura || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	getBase64 = (e, dropzone) => {
		return new Promise((resolve) => {
			const accessToken = CONFIG.DROPBOX_TOKEN;
			let files;
			if (dropzone) {
				files = e;
			} else {
				console.log(e.target);
				files = e.target.files;
			}
			
			const dbx = new Dropbox({
				accessToken,
				fetch
			});
			console.log("DROPBOX: ", '/USUARIOS/' + this.Usr.id_usuario+'-'+this.Usr.nombre+' '+this.Usr.apellido_paterno+' '+this.Usr.apellido_materno+'/'+ files[0].name);
			dbx.filesUpload({path: '/USUARIOS/' + this.Usr.id_usuario+'-'+this.Usr.nombre+' '+this.Usr.apellido_paterno+' '+this.Usr.apellido_materno +'/'+ files[0].name, contents: files[0]})
				.then(function (response) {
					console.log("DROPBOX UPLOAD: ", response);
					resolve(true);
				})
				.catch(function (error) {
					console.error(error);
				});
		});
	};
	
	render() {
		
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Nube'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<form action="" method="post" encType="multipart/form-data">
									<input type="file" name="drop" id="drop" onChange={(e) => this.getBase64(e)} />
								</form>
							</Grid>*/}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<DropzoneArea
									onChange={this.handleChangeDropzoneArea.bind(this)}
									open={this.state.open}
									filesLimit={10}
									dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
									acceptedFiles={[]}
									showPreviews={false}
									maxFileSize={5000000}
									showAlerts={false}
									showFileNames={true}
									showFileNamesInPreview={true}
								/>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cat_sexo}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalNube.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalNube;
