import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const ComprobantesPagoService = {
	Listar: (filtro, paginacion) => {
		let fecha_alta = (filtro.fecha_alta || null) ? DateFormat.FormatSql(filtro.fecha_alta) : null;
		
		let params = {
			filtro: {
				id_comprobante_pago: filtro.id_comprobante_pago || null,
				id_empresa: filtro.id_empresa || null,
				ejercicio: filtro.ejercicio || null,
				periodo: filtro.periodo || null,
				id_cat_tipo_pago_comprobante_pago: filtro.id_cat_tipo_pago_comprobante_pago || null,
				id_cat_tipo_declaracion: filtro.id_cat_tipo_declaracion || null,
				fecha_alta: fecha_alta || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Comprobante_Pago_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_vigencia = form.fecha_vigencia ? DateFormat.FormatSql(form.fecha_vigencia) : null;
		
		let params = {
			id_comprobante_pago: null,
			id_empresa: form.id_empresa,
			ejercicio: form.ejercicio,
			periodo: form.periodo,
			id_cat_tipo_pago_comprobante_pago: form.id_cat_tipo_pago_comprobante_pago,
			id_cat_tipo_declaracion: form.id_cat_tipo_declaracion,
			fecha_vigencia: fecha_vigencia,
			observaciones: form.observaciones,
			importe: form.importe,
			recargo: form.recargo,
			parcial: form.parcial,
			archivos: form.archivos,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Comprobante_Pago_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_vigencia = form.fecha_vigencia ? DateFormat.FormatSql(form.fecha_vigencia) : null;
		
		let params = {
			id_comprobante_pago: form.id_comprobante_pago,
			id_empresa: form.id_empresa,
			ejercicio: form.ejercicio,
			periodo: form.periodo,
			id_cat_tipo_pago_comprobante_pago: form.id_cat_tipo_pago_comprobante_pago,
			id_cat_tipo_declaracion: form.id_cat_tipo_declaracion,
			fecha_vigencia: fecha_vigencia,
			observaciones: form.observaciones,
			importe: form.importe,
			recargo: form.recargo,
			parcial: form.parcial,
			archivos: form.archivos,
			activo: form.activo ? 1 : 0,
		};
		
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Comprobante_Pago_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_comprobante_pago) => {
		let params = {
			id_comprobante_pago: id_comprobante_pago
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Comprobante_Pago_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ComprobantePago: (form) => {
		let params = {
			id_comprobante_pago: form.id_comprobante_pago,
			observaciones: form.observaciones,
			comprobante_pago: form.comprobante_pago || 0,
			archivos: form.archivos
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Comprobante_Pago_Comprobante_Pago', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SendMail: (form) => {
		let params = {
			id_comprobante_pago: form.id_comprobante_pago,
			id_usuario: form.id_usuario,
			mensaje: form.mensaje,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Comprobante_Pago_SendRocketChat', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
