import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper/index';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {SaekoAlumnosService} from "../../../../services/SaekoAlumnosService/SaekoAlumnosService";
import {CONFIG} from "../../../../settings/Config/Config";
import Grid from "@material-ui/core/Grid";
import {

} from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import {CardActionArea, CardContent, CardActions } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Fotografia from '../../../../assets/img/icons/usuario.png';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import ModalCliente from "../../Cliente/Includes/ModalCliente";
import Table from "@material-ui/core/Table";
import ModalSaekoAlumnos from "./ModalSaekoAlumnos";

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el cliente con RFC: ${item.rfc}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				SaekoAlumnosService.Eliminar(item.id_cliente).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	

	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>
								ID
							</TableCell>
							<TableCell component="th" align={'left'}>Ciclo</TableCell>
							<TableCell component="th" align={'left'}>Matrícula</TableCell>
							<TableCell component="th" align={'left'}>Nombre Completo</TableCell>
							<TableCell component="th" align={'left'}>Género</TableCell>
							<TableCell component="th" align={'left'}>Correo Eléctronico</TableCell>
							<TableCell component="th" align={'left'}>Grado</TableCell>
							<TableCell component="th" align={'left'}>Grupo</TableCell>
							<TableCell component="th" align={'left'}>Turno</TableCell>
							<TableCell component="th" align={'left'}>Fecha inscripción</TableCell>
							<TableCell component="th" align={'left'}>Receptor de factura</TableCell>
							<TableCell component="th" align={'left'}>Familiares</TableCell>
							{/*<TableCell component="th" align={'center'}>Activo</TableCell>*/}
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<TableRow key={key}>
								<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
									{item.id_saeko_alumno}
								</TableCell>
								<TableCell align={'left'} className={'text-uppercase'}>{item.ciclo}</TableCell>
								<TableCell align={'left'}>{item.matricula}</TableCell>
								<TableCell align={'left'}>{item.nombre_completo}</TableCell>
								<TableCell align={'left'}>{item.genero}</TableCell>
								<TableCell align={'left'}>{item.correo_institucional}</TableCell>
								<TableCell align={'left'}>{item.grado}</TableCell>
								<TableCell align={'left'}>{item.grupo}</TableCell>
								<TableCell align={'left'}>{item.turno}</TableCell>
								<TableCell align={'left'}>{item.fecha_inscripcion}</TableCell>
								<TableCell align={'left'}>{item.receptor_facturas}</TableCell>
								<TableCell align={'left'}>{item.familiares}</TableCell>
								{/*<TableCell align={'center'}>
									{item.activo === 1 ? (
										<div style={{
											width: "50px",
											color: "white",
											borderRadius: "3px",
											padding: "2px 10px",
											background: "darkgreen",
											display: "inline-grid",
										}}>Activo</div>
									) : (
										<div style={{
											width: "50px",
											color: "white",
											borderRadius: "3px",
											padding: "2px 10px",
											background: "#901C30",
											display: "inline-grid",
										}}>Inactivo</div>
									)}
								</TableCell>*/}
								<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
									<div className={'w-auto vertical-inline'}>
										<div className={'v-center'}>
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
												<IconButton aria-label="Eliminar"
												            onClick={() => this.eliminar(item)}>
													<DeleteOutlined/>
												</IconButton>
											</Tooltip>
										</div>
										<div className={'v-center'}>
											<ModalSaekoAlumnos
												tipo={'edit'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
										<div className={'v-center'}>
											<ModalSaekoAlumnos
												tipo={'view'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				{/*<Grid container spacing={4} justify="flex-start" alignItems="flex-start" className={'margin-B-30'}>
					{lista.map((prod, key) => (
						<Fragment key={key}>
							{ prod.categoria_nueva ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
									<span className={'px-16'} style={{fontSize: 12, borderRadius: '6px', color: 'white', textAlign: 'center', margin: 0, padding: '2px 5px 2px 5px', background: '#1c9997'}}>
										{prod.categoria}
									</span>
								</Grid>
							) : null }
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<Card>
									<CardActionArea>
										<div className={'vertical-inline'}>
											<div className={'v-center margin-10-T'}>
												<Avatar
													style={{
														width: 80,
														height: 80
													}}
													alt={"Fotografia"}
													src={ prod.fotografia ? CONFIG.src + prod.fotografia : Fotografia}
													variant={'square'}
												/>
											
											</div>
										</div>
										<CardContent style={{padding: 10, minHeight: 160}}>
											<Grid container spacing={4} justify="flex-start" alignItems="flex-start">
												<Grid item xs={12} sm={12} md={4} lg={4} xl={4} align={'rigth'}>
													<Typography gutterBottom variant="h5" component="h2" style={{fontSize: 12, color: 'gray', textAlign: 'left', margin: 0, padding: 0}}>
														{prod.matricula}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={12} md={8} lg={8} xl={8} align={'left'}>
													<Typography gutterBottom variant="h5" component="h2" style={{fontSize: 12, color: 'gray', textAlign: 'right', margin: 0, padding: 0}}>
														{prod.correo_institucional}
													</Typography>
												</Grid>
											</Grid>
											<Typography gutterBottom variant="h5" component="h2">
												{prod.nombre_completo}
											</Typography>
											<Typography variant="body2" color="textSecondary" component="p">
												{prod.plan_estudios_cursos}
											</Typography>
											
										</CardContent>
									</CardActionArea>
									<CardActions>
										<Button fullWidth variant="contained" color="primary" onClick={() => this.props.mas(prod)}
										        className={'btn-default-primary text-transform-none'}>
											Agregar
										</Button>
									</CardActions>
								</Card>
							</Grid>
						</Fragment>
					))}
				</Grid>
				<div className={'margin-30-B'}></div>*/}
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ListaTabla;
