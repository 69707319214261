import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import ModalRepositorioJuridico from "./ModalRepositorioJuridico";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {RepositorioJuridicoService} from "../../../../services/RepositorioJuridicoService/RepositorioJuridicoService";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import icon_mas from "../../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../../assets/img/icons/expandir-menos.svg";
import Fab from "@material-ui/core/Fab";
import {CloudDownload, EventNote} from "@material-ui/icons";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import ModalCompartir from "./ModalCompartir";
import Button from "@material-ui/core/Button";
import ModalRevocado from "./ModalRevocado";
import BarraProgreso from "../../Tickets/Includes/BarraProgreso";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	is_resposable = (item) => {
		if (Number(this.Usr.id_cat_tipo_usuario) === Number(item.id_cat_tipo_usuario)) {
			return true;
		} else {
			return false;
		}
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el resguardo del equipo: ${item.equipo}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				RepositorioJuridicoService.Eliminar(item.id_resguardo).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					
					this.props.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	descargarZip = (item) => {
		let archivos = item.archivos;
		
		RepositorioJuridicoService.Descarga(item).then((response) => {
			if (archivos.length > 0) {
				for (let i = 0; i < archivos.length; i++) {
					DropboxApi.Download(archivos[i].archivo).then(function (data) {
						console.log('Descarga con éxito!');
					}).catch(function (error) {
						console.error("ERROR::: ", error);
					});
				}
			} else {
				this.props.showSnackBars('error', "No hay documentos regitrados.");
			}
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
		
		
		
	};
	
	spanStatus = (item) => {
		let bgg = 'white';
		switch (Number(item.id_cat_cronograma_estatus)) {
			case 1:
				bgg = 'gray';
				break;
			case 2:
				bgg = '#00c1ff';
				break;
			case 3:
				bgg = '#fcee15';
				break;
			case 4:
				bgg = '#c66500';
				break;
			case 5:
				bgg = '#119703';
				break;
			default:
				bgg = '#ccc';
				break;
		}
		
		const foto_default = CONFIG.foto_default;
		
		return (
			<Fragment>
				<div className={'w-100 text-white'} style={{borderRadius: '5px', background: bgg}}>
					{item.cronograma_estatus}
				</div>
			</Fragment>
		);
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Id</TableCell>
							<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Empresa</TableCell>
							<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Documento</TableCell>
							<TableCell component="th" align={'left'}>Representante Legal</TableCell>
							<TableCell component="th" align={'left'}>Presencial(Sí/No)</TableCell>
							<TableCell component="th" align={'center'}>Descarga</TableCell>
							<TableCell component="th" align={'left'}>Responsable Resguardo</TableCell>
							<TableCell component="th" align={'left'}>Departamento</TableCell>
							<TableCell component="th" align={'center'}>Vigencia</TableCell>
							<TableCell component="th" align={'center'}>Estatus</TableCell>
							<TableCell component="th" align={'center'} width={100}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							lista.map((item, key) => {
								return (
									<Fragment key={key}>
										<TableRow>
											<TableCell align={'left'} style={{paddingLeft: '15px'}}>{item.id_repositorio_juridico}</TableCell>
											<TableCell align={'left'} style={{paddingLeft: '15px'}}>{item.razon_social}</TableCell>
											<TableCell align={'left'} style={{paddingLeft: '15px'}}>{item.tipo_documento}</TableCell>
											<TableCell align={'left'}>{item.representante_legal}</TableCell>
											<TableCell align={'left'}>{item.fisicamente_rl ? item.fisicamente_rl : '-'}</TableCell>
											<TableCell align={'center'}>
												<div className={'v-center'}>
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Descargar">
														<IconButton aria-label="Descargar" onClick={() => this.descargarZip(item)}>
															<CloudDownload style={{color: 'rgb(142, 30, 50)'}}/>
														</IconButton>
													</Tooltip>
												</div>
											</TableCell>
											<TableCell align={'left'}>{item.responsable}</TableCell>
											<TableCell align={'left'}>{item.tipo_usuario}</TableCell>
											<TableCell align={'center'}>
												{FieldsJs.Field(item.fecha_vigencia) ? (
													<Tooltip TransitionComponent={Zoom} placement={"top"} title={"Vigencia: " + item.fecha_vigencia}>
														<div>
															<BarraProgreso color={item.id_cat_repositorio_juridico_estatus} completed={item.progreso || 0} buffer={100} value={item.progreso || 0} size={'150px'}/>
														</div>
													</Tooltip>
												) : (
													<BarraProgreso color={item.id_cat_repositorio_juridico_estatus} completed={item.progreso || 0} buffer={100} value={item.progreso || 0} size={'150px'}/>
												)}
											</TableCell>
											<TableCell align={'center'}>
												<div className={'w-auto vertical-inline'}>
													<div className={'v-center'}>
														<ModalRevocado
															tipo={'add'}
															item={item}
															componente={
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Cambiar estatus">
																	<Button aria-label="Cambiar estatus" onClick={() => {
																	}} className={'px-12'} style={{color: '#3e3e3e', fontWeight: 500, textTransform: 'capitalize'}}>
																		{item.estatus}
																	</Button>
																</Tooltip>
															}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												</div>
											</TableCell>
											<TableCell align={'right'} className={'padding-5-L padding-5-R'}>
												<div className={'w-auto vertical-inline'}>
													
													{this.is_resposable(item) ? (
														<div className={'v-center'}>
															<ModalCompartir
																tipo={'add'}
																item={item}
																RefreshList={this.props.RefreshList}
																showSnackBars={this.props.showSnackBars}
															/>
														</div>
													) : null
													}
													
													
													{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
														<div className={'v-center'}>
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
																<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}><DeleteOutlined/></IconButton>
															</Tooltip>
														</div>
													) : ''}
													
													<div className={'v-center'}>
														<ModalRepositorioJuridico
															tipo={'edit'}
															item={item}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
															lista_empresa={this.props.lista_empresa}
														/>
													</div>
													
													<div className={'v-center'}>
														<ModalRepositorioJuridico
															tipo={'view'}
															item={item}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
															lista_empresa={this.props.lista_empresa}
														/>
													</div>
												</div>
											</TableCell>
										</TableRow>
									</Fragment>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
