import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import {DropzoneArea} from 'material-ui-dropzone';
import {CONFIG} from "../../../../settings/Config/Config";
import {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {RepositorioContableService} from "../../../../services/RepositorioContableService/RepositorioContableService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import ModalNuevaCuenta from "./ModalNuevaCuenta";
import Fab from "@material-ui/core/Fab";
import {CreditCard, RadioButtonChecked, RadioButtonUnchecked} from "@material-ui/icons";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";


class ModalSubirInfo extends Component {
	
	state = {};
	
	Usr = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false,
			},
			
			listar_repositorio: [],
			
			routes: [],
			
			tipo: props.tipo,
			files: [],
			archivos: [],
			jsonXML: [],
			
			ruta: '/Repositorio Contable/' + this.Usr.id_usuario + '-' + this.Usr.nombre_completo,
			
			lista_tipo_info: [
				{id_cat_tipo_informacion: 1, titulo: "CONTABILIDAD", descripcion: "Estados de cuenta", url_icono: 'file/tipo_usuario/contabilidad.svg'},
				{id_cat_tipo_informacion: 2, titulo: "LEGAL", descripcion: "Información legal", url_icono: 'file/tipo_usuario/juridico.svg'},
				{id_cat_tipo_informacion: 3, titulo: "FISCAL", descripcion: "Documentos, cédulas fiscales.", url_icono: 'file/tipo_usuario/fiscal.svg'},
				{id_cat_tipo_informacion: 4, titulo: "OTROS", descripcion: "Documentos varios.", url_icono: 'file/tipo_usuario/promotor.svg'},
			],
			
			activeStep: 0,
			completedStep: 0,
			id_cuenta_repositorio_contable: '',
			cuenta_repositorio_contable: '',
			cuenta: '',
			listar_cuentas: [],
		};
	}
	
	handleChangeDropzoneArea = (files) => {
		this.setState({
			files: files
		});
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		this.setState({
			modal: {
				open: true
			}
		});
	};
	
	open = () => {
		this.ListarCuentas();
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	selectTipoInfo = (item) => {
		if (item.id_cat_tipo_informacion !== 1) {
			this.setState({
				activeStep: 1,
				completedStep: 1,
				id_cat_tipo_informacion: item.id_cat_tipo_informacion,
				cuenta: '',
				cuenta_repositorio_contable: item.titulo
			});
		} else {
			this.setState({
				id_cat_tipo_informacion: item.id_cat_tipo_informacion,
				cuenta: '',
				cuenta_repositorio_contable: item.titulo
			});
		}
	};
	
	selectCuenta = (item) => {
		this.setState({
			activeStep: 1,
			completedStep: 1,
			id_cat_tipo_informacion: 1,
			cuenta_repositorio_contable: 'CONTABILIDAD',
			cuenta: item.abreviatura + '-' + item.numero_cuenta.substr(-4, 4),
			id_cuenta_repositorio_contable: item.id_cuenta_repositorio_contable
		});
	};
	
	guardarDocTimbradoDbx = () => {
		let ftar = this.state.files;
		console.log("1 FTAR::: ", ftar);
		let files;
		
		return new Promise((resolve, reject) => {
			let band = 0;
			let band2 = 0;
			
			band = ftar.length;
			
			let archives = [];
			console.log("2 FTAR 22::: ", ftar);
			
			for (let x = 0; x < ftar.length; x++) {
				files = ftar[x];
				
				let ruta = '';
				
				ruta = this.state.ruta + "/" + (Number(this.state.id_cuenta_repositorio_contable) === 1 ? this.state.cuenta_repositorio_contable + '/' + this.state.cuenta : this.state.cuenta_repositorio_contable) + '/' + files.name;
				
				
				let displayDropbox = new Promise((resol, reje) => {
					DropboxApi.Upload(ruta, files).then(function (response) {
						
						archives.push(response.path_display);
						console.log("DROPBOX UPLOAD RESPONSE: ", response, band, (x + 1));
						resol(true);
					}).catch(function (error) {
						console.log("DROPBOX UPLOAD ERROR: ", error);
						reje(null);
					});
				});
				
				displayDropbox.then((response) => {
					band2++;
					if (band2 >= band) {
						resolve(archives);
					}
				}).catch((error) => {
					band2++;
					if (band2 >= band) {
						resolve(true);
					}
				});
			}
		});
	};
	
	save = () => {
		console.log("THIS.STATE: ", this.state);
		
		try {
			
			if (this.state.files.length <= 0) {
				throw Object({
					status: false, mensaje: 'Agrega un archivo.'
				})
			}
			showSpinner('spinner');
			let guardar = new Promise((resolve, reject) => {
				this.guardarDocTimbradoDbx().then(response => {
					console.log("RESPONSE:::", response);
					
					this.setState({
						archivos: response
					});
					resolve(response);
				}).catch(error => {
					reject(error);
				})
			});
			guardar.then(response => {
				hideSpinner('spinner');
				console.log(this.state);
				
				RepositorioContableService.Agregar(this.state).then((response) => {
					this.clearAll();
					this.closeModal();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			})
			
		} catch (e) {
			hideSpinner('spinner');
			console.log("ERROR", e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	clearAll = () => {
		this.setState({
			files: [],
			archivos: [],
			jsonXML: [],
			activeStep: 0,
			completedStep: 0,
			id_cuenta_repositorio_contable: '',
			cuenta_repositorio_contable: '',
			cuenta: '',
		});
	};
	
	add = () => {
		console.log(this.props);
		this.clearAll();
		this.setState({
			ruta: '/Repositorio Contable',
			files: [],
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	ListarCuentas = () => {
		RepositorioContableService.ListarCuentas().then((response) => {
			this.setState({
				listar_cuentas: response.data,
			});
		}).catch((error) => {
			this.setState({listar_cuentas: []});
			//this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	render() {
		
		const filterOptions = createFilterOptions({
			matchFrom: 'start',
			stringify: option => option.rfc,
		});
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Subir Info.
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Stepper alternativeLabel nonLinear activeStep={this.state.activeStep}>
									
									<Step>
										<StepButton onClick={() => {
											this.setState({
												activeStep: 0,
												completedStep: 0
											});
										}} completed={this.state.completedStep === 0 || this.state.completedStep === 1}>
											{"Selecciona el tipo de información."}
										</StepButton>
									</Step>
									<Step>
										<StepButton completed={this.state.completedStep === 1}>
											{"Subir archivos."}
										</StepButton>
									</Step>
								
								</Stepper>
							</Grid>
							
							{this.state.completedStep === 0 ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={2} className={'margin-15-B'}>
											{this.state.lista_tipo_info.map((item, key) => (
												<Grid key={key} item xs={12} sm={6} md={6} lg={6} xl={6} className={'padding-10-T text-left'}>
													<div className={'vertical-inline cursor-pointer'}
													     style={{borderBottom: '1px solid rgba(100,100,100,0.2)', width: '100%'}}>
														<Button className={'w-100'} onClick={() => this.selectTipoInfo(item)}>
															<div className={'margin-10 vertical-inline w-100'}>
																<div className={'w-90-px'}>
																	<img className={'v-center'} src={CONFIG.src + item.url_icono} alt="add" width={70} height={50} style={{opacity: 0.7}}/>
																</div>
																<div className={'w-100-90-px text-left'}>
																	<div className={'h-100 vertical-inline text-left'}>
																		<div className={'v-center text-left'}>
																			<Typography variant={'h6'} className={'text-black px-13'}>
																				{item.titulo}
																			</Typography>
																			<Typography variant={'h6'} className={'text-gray px-12'}>
																				{item.descripcion}
																			</Typography>
																		</div>
																	</div>
																</div>
															</div>
														</Button>
													
													</div>
												</Grid>
											))}
										</Grid>
									</Grid>
									
									{this.state.id_cat_tipo_informacion === 1 ? (
										<Fragment>
											
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={'margin-15-B'}>
												<Typography style={{color: 'gray', opacity: '0.8'}}>
													Selecciona una cuenta cuenta/tarjeta:
												</Typography>
											</Grid>
											
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={'margin-15-B text-right'}>
												<ModalNuevaCuenta
													tipo={'add'}
													item={this.state}
													componente={
														<Fab variant="extended" size="small" style={{backgroundColor: '#7fccab', color: 'white'}} aria-label="Add">
															<CreditCard className={'margin-5-R px-14'}/>
															Nueva Cuenta
														</Fab>
													}
													RefreshList={this.props.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={2}>
													{this.state.listar_cuentas.length > 0 ? (
														<Fragment>
															{this.state.listar_cuentas.map((item, key) => (
																<Grid item key={key} xs={3} sm={3} md={3} lg={3} xl={3}>
																	<div className={'borde-punteado-lightgray-2 margin-15-B padding-10 cursor-pointer crc-hover'} onClick={() => this.selectCuenta(item)}>
																		
																		<Typography style={{color: 'gray', opacity: '0.8'}}>
																			Tipo de cuenta/tarjeta:
																		</Typography>
																		<Typography style={{opacity: '0.8'}}>
																			{item.tipo_cuenta_tarjeta}
																		</Typography>
																		<Typography style={{color: 'gray', opacity: '0.8'}}>
																			Banco:
																		</Typography>
																		<Typography style={{opacity: '0.8'}}>
																			{item.banco}
																		</Typography>
																		<Typography style={{color: 'gray', opacity: '0.8'}}>
																			Número de cuenta:
																		</Typography>
																		<Typography style={{opacity: '0.8'}}>
																			{item.numero_cuenta}
																		</Typography>
																		<Typography style={{color: 'gray', opacity: '0.8'}}>
																			Día de corte:
																		</Typography>
																		<Typography style={{opacity: '0.8'}}>
																			{item.dia_corte} de cada mes.
																		</Typography>
																		<div className={'text-center'}>
																			{Number(this.state.id_cuenta_repositorio_contable) === Number(item.id_cuenta_repositorio_contable) ?
																				<RadioButtonChecked style={{color: 'blue'}}/> : <RadioButtonUnchecked style={{color: 'gray'}}/>
																			}
																		</div>
																	</div>
																</Grid>
															))}
														</Fragment>
													) : (
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<VistaVacia
																numero={0}
																mensaje={'No se encontraron datos.'}
															/>
														</Grid>
													)}
												</Grid>
											</Grid>
										</Fragment>
									) : null}
								</Fragment>
							) : (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography style={{color: 'gray', opacity: '0.8'}}>
											Los archivos se guardaran en la siguiente
											dirección: {Number(this.state.id_cuenta_repositorio_contable) === 1 ? this.state.cuenta_repositorio_contable + '/' + this.state.cuenta : this.state.cuenta_repositorio_contable}
										</Typography>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={1}>
											<DropzoneArea
												onChange={this.handleChangeDropzoneArea.bind(this)}
												open={this.state.open}
												filesLimit={100}
												dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
												acceptedFiles={[]}
												showPreviews={false}
												maxFileSize={5000000}
												showAlerts={false}
												showFileNames={true}
												showFileNamesInPreview={true}
											/>
										</Grid>
									</Grid>
									
									{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										<Button onClick={this.save} variant={'contained'} color="primary" className={'margin-15-T'}>
											{'Guardar'}
										</Button>
									</Grid>*/}
								
								</Fragment>
							)}
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close} color="primary">
								{'Cancelar'}
							</Button>
							{this.state.completedStep === 1 ? (
								<Button onClick={this.save} color="primary">
									{'Agregar'}
								</Button>
							) : ''}
						</Fragment>
					
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ModalSubirInfo.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalSubirInfo;
