import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const ComprasService = {
	Listar: (filtro, paginacion) => {
		let fecha_alta_inicio = (filtro.fecha_alta_inicio || null) ? DateFormat.FormatSql(filtro.fecha_alta_inicio) : null;
		let fecha_alta_fin = (filtro.fecha_alta_fin || null) ? DateFormat.FormatSql(filtro.fecha_alta_fin) : null;
		let fecha_compra_inicio = (filtro.fecha_compra_inicio || null) ? DateFormat.FormatSql(filtro.fecha_compra_inicio) : null;
		let fecha_compra_fin = (filtro.fecha_compra_fin || null) ? DateFormat.FormatSql(filtro.fecha_compra_fin) : null;
		
		let params = {
			filtro: {
				numero_compra: filtro.numero_compra || null,
				usuario: filtro.usuario || null,
				id_plaza: filtro.id_plaza || null,
				empresa: filtro.empresa || null,
				oficina: filtro.oficina || null,
				plaza_direccion: filtro.plaza_direccion || null,
				fecha_alta_inicio: fecha_alta_inicio,
				fecha_alta_fin: fecha_alta_fin,
				fecha_compra_inicio: fecha_compra_inicio,
				fecha_compra_fin: fecha_compra_fin,
				ordenar_fecha_alta: filtro.ordenar_fecha_alta || null,
				ordenar_fecha_compra: filtro.ordenar_fecha_compra || null,
				servicio_compra: filtro.servicio_compra || null
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Compras_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarServicio: (filtro, paginacion) => {
		let fecha_alta_inicio = (filtro.fecha_alta_inicio || null) ? DateFormat.FormatSql(filtro.fecha_alta_inicio) : null;
		let fecha_alta_fin = (filtro.fecha_alta_fin || null) ? DateFormat.FormatSql(filtro.fecha_alta_fin) : null;
		let fecha_compra_inicio = (filtro.fecha_compra_inicio || null) ? DateFormat.FormatSql(filtro.fecha_compra_inicio) : null;
		let fecha_compra_fin = (filtro.fecha_compra_fin || null) ? DateFormat.FormatSql(filtro.fecha_compra_fin) : null;
		
		let params = {
			filtro: {
				numero_compra: filtro.numero_compra || null,
				usuario: filtro.usuario || null,
				id_plaza: filtro.id_plaza || null,
				empresa: filtro.empresa || null,
				oficina: filtro.oficina || null,
				plaza_direccion: filtro.plaza_direccion || null,
				fecha_alta_inicio: fecha_alta_inicio,
				fecha_alta_fin: fecha_alta_fin,
				fecha_compra_inicio: fecha_compra_inicio,
				fecha_compra_fin: fecha_compra_fin,
				ordenar_fecha_alta: filtro.ordenar_fecha_alta || null,
				ordenar_fecha_compra: filtro.ordenar_fecha_compra || null,
				servicio_compra: filtro.servicio_compra || null
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Compras_Servicio_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalles: (id_compra) => {
		let params = {
			id_compra: id_compra,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Compras_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	DetallesServicio: (id_compra_servicio) => {
		let params = {
			id_compra_servicio: id_compra_servicio,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Compras_Servicio_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Guardar: (form) => {
		let ws = '_Compras_Agregar';
		let id_compra = null;
		if (form.id_compra > 0) {
			ws = '_Compras_Modificar';
			id_compra = form.id_compra;
		}
		let fecha_compra = form.fecha_compra && form.fecha_compra !== 'NaN-NaN-NaN' ? DateFormat.FormatSql(form.fecha_compra) : '';
		let fecha_pago = form.fecha_pago && form.fecha_pago !== 'NaN-NaN-NaN' ? DateFormat.FormatSql(form.fecha_pago) : '';
		let params = {
			id_compra: id_compra,
			id_compra_servicio: form.id_compra_servicio,
			id_cat_tipo_comprobante: form.id_cat_tipo_comprobante,
			id_plaza_direccion: form.id_plaza_direccion,
			id_cat_servicio_gasto: form.id_cat_servicio_gasto,
			id_estatus_pago: form.id_estatus_pago,
			id_plaza: form.id_plaza,
			id_usuario: form.id_usuario,
			id_empresa: form.id_empresa,
			id_plaza_direccino: form.id_plaza_direccion,
			quien_recibio: form.quien_recibio,
			servicio_compra: form.servicio_compra,
			dia_pago: form.dia_pago,
			descripcion: form.descripcion || null,
			fecha_compra: fecha_compra || null,
			fecha_pago: fecha_pago || null,
			total: form.total || null,
			observaciones: form.observaciones || '',
			archivopdf: form.archivopdf || '',
			formatopdf: form.formatopdf || '',
			nombre_archivopdf: form.nombre_archivopdf || '',
			archivoxml: form.archivoxml || '',
			formatoxml: form.formatoxml || '',
			nombre_archivoxml: form.nombre_archivoxml || '',
			compra_producto: form.lista_compra_producto || [],
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post(ws, params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarServicio: (form) => {
		let ws = '_Compras_Servicio_Agregar';
		let id_compra_servicio = null;
		if (form.id_compra_servicio > 0) {
			ws = '_Compras_Servicio_Modificar';
			id_compra_servicio = form.id_compra_servicio;
		}
		let fecha_compra = form.fecha_compra && form.fecha_compra !== 'NaN-NaN-NaN' ? DateFormat.FormatSql(form.fecha_compra) : '';
		let params = {
			id_compra_servicio: id_compra_servicio,
			id_cat_tipo_comprobante: form.id_cat_tipo_comprobante,
			id_plaza_direccion: form.id_plaza_direccion,
			id_cat_servicio_gasto: form.id_cat_servicio_gasto,
			id_plaza: form.id_plaza,
			id_usuario: form.id_usuario,
			id_empresa: form.id_empresa,
			id_plaza_direccino: form.id_plaza_direccion,
			quien_recibio: form.quien_recibio,
			servicio_compra: form.servicio_compra,
			dia_pago: form.dia_pago,
			
			descripcion: form.descripcion || null,
			fecha_compra: fecha_compra || null,
			total: form.total || null,
			compra_producto: form.lista_compra_producto || [],
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post(ws, params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_compra) => {
		let params = {
			id_compra: id_compra
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Compras_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	EliminarServicio: (id_compra_servicio) => {
		let params = {
			id_compra_servicio: id_compra_servicio
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Compras_Servicio_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SubirComprobante: (form) => {
		let fecha_pago = form.fecha_pago && form.fecha_pago !== 'NaN-NaN-NaN' ? DateFormat.FormatSql(form.fecha_pago) : null;
		
		let params = {
			id_compra: form.id_compra,
			fecha_pago: fecha_pago,
			comprobante_archivo: form.comprobante_archivo,
			comprobante_formato: form.comprobante_formato,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Compras_Subir_Comprobante', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SubirComprobanteServicio: (form) => {
		let params = {
			id_compra_servicio: form.id_compra_servicio,
			comprobante_archivo: form.comprobante_archivo,
			comprobante_formato: form.comprobante_formato,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Compras_Subir_Servicio_Comprobante', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
