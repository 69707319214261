import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FieldsJs} from "../../../../settings/General/General";
import TextField from "@material-ui/core/TextField";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {Group, Send} from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import {CONFIG} from "../../../../settings/Config/Config";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import FiltrosUsuarios from "../../Usuarios/Includes/FiltrosUsuarios";
import FiltrosVacacionesUsuarios from "./FiltrosVacacionesUsuarios";
import {TipoUsuarioService} from "../../../../services/_Cat/TipoUsuarioService/TipoUsuarioService";
import {UsuariosService} from "../../../../services/_Sis/UsuariosService/UsuariosService";

class ModalDiasDisponibles extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			
			tipo: props.tipo,
			id_usuario: '',
			usuario: '',
			correo_electronico: '',
			id_cat_tipo_usuario: '',
			dias_disponibles_vacaciones: '',
			mensaje: '',
			listar_usuarios: [],
		};
		
	}
	
	openModal = () => {
		const {item} = this.props;
		console.log("ITEM:::", item);
		
		this.setState({
			open: true,
			scroll: 'paper',
			id_usuario: '',
			mensaje: '',
			listar_usuarios: [],
		});
		
		this.listarUsuarios();
	};
	
	closeModal = () => {
		this.setState({open: false});
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	close = () => {
		this.closeModal();
	};
	
	listarUsuarios = () => {
		CatService.ListUsuario().then(response => {
			this.setState({
				listar_usuarios: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_usuarios: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	Listar = (filtro) => {
		UsuariosService.ListarAll(filtro).then((response) => {
			
			this.setState({
				listar_usuarios: response.data,
			});
			
		}).catch((error) => {
			
			this.setState({listar_usuarios: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	HandleFiltro = (fil) => {
		let filtro = {};
		
		filtro.usuario = fil.usuario || null;
		filtro.correo_electronico = fil.correo_electronico || null;
		filtro.id_cat_tipo_usuario = fil.id_cat_tipo_usuario || null;
		filtro.dias_disponibles_vacaciones = fil.dias_disponibles_vacaciones || null;
		filtro.activo = fil.activo || null;
		
		this.Listar(filtro);
	};
	
	render() {
		
		return (
			<span>
				
				{this.props.component ? (
					<Fragment>
						<span onClick={() => this.openModal('paper')} style={{cursor: 'pointer'}}>
							{this.props.component}
						</span>
					</Fragment>
				) : (
					<Fragment>
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={() => this.openModal('paper')}>
							<Send className={'margin-5-R px-14'}/>
							Usuarios Días Disponibles
						</Fab>
					</Fragment>
				)}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.closeModal}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal} className={'sendMessage-ticket'}
				>
					
					<DialogTitle style={{color: 'gray'}}>Días Disponibles</DialogTitle>
					
					<DialogContent>
						<Grid container spacing={0}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FiltrosVacacionesUsuarios
									AplicarFiltros={this.AplicarFiltro}
									HandleFiltro={this.HandleFiltro}
									showSnackBars={this.props.showSnackBars}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-400-px'}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell component="th" align={'center'}>Usuario</TableCell>
											<TableCell component="th" align={'center'}>Fecha de Ingreso</TableCell>
											<TableCell component="th" align={'center'}>Días Disponibles</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											this.state.listar_usuarios.map((item, key) => {
												return (
													<TableRow key={key}>
														{/*<TableCell align={'left'} className={Number(item.id_cat_linea_captura_motivo_cancelacion) > 0 ? 'text-tachado padding-10-L' : 'padding-10-L'}>{item.id_usuario}</TableCell>*/}
														<TableCell align={'left'} className={'padding-10-L'}>
															<div className={'v-center vertical-inline'}>
																<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
																     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
																</div>
																<Typography variant={'h5'} className={'v-center padding-15-L margin-0 text-black px-13'}>
																	{item.nombre_completo}
																</Typography>
															</div>
														</TableCell>
														<TableCell align={'right'} className={'padding-10-L padding-10-R'}>
															{item.fecha_ingreso && item.fecha_ingreso !== "0000-00-00" ? DateFormat.FormatText(item.fecha_ingreso):'Sin registrar'}
														</TableCell>
														<TableCell align={'right'} className={'padding-10-L padding-10-R'}>
															{item.dias_disponibles_vacaciones + (item.dias_disponibles_vacaciones === 1 ? ' día':' días') ?? '0 días'}
														</TableCell>
													</TableRow>
												)
											})
										}
									</TableBody>
								</Table>
							</Grid>
						</Grid>
			
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={0}>
							<Grid item xs={12} align={'right'}>
								<Button onClick={() => this.close()} color="primary">
									{"Cerrar"}
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			
			</span>
		);
	}
}

ModalDiasDisponibles.propTypes = {
	tipo: PropTypes.oneOf([
		"add",
		"view",
		"edit",
	]),
	showSnackBars: PropTypes.func.isRequired,
	component: PropTypes.element,
	RefreshList: PropTypes.func.isRequired,
};

export default ModalDiasDisponibles;
