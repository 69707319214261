import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, BotonAccionFooterModal} from "../../../Include/MiniComponents/GlobalComponent";
import TextField from "@material-ui/core/TextField";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {RepositorioContableService} from "../../../../services/RepositorioContableService/RepositorioContableService";
import DialogTitle from "@material-ui/core/DialogTitle";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";

class ModalNuevaCuenta extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo,
			numero_cuenta: '',
			dia_corte: '',
			id_cat_tipo_cuenta_tarjeta: '',
			id_cat_banco: '',
			listar_bancos: [],
			listar_cat_tipo_cuenta_tarjeta: [],
			cat_dia_corte: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
	};
	
	open = () => {
		this.listaBancos();
		this.listaCuentaTarjeta();
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	listaBancos = () => {
		CatService.ListBanco().then((response) => {
			this.setState({
				listar_bancos: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_bancos: []
			});
			this.props.showSnackBars('error', error.mensaje);
		})
	};
	
	listaCuentaTarjeta = () => {
		CatService.ListTipoCuentaTarjeta().then((response) => {
			this.setState({
				listar_cat_tipo_cuenta_tarjeta: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_cat_tipo_cuenta_tarjeta: []
			});
			this.props.showSnackBars('error', error.mensaje);
		})
	};
	
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	add = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			numero_cuenta: '',
			dia_corte: '',
			id_cat_tipo_cuenta_tarjeta: '',
			id_cat_banco: '',
			activo: true,
		});
		this.open();
	};
	
	save = () => {
		console.log(this.state);
		RepositorioContableService.NuevaCuenta(this.state).then((response) => {
			this.closeModal();
			this.props.RefreshList();
			this.props.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			console.log('error', error);
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	render() {
		
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Nueva Cuenta
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_tipo_cuenta_tarjeta"
									label="Tipo de cuenta/tarjeta"
									value={this.state.id_cat_tipo_cuenta_tarjeta}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_tipo_cuenta_tarjeta.map(option => (
										<option key={option.id_cat_tipo_cuenta_tarjeta} value={option.id_cat_tipo_cuenta_tarjeta}>
											{option.tipo_cuenta_tarjeta}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_banco"
									label="Banco"
									value={this.state.id_cat_banco}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_bancos.map(option => (
										<option key={option.id_cat_banco} value={option.id_cat_banco}>
											{option.banco}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<TextField
									margin="none"
									name="numero_cuenta"
									label="Número de cuenta"
									type="text"
									fullWidth
									value={this.state.numero_cuenta}
									onChange={this.handleChange}
									onKeyPress={EnteroSolo}
									inputProps={{maxLength: 16}}
									required
								/>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<FormControl fullWidth required>
									<InputLabel>Día de corte</InputLabel>
									<Select
										native
										value={this.state.dia_corte}
										onChange={(e) => {
											this.handleChange(e);
											this.setState({otro_tipo_adquisicion: ''});
										}}
										name="dia_corte"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.state.cat_dia_corte.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cat_sexo}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalNuevaCuenta.propTypes = {
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalNuevaCuenta;
