import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import {CloudUploadOutlined, DeleteOutlined, Description, EditOutlined, SearchOutlined, Assignment} from '@material-ui/icons';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {FieldsJs} from "../../../../settings/General/General";
import ModalCompras from "./ModalCompras";
import ModalObservaciones from "./ModalObservaciones";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import {CONFIG} from "../../../../settings/Config/Config";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import ModalComprobantes from "./ModalComprobantes";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});



class ListaTabla extends Component {
	
	exportar = (item) => {
		console.log(item);
		let path = CONFIG.src + item;
		window.open(path, '', 'width=900, height=600');
		
		/*if (item.is_local) {
			let path = tipo ? item.archivo_pdf : item.archivo;
			path = CONFIG.src + path;
			window.open(path, '', 'width=900, height=600');
		} else {
			let path = tipo ? item.archivo_pdf : item.archivo;
			
			DropboxApi.Open(path).then(function (data) {
				console.log('Descarga con éxito!');
			}).catch(function (error) {
				console.error(error);
			});
		}*/
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-mark'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>No. compra</TableCell>
							<TableCell component="th" align={'left'}>Plaza</TableCell>
							<TableCell component="th" align={'left'}>Oficina</TableCell>
							<TableCell component="th" align={'left'}>Empresa</TableCell>
							<TableCell component="th" align={'left'}>Fecha compra</TableCell>
							<TableCell component="th" align={'left'}>Tipo gasto</TableCell>
							<TableCell component="th" align={'left'}>Límite de pago</TableCell>
							<TableCell component="th" align={'left'}>Estatus</TableCell>
							<TableCell component="th" align={'left'}>Fecha pago</TableCell>
							<TableCell component="th" align={'left'}>Total</TableCell>
							<TableCell component="th" align={'left'}>Observaciones</TableCell>
							<TableCell component="th" align={'center'}>Comprobantes</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => {
							return (
								<TableRow key={key}>
									<TableCell align={'center'} className={'padding-20-L padding-20-R'}>{item.id_compra}</TableCell>
									<TableCell align={'left'}>{item.plaza}</TableCell>
									<TableCell align={'left'}>{item.nombre_plaza}</TableCell>
									<TableCell align={'left'}>{item.nombre_comercial}</TableCell>
									<TableCell align={'left'}>{DateFormat.FormatText(item.fecha_compra)}</TableCell>
									<TableCell align={'left'}>{item.servicio_compra}</TableCell>
									<TableCell align={'left'}>{item.limite_pago ? DateFormat.FormatText(item.limite_pago) : 'Sin registrar'}</TableCell>
									<TableCell align={'left'}>{item.estatus}</TableCell>
									<TableCell align={'left'}>{item.fecha_pago ? DateFormat.FormatText(item.fecha_pago) : 'Sin registrar'}</TableCell>
									<TableCell align={'left'}>${FieldsJs.Currency(item.total)}</TableCell>
									<TableCell align={'left'}>
										{FieldsJs.Field(item.observaciones) ? (
											<ModalObservaciones
												item={item}
												tipo={'view'}
												icono={(
													<Tooltip TransitionComponent={Zoom} placement={"top"}
													         title="Observaciones">
														<IconButton aria-label="Observaciones">
															<Assignment/>
														</IconButton>
													</Tooltip>
												)}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										):(
											<Tooltip TransitionComponent={Zoom} placement={"top"}
											         title="Observaciones">
												<IconButton aria-label="Observaciones">
													<Assignment style={{color: '#ccc'}}/>
												</IconButton>
											</Tooltip>
										)}
										
									</TableCell>
									<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
										<div className={'w-auto vertical-inline'}>
											<div className={'v-center'}>
												<ModalComprobantes
													id_compra={item.id_compra}
													item={item}
													icono={(
														<Tooltip TransitionComponent={Zoom} placement={"top"}
														         title={(item.compra_comprobante.length > 0 ? "Ver o subir comprobantes" : "Subir comprobante")}>
															<IconButton aria-label={(item.compra_comprobante.length > 0 ? "Ver o subir comprobantes" : "Subir comprobante")}>
																<CloudUploadOutlined style={{color: (item.compra_comprobante.length > 0 ? "green" : "gray")}}/>
															</IconButton>
														</Tooltip>
													)}
													RefreshList={this.props.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											</div>
											<div className={'v-center'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver XML">
													<IconButton aria-label="Ver XML" onClick={() => this.exportar(item.comprobante_archivoxml)}>
														<Description style={{color: '#e18c02'}}/>
													</IconButton>
												</Tooltip>
											</div>
											<div className={'v-center'}>
												{item.comprobante_archivopdf ? (
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver PDF">
														<IconButton aria-label="Ver PDF" onClick={() => this.exportar(item.comprobante_archivopdf)}>
															<PictureAsPdf style={{color: '#8E1E32'}}/>
														</IconButton>
													</Tooltip>
												) : (
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver PDF">
														<IconButton aria-label="Ver PDF" onClick={() =>
															this.props.showSnackBars('error', "No se ha agregado el PDF correspondiente a la compra.")}>
															<PictureAsPdf style={{color: 'rgba(0, 0, 0, 0.54)'}}/>
														</IconButton>
													</Tooltip>
												)}
											</div>
										</div>
									</TableCell>
									<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
										
										<div className={'w-auto vertical-inline'}>
											<div className={'v-center'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
													<IconButton aria-label="Eliminar"
													            onClick={() => this.props.delete(item)}>
														<DeleteOutlined/>
													</IconButton>
												</Tooltip>
											</div>
											<div className={'v-center'}>
												<ModalCompras
													id_compra={item.id_compra}
													item={item}
													tipo={'edit'}
													icono={(
														<Tooltip TransitionComponent={Zoom} placement={"top"}
														         title="Editar">
															<IconButton aria-label="Editar">
																<EditOutlined/>
															</IconButton>
														</Tooltip>
													)}
													RefreshList={this.props.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											</div>
											<div className={'v-center'}>
												<ModalCompras
													id_compra={item.id_compra}
													item={item}
													tipo={'view'}
													icono={(
														<Tooltip TransitionComponent={Zoom} placement={"top"}
														         title="Detalles">
															<IconButton aria-label="Detalles">
																<SearchOutlined/>
															</IconButton>
														</Tooltip>
													)}
													RefreshList={this.props.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											</div>
										</div>
									
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	delete: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
