import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {AccountTree, DateRangeOutlined, HdrStrong, MailOutline, PersonOutlined, SearchOutlined, ViewDay, Business} from "@material-ui/icons";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {CatService as TipoUsuarioServiceService, CatService} from "../../../../services/_Cat/CatService/CatService";
import {TipoUsuarioService} from "../../../../services/_Cat/TipoUsuarioService/TipoUsuarioService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {ProyectoService} from "../../../../services/_Cat/ProyectoService/ProyectoService";
import {ModuloService} from "../../../../services/_Cat/ModuloService/ModuloService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import TextField from "@material-ui/core/TextField";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {KeyboardDatePicker} from "@material-ui/pickers";


class FiltrosImportesNetos extends Component {
	
	Usr = {};
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			usuario: '',
			id_importe_neto: '',
			id_empresa: '',
			
			fecha_inicio: null,
			fecha_fin: null,
			fecha_envio: null,
			activo: '',
			
		};
		
	}
	
	ListImpuesto = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoImpuesto().then((response) => {
				this.setState({
					lista_tipo_impuesto: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.id_importe_neto = this.state.id_importe_neto || undefined;
		filtro.id_empresa = this.state.id_empresa || undefined;
		filtro.ejercicio = this.state.ejercicio || undefined;
		filtro.periodo = this.state.periodo || undefined;
		filtro.id_cat_tipo_impuesto = this.state.id_cat_tipo_impuesto || undefined;
		filtro.id_cat_tipo_declaracion = this.state.id_cat_tipo_declaracion || undefined;
		filtro.activo = this.state.activo || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={6} sm={6} md={2} lg={1} xl={1} align={'left'}>
						<TextField
							type={'text'}
							fullWidth
							name="id_importe_neto"
							onChange={this.handleChange}
							label="ID"
							autoComplete={'off'}
							value={this.state.id_importe_neto}
						/>
					</Grid>
					
					<Grid item xs={12} sm={12} md={3} lg={3} xl={3} align={'left'}>
						<MDDatePicker
							type={'range'}
							component={(
								<TextField
									fullWidth
									label="Periodo"
									value={(this.state.fecha_inicio && this.state.fecha_fin) ? (DateFormat.FormatText(this.state.fecha_inicio) + " al " + DateFormat.FormatText(this.state.fecha_fin)) : ''}
									readOnly
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton aria-label="Periodo / Ejercicio">
													<DateRangeOutlined/>
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							)}
							startDate={this.state.fecha_inicio}
							endDate={this.state.fecha_fin}
							onChange={(range) => {
								console.log("RANGE", range);
								this.setState({
									fecha_inicio: range.startDate,
									fecha_fin: range.endDate,
								});
								setTimeout(() => this.HandleFiltro());
							}}
						/>
					</Grid>
					
					<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
						<KeyboardDatePicker
							format="dd/MM/yyyy"
							fullWidth
							clearable
							inputProps={{readOnly: true}}
							/*KeyboardButtonProps={{
								'aria-label': 'Fecha de pago',
							}}*/
							label="Fecha envió información"
							value={this.state.fecha_envio}
							onChange={(date) => {
								this.handleChange(null, null, null, date, 'fecha_envio');
							}}
							// minDate={moment(new Date())}
							clearLabel={'Limpiar'}
							okLabel={'Aceptar'}
							cancelLabel={'Cancelar'}
							onError={console.log}
						/>
					</Grid>
					
					<Grid item xs={6} sm={6} md={3} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Business className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Empresa Relacionada</InputLabel>
									<Select
										native
										value={this.state.id_empresa}
										onChange={this.handleChange}
										name="id_empresa"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.props.lista_empresa.map(option => (
											<option key={option.id_empresa}
											        value={option.id_empresa}>
												{option.nombre_comercial}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel htmlFor="age-native-activo">Estatus</InputLabel>
									<Select
										native
										value={this.state.estatus}
										onChange={this.handleChange}
										inputProps={{
											name: 'activo',
											id: 'age-native-activo',
										}}
									>
										<option value={'all'}>Todos</option>
										<option value={1}>Por enviar</option>
										<option value={2}>Enviado</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={2} xl={2} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosImportesNetos.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	lista_empresa: PropTypes.array.isRequired,
	ListEmpresa: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosImportesNetos;
