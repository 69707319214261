import React, {Component, Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {$cWarn, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import {Description, CloudUpload, Backup, FindInPage} from "@material-ui/icons";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import ModalTimbradoNomina from "./ModalTimbradoNomina";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import ModalSubirPDF from "./ModalSubirPDF";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 600,
	},
});


class SubTablaTimbradoNomina extends Component {
	state = {};
	Usr = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			folio: props.item.folio,
			item: props.item,
			listar_equipos: props.item.equipos || [],
		}
	}
	
	componentDidMount() {
		$cWarn("componentDidMount");
	}
	
	componentWillUnmount() {
		$cWarn("componentWillUnmount");
	}
	
	pasos = () => {
		let curculo = [
			{
				fecha_alta: "12/12/2019",
				serie: '1111111',
				folio: '2222222',
				folio_fiscal: '3333333',
				url_xml_factura: 'http://localhost:3000/facturacion',
				url_pdf_factura: 'http://localhost:3000/facturacion',
			},
			{
				fecha_alta: "12/12/2019",
				serie: '1111111',
				folio: '2222222',
				folio_fiscal: '3333333',
				url_xml_factura: 'http://localhost:3000/facturacion',
				url_pdf_factura: 'http://localhost:3000/facturacion',
			},
		
		];
		return curculo;
	};
	
	
	generarCarta = (item, archivo, auth) => {
		if (FieldsJs.Field(item.id_empresa) && item.id_empresa > 0) {
			let filtro = {
				id_resguardo: item.id_resguardo || null,
			};
			HttpRequest.export('cartapdf', ['carta'], {
				auth: auth,
				archivo: archivo,
				filtro: filtro
			}, true, false, false, archivo === "PDF")
			
		} else {
			this.showSnackBars('error', "Aun no se ha seleccionado la empresa.");
		}
		
	};
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Open(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	render() {
		
		
		const {classes} = this.props;
		
		return (
			<Fragment>
				<div className={'bg-white card-1'}>
					
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={'px-14 text-center bg-white padding-5'}>
							Equipo(s)
						</Typography>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Table className={classes.table + ' desing-integraciones'}>
							<TableHead>
								<TableRow>
									<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Equipo</TableCell>
									<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Cantidad</TableCell>
									<TableCell component="th" align={'left'}>Marca</TableCell>
									<TableCell component="th" align={'left'}>Modelo</TableCell>
									<TableCell component="th" align={'left'}>Serie</TableCell>
									<TableCell component="th" align={'left'}>Color</TableCell>
									<TableCell component="th" align={'left'}>Recibío</TableCell>
									{/*<TableCell component="th" align={'left'}>Estado Actual</TableCell>*/}
									<TableCell component="th" align={'left'}>Dirección Actual</TableCell>
									<TableCell component="th" align={'center'}>URL Anexo</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{this.state.listar_equipos.map((item, key) => {
									return (
										<Fragment key={key}>
											<TableRow>
												<TableCell align={'left'} style={{paddingLeft: '15px'}}>{item.equipo}</TableCell>
												<TableCell align={'left'} style={{paddingLeft: '15px'}}>{item.cantidad}</TableCell>
												<TableCell align={'left'}>{item.marca}</TableCell>
												<TableCell align={'left'}>{item.modelo}</TableCell>
												<TableCell align={'left'}>{item.serie}</TableCell>
												<TableCell align={'left'}>{item.color}</TableCell>
												<TableCell align={'left'}>{item.recibio_recepcion}</TableCell>
												{/*<TableCell align={'left'}>{item.estado_actual}</TableCell>*/}
												<TableCell align={'left'}>{item.direccion_actual}</TableCell>
												<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
													<div className={'w-auto vertical-inline'}>
														
														<div className={'v-center'}>
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Generar carta resguardo">
																<IconButton aria-label="Generar carta resguardo" onClick={() => this.generarCarta(item)}><Description/></IconButton>
															</Tooltip>
														</div>
														
														{ item.archivo_firmado ? (
															<div className={'v-center'}>
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Descargar resguardo firmado">
																	<IconButton aria-label="Descargar resguardo firmado" onClick={() => this.exportar(item.archivo_firmado)}><FindInPage style={{color: 'rgb(0, 99, 0)'}}/></IconButton>
																</Tooltip>
															</div>
														) : (
															<div className={'v-center'}>
																<ModalSubirPDF
																	tipo={'add'}
																	item={item}
																	componente={
																		<Tooltip TransitionComponent={Zoom} placement={"top"} title="Subir resguardo firmado" className={!FieldsJs.Field(item.archivo) ? 'animate__animated animate__bounce animate__delay-2s animate__infinite infinite':''}>
																			<IconButton aria-label="Subir resguardo firmado"><Backup/></IconButton>
																		</Tooltip>
																	}
																	showSnackBars={this.props.showSnackBars}
																	RefreshList={this.props.RefreshList}
																/>
															</div>
														)}
														
														{/*<div className={'v-center'}>
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Subir carta firmada">
																<IconButton aria-label="Subir carta firmada"><CloudUpload/></IconButton>
															</Tooltip>
														</div>*/}
														
														{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
															<div className={'v-center'}>
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
																	<IconButton aria-label="Eliminar" onClick={() => this.props.eliminar(item)}><DeleteOutlined/></IconButton>
																</Tooltip>
															</div>
														) : null}
														
														<div className={'v-center'}>
															<ModalTimbradoNomina
																tipo={'edit'}
																item={item}
																RefreshList={this.props.RefreshList}
																showSnackBars={this.props.showSnackBars}
																lista_empresa={this.props.lista_empresa}
															/>
														</div>
														
														<div className={'v-center'}>
															<ModalTimbradoNomina
																tipo={'view'}
																item={item}
																RefreshList={this.props.RefreshList}
																showSnackBars={this.props.showSnackBars}
																lista_empresa={this.props.lista_empresa}
															/>
														</div>
													</div>
												</TableCell>
											</TableRow>
										</Fragment>
									);
								})
								}
							</TableBody>
						</Table>
					</Grid>
				
				</div>
			</Fragment>
		);
	}
}

SubTablaTimbradoNomina.propTypes = {
	item: PropTypes.object.isRequired,
	eliminar: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired
};

export default withStyles(styles)(SubTablaTimbradoNomina);
