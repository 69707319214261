import React, {Component, Fragment} from 'react';
import Typography from "@material-ui/core/Typography/index";
import Sync from '@material-ui/icons/Sync';
import ListaTabla from './Includes/ListaTabla';
import {FacturacionService} from '../../../services/FacturacionService/FacturacionService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Zoom from '@material-ui/core/Zoom/index';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab/index";
import VistaVacia from "../../Include/VistaVacia/VistaVacia";
import carrito_de_compra_1 from "../../../assets/img/icons/facturacion.svg";
import Paginacion from "../../Include/Paginacion/Paginacion";
import FiltrosTimbradoFacturacion from "./Includes/FiltrosTimbradoFacturacion";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import {CONFIG} from "../../../settings/Config/Config";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import {BarChart, Description, PostAdd} from "@material-ui/icons";
import {TimbradoFacturacionExtService} from "../../../services/TimbradoFacturacionExtService/TimbradoFacturacionExtService";
import ModalConsultaSat from "./Includes/ModalConsultaSat";
import {CargaCFDIService} from "../../../services/CargaCFDIService/CargaCFDIService";
import ModalDescargaCFDI from "../CargaCFDI/Includes/ModalDescargaCFDI";
import {BotonActualizarLista, BotonDescargaReporte, BotonExportarListaExcel, CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import ModalExcelCFDI from "./Includes/ModalExcelCFDI";
import ModalOpcionesTimbrado from "./Includes/ModalOpcionesTimbrado";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import ListaTablaPago from "./Includes/ListaTablaPago";


class TimbradoFacturacion extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		let ids_expandido = ReactLocalStorageService.get('ids_expandido');
		
		
		this.state = {
			
			filtro: {
				id_usuario: this.Usr.id_usuario,
				id_rocket_chat: this.Usr.id_rocket_chat,
				id_plaza: this.Usr.id_plaza
			},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			listar_facturacion: [],
			
			band_download_cfdi: false,
			
			ids_expandido: ids_expandido || [],
			
			listDropbox: [],
			listar_usuarios: [],
			
			open_modal_opciones: false,
			
			id_cat_tipo_cfdi_band: null
		};
		
		this.RefreshList();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	RefreshList = () => {
		this.Listar();
		this.listarUsuarios();
	};
	
	Listar = () => {
		TimbradoFacturacionExtService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			
			this.setState({
				listar_facturacion: response.data,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				},
				id_cat_tipo_cfdi_band: response.paginacion.id_cat_tipo_cfdi_band
			});
			
		}).catch((error) => {
			
			this.setState({listar_facturacion: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	listarUsuarios = () => {
		CatService.ListUsuarioRocketChat().then(response => {
			this.setState({
				listar_usuarios: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_usuarios: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	exportar = (item, archivo, auth) => {
		if (item.id_facturacion > 0) {
			let tipo = 2;
			HttpRequest.export('cxid', ['facturacion'], {
				id_facturacion: item.id_facturacion,
				tipo: tipo,
			}, !(tipo === 3))
		} else {
			let fecha_alta = (this.state.filtro.fecha_alta || null) ? DateFormat.FormatSql(this.state.filtro.fecha_alta) : null;
			
			let filtro = {
				id_facturacion: this.state.filtro.id_facturacion || null,
				id_usuario: this.state.filtro.id_usuario || null,
				id_rocket_chat: this.state.filtro.id_rocket_chat || null,
				id_plaza: this.state.filtro.id_plaza || null,
				cliente: this.state.filtro.cliente || null,
				id_cat_forma_pago: this.state.filtro.id_cat_forma_pago || null,
				id_cat_metodo_pago: this.state.filtro.id_cat_metodo_pago || null,
				fecha_alta: fecha_alta,
				ordenar_fecha_alta: this.state.filtro.ordenar_fecha_alta || null,
				
			};
			HttpRequest.export('c', ['facturacion'], {
				auth: auth,
				archivo: archivo,
				filtro: filtro
			}, false, false, false, archivo === "EXCEL")
		}
	};
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	handleChangeModalOpciones = (ruta_xml) => {
		this.setState({
			open_modal_opciones : true,
			ruta_xml: ruta_xml
		});
	};
	
	closeModalOpciones = () => {
		this.setState({
			open_modal_opciones : false,
			ruta_xml: ''
		});
	};
	
	ShowSubTabla = (item, key) => {
		let expandir = !!!item.expandir;
		this.handleChange({
			target: {
				value: expandir,
				name: 'expandir__' + key,
				type: 'text'
			}
		}, 'listar_facturacion', 'expandir');
		let ids_temp = this.state.ids_expandido;
		let ids_expandido = [];
		if (expandir) {
			ids_expandido = ids_temp;
			ids_expandido.push({
				id_facturacion_cfdi: item.id_facturacion_cfdi
			});
		} else {
			for (let i = 0; i < ids_temp.length; i++) {
				if (Number(ids_temp[i].id_facturacion_cfdi) !== Number(item.id_facturacion_cfdi)) {
					ids_expandido.push({
						id_facturacion_cfdi: ids_temp[i].id_facturacion
					})
				}
			}
			
		}
		ReactLocalStorageService.set('ids_expandido', ids_expandido);
		this.setState({
			ids_expandido: ids_expandido
		});
	};
	
	SubTabla = (item, key) => {
		this.ShowSubTabla(item, key);
	};
	
	ListarDonwload = () => {
		let fecha_inicio = this.state.filtro.fecha_inicio ? DateFormat.FormatSql(this.state.filtro.fecha_inicio) : null;
		let fecha_fin = this.state.filtro.fecha_fin ? DateFormat.FormatSql(this.state.filtro.fecha_fin) : null;
		let params = {
			id_facturacion: this.state.filtro.id_facturacion || '',
			cliente: this.state.filtro.cliente || '',
			empresa: this.state.filtro.empresa || '',
			id_usuario: this.state.filtro.id_usuario || '',
			id_rocket_chat: this.state.filtro.id_rocket_chat || '',
			id_plaza: this.state.filtro.id_plaza || '',
			serie: this.state.filtro.serie || '',
			folio: this.state.filtro.folio || '',
			id_cat_forma_pago: this.state.filtro.id_cat_forma_pago || '',
			id_cat_metodo_pago: this.state.filtro.id_cat_metodo_pago || '',
			id_cat_facturacion_estatus: this.state.filtro.id_cat_facturacion_estatus || '',
			id_cat_tipo_cfdi: this.state.filtro.id_cat_tipo_cfdi || '',
			fecha_inicio: fecha_inicio || '',
			fecha_fin: fecha_fin || '',
			id_cat_facturacion_status_sat: this.state.filtro.id_cat_facturacion_status_sat || null,
			id_cat_facturacion_status_pago: this.state.filtro.id_cat_facturacion_status_pago || null,
			uuid: this.state.filtro.uuid || null,
		};
		
		CargaCFDIService.ListarDownloadFac(params).then((response) => {
			let data = response.data;
			if (data.length > 0) {
				this.setState({
					band_download_cfdi: true,
					directorio: response.data
				});
			} else {
				this.showSnackBars('error', response.mensaje);
			}
			
		}).catch((error) => {
			
			this.setState({listar_facturacion: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	cancelModalDescarga = () => {
		this.setState({
			band_download_cfdi: false
		});
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				<CabeceraTituloPdfExcelLista
					titulo={'Consulta de CFDI'}
					
					botonReporte={
						<Fragment>
							<Fab className={'vertical-inline'} variant="extended" size="small" color="primary" style={{backgroundColor: "rgba(130,38,27,0.8)"}}
							     onClick={() => this.ListarDonwload()}>
								{/*<img className={'v-center padding-6-R'} alt={'Chat'} src={icon_integraciones} width="25px" height="auto"/>*/}
								<PostAdd className={'v-center margin-5-R px-15'}/>
								<div className={'v-center px-13 text-white'}>
									Descarga CFDI´s
								</div>
							</Fab>
						</Fragment>
					}
					
					botonDropbox={
						<Fragment>
							<ModalConsultaSat
								tipo={'add'}
								componente={
									<Fab variant="extended" size="small" color="default" aria-label="Add"
									     className={'v-center margin-10-L'}>
										<Description className={'margin-5-R px-14'}/>
										Consulta SAT
									</Fab>
								}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
						</Fragment>
					}
					
					botonEXCEL={
						<Fragment>
							{FieldsJs.inArray([1, 2, 7, 9, 11, 13, 14], this.Usr.id_cat_tipo_usuario) ? (
								<ModalExcelCFDI
									componente={
										<BotonExportarListaExcel onClick={() => {
											//this.exportaryenviar({}, 'EXCEL', true, 1)
										}}/>
									}
									filtro={this.state.filtro}
									RefreshList={this.RefreshList}
									showSnackBars={this.showSnackBars}
								/>
							) : null}
						</Fragment>
					}
					
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() => { this.RefreshList(); }}/>
						</Fragment>
					}
				/>
				
				<FiltrosTimbradoFacturacion
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				
				{this.state.band_download_cfdi ? (
					<Fragment>
						<ModalDescargaCFDI
							open={this.state.band_download_cfdi}
							item={this.state}
							fileText={"Facturación"}
							cancelModalDescarga={this.cancelModalDescarga}
						/>
					</Fragment>
				) : null
				}
				
				{/*{this.state.open_modal_opciones ? (
					<Fragment>
						<ModalOpcionesTimbrado
							tipo={'add'}
							open={this.state.open_modal_opciones}
							item={this.state}
							RefreshList={this.RefreshList}
							showSnackBars={this.showSnackBars}
							handleChangeModalOpciones={this.handleChangeModalOpciones}
							closeModalOpciones={this.closeModalOpciones}
						/>
					</Fragment>
				) : null }*/}
				
				<div className={'form margin-30-B'}>
					
					{this.state.listar_facturacion.length > 0 ? (
						<Fragment>
							{Number(this.state.id_cat_tipo_cfdi_band) === 4 ? (
								<ListaTablaPago
									lista={this.state.listar_facturacion}
									listDropbox={this.state.listDropbox}
									listar_usuarios={this.state.listar_usuarios}
									delete={this.delete}
									RefreshList={this.RefreshList}
									showSnackBars={this.showSnackBars}
									SubTabla={this.SubTabla}
								/>
							):(
								<ListaTabla
									lista={this.state.listar_facturacion}
									listDropbox={this.state.listDropbox}
									listar_usuarios={this.state.listar_usuarios}
									delete={this.delete}
									RefreshList={this.RefreshList}
									showSnackBars={this.showSnackBars}
									SubTabla={this.SubTabla}
								/>
							)}
							
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={null}
							mensaje={'No se encontraron datos.'}
							image={carrito_de_compra_1}
							// padding={'20px'}
							// paddingText={'20px'}
							// height={'80px'}
							// width={'80px'}
						/>
					)}
				
				</div>
				
				{/*{FieldsJs.inArray([1, 2, 3, 9, 13], this.Usr.id_cat_tipo_usuario) || Number(this.Usr.id_usuario) === 101 ? (
					<ModalFacturacionPromotor
						id_facturacion={null}
						item={{}}
						tipo={'add'}
						icono={(
							<Zoom className={'btn-fixed-bottom-right cursor-pointer'}
							      key={'inherit'}
							      timeout={1500}
							      in={true}
							      style={{transitionDelay: `${100}ms`}}
							      unmountOnExit
							>
								<Fab color={'primary'}><Add/></Fab>
							</Zoom>
						)}
						RefreshList={this.RefreshList}
						showSnackBars={this.showSnackBars}
					/>
				) : null}*/}
			
			</div>
		);
	}
}

TimbradoFacturacion.propTypes = {};

export default IntegrationNotistack(TimbradoFacturacion);
