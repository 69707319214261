import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {AccountTree, DateRangeOutlined, HdrStrong, MailOutline, PersonOutlined, SearchOutlined, ViewDay, Business} from "@material-ui/icons";
import {EnteroSolo, FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {CatService as TipoUsuarioServiceService, CatService} from "../../../../services/_Cat/CatService/CatService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";


class FiltrosComprobantesNomina extends Component {
	
	Usr = {};
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			usuario: '',
			id_comprobante_nomina: '',
			id_empresa: '',
			ejercicio: '',
			periodo: '',
			id_cat_banco: '',
			id_cat_tipo_documento_estados_cuenta: '',
			id_empleado: '',
			id_cat_periodicidad_pago: '',
			id_cat_periodicidad: '',
			fecha_alta: null,
			fecha_pago: null,
			fecha_pago_inicio: null,
			fecha_pago_fin: null,
			fecha_alta_inicio: null,
			fecha_alta_fin: null,
			lista_empleado: [],
			lista_cat_periodicidad_pago: [],
			activo: '',
			importe: '',
			
			lista_ejercicio: [
				{anio: 2022},
				{anio: 2021},
				{anio: 2020},
				{anio: 2019},
				{anio: 2018},
				{anio: 2017},
				{anio: 2016},
				{anio: 2015},
				{anio: 2014},
				{anio: 2013},
				{anio: 2012},
				{anio: 2011},
				{anio: 2010},
				{anio: 2009},
				{anio: 2008},
				{anio: 2007},
				{anio: 2006},
				{anio: 2005},
			],
			lista_periodo: [
				{mes: 'Enero'},
				{mes: 'Febrero'},
				{mes: 'Marzo'},
				{mes: 'Abril'},
				{mes: 'Mayo'},
				{mes: 'Junio'},
				{mes: 'Julio'},
				{mes: 'Agosto'},
				{mes: 'Septiembre'},
				{mes: 'Octubre'},
				{mes: 'Noviembre'},
				{mes: 'Diciembre'}
			],
			lista_banco: [],
			lista_tipo_documento: [],
			
		};
		
		this.ListEmpleado();
		//this.ListTipoDocumento();
		this.ListPeriodicidadPago();
	}
	
	ListEmpleado = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpleado().then((response) => {
				this.setState({
					lista_empleado: response.data
				});
				resolve(response);
			}).catch(error => {
				this.setState({
					lista_empleado: []
				});
				reject(error);
			})
		});
	};
	
	
	ListBanco = () => {
		return new Promise((resolve, reject) => {
			CatService.ListBanco().then((response) => {
				this.setState({
					lista_banco: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	ListPeriodicidadPago = () => {
		return new Promise((resolve, reject) => {
			CatService.ListPeriodicidadTipo().then((response) => {
				this.setState({
					lista_cat_periodicidad_pago: response.data
				});
				resolve(response);
			}).catch(error => {
				this.setState({
					lista_cat_periodicidad_pago: []
				});
				reject(error);
			})
		});
	};
	
	ListTipoDocumento = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoDocumentoEstadoCuenta().then((response) => {
				this.setState({
					lista_tipo_documento: response.data
				});
				resolve(response);
			}).catch(error => {
				reject(error);
			})
		});
	};
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	pPago = (id, label) => {
		
		switch (Number(id)) {
			case 1:
			case 2:
			case 3:
			case 4:
			case 5:
			case 6:
					label = label.split(' ')[1];
				break;
			
		}
		return label;
	}
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.id_comprobante_nomina = this.state.id_comprobante_nomina || undefined;
		filtro.usuario = this.state.usuario || undefined;
		filtro.id_empresa = this.state.id_empresa || undefined;
		filtro.ejercicio = this.state.ejercicio || undefined;
		filtro.periodo = this.state.periodo || undefined;
		filtro.id_cat_banco = this.state.id_cat_banco || undefined;
		filtro.id_cat_tipo_documento_estados_cuenta = this.state.id_cat_tipo_documento_estados_cuenta || undefined;
		filtro.id_empleado = this.state.id_empleado || undefined;
		filtro.id_cat_periodicidad_pago = this.state.id_cat_periodicidad_pago || undefined;
		filtro.id_cat_periodicidad = this.state.id_cat_periodicidad || undefined;
		filtro.fecha_alta = this.state.fecha_alta || undefined;
		filtro.fecha_pago = this.state.fecha_pago || undefined;
		filtro.fecha_pago_inicio = this.state.fecha_pago_inicio || undefined;
		filtro.fecha_pago_fin = this.state.fecha_pago_fin || undefined;
		filtro.fecha_alta_inicio = this.state.fecha_alta_inicio || undefined;
		filtro.fecha_alta_fin = this.state.fecha_alta_fin || undefined;
		filtro.importe = this.state.importe || undefined;
		filtro.activo = this.state.activo || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={6} sm={6} md={2} lg={1} xl={1} align={'left'}>
						<TextField
							type={'text'}
							fullWidth
							name="id_comprobante_nomina"
							onChange={this.handleChange}
							label="Id"
							autoComplete={'off'}
							value={this.state.id_comprobante_nomina}
						/>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="usuario"
									onChange={this.handleChange}
									label="Usuario"
									autoComplete={'off'}
									value={this.state.usuario}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={3} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Business className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Empresa</InputLabel>
									<Select
										native
										value={this.state.id_empresa}
										onChange={this.handleChange}
										name="id_empresa"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.props.lista_empresa.map(option => (
											<option key={option.id_empresa}
											        value={option.id_empresa}>
												{option.nombre_comercial}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Empleado</InputLabel>
									<Select
										native
										value={this.state.id_empleado}
										onChange={this.handleChange}
										name="id_empleado"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.state.lista_empleado.map(option => (
											<option key={option.id_empleado}
											        value={option.id_empleado}>
												{option.nombre_completo}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={12} sm={12} md={2} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Periodicidad de pago</InputLabel>
									<Select
										native
										value={this.state.id_cat_periodicidad}
										onChange={this.handleChange}
										name="id_cat_periodicidad"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										<option value={1}>Semanal</option>
										<option value={2}>Quincenal</option>
										<option value={3}>Anual</option>
									
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={12} sm={12} md={2} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Tipo pago</InputLabel>
									<Select
										native
										value={this.state.id_cat_periodicidad_pago}
										onChange={this.handleChange}
										name="id_cat_periodicidad_pago"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										<option value={1}>Capacitación</option>
										<option value={2}>SyS</option>
										<option value={3}>IAS</option>
										<option value={4}>Aguinaldo</option>
										<option value={5}>Prima Vacacional</option>
										
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={2} xl={2} align={'left'}>
						<MDDatePicker
							type={'range'}
							component={(
								<TextField
									fullWidth
									label="Fecha de Pago"
									value={(this.state.fecha_pago_inicio && this.state.fecha_pago_fin) ? (DateFormat.FormatText(this.state.fecha_pago_inicio) + " al " + DateFormat.FormatText(this.state.fecha_pago_fin)) : ''}
									readOnly
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton aria-label="Fecha de Pago">
													<DateRangeOutlined/>
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							)}
							startDate={this.state.fecha_pago_inicio}
							endDate={this.state.fecha_pago_fin}
							onChange={(range) => {
								this.setState({
									fecha_pago_inicio: range.startDate,
									fecha_pago_fin: range.endDate,
								});
								setTimeout(() => this.HandleFiltro());
							}}
						/>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={2} xl={2} align={'left'}>
						<MDDatePicker
							type={'range'}
							component={(
								<TextField
									fullWidth
									label="Fecha Alta"
									value={(this.state.fecha_alta_inicio && this.state.fecha_alta_fin) ? (DateFormat.FormatText(this.state.fecha_alta_inicio) + " al " + DateFormat.FormatText(this.state.fecha_alta_fin)) : ''}
									readOnly
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton aria-label="Fecha Alta">
													<DateRangeOutlined/>
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							)}
							startDate={this.state.fecha_alta_inicio}
							endDate={this.state.fecha_alta_fin}
							onChange={(range) => {
								this.setState({
									fecha_alta_inicio: range.startDate,
									fecha_alta_fin: range.endDate,
								});
								setTimeout(() => this.HandleFiltro());
							}}
						/>
					</Grid>
					
					<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={2} xl={2}>
						<TextField
							className={'margin-0'}
							type={'text'}
							fullWidth
							name="importe"
							onChange={this.handleChange}
							label="Importe"
							autoComplete={'off'}
							value={this.state.importe}
							onKeyPress={EnteroSolo}
						/>
					</Grid>
					
					
					{/*<Grid item className={'text-left'} xs={12} sm={12} md={2} lg={2} xl={2}>
						<KeyboardDatePicker
							//autoOk={true}
							format="dd/MM/yyyy"
							fullWidth
							clearable
							inputProps={{readOnly: true}}
							label="Fecha de Pago"
							value={this.state.fecha_pago}
							onChange={(date) => {
								this.handleChange(null, null, null, date, 'fecha_pago');
							}}
							// minDate={moment(new Date())}
							clearLabel={'Limpiar'}
							okLabel={'Aceptar'}
							cancelLabel={'Cancelar'}
							onError={console.log}
							disabled={this.state.tipo === 'view'}
						/>
					</Grid>*/}
					
					{/*<Grid item className={'text-left'} xs={12} sm={12} md={2} lg={2} xl={2}>
						<KeyboardDatePicker
							//autoOk={true}
							format="dd/MM/yyyy"
							fullWidth
							clearable
							inputProps={{readOnly: true}}
							label="Fecha Alta"
							value={this.state.fecha_alta}
							onChange={(date) => {
								this.handleChange(null, null, null, date, 'fecha_alta');
							}}
							// minDate={moment(new Date())}
							clearLabel={'Limpiar'}
							okLabel={'Aceptar'}
							cancelLabel={'Cancelar'}
							onError={console.log}
							disabled={this.state.tipo === 'view'}
						/>
					</Grid>*/}
					
					{/*<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel>Tipo Documento</InputLabel>
									<Select
										native
										value={this.state.id_cat_tipo_documento_estados_cuenta}
										onChange={this.handleChange}
										name="id_cat_tipo_documento_estados_cuenta"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.state.lista_tipo_documento.map(option => (
											<option key={option.id_cat_tipo_documento_estados_cuenta}
											        value={option.id_cat_tipo_documento_estados_cuenta}>
												{option.tipo_documento}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>*/}
					
					<Grid item xs={12} sm={12} md={12} lg={2} xl={2} align={'left'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosComprobantesNomina.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	lista_empresa: PropTypes.array.isRequired,
	ListEmpresa: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosComprobantesNomina;
