import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {
	BotonActualizarLista,
	BotonExportarListaExcel,
	BotonExportarListaPDF,
	CabeceraTituloPdfExcelLista
} from "../../../Include/MiniComponents/GlobalComponent";
import Button from "@material-ui/core/Button";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import {HomeWorkOutlined} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import ClienteReceptoraPagoDatoBancarioService from "../../../../services/_Cat/ClienteReceptoraPagoDatoBancarioService/ClienteReceptoraPagoDatoBancarioService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import ListaTabla from "./Includes/ListaTabla";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import ModalClienteReceptoraPagoDatoBancario from "./Includes/ModalClienteReceptoraPagoDatoBancario";

class ClienteReceptoraPagoDatoBancario extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			modal: {
				open: false
			},
			id_empresa: (props.item || {}).id_empresa,
			empresa_dato_bancario: [],
		};
	}
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshList = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			ClienteReceptoraPagoDatoBancarioService.Listar(this.state.id_empresa).then((response) => {
				
				this.setState({empresa_dato_bancario: response.data});
				
				resolve(true);
				
			}).catch((error) => {
				
				this.setState({empresa_dato_bancario: []});
				
				this.props.showSnackBars('error', error.mensaje);
				
				reject(false);
				
			});
		});
	};
	
	render() {
		
		return (
			<div>
				
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Datos Bancarios">
					<IconButton aria-label="Datos Bancarios" onClick={() => this.open()}>
						<HomeWorkOutlined/>
					</IconButton>
				</Tooltip>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<CabeceraTituloPdfExcelLista
									titulo={'Cuentas Bancarias'}
									/*botonPDF={
										<Fragment>
											{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
												<BotonExportarListaPDF onClick={() => {
												
												}}/>
											) : ''}
										</Fragment>
									}
									botonEXCEL={
										<Fragment>
											{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
												<BotonExportarListaExcel onClick={() => {
												
												}}/>
											) : ''}
										</Fragment>
									}*/
									botonLISTA={
										<Fragment>
											<BotonActualizarLista onClick={() => {
												this.RefreshList();
											}}/>
										</Fragment>
									}
									marginTop={10}
									marginBottom={10}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<div className={'form margin-30-B'}>
									
									{this.state.empresa_dato_bancario.length > 0 ? (
										<ListaTabla
											lista={this.state.empresa_dato_bancario}
											RefreshList={this.RefreshList}
											showSnackBars={this.props.showSnackBars}
										/>
									) : (
										<VistaVacia
											width={70}
											height={70}
											numero={0}
											mensaje={'No se encontraron datos.'}
										/>
									)}
								
								</div>
								
								<ModalClienteReceptoraPagoDatoBancario
									tipo={'add'}
									item={{
										id_empresa_dato_bancario: null,
										id_empresa: this.state.id_empresa
									}}
									RefreshList={this.RefreshList}
									showSnackBars={this.props.showSnackBars}
								/>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={1}>
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Button onClick={this.close} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'right'}>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ClienteReceptoraPagoDatoBancario.propTypes = {
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ClienteReceptoraPagoDatoBancario;
