import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import ModalRepse from "./ModalRepse";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {RepseService} from "../../../../services/RepseService/RepseService";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import ModalRecordatorios from "../../Reccordatorios/Includes/ModalRecordatorios";
import {AttachMoney, MonetizationOn, Money, SpeakerNotes, Description, InsertDriveFile} from "@material-ui/icons";
import ModalComprobantePago from "./ModalComprobantePago";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import icon_rocket_chat from "../../../../assets/img/icons/rocketchat.svg";
import ModalSendRocketChat from "./ModalSendRocketChat";
import ModalRepseAcuse from "./ModalRepseAcuse";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el REPSE con id ${item.id_repse}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				RepseService.Eliminar(item.id_repse).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					
					this.props.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	
	spanStatus = (item) => {
		let bgg = 'white';
		switch (Number(item.id_cat_cronograma_estatus)) {
			case 1:
				bgg = 'gray';
				break;
			case 2:
				bgg = '#00c1ff';
				break;
			case 3:
				bgg = '#fcee15';
				break;
			case 4:
				bgg = '#c66500';
				break;
			case 5:
				bgg = '#119703';
				break;
			default:
				bgg = '#ccc';
				break;
		}
		
		const foto_default = CONFIG.foto_default;
		
		return (
			<Fragment>
				<div className={'w-100 text-white'} style={{borderRadius: '5px', background: bgg}}>
					{item.cronograma_estatus}
				</div>
			</Fragment>
		);
	};
	
	verDocumento = (path) => {
		console.log("PATH ::: ", path);
		DropboxApi.Open(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'} className={'padding-10-L'}>N.P.</TableCell>
							<TableCell component="th" align={'left'}>Fecha</TableCell>
							<TableCell component="th" align={'left'}>Usuario</TableCell>
							<TableCell component="th" align={'left'}>RFC</TableCell>
							<TableCell component="th" align={'left'}>Empresa</TableCell>
							<TableCell component="th" align={'left'}>Ejercicio</TableCell>
							<TableCell component="th" align={'left'}>Periodo</TableCell>
							{/*<TableCell component="th" align={'left'}>Impuesto</TableCell>*/}
							{/*<TableCell component="th" align={'left'}>Dec.</TableCell>*/}
							{/*<TableCell component="th" align={'left'}>LC(PDF)</TableCell>*/}
							<TableCell component="th" align={'left'}>Documento</TableCell>
							<TableCell component="th" align={'left'}>Tipo</TableCell>
							<TableCell component="th" align={'left'}>Fecha Registro</TableCell>
							<TableCell component="th" align={'left'}>Número Registro</TableCell>
							<TableCell component="th" align={'left'}>Agregar</TableCell>
							{/*<TableCell component="th" align={'center'} className={'w-100-px'}>Estatus</TableCell>*/}
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							lista.map((item, key) => {
								return (
									<TableRow key={key}>
										<TableCell align={'left'} className={'padding-10-L'}>{item.id_repse}</TableCell>
										<TableCell align={'left'}>{DateFormat.getFechaTextDiagonal(item.fecha_alta)}</TableCell>
										<TableCell align={'left'}>{item.nombre_completo}</TableCell>
										<TableCell align={'left'}>{item.rfc}</TableCell>
										<TableCell align={'left'}>{item.nombre_comercial}</TableCell>
										<TableCell align={'left'}>{item.ejercicio || 'Sin registrar'}</TableCell>
										<TableCell align={'left'}>{item.periodo || 'Sin registrar'}</TableCell>
										{/*<TableCell align={'left'}>{item.abreviatura_impuesto || 'Sin registrar'}</TableCell>*/}
										{/*<TableCell align={'left'}>{item.abreviatura_declaracion || 'Sin registrar'}</TableCell>*/}
										<TableCell align={'left'}>{item.archivos.map((itemarc) => (
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver Documento">
												<IconButton aria-label="Ver Documento" onClick={() => this.verDocumento(itemarc.archivo)}><Description/></IconButton>
											</Tooltip>
										))}
										</TableCell>
										{/*<TableCell align={'left'}>{item.acuse ? <Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver Acuse Pago">*/}
										{/*	<IconButton aria-label="Ver Acuse Pago" onClick={() => this.verDocumento(item.acuse)}><InsertDriveFile style={{color: 'rgb(142, 30, 50)'}}/></IconButton>*/}
										{/*</Tooltip> : '-'}</TableCell>*/}
										<TableCell align={'left'}>{item.tipo}</TableCell>
										<TableCell align={'left'}>{item.fecha_registro ? DateFormat.getFechaTextDiagonal(item.fecha_registro) : '-'}</TableCell>
										<TableCell align={'left'}>{item.numero_registro}</TableCell>
										<TableCell align={'left'}>{item.recargo > 0 ? '$' + FieldsJs.Currency(item.recargo): '-'}</TableCell>
										{/*<TableCell align={'center'}>{item.status}</TableCell>*/}
										<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
											<div className={'w-auto vertical-inline'}>
												
												<div className={'v-center'}>
													{/*<ModalRecordatorios
														tipo={'new'}
														item={{}}
														componente={(
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Recordatorio">
																<IconButton aria-label="Recordatorio"><SpeakerNotes/></IconButton>
															</Tooltip>
														)}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>*/}
													<ModalSendRocketChat
														item={item}
														tipo={'add'}
														component={(
															<Tooltip TransitionComponent={Zoom} placement={"top"}
															         title="Enviar Notificación">
																<IconButton aria-label="Enviar Notificación">
																	<img className={'v-center'} src={icon_rocket_chat} alt="add" width={25} height={25} style={{opacity: 0.7}}/>
																</IconButton>
															</Tooltip>
														)}
														listar_usuarios={this.props.listar_usuarios}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
												
												{/*{FieldsJs.inArray([1, 2, 6, 13], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<ModalComprobantePago
															tipo={'add'}
															item={item}
															componente={(
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Comprobante Pago">
																	<IconButton aria-label="Comprobante Pago"><AttachMoney/></IconButton>
																</Tooltip>
															)}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												) : null}*/}
												
												<div className={'v-center'}>
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
														<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}><DeleteOutlined/></IconButton>
													</Tooltip>
												</div>
												
												{Number(item.tipo_documento) === 1 ? (
													<Fragment>
														<div className={'v-center'}>
															<ModalRepse
																tipo={'edit'}
																item={item}
																ultimoid={item.id_repse}
																ListEmpresa={this.props.ListEmpresa}
																lista_empresa={this.props.lista_empresa}
																RefreshList={this.props.RefreshList}
																showSnackBars={this.props.showSnackBars}
															/>
														</div>
														
														<div className={'v-center'}>
															<ModalRepse
																tipo={'view'}
																item={item}
																ListEmpresa={this.props.ListEmpresa}
																lista_empresa={this.props.lista_empresa}
																RefreshList={this.props.RefreshList}
																showSnackBars={this.props.showSnackBars}
															/>
														</div>
													</Fragment>
												) : null }
												
												{ Number(item.tipo_documento) === 2 ? (
													<Fragment>
														<div className={'v-center'}>
															<ModalRepseAcuse
																tipo={'edit'}
																item={item}
																ultimoid={item.id_repse}
																ListEmpresa={this.props.ListEmpresa}
																lista_empresa={this.props.lista_empresa}
																RefreshList={this.props.RefreshList}
																showSnackBars={this.props.showSnackBars}
															/>
														</div>
														
														<div className={'v-center'}>
															<ModalRepseAcuse
																tipo={'view'}
																item={item}
																ListEmpresa={this.props.ListEmpresa}
																lista_empresa={this.props.lista_empresa}
																RefreshList={this.props.RefreshList}
																showSnackBars={this.props.showSnackBars}
															/>
														</div>
													</Fragment>
												) : null }
												
												
											</div>
										
										</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	lista_empresa: PropTypes.array.isRequired,
	listar_usuarios: PropTypes.array,
	ListEmpresa: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
