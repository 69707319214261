import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import ModalTickets from "./ModalTickets";
import {CONFIG} from "../../../../settings/Config/Config";
import icon_mas from "../../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../../assets/img/icons/expandir-menos.svg";
import SubTablaTickets from "./SubTablaTickets";
import {TicketsService} from "../../../../services/TicketsService/TicketsService";
import {FieldsJs} from "../../../../settings/General/General";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {AssignmentTurnedIn, CallReceived, Close, SearchOutlined, Send} from "@material-ui/icons";
import ModalTicketsResponse from "./ModalTicketsResponse";
import BarraProgreso from "./BarraProgreso";
import ModalSendMail from "./ModalSendMail";

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	cerrar = (item) => {
		let msg = '¿Deseas cerrar el No. ticket: #' + item.folio + '?';
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				TicketsService.Eliminar(item.id_ticket).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	resuelto = (item) => {
		let msg = '¿Estás seguro de guardar los cambios del ticket: #' + item.folio + '?';
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Aceptar') {
				TicketsService.Status(item.id_ticket).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	is_cancel = (item) => {
		return item.activo === 0;
	};
	
	ver_root = () => {
		return this.Usr.id_usuario === 1;
	};
	
	progresoTicket = (item) => {
		let progreso = 0;
		if (item) {
			let contRealizado = 0;
			let contCompletdo = 0;
			let contProceso = 0;
			
			item.requerimientos.map((value, key) => {
				
				let obs = value.observaciones.length > 0 ? true : false;
				
				if (Number(value.id_cat_ticket_status) === 2) {
					contProceso++;
				}
				
				if (Number(value.id_cat_ticket_status) === 2 || Number(value.id_cat_ticket_status) === 3 || Number(value.id_cat_ticket_status) === 5) {
					contRealizado++;
				}
				
				if (Number(value.id_cat_ticket_status) === 5) {
					contCompletdo++;
				}
			});
			if (contProceso === 1 && contRealizado === 1) {
				progreso = parseFloat((100 / (item.requerimientos.length / (contRealizado / 2))).toFixed(0));
			} else {
				progreso = parseFloat((100 / (item.requerimientos.length / (contRealizado - contProceso))).toFixed(0));
			}
			console.log("COUNT PROCESO: ", contProceso, contRealizado, progreso);
			
			if (contRealizado === item.requerimientos.length && contCompletdo != item.requerimientos.length && progreso > 95) {
				progreso = progreso - 1;
			} else if (contRealizado === item.requerimientos.length && contCompletdo != item.requerimientos.length && progreso === 0) {
				progreso = 5;
			}
		}
		
		return progreso;
	};
	
	render() {
		
		const {lista} = this.props;
		
		console.log('LISTA', lista);
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>...</TableCell>
							<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>
								No. Ticket
							</TableCell>
							<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>
								Solicitud
							</TableCell>
							<TableCell component="th" align={'left'}>Fecha Solicitud</TableCell>
							{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (
								<TableCell component="th" align={'left'}>Solicitante</TableCell>
							) : null}
							<TableCell component="th" align={'left'}>Area de Solución</TableCell>
							<TableCell component="th" align={'center'}>Progreso</TableCell>
							<TableCell component="th" align={'left'}>Estatus</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => {
								return (
									<Fragment key={key}>
										<TableRow>
											<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
												<div className={'w-100'} align={'center'}>
													<div style={!!item.expandir ? bg_menos : bg_mas}
													     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
													     onClick={() => this.props.SubTabla(item, key)}
													/>
												</div>
											</TableCell>
											<TableCell align={'center'} className={'padding-20-L padding-20-R'}>{item.folio}</TableCell>
											<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
												<div className={'vertical-inline'}>
													{item.is_enviado ? <CallReceived style={{color: 'orange'}} className={'v-center padding-5-R'}/>
														: <Send style={{color: 'green'}} className={'v-center padding-5-R'}/>
													}
												</div>
											</TableCell>
											<TableCell align={'left'}>{DateFormat.getHoraFecha(item.fecha_solucion)}</TableCell>
											{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (
												<TableCell align={'left'}>{item.nombre_completo_solicitante}</TableCell>
											) : null}
											<TableCell align={'left'}>{item.area_solucion}</TableCell>
											
											<TableCell align={'center'} className={item.id_cat_ticket_status === 4 ? 'text-tachado' : ''}>
												{/*<div className={'w-100 h-20-px bg-lightgray b-r-3'}>
													<div className={'h-20-px bg-green b-r-3'}
													     style={{width: (this.progresoTicket(item) || 0) + "%"}}/>
												</div>*/}
												
												<BarraProgreso color={item.id_cat_ticket_status} completed={this.progresoTicket(item || 0)} buffer={100} value={this.progresoTicket(item || 0)} size={'150px'}/>
											
											</TableCell>
											
											<TableCell align={'left'}>{item.ticket_status}</TableCell>
											<TableCell align={'right'} className={'padding-5-L padding-5-R'}>
												<div className={'w-auto vertical-inline'}>
													
													{/*{ item.finalizar_ticket ? (
														<div className={'v-center'}>
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Resuelto">
																<IconButton aria-label="Resuelto"
																            onClick={() => this.resuelto(item)}>
																	<Check/>
																</IconButton>
															</Tooltip>
														</div>
														
														<div className={'v-center'}>
															<ModalTicketsResponse
																id_ticket={item.id_ticket}
																item={item}
																tipo={'add'}
																icono={(
																	<Tooltip TransitionComponent={Zoom} placement={"top"}
																	         title="Agregar">
																		<IconButton aria-label="Agregar">
																			<Check/>
																		</IconButton>
																	</Tooltip>
																)}
																RefreshList={this.props.RefreshList}
																showSnackBars={this.props.showSnackBars}
															/>
														</div>
													) : null }
													*/}
													
													{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) ? (
														<ModalSendMail
															id_ticket={item.id_ticket}
															item={item}
															tipo={'add'}
															component={(
																<Tooltip TransitionComponent={Zoom} placement={"top"}
																         title="Enviar Notificación">
																	<IconButton aria-label="Enviar Notificación">
																		<Send/>
																	</IconButton>
																</Tooltip>
															)}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													) : null}
													
													
													{item.id_cat_ticket_status != 4 && this.Usr.id_usuario === item.id_usuario_solicitante ? (
														<div className={'v-center'}>
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Cancelar Ticket">
																<IconButton aria-label="Cancelar Ticket" onClick={() => this.cerrar(item)}>
																	<Close/>
																</IconButton>
															</Tooltip>
														</div>
													) : null}
													
													<div className={'v-center'}>
														<ModalTicketsResponse
															aria-describedby={'popover' + key}
															id_ticket={item.id_ticket}
															item={item}
															tipo={'add'}
															icono={(
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Resolver">
																	<IconButton aria-label="Resolver">
																		<AssignmentTurnedIn/>
																	</IconButton>
																</Tooltip>
															)}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
													
													<div className={'v-center'}>
														<ModalTickets
															id_ticket={item.id_ticket}
															item={item}
															tipo={'view'}
															icono={(
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver detalle">
																	<IconButton aria-label="Ver detalle">
																		<SearchOutlined/>
																	</IconButton>
																</Tooltip>
															)}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												</div>
											</TableCell>
										</TableRow>
										
										{(
											<Fragment>
												{!!item.expandir ? (
													<TableRow>
														<TableCell align={'center'}
														           className={'padding-20-L padding-20-R padding-20-T padding-20-B'}
														           colSpan={this.ver_root() ? 13 : 13}
														>
															
															<SubTablaTickets
																tipo_vista={this.props.tipo_vista}
																item={item}
																showSnackBars={this.props.showSnackBars}
																RefreshList={this.props.RefreshList}
															/>
														
														</TableCell>
													</TableRow>
												) : null}
											</Fragment>
										)}
									</Fragment>
								)
							}
						)}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	SubTabla: PropTypes.func.isRequired,
};

export default ListaTabla;
