import React, {Component, Fragment} from 'react';

import './Home.css';
import logo_svg from '../../../assets/img/logo.svg';

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {ReactLocalStorageService} from "../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {FieldsJs} from "../../../settings/General/General";
import Icon from '@material-ui/core/Icon';
import Chart from "react-google-charts";
import Typography from "@material-ui/core/Typography";
import {HomeService} from "../../../services/HomeService/HomeService";
import {CONFIG} from "../../../settings/Config/Config";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import BarraProgreso from "../Tickets/Includes/BarraProgreso";
import VistaVacia from "../../Include/VistaVacia/VistaVacia";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import {VacacionesService} from "../../../services/VacacionesService/VacacionesService";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import ModalCreditoProcea from "./Includes/ModalCreditoProcea";

// import Button from "@material-ui/core/Button";


const ItemGraficaArea = ({state}) => {
	let grafica = [
		['Progreso de ventas', 'Porcentaje']
	];
	grafica.push([state.venta_creado_disenador_asignado_texto, state.venta_creado_disenador_asignado.length]);
	
	return (
		<Fragment>
			<div align={'center'} style={{width: '100%'}} className={'margin-5 fill-transparent'}>
				
				<Typography component={'h2'} className={'ff-arial'} style={{}}>
					Gráfica porcentaje progreso de ventas
				</Typography>
				
				{grafica.length > 0 ? (
					<Chart
						width={'100%'}
						height={'190px'}
						chartType="Sankey   "
						loader={
							<Typography component={'h2'} className={'ff-arial'}>
								Cargando gráficas...
							</Typography>
						}
						data={grafica}
						options={{
							is3D: true,
							backgroundColor: {fill: 'transparent'},
							title: '',
							legend: {
								alignment: 'center',
								position: 'bottom',
								textStyle: {
									color: 'black',
									fontSize: 10,
								},
							},
							pieHole: 0,
							animation: {
								duration: 1000,
								easing: 'out',
								startup: true,
							},
						}}
						rootProps={{'data-testid': '3'}}
					/>
				) : (
					<Fragment>
						<Typography component={'h2'} className={'ff-arial'}>
							Esperando datos...
						</Typography>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};


const ItemGrafica = ({state}) => {
	let grafica = [
		['Progreso de ventas', 'Porcentaje']
	];
	grafica.push([state.venta_creado_disenador_asignado_texto, state.venta_creado_disenador_asignado.length]);
	const columns = [
		{type: "string", id: "President"},
		{type: "number", id: "Start"},
		{type: "number", id: "End"}
	];
	
	const rows = [
		["VANTIS S DE RL DE CV", 13, 16],
		["VANGUARDIA EN INYECCION DE PLASTICOS DEL BAJIO S DE RL DE CV", 3, 10]
	];
	
	return (
		<Fragment>
			<div align={'center'} style={{width: '100%'}} className={'margin-5 fill-transparent'}>
				
				<Typography component={'h2'} className={'ff-arial'} style={{}}>
					Gráfica porcentaje progreso de ventas
				</Typography>
				
				{grafica.length > 0 ? (
					<Chart
						width={'100%'}
						height={'190px'}
						chartType="Timeline"
						loader={
							<Typography component={'h2'} className={'ff-arial'}>
								Cargando gráficas...
							</Typography>
						}
						data={[columns, ...rows]}
						options={{
							timeline: {colorByRowLabel: true},
							backgroundColor: '#ffd',
						}}
						rootProps={{'data-testid': '3'}}
					/>
				) : (
					<Fragment>
						<Typography component={'h2'} className={'ff-arial'}>
							Esperando datos...
						</Typography>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};


class Home extends Component {
	
	state = {};
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			Usr: (ReactLocalStorageService.get('Usr') || {}),
			
			venta_creado_disenador_asignado_texto: '',
			venta_creado_disenador_asignado: [
				['Element', 'Density', {role: 'style'}, {role: 'annotation'}],
				['Copper', 8.94, '#b87333', 'Cu'],
				['Silver', 10.49, 'silver', 'Ag'],
				['Gold', 19.30, 'gold', 'Au'],
				['Platinum', 21.45, 'color: #e5e4e2', 'Pt']
			],
			venta_en_produccion_texto: '', venta_en_produccion: [],
			venta_en_almacen_texto: '', venta_en_almacen: [],
			
			listar_home: [],
			lista_tickets: [],
			registros: [],
			cfdi: [],
			acceso: [],
			actividades: [],
			plataformas: [],
			vacaciones: [],
		};
		
		this.ListarHome();
		this.ListarPlataformas();
		//this.ListarVacaciones();
	}
	
	ListarVacaciones = () => {
		let params = {
			id_vacaciones: '',
			id_cat_proyecto: '',
			id_cat_proyecto_modulo: '',
			id_cat_cronograma_estatus: '',
			id_cat_tipo_usuario: '',
			usuario_asignado: '',
			fecha_inicio: null,
			fecha_fin: null,
			activo: 1
		};
		let paginacion = {
			total: null,
			page: 1,
			limit: 5,
			rangos: 5
		};
		VacacionesService.Listar(params, paginacion).then((response) => {
			this.setState({
				vacaciones: response.data,
			});
		}).catch((error) => {
			this.setState({vacaciones: []});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListarHome = () => {
		HomeService.Listar().then((response) => {
			this.setState({
				listar_home: response.data || [],
				lista_tickets: response.data.lista_tickets || [],
				registros: response.data.registros || [],
				cfdi: response.data.cfdi || [],
				acceso: response.data.acceso || [],
				actividades: response.data.actividades || [],
				vacaciones: response.data.vacaciones || []
			});
		}).catch((error) => {
			this.setState({
				listar_home: [],
				lista_tickets: [],
				registros: [],
				cfdi: [],
				acceso: [],
				actividades: [],
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListarPlataformas = () => {
		CatService.ListPlataformas().then((response) => {
			this.setState({
				plataformas: response.data
			});
		}).catch((error) => {
			this.setState({
				plataformas: [],
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	renderSwitchIcon = (icono) => {
		return <Icon className={'px-30 margin-10-R'} style={{color: '#d9e9eb'}}>{icono}</Icon>;
	};
	
	go = (u) => {
		this.props.history.push('/' + u);
	};
	
	redireccion = (url) => {
		if (FieldsJs.Field(url)) {
			window.open(url, '_blank');
		}
	};
	
	getRegistros = (id_menu) => {
		let registros = 0;
		if (this.state.registros.length > 0) {
			for (let i = 0; i < this.state.registros.length; i++) {
				if (Number(id_menu) === Number(this.state.registros[i].id_menu)) {
					registros = this.state.registros[i].total
				}
			}
			
			/*switch (key) {
				case 2:
					registros = this.state.registros[0].total;
					break;
				case 3:
					registros = this.state.registros[1].total;
					break;
				case 4:
					registros = this.state.registros[2].total;
					break;
				case 5:
					registros = this.state.registros[3].total;
					break;
				case 6:
					registros = this.state.registros[4].total;
					break;
				case 7:
					registros = this.state.registros[7].total;
					break;
				case 8:
					registros = this.state.registros[5].total;
					break;
				case 9:
					registros = this.state.registros[4].total;
					break;
				case 10:
					registros = this.state.registros[4].total;
					break;
				case 20:
					registros = this.state.registros[8].total;
					break;
				default:
					break;
			}*/
			
		}
		return registros;
	};
	
	goCreditoProcea = () => {
		this.props.history.push('/creditoprocea');
	}
	
	goVacaciones = () => {
		this.props.history.push('/vacacionesinfo');
	}
	
	render() {
		let menu = this.state.Usr.menu || [];
		const bg_logo_svg = {
			backgroundImage: `url(${logo_svg})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'bottom',
			backgroundAttachment: 'fixed',
			backgroundSize: '350px 350px',
			opacity: '1',
		};
		
		return (
			<div className={'w-100'} align={'center'}>
				<div>
					<div className='Home ContaineViewComponet' style={bg_logo_svg}>
						<Grid container spacing={3}>
							<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
							</Grid>
							<Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
								<ModalCreditoProcea goCreditoProcea={this.goCreditoProcea} goVacaciones={this.goVacaciones}/>
								{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) || Number(this.Usr.id_usuario) === 79 ? (
									<Fragment>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={3}>
													{menu.map((item, key) => (
														<Fragment key={key}>
															{FieldsJs.inArray([5, 6, 7, 8, 9, 11, 23], item.id_menu) ? (
																<Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
																	<Fragment>
																		<Card className={'cursor-pointer ' + ('btn-dash-home-' + item.id_menu)} onClick={() => this.go(item.sub_menu.ruta)}>
																			<div className={'padding-10 home-gradient' + item.id_menu}>
																				<div className={'w-100 m-h-50-px'}>
																					<div className={'vertical-inline w-100'}>
																						<div className={'v-center'}>{this.renderSwitchIcon(item.sub_menu.icono)}</div>
																						<p className={'v-center text-left margin-0'} style={{color: 'white'}}>{item.sub_menu.sub_menu}</p>
																					</div>
																					<div className={'vertical-inline w-100'}>
																						<div className={'v-center w-50'}>
																							<p className={'text-left margin-0'} style={{color: 'white'}}>Registros</p>
																						</div>
																						<div className={'v-center w-50'}>
																							<p className={'text-right margin-10-T margin-10-B px-24'} style={{color: '#8E1E32', opacity: 0.5, fontWeight: 'bold'}}>{this.getRegistros(item.id_menu)}</p>
																						</div>
																					</div>
																					{/*<ItemGraficaArea state={this.state}/>*/}
																				</div>
																			</div>
																		</Card>
																	</Fragment>
																</Grid>
															) : null}
														</Fragment>
													))}
												</Grid>
											</Grid>
											
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												<Grid container spacing={3}>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Paper className={'card padding-10'} style={{maxHeight: '300px', overflowX: "auto"}}>
															<p className={'text-center margin-0-T margin-10-B px-24'} style={{color: '#171717', opacity: 0.5, fontWeight: 'bold'}}>Tickets</p>
															<Table className={'desing-acresco-home'}>
																<TableHead>
																	<TableRow>
																		<TableCell component="th" align={'center'} className={'w-210-px'}>Usuario</TableCell>
																		{/*<TableCell component="th" align={'center'}>Total Tickets</TableCell>
																	<TableCell component="th" align={'center'}>Tickets Resueltos</TableCell>
																	<TableCell component="th" align={'center'}>Tickets Pendientes</TableCell>*/}
																		<TableCell component="th" align={'center'}>Cumplimiento</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{this.state.lista_tickets.length > 0 ? this.state.lista_tickets.map((item, key) => (
																		<TableRow key={key}>
																			<TableCell align={'left'}>
																				<div className={'vertical-inline margin-3-T margin-3-B w-100'}>
																					<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
																					     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
																					</div>
																					<div className={'v-center w-100-30-px padding-10-L margin-0 text-black'}>
																						{item.nombre_completo_asignado}
																					</div>
																				</div>
																			</TableCell>
																			{/*<TableCell align={'center'}>{item.total_tickets}</TableCell>
																		<TableCell align={'center'}>{item.tickets_resueltos}</TableCell>
																		<TableCell align={'center'}>{item.tickets_pendientes}</TableCell>*/}
																			<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
																				<div className={'w-100'} align={'center'}>
																					<BarraProgreso color={1} completed={Number(item.cumplimiento) || 0} buffer={100} value={Number(item.cumplimiento) || 0} size={'150px'}/>
																				</div>
																			</TableCell>
																		</TableRow>
																	)) : null}
																</TableBody>
															</Table>
														</Paper>
													</Grid>
													
													{FieldsJs.inArray([1, 2, 4, 5, 7, 13], this.Usr.id_cat_tipo_usuario) ? (
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<Paper className={'card padding-10'} style={{maxHeight: '300px', overflowX: "auto"}}>
																<p className={'text-center margin-0-T margin-10-B px-24'} style={{color: '#171717', opacity: 0.5, fontWeight: 'bold'}}>Membresías</p>
																<Table className={'desing-acresco-home'}>
																	<TableHead>
																		<TableRow>
																			<TableCell component="th" align={'center'} className={'w-210-px'}>Plataforma</TableCell>
																			<TableCell component="th" align={'center'}>URL</TableCell>
																			<TableCell component="th" align={'center'}>Usuario</TableCell>
																			<TableCell component="th" align={'center'}>Contraseña</TableCell>
																		</TableRow>
																	</TableHead>
																	<TableBody>
																		{this.state.plataformas.length > 0 ? this.state.plataformas.map((item, key) => (
																			<TableRow key={key}>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.plataforma}</div>
																				</TableCell>
																				<TableCell align={'center'}>
																					<div className={'px-13 cursor-pointer cursorcolor'} onClick={() => this.redireccion(item.url)}>{item.url}</div>
																				</TableCell>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.usuario}</div>
																				</TableCell>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.password}</div>
																				</TableCell>
																			</TableRow>
																		)) : null}
																	</TableBody>
																</Table>
															</Paper>
														</Grid>
													) : null}
												
												</Grid>
											</Grid>
											
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												<Grid container spacing={3}>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Chart
															width={'100%'}
															height={'320px'}
															chartType="ColumnChart"
															loader={<div>Cargando grafica ...</div>}
															data={
																this.state.cfdi
															}
															options={{
																title: 'CFDI´s por periodo.',
																chart: {
																	title: 'CFDI´s por periodo.',
																},
																legend: "none"
															}}
															rootProps={{'data-testid': '2'}}
														/>
													</Grid>
													
													{this.state.vacaciones.length > 0 ? (
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<Paper className={'card padding-10'} style={{maxHeight: '300px', overflowX: "auto"}}>
																<p className={'text-center margin-0-T margin-10-B px-24'} style={{color: '#171717', opacity: 0.5, fontWeight: 'bold'}}>Colaboradores de vacaciones</p>
																<Table className={'desing-acresco-home'}>
																	<TableHead>
																		<TableRow>
																			<TableCell component="th" align={'center'}>N.P.</TableCell>
																			<TableCell component="th" align={'center'}>Nombre</TableCell>
																			<TableCell component="th" align={'center'}>Departamento</TableCell>
																			<TableCell component="th" align={'center'}>Periodo vacacional</TableCell>
																			<TableCell component="th" align={'center'}>Teléfono(Solo urgencias)</TableCell>
																			<TableCell component="th" align={'center'}>Estatus</TableCell>
																		</TableRow>
																	</TableHead>
																	<TableBody>
																		{this.state.vacaciones.length > 0 ? this.state.vacaciones.map((item, key) => (
																			<TableRow key={key}>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.id_vacaciones}</div>
																				</TableCell>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.nombre_completo}</div>
																				</TableCell>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.area}</div>
																				</TableCell>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.fecha_inicio_labores}</div>
																				</TableCell>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.celular ? item.celular : '-'}</div>
																				</TableCell>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.estatus ? item.estatus : '-'}</div>
																				</TableCell>
																			</TableRow>
																		)) : null}
																	</TableBody>
																</Table>
															</Paper>
														</Grid>
													) : null}
												</Grid>
											</Grid>
											
											{/*<Grid item xs={4}>
											<Paper className={'card padding-10'} style={{height: 'calc(100vh - 290px)', overflowX: "auto"}}>
												<Table className={'desing-acresco-home'}>
													<TableHead>
														<TableRow>
															<TableCell component="th" align={'left'} className={'w-210-px'}>Cliente</TableCell>
															<TableCell component="th" align={'center'}>Total CFDI´s</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{this.state.cfdi.length > 0 ? this.state.cfdi.map((item, key) => (
															<TableRow key={key}>
																<TableCell align={'left'}>{item.cliente}</TableCell>
																<TableCell align={'center'}>{item.total}</TableCell>
															</TableRow>
														)) : null}
													</TableBody>
												</Table>
											</Paper>
										</Grid>
										
										<Grid item xs={4}>
											<Paper className={'card padding-10'} style={{height: 'calc(100vh - 290px)', overflowX: "auto"}}>
												<Table className={'desing-acresco-home'}>
													<TableHead>
														<TableRow>
															<TableCell component="th" align={'left'} className={'w-210-px'}>Usuario</TableCell>
															<TableCell component="th" align={'center'}>Correo Electrónico</TableCell>
															<TableCell component="th" align={'center'}>Tipo Usuario</TableCell>
															<TableCell component="th" align={'center'}>Última Sesión</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{this.state.acceso.length > 0 ? this.state.acceso.map((item, key) => (
															<TableRow key={key}>
																<TableCell align={'left'}>
																	<div className={'vertical-inline margin-3-T margin-3-B w-100'}>
																		<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
																		     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
																		</div>
																		<div className={'v-center w-100-30-px padding-10-L margin-0 text-black'}>
																			{item.nombre_completo}
																		</div>
																	</div>
																</TableCell>
																<TableCell align={'center'}>{item.correo_electronico}</TableCell>
																<TableCell align={'center'}>{item.tipo_usuario}</TableCell>
																<TableCell align={'center'}>{item.ultima_sesion}</TableCell>
															</TableRow>
														)) : null}
													</TableBody>
												</Table>
											</Paper>
										</Grid>
										
										<Grid item xs={4}>
											<Paper className={'card padding-10'} style={{height: 'calc(100vh - 290px)', overflowX: "auto"}}>
												<Table className={'desing-acresco-home'}>
													<TableHead>
														<TableRow>
															<TableCell component="th" align={'left'} className={'w-210-px'}>Usuario</TableCell>
															<TableCell component="th" align={'center'}>Total Tickets</TableCell>
															<TableCell component="th" align={'center'}>Tickets Resueltos</TableCell>
															<TableCell component="th" align={'center'}>Tickets Pendientes</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{this.state.lista_tickets.length > 0 ? this.state.lista_tickets.map((item, key) => (
															<TableRow key={key}>
																<TableCell align={'left'}>
																	<div className={'vertical-inline margin-3-T margin-3-B w-100'}>
																		<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
																		     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
																		</div>
																		<div className={'v-center w-100-30-px padding-10-L margin-0 text-black'}>
																			{item.nombre_completo_asignado}
																		</div>
																	</div>
																</TableCell>
																<TableCell align={'center'}>{item.total_tickets}</TableCell>
																<TableCell align={'center'}>{item.tickets_resueltos}</TableCell>
																<TableCell align={'center'}>{item.tickets_pendientes}</TableCell>
															</TableRow>
														)) : null}
													</TableBody>
												</Table>
											</Paper>
										</Grid>*/}
										
										
										</Grid>
									</Fragment>
								) : (
									<Fragment>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={3}>
													{menu.map((item, key) => (
														<Fragment key={key}>
															{FieldsJs.inArray([11, 15, 21], item.id_menu) ? (
																<Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
																	<Fragment>
																		<Card className={'cursor-pointer ' + ('btn-dash-home-' + item.id_menu)} onClick={() => this.go(item.sub_menu.ruta)}>
																			<div className={'padding-10 home-gradient' + item.id_menu}>
																				<div className={'w-100 m-h-50-px'}>
																					<div className={'vertical-inline w-100'}>
																						<div className={'v-center'}>{this.renderSwitchIcon(item.sub_menu.icono)}</div>
																						<p className={'v-center text-left margin-0'} style={{color: 'white'}}>{item.sub_menu.sub_menu}</p>
																					</div>
																					<div className={'vertical-inline w-100'}>
																						<div className={'v-center w-50'}>
																							<p className={'text-left margin-0'} style={{color: 'white'}}>Registros</p>
																						</div>
																						<div className={'v-center w-50'}>
																							<p className={'text-right margin-10-T margin-10-B px-24'} style={{color: '#8E1E32', opacity: 0.5, fontWeight: 'bold'}}>{this.getRegistros(item.id_menu)}</p>
																						</div>
																					</div>
																				</div>
																			</div>
																		</Card>
																	</Fragment>
																</Grid>
															) : null}
														</Fragment>
													))}
												</Grid>
											</Grid>
											
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												<Grid container spacing={3}>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Paper className={'card padding-10'} style={{height: '300px', overflowX: "auto"}}>
															<p className={'text-center margin-0-T margin-10-B px-24'} style={{color: '#8E1E32', opacity: 0.5, fontWeight: 'bold'}}>Tickets</p>
															
															{this.state.lista_tickets.length > 0 ? (
																<Table className={'desing-acresco-home'}>
																	<TableHead>
																		<TableRow>
																			<TableCell component="th" align={'center'} className={'w-210-px'}>Requirente</TableCell>
																			<TableCell component="th" align={'center'}>Cumplimiento</TableCell>
																		</TableRow>
																	</TableHead>
																	<TableBody>
																		{this.state.lista_tickets.length > 0 ? this.state.lista_tickets.map((item, key) => (
																			<TableRow key={key}>
																				<TableCell align={'left'}>
																					<div className={'vertical-inline margin-3-T margin-3-B w-100'}>
																						<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
																						     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
																						</div>
																						<div className={'v-center w-100-30-px padding-10-L margin-0 text-black'}>
																							{item.nombre_completo_asignado}
																						</div>
																					</div>
																				</TableCell>
																				<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
																					<div className={'w-100'} align={'center'}>
																						<BarraProgreso color={1} completed={Number(item.cumplimiento) || 0} buffer={100} value={Number(item.cumplimiento) || 0} size={'150px'}/>
																					</div>
																				</TableCell>
																			</TableRow>
																		)) : null}
																	</TableBody>
																</Table>
															) : (
																<VistaVacia
																	numero={0}
																	mensaje='No se encontraron tickets'
																	width={'100px'}
																	height={'100px'}
																/>
															)}
														</Paper>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												<Grid container spacing={3}>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Chart
															width={'100%'}
															height={'300px'}
															chartType="ColumnChart"
															loader={<div>Cargando grafica ...</div>}
															data={
																this.state.actividades
															}
															options={{
																title: 'Actividades por periodo',
																chart: {
																	title: 'Actividades por periodo',
																},
																legend: "none"
															}}
															rootProps={{'data-testid': '2'}}
														/>
													</Grid>
													
													{this.state.vacaciones.length > 0 ? (
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<Paper className={'card padding-10'} style={{maxHeight: '300px', overflowX: "auto"}}>
																<p className={'text-center margin-0-T margin-10-B px-24'} style={{color: '#171717', opacity: 0.5, fontWeight: 'bold'}}>Colaboradores de vacaciones</p>
																<Table className={'desing-acresco-home'}>
																	<TableHead>
																		<TableRow>
																			<TableCell component="th" align={'center'}>N.P.</TableCell>
																			<TableCell component="th" align={'center'}>Nombre</TableCell>
																			<TableCell component="th" align={'center'}>Departamento</TableCell>
																			<TableCell component="th" align={'center'}>Periodo vacacional</TableCell>
																			<TableCell component="th" align={'center'}>Teléfono(Solo urgencias)</TableCell>
																			<TableCell component="th" align={'center'}>Estatus</TableCell>
																		</TableRow>
																	</TableHead>
																	<TableBody>
																		{this.state.vacaciones.length > 0 ? this.state.vacaciones.map((item, key) => (
																			<TableRow key={key}>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.id_vacaciones}</div>
																				</TableCell>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.nombre_completo}</div>
																				</TableCell>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.area}</div>
																				</TableCell>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.fecha_inicio_labores}</div>
																				</TableCell>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.celular ? item.celular : '-'}</div>
																				</TableCell>
																				<TableCell align={'center'}>
																					<div className={'px-13'}>{item.estatus ? item.estatus : '-'}</div>
																				</TableCell>
																			</TableRow>
																		)) : null}
																	</TableBody>
																</Table>
															</Paper>
														</Grid>
													) : null}
												</Grid>
											</Grid>
										</Grid>
									</Fragment>
								)}
								
								
								{/*<div className={'w-100 v-center'}>
						
						
						<Button onClick={() => this.showSnackBars('info', 'Bienvenidos a Acresco Integraciones!!')}>
							Show
						</Button>
						
					</div>*/}
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		);
	}
}

Home.propTypes = {};

export default IntegrationNotistack(Home);
