import React, {Component} from 'react';
import PropTypes from "prop-types";

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import Grid from "@material-ui/core/Grid";
import {FieldsJs} from "../../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";


class ModalDetalleEmpleado extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
		};
	}
	
	openModal = () => {
		let item = FieldsJs.Copy(this.props.item);
		this.setState({
			modal: {
				open: true
			},
			
			item: item,
		});
		console.log(this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			},
			
			item: {}
		});
	};
	
	handleChange = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	render() {
		
		const {item} = this.props;
		
		return (
			<div>
				
				<div onClick={() => this.openModal()}>
					{this.props.icono}
				</div>
				
				<Dialog open={this.state.modal.open} onClose={() => this.closeModal()} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						Detalle del empleado
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Nombre: {(item.nombre + ' ' + item.apellido_paterno + ' ' + item.apellido_materno).trim() || 'Pendiente...'}
								</Typography>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									RFC: {item.rfc || 'Pendiente...'}
								</Typography>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									CURP: {item.curp || 'Pendiente...'}
								</Typography>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									NSS: {item.nss || 'Pendiente...'}
								</Typography>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Fecha de nacimiento: {item.fecha_nacimiento || 'Pendiente...'}
								</Typography>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Fecha inicio de labores: {item.fecha_inicio_relacion_laboral || 'Pendiente...'}
								</Typography>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Departamento/Puesto: {(item.departamento + ' ' + item.fecha_inicio_relacion_laboral).trim() || 'Pendiente...'}
								</Typography>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Sueldo bruto: {item.sueldo_bruto || 'Pendiente...'}
								</Typography>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									ISR: {item.isr || 'Pendiente...'}
								</Typography>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Subsidio: {item.subsidio || 'Pendiente...'}
								</Typography>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Suelo Neto: {item.suelo_neto || 'Pendiente...'}
								</Typography>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Estatus: {item.activo === 1 ? "Activo" : "Inactivo"}
								</Typography>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Grid spacing={0} container direction="row" justify="space-between" alignItems="center">
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Button onClick={() => this.closeModal()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'right'}>
								{/*<Button onClick={() => this.actuaizar_foto_de_perfil()} color="secondary">
									Actualizar foto de perfil
								</Button>*/}
							</Grid>
						</Grid>
					
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ModalDetalleEmpleado.propTypes = {
	item: PropTypes.object.isRequired,
};

export default ModalDetalleEmpleado;
