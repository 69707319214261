import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {VacacionesService} from '../../../../services/VacacionesService/VacacionesService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {Group} from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import FormGroup from "@material-ui/core/FormGroup";
import {CONFIG} from "../../../../settings/Config/Config";
import moment from "moment";


class ModalVacaciones extends Component {

	Usr = {};
	state = {};

	constructor(props) {

		super(props);

		this.Usr = ReactLocalStorageService.get('Usr') || {};

		this.state = {
			open: false,
			scroll: 'paper',
			id_vacaciones: (props.item || '').id_vacaciones,
			tipo: props.tipo,
			id_empresa: '',
			nombre_trabajador: this.Usr.nombre_completo,
			fecha_ingreso: this.Usr.fecha_ingreso && this.Usr.fecha_ingreso != "0000-00-00" ? moment(this.Usr.fecha_ingreso) : null,
			anio_servicio: '',
			dias_corresponden: '',
			dias_disfrutar: '',
			fecha_inicio: null,
			fecha_inicio_labores: null,
			fecha_elaboracion: null,
			area: this.Usr.tipo_usuario || '',
			puesto: '',
			periodo: '',
			observaciones: '',
			lista_empresas: [],
			lista_usuarios: [],
			usuarios: [],
			lista_usuario_autorizado: [],
			usuarios_notificar: [],
		};
	}

	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};

	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};

	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};

	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};

	modalClose = () => {
		this.setState({open: false});
	};

	ListEmpresa = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpresa().then((response) => {
				this.setState({
					lista_empresas: response.data
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_empresas: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};

	ListUsuarios = () => {
		return new Promise((resolve, reject) => {
			CatService.ListUsuario().then((response) => {
				let usuarios = [];
				let notificar = [];
				if (!FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario)) {
					for (let i = 0; i < response.data.length; i++) {
						console.log(Number(this.Usr.id_cat_tipo_usuario) === Number(response.data[i].id_cat_tipo_usuario), Number(this.Usr.id_cat_tipo_usuario), response.data[i]);
						if (Number(this.Usr.id_cat_tipo_usuario) === Number(response.data[i].id_cat_tipo_usuario)) {
							usuarios.push(response.data[i]);
						}
						if (Number(this.Usr.id_cat_tipo_usuario) === Number(response.data[i].id_cat_tipo_usuario) || Number(response.data[i].id_cat_tipo_usuario) === 13) {
							notificar.push(response.data[i]);
						}
					}
				} else {
					usuarios = response.data;
					notificar = response.data;
				}
				this.setState({
					lista_usuarios: usuarios,
					lista_usuario_autorizado: notificar
				});
				resolve(response);
			}).catch((error) => {
				this.setState({lista_usuarios: []});
				this.showSnackBars('error', error.mensaje);
			});
		});
	};

	save = () => {

		try {

			/*if (!FieldsJs.Field(this.state.id_cat_proyecto)) {
				throw Object({
					status: false, mensaje: 'Campo proyecto es requerido.'
				})
			}*/

			if (this.state.id_vacaciones > 0) {
				VacacionesService.Modificar(this.state).then((response) => {
					this.props.RefreshList();
					this.modalClose();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			} else {
				VacacionesService.Agregar(this.state).then((response) => {
					this.props.RefreshList();
					this.modalClose();
					this.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};

	add = () => {

		this.setState({
			id_vacaciones: '',
			id_empresa: '',
			nombre_trabajador: this.Usr.nombre_completo,
			fecha_ingreso: this.Usr.fecha_ingreso && this.Usr.fecha_ingreso != "0000-00-00" ? moment(this.Usr.fecha_ingreso) : null,
			anio_servicio: '',
			dias_corresponden: '',
			dias_disfrutar: '',
			fecha_inicio: null,
			fecha_inicio_labores: null,
			fecha_elaboracion: moment().format('DD/MM/YYYY'),
			area: this.Usr.tipo_usuario || '',
			puesto: '',
			periodo: '',
			observaciones: '',
			usuarios: [],
			activo: true,
		});
		
		this.obtenerAniosServicio(this.Usr.fecha_ingreso && this.Usr.fecha_ingreso != "0000-00-00" ? moment(this.Usr.fecha_ingreso) : null);
		this.ListEmpresa();
		this.ListUsuarios();
		this.openModal();

	};

	edit = () => {

		const {item} = this.props;

		this.ListEmpresa();
		this.ListUsuarios();

		this.setState({
			id_vacaciones: item.id_vacaciones || '',
			id_empresa: item.id_empresa || '',
			nombre_trabajador: this.Usr.nombre_completo,
			fecha_ingreso: item.fecha_ingreso || null,
			anio_servicio: item.anio_servicio || '',
			dias_corresponden: item.dias_corresponden || '',
			dias_disfrutar: item.dias_disfrutar || '',
			fecha_inicio: item.fecha_inicio || null,
			fecha_inicio_labores: item.fecha_inicio_labores || null,
			fecha_elaboracion:  moment(item.fecha_elaboracion).format('DD/MM/YYYY') || null,
			area: item.area || '',
			puesto: item.puesto || '',
			periodo: item.periodo || '',
			observaciones: item.observaciones || '',
			usuarios: item.usuarios || [],
			activo: (item.activo === 1),
		});

		this.openModal('paper');

	};

	view = () => {

		const {item} = this.props;

		console.log(this.props);

		this.ListEmpresa();
		this.ListUsuarios();

		this.setState({
			id_vacaciones: item.id_vacaciones || '',
			id_empresa: item.id_empresa || '',
			nombre_trabajador: this.Usr.nombre_completo,
			fecha_ingreso: item.fecha_ingreso || null,
			anio_servicio: item.anio_servicio || '',
			dias_corresponden: item.dias_corresponden || '',
			dias_disfrutar: item.dias_disfrutar || '',
			fecha_inicio: item.fecha_inicio || null,
			fecha_inicio_labores: item.fecha_inicio_labores || null,
			fecha_elaboracion: moment(item.fecha_elaboracion).format('DD/MM/YYYY') || null,
			area: item.area || '',
			puesto: item.puesto || '',
			periodo: item.periodo || '',
			observaciones: item.observaciones || '',
			usuarios: item.usuarios || [],
			activo: (item.activo === 1),
		});

		this.openModal('paper');

	};

	BOTON_ACCION = () => {
		var BTN_ACTION = '';

		if (this.state.id_vacaciones > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};

	agregar_usuario = () => {

		try {

			if (this.state.id_usuario <= 0) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("Selecciona por lo menos un usuario.")
				});
			}

			let arr_temp = this.state.usuarios;
			let id_usuario = this.state.id_usuario;
			let id;
			let nombre_completo;
			let exist_empresa = false;

			for (let i = 0; i < this.state.lista_usuarios.length; i++) {
				if (Number(this.state.lista_usuarios[i].id_usuario) === Number(id_usuario)) {
					id = this.state.lista_usuarios[i].id_usuario;
					nombre_completo = this.state.lista_usuarios[i].nombre_completo;
				}
			}

			for (let i = 0; i < this.state.usuarios.length; i++) {
				if (Number(this.state.usuarios[i].id_usuario) === Number(id_usuario)) {
					exist_empresa = true;
				}
			}

			if (exist_empresa) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("El usuario ya se encuentra seleccionado.")
				});
			}

			arr_temp.push({
				id_usuario: id,
				nombre_completo: nombre_completo,
			});

			this.setState({
				usuarios: arr_temp,
				id_usuario: '',
			});

		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};

	eliminar_usuario = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.usuarios);
		arr_temp.splice(key, 1);
		this.setState({
			usuarios: arr_temp
		});
	};

	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	obtenerAniosServicio = (date) => {

		var fecha1 = moment();
		var fecha2 = moment(date);

		if (fecha1.diff(fecha2, "years") <= 0) {
			this.showSnackBars('error', 'Para solicitar vacaciones, es necesario contar con un año de antigüedad.');
		} else {
			var dias_corresponden = 12;
			switch (fecha1.diff(fecha2, "years")) {
				case 1:
					dias_corresponden = 12;
					break;
				case 2:
					dias_corresponden = 14;
					break;
				case 3:
					dias_corresponden = 16;
					break;
				case 4:
					dias_corresponden = 18;
					break;
				case 5:
					dias_corresponden = 20;
					break;
				case 6:
				case 7:
				case 8:
				case 9:
				case 10:
					dias_corresponden = 22;
					break;
				case 11:
				case 12:
				case 13:
				case 14:
				case 15:
					dias_corresponden = 24;
					break;
				case 16:
				case 17:
				case 18:
				case 19:
				case 20:
					dias_corresponden = 26;
					break;
				case 21:
				case 22:
				case 23:
				case 24:
				case 25:
					dias_corresponden = 28;
					break;
				case 26:
				case 27:
				case 28:
				case 29:
				case 30:
					dias_corresponden = 30;
					break;
				default:
					dias_corresponden = 12;
					break;
			}


			this.setState({
				anio_servicio: fecha1.diff(fecha2, "years"),
				dias_corresponden: dias_corresponden
			});
		}
	};


	asignarUsuarioReporte = (id_usuario, id_usuario_autorizado) => {
		let usuarios = FieldsJs.Copy(this.state.lista_usuario_autorizado);
		let users = FieldsJs.Copy(this.state.usuarios_notificar);
		let banduser = false;
		let userkey = 0;
		for (let i = 0; i < users.length; i++) {
			if (Number(users[i].id_usuario) === Number(id_usuario)) {
				banduser = true;
				userkey = i;
			}
		}
		if (!banduser) {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = true;
					if (id_usuario_autorizado) {
						usuarios[i].id_usuario = id_usuario_autorizado;
					}
					users.push(usuarios[i]);
				}
			}
		} else {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = false;
				}
			}
			users.splice(userkey, 1);
		}
		this.setState({
			lista_usuario_autorizado: usuarios,
			usuarios_notificar: users,
		});
		console.log("ASIGNARUSUARIO", users, usuarios, this.state.usuarios_notificar);
	};

	fechaInicioLabores = (dateFrom) => {
		try {
			if (!FieldsJs.Field(this.state.dias_disfrutar)) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("Días a disfrutar es requerido.")
				});
			}

			var from = moment(dateFrom);

			for (let i = 0; i < this.state.dias_disfrutar; i++) {
				if (from.isoWeekday() === 5) {
					if(Number(this.Usr.id_plaza) === 9) {
						from.add(1, 'days');
					} else {
						from.add(3, 'days');
					}
				} else if (from.isoWeekday() === 6) {
					if(Number(this.Usr.id_plaza) === 9) {
						from.add(2, 'days');
					} else {
						from.add(2, 'days');
					}
				} else if(from.isoWeekday() === 7) {
					from.add(1, 'days');
				} else {
					from.add(1, 'days');
				}
				console.log("FECHA CON LOS DIAS A DISFRUTAR >>>>>>",moment(dateFrom).format('DD MM YYYY'), this.state.dias_disfrutar, moment(from).format('DD MM YYYY'), i+1);

			}

			this.setState({
				fecha_inicio_labores:  moment(from)
			});
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}

	}

	render() {

		const BTN_ACTION = this.BOTON_ACCION();

		return (
			<div>

				{BTN_ACTION}

				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>

					<DialogTitle>
						<Grid container spacing={2} alignItems={'center'}>
							<Grid item className={'text-left'} xs={6} sm={6} md={6} lg={6} xl={6}>
								{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Solicitud de Vacaciones
							</Grid>
							<Grid item className={'text-right px-14'} xs={6} sm={6} md={6} lg={6} xl={6}>
								{'Fecha de Elaboración: ' + this.state.fecha_elaboracion}
							</Grid>
						</Grid>
					</DialogTitle>

					<Divider/>

					<DialogContent className={'padding-20'}>

						<Grid container spacing={2}>

							<Grid item className={'text-left'} xs={12} sm={12} md={8} lg={8} xl={8}>

								<Grid container spacing={2}>

									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<TextField
											className={'margin-0'}
											type={'text'}
											fullWidth
											required
											name="nombre_trabajador"
											onChange={this.handleChange}
											label="Nombre del trabajador"
											autoComplete={'off'}
											value={this.state.nombre_trabajador}
											disabled
										/>
									</Grid>

									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<TextField
											className={'margin-0'}
											type={'text'}
											fullWidth
											required
											name="area"
											onChange={this.handleChange}
											label="Área o departamento"
											autoComplete={'off'}
											value={this.state.area}
											disabled
										/>
									</Grid>

									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<FormControl fullWidth required className={'margin-0'}>
											<InputLabel>Empresa Pagadora</InputLabel>
											<Select
												native
												value={this.state.id_empresa}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="id_empresa"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_empresas.map(option => (
													<option key={option.id_empresa}
													        value={option.id_empresa}>
														{option.razon_social}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>

									<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
										<KeyboardDatePicker
											format={'dd/MM/yyyy'}
											fullWidth
											required
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'Fecha de ingreso',
											}}
											label="Fecha de ingreso"
											value={this.state.fecha_ingreso}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_ingreso');
												this.obtenerAniosServicio(date);
											}}
											// minDate={moment(new Date())}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											onError={console.log}
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>

									<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
										<TextField
											className={'margin-0'}
											type={'text'}
											fullWidth
											required
											name="anio_servicio"
											onChange={this.handleChange}
											label="Años de servicio"
											autoComplete={'off'}
											value={this.state.anio_servicio}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>

									<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
										<TextField
											className={'margin-0'}
											type={'text'}
											fullWidth
											required
											name="dias_corresponden"
											onChange={this.handleChange}
											label="Días que corresponden"
											autoComplete={'off'}
											value={this.state.dias_corresponden}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>

									<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
										<TextField
											className={'margin-0'}
											type={'text'}
											fullWidth
											required
											name="dias_disfrutar"
											onChange={(e) => {
												this.handleChange(e);
											}}
											onBlur={(e) => {
												if (Number(e.target.value) < 6 && !this.Usr.isjefe || e.target.value === '') {
													this.showSnackBars('error', 'Debes solicitar más de 5 días.');
												}
											}}
											label="Días a disfrutar"
											autoComplete={'off'}
											value={this.state.dias_disfrutar}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>

									<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
										<KeyboardDatePicker
											format={'dd/MM/yyyy'}
											fullWidth
											required
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'Fecha inicio',
											}}
											label="Fecha Inicio"
											value={this.state.fecha_inicio}
											onChange={(date) => {
												var from = moment(date);
												var from2 = moment().add(30, 'days');
												if (from > from2) {
													if (FieldsJs.Field(this.state.dias_disfrutar)) {
														this.handleChange(null, null, null, date, 'fecha_inicio');
														this.fechaInicioLabores(date);
													} else {
														this.showSnackBars('error', 'Días a disfrutar es requerido.');
													}
												} else {
													this.showSnackBars('error', 'Fecha inicio debe ser mayor a 30 días.');
												}
												
											}}
											// minDate={moment(new Date())}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											onError={console.log}
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>

									<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
										<KeyboardDatePicker
											format={'dd/MM/yyyy'}
											fullWidth
											required
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'Día Inicio Labores',
											}}
											label="Día Inicio Labores"
											value={this.state.fecha_inicio_labores}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_inicio_labores');
											}}
											// minDate={moment(new Date())}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											onError={console.log}
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>

									{/*<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<KeyboardDatePicker
											format={'dd/MM/yyyy'}
											fullWidth
											required
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'Fecha de elaboración del reporte',
											}}
											label="Fecha Elaboración"
											value={this.state.fecha_elaboracion}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_elaboracion');
											}}
											// minDate={moment(new Date())}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											onError={console.log}
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>*/}



									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<TextField
											className={'margin-0'}
											type={'text'}
											fullWidth
											required
											name="puesto"
											onChange={this.handleChange}
											label="Puesto"
											autoComplete={'off'}
											value={this.state.puesto}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>

									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<TextField
											className={'margin-0'}
											type={'text'}
											fullWidth
											required
											name="periodo"
											onChange={this.handleChange}
											label="Periodo a disfrutar"
											autoComplete={'off'}
											value={this.state.periodo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>

									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											className={'margin-0'}
											type={'text'}
											fullWidth
											rows={2}
											name="observaciones"
											onChange={this.handleChange}
											label="Observaciones"
											autoComplete={'off'}
											value={this.state.observaciones}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>

								</Grid>
							</Grid>

							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container className={'text-left borde-punteado-lightgray-2 padding-10'} alignItems={"flex-center"}>
									<Grid className={'w-30-px'}>
										<Group className={'w-100 text-gray'}/>
									</Grid>
									<Grid className={'w-100-30-px'}>
										<Typography className={'px-14 text-left text-gray margin-2-T'}>
											{'Notificar a:'}
										</Typography>
									</Grid>
									<Grid className={'w-100'}>
										<div style={{maxHeight: 310, overflowY: 'auto'}}>
											{this.state.lista_usuario_autorizado.map((item, index) => (
												<Fragment key={index}>
													<div className={'vertical-inline w-95 cursor-pointer'} onClick={() => {
														if (this.props.tipo !== 'view') {
															this.asignarUsuarioReporte(item.id_usuario);
														}
													}}>
														<div className='v-center padding-10-L'>
															<FormGroup row className={'text-center check-recuser'}>
																<FormControlLabel
																	control={
																		<Checkbox
																			type="checkbox"
																			color="primary"
																			checked={item.check}
																			value={item.check}
																			disabled={this.props.tipo === 'view'}
																		/>
																	}
																	label={''}
																/>
															</FormGroup>
														</div>

														<div className={'v-center vertical-inline'}>
															<div className={'v-center w-30-px h-30-px b-r-100 bg-img-contain-x-center-y-center'}
															     style={{backgroundImage: `url(${item.foto ? CONFIG.src + item.foto : CONFIG.foto_default})`}}>
															</div>
															<Typography variant={'h5'} className={'v-center padding-15-L margin-0 text-black px-13'}>
																{item.nombre_completo}
															</Typography>
														</div>
													</div>
												</Fragment>
											))}
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

					</DialogContent>

					<Divider/>

					<DialogActions>

						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>

						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_vacaciones > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}

					</DialogActions>

				</Dialog>

			</div>
		);
	}
}

ModalVacaciones.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalVacaciones);
