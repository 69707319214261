import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const ComprobantesNominaService = {
	Listar: (filtro, paginacion) => {
		let fecha_alta = (filtro.fecha_alta || null) ? DateFormat.FormatSql(filtro.fecha_alta) : null;
		let fecha_pago = (filtro.fecha_pago || null) ? DateFormat.FormatSql(filtro.fecha_pago) : null;
		let fecha_pago_inicio = (filtro.fecha_pago_inicio || null) ? DateFormat.FormatSql(filtro.fecha_pago_inicio) : null;
		let fecha_pago_fin = (filtro.fecha_pago_fin || null) ? DateFormat.FormatSql(filtro.fecha_pago_fin) : null;
		let fecha_alta_inicio = (filtro.fecha_alta_inicio || null) ? DateFormat.FormatSql(filtro.fecha_alta_inicio) : null;
		let fecha_alta_fin = (filtro.fecha_alta_fin || null) ? DateFormat.FormatSql(filtro.fecha_alta_fin) : null;
		
		let params = {
			filtro: {
				id_comprobante_nomina: filtro.id_comprobante_nomina || null,
				usuario: filtro.usuario || null,
				id_empresa: filtro.id_empresa || null,
				ejercicio: filtro.ejercicio || null,
				periodo: filtro.periodo || null,
				cuenta_bancaria: filtro.cuenta_bancaria || null,
				id_cat_banco: filtro.id_cat_banco || null,
				fecha_alta: fecha_alta || null,
				fecha_pago: fecha_pago || null,
				fecha_pago_inicio: fecha_pago_inicio || null,
				fecha_pago_fin: fecha_pago_fin || null,
				fecha_alta_inicio: fecha_alta_inicio || null,
				fecha_alta_fin: fecha_alta_fin || null,
				id_empleado: filtro.id_empleado || null,
				id_cat_periodicidad_pago: filtro.id_cat_periodicidad_pago || null,
				id_cat_periodicidad: filtro.id_cat_periodicidad || null,
				importe: filtro.importe || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Comprobante_Nomina_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_alta = form.fecha_alta ? DateFormat.FormatSql(form.fecha_alta) : null;
		let fecha_inicio = form.fecha_inicio ? DateFormat.FormatSql(form.fecha_inicio) : null;
		let fecha_fin = form.fecha_fin ? DateFormat.FormatSql(form.fecha_fin) : null;
		
		let params = {
			id_comprobante_nomina: null,
			id_empresa: form.id_empresa,
			id_empleado: form.id_empleado || '',
			id_cat_periodicidad_pago: form.id_cat_periodicidad_pago || '',
			ejercicio: form.ejercicio,
			periodo: form.periodo,
			cuenta_bancaria: form.cuenta_bancaria,
			id_cat_banco: form.id_cat_banco,
			fecha_alta: fecha_alta,
			fecha_inicio: fecha_inicio,
			fecha_fin: fecha_fin,
			observaciones: form.observaciones,
			importe: form.importe,
			recargo: form.recargo,
			archivos: form.archivos,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Comprobante_Nomina_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_alta = form.fecha_alta ? DateFormat.FormatSql(form.fecha_alta) : null;
		let fecha_inicio = form.fecha_inicio ? DateFormat.FormatSql(form.fecha_inicio) : null;
		let fecha_fin = form.fecha_fin ? DateFormat.FormatSql(form.fecha_fin) : null;
		
		let params = {
			id_comprobante_nomina: form.id_comprobante_nomina,
			id_empresa: form.id_empresa,
			id_empleado: form.id_empleado || '',
			id_cat_periodicidad_pago: form.id_cat_periodicidad_pago || '',
			ejercicio: form.ejercicio,
			periodo: form.periodo,
			cuenta_bancaria: form.cuenta_bancaria,
			id_cat_banco: form.id_cat_banco,
			fecha_alta: fecha_alta,
			fecha_inicio: fecha_inicio,
			fecha_fin: fecha_fin,
			observaciones: form.observaciones,
			importe: form.importe,
			recargo: form.recargo,
			archivos: form.archivos,
			activo: form.activo ? 1 : 0,
		};
		
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Comprobante_Nomina_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_comprobante_nomina) => {
		let params = {
			id_comprobante_nomina: id_comprobante_nomina
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Comprobante_Nomina_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ComprobantePago: (form) => {
		let params = {
			id_comprobante_nomina: form.id_comprobante_nomina,
			observaciones: form.observaciones,
			comprobante_pago: form.comprobante_pago || 0,
			archivos: form.archivos
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Comprobante_Nomina_Comprobante_Pago', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SendMail: (form) => {
		let params = {
			id_comprobante_nomina: form.id_comprobante_nomina,
			id_usuario: form.id_usuario,
			mensaje: form.mensaje,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Comprobante_Nomina_SendRocketChat', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarDownload: (filtro) => {
		let fecha_alta = (filtro.fecha_alta || null) ? DateFormat.FormatSql(filtro.fecha_alta) : null;
		let fecha_pago = (filtro.fecha_pago || null) ? DateFormat.FormatSql(filtro.fecha_pago) : null;
		let fecha_pago_inicio = (filtro.fecha_pago_inicio || null) ? DateFormat.FormatSql(filtro.fecha_pago_inicio) : null;
		let fecha_pago_fin = (filtro.fecha_pago_fin || null) ? DateFormat.FormatSql(filtro.fecha_pago_fin) : null;
		let fecha_alta_inicio = (filtro.fecha_alta_inicio || null) ? DateFormat.FormatSql(filtro.fecha_alta_inicio) : null;
		let fecha_alta_fin = (filtro.fecha_alta_fin || null) ? DateFormat.FormatSql(filtro.fecha_alta_fin) : null;
		
		let params = {
			filtro: {
				id_comprobante_nomina: filtro.id_comprobante_nomina || null,
				usuario: filtro.usuario || null,
				id_empresa: filtro.id_empresa || null,
				ejercicio: filtro.ejercicio || null,
				periodo: filtro.periodo || null,
				cuenta_bancaria: filtro.cuenta_bancaria || null,
				id_cat_banco: filtro.id_cat_banco || null,
				fecha_alta: fecha_alta || null,
				fecha_pago: fecha_pago || null,
				fecha_pago_inicio: fecha_pago_inicio || null,
				fecha_pago_fin: fecha_pago_fin || null,
				fecha_alta_inicio: fecha_alta_inicio || null,
				fecha_alta_fin: fecha_alta_fin || null,
				id_empleado: filtro.id_empleado || null,
				id_cat_periodicidad_pago: filtro.id_cat_periodicidad_pago || null,
				importe: filtro.importe || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Comprobante_Nomina_Donwload', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
