import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const EmpresaPlazaOficinaService = {
	Listar: (filtro, id_plaza_direccion) => {
		let params = {
			id_plaza: filtro.id_plaza,
			id_plaza_direccion: id_plaza_direccion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Plaza_Oficina_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalles: (id_compra) => {
		let params = {
			id_compra: id_compra,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Plaza_Oficina_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Guardar: (form) => {
		let ws = '_Empresa_Plaza_Oficina_Guardar';
		let id_empresa = null;
		if (form.id_empresa > 0) {
			ws = '_Empresa_Plaza_Oficina_Modificar';
			id_empresa = form.id_empresa;
		}
		let params = {
			id_empresa_plaza_oficina: form.id_empresa_plaza_oficina || '',
			id_empresa: id_empresa,
			id_plaza_direccion: form.id_plaza_direccion,
			id_plaza: form.id_plaza,
			nombre_comercial: form.nombre_comercial || '',
			razon_social: form.razon_social || '',
			rfc: form.rfc || '',
			correo_electronico: form.correo_electronico || '',
			telefono: form.telefono || '',
			id_cat_giro_cliente: form.id_cat_giro_cliente || null,
			activo: form.activo,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post(ws, params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_compra) => {
		let params = {
			id_compra: id_compra
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Plaza_Oficina_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
