import React, {Component, Fragment} from 'react';
import PropTypes, {number} from 'prop-types';
import Typography from "@material-ui/core/Typography";

import {FieldsJs, hideSpinner, showSpinner} from '../../../settings/General/General';
import {CatService} from '../../../services/_Cat/CatService/CatService';
import {FichaTecnicaService} from '../../../services/FichaTecnicaService/FichaTecnicaService';
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {FileBase64} from "../../../settings/FileBase64/FileBase64";
import ModalImage from "../../Include/ModalImage/ModalImage";
import {PopupService} from "../../../settings/PoPup/PoPup";

import {IDBLocalFichaTecnicaService} from "../../../services/IDBLocalFichaTecnicaService/IDBLocalFichaTecnicaService";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import $State from "../../../settings/$State/$State";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import {CONFIG} from "../../../settings/Config/Config";
import Avatar from "@material-ui/core/Avatar";
import ModalMessageConfirmation from "../../Include/ModalMessageConfirmation/ModalMessageConfirmation";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import SeccionPromotorRequerimientos from "./Includes/SeccionPromotorRequerimientos";
import SeccionPropuestaFiscalRespuestaClientePromotor from "./Includes/SeccionPropuestaFiscalRespuestaClientePromotor";
import SeccionPromotorIntegracionDelExpediente from "./Includes/SeccionPromotorIntegracionDelExpediente";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import Fab from "@material-ui/core/Fab";
import Zoom from "@material-ui/core/Zoom";
import {RefreshOutlined} from "@material-ui/icons";
import {Dropbox} from 'dropbox';
import {DropboxApi} from '../../../settings/Dropbox/DropboxApi';
import Button from "@material-ui/core/Button";


class FichaTecnica extends Component {
	
	state = {
		modal: {}
	};
	
	Usr = {};
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		const params = props.params || {};
		
		this.state = {
			visible: null,
			mensaje: "",
			modal: {
				open: false,
				title: '',
				message: '',
				bg: '',
				button: ''
			},
			cliente: {},
			
			active_modal: false,
			
			id_cliente: params.id_cliente,
			id_ficha_tecnica: '',
			id_cat_ficha_tecnica_status: '',
			
			actividad_ante_sat: '',
			rfc: '',
			servicio_requerido_propuesto: '',
			ficha_tecnica_archivo_elaboracion_propuesta: [],
			ficha_tecnica_archivo_integracion_expediente: [],
			ficha_tecnica_archivo_propuesta_fiscal: [],
			ficha_tecnica_archivo_propuesta_fiscal_delete: [],
			numero_propuesta: '',
			
			id_plaza_pertenece_cliente: '',
			id_plaza_opera_nomina: '',
			
			sueldo_salario: false,
			cooperativa: false,
			honorarios: false,
			sociedad_civil: false,
			asimilados_salario: false,
			outsourcing: false,
			otros: '',
			
			id_cat_periodo_pago: '',
			impuesto_sobre_nomina: '',
			
			otros_impuestos: false,
			otros_impuestos_nombre: '',
			otros_impuestos_porcentaje: '',
			
			ficha_tecnica_otro_impuesto: [],
			ficha_tecnica_estado_municipio_impuesto: [],
			
			id_cat_factor_riesgo_imss: '',
			id_cat_estado_entidad_trabajo: '',
			id_cat_municipio_entidad_trabajo: '',
			
			cooperativa_alta_imss: false,
			cooperativa_porcentaje_comision_paga_trabajador: '',
			
			trabajador_entrega_recibo_honorarios: false,
			pago_realiza_fuera_es_no_deducible: false,
			outsourcing_porcentaje_paga_servicio: '',
			asimilados_hace_retencion_isr: false,
			tienen_otras_prestaciones_aparte_salario: false,
			
			vacaciones_ley_o_especiales_cuantos_dias: '',
			prima_vacacional_ley_o_especiales_cuantos_dias: '',
			gratificacion_anual_ley_o_especiales_cuantos_dias: '',
			
			ficha_tecnica_prestacion: [],
			
			comentario_adicional_elaboracion_propuesta: '',
			
			lista_cat_factor_riesgo_imss: [],
			lista_cat_periodo_pago: [],
			lista_bancos: [],
			lista_plazas: [],
			lista_esquemas: [],
			lista_cat_estado: [],
			lista_cat_municipio: [],
			lista_cat_banco: [],
			lista_cat_promotores: [],
			
			cliente_cancelo: false,
			cliente_acepto: false,
			cliente_requiere_nueva_propuesta: false,
			porque_requiere_nueva_propuesta: '',
			
			fecha_firma_contrato: null,
			
			constancia_de_situacion_fiscal_base64: '',
			constancia_de_situacion_fiscal_formato: '',
			constancia_de_situacion_fiscal: '',
			
			comprobante_de_domicilio_base64: '',
			comprobante_de_domicilio_formato: '',
			comprobante_de_domicilio: '',
			
			acta_constitutiva_de_la_cliente_base64: '',
			acta_constitutiva_de_la_cliente_formato: '',
			acta_constitutiva_de_la_cliente: '',
			
			poder_del_representante_legal_base64: '',
			poder_del_representante_legal_formato: '',
			poder_del_representante_legal: '',
			
			identificacion_del_repesentante_legal_base64: '',
			identificacion_del_repesentante_legal_formato: '',
			identificacion_del_repesentante_legal: '',
			
			fecha_alta_imss: null,
			confirmacion_fecha_primera_dispersion: null,
			id_cat_periodo_pago_tipo_nomina: '',
			comision_propuesta: '',
			front_nombre_banco: '',
			front_id_esquema_banco: '',
			front_id_banco: '',
			front_cantidad_empleado: '',
			ficha_tecnica_cantidad_empleado_banco: [],
			front_nombre_esquema: '',
			front_id_esquema: '',
			front_cantidad_empleado_esquema: '',
			ficha_tecnica_cantidad_empleado_esquema: [],
			expediente_trabajador: [],
			iva: '',
			retencion_isr_en_caso_de_ias: '',
			retencion_isn_en_caso_de_ias: '',
			
			
			ficha_tecnica_expediente_trabajador: [],
			
			lista_ejecutivo_asignado_tesoreria: [],
			id_ejecutivo_asignado_tesoreria: '',
			
			cat_tipo_pago: [],
			cliente_que_paga: [],
			ep_cliente_que_paga: '',
			ep_tipo_pago: '',
			ep_id_ejecutivo_asignado: '',
			// ep_id_cat_tipo_pago: '',
			
			cliente_que_factura: [],
			ep_cliente_que_factura: '',
			ep_porcentaje: '',
			
			/* J U R I D I C O */
			total_contratos_individuales: '',
			total_contratos_individuales_elaborados: '',
			total_contratos_individuales_pendientes: '',
			
			cantidad_elaborado: '',
			
			excel_elaborado_base64: '',
			excel_elaborado_base64Tipo: '',
			excel_elaborado_archivo: '',
			excel_elaborado_formato: '',
			
			excel_pendiente_base64: '',
			excel_pendiente_base64Tipo: '',
			excel_pendiente_archivo: '',
			excel_pendiente_formato: '',
			
			comentario_general: '',
			
			cliente_contratos_individuales: [],
			
			comentario_juridico_contratos_individuales: '',
			
			cliente_agendar_firma_contrato: [],
			
			
			concepto_de_facturacion: '',
			id_cat_banco_deposita_cliente: '',
			id_cat_banco_depositos_personal: '',
			datos_especiales_para_la_operacion: '',
			promotor_id_usuario_nombre_completo: '',
			promotor_no_cuenta_clabe_interbancaria: '',
			promotor_id_cat_banco: '',
			promotor_comision: '',
			promotor_telefono: '',
			promotor_celular: '',
			promotor_correo_electronico: '',
			promotor_comentarios_especiales_manejo_cuenta_cliente: '',
			
			
			notas: false, // MOSTRAR BLOQUE DE NOTAS PARA EL DESARROLLADOR
			mostrar_notas_desarrollador: false, // MOSTRAR PARA EL DESAROOLLADOR
			comentario_observacion_desarrollador: '', // COMENTARIO PARA EL DESAROOLLADOR
			ficha_tecnica_historial_accion_usuario: [], // HISTORIAL PARA EL DESAROOLLADOR
			
			siroc_active: false,
			siroc: false
		};
		
		this.InitDato();
		
		this.VerificaDataLocalFichaTecnica();
		
	}
	
	showSnackBars = (type, message) => {
		if (message) {
			ShowSnackBarsNotistack(this.props, type, message, null);
		}
	};
	
	$BroadcastModalSeleccionarArchivoElaboracionPropuesta = (data) => {
		console.log(data);
		if (data.status) {
			switch (data.accion) {
				case "add_archivo_elaboracion_propuesta":
					let arr_temp_add = this.state.ficha_tecnica_archivo_elaboracion_propuesta;
					if (data.id_cat_archivo_elaboracion_propuesta > 0) {
						for (let i = 0; i < arr_temp_add.length; i++) {
							if (arr_temp_add[i].id_cat_archivo_elaboracion_propuesta === data.id_cat_archivo_elaboracion_propuesta) {
								arr_temp_add[i].id_ficha_tecnica_archivo_elaboracion_propuesta = data.id_ficha_tecnica_archivo_elaboracion_propuesta;
								arr_temp_add[i].id_cat_archivo_elaboracion_propuesta = data.id_cat_archivo_elaboracion_propuesta;
								arr_temp_add[i].titulo = data.titulo;
								arr_temp_add[i].descripcion = data.descripcion;
								arr_temp_add[i].base64Tipo = data.base64Tipo;
								arr_temp_add[i].archivo = data.archivo;
								arr_temp_add[i].formato = data.formato;
								arr_temp_add[i].file = data.file;
							}
						}
					} else {
						arr_temp_add.push({
							id_ficha_tecnica_archivo_elaboracion_propuesta: data.id_ficha_tecnica_archivo_elaboracion_propuesta,
							id_cat_archivo_elaboracion_propuesta: data.id_cat_archivo_elaboracion_propuesta,
							titulo: data.titulo,
							descripcion: data.descripcion,
							base64Tipo: data.base64Tipo,
							archivo: data.archivo,
							formato: data.formato,
							file: data.file
						});
					}
					this.setState({
						ficha_tecnica_archivo_elaboracion_propuesta: arr_temp_add
					});
					break;
				case "update_archivo_elaboracion_propuesta":
					let arr_temp_update = this.state.ficha_tecnica_archivo_elaboracion_propuesta;
					for (let i = 0; i < arr_temp_update.length; i++) {
						if (arr_temp_update[i].id_ficha_tecnica_archivo_elaboracion_propuesta === data.id_ficha_tecnica_archivo_elaboracion_propuesta) {
							arr_temp_update[i].titulo = data.titulo;
							arr_temp_update[i].descripcion = data.descripcion;
							arr_temp_update[i].base64Tipo = data.base64Tipo;
							arr_temp_update[i].archivo = data.archivo;
							arr_temp_update[i].formato = data.formato;
							arr_temp_update[i].file = data.file;
						}
					}
					this.setState({
						ficha_tecnica_archivo_elaboracion_propuesta: arr_temp_update
					});
					break;
				default:
					this.setState({
						ficha_tecnica_archivo_elaboracion_propuesta: []
					});
			}
		}
	};
	
	handleChange = (e, variable, campo, date, input) => {

		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				let key = Number(name.split('__')[1]);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				console.log(campo, variable, arr_temp);
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
		this.UpdateDataLocalFichaTecnica();
		
	};
	
	RefreshFichaTecnica = () => {
		this.InitDato();
		this.VerificaDataLocalFichaTecnica();
	};
	
	VerificaDataLocalFichaTecnica = () => {
		IDBLocalFichaTecnicaService.VerificarClienteFichaTecnica(this.state.id_cliente).then((result) => {
			if (result.data.id_cliente > 0) {
				PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, '¿Existen datos locales de la ficha tecnica de este cliente, deseas recuperarlos para continuar con el proceso de requerimientos del cliente?').then((r) => {
					if (r.button === 'Aceptar') {
						FieldsJs.TimePromise(1000, () => {
							if (!this.state.id_ficha_tecnica) {
								
								let info = JSON.parse(result.data.state);
								console.log(info);
								
								this.setState({
									
									actividad_ante_sat: info.actividad_ante_sat || '',
									rfc: info.rfc || '',
									servicio_requerido_propuesto: info.servicio_requerido_propuesto || '',
									ficha_tecnica_archivo_elaboracion_propuesta: info.ficha_tecnica_archivo_elaboracion_propuesta || [],
									ficha_tecnica_archivo_propuesta_fiscal: info.ficha_tecnica_archivo_propuesta_fiscal || [],
									
									id_plaza_pertenece_cliente: info.id_plaza_pertenece_cliente || '',
									id_plaza_opera_nomina: info.id_plaza_opera_nomina || '',
									
									sueldo_salario: info.sueldo_salario || false,
									cooperativa: info.cooperativa || false,
									honorarios: info.honorarios || false,
									sociedad_civil: info.sociedad_civil || false,
									asimilados_salario: info.asimilados_salario || false,
									outsourcing: info.outsourcing || false,
									otros: info.otros || '',
									
									id_cat_periodo_pago: info.id_cat_periodo_pago || '',
									impuesto_sobre_nomina: info.impuesto_sobre_nomina || '',
									
									otros_impuestos: info.otros_impuestos || false,
									otros_impuestos_nombre: info.otros_impuestos_nombre || '',
									otros_impuestos_porcentaje: info.otros_impuestos_porcentaje || '',
									
									ficha_tecnica_estado_municipio_impuesto: info.ficha_tecnica_estado_municipio_impuesto || [],
									ficha_tecnica_otro_impuesto: info.ficha_tecnica_otro_impuesto || [],
									
									id_cat_factor_riesgo_imss: info.id_cat_factor_riesgo_imss || '',
									id_cat_estado_entidad_trabajo: info.id_cat_estado_entidad_trabajo || '',
									id_cat_municipio_entidad_trabajo: info.id_cat_municipio_entidad_trabajo || '',
									
									cooperativa_alta_imss: info.cooperativa_alta_imss || false,
									cooperativa_porcentaje_comision_paga_trabajador: info.cooperativa_porcentaje_comision_paga_trabajador || '',
									
									trabajador_entrega_recibo_honorarios: info.trabajador_entrega_recibo_honorarios || false,
									pago_realiza_fuera_es_no_deducible: info.pago_realiza_fuera_es_no_deducible || false,
									outsourcing_porcentaje_paga_servicio: info.outsourcing_porcentaje_paga_servicio || '',
									asimilados_hace_retencion_isr: info.asimilados_hace_retencion_isr || false,
									tienen_otras_prestaciones_aparte_salario: info.tienen_otras_prestaciones_aparte_salario || false,
									
									vacaciones_ley_o_especiales_cuantos_dias: info.vacaciones_ley_o_especiales_cuantos_dias || '',
									prima_vacacional_ley_o_especiales_cuantos_dias: info.prima_vacacional_ley_o_especiales_cuantos_dias || '',
									gratificacion_anual_ley_o_especiales_cuantos_dias: info.gratificacion_anual_ley_o_especiales_cuantos_dias || '',
									
									ficha_tecnica_prestacion: info.ficha_tecnica_prestacion || [],
									
									comentario_adicional_elaboracion_propuesta: info.comentario_adicional_elaboracion_propuesta || '',
									
									ficha_tecnica_expediente_trabajador: info.ficha_tecnica_expediente_trabajador.length > 0 ? info.ficha_tecnica_expediente_trabajador : (this.state.ficha_tecnica_expediente_trabajador || []),
									
									lista_cat_estado: info.lista_cat_estado.length > 0 ? info.lista_cat_estado : (this.state.lista_cat_estado || []),
									lista_cat_municipio: info.lista_cat_municipio.length > 0 ? info.lista_cat_municipio : (this.state.lista_cat_municipio || []),
									
									
									comentario_observacion_desarrollador: '', // COMENTARIO PARA EL DESAROOLLADOR
									ficha_tecnica_historial_accion_usuario: [], // HISTORIAL PARA EL DESAROOLLADOR
									
									
								});
								
							}
						});
					}
				});
			}
		});
	};
	
	UpdateDataLocalFichaTecnica = () => {
		FieldsJs.TimePromise(2000, () => {
			if (!this.state.id_ficha_tecnica) {
				IDBLocalFichaTecnicaService.Iniciar(this.state.id_cliente, this.state.id_ficha_tecnica, this.state);
			}
		});
	};
	
	DeleteDatoLocalFichaTecnica = () => {
		IDBLocalFichaTecnicaService.DeleteClienteFichaTecnica(this.state.id_cliente);
	};
	
	acordionPropuestasFiscal = (ftapf) => {
		let propuestas = [];
		let cont = 0;
		
		
		if (ftapf.length > 0) {
			for (let i = 0; i < ftapf.length; i++) {
				if (cont < ftapf[i].numero_propuesta) {
					cont = ftapf[i].numero_propuesta;
				}
			}
			for (let x = 0; x <= cont; x++) {
				let prop = [];
				for (let y = 0; y < ftapf.length; y++) {
					if (x === ftapf[y].numero_propuesta) {
						prop.push(ftapf[y]);
					}
				}
				/*let bandnueva = false;
				for (let y = 0; y < ftapf.length; y++) {
					if (ftapf[y].id_ficha_tecnica_archivo_propuesta_fiscal === null) {
						bandnueva = true;
					}
				}
				if (bandnueva) {
					propuestas.push({"propuesta": prop, "id": null});
				} else {
					propuestas.push({"propuesta": prop, "id": x});
				}*/
				propuestas.push({"propuesta": prop, "id": x});
				
			}
		}
		console.log("PROPUESTAS::: ", propuestas);
		return propuestas;
	};
	
	
	InitDato = () => {
		FichaTecnicaService.InitDato(this.state.id_cliente).then((response) => {
			
			let dato = FieldsJs.Copy(response.data);
			
			if (response.visible === 0) {
				
				this.setState({
					visible: 0,
					mensaje: response.message
				});
				
			} else {
				
				let cliente = dato.cliente || {};
				let ficha_tecnica_prestacion = dato.ficha_tecnica_prestacion;
				let ft = dato.ficha_tecnica || {};
				let ficha_tecnica_archivo_elaboracion_propuesta = dato.ficha_tecnica_archivo_elaboracion_propuesta;
				let ficha_tecnica_archivo_integracion_expediente = dato.ficha_tecnica_archivo_integracion_expediente;
				let ficha_tecnica_archivo_propuesta_fiscal = dato.ficha_tecnica_archivo_propuesta_fiscal;
				let ftapf = this.acordionPropuestasFiscal(dato.ficha_tecnica_archivo_propuesta_fiscal);
				let ficha_tecnica_estado_municipio_impuesto = dato.ficha_tecnica_estado_municipio_impuesto;
				let ficha_tecnica_otro_impuesto = dato.ficha_tecnica_otro_impuesto;
				let ficha_tecnica_historial_accion_usuario = dato.ficha_tecnica_historial_accion_usuario || [];
				let lista_ejecutivo_asignado_tesoreria = dato.ejecutivo_asignado_tesoreria || [];
				let ficha_tecnica_cantidad_empleado_banco = dato.ficha_tecnica_cantidad_empleado_banco || [];
				let ficha_tecnica_cantidad_empleado_esquema = dato.ficha_tecnica_cantidad_empleado_esquema || [];
				let ficha_tecnica_expediente_trabajador = dato.ficha_tecnica_expediente_trabajador || [];
				let cliente_contratos_individuales = dato.cliente_contratos_individuales || [];
				let cliente_agendar_firma_contrato = dato.cliente_agendar_firma_contrato || [];
				let cat_tipo_pago = dato.cat_tipo_pago || [];
				
				let id_plaza_pertenece_cliente = dato.plaza_pertenece_cliente || '';
				/*if (FieldsJs.Array(dato.plaza_pertenece_cliente)) {
					id_plaza_pertenece_cliente = dato.plaza_pertenece_cliente.id_plaza;
				}*/
				
				let id_plaza_opera_nomina = dato.plaza_opera_nomina || '';
				/*if (FieldsJs.Array(dato.plaza_opera_nomina)) {
					id_plaza_opera_nomina = dato.plaza_opera_nomina.id_plaza;
				}*/
				
				for (let i = 0; i < ficha_tecnica_prestacion.length; i++) {
					var it = ficha_tecnica_prestacion[i];
					it.porcentaje = it.porcentaje || '';
					it.pago_efectivo = (it.pago_efectivo === 1);
					it.vales = (it.vales === 1);
					it.prestaciones = (it.prestaciones === 1);
					it.frecuencia = (it.frecuencia === 1);
					it.fijo_variable = it.fijo_variable || '';
					it.integra_sbc = (it.integra_sbc === 1);
				}
				
				this.setState({
					
					visible: 1,
					
					cliente: dato.cliente,
					
					id_ficha_tecnica: ft.id_ficha_tecnica ? Number(ft.id_ficha_tecnica) : '',
					id_cat_ficha_tecnica_status: ft.id_cat_ficha_tecnica_status ? Number(ft.id_cat_ficha_tecnica_status) : '',
					
					actividad_ante_sat: ft.actividad_ante_sat || '',
					rfc: ft.rfc || dato.cliente.rfc || '',
					servicio_requerido_propuesto: ft.servicio_requerido_propuesto || '',
					ficha_tecnica_archivo_elaboracion_propuesta: ficha_tecnica_archivo_elaboracion_propuesta || [],
					ficha_tecnica_archivo_integracion_expediente: ficha_tecnica_archivo_integracion_expediente || [],
					ficha_tecnica_archivo_propuesta_fiscal: ficha_tecnica_archivo_propuesta_fiscal || [],
					propuestas: ftapf || [],
					
					id_plaza_pertenece_cliente: id_plaza_pertenece_cliente || '',
					id_plaza_opera_nomina: id_plaza_opera_nomina || '',
					
					sueldo_salario: (ft.sueldo_salario === 1),
					cooperativa: (ft.cooperativa === 1),
					honorarios: (ft.honorarios === 1),
					sociedad_civil: (ft.sociedad_civil === 1),
					asimilados_salario: (ft.asimilados_salario === 1),
					outsourcing: (ft.outsourcing === 1),
					otros: ft.otros || '',
					
					id_cat_periodo_pago: ft.id_cat_periodo_pago || '',
					impuesto_sobre_nomina: ft.impuesto_sobre_nomina || '',
					
					otros_impuestos: (ficha_tecnica_otro_impuesto.length > 0),
					otros_impuestos_nombre: '',
					otros_impuestos_porcentaje: '',
					
					ficha_tecnica_estado_municipio_impuesto: ficha_tecnica_estado_municipio_impuesto || [],
					
					ficha_tecnica_otro_impuesto: ficha_tecnica_otro_impuesto || [],
					
					id_cat_factor_riesgo_imss: ft.id_cat_factor_riesgo_imss || '',
					id_cat_estado_entidad_trabajo: ft.id_cat_estado_entidad_trabajo || '',
					id_cat_municipio_entidad_trabajo: ft.id_cat_municipio_entidad_trabajo || '',
					
					cooperativa_alta_imss: (ft.cooperativa_alta_imss === 1),
					cooperativa_porcentaje_comision_paga_trabajador: ft.cooperativa_porcentaje_comision_paga_trabajador || '',
					
					trabajador_entrega_recibo_honorarios: (ft.trabajador_entrega_recibo_honorarios === 1),
					pago_realiza_fuera_es_no_deducible: (ft.pago_realiza_fuera_es_no_deducible === 1),
					outsourcing_porcentaje_paga_servicio: ft.outsourcing_porcentaje_paga_servicio || '',
					asimilados_hace_retencion_isr: (ft.asimilados_hace_retencion_isr === 1),
					tienen_otras_prestaciones_aparte_salario: (ft.tienen_otras_prestaciones_aparte_salario === 1),
					
					vacaciones_ley_o_especiales_cuantos_dias: ft.vacaciones_ley_o_especiales_cuantos_dias || '',
					prima_vacacional_ley_o_especiales_cuantos_dias: ft.prima_vacacional_ley_o_especiales_cuantos_dias || '',
					gratificacion_anual_ley_o_especiales_cuantos_dias: ft.gratificacion_anual_ley_o_especiales_cuantos_dias || '',
					
					ficha_tecnica_prestacion: ficha_tecnica_prestacion || [],
					
					comentario_adicional_elaboracion_propuesta: ft.comentario_adicional_elaboracion_propuesta || '',
					
					lista_cat_factor_riesgo_imss: dato.cat_factor_riesgo_imss,
					lista_cat_periodo_pago: dato.cat_periodo_pago,
					lista_bancos: dato.bancos,
					lista_plazas: dato.plazas,
					lista_esquemas: dato.esquemas,
					lista_cat_estado: dato.cat_estado,
					lista_cat_municipio: dato.cat_municipio,
					lista_cat_banco: dato.cat_banco,
					lista_cat_promotores: dato.cat_promotores,
					
					
					fecha_firma_contrato: ft.fecha_firma_contrato ? moment(ft.fecha_firma_contrato) : null,
					
					constancia_de_situacion_fiscal_base64: '',
					constancia_de_situacion_fiscal_formato: (ft.constancia_de_situacion_fiscal || '').split('.')[1],
					constancia_de_situacion_fiscal: ft.constancia_de_situacion_fiscal,
					
					comprobante_de_domicilio_base64: '',
					comprobante_de_domicilio_formato: (ft.comprobante_de_domicilio || '').split('.')[1],
					comprobante_de_domicilio: ft.comprobante_de_domicilio,
					
					acta_constitutiva_de_la_cliente_base64: '',
					acta_constitutiva_de_la_cliente_formato: (ft.acta_constitutiva_de_la_cliente || '').split('.')[1],
					acta_constitutiva_de_la_cliente: ft.acta_constitutiva_de_la_cliente,
					
					poder_del_representante_legal_base64: '',
					poder_del_representante_legal_formato: (ft.poder_del_representante_legal || '').split('.')[1],
					poder_del_representante_legal: ft.poder_del_representante_legal,
					
					identificacion_del_repesentante_legal_base64: '',
					identificacion_del_repesentante_legal_formato: (ft.identificacion_del_repesentante_legal || '').split('.')[1],
					identificacion_del_repesentante_legal: ft.identificacion_del_repesentante_legal,
					
					fecha_alta_imss: ft.fecha_alta_imss ? moment(ft.fecha_alta_imss) : null,
					confirmacion_fecha_primera_dispersion: ft.confirmacion_fecha_primera_dispersion ? moment(ft.confirmacion_fecha_primera_dispersion) : null,
					id_cat_periodo_pago_tipo_nomina: ft.id_cat_periodo_pago_tipo_nomina || '',
					comision_propuesta: ft.comision_propuesta || '',
					ficha_tecnica_cantidad_empleado_banco: ficha_tecnica_cantidad_empleado_banco || [],
					ficha_tecnica_cantidad_empleado_esquema: ficha_tecnica_cantidad_empleado_esquema || [],
					iva: ft.iva || '',
					retencion_isr_en_caso_de_ias: ft.retencion_isr_en_caso_de_ias || '',
					retencion_isn_en_caso_de_ias: ft.retencion_isn_en_caso_de_ias || '',
					
					ficha_tecnica_expediente_trabajador: ficha_tecnica_expediente_trabajador || [],
					
					lista_ejecutivo_asignado_tesoreria: lista_ejecutivo_asignado_tesoreria,
					id_ejecutivo_asignado_tesoreria: cliente.id_ejecutivo_asignado_tesoreria || '',
					
					cat_tipo_pago: cat_tipo_pago,
					cliente_que_paga: dato.cliente_que_paga || [],
					ep_cliente_que_paga: '',
					ep_tipo_pago: '',
					ep_id_ejecutivo_asignado: '',
					// ep_id_cat_tipo_pago: '',
					
					cliente_que_factura: dato.cliente_que_factura || [],
					ef_cliente_que_factura: '',
					ef_clave_producto_o_servicio: '',
					ef_concepto: '',
					ef_porcentaje: '',
					// ef_id_ejecutivo_asignado: '',
					
					/* J U R I D I C O */
					total_contratos_individuales: cliente.total_contratos_individuales || '',
					total_contratos_individuales_elaborados: '',
					total_contratos_individuales_pendientes: '',
					cantidad_elaborado: '',
					
					excel_elaborado_base64: '',
					excel_elaborado_base64Tipo: '',
					excel_elaborado_archivo: '',
					excel_elaborado_formato: '',
					
					excel_pendiente_base64: '',
					excel_pendiente_base64Tipo: '',
					excel_pendiente_archivo: '',
					excel_pendiente_formato: '',
					
					comentario_general: '',
					
					cliente_contratos_individuales: cliente_contratos_individuales || [],
					
					comentario_juridico_contratos_individuales: cliente.comentario_juridico_contratos_individuales || '',
					
					cliente_agendar_firma_contrato: cliente_agendar_firma_contrato || [],
					
					
					siroc_active: ((ft.sueldo_salario === 1) || (ft.outsourcing === 1)),
					siroc: (Number(ft.siroc) === 1),
					
					comentario_observacion_desarrollador: '', // COMENTARIO PARA EL DESAROOLLADOR
					ficha_tecnica_historial_accion_usuario: ficha_tecnica_historial_accion_usuario, // HISTORIAL PARA EL DESAROOLLADOR
					
				});
				
				
				this.sirocActive();
				
			}
			
		}).catch((error) => {
			console.error(error);
		})
	};
	
	ListMunicipio = (e) => {
		const {value} = e.target;
		CatService.ListMunicipio(value).then((response) => {
			this.setState({
				lista_cat_municipio: response.data
			});
		}).catch((error) => {
		
		})
	};
	
	agregar_otro_impuesto = () => {
		
		try {
		
		let arr_temp = this.state.ficha_tecnica_otro_impuesto;
		let otros_impuestos_nombre = this.state.otros_impuestos_nombre;
		let otros_impuestos_porcentaje = this.state.otros_impuestos_porcentaje;
			
			if (FieldsJs.Field(otros_impuestos_nombre) === false) {
				throw Object({
					status: false,
					mensaje: "Impuesto requerido."
				});
			}
			
			if (Number(otros_impuestos_porcentaje) <= 0) {
				throw Object({
					status: false,
					mensaje: "Impuesto Porcentaje requerido."
				});
			}
			
		arr_temp.push({
			otro_impuesto: otros_impuestos_nombre, porcentaje: otros_impuestos_porcentaje
		});
		
		this.setState({
			ficha_tecnica_otro_impuesto: arr_temp
		});
		
		this.setState({
			otros_impuestos_nombre: "", otros_impuestos_porcentaje: ""
		});
		
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	agregar_estado_municipio_impuesto = () => {
		
		try {
			
			if (this.state.id_cat_estado_entidad_trabajo <= 0) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("Selecciona el Estado de Entidad")
				});
			}
			
			if (this.state.id_cat_municipio_entidad_trabajo <= 0) {
				throw Object({
					status: false,
					mensaje: FieldsJs.FirstMayus("Selecciona el Municipio de Entidad")
				});
			}
			
			let arr_temp = this.state.ficha_tecnica_estado_municipio_impuesto;
			let id_cat_estado_entidad_trabajo = this.state.id_cat_estado_entidad_trabajo;
			let id_cat_municipio_entidad_trabajo = this.state.id_cat_municipio_entidad_trabajo;
			let impuesto_sobre_nomina = this.state.impuesto_sobre_nomina;
			let estado;
			let municipio;
			
			for (let i = 0; i < this.state.lista_cat_estado.length; i++) {
				if (Number(this.state.lista_cat_estado[i].id_cat_estado) === Number(id_cat_estado_entidad_trabajo)) {
					estado = this.state.lista_cat_estado[i].estado;
				}
			}
			
			for (let i = 0; i < this.state.lista_cat_municipio.length; i++) {
				if (Number(this.state.lista_cat_municipio[i].id_cat_municipio) === Number(id_cat_municipio_entidad_trabajo)) {
					municipio = this.state.lista_cat_municipio[i].municipio;
				}
			}
			
			
			arr_temp.push({
				id_cat_estado_entidad_trabajo: id_cat_estado_entidad_trabajo,
				estado: estado,
				id_cat_municipio_entidad_trabajo: id_cat_municipio_entidad_trabajo,
				municipio: municipio,
				impuesto_sobre_nomina: impuesto_sobre_nomina
			});
			
			this.setState({
				ficha_tecnica_estado_municipio_impuesto: arr_temp,
				id_cat_estado_entidad_trabajo: '',
				id_cat_municipio_entidad_trabajo: '',
				impuesto_sobre_nomina: ''
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
		
	};
	
	
	eliminar_estado_municipio_impuesto = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.ficha_tecnica_estado_municipio_impuesto);
		arr_temp.splice(key, 1);
		this.setState({
			ficha_tecnica_estado_municipio_impuesto: arr_temp
		});
	};
	
	eliminar_otro_impuesto = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.ficha_tecnica_otro_impuesto);
		arr_temp.splice(key, 1);
		this.setState({
			ficha_tecnica_otro_impuesto: arr_temp
		});
	};
	
	fileSelect = (id) => {
		document.getElementById(id).value = '';
		document.getElementById(id).click();
	};
	
	getBase64 = (e, id, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente) => {
		let formatos = [".csv", "text/csv", "image/jpeg", "image/png", "application/pdf", "application/xlsx", "application/docx", "text/txt", "image/svg"];
		let files = e.target.files[0];
		
		console.log("FILES:::: ", id, files);
		
		FileBase64.Base64(e.target, formatos, '', true).then((response) => {
			switch (id) {
				case 'archivo_fiscal':
					let arr_temp_add = FieldsJs.Copy(this.state.ficha_tecnica_archivo_propuesta_fiscal);
					let propuesta_num = 0;
					
					for (let i = 0; i < arr_temp_add.length; i++) {
						console.log(Number(arr_temp_add[i].numero_propuesta) > 0 && Number(arr_temp_add[i].id_ficha_tecnica_archivo_propuesta_fiscal) > 0, Number(arr_temp_add[i].numero_propuesta) , propuesta_num);
						if (Number(arr_temp_add[i].numero_propuesta) > 0 && Number(arr_temp_add[i].id_ficha_tecnica_archivo_propuesta_fiscal) > 0) {
							propuesta_num = Number(arr_temp_add[i].numero_propuesta)+1;
						} else if (Number(arr_temp_add[i].numero_propuesta) === propuesta_num && Number(arr_temp_add[i].id_ficha_tecnica_archivo_propuesta_fiscal) > 0) {
							propuesta_num = 1;
						}
					}
					console.log(propuesta_num);
					
					arr_temp_add.push({
						id_ficha_tecnica_archivo_propuesta_fiscal: null,
						titulo: '',
						descripcion: '',
						comentario_adicional: '',
						base64: response.base64,
						archivo: response.archivo,
						formato: response.formato,
						file: files,
						numero_propuesta: propuesta_num
					});
					this.setState({
						ficha_tecnica_archivo_propuesta_fiscal: arr_temp_add,
						propuestas: this.acordionPropuestasFiscal(arr_temp_add)
					});
					
					console.log("FICHA_TECNICA_ARCHIVO_PROPUESTA_FISCAL::: ", this.state.ficha_tecnica_archivo_propuesta_fiscal);
					break;
				case 'archivo_integracion_expediente':
					
					let item = {
						id_cliente: this.state.id_cliente,
						id_ficha_tecnica: this.state.id_ficha_tecnica,
						id_cat_archivo_integracion_expediente: id_cat_archivo_integracion_expediente,
						id_ficha_tecnica_archivo_integracion_expediente: id_ficha_tecnica_archivo_integracion_expediente,
						archivo: response.archivo,
						formato: response.formato,
						file: files
					};
					
					
					console.log("GUARDAREXPEDIENTE::: ", this.state.ficha_tecnica_archivo_integracion_expediente, item);
					
					/*DropboxApi.Delete('/Resto del Mundo S.A. de C.V./expediente/RETS890702SF2_Comprobante de domicilio.pdf').then(function (response) {
						
					
						console.log("DROPBOX UPLOAD: ", response);
						
					}).catch(function (error) {
						console.log('error', error);
					});
					
					return*/
					this.guardarExpedienteDbx(item, id_cat_archivo_integracion_expediente).then(item => {
						this.active_inactive_spinner(true, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente);
						
						FichaTecnicaService.GuardarDatoIntegracionExpedienteXId(item).then((res) => {
							/*
							* Actualizar local variables
							* */
							let arr_temp = this.state.ficha_tecnica_archivo_integracion_expediente;
							if (id_ficha_tecnica_archivo_integracion_expediente || id_cat_archivo_integracion_expediente) {
								let uno = false;
								let dos = false;
								for (let i = 0; i < arr_temp.length; i++) {
									uno = Number(arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente) === Number(id_ficha_tecnica_archivo_integracion_expediente) && Number(id_ficha_tecnica_archivo_integracion_expediente) > 0;
									dos = Number(arr_temp[i].id_cat_archivo_integracion_expediente) === Number(id_cat_archivo_integracion_expediente) && Number(id_cat_archivo_integracion_expediente) > 0;
									if (uno && dos) {
										arr_temp[i].loading = false;
										arr_temp[i].base64 = response.base64;
										arr_temp[i].formato = res.data.formato || response.formato;
										arr_temp[i].archivo = res.data.archivo || response.archivo;
										arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = res.data.id_ficha_tecnica_archivo_integracion_expediente || null;
										arr_temp[i].id_cat_archivo_integracion_expediente = res.data.id_cat_archivo_integracion_expediente || null;
									} else if (uno && !dos) {
										arr_temp[i].loading = false;
										arr_temp[i].base64 = response.base64;
										arr_temp[i].formato = res.data.formato || response.formato;
										arr_temp[i].archivo = res.data.archivo || response.archivo;
										arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = res.data.id_ficha_tecnica_archivo_integracion_expediente || null;
										arr_temp[i].id_cat_archivo_integracion_expediente = res.data.id_cat_archivo_integracion_expediente || null;
									} else if (!uno && dos) {
										arr_temp[i].loading = false;
										arr_temp[i].base64 = response.base64;
										arr_temp[i].formato = res.data.formato || response.formato;
										arr_temp[i].archivo = res.data.archivo || response.archivo;
										arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = res.data.id_ficha_tecnica_archivo_integracion_expediente || null;
										arr_temp[i].id_cat_archivo_integracion_expediente = res.data.id_cat_archivo_integracion_expediente || null;
									}
								}
							} else {
								arr_temp.push({
									loading: false,
									base64: response.base64,
									formato: res.data.formato || response.formato,
									archivo: res.data.archivo || response.archivo,
									id_ficha_tecnica_archivo_integracion_expediente: res.data.id_ficha_tecnica_archivo_integracion_expediente || null,
									id_cat_archivo_integracion_expediente: res.data.id_cat_archivo_integracion_expediente || null,
								});
							}
							this.setState({
								ficha_tecnica_archivo_integracion_expediente: arr_temp
							});
						}).catch((error) => {
							
							this.active_inactive_spinner(false, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente);
							
							this.showSnackBars('error', error.mensaje);
							
						});
					}).catch((error) => {
						
						this.active_inactive_spinner(false, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente);
						
						this.showSnackBars('error', error.mensaje);
						
					});
					
					break;
				case 'constancia_de_situacion_fiscal':
				case 'comprobante_de_domicilio':
				case 'acta_constitutiva_de_la_cliente':
				case 'poder_del_representante_legal':
				case 'identificacion_del_repesentante_legal':
					this.setState({
						[id + '_base64']: response.base64,
						[id + '_formato']: response.formato,
						[id]: response.archivo
					});
					break;
				default:
			}
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	active_inactive_spinner = (active, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente) => {
		/*
		* INICIO: Activar el spinner
		* */
		let arr_temp = this.state.ficha_tecnica_archivo_integracion_expediente;
		if (id_ficha_tecnica_archivo_integracion_expediente || id_cat_archivo_integracion_expediente) {
			let uno = false;
			let dos = false;
			for (let i = 0; i < arr_temp.length; i++) {
				uno = Number(arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente) === Number(id_ficha_tecnica_archivo_integracion_expediente) && Number(id_ficha_tecnica_archivo_integracion_expediente) > 0;
				dos = Number(arr_temp[i].id_cat_archivo_integracion_expediente) === Number(id_cat_archivo_integracion_expediente) && Number(id_cat_archivo_integracion_expediente) > 0;
				if (uno && dos) {
					arr_temp[i].loading = active;
				} else if (uno && !dos) {
					arr_temp[i].loading = active;
				} else if (!uno && dos) {
					arr_temp[i].loading = active;
				}
			}
		}
		this.setState({
			ficha_tecnica_archivo_integracion_expediente: arr_temp
		});
		/*
		* FIN: Activar el spinner
		* */
	};
	
	guardarExpedienteDbx = (item, id_cat_archivo_integracion_expediente) => {
		console.log("1 FTAR::: ", item);
		
		let ftaie = this.state.ficha_tecnica_archivo_integracion_expediente;
		let name_archive = '';
		for (let i = 0; i < ftaie.length; i++) {
			if (ftaie[i].id_cat_archivo_integracion_expediente === id_cat_archivo_integracion_expediente) {
				name_archive = ftaie[i].archivo_integracion_expediente;
			}
		}
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		let files;
		
		return new Promise((resolve, reject) => {
			DropboxApi.Upload('/' + this.state.rfc + '-' + FieldsJs.getClienteTipoPersona(this.state.cliente) + '/expediente/' +
				this.state.rfc + '_' + name_archive + '.' +
				item.formato, item.file).then(function (response) {
				
				item.archivo = response.path_display;
				item.id_archivo = response.id;
				
				resolve(item);
				console.log("DROPBOX UPLOAD: ", response);
				
			}).catch(function (error) {
				reject(error);
			});
		});
	}
	
	
	viewDbx = (item) => {
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		let path = item.archivo;
		console.log("ITEM::: ", item, path);
		
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
	};
	
	BOTON_ACCION_IMAGEN = (item) => {
		let BTNS = '';
		let tipo = item.id_ficha_tecnica_archivo_propuesta_fiscal > 0 ? 'view' : 'add';
		switch (tipo) {
			case 'add':
				BTNS = (<Fragment>
					<ModalImage tipo={tipo} desing={1} item={item}/>
				</Fragment>);
				break;
			case 'view':
				BTNS = (<Fragment>
					{/*<ModalImage tipo={tipo} desing={1} item={item}/>*/}
					<Button size="small" color="primary" onClick={() => this.viewDbx(item)} className={'cursor-pointer'}>
						Ver
					</Button>
				</Fragment>);
				break;
			default:
		}
		return BTNS;
	};
	
	cambio = (e) => {
		const {name, checked} = e.target;
		switch (name) {
			case 'cliente_cancelo':
				if (checked) {
					this.setState({
						cliente_acepto: false, cliente_requiere_nueva_propuesta: false,
					});
				} else {
					this.setState({
						cliente_acepto: false, cliente_requiere_nueva_propuesta: false,
					});
				}
				break;
			case 'cliente_acepto':
				if (checked) {
					this.setState({
						cliente_cancelo: false, cliente_requiere_nueva_propuesta: false,
					});
				} else {
					this.setState({
						cliente_cancelo: false, cliente_requiere_nueva_propuesta: false,
					});
				}
				break;
			case 'cliente_requiere_nueva_propuesta':
				if (checked) {
					this.setState({
						cliente_cancelo: false, cliente_acepto: false,
					});
				} else {
					this.setState({
						cliente_cancelo: false, cliente_acepto: false,
					});
				}
				break;
			default:
		}
	};
	
	
	guardarPromotor = (flag) => {
		
		try {
			
			if (this.state.otros_impuestos && this.state.ficha_tecnica_otro_impuesto.length === 0) {
				throw Object({
					status: false,
					mensaje: "Por lo menos 1 impuesto es requerido.",
				});
			}
			
			let arr_temp = this.state.ficha_tecnica_archivo_elaboracion_propuesta;
			
			for (let i = 0; i < arr_temp.length; i++) {
				if (Number(arr_temp[i].is_required) === 1) {
					if (!arr_temp[i].archivo) {
						throw Object({
							status: false,
							mensaje: FieldsJs.FirstMayus("Selecciona el archivo \"" + arr_temp[i].archivo_elaboracion_propuesta + "\" para la elaboración de propuesta")
						});
					}
				}
			}
			
			if (this.state.ficha_tecnica_estado_municipio_impuesto.length === 0) {
				throw Object({
					status: false,
					mensaje: "Agregar por lo menos un estado y municipio con impuestos sobre nómina.",
				});
			}
			
			if (!(flag === 'si')) {
				let array_required_3 = [];
				
				for (let i = 0; i < arr_temp.length; i++) {
					if (Number(arr_temp[i].is_required) === 2) {
						if (!arr_temp[i].archivo) {
							array_required_3.push(arr_temp[i].archivo_elaboracion_propuesta);
						}
					}
				}
				
				if (array_required_3.length > 0) {
					let archivos = array_required_3.join(', ');
					throw Object({
						status: false,
						mensaje: "Los archivos " + archivos + " son requeridos, pero puedes continuar, ¿Deseas guardar la ficha técnica?",
						popup_alert: true
					});
				}
			}
			
			
			console.log("FICHATECNICASERVICE:::", this.state, this.state.ficha_tecnica_archivo_elaboracion_propuesta.length);
			showSpinner('spinner');
			
			let guardar = new Promise((resolve, reject) => {
				this.guardarFichaTecnicaArchivoElaboracionPropuestaDbx().then(response => {
					console.log("GUARDARFICHATECNICAARCHIVOELABORACIONPROPUESTADBX::: ", response);
					this.setState({
						ficha_tecnica_archivo_elaboracion_propuesta: response
					});
					resolve(response);
					console.log("DROPBOX UPLOAD: ", response);
					
				}).catch(error => {
					reject(error);
				})
			});
			
			guardar.then(response => {
				FichaTecnicaService.GuardarDatoPromotor(this.state).then((response) => {
					console.log("ENVIO DE DATOS");
					this.showSnackBars('success', response.mensaje);
					this.InitDato();
					this.DeleteDatoLocalFichaTecnica();
					if (response.modal === 1) {
						this.openModal(1);
					} else {
						setTimeout(() => {
							this.regresar();
						}, 1000)
					}
				}).catch((error) => {
					hideSpinner('spinner');
					this.showSnackBars('error', error.mensaje);
				})
			})
			
			
		} catch (e) {
			if (!!e.popup_alert === true) {
				PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, e.mensaje).then((r) => {
					if (r.button === 'Aceptar') {
						this.guardarPromotor('si')
					}
				});
			} else {
				this.showSnackBars('error', e.mensaje);
			}
		}
		
	};
	
	
	guardarFichaTecnicaArchivoElaboracionPropuestaDbx = () => {
		let ftar = this.state.ficha_tecnica_archivo_elaboracion_propuesta;
		console.log("1 FTAR::: ", ftar);
		
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		let files;
		
		return new Promise((resolve, reject) => {
			let band = 0;
			let band2 = 0;
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i].archivo) === true) {
					band++;
				}
			}
			
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i].archivo) === true) {
					console.log("2 FTAR::: ", ftar);
					files = ftar[i].file;
					
					if (FieldsJs.Field(files) === true) {
						DropboxApi.Upload('/' + this.state.rfc + '-' + FieldsJs.getClienteTipoPersona(this.state.cliente) + '/archivos_para_propuesta/' +
							this.state.rfc + '_' + ftar[i].titulo + '.' +
							ftar[i].formato, files).then(function (response) {
							
							ftar[i].archivo = response.path_display;
							ftar[i].id_archivo = response.id;
							
							band2++;
							
							console.log("DROPBOX UPLOAD: ", response, band, band2);
							
							if (band === band2) {
								resolve(ftar);
							}
						}).catch(function (error) {
							reject(error);
						});
					} else {
						resolve(ftar);
					}
				}
			}
		});
	};
	
	guardarFiscalDbx = () => {
		let ftar = this.state.ficha_tecnica_archivo_propuesta_fiscal;
		console.log("1 FTAR::: ", ftar);
		
		let files;
		
		return new Promise((resolve, reject) => {
			let band = 0;
			let band2 = 0;
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i].archivo) === true) {
					band++;
				}
			}
			
			let propuestaText = "propuesta";
			
			let propuesta_num = 0;
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i].numero_propuesta)) {
					if (Number(ftar[i].numero_propuesta) > propuesta_num) {
						propuesta_num = Number(ftar[i].numero_propuesta);
					}
				}
			}
			
			propuestaText = propuestaText + (propuesta_num === 0 ? '' : propuesta_num + 1);
			
			for (let i = 0; i < ftar.length; i++) {
				if (FieldsJs.Field(ftar[i].archivo) === true) {
					console.log("2 FTAR::: ", ftar);
					files = ftar[i].file;
					
					if (FieldsJs.Field(files) === true && FieldsJs.Field(ftar[i].formato)) {
						DropboxApi.Upload('/' + this.state.rfc + '-' + FieldsJs.getClienteTipoPersona(this.state.cliente) + '/propuestas/' +
							this.state.rfc + '_' + propuestaText + '_' +
							ftar[i].titulo + '.' +
							ftar[i].formato, files).then(function (response) {
							
							ftar[i].archivo = response.path_display;
							ftar[i].id_archivo = response.id;
							
							band2++;
							
							console.log("DROPBOX UPLOAD: ", response, band, band2);
							
							if (band === band2) {
								resolve(ftar);
							}
						}).catch(function (error) {
							reject(error);
						});
					} else {
						band2++;
						if (band === band2) {
							resolve(ftar);
						}
					}
				}
			}
		});
	};
	
	guardarFiscal = () => {
		console.log("FICHATECNICASERVICE:::", this.state, this.state.ficha_tecnica_archivo_propuesta_fiscal.length);
		console.log("FICHATECNICASERVICE2222:::", this.state, this.state.ficha_tecnica_archivo_propuesta_fiscal_delete);
		try {
			
			let band = false;
			let ftapf = this.state.ficha_tecnica_archivo_propuesta_fiscal;
			let ftapfd = this.state.ficha_tecnica_archivo_propuesta_fiscal_delete;
			
			for (let i = 0; i < ftapf.length; i++) {
				console.log(ftapf.length, ftapf[i].titulo, FieldsJs.Field(ftapf[i].titulo));
				if (!FieldsJs.Field(ftapf[i].titulo)) {
					band = true;
				}
			}
			if (ftapf.length <= 0) {
				throw Object({
					status: false,
					mensaje: "Es necesario agregar mínimo una propuesta"
				});
			}
			if (band) {
				throw Object({
					status: false,
					mensaje: "Titulo de propuesta requerido"
				});
			}
			showSpinner('spinner');
			let guardar = new Promise((resolve, reject) => {
				this.guardarFiscalDbx().then(response => {
					console.log("GUARDARFICHATECNICAARCHIVOELABORACIONPROPUESTADBX::: ", response);
					/*this.setState({
						ficha_tecnica_archivo_propuesta_fiscal: response
					});*/
					resolve(response);
					console.log("DROPBOX UPLOAD: ", response);
					
				}).catch(error => {
					reject(error);
				})
			});
			
			if (ftapfd.length > 0) {
				for (let i = 0; i < ftapfd.length; i++) {
					DropboxApi.Delete(ftapfd[i]).then(function (response) {
						console.log("DROPBOX UPLOAD: ", response);
					}).catch(function (error) {
						console.log('error', error);
					});
				}
			}
			
			guardar.then(response => {
				FichaTecnicaService.GuardarDatoFiscal(this.state).then((response) => {
					this.showSnackBars('success', response.mensaje);
					this.InitDato();
					if (response.modal === 1) {
						this.openModal(2);
					} else {
						setTimeout(() => {
							this.regresar();
						}, 1000)
					}
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				})
			});
		} catch (e) {
			hideSpinner('spinner');
			if (!!e.popup_alert === true) {
				PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, e.mensaje).then((r) => {
					if (r.button === 'Aceptar') {
						this.guardarPromotor('si')
					}
				});
			} else {
				this.showSnackBars('error', e.mensaje);
			}
		}
	};
	
	guardarRespuesta = () => {
		try {
			
			if (this.state.cliente_requiere_nueva_propuesta) {
				if (!this.state.porque_requiere_nueva_propuesta) {
					throw Object({
						status: false, mensaje: 'Especifica el motivo por la que el cliente requiere nueva propuesta'
					})
				}
			}
			
			let msg = '';
			
			if (this.state.cliente_cancelo) {
				msg = `¿Deseas confirmar esta acción, el cliente: ${this.state.cliente.nombre_comercial} requiere cancelar el servicio ofrecido?`;
			}
			if (this.state.cliente_requiere_nueva_propuesta) {
				msg = `¿Deseas confirmar esta acción, el cliente: ${this.state.cliente.nombre_comercial} requiere una nueva propuesta?`;
			}
			if (this.state.cliente_acepto) {
				msg = `¿Deseas confirmar esta acción, el cliente: ${this.state.cliente.nombre_comercial} ha aceptado el servicio ofrecido?`;
			}
			
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
				
				if (r.button === 'Aceptar') {
					FichaTecnicaService.GuardarDatoPromotorRespuesta(this.state).then((response) => {
						
						this.showSnackBars('success', response.mensaje);
						
						this.InitDato();
						
					}).catch((error) => {
						
						this.showSnackBars('error', error.mensaje);
						
					});
				}
				
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	activeModalExpediente = () => {
		this.setState({
			active_modal: true
		});
		this.setState({
			active_modal: false
		});
		console.log(this.state.active_modal, "MODAL ACTIVADO")
	};
	
	guardarExpediente = (flag) => {
		
		try {
			
			let arr_temp = this.state.ficha_tecnica_archivo_integracion_expediente;
			
			for (let i = 0; i < arr_temp.length; i++) {
				if (Number(arr_temp[i].is_required) === 1) {
					if (!arr_temp[i].archivo) {
						throw Object({
							status: false,
							mensaje: FieldsJs.FirstMayus("Selecciona el archivo \"" + arr_temp[i].archivo_integracion_expediente + "\" para la integración del expediente")
						});
					}
				}
			}
			
			for (let i = 0; i < arr_temp.length; i++) {
				if (Number(arr_temp[i].is_required) === 1) {
					if (!arr_temp[i].archivo) {
						throw Object({
							status: false,
							mensaje: FieldsJs.FirstMayus("Es ")
						});
					}
				}
			}
			
			if (!(flag === 'si')) {
				let array_required_3 = [];
				
				for (let i = 0; i < arr_temp.length; i++) {
					if (Number(arr_temp[i].is_required) === 2) {
						if (!arr_temp[i].archivo) {
							array_required_3.push(arr_temp[i].archivo_integracion_expediente);
						}
					}
				}
				
				if (array_required_3.length > 0) {
					let archivos = array_required_3.join(', ');
					throw Object({
						status: false,
						mensaje: "Los archivos " + archivos + " son requeridos, pero puedes continuar, ¿Deseas guardar la integración del expediente?",
						popup_alert: true
					});
				}
			}
			
			if (flag !== 'si' && this.state.expediente_trabajador.length === 0) {
				throw Object({
					status: false,
					mensaje: "No se han agregado expedientes del trabajador. Desea continuar?",
					popup_alert: true,
					expedientes: true
				});
			}
			
			FichaTecnicaService.GuardarDatoIntegracionExpediente(this.state, this.state.ficha_tecnica_archivo_integracion_expediente).then((response) => {
				
				this.showSnackBars('success', response.mensaje);
				
				this.InitDato();
				
				if (response.modal === 1) {
					this.openModal(3);
				} else {
					setTimeout(() => {
						this.regresar();
					}, 1000)
				}
				
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} catch (e) {
			if ((e.expedientes || false) === true) {
				PopupService.Confirm(['Agregar Expedientes', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, e.mensaje).then((r) => {
					if (r.button === 'Aceptar') {
						this.guardarExpediente('si')
					} else if (r.button === 'Agregar Expedientes') {
						this.activeModalExpediente();
					}
				});
			} else if ((e.popup_alert || false) === true) {
				PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, e.mensaje).then((r) => {
					if (r.button === 'Aceptar') {
						this.guardarExpediente('si')
					}
				});
			} else {
				this.showSnackBars('error', e.mensaje);
			}
		}
	};
	
	regresar = () => {
		if (FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status)) {
			$State.go(this.props, 'clientepotencial');
		} else {
			$State.go(this.props, 'clientepotencial');
		}
	};
	
	ListaHistorialFichaTecnica = (arr) => {
		
		let New_Arr = [];
		
		for (let i = 0; i < this.state.ficha_tecnica_historial_accion_usuario.length; i++) {
			if (FieldsJs.inArray(arr, this.state.ficha_tecnica_historial_accion_usuario[i].id_cat_ficha_tecnica_status)) {
				New_Arr.push(this.state.ficha_tecnica_historial_accion_usuario[i]);
			}
		}
		
		const src = CONFIG.src;
		
		const foto_default = CONFIG.foto_default;
		
		return (
			<Fragment>
				{this.state.mostrar_notas_desarrollador ? (
					<Fragment>
						{New_Arr.length > 0 ? (
							<div className={'borde-punteado-warning-2 bg-nota padding-20 margin-20-T margin-20-B'} style={{opacity: 0.5}}>
								
								<Typography variant={'h6'} className={'text-center text-warning'}>
									Notas:
								</Typography>
								
								<List>
									{New_Arr && New_Arr.map((item, index) => (
										<ListItem button key={index} alignItems="flex-start">
											<ListItemAvatar>
												<Avatar alt="Remy Sharp" src={item.foto ? (src + item.foto) : foto_default}/>
											</ListItemAvatar>
											<ListItemText primary={(
												<Fragment>
													{item.nombre} {item.apellido_paterno} {item.apellido_materno}
													<b> ({item.tipo_usuario})</b><br/>
													{item.comentario_observacion_desarrollador}
												</Fragment>
											)}/>
										</ListItem>
									))}
								</List>
							
							</div>
						) : ''}
					</Fragment>
				) : ''}
			</Fragment>
		);
	};
	
	removefilepromotor = (item, key) => {
		// console.log(item, key);
		var arr_temp = FieldsJs.Copy(this.state.ficha_tecnica_archivo_elaboracion_propuesta);
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, '¿Deseas borrar este archivo?').then((r) => {
			if (r.button === 'Aceptar') {
				arr_temp.splice(key, 1);
				this.setState({
					ficha_tecnica_archivo_elaboracion_propuesta: arr_temp
				});
			}
		});
	};
	
	openModal = tipo => {
		switch (tipo) {
			case 1:
				this.setState({
					modal: {
						open: true,
						title: 'Realizaste el levantamiento de requerimientos de la ficha técnica con éxito',
						message: 'Se le envió un correo electrónico al fiscal confirmando esta acción para solicitar la elaboración de la ficha técnica',
						bg: 2,
						button: 'Aceptar'
					}
				});
				break;
			case 2:
				this.setState({
					modal: {
						open: true,
						title: 'La propuesta fue enviada con éxito',
						message: 'Se le envió un correo electrónico de confirmación al promotor',
						bg: 3,
						button: 'Aceptar'
					}
				});
				break;
			case 3:
				this.setState({
					modal: {
						open: true,
						title: 'Los expedientes fueron enviados con éxito',
						message: 'En estos momentos el cliente dejara de aparecer en la lista de clientes potenciales, aparecerá en el apartado de empresas clientes, para continuar con el proceso',
						bg: 4,
						button: 'Aceptar'
					}
				});
				break;
			default:
				this.setState({
					modal: {
						open: true,
						title: 'Title',
						message: 'Message',
						bg: 1,
						button: 'Aceptar'
					}
				});
		}
		
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false,
				title: '',
				message: '',
				bg: '',
				button: ''
			}
		});
		setTimeout(() => {
			this.regresar();
		}, 1000);
	};
	
	sirocActive = () => {
		setTimeout(() => {
			let siroc_active = false;
			if (this.state.sueldo_salario || this.state.outsourcing) {
				siroc_active = true;
			}
			this.setState({
				siroc_active: siroc_active
			});
		});
	};
	
	removefileFiscal = (item, key) => {
		console.log(item, key);
		var arr_temp = FieldsJs.Copy(this.state.ficha_tecnica_archivo_propuesta_fiscal);
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, '¿Deseas borrar este archivo?').then((r) => {
			if (r.button === 'Aceptar') {
				arr_temp.splice(key, 1);
				let ftapfd = FieldsJs.Copy(this.state.ficha_tecnica_archivo_propuesta_fiscal_delete);
				if (!item.formato) {
					ftapfd.push(item.archivo);
				}
				this.setState({
					ficha_tecnica_archivo_propuesta_fiscal: arr_temp,
					ficha_tecnica_archivo_propuesta_fiscal_delete: ftapfd
				});
				console.log("FICHA_TECNICA_ARCHIVO_PROPUESTA_FISCAL_DELETE:::", this.state.ficha_tecnica_archivo_propuesta_fiscal_delete);
			}
		});
	};
	
	agregar_ficha_tecnica_cantidad_empleado_banco = () => {
		
		try {
			
			let arr_temp = FieldsJs.Copy(this.state.ficha_tecnica_cantidad_empleado_banco);
			let front_id_esquema_banco = this.state.front_id_esquema_banco;
			let front_nombre_esquema = this.state.front_nombre_esquema;
			let front_nombre_banco = this.state.front_nombre_banco;
			let front_id_banco = this.state.front_id_banco;
			let front_cantidad_empleado = this.state.front_cantidad_empleado;
			
			
			if (!front_id_esquema_banco) {
				throw Object({
					status: false,
					mensaje: "Selecciona un esquema para agregarlo a la lista"
				});
			}
			
			if (!front_id_banco) {
				throw Object({
					status: false,
					mensaje: "Selecciona un banco para agregarlo a la lista"
				});
			}
			
			if (!front_cantidad_empleado || front_cantidad_empleado <= 0) {
				throw Object({
					status: false,
					mensaje: "La cantidad de empleados no es valida."
				});
			}
			
			let cant = 0;
			let cant_banco = Number(front_cantidad_empleado);
			for (let i = 0; i < this.state.ficha_tecnica_cantidad_empleado_esquema.length; i++) {
				if (Number(this.state.ficha_tecnica_cantidad_empleado_esquema[i].id_cat_esquema) === Number(front_id_esquema_banco)) {
					cant = Number(this.state.ficha_tecnica_cantidad_empleado_esquema[i].cantidad_empleado_esquema);
				}
			}
			for (let i = 0; i < this.state.ficha_tecnica_cantidad_empleado_banco.length; i++) {
				if (Number(this.state.ficha_tecnica_cantidad_empleado_banco[i].id_cat_esquema) === Number(front_id_esquema_banco)) {
					cant_banco = cant_banco + Number(this.state.ficha_tecnica_cantidad_empleado_banco[i].cantidad_empleado);
				}
			}
			console.log("CANTIDADES :::: ", cant_banco, cant, cant_banco > cant);
			if (cant_banco > cant) {
				throw Object({
					status: false,
					mensaje: "La cantidad de empleados no puede exceder del limite registrado como esquema."
				});
			}
			
			for (let i = 0; i < this.state.lista_esquemas.length; i++) {
				if (Number(this.state.lista_esquemas[i].id_cat_esquema) === Number(front_id_esquema_banco)) {
					front_nombre_esquema = this.state.lista_esquemas[i].abreviatura;
				}
			}
			
			for (let i = 0; i < this.state.lista_bancos.length; i++) {
				if (Number(this.state.lista_bancos[i].id_cat_banco) === Number(front_id_banco)) {
					front_nombre_banco = this.state.lista_bancos[i].banco;
				}
			}
			
			arr_temp.push({
				id_cat_esquema: front_id_esquema_banco,
				esquema: front_nombre_esquema,
				id_cat_banco: front_id_banco,
				banco: front_nombre_banco,
				cantidad_empleado: front_cantidad_empleado
			});
			
			this.setState({
				ficha_tecnica_cantidad_empleado_banco: arr_temp,
				front_id_esquema_banco: "",
				front_nombre_banco: "",
				front_id_banco: "",
				front_cantidad_empleado: ""
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
		
	};
	
	agregar_ficha_tecnica_cantidad_empleado_esquema = () => {
		
		try {
			
			let arr_temp = FieldsJs.Copy(this.state.ficha_tecnica_cantidad_empleado_esquema);
			let front_nombre_esquema = this.state.front_nombre_esquema;
			let front_id_esquema = this.state.front_id_esquema;
			let front_cantidad_empleado = this.state.front_cantidad_empleado_esquema;
			
			
			if (!front_id_esquema) {
				throw Object({
					status: false,
					mensaje: "Selecciona un esquema para agregarlo a la lista"
				});
			}
			
			if (!front_cantidad_empleado) {
				throw Object({
					status: false,
					mensaje: "Escribe la cantidad de empleados para agregarlo a la lista"
				});
			}
			
			for (let i = 0; i < this.state.lista_esquemas.length; i++) {
				if (Number(this.state.lista_esquemas[i].id_cat_esquema) === Number(front_id_esquema)) {
					front_nombre_esquema = this.state.lista_esquemas[i].abreviatura;
				}
			}
			
			arr_temp.push({
				id_cat_esquema: front_id_esquema,
				esquema: front_nombre_esquema,
				cantidad_empleado_esquema: front_cantidad_empleado
			});
			
			this.setState({
				ficha_tecnica_cantidad_empleado_esquema: arr_temp,
				front_nombre_esquema: "",
				front_id_esquema: "",
				front_cantidad_empleado_esquema: ""
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
		
	};
	
	eliminar_ficha_tecnica_cantidad_empleado_banco = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.ficha_tecnica_cantidad_empleado_banco);
		arr_temp.splice(key, 1);
		this.setState({
			ficha_tecnica_cantidad_empleado_banco: arr_temp
		});
	};
	
	eliminar_ficha_tecnica_cantidad_empleado_esquema = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.ficha_tecnica_cantidad_empleado_esquema);
		arr_temp.splice(key, 1);
		this.setState({
			ficha_tecnica_cantidad_empleado_esquema: arr_temp
		});
	};
	
	/*
	* Para colocar los campos disabled para cada tipo de usuarios
	* ------------------------------------------------------------------------------------------------------------*/
	
	disabledFieldPromotorRequerimientoClienteBolean = () => {
		
		let disabledFieldPromotorRequerimientoCliente = true;
		
		if (FieldsJs.inArray([1, 2, 3, 13], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([0, 1, 2], this.state.id_cat_ficha_tecnica_status || 0)) {
			disabledFieldPromotorRequerimientoCliente = false;
		}
		
		return disabledFieldPromotorRequerimientoCliente;
	};
	
	disabledFieldFiscalSubirPropuestaBolean = () => {
		
		let disabledFieldFiscalSubirPropuesta = true;
		
		if (FieldsJs.inArray([1, 2, 4], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([2, 3, 5], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldFiscalSubirPropuesta = false;
		}
		
		return disabledFieldFiscalSubirPropuesta;
	};
	
	disabledFieldPromotorRespuestaClienteBolean = () => {
		
		let disabledFieldPromotorRespuestaCliente = true;
		
		if (FieldsJs.inArray([1, 2, 3, 13], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([3], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldPromotorRespuestaCliente = false;
		}
		
		return disabledFieldPromotorRespuestaCliente;
	};
	
	disabledFieldPromotorIntegracionExpedienteBolean = () => {
		
		let disabledFieldPromotorIntegracionExpediente = true;
		
		if (FieldsJs.inArray([1, 2, 3, 13], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([6, 7], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldPromotorIntegracionExpediente = false;
		}
		
		return disabledFieldPromotorIntegracionExpediente;
	};
	
	render() {
		
		const disabledFieldPromotorRequerimientoCliente = this.disabledFieldPromotorRequerimientoClienteBolean();
		const disabledFieldFiscalSubirPropuesta = this.disabledFieldFiscalSubirPropuestaBolean();
		const disabledFieldPromotorRespuestaCliente = this.disabledFieldPromotorRespuestaClienteBolean();
		const disabledFieldPromotorIntegracionExpediente = this.disabledFieldPromotorIntegracionExpedienteBolean();
		
		const HISTORIAL_1 = this.ListaHistorialFichaTecnica([2]);
		const HISTORIAL_2 = this.ListaHistorialFichaTecnica([3]);
		const HISTORIAL_3 = this.ListaHistorialFichaTecnica([4, 5, 6, 7]);
		
		return (<div className='FichaTecnica ContaineViewComponet'>
			
			{this.state.visible === 0 ? (
				<Fragment>
					<Typography variant={'h6'} className={'text-center px-20 padding-50'}>
						{this.state.mensaje}
					</Typography>
				</Fragment>
			) : null}
			
			{this.state.visible === 1 ? (
				<Fragment>
					{/*<Button color='primary' onClick={this.openModal}>Open</Button>*/}
					
					<ModalMessageConfirmation modal={this.state.modal} closeModal={this.closeModal}/>
					
					<Fragment>
						
						<SeccionPromotorRequerimientos
							disabledFieldPromotorRequerimientoCliente={disabledFieldPromotorRequerimientoCliente}
							state={this.state}
							showSnackBars={this.showSnackBars}
							handleChange={this.handleChange}
							$BroadcastModalSeleccionarArchivoElaboracionPropuesta={this.$BroadcastModalSeleccionarArchivoElaboracionPropuesta}
							sirocActive={this.sirocActive}
							agregar_otro_impuesto={this.agregar_otro_impuesto}
							agregar_estado_municipio_impuesto={this.agregar_estado_municipio_impuesto}
							ListMunicipio={this.ListMunicipio}
							guardarPromotor={this.guardarPromotor}
							eliminar_estado_municipio_impuesto={this.eliminar_estado_municipio_impuesto}
							eliminar_otro_impuesto={this.eliminar_otro_impuesto}
							removefilepromotor={this.removefilepromotor}
						/>
					
					</Fragment>
					
					<Fragment>
						{HISTORIAL_1}
					</Fragment>
					
					<Fragment>
						<SeccionPropuestaFiscalRespuestaClientePromotor
							disabledFieldFiscalSubirPropuesta={disabledFieldFiscalSubirPropuesta}
							disabledFieldPromotorRespuestaCliente={disabledFieldPromotorRespuestaCliente}
							state={this.state}
							
							removefileFiscal={this.removefileFiscal}
							BOTON_ACCION_IMAGEN={this.BOTON_ACCION_IMAGEN}
							showSnackBars={this.showSnackBars}
							handleChange={this.handleChange}
							fileSelect={this.fileSelect}
							getBase64={this.getBase64}
							guardarFiscal={this.guardarFiscal}
							guardarRespuesta={this.guardarRespuesta}
							HISTORIAL_2={HISTORIAL_2}
							cambio={this.cambio}
						/>
					</Fragment>
					
					{(FieldsJs.inArray([1, 2, 3, 4, 5, 6, 7], this.Usr.id_cat_tipo_usuario || 0) && FieldsJs.inArray([6, 7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status || 0)) ? (
						
						<Fragment>
							
							<SeccionPromotorIntegracionDelExpediente
								disabledFieldPromotorIntegracionExpediente={disabledFieldPromotorIntegracionExpediente}
								state={this.state}
								showSnackBars={this.showSnackBars}
								handleChange={this.handleChange}
								fileSelect={this.fileSelect}
								getBase64={this.getBase64}
								agregar_ficha_tecnica_cantidad_empleado_banco={this.agregar_ficha_tecnica_cantidad_empleado_banco}
								eliminar_ficha_tecnica_cantidad_empleado_banco={this.eliminar_ficha_tecnica_cantidad_empleado_banco}
								agregar_ficha_tecnica_cantidad_empleado_esquema={this.agregar_ficha_tecnica_cantidad_empleado_esquema}
								eliminar_ficha_tecnica_cantidad_empleado_esquema={this.eliminar_ficha_tecnica_cantidad_empleado_esquema}
								guardarExpediente={this.guardarExpediente}
								RefreshFichaTecnica={this.RefreshFichaTecnica}
							/>
						
						</Fragment>
					
					) : ''}
					
					<Fragment>
						{HISTORIAL_3}
					</Fragment>
					
					{/*
					CAMPO COMENTARIO PARA EL DESARROLLADOR
					
					<div onClick={() => {this.setState({notas: !this.state.notas})}} className={'cursor-pointer padding-10'}>
						{this.state.notas ? "Ocultar notas" : "Ver notas"}
					</div>
					*/}
					
					{this.state.notas ? (
						<Fragment>
							<Divider className={'margin-50-T margin-50-B'}/>
							
							<Fragment>
								<div className={'borde-punteado-warning-2 bg-nota padding-20'}>
									
									<Typography variant={'h5'} className={'text-center text-warning'}>
										Nota extra:
									</Typography>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<FormControlLabel
											control={<Checkbox type="checkbox" name='mostrar_notas_desarrollador'
											                   checked={this.state.mostrar_notas_desarrollador}
											                   onChange={this.handleChange}
											                   value="mostrar_notas_desarrollador"
											/>}
											label="Mostrar notas"
										/>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											multiline
											fullWidth
											onChange={this.handleChange}
											type="text"
											margin="none"
											
											helperText="Escribe aquí los comentario, observaciones o mejoras para el modulo"
											name="comentario_observacion_desarrollador"
											label="Comentarios, observaciones o mejoras:"
											value={this.state.comentario_observacion_desarrollador}
										/>
									</Grid>
								</div>
							</Fragment>
						</Fragment>
					) : ""}
				</Fragment>
			) : null}
			
			<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
			      style={{transitionDelay: `${100}ms`}} unmountOnExit>
				<Fab style={{backgroundColor: '#ff6037', background: '#ff6037'}} color={'primary'} onClick={() => this.InitDato()}>
					<RefreshOutlined/>
				</Fab>
			</Zoom>
		
		</div>);
	}
}

FichaTecnica.propTypes = {
	params: PropTypes.object.isRequired,
};

export default IntegrationNotistack(FichaTecnica);
