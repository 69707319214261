import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {CitaService} from '../../../../services/CitaService/CitaService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";
import ModalEmpresa from "../../Cat/Empresa/Includes/ModalEmpresa";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import ModalRecordatorios from "../../Reccordatorios/Includes/ModalRecordatorios";
import {DeleteOutlined, SpeakerNotes, Sync} from "@material-ui/icons";
import {DropzoneArea} from "material-ui-dropzone";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DocSvg from "../../../../assets/img/icons/doc.svg";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";


class ModalCita extends Component {
	
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			list_cat_tipo_usuario: [],
			id_cita: (props.item || '').id_cita,
			tipo: props.tipo,
			id_empresa: '',
			nombre_acude: '',
			lugar_cita: '',
			fecha_cita: null,
			fecha_fisica: null,
			nombre_autoridad: '',
			telefono: '',
			correo_electronico: '',
			nombre_contador: '',
			descripcion: '',
			plazo: '',
			diferencias: '',
			atencion: '',
			recordatorio: '',
			url: '',
			check_fecha_hora: '',
			check_url: '',
			files: [],
			archivos: [],
		};
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	guardarDocTimbradoDbx = () => {
		
		let files = this.state.files;
		
		return new Promise((resolve, reject) => {
			let band = files.length;
			let band2 = 0;
			let archives = this.state.archivos;
			let file;
			
			for (let i = 0; i < files.length; i++) {
				console.log("2 FTAR::: ", files);
				file = files[i];
				
				let ruta = '/Entrevistas_SAT/' + file.name;
				
				DropboxApi.Upload(ruta, file).then(function (response) {
					archives.push({archivo: response.path_display});
					
					band2++;
					
					console.log("DROPBOX UPLOAD: ", response, band, band2);
					
					if (band === band2) {
						resolve(archives);
					}
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	save = () => {
		try {
			
			
			if (!FieldsJs.Field(this.state.id_empresa)) {
				throw Object({
					status: false, mensaje: 'Campo empresa es requerido.'
				})
			}
			if (!FieldsJs.Field(this.state.fecha_cita)) {
				throw Object({
					status: false, mensaje: 'Campo fecha cita es requerido.'
				})
			}
			
			if (this.state.files.length > 0) {
				
				let guardar = new Promise((resolve, reject) => {
					showSpinner('spinner');
					this.guardarDocTimbradoDbx(this.state.files).then(response => {
						this.setState({
							archivos: response,
						});
						resolve(response);
						hideSpinner('spinner', 500);
						console.log("DROPBOX UPLOAD: ", this.state);
					}).catch(error => {
						hideSpinner('spinner', 500);
						reject(error);
					})
				});
				
				guardar.then(response => {
					if (this.state.id_cita > 0) {
						CitaService.Modificar(this.state).then((response) => {
							this.modalClose();
							this.props.RefreshList();
							this.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.showSnackBars('error', error.mensaje);
						});
					} else {
						CitaService.Agregar(this.state).then((response) => {
							this.modalClose();
							this.props.RefreshList();
							this.showSnackBars('success', response.mensaje);
						}).catch((error) => {
							this.showSnackBars('error', error.mensaje);
						});
					}
				});
				
			} else {
				if (this.state.id_cita > 0) {
					CitaService.Modificar(this.state).then((response) => {
						this.modalClose();
						this.props.RefreshList();
						this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
					});
				} else {
					CitaService.Agregar(this.state).then((response) => {
						this.modalClose();
						this.props.RefreshList();
						this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.showSnackBars('error', error.mensaje);
					});
				}
			}
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		
		this.setState({
			id_cita: '',
			id_empresa: '',
			nombre_acude: '',
			lugar_cita: '',
			fecha_cita: null,
			fecha_fisica: null,
			nombre_autoridad: '',
			telefono: '',
			correo_electronico: '',
			nombre_contador: '',
			descripcion: '',
			plazo: '',
			diferencias: '',
			atencion: '',
			url: '',
			check_fecha_hora: '',
			check_url: '',
			archivos: '',
			activo: true,
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_cita: item.id_cita || '',
			id_empresa: item.id_empresa || '',
			nombre_acude: item.nombre_acude || '',
			lugar_cita: item.lugar_cita || '',
			fecha_cita: item.fecha_cita || null,
			fecha_fisica: item.fecha_fisica || null,
			nombre_autoridad: item.nombre_autoridad || '',
			telefono: item.telefono || '',
			correo_electronico: item.correo_electronico || '',
			nombre_contador: item.nombre_contador || '',
			descripcion: item.descripcion || '',
			plazo: item.plazo || '',
			diferencias: item.diferencias || '',
			atencion: item.atencion || '',
			url: item.url || '',
			check_fecha_hora: item.fecha_fisica ? true : '',
			check_url: item.url ? true : '',
			archivos: item.archivos || [],
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_cita: item.id_cita || '',
			id_empresa: item.id_empresa || '',
			nombre_acude: item.nombre_acude || '',
			lugar_cita: item.lugar_cita || '',
			fecha_cita: item.fecha_cita || null,
			fecha_fisica: item.fecha_fisica || null,
			nombre_autoridad: item.nombre_autoridad || '',
			telefono: item.telefono || '',
			correo_electronico: item.correo_electronico || '',
			nombre_contador: item.nombre_contador || '',
			descripcion: item.descripcion || '',
			plazo: item.plazo || '',
			diferencias: item.diferencias || '',
			atencion: item.atencion || '',
			activo: (item.activo === 1),
			url: item.url || '',
			archivos: item.archivos || [],
			check_fecha_hora: item.fecha_fisica ? true : '',
			check_url: item.url ? true : '',
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_cita > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	descargarZip = (path) => {
		console.log("PATH ::: ", path);
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error("ERROR::: ", error);
		});
	};
	
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Entrevista SAT</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={2} alignItems={"flex-end"}>
									<Grid item className={'w-50-px text-left'}>
										<ModalEmpresa
											tipo={'add'}
											item={{}}
											componente={
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Agregar empresa">
													<Fab size="small" color="primary" aria-label="Agregar empresa">
														<Add/>
													</Fab>
												</Tooltip>
											}
											RefreshList={this.props.RefreshList}
											showSnackBars={this.props.showSnackBars}
										/>
									</Grid>
									<Grid item className={'w-100-50-px'}>
										<FormControl fullWidth required margin="dense" className={'margin-0'}>
											<InputLabel>Empresa</InputLabel>
											<Select
												native
												value={this.state.id_empresa}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="id_empresa"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.props.lista_empresa.map(option => (
													<option key={option.id_empresa}
													        value={option.id_empresa}>
														{option.nombre_comercial}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="nombre_acude"
									label="Nombre persona que acude a la cita"
									value={this.state.nombre_acude}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="lugar_cita"
									label="Lugar de la cita"
									value={this.state.lugar_cita}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<KeyboardDateTimePicker
									format="yyyy/MM/dd hh:mm a"
									fullWidth
									clearable
									required
									className={'margin-0'}
									margin="dense"
									label="Fecha de la cita"
									value={this.state.fecha_cita}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_cita');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									name="nombre_autoridad"
									label="Nombre de la autoridad"
									value={this.state.nombre_autoridad}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<TextField
									className={'margin-0'}
									type="text"
									fullWidth
									margin="dense"
									name="telefono"
									onChange={this.handleChange}
									label="Teléfono"
									autoComplete={'off'}
									value={this.state.telefono}
									inputProps={{maxLength: 10}}
									onKeyPress={EnteroSolo}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<TextField
									className={'margin-0'}
									type="text"
									fullWidth
									margin="dense"
									name="correo_electronico"
									onChange={this.handleChange}
									label="Correo Electrónico"
									autoComplete={'off'}
									value={this.state.correo_electronico}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="nombre_contador"
									label="Nombre de los contadores externos"
									value={this.state.nombre_contador}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									name="descripcion"
									label="Descripción"
									value={this.state.descripcion}
								/>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={!this.state.check_fecha_hora ? 12 : 8} lg={!this.state.check_fecha_hora ? 12 : 8} xl={!this.state.check_fecha_hora ? 12 : 8}>
								<div className={'vertical-inline h-100 w-100'}>
									<div className={'v-center h-100'}>
										<FormControlLabel
											className={'text-black px-12 text-bold'}
											control={
												<Checkbox
													type="checkbox"
													disabled={this.state.tipo === 'view'}
													onChange={this.handleChange}
													color="primary"
													name='check_fecha_hora'
													checked={this.state.check_fecha_hora}
													value="check_fecha_hora"
												/>
											}
											label={<div style={{fontWeight: 'bold'}}>Será necesario que acuda alguien físicamente por parte de la empresa en la siguiente cita? De ser así señalar fecha y hora.</div>}
										/>
									</div>
								</div>
							</Grid>
							
							{this.state.check_fecha_hora ? (
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
									<KeyboardDateTimePicker
										format="yyyy/MM/dd hh:mm a"
										fullWidth
										clearable
										className={'margin-0'}
										margin="dense"
										inputProps={{readOnly: true}}
										KeyboardButtonProps={{
											'aria-label': 'Fecha y hora',
										}}
										label="Fecha y hora"
										value={this.state.fecha_fisica}
										onChange={(date) => {
											this.handleChange(null, null, null, date, 'fecha_fisica');
										}}
										// minDate={moment(new Date())}
										clearLabel={'Limpiar'}
										okLabel={'Aceptar'}
										cancelLabel={'Cancelar'}
										onError={console.log}
										disabled={this.state.tipo === 'view'}
									/>
								</Grid>
							) : null
							}
							
							
							<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
								<div className={'vertical-inline h-100 w-100'}>
									<p style={{fontWeight: 'bold'}} className={'v-center text-black px-12'}>
										Confirmar el plazo (Días) que da la autoridad para efectos de dar respuesta al requerimiento de Diferencias.
									</p>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									className={'margin-0'}
									margin="dense"
									type={'text'}
									fullWidth
									name="plazo"
									onChange={this.handleChange}
									label="Plazo"
									autoComplete={'off'}
									value={this.state.plazo}
									onKeyPress={EnteroSolo}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={8} lg={8} xl={8}>
								<Grid container spacing={2} alignItems={"flex-end"}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<div className={'vertical-inline h-100 w-100'}>
											<p style={{fontWeight: 'bold'}} className={'v-center text-black px-12'}>
												Señalar las Diferencias Mencionadas o Detalles que se manejaron en la reunión por parte de la autoridad para el contribuyente:
											</p>
										</div>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<div className={'vertical-inline h-100 w-100'}>
											<div className={'v-center h-100'}>
												<FormControlLabel
													className={'text-black px-12 text-bold'}
													control={
														<Checkbox
															type="checkbox"
															disabled={this.state.tipo === 'view'}
															onChange={this.handleChange}
															color="primary"
															name='check_url'
															checked={this.state.check_url}
															value="check_url"
														/>
													}
													label={<div style={{fontWeight: 'bold'}}>Agregar enlace de archivo (video).</div>}
												/>
											</div>
										</div>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<div className={'text-center borde-punteado-gray-1 padding-10'}>
									
									<ModalRecordatorios
										tipo={'add'}
										item={{}}
										componente={
											<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#3f50b6", width: 250}}>
												<SpeakerNotes style={{fontSize: 14, marginRight: 5}}/> Recordatorio
											</Fab>
										}
										RefreshList={this.props.RefreshList}
										showSnackBars={this.props.showSnackBars}
									/>
									
									<Typography variant={'h6'} className={'v-center text-gray px-12 padding-10 padding-15-T'}>
										{this.state.recordatorio || 'Registrar un recordatorio para tu entrevista.'}
									</Typography>
								
								</div>
							</Grid>
							
							{this.state.check_url ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<TextField
										className={'margin-0'}
										fullWidth
										onChange={this.handleChange}
										disabled={this.state.tipo === 'view'}
										type="text"
										margin="dense"
										name="url"
										label="URL"
										value={this.state.url}
									/>
								</Grid>
							) : null
							}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									className={'margin-0'}
									fullWidth
									multiline
									rows={2}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									name="diferencias"
									label="Diferencias"
									value={this.state.diferencias}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									className={'margin-0'}
									fullWidth
									multiline
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									rows={3}
									margin="dense"
									name="atencion"
									label="Señalar si algo llamo su atención"
									value={this.state.atencion}
								/>
							</Grid>
							
							{this.state.archivos.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
										Documento(s) Anexo:
									</Typography>
									<div className={'vertical-inline'}>
										{this.state.archivos.map((item, key) => (
											<div>
												<div className={'vertical-inline b-r-20 margin-5 margin-0-L'} key={key}>
													<img className={'v-center cursor-pointer'} src={DocSvg} alt="add" width={40} height={40} style={{opacity: 0.7, marginRight: '5px'}} onClick={() => this.descargarZip(item.archivo)}/>
													
													
													{this.props.tipo === 'edit' ? (<div className={'vertical-inline v-end b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
														<div className={'padding-3 v-end text-white cursor-pointer'}>
															{this.props.tipo === 'view' ? null : (
																<DeleteOutlined className={'px-16 text-white'} onClick={() => this.eliminar_documento(key)}/>
															)
															}
														</div>
													</div>) : null}
												</div>
											
											
											</div>
										))}
									</div>
								</Grid>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'dropzone-cronograma'}>
								<div className={'w-100'}>
									<DropzoneArea
										onChange={this.handleChangeDropzoneArea.bind(this)}
										open={this.state.open}
										//filesLimit={2}
										dropzoneText={'De ser posible sacar fotos a las Diferencias o pedirlas en PDF.'}
										//acceptedFiles={['text/xml', 'application/pdf']}
										showPreviews={false}
										maxFileSize={5000000}
										showAlerts={false}
										showFileNames={true}
										showFileNamesInPreview={true}
									/>
								</div>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_cita > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCita.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	lista_empresa: PropTypes.array.isRequired,
};

export default IntegrationNotistack(ModalCita);
