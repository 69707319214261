import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import ModalVacaciones from "./ModalVacaciones";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {VacacionesService} from "../../../../services/VacacionesService/VacacionesService";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import ModalEstatus from "./ModalEstatus";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {Description, FindInPage, ForwardOutlined} from "@material-ui/icons";
import {FacturacionService} from "../../../../services/FacturacionService/FacturacionService";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import ModalCancelar from "./ModalCancelar";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar la solicitud de vacaciones con id ${item.id_vacaciones}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				VacacionesService.Eliminar(item.id_vacaciones).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					
					this.props.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	
	spanStatus = (item) => {
		let bgg = 'white';
		switch (Number(item.id_cat_cronograma_estatus)) {
			case 1:
				bgg = 'gray';
				break;
			case 2:
				bgg = '#00c1ff';
				break;
			case 3:
				bgg = '#fcee15';
				break;
			case 4:
				bgg = '#c66500';
				break;
			case 5:
				bgg = '#119703';
				break;
			default:
				bgg = '#ccc';
				break;
		}
		
		const foto_default = CONFIG.foto_default;
		
		return (
			<Fragment>
				<div className={'w-100 text-white'} style={{borderRadius: '5px', background: bgg}}>
					{item.cronograma_estatus}
				</div>
			</Fragment>
		);
	};
	
	autorizar = (item) => {
		
		try {
			
			let msg = `¿Deseas autorizar la solicitud de vacaciones del trabajador: ${item.nombre_completo}?`;
			PopupService.Confirm(['Cancelar', 'Autorizar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
				if (r.button === 'Autorizar') {
					VacacionesService.Estatus(item).then((response) => {
						this.props.showSnackBars('success', response.mensaje);
						this.props.RefreshList();
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				}
			});
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	generarSolicitud = (item, archivo, auth) => {
		
		let filtro = {
			id_vacaciones: item.id_vacaciones || null,
		};
		HttpRequest.export('solicitudvacacionespdf', ['vacaciones'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, true, false, false, archivo === "PDF")
		
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'} className={'padding-10-L'}>Id</TableCell>
							<TableCell component="th" align={'left'}>Nombre del Trabajador</TableCell>
							<TableCell component="th" align={'left'}>Empresa</TableCell>
							<TableCell component="th" align={'center'}>Fecha Ingreso</TableCell>
							<TableCell component="th" align={'center'}>Año de Servicio</TableCell>
							<TableCell component="th" align={'center'}>Días Corresponden</TableCell>
							<TableCell component="th" align={'center'}>Días a Disfrutar</TableCell>
							<TableCell component="th" align={'center'}>Fecha Inicio</TableCell>
							<TableCell component="th" align={'center'}>Fecha Inicio Labores</TableCell>
							<TableCell component="th" align={'center'}>Fecha Elaboración</TableCell>
							<TableCell component="th" align={'left'}>Área</TableCell>
							<TableCell component="th" align={'left'}>Puesto</TableCell>
							<TableCell component="th" align={'left'} className={'w-85-px'}>Estatus</TableCell>
							{FieldsJs.inArray([1, 2, 7], this.Usr.id_cat_tipo_usuario) ? (
								<TableCell component="th" align={'left'} className={'w-85-px'}>Días Disponibles</TableCell>
							):null}
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							lista.map((item, key) => {
								return (
									<TableRow key={key}>
										<TableCell align={'left'} className={Number(item.estatus) === 4 ? 'padding-10-L text-tachado' : 'padding-10-L'}>{item.id_vacaciones}</TableCell>
										<TableCell align={'left'} className={Number(item.estatus) === 4 ? 'padding-10-L text-tachado' : 'padding-10-L'}>{item.nombre_completo}</TableCell>
										<TableCell align={'left'} className={Number(item.estatus) === 4 ? 'padding-10-L text-tachado' : 'padding-10-L'}>{item.razon_social}</TableCell>
										<TableCell align={'center'} className={Number(item.estatus) === 4 ? 'text-tachado' : ''}>{DateFormat.getFechaTextDiagonal(item.fecha_ingreso)}</TableCell>
										<TableCell align={'center'} className={Number(item.estatus) === 4 ? 'text-tachado' : ''}>{item.anio_servicio}</TableCell>
										<TableCell align={'center'} className={Number(item.estatus) === 4 ? 'text-tachado' : ''}>{item.dias_corresponden}</TableCell>
										<TableCell align={'center'} className={Number(item.estatus) === 4 ? 'text-tachado' : ''}>{item.dias_disfrutar}</TableCell>
										<TableCell align={'center'} className={Number(item.estatus) === 4 ? 'text-tachado' : ''}>{DateFormat.getFechaTextDiagonal(item.fecha_inicio)}</TableCell>
										<TableCell align={'center'} className={Number(item.estatus) === 4 ? 'text-tachado' : ''}>{DateFormat.getFechaTextDiagonal(item.fecha_inicio_labores)}</TableCell>
										<TableCell align={'center'} className={Number(item.estatus) === 4 ? 'text-tachado' : ''}>{DateFormat.getFechaTextDiagonal(item.fecha_elaboracion)}</TableCell>
										<TableCell align={'left'} className={Number(item.estatus) === 4 ? 'text-tachado' : ''}>{item.area || 'Sin registrar'}</TableCell>
										<TableCell align={'left'} className={Number(item.estatus) === 4 ? 'text-tachado' : ''}>{item.puesto || 'Sin registrar'}</TableCell>
										<TableCell align={'left'} className={Number(item.estatus) === 4 ? 'text-tachado' : ''}>
											<span style={{backgroundColor: Number(item.estatus) === 2 ? "#3f51b5":Number(item.estatus) === 3 ? "#006300":"",color: Number(item.estatus) === 2 || Number(item.estatus) === 3 ? "white":"#3e3e3e", borderRadius: 10, padding: "2px 5px 2px 5px"}} className={ Number(item.estatus) === 4 ? 'text-tachado w-85-px':'w-85-px'}>
												{item.estatus_text || 'Sin registrar'}
											</span>
										</TableCell>
										{FieldsJs.inArray([1, 2, 7], this.Usr.id_cat_tipo_usuario) ? (
											<TableCell align={'left'}>
												{item.dias_disponibles || '-'}
											</TableCell>
										):null}
										<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
											<div className={'w-auto vertical-inline'}>
												
												<div className={'v-center'}>
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Solicitud PDF">
														<IconButton aria-label="Solicitud PDF" onClick={() => this.generarSolicitud(item)}><Description/></IconButton>
													</Tooltip>
												</div>
												
												{this.Usr.isjefe && Number(item.estatus) === 1 && Number(item.id_usuario ) !== Number(this.Usr.id_usuario) || FieldsJs.inArray([1, 2, 7], this.Usr.id_cat_tipo_usuario) && Number(item.estatus) < 3 ? (
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Autorizar">
															<IconButton aria-label="Autorizar" onClick={() => this.autorizar(item)}>
																<ForwardOutlined/>
															</IconButton>
														</Tooltip>
													</div>
												) : null}
												
												{this.Usr.isjefe && Number(item.estatus) === 1 && Number(item.id_usuario ) !== Number(this.Usr.id_usuario) || FieldsJs.inArray([1, 2, 7], this.Usr.id_cat_tipo_usuario) && Number(item.estatus) < 3 ? (
													<div className={'v-center'}>
														<ModalCancelar
															tipo={'edit'}
															item={item}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												) : null}
												
												{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) || Number(this.Usr.id_usuario) === Number(item.id_usuario) ? (
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
															<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}><DeleteOutlined/></IconButton>
														</Tooltip>
													</div>
												) : ''}
												
												{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<ModalVacaciones
															tipo={'edit'}
															item={item}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
															lista_empresa={this.props.lista_empresa}
														/>
													</div>
												) : ''}
												
												<div className={'v-center'}>
													<ModalVacaciones
														tipo={'view'}
														item={item}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
														lista_empresa={this.props.lista_empresa}
													/>
												</div>
											</div>
										
										</TableCell>
									
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
