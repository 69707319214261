import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const EstadosCuentaService = {
	Listar: (filtro, paginacion) => {
		let fecha_alta = (filtro.fecha_alta || null) ? DateFormat.FormatSql(filtro.fecha_alta) : null;
		
		let params = {
			filtro: {
				id_estados_cuenta: filtro.id_estados_cuenta || null,
				id_empresa: filtro.id_empresa || null,
				ejercicio: filtro.ejercicio || null,
				periodo: filtro.periodo || null,
				id_cat_tipo_documento_estados_cuenta: filtro.id_cat_tipo_documento_estados_cuenta || null,
				id_cat_banco: filtro.id_cat_banco || null,
				fecha_alta: fecha_alta || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Estados_Cuenta_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_vigencia = form.fecha_vigencia ? DateFormat.FormatSql(form.fecha_vigencia) : null;
		
		let params = {
			id_estados_cuenta: null,
			id_empresa: form.id_empresa,
			ejercicio: form.ejercicio,
			periodo: form.periodo,
			id_cat_tipo_documento_estados_cuenta: form.id_cat_tipo_documento_estados_cuenta,
			id_cat_banco: form.id_cat_banco,
			fecha_vigencia: fecha_vigencia,
			observaciones: form.observaciones,
			importe: form.importe,
			recargo: form.recargo,
			archivo: form.archivo,
			archivo_formato:  form.archivo_formato,
			archivo_nombre:  form.archivo_nombre,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Estados_Cuenta_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_vigencia = form.fecha_vigencia ? DateFormat.FormatSql(form.fecha_vigencia) : null;
		
		let params = {
			id_estados_cuenta: form.id_estados_cuenta,
			id_empresa: form.id_empresa,
			ejercicio: form.ejercicio,
			periodo: form.periodo,
			id_cat_tipo_documento_estados_cuenta: form.id_cat_tipo_documento_estados_cuenta,
			id_cat_banco: form.id_cat_banco,
			fecha_vigencia: fecha_vigencia,
			observaciones: form.observaciones,
			importe: form.importe,
			recargo: form.recargo,
			archivo: form.archivo,
			archivo_formato:  form.archivo_formato,
			archivo_nombre:  form.archivo_nombre,
			activo: form.activo ? 1 : 0,
		};
		
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Estados_Cuenta_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_estados_cuenta) => {
		let params = {
			id_estados_cuenta: id_estados_cuenta
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Estados_Cuenta_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ComprobantePago: (form) => {
		let params = {
			id_estados_cuenta: form.id_estados_cuenta,
			observaciones: form.observaciones,
			comprobante_pago: form.comprobante_pago || 0,
			archivos: form.archivos
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Estados_Cuenta_Comprobante_Pago', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SendMail: (form) => {
		let params = {
			id_estados_cuenta: form.id_estados_cuenta,
			id_usuario: form.id_usuario,
			mensaje: form.mensaje,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Estados_Cuenta_SendRocketChat', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
