import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {ClientePotencialService} from '../../../../services/ClientePotencialService/ClientePotencialService';
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";

import {CatService} from "../../../../services/_Cat/CatService/CatService";
import Divider from "@material-ui/core/Divider";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import {PlaylistAddOutlined} from "@material-ui/icons";
import ModalCliente from "../../Cliente/Includes/ModalCliente";
import {ClienteService} from "../../../../services/ClienteService/ClienteService";


class ModalClientePotencial extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			scroll: 'paper',
			list_cat_giro_cliente: [],
			id_cliente: props.id_cliente || 0,
			tipo: props.tipo,
			
			nombre_comercial: '',
			razon_social: '',
			rfc: '',
			correo_electronico: '',
			telefono: '',
			id_cat_giro_cliente: '',
			activo: 1,
			
			listar_cat_cliente: []
		};
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({
			listar_cat_cliente: this.props.listaClientes || []
		})
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	listGiroCliente = () => {
		CatService.ListGiroCliente().then((response) => {
			this.setState({list_cat_giro_cliente: response.data});
		})
	};
	
	
	save = () => {
		if (this.state.id_cliente > 0) {
			ClientePotencialService.Modificar(this.state).then((response) => {
				this.modalClose();
				this.props.$BroadcastModalClientePotencial({
					accion: 'list',
					openmodal: false,
					status: true
				});
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			ClientePotencialService.Agregar(this.state).then((response) => {
				this.modalClose();
				this.props.$BroadcastModalClientePotencial({
					accion: 'list',
					openmodal: true,
					status: true
				});
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		
		console.log(this.props);
		
		this.listGiroCliente();
		
		this.setState({
			id_cliente: '',
			nombre_comercial: '',
			razon_social: '',
			rfc: '',
			correo_electronico: '',
			telefono: '',
			id_cat_giro_cliente: '',
			select_cliente: null,
			activo: true
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {id_cliente, item} = this.props;
		
		console.log(this.props);
		
		this.listGiroCliente();
		
		this.setState({
			id_cliente: id_cliente,
			nombre_comercial: item.nombre_comercial,
			razon_social: item.razon_social,
			rfc: item.rfc,
			correo_electronico: item.correo_electronico,
			telefono: item.telefono,
			id_cat_giro_cliente: item.id_cat_giro_cliente,
			activo: (item.activo === 1)
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {id_cliente, item} = this.props;
		
		console.log(this.props);
		
		this.listGiroCliente();
		
		this.setState({
			id_cliente: id_cliente,
			nombre_comercial: item.nombre_comercial,
			razon_social: item.razon_social,
			rfc: item.rfc,
			correo_electronico: item.correo_electronico,
			telefono: item.telefono,
			id_cat_giro_cliente: item.id_cat_giro_cliente,
			activo: (item.activo === 1)
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_cliente > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Fragment>
						<MenuItem button={true} onClick={() => this.edit()}>
							<ListItemIcon>
								<EditOutlined/>
							</ListItemIcon>
							<Typography variant="inherit" noWrap>
								Editar
							</Typography>
						</MenuItem>
					</Fragment>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Fragment>
						<MenuItem button={true} onClick={() => this.view()}>
							<ListItemIcon>
								<SearchOutlined/>
							</ListItemIcon>
							<Typography variant="inherit" noWrap>
								Detalles
							</Typography>
						</MenuItem>
					</Fragment>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	onTagsChange = (event, value) => {
		let select = FieldsJs.Copy(value);
		
		if (select) {
			try {
				
				this.setState({
					select_cliente: select,
					nombre_comercial: select.nombre_comercial,
					razon_social: select.razon_social,
					rfc: select.rfc,
					correo_electronico: select.correo_electronico,
					telefono: select.telefono,
					id_cat_giro_cliente: select.id_cat_giro_cliente,
				}, () => console.log("STATE", select, this.state));
				
			} catch (error) {
				this.showSnackBars('error', error.mensaje);
			}
		} else {
			try {
				
				this.setState({
					select_cliente: '',
					nombre_comercial: '',
					razon_social: '',
					rfc: '',
					correo_electronico: '',
					telefono: '',
					id_cat_giro_cliente: '',
				});
				
			} catch (error) {
				this.showSnackBars('error', error.mensaje);
			}
		}
	}
	
	titulo = () => {
		let title = '';
		switch (this.state.tipo) {
			case 'edit':
				title += 'Actualizar';
				break;
			case 'view':
				title += 'Detalles';
				break;
			case 'add':
				title += 'Agregar';
				break;
			default:
				break;
		}
		switch (this.props.tipo_vista) {
			case 'cliente_potencial':
				title += ' cliente potencial';
				break;
			case 'cliente_cliente':
				title += ' cliente empresa';
				break;
			default:
				break;
		}
		return title;
	};
	
	ListaClientes = () => {
		return new Promise((resolve, reject) => {
			ClienteService.ListarTodo().then((response) => {
				this.setState({
					listar_cat_cliente: response.data,
				});
				resolve(response.data);
			}).catch((error) => {
				this.setState({
					listar_cat_cliente: []
				});
				reject(false);
			});
		});
	};
	
	RefreshListClientes = (id_cliente) => {
		if (id_cliente > 0) {
			this.ListaClientes().then(r => {
				let cliente='';
				for (let i = 0; i < r.length; i++) {
					if (Number(r[i].id_cliente) === Number(id_cliente)) {
						cliente = r[i];
					}
				}
				
				console.log('CLIENTES:', r, 'ID CLIENTE: ', id_cliente, 'DATOS CLIENTE: ', cliente);
				this.setState({
					select_cliente: cliente,
					nombre_comercial: cliente.nombre_comercial,
					razon_social: cliente.razon_social,
					rfc: cliente.rfc,
					correo_electronico: cliente.correo_electronico,
					telefono: cliente.telefono,
					id_cat_giro_cliente: cliente.id_cat_giro_cliente,
				})
			}).catch(e => {
				console.log(e);
			});
		}
	};
	
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		const title = this.titulo();
		
		const filterOptions = createFilterOptions({
			matchFrom: 'start',
			stringify: option => option.rfc,
		});
		
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						{title}
					</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							{this.state.tipo === 'add' ?
								<Fragment>
									<Grid item xs={12} sm={12} md={9} lg={11} xl={11}>
										<Autocomplete
											id="filter-demo"
											className={'margin-0'}
											options={this.state.listar_cat_cliente}
											getOptionLabel={option => option.rfc +
												( option.id_cat_tipo_persona === 2 ? option.razon_social ? " - " + option.razon_social : option.nombre_comercial :
													" - " + option.nombre+' '+option.apellido_paterno+' '+option.apellido_materno )}
											filterOptions={filterOptions}
											onChange={this.onTagsChange}
											name="select_cliente"
											renderInput={params =>
												<TextField
													{...params}
													label="Seleccionar cliente"
													fullWidth
													margin="dense"
													disabled={this.state.tipo === 'view'}
													variant="outlined"/>
											}
											value={this.state.select_cliente || null}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={3} lg={1} xl={1}>
										<div className={'vertical-inline h-100'}>
											<div className={'v-center'}>
												<ModalCliente
													tipo={'add'}
													item={{}}
													componente={(
														<Tooltip
															TransitionComponent={Zoom}
															placement={"right"}
															title="Agregar nuevo cliente potencial"
															children={(
																<Fab
																	color="primary"
																	size="small"
																	aria-label="Agregar nuevo cliente potencial"
																	children={(
																		<PlaylistAddOutlined/>
																	)}
																/>
															)}
														/>
													)}
													RefreshList={this.RefreshListClientes}
													showSnackBars={this.props.showSnackBars}/>
											</div>
										</div>
									</Grid>
								</Fragment>
								: null}
							
							
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									required
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view' || this.state.tipo === 'add'}
									type="text"
									margin="dense"
									helperText="Requerido"
									name="nombre_comercial"
									label="Nombre comercial"
									value={this.state.nombre_comercial}
								/>
							
							</Grid>
							
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								
								<TextField
									required
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view' || this.state.tipo === 'add'}
									type="text"
									margin="dense"
									helperText="Requerido"
									name="razon_social"
									label="Razón social"
									value={this.state.razon_social}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									required
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view' || this.state.tipo === 'add'}
									type="text"
									margin="dense"
									helperText="Requerido"
									name="rfc"
									label="R.F.C."
									value={this.state.rfc}
									inputProps={{maxLength: 13}}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								
								<TextField
									required
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view' || this.state.tipo === 'add'}
									type="text"
									margin="dense"
									helperText="Requerido"
									name="correo_electronico"
									label="Correo electrónico"
									value={this.state.correo_electronico}
								/>
							
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								
								<TextField
									required
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view' || this.state.tipo === 'add'}
									type="text"
									margin="dense"
									helperText="Requerido"
									name="telefono"
									label="Teléfono"
									value={this.state.telefono}
									inputProps={{maxLength: 10}}
									onKeyPress={EnteroSolo}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									required
									className={'margin-0'}
									select
									fullWidth
									margin="dense"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu,
										},
									}}
									
									helperText="Requerido"
									name="id_cat_giro_cliente"
									label="Giro de la empresa"
									value={this.state.id_cat_giro_cliente}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.list_cat_giro_cliente.map(option => (
										<option key={option.id_cat_giro_cliente} value={option.id_cat_giro_cliente}>
											{option.giro_cliente}
										</option>
									))}
								</TextField>
							
							</Grid>
							
							{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox type="checkbox" name='activo' checked={this.state.activo}
											          onChange={this.handleChange} value="activo" color="primary"
											          disabled={this.state.tipo === 'view'}/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>*/}
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={this.modalClose} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={this.save} color="primary">
								{this.state.id_cliente > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalClientePotencial.propTypes = {
	tipo_vista: PropTypes.string.isRequired,
	id_cliente: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	listaClientes: PropTypes.array,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object,
	$BroadcastModalClientePotencial: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default IntegrationNotistack(ModalClientePotencial);
