import React, {Component, Fragment} from "react";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import {FichaTecnicaService} from "../../../../services/FichaTecnicaService/FichaTecnicaService";
import {ClientePotencialService} from '../../../../services/ClientePotencialService/ClientePotencialService';
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import moment from "moment";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {Icono} from "../../../Include/Icono/Icono";
import ModalImage from "../../../Include/ModalImage/ModalImage";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import {CONFIG} from "../../../../settings/Config/Config";
import ModalSeleccionarArchivoExpedienteTrabajador from "../../FichaTecnica/Includes/ModalSeleccionarArchivoExpedienteTrabajador";
import icon_expediente from "../../../../assets/img/icons/expediente.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import * as PropTypes from "prop-types";
import {UsuariosService} from "../../../../services/_Sis/UsuariosService/UsuariosService";
import {PlazaService} from "../../../../services/_Cat/Plaza/PlazaService";
import ModalComentarioResguardo from "./includes/ModalComentarioResguardo";

class ContratosIndividualesElaborados extends Component {
	
	state = {};
	
	Usr = {};
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			id_cliente: props.id_cliente,
			
			cliente: {},
			
			id_ficha_tecnica: '',
			id_cat_ficha_tecnica_status: '',
			
			actividad_ante_sat: '',
			rfc: '',
			servicio_requerido_propuesto: '',
			ficha_tecnica_archivo_elaboracion_propuesta: [],
			ficha_tecnica_archivo_integracion_expediente: [],
			ficha_tecnica_archivo_propuesta_fiscal: [],
			ficha_tecnica_expediente_trabajador: [],
			
			id_plaza_pertenece_cliente: '',
			id_plaza_opera_nomina: '',
			
			sueldo_salario: false,
			cooperativa: false,
			honorarios: false,
			sociedad_civil: false,
			asimilados_salario: false,
			outsourcing: false,
			otros: '',
			
			id_cat_periodo_pago: '',
			impuesto_sobre_nomina: 0,
			
			otros_impuestos: false,
			otros_impuestos_nombre: '',
			otros_impuestos_porcentaje: '',
			
			ficha_tecnica_otro_impuesto: [],
			
			id_cat_factor_riesgo_imss: '',
			id_cat_estado_entidad_trabajo: '',
			id_cat_municipio_entidad_trabajo: '',
			
			cooperativa_alta_imss: false,
			cooperativa_porcentaje_comision_paga_trabajador: '',
			
			trabajador_entrega_recibo_honorarios: false,
			pago_realiza_fuera_es_no_deducible: false,
			outsourcing_porcentaje_paga_servicio: '',
			asimilados_hace_retencion_isr: false,
			tienen_otras_prestaciones_aparte_salario: false,
			
			vacaciones_ley_o_especiales_cuantos_dias: '',
			prima_vacacional_ley_o_especiales_cuantos_dias: '',
			gratificacion_anual_ley_o_especiales_cuantos_dias: '',
			
			ficha_tecnica_prestacion: [],
			
			comentario_adicional_elaboracion_propuesta: '',
			
			lista_cat_factor_riesgo_imss: [],
			lista_cat_periodo_pago: [],
			lista_plazas: [],
			lista_cat_estado: [],
			lista_cat_municipio: [],
			lista_cat_banco: [],
			lista_cat_promotores: [],
			
			cliente_cancelo: false,
			cliente_acepto: false,
			cliente_requiere_nueva_propuesta: false,
			porque_requiere_nueva_propuesta: '',
			
			fecha_firma_contrato: null,
			
			constancia_de_situacion_fiscal_base64: '',
			constancia_de_situacion_fiscal_formato: '',
			constancia_de_situacion_fiscal: '',
			
			comprobante_de_domicilio_base64: '',
			comprobante_de_domicilio_formato: '',
			comprobante_de_domicilio: '',
			
			acta_constitutiva_de_la_cliente_base64: '',
			acta_constitutiva_de_la_cliente_formato: '',
			acta_constitutiva_de_la_cliente: '',
			
			poder_del_representante_legal_base64: '',
			poder_del_representante_legal_formato: '',
			poder_del_representante_legal: '',
			
			identificacion_del_repesentante_legal_base64: '',
			identificacion_del_repesentante_legal_formato: '',
			identificacion_del_repesentante_legal: '',
			
			fecha_alta_imss: null,
			confirmacion_fecha_primera_dispersion: null,
			id_cat_periodo_pago_tipo_nomina: '',
			comision_propuesta: '',
			front_nombre_banco: '',
			front_cantidad_empleado: '',
			ficha_tecnica_cantidad_empleado_banco: [],
			iva: '',
			retencion_isr_en_caso_de_ias: '',
			retencion_isn_en_caso_de_ias: '',
			
			
			lista_ejecutivo_asignado_tesoreria: [],
			id_ejecutivo_asignado_tesoreria: '',
			
			cat_tipo_pago: [],
			cliente_que_paga: [],
			ep_cliente_que_paga: '',
			ep_tipo_pago: '',
			ep_id_ejecutivo_asignado: '',
			// ep_id_cat_tipo_pago: '',
			
			cliente_que_factura: [],
			ep_cliente_que_factura: '',
			ep_porcentaje: '',
			
			/* J U R I D I C O */
			total_contratos_individuales: '',
			total_contratos_individuales_elaborados: '',
			total_contratos_individuales_pendientes: '',
			
			total_contratos_individuales_ias: '',
			total_contratos_individuales_elaborados_ias: '',
			total_contratos_individuales_pendientes_ias: '',
			
			cantidad_elaborado: '',
			cantidad_elaborado_firmado: '',
			
			excel_elaborado_base64: '',
			excel_elaborado_base64Tipo: '',
			excel_elaborado_archivo: '',
			excel_elaborado_formato: '',
			
			excel_pendiente_base64: '',
			excel_pendiente_base64Tipo: '',
			excel_pendiente_archivo: '',
			excel_pendiente_formato: '',
			
			excel_elaborado_base64_firmado: '',
			excel_elaborado_base64Tipo_firmado: '',
			excel_elaborado_archivo_firmado: '',
			excel_elaborado_formato_firmado: '',
			
			excel_pendiente_base64_firmado: '',
			excel_pendiente_base64Tipo_firmado: '',
			excel_pendiente_archivo_firmado: '',
			excel_pendiente_formato_firmado: '',
			
			comentario_general: '',
			comentario_general_firmado: '',
			
			cliente_contratos_individuales: [],
			cliente_contratos_individuales_ias: [],
			
			cliente_contratos_individuales_firmados: [],
			cliente_contratos_individuales_ias_firmados: [],
			
			comentario_juridico_contratos_individuales: '',
			
			cliente_agendar_firma_contrato: [],
			
			
			concepto_de_facturacion: '',
			id_cat_banco_deposita_cliente: '',
			id_cat_banco_depositos_personal: '',
			datos_especiales_para_la_operacion: '',
			promotor_id_usuario_nombre_completo: '',
			promotor_no_cuenta_clabe_interbancaria: '',
			promotor_id_cat_banco: '',
			promotor_comision: '',
			promotor_telefono: '',
			promotor_celular: '',
			promotor_correo_electronico: '',
			promotor_comentarios_especiales_manejo_cuenta_cliente: '',
			
			
			notas: false, // MOSTRAR BLOQUE DE NOTAS PARA EL DESARROLLADOR
			mostrar_notas_desarrollador: false, // MOSTRAR PARA EL DESAROOLLADOR
			comentario_observacion_desarrollador: '', // COMENTARIO PARA EL DESAROOLLADOR
			ficha_tecnica_historial_accion_usuario: [], // HISTORIAL PARA EL DESAROOLLADOR
			
			siroc_active: false,
			siroc: false,
			
			esquema: '0',
			esquema_firmado: '0',
			
			lugar_resguardo: '',
			id_responsable_resguardo: '',
			listar_usuarios: [],
			listar_plazas: [],
			id_plaza_resguardo: '',
			
			valuetab: '1'
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	handleChangeTabs = (e, variable, campo, date, input) => {
		console.log(e, variable);
		this.setState({
			valuetab: variable
		});
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	init = () => {
		this.ListaPlazas();
		this.ListaUsuario();
		return new Promise((resolve, reject) => {
			FichaTecnicaService.InitDato(this.state.id_cliente).then((response) => {
				
				let dato = FieldsJs.Copy(response.data);
				
				if (response.visible === 0) {
					
					this.setState({
						visible: 0,
						mensaje: response.message
					});
					
				} else {
					
					let cliente = dato.cliente || {};
					let ficha_tecnica_prestacion = dato.ficha_tecnica_prestacion;
					let ft = dato.ficha_tecnica || {};
					let ficha_tecnica_archivo_elaboracion_propuesta = dato.ficha_tecnica_archivo_elaboracion_propuesta;
					let ficha_tecnica_archivo_integracion_expediente = dato.ficha_tecnica_archivo_integracion_expediente;
					let ficha_tecnica_archivo_propuesta_fiscal = dato.ficha_tecnica_archivo_propuesta_fiscal;
					let ficha_tecnica_otro_impuesto = dato.ficha_tecnica_otro_impuesto;
					let ficha_tecnica_historial_accion_usuario = dato.ficha_tecnica_historial_accion_usuario || [];
					let ficha_tecnica_expediente_trabajador = dato.ficha_tecnica_expediente_trabajador || [];
					let lista_ejecutivo_asignado_tesoreria = dato.ejecutivo_asignado_tesoreria || [];
					let lista_esquema = dato.esquemas || [];
					let ficha_tecnica_cantidad_empleado_banco = dato.ficha_tecnica_cantidad_empleado_banco || [];
					let cliente_contratos_individuales = dato.cliente_contratos_individuales || [];
					let cliente_contratos_individuales_firmados = dato.cliente_contratos_individuales_firmados || [];
					let cliente_agendar_firma_contrato = dato.cliente_agendar_firma_contrato || [];
					let cat_tipo_pago = dato.cat_tipo_pago || [];
					
					let id_plaza_pertenece_cliente = '';
					if (FieldsJs.Array(dato.plaza_pertenece_cliente)) {
						id_plaza_pertenece_cliente = dato.plaza_pertenece_cliente.id_plaza;
					}
					
					let id_plaza_opera_nomina = '';
					if (FieldsJs.Array(dato.plaza_opera_nomina)) {
						id_plaza_opera_nomina = dato.plaza_opera_nomina.id_plaza;
					}
					
					for (let i = 0; i < ficha_tecnica_prestacion.length; i++) {
						var it = ficha_tecnica_prestacion[i];
						it.porcentaje = it.porcentaje || '';
						it.pago_efectivo = (it.pago_efectivo === 1);
						it.vales = (it.vales === 1);
						it.prestaciones = (it.prestaciones === 1);
						it.frecuencia = (it.frecuencia === 1);
						it.fijo_variable = it.fijo_variable || '';
						it.integra_sbc = (it.integra_sbc === 1);
					}
					
					this.setState({
						
						cliente: dato.cliente,
						
						id_ficha_tecnica: ft.id_ficha_tecnica ? Number(ft.id_ficha_tecnica) : '',
						id_cat_ficha_tecnica_status: ft.id_cat_ficha_tecnica_status ? Number(ft.id_cat_ficha_tecnica_status) : '',
						
						actividad_ante_sat: ft.actividad_ante_sat || '',
						rfc: ft.rfc || dato.cliente.rfc || '',
						servicio_requerido_propuesto: ft.servicio_requerido_propuesto || '',
						ficha_tecnica_archivo_elaboracion_propuesta: ficha_tecnica_archivo_elaboracion_propuesta || [],
						ficha_tecnica_archivo_integracion_expediente: ficha_tecnica_archivo_integracion_expediente || [],
						ficha_tecnica_archivo_propuesta_fiscal: ficha_tecnica_archivo_propuesta_fiscal || [],
						ficha_tecnica_expediente_trabajador: ficha_tecnica_expediente_trabajador || [],
						lista_esquema: lista_esquema || [],
						
						id_plaza_pertenece_cliente: id_plaza_pertenece_cliente || '',
						id_plaza_opera_nomina: id_plaza_opera_nomina || '',
						
						sueldo_salario: (ft.sueldo_salario === 1),
						cooperativa: (ft.cooperativa === 1),
						honorarios: (ft.honorarios === 1),
						sociedad_civil: (ft.sociedad_civil === 1),
						asimilados_salario: (ft.asimilados_salario === 1),
						outsourcing: (ft.outsourcing === 1),
						otros: ft.otros || '',
						
						id_cat_periodo_pago: ft.id_cat_periodo_pago || '',
						impuesto_sobre_nomina: ft.impuesto_sobre_nomina || 0,
						
						otros_impuestos: (ficha_tecnica_otro_impuesto.length > 0),
						otros_impuestos_nombre: '',
						otros_impuestos_porcentaje: '',
						
						ficha_tecnica_otro_impuesto: ficha_tecnica_otro_impuesto || [],
						
						id_cat_factor_riesgo_imss: ft.id_cat_factor_riesgo_imss || '',
						id_cat_estado_entidad_trabajo: ft.id_cat_estado_entidad_trabajo || '',
						id_cat_municipio_entidad_trabajo: ft.id_cat_municipio_entidad_trabajo || '',
						
						cooperativa_alta_imss: (ft.cooperativa_alta_imss === 1),
						cooperativa_porcentaje_comision_paga_trabajador: ft.cooperativa_porcentaje_comision_paga_trabajador || '',
						
						trabajador_entrega_recibo_honorarios: (ft.trabajador_entrega_recibo_honorarios === 1),
						pago_realiza_fuera_es_no_deducible: (ft.pago_realiza_fuera_es_no_deducible === 1),
						outsourcing_porcentaje_paga_servicio: ft.outsourcing_porcentaje_paga_servicio || '',
						asimilados_hace_retencion_isr: (ft.asimilados_hace_retencion_isr === 1),
						tienen_otras_prestaciones_aparte_salario: (ft.tienen_otras_prestaciones_aparte_salario === 1),
						
						vacaciones_ley_o_especiales_cuantos_dias: ft.vacaciones_ley_o_especiales_cuantos_dias || '',
						prima_vacacional_ley_o_especiales_cuantos_dias: ft.prima_vacacional_ley_o_especiales_cuantos_dias || '',
						gratificacion_anual_ley_o_especiales_cuantos_dias: ft.gratificacion_anual_ley_o_especiales_cuantos_dias || '',
						
						ficha_tecnica_prestacion: ficha_tecnica_prestacion || [],
						
						comentario_adicional_elaboracion_propuesta: ft.comentario_adicional_elaboracion_propuesta || '',
						
						lista_cat_factor_riesgo_imss: dato.cat_factor_riesgo_imss,
						lista_cat_periodo_pago: dato.cat_periodo_pago,
						lista_plazas: dato.plazas,
						lista_cat_estado: dato.cat_estado,
						lista_cat_municipio: dato.cat_municipio,
						lista_cat_banco: dato.cat_banco,
						lista_cat_promotores: dato.cat_promotores,
						
						
						fecha_firma_contrato: ft.fecha_firma_contrato ? moment(ft.fecha_firma_contrato) : null,
						
						constancia_de_situacion_fiscal_base64: '',
						constancia_de_situacion_fiscal_formato: (ft.constancia_de_situacion_fiscal || '').split('.')[1],
						constancia_de_situacion_fiscal: ft.constancia_de_situacion_fiscal,
						
						comprobante_de_domicilio_base64: '',
						comprobante_de_domicilio_formato: (ft.comprobante_de_domicilio || '').split('.')[1],
						comprobante_de_domicilio: ft.comprobante_de_domicilio,
						
						acta_constitutiva_de_la_cliente_base64: '',
						acta_constitutiva_de_la_cliente_formato: (ft.acta_constitutiva_de_la_cliente || '').split('.')[1],
						acta_constitutiva_de_la_cliente: ft.acta_constitutiva_de_la_cliente,
						
						poder_del_representante_legal_base64: '',
						poder_del_representante_legal_formato: (ft.poder_del_representante_legal || '').split('.')[1],
						poder_del_representante_legal: ft.poder_del_representante_legal,
						
						identificacion_del_repesentante_legal_base64: '',
						identificacion_del_repesentante_legal_formato: (ft.identificacion_del_repesentante_legal || '').split('.')[1],
						identificacion_del_repesentante_legal: ft.identificacion_del_repesentante_legal,
						
						fecha_alta_imss: ft.fecha_alta_imss ? moment(ft.fecha_alta_imss) : null,
						confirmacion_fecha_primera_dispersion: ft.confirmacion_fecha_primera_dispersion ? moment(ft.confirmacion_fecha_primera_dispersion) : null,
						id_cat_periodo_pago_tipo_nomina: ft.id_cat_periodo_pago_tipo_nomina || '',
						comision_propuesta: ft.comision_propuesta || '',
						ficha_tecnica_cantidad_empleado_banco: ficha_tecnica_cantidad_empleado_banco || [],
						iva: ft.iva || '',
						retencion_isr_en_caso_de_ias: ft.retencion_isr_en_caso_de_ias || '',
						retencion_isn_en_caso_de_ias: ft.retencion_isn_en_caso_de_ias || '',
						
						
						lista_ejecutivo_asignado_tesoreria: lista_ejecutivo_asignado_tesoreria,
						id_ejecutivo_asignado_tesoreria: cliente.id_ejecutivo_asignado_tesoreria || '',
						
						cat_tipo_pago: cat_tipo_pago,
						cliente_que_paga: dato.cliente_que_paga || [],
						ep_cliente_que_paga: '',
						ep_tipo_pago: '',
						ep_id_ejecutivo_asignado: '',
						// ep_id_cat_tipo_pago: '',
						
						cliente_que_factura: dato.cliente_que_factura || [],
						ef_cliente_que_factura: '',
						ef_clave_producto_o_servicio: '',
						ef_concepto: '',
						ef_porcentaje: '',
						// ef_id_ejecutivo_asignado: '',
						
						/* J U R I D I C O */
						total_contratos_individuales: cliente.total_contratos_individuales || '',
						total_contratos_individuales_elaborados: '',
						total_contratos_individuales_pendientes: '',
						
						total_contratos_individuales_ias: cliente.total_contratos_individuales_ias || '',
						total_contratos_individuales_elaborados_ias: '',
						total_contratos_individuales_pendientes_ias: '',
						cantidad_elaborado: '',
						cantidad_elaborado_firmado: '',
						
						excel_elaborado_base64: '',
						excel_elaborado_base64Tipo: '',
						excel_elaborado_archivo: '',
						excel_elaborado_formato: '',
						
						excel_pendiente_base64: '',
						excel_pendiente_base64Tipo: '',
						excel_pendiente_archivo: '',
						excel_pendiente_formato: '',
						
						comentario_general: '',
						
						cliente_contratos_individuales: cliente_contratos_individuales || [],
						cliente_contratos_individuales_firmados: cliente_contratos_individuales_firmados || [],
						
						comentario_juridico_contratos_individuales: cliente.comentario_juridico_contratos_individuales || '',
						
						cliente_agendar_firma_contrato: cliente_agendar_firma_contrato || [],
						
						
						siroc_active: ((ft.sueldo_salario === 1) || (ft.outsourcing === 1)),
						siroc: (Number(ft.siroc) === 1),
						
						comentario_observacion_desarrollador: '', // COMENTARIO PARA EL DESAROOLLADOR
						ficha_tecnica_historial_accion_usuario: ficha_tecnica_historial_accion_usuario, // HISTORIAL PARA EL DESAROOLLADOR
						
					});
					
				}
				
				setTimeout(() => this.openModal());
				
				resolve(true);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
				reject(false);
			});
		});
	};
	
	ListaPlazas = () => {
		PlazaService.Listar().then((response) => {
			this.setState({
				listar_plazas: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_plazas: [],
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaUsuario = () => {
		let filtro = {
			usuario: null,
			correo_electronico: null,
			id_cat_tipo_usuario: null,
			activo: 1,
		};
		let paginacion = {
			total: null,
			page: null,
			limit: null
		};
		UsuariosService.Listar(filtro, paginacion).then((response) => {
			this.setState({
				listar_usuarios: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_usuarios: [],
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	close = () => {
		this.closeModal();
	};
	
	agregar_cliente_contratos_individuales = () => {
		
		try {
			
			let total_contratos_individuales = Number(this.state.total_contratos_individuales);
			let total_contratos_individuales_ias = Number(this.state.total_contratos_individuales_ias);
			
			let arr_temp = FieldsJs.Copy(this.state.cliente_contratos_individuales);
			
			let cantidad_elaborado = Number(this.state.cantidad_elaborado);
			let esquema = Number(this.state.esquema);
			
			let excel_elaborado_base64 = this.state.excel_elaborado_base64;
			let excel_elaborado_base64Tipo = this.state.excel_elaborado_base64Tipo;
			let excel_elaborado_archivo = this.state.excel_elaborado_archivo;
			let excel_elaborado_formato = this.state.excel_elaborado_formato;
			
			let excel_pendiente_base64 = this.state.excel_pendiente_base64;
			let excel_pendiente_base64Tipo = this.state.excel_pendiente_base64Tipo;
			let excel_pendiente_archivo = this.state.excel_pendiente_archivo;
			let excel_pendiente_formato = this.state.excel_pendiente_formato;
			
			let comentario_general = this.state.comentario_general;
			
			let suma_total_contrato_temporal = (this.total_contratos_confirmados() + cantidad_elaborado);
			let suma_total_contrato_temporal_ias = (this.total_contratos_confirmados_ias() + cantidad_elaborado);
			
			if (!total_contratos_individuales) {
				throw Object({
					status: false,
					mensaje: "Coloca el total de contratos individuales"
				});
			}
			
			if (!esquema) {
				if (suma_total_contrato_temporal > total_contratos_individuales) {
					throw Object({
						status: false,
						mensaje: "No puedes agregar mas contratos, excedes del total de contratos SYS, pendientes por confirmar: " + this.pendiente_para_realizar()
					});
				}
			} else {
				if (suma_total_contrato_temporal_ias > total_contratos_individuales_ias) {
					throw Object({
						status: false,
						mensaje: "No puedes agregar mas contratos, excedes del total de contratos IAS, pendientes por confirmar: " + this.pendiente_para_realizar_ias()
					});
				}
			}
			
			if (!cantidad_elaborado) {
				throw Object({
					status: false,
					mensaje: "Escribe la cantidad de contratos elaborados"
				});
			}
			
			if (cantidad_elaborado <= 0) {
				throw Object({
					status: false,
					mensaje: "La cantidad no puede ser menor a cero"
				});
			}
			
			if (!excel_elaborado_archivo) {
				throw Object({
					status: false,
					mensaje: "Adjunta en formato .zip los contratos individuales elaborados"
				});
			}
			
			if (this.requerido_si_falta_contratos()) {
				
				/*if (!excel_pendiente_archivo) {
					throw Object({
						status: false,
						mensaje: "Selecciona el excel con los registros de contratos pendientes"
					});
				}*/
				
				/*if (!comentario_general) {
					throw Object({
						status: false,
						mensaje: "Escribe un comentario porque no se completaron los contratos individuales"
					});
				}*/
			}
			
			let data = {
				id_cliente: this.state.id_cliente,
				id_ficha_tecnica: this.state.id_ficha_tecnica,
				
				cantidad_elaborado: cantidad_elaborado,
				
				excel_elaborado_base64: excel_elaborado_base64 || '',
				excel_elaborado_base64Tipo: excel_elaborado_base64Tipo || '',
				excel_elaborado_archivo: excel_elaborado_archivo || '',
				excel_elaborado_formato: excel_elaborado_formato || '',
				
				excel_pendiente_base64: excel_pendiente_base64 || '',
				excel_pendiente_base64Tipo: excel_pendiente_base64Tipo || '',
				excel_pendiente_archivo: excel_pendiente_archivo || '',
				excel_pendiente_formato: excel_pendiente_formato || '',
				
				comentario_general: comentario_general || '',
				esquema: this.state.esquema || '',
			};
			
			ClientePotencialService.GuardarContratosIndividualesGuardar(data, this.state.total_contratos_individuales).then((response) => {
				
				let data = response.data;
				
				arr_temp.push({
					id_cliente_contratos_individuales: data.id_cliente_contratos_individuales,
					
					id_cliente: this.state.id_cliente,
					id_ficha_tecnica: this.state.id_ficha_tecnica,
					
					cantidad_elaborado: data.cantidad_elaborado,
					
					// excel_elaborado_base64: excel_elaborado_base64 || '',
					// excel_elaborado_base64Tipo: excel_elaborado_base64Tipo || '',
					excel_elaborado_archivo: data.excel_elaborado_archivo || '',
					excel_elaborado_formato: data.excel_elaborado_formato || '',
					
					// excel_pendiente_base64: excel_pendiente_base64 || '',
					// excel_pendiente_base64Tipo: excel_pendiente_base64Tipo || '',
					excel_pendiente_archivo: data.excel_pendiente_archivo || '',
					excel_pendiente_formato: data.excel_pendiente_formato || '',
					
					comentario_general: data.comentario_general || '',
					esquema: data.esquema || '',
					
					created_at: data.created_at || '',
				});
				
				this.setState({
					cliente_contratos_individuales: arr_temp,
					cantidad_elaborado: '',
					
					excel_elaborado_base64: '',
					excel_elaborado_base64Tipo: '',
					excel_elaborado_archivo: '',
					excel_elaborado_formato: '',
					
					excel_pendiente_base64: '',
					excel_pendiente_base64Tipo: '',
					excel_pendiente_archivo: '',
					excel_pendiente_formato: '',
					
					comentario_general: '',
				});
				
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
		
	};
	
	agregar_cliente_contratos_individuales_firmados = () => {
		
		try {
			
			let total_contratos_individuales = Number(this.state.total_contratos_individuales);
			let total_contratos_individuales_ias = Number(this.state.total_contratos_individuales_ias);
			
			let arr_temp = FieldsJs.Copy(this.state.cliente_contratos_individuales_firmados);
			
			let cantidad_elaborado = Number(this.state.cantidad_elaborado_firmado);
			let esquema = Number(this.state.esquema_firmado);
			
			let excel_elaborado_base64 = this.state.excel_elaborado_base64_firmado;
			let excel_elaborado_base64Tipo = this.state.excel_elaborado_base64Tipo_firmado;
			let excel_elaborado_archivo = this.state.excel_elaborado_archivo_firmado;
			let excel_elaborado_formato = this.state.excel_elaborado_formato_firmado;
			
			let excel_pendiente_base64 = this.state.excel_pendiente_base64_firmado;
			let excel_pendiente_base64Tipo = this.state.excel_pendiente_base64Tipo_firmado;
			let excel_pendiente_archivo = this.state.excel_pendiente_archivo_firmado;
			let excel_pendiente_formato = this.state.excel_pendiente_formato_firmado;
			
			let comentario_general = this.state.comentario_general_firmado;
			let lugar_resguardo = this.state.lugar_resguardo;
			let id_responsable_resguardo = this.state.id_responsable_resguardo;
			let id_plaza_resguardo = this.state.id_plaza_resguardo;
			
			let suma_total_contrato_temporal = (this.total_contratos_confirmados_firmados() + cantidad_elaborado);
			let suma_total_contrato_temporal_ias = (this.total_contratos_confirmados_ias_firmados() + cantidad_elaborado);
			
			if (!total_contratos_individuales) {
				throw Object({
					status: false,
					mensaje: "Coloca el total de contratos individuales"
				});
			}
			
			if (!esquema) {
				if (suma_total_contrato_temporal > total_contratos_individuales) {
					throw Object({
						status: false,
						mensaje: "No puedes agregar mas contratos, excedes del total de contratos SYS, pendientes por confirmar: " + this.pendiente_para_realizar()
					});
				}
			} else {
				if (suma_total_contrato_temporal_ias > total_contratos_individuales_ias) {
					throw Object({
						status: false,
						mensaje: "No puedes agregar mas contratos, excedes del total de contratos IAS, pendientes por confirmar: " + this.pendiente_para_realizar_ias()
					});
				}
			}
			
			if (!cantidad_elaborado) {
				throw Object({
					status: false,
					mensaje: "Escribe la cantidad de contratos elaborados"
				});
			}
			
			if (cantidad_elaborado <= 0) {
				throw Object({
					status: false,
					mensaje: "La cantidad no puede ser menor a cero"
				});
			}
			
			if (!excel_elaborado_archivo) {
				throw Object({
					status: false,
					mensaje: "Adjunta en formato .zip los contratos individuales elaborados"
				});
			}
			
			if (this.requerido_si_falta_contratos_firmados()) {
				
				/*if (!excel_pendiente_archivo) {
					throw Object({
						status: false,
						mensaje: "Selecciona el excel con los registros de contratos pendientes"
					});
				}*/
				
				/*if (!comentario_general) {
					throw Object({
						status: false,
						mensaje: "Escribe un comentario porque no se completaron los contratos individuales"
					});
				}*/
			}
			
			let data = {
				id_cliente: this.state.id_cliente,
				id_ficha_tecnica: this.state.id_ficha_tecnica,
				
				cantidad_elaborado: cantidad_elaborado,
				
				excel_elaborado_base64: excel_elaborado_base64 || '',
				excel_elaborado_base64Tipo: excel_elaborado_base64Tipo || '',
				excel_elaborado_archivo: excel_elaborado_archivo || '',
				excel_elaborado_formato: excel_elaborado_formato || '',
				
				excel_pendiente_base64: excel_pendiente_base64 || '',
				excel_pendiente_base64Tipo: excel_pendiente_base64Tipo || '',
				excel_pendiente_archivo: excel_pendiente_archivo || '',
				excel_pendiente_formato: excel_pendiente_formato || '',
				
				comentario_general: comentario_general || '',
				esquema: esquema || 0,
				lugar_resguardo: lugar_resguardo,
				id_responsable_resguardo: id_responsable_resguardo,
				id_plaza_resguardo: id_plaza_resguardo,
			};
			
			ClientePotencialService.GuardarContratosIndividualesFirmadoGuardar(data, this.state.total_contratos_individuales).then((response) => {
				
				let data = response.data;
				
				arr_temp.push({
					id_cliente_contratos_individuales_firmados: data.id_cliente_contratos_individuales_firmados,
					
					id_cliente: this.state.id_cliente,
					id_ficha_tecnica: this.state.id_ficha_tecnica,
					
					cantidad_elaborado: data.cantidad_elaborado,
					
					// excel_elaborado_base64: excel_elaborado_base64 || '',
					// excel_elaborado_base64Tipo: excel_elaborado_base64Tipo || '',
					excel_elaborado_archivo: data.excel_elaborado_archivo || '',
					excel_elaborado_formato: data.excel_elaborado_formato || '',
					
					// excel_pendiente_base64: excel_pendiente_base64 || '',
					// excel_pendiente_base64Tipo: excel_pendiente_base64Tipo || '',
					excel_pendiente_archivo: data.excel_pendiente_archivo || '',
					excel_pendiente_formato: data.excel_pendiente_formato || '',
					
					comentario_general: data.comentario_general || '',
					esquema: data.esquema || '',
					lugar_resguardo: data.lugar_resguardo || '',
					id_responsable_resguardo: data.id_responsable_resguardo || '',
					
					created_at: data.created_at || '',
				});
				
				this.setState({
					cliente_contratos_individuales_firmados: arr_temp,
					cantidad_elaborado_firmado: '',
					
					excel_elaborado_base64_firmado: '',
					excel_elaborado_base64Tipo_firmado: '',
					excel_elaborado_archivo_firmado: '',
					excel_elaborado_formato_firmado: '',
					
					excel_pendiente_base64_firmado: '',
					excel_pendiente_base64Tipo_firmado: '',
					excel_pendiente_archivo_firmado: '',
					excel_pendiente_formato_firmado: '',
					
					comentario_general_firmado: '',
					lugar_resguardo: '',
					id_responsable_resguardo: '',
				});
				
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
		
	};
	
	eliminar_cliente_contratos_individuales = (item, key) => {
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, '¿Estas seguro de eliminar este registro?').then((r) => {
			if (r.button === 'Aceptar') {
				ClientePotencialService.GuardarContratosIndividualesEliminar(item, this.state.id_cliente, this.state.id_ficha_tecnica).then((response) => {
					var arr_temp = FieldsJs.Copy(this.state.cliente_contratos_individuales);
					arr_temp.splice(key, 1);
					this.setState({
						cliente_contratos_individuales: arr_temp
					});
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	eliminar_cliente_contratos_individuales_firmados = (item, key) => {
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, '¿Estas seguro de eliminar este registro?').then((r) => {
			if (r.button === 'Aceptar') {
				ClientePotencialService.GuardarContratosIndividualesFirmadosEliminar(item, this.state.id_cliente, this.state.id_ficha_tecnica).then((response) => {
					var arr_temp = FieldsJs.Copy(this.state.cliente_contratos_individuales_firmados);
					arr_temp.splice(key, 1);
					this.setState({
						cliente_contratos_individuales_firmados: arr_temp
					});
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	guardarJuridicoContratoIndividual = () => {
		
		console.warn(this.state);
		
		try {
			
			let cliente_contratos_individuales = this.state.cliente_contratos_individuales;
			
			let total = 0;
			let totalias = 0;
			
			for (let i = 0; i < cliente_contratos_individuales.length; i++) {
				let item = cliente_contratos_individuales[i];
				if (item.esquema) {
					totalias += Number(item.cantidad_elaborado);
				} else {
					total += Number(item.cantidad_elaborado);
				}
			}
			
			if (Number(this.state.total_contratos_individuales) !== Number(total)) {
				throw Object({
					status: false,
					mensaje: 'El numero de contratos SYS no coincide con el total de contratos elaborados confirmados.'
				})
			}
			
			if (Number(this.state.total_contratos_individuales_ias) !== Number(totalias)) {
				throw Object({
					status: false,
					mensaje: 'El numero de contratos IAS no coincide con el total de contratos elaborados confirmados.'
				})
			}
			
			/*if (!this.state.comentario_juridico_contratos_individuales) {
				throw Object({
					status: false,
					mensaje: 'Campo requerido: Comentario contratos individuales y firmas, asegúrate de deja un comentario para confirmar que ya fueron elaborados.'
				})
			}*/
			
			ClientePotencialService.GuardarContratosIndividualesConfirmar(this.state).then((response) => {
				
				this.props.RefreshList();
				
				this.close();
				
				this.showSnackBars('success', response.mensaje);
				
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	confirmar_contrato_individual_firmado_resguardo = () => {
		let msg = `¿Deseas confirmar el resguardo del contrato individual firmado?`;
		PopupService.Confirm(['Cancelar', 'Confirmar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Confirmar') {
				ClientePotencialService.GuardarContratosIndividualesFirmadosConfirmarResguardo(this.state).then((response) => {
					
					this.init();
					
					this.showSnackBars('success', response.mensaje);
					
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	total_contratos_confirmados = () => {
		let arr_temp = this.state.cliente_contratos_individuales;
		let cat = 0;
		for (let i = 0; i < arr_temp.length; i++) {
			let item = arr_temp[i];
			if (!item.esquema) {
				cat += (item.cantidad_elaborado ? Number(item.cantidad_elaborado) : 0);
			}
		}
		return cat;
	};
	
	total_contratos_confirmados_ias = () => {
		let arr_temp = this.state.cliente_contratos_individuales;
		let cat = 0;
		for (let i = 0; i < arr_temp.length; i++) {
			let item = arr_temp[i];
			if (item.esquema) {
				cat += (item.cantidad_elaborado ? Number(item.cantidad_elaborado) : 0);
			}
		}
		return cat;
	};
	
	total_contratos_confirmados_firmados = () => {
		let arr_temp = this.state.cliente_contratos_individuales_firmados;
		let cat = 0;
		for (let i = 0; i < arr_temp.length; i++) {
			let item = arr_temp[i];
			if (!item.esquema) {
				cat += (item.cantidad_elaborado ? Number(item.cantidad_elaborado) : 0);
			}
		}
		return cat;
	};
	
	total_contratos_confirmados_ias_firmados = () => {
		let arr_temp = this.state.cliente_contratos_individuales_firmados;
		let cat = 0;
		for (let i = 0; i < arr_temp.length; i++) {
			let item = arr_temp[i];
			if (item.esquema) {
				cat += (item.cantidad_elaborado ? Number(item.cantidad_elaborado) : 0);
			}
		}
		return cat;
	};
	
	pendiente_para_realizar = () => {
		let arr_temp = this.state.cliente_contratos_individuales;
		let cat = 0;
		for (let i = 0; i < arr_temp.length; i++) {
			let item = arr_temp[i];
			if (!item.esquema) {
				cat += (item.cantidad_elaborado ? Number(item.cantidad_elaborado) : 0);
			}
		}
		return (this.state.total_contratos_individuales || 0) - cat;
	};
	
	pendiente_para_realizar_ias = () => {
		let arr_temp = this.state.cliente_contratos_individuales;
		let cat = 0;
		for (let i = 0; i < arr_temp.length; i++) {
			let item = arr_temp[i];
			if (item.esquema) {
				cat += (item.cantidad_elaborado ? Number(item.cantidad_elaborado) : 0);
			}
		}
		return (this.state.total_contratos_individuales_ias || 0) - cat;
	};
	
	pendiente_para_realizar_firmado = () => {
		let arr_temp = this.state.cliente_contratos_individuales_firmados;
		let cat = 0;
		for (let i = 0; i < arr_temp.length; i++) {
			let item = arr_temp[i];
			if (!item.esquema) {
				cat += (item.cantidad_elaborado ? Number(item.cantidad_elaborado) : 0);
			}
		}
		return (this.state.total_contratos_individuales || 0) - cat;
	};
	
	pendiente_para_realizar_ias_firmado = () => {
		let arr_temp = this.state.cliente_contratos_individuales_firmados;
		let cat = 0;
		for (let i = 0; i < arr_temp.length; i++) {
			let item = arr_temp[i];
			if (item.esquema) {
				cat += (item.cantidad_elaborado ? Number(item.cantidad_elaborado) : 0);
			}
		}
		return (this.state.total_contratos_individuales_ias || 0) - cat;
	};
	
	requerido_si_falta_contratos = () => {
		return ((Number(this.state.total_contratos_individuales) - (this.total_contratos_confirmados() + (Number(this.state.cantidad_elaborado) || 0))) > 0);
	};
	
	requerido_si_falta_contratos_firmados = () => {
		return ((Number(this.state.total_contratos_individuales) - (this.total_contratos_confirmados_firmados() + (Number(this.state.cantidad_elaborado) || 0))) > 0);
	};
	
	disabledFieldJuridicoContratoIndividualBolean = () => {
		
		let disabledFieldJuridicoContratoIndividual = true;
		
		if (FieldsJs.inArray([1, 2, 3, 5], this.Usr.id_cat_tipo_usuario) && FieldsJs.inArray([7, 8, 9, 10, 11, 12], this.state.id_cat_ficha_tecnica_status)) {
			disabledFieldJuridicoContratoIndividual = false;
		}
		
		return disabledFieldJuridicoContratoIndividual;
	};
	
	
	render() {
		
		const disabledFieldJuridicoContratoIndividual = this.disabledFieldJuridicoContratoIndividualBolean();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					{/*<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Typography className={'px-20'}>
									<Fab size="small" color="secondary" aria-label="Actualizar" onClick={() => this.RefreshInit()} style={{marginRight: '15px'}}>
										<SyncOutlined/>
									</Fab>
									{this.props.titulo}
								</Typography>
							</Grid>
						</Grid>
					</DialogTitle>*/}
					
					<DialogContent>
						
						<Grid container spacing={2} alignContent={"flex-start"} alignItems={"flex-start"}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<div>
									<TabContext value={this.state.valuetab}>
										<AppBar position="static">
											<TabList variant="fullWidth" onChange={this.handleChangeTabs} aria-label="simple tabs example">
												<Tab label={<Fragment>
													<p className={'margin-0 padding-0 px-14'} style={{fontWeight: 'bold'}}>(Jurídico)</p>
													<p className={'margin-0 padding-0 px-13'}>{this.props.titulo}</p>
												</Fragment>} value="1"/>
												<Tab label={<Fragment>
													<p className={'margin-0 padding-0 px-14'} style={{fontWeight: 'bold'}}>(Promotor)</p>
													<p className={'margin-0 padding-0 px-13'}>Contratos individuales firmados</p>
												</Fragment>} value="2"/>
											</TabList>
										</AppBar>
										<TabPanel value="1">
											<Grid container spacing={2} alignContent={"flex-start"} alignItems={"flex-start"}>
												<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
													<TextField
														//required
														fullWidth
														onChange={this.handleChange}
														type="text"
														margin="none"
														//helperText="Requerido"
														name="total_contratos_individuales"
														label="Número de contratos SYS"
														value={this.state.total_contratos_individuales}
														disabled
														//disabled={disabledFieldJuridicoContratoIndividual}
														onKeyPress={EnteroSolo}
													/>
												</Grid>
												
												<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
													<TextField
														//required
														fullWidth
														onChange={this.handleChange}
														type="text"
														margin="none"
														//helperText="Requerido"
														name="total_contratos_individuales_ias"
														label="Número de contratos IAS"
														value={this.state.total_contratos_individuales_ias}
														disabled
														//disabled={disabledFieldJuridicoContratoIndividual}
														onKeyPress={EnteroSolo}
													/>
												</Grid>
												
												<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
													<Grid container spacing={2} alignContent={"flex-start"} alignItems={"flex-start"}>
														<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={8} xl={8} style={{backgroundColor: 'lightgray'}}>
															<div className={'vertical-inline w-100'}>
																<div className={'v-center w-50 margin-10-R'}>
																	<Card className={'padding-10'}>
																		<p className={'text-center text-black padding-0 margin-0'} style={{fontWeight: 'bold'}}>
																			SYS
																		</p>
																		<p className={'text-center px-12 text-black padding-0 margin-0'}>
																			Pendientes para realizar
																		</p>
																		<p className={'text-center text-black padding-0 margin-0 px-25'}>
																			{this.pendiente_para_realizar()}
																		</p>
																	</Card>
																</div>
																<div className={'v-center w-50 margin-10-L'}>
																	<Card className={'padding-10'}>
																		<p className={'text-center text-black padding-0 margin-0'} style={{fontWeight: 'bold'}}>
																			IAS
																		</p>
																		<p className={'text-center px-12 text-black padding-0 margin-0'}>
																			Pendientes para realizar
																		</p>
																		<p className={'text-center text-black padding-0 margin-0 px-25'}>
																			{this.pendiente_para_realizar_ias()}
																		</p>
																	</Card>
																</div>
															</div>
														</Grid>
														<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={4} xl={4}>
															<Button variant="outlined" size="large" color="secundary" fullWidth className={'bg_white text-transform-none'}>
																<ModalSeleccionarArchivoExpedienteTrabajador
																	tipo={'add'}
																	componente={
																		<div className={'bg_gray vertical-inline w-100 h-100'}>
																			<img className={'v-center padding-10-R'} src={icon_expediente} alt="add" width={50} height={50}/>
																			<p className={'v-center px-13 margin-0'} style={{color: '#3f51b5', letterSpacing: 'initial'}}>Expedientes del Trabajador </p>
																		</div>
																	}
																	state={this.state}
																	item={this.state}
																	RefreshList={this.props.RefreshList}
																	showSnackBars={this.props.showSnackBars}
																	RefreshFichaTecnica={this.RefreshFichaTecnica}
																/>
															</Button>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid container spacing={2} alignContent={"flex-start"} alignItems={"stretch"}>
												
												{this.pendiente_para_realizar() > 0 || this.pendiente_para_realizar_ias() > 0 ? (
													<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
														
														<Card style={{height: '100%', padding: 0, backgroundColor: "white"}}>
															<CardContent style={{padding: '15px'}}>
																<Grid container spacing={3}>
																	
																	<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																		<p className={'margin-0 padding-0 px-12'} style={{color: 'rgba(0, 0, 0, 0.54)', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontWeight: 400}}>
																			Tipo de contrato:
																		</p>
																		
																		<RadioGroup row aria-label="position" name="esquema" value={this.state.esquema} onChange={this.handleChange}>
																			<FormControlLabel
																				style={{color: '#333333', fontSize: '13px', fontWeight: 'bold'}}
																				labelPlacement="end"
																				control={<Radio color="primary"/>}
																				value={'0'}
																				label={"SYS"}
																				disabled={disabledFieldJuridicoContratoIndividual}
																			/>
																			<FormControlLabel
																				style={{color: '#333333', fontSize: '13px', fontWeight: 'bold'}}
																				labelPlacement="end"
																				control={<Radio color="primary"/>}
																				value={'1'}
																				label={"IAS"}
																				disabled={disabledFieldJuridicoContratoIndividual}
																			/>
																		</RadioGroup>
																		
																		<TextField
																			required
																			fullWidth
																			onChange={this.handleChange}
																			type="text"
																			margin="none"
																			
																			helperText="Especifica la cantidad de contratos elaborados"
																			name="cantidad_elaborado"
																			label="Contratos elaborados"
																			value={this.state.cantidad_elaborado}
																			disabled={disabledFieldJuridicoContratoIndividual}
																			onKeyPress={EnteroSolo}
																		/>
																	</Grid>
																	
																	<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12}
																	      xl={12}>
																		
																		<Grid container spacing={3}>
																			<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																				<BoxSelectFile
																					colorCircleIcon={'#dcdcdc'}
																					style={{height: '120px'}}
																					label={(
																						<div>
																							Contratos elaborados en ZIP <b
																							className={'vertical-inline v-center text-danger'}>(Requerido)</b>
																						</div>
																					)}
																					item={{
																						base64: this.state.excel_elaborado_base64,
																						base64Tipo: this.state.excel_elaborado_base64Tipo,
																						archivo: this.state.excel_elaborado_archivo,
																						formato: this.state.excel_elaborado_formato,
																					}}
																					onChange={(r) => {
																						this.handleChange({
																							target: {
																								name: 'excel_elaborado_base64',
																								value: r.base64
																							}
																						});
																						this.handleChange({
																							target: {
																								name: 'excel_elaborado_base64Tipo',
																								value: r.base64Tipo
																							}
																						});
																						this.handleChange({
																							target: {
																								name: 'excel_elaborado_archivo',
																								value: r.archivo
																							}
																						});
																						this.handleChange({
																							target: {
																								name: 'excel_elaborado_formato',
																								value: r.formato
																							}
																						});
																					}}
																					showSnackBars={this.showSnackBars}
																					disabled={disabledFieldJuridicoContratoIndividual}
																				/>
																			</Grid>
																			<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																				<BoxSelectFile
																					colorCircleIcon={'#dcdcdc'}
																					style={{height: '120px'}}
																					label={(
																						<div>
																							Excel de contratos pendientes <b
																							className={'vertical-inline v-center text-blue'}> {' (Opcional)'}</b>
																							{/*className={'vertical-inline v-center' + (this.requerido_si_falta_contratos() ? ' text-blue' : ' text-blue')}> {(this.requerido_si_falta_contratos() ? ' (Requerido)' : ' (Opcional)')}</b>*/}
																						</div>
																					)}
																					item={{
																						base64: this.state.excel_pendiente_base64,
																						base64Tipo: this.state.excel_pendiente_base64Tipo,
																						archivo: this.state.excel_pendiente_archivo,
																						formato: this.state.excel_pendiente_formato,
																					}}
																					onChange={(r) => {
																						this.handleChange({
																							target: {
																								name: 'excel_pendiente_base64',
																								value: r.base64
																							}
																						});
																						this.handleChange({
																							target: {
																								name: 'excel_pendiente_base64Tipo',
																								value: r.base64Tipo
																							}
																						});
																						this.handleChange({
																							target: {
																								name: 'excel_pendiente_archivo',
																								value: r.archivo
																							}
																						});
																						this.handleChange({
																							target: {
																								name: 'excel_pendiente_formato',
																								value: r.formato
																							}
																						});
																					}}
																					showSnackBars={this.showSnackBars}
																					disabled={disabledFieldJuridicoContratoIndividual}
																				/>
																			</Grid>
																		</Grid>
																	
																	</Grid>
																	
																	<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12}
																	      xl={12}>
																		<TextField
																			//required={this.requerido_si_falta_contratos()}
																			fullWidth
																			onChange={this.handleChange}
																			type="text"
																			margin="none"
																			helperText=""
																			name="comentario_general"
																			label="Comentarios adicionales"
																			value={this.state.comentario_general}
																			disabled={disabledFieldJuridicoContratoIndividual}
																		/>
																	</Grid>
																	
																	<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12}
																	      xl={12}>
																		<Button variant="contained" color="primary"
																		        onClick={this.agregar_cliente_contratos_individuales}
																		        className={'btn-default-primary text-transform-none'}
																		        disabled={disabledFieldJuridicoContratoIndividual}
																		>
																			Agregar
																		</Button>
																	</Grid>
																</Grid>
															</CardContent>
														</Card>
													
													</Grid>
												) : ''}
												
												<Grid item className={'text-left'}
												      xs={(this.pendiente_para_realizar() > 0 || this.pendiente_para_realizar_ias() > 0 ? 12 : 12)}
												      sm={(this.pendiente_para_realizar() > 0 || this.pendiente_para_realizar_ias() > 0 ? 12 : 12)}
												      md={(this.pendiente_para_realizar() > 0 || this.pendiente_para_realizar_ias() > 0 ? 8 : 12)}
												      lg={(this.pendiente_para_realizar() > 0 || this.pendiente_para_realizar_ias() > 0 ? 8 : 12)}
												      xl={(this.pendiente_para_realizar() > 0 || this.pendiente_para_realizar_ias() > 0 ? 8 : 12)}
												>
													
													<Card style={{height: '100%', padding: 0, backgroundColor: "lightgray"}}>
														<CardContent style={{padding: '15px'}}>
															<Grid container spacing={2}>
																<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
																	<Grid container spacing={2}>
																		
																		{this.state.cliente_contratos_individuales.map((item, key) => (
																			
																			<Fragment key={key}>
																				{!item.esquema ? (
																					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																						
																						<Card style={{height: '100%'}}>
																							<CardContent style={{padding: '10px'}}>
																								
																								<div style={{height: 0, width: '100%'}} align={'left'}>
																									{item.id_cliente_contratos_individuales ? (
																										<div
																											style={{
																												textAlign: 'center',
																												float: 'left',
																												padding: '6px',
																												background: 'gray',
																												height: '6px',
																												width: '25px',
																												lineHeight: '6px',
																												color: 'white',
																												borderRadius: '50%',
																												fontSize: '12px',
																											}}
																											children={'SYS'}
																										/>
																									) : ""}
																								</div>
																								
																								<div style={{height: 0, width: '100%'}} align={'right'}>
																									{!disabledFieldJuridicoContratoIndividual ? (
																										(item.id_cliente_contratos_individuales ? (
																											<div
																												className={'cursor-pointer'}
																												style={{
																													textAlign: 'center',
																													marginLeft: 5,
																													float: 'right',
																													padding: '6px',
																													background: 'red',
																													height: '6px',
																													width: '6px',
																													lineHeight: '6px',
																													color: 'white',
																													borderRadius: '100%',
																													fontSize: '12px',
																												}}
																												onClick={() => this.eliminar_cliente_contratos_individuales(item, key)}
																												children={'x'}
																											/>
																										) : "")
																									) : ''}
																								</div>
																								
																								<Typography
																									className={'text-right text-gray margin-0 padding-0'}
																									style={{lineHeight: '18px', fontSize: '12px'}}>
																									{item.created_at ? DateFormat.FechaHoraFormatString(item.created_at) : ''}
																								</Typography>
																								
																								<Typography
																									className={'text-left text-black margin-0 padding-0 padding-5-T px-14'}>
																									Cantidad elaborados: {item.cantidad_elaborado}
																								</Typography>
																								
																								<div className={'w-100'}>
																									{item.excel_elaborado_archivo ? (
																										<div className="vertical-inline">
																											<div className={'v-center'}>
																												<Typography
																													className={'text-left text-black margin-0 padding-0 px-14'}>
																													Contratos elaborados:
																												</Typography>
																											</div>
																											<div
																												className={'padding-5-L padding-5-R w-18-px v-center'}>
																												{Icono.get(item.excel_elaborado_formato, '20px', '20px')}
																											</div>
																											<div className={'v-center'}>
																												<Fragment>
																													<ModalImage
																														tipo={'view'} desing={3}
																														item={{
																															base64: item.excel_elaborado_base64,
																															base64Tipo: item.excel_elaborado_base64Tipo,
																															archivo: item.excel_elaborado_archivo,
																															formato: item.excel_elaborado_formato,
																														}}
																													/>
																												</Fragment>
																											</div>
																										</div>
																									) : ''}
																								</div>
																								
																								<div className={'w-100'}>
																									{item.excel_pendiente_archivo ? (
																										<div className="vertical-inline">
																											<div className={'v-center'}>
																												<Typography
																													className={'text-left text-black margin-0 padding-0 px-14'}>
																													Contratos pendientes:
																												</Typography>
																											</div>
																											<div
																												className={'padding-5-L padding-5-R w-18-px v-center'}>
																												{Icono.get(item.excel_pendiente_formato, '20px', '20px')}
																											</div>
																											<div className={'v-center'}>
																												<Fragment>
																													<ModalImage
																														tipo={'view'} desing={3}
																														item={{
																															base64: item.excel_pendiente_base64,
																															base64Tipo: item.excel_pendiente_base64Tipo,
																															archivo: item.excel_pendiente_archivo,
																															formato: item.excel_pendiente_formato,
																														}}
																													/>
																												</Fragment>
																											</div>
																										</div>
																									) : ''}
																								</div>
																								
																								<Typography
																									className={'text-left text-black margin-0 padding-0 px-14'}>
																									Comentarios: {item.comentario_general || 'Sin comentarios'}
																								</Typography>
																							
																							</CardContent>
																						</Card>
																					
																					</Grid>
																				) : null}
																			</Fragment>
																		))}
																	</Grid>
																</Grid>
																
																<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
																	<Grid container spacing={2}>
																		
																		{this.state.cliente_contratos_individuales.map((item, key) => (
																			
																			<Fragment key={key}>
																				{item.esquema ? (
																					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																						
																						<Card style={{height: '100%'}}>
																							<CardContent style={{padding: '10px'}}>
																								
																								<div style={{height: 0, width: '100%'}} align={'left'}>
																									{item.id_cliente_contratos_individuales ? (
																										<div
																											style={{
																												textAlign: 'center',
																												float: 'left',
																												padding: '6px',
																												background: 'gray',
																												height: '6px',
																												width: '25px',
																												lineHeight: '6px',
																												color: 'white',
																												borderRadius: '50%',
																												fontSize: '12px',
																											}}
																											children={'IAS'}
																										/>
																									) : ""}
																								</div>
																								
																								<div style={{height: 0, width: '100%'}} align={'right'}>
																									{!disabledFieldJuridicoContratoIndividual ? (
																										(item.id_cliente_contratos_individuales ? (
																											<div
																												className={'cursor-pointer'}
																												style={{
																													textAlign: 'center',
																													marginLeft: 5,
																													float: 'right',
																													padding: '6px',
																													background: 'red',
																													height: '6px',
																													width: '6px',
																													lineHeight: '6px',
																													color: 'white',
																													borderRadius: '100%',
																													fontSize: '12px',
																												}}
																												onClick={() => this.eliminar_cliente_contratos_individuales(item, key)}
																												children={'x'}
																											/>
																										) : "")
																									) : ''}
																								</div>
																								
																								<Typography
																									className={'text-right text-gray margin-0 padding-0'}
																									style={{lineHeight: '18px', fontSize: '12px'}}>
																									{item.created_at ? DateFormat.FechaHoraFormatString(item.created_at) : ''}
																								</Typography>
																								
																								<Typography
																									className={'text-left text-black margin-0 padding-0 padding-5-T px-14'}>
																									Cantidad elaborados: {item.cantidad_elaborado}
																								</Typography>
																								
																								<div className={'w-100'}>
																									{item.excel_elaborado_archivo ? (
																										<div className="vertical-inline">
																											<div className={'v-center'}>
																												<Typography
																													className={'text-left text-black margin-0 padding-0 px-14'}>
																													Contratos elaborados:
																												</Typography>
																											</div>
																											<div
																												className={'padding-5-L padding-5-R w-18-px v-center'}>
																												{Icono.get(item.excel_elaborado_formato, '20px', '20px')}
																											</div>
																											<div className={'v-center'}>
																												<Fragment>
																													<ModalImage
																														tipo={'view'} desing={3}
																														item={{
																															base64: item.excel_elaborado_base64,
																															base64Tipo: item.excel_elaborado_base64Tipo,
																															archivo: item.excel_elaborado_archivo,
																															formato: item.excel_elaborado_formato,
																														}}
																													/>
																												</Fragment>
																											</div>
																										</div>
																									) : ''}
																								</div>
																								
																								<div className={'w-100'}>
																									{item.excel_pendiente_archivo ? (
																										<div className="vertical-inline">
																											<div className={'v-center'}>
																												<Typography
																													className={'text-left text-black margin-0 padding-0 px-14'}>
																													Contratos pendientes:
																												</Typography>
																											</div>
																											<div
																												className={'padding-5-L padding-5-R w-18-px v-center'}>
																												{Icono.get(item.excel_pendiente_formato, '20px', '20px')}
																											</div>
																											<div className={'v-center'}>
																												<Fragment>
																													<ModalImage
																														tipo={'view'} desing={3}
																														item={{
																															base64: item.excel_pendiente_base64,
																															base64Tipo: item.excel_pendiente_base64Tipo,
																															archivo: item.excel_pendiente_archivo,
																															formato: item.excel_pendiente_formato,
																														}}
																													/>
																												</Fragment>
																											</div>
																										</div>
																									) : ''}
																								</div>
																								
																								<Typography
																									className={'text-left text-black margin-0 padding-0 px-14'}>
																									Comentarios: {item.comentario_general || 'Sin comentarios'}
																								</Typography>
																							
																							</CardContent>
																						</Card>
																					
																					</Grid>
																				) : null}
																			</Fragment>
																		))}
																	</Grid>
																</Grid>
																
																{this.state.cliente_contratos_individuales.length <= 0 ? (
																	<Fragment>
																		<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
																			<VistaVacia
																				numero={0}
																				mensaje='No se han reportado contratos individuales realizados.'
																				width={'100px'}
																				height={'100px'}
																			/>
																		</Grid>
																	</Fragment>
																) : ''}
															
															</Grid>
														</CardContent>
													</Card>
												
												</Grid>
												
												<Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
													
													{(this.pendiente_para_realizar() <= 0 && Number(this.state.total_contratos_individuales) > 0) &&
													(this.pendiente_para_realizar_ias() <= 0 && Number(this.state.total_contratos_individuales_ias) > 0) ? (
														<Fragment>
															<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																
																{!disabledFieldJuridicoContratoIndividual ? (
																	<Typography
																		className={'text-center text-black padding-0 margin-0 px-22'}>
																		Has indicado que los contratos individuales de trabajo se
																		completó al 100%, informale a {this.state.cliente.usuario_alta_cliente}.
																	</Typography>
																) : (
																	<Typography
																		className={'text-center text-black padding-0 margin-0 px-22'}>
																		Has indicado que los contratos individuales de trabajo se
																		completó al 100%.
																	</Typography>
																)}
															
															</Grid>
															
															<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																
																<TextField
																	//required
																	fullWidth
																	onChange={this.handleChange}
																	type="text"
																	margin="none"
																	//helperText="Requerido"
																	name="comentario_juridico_contratos_individuales"
																	label="Comentario contratos individuales y firmas"
																	value={this.state.comentario_juridico_contratos_individuales}
																	disabled={disabledFieldJuridicoContratoIndividual}
																/>
															
															</Grid>
														
														</Fragment>
													) : ''}
												
												</Grid>
											</Grid>
										</TabPanel>
										
										<TabPanel value="2">
											<Grid container spacing={2} alignContent={"flex-start"} alignItems={"flex-start"}>
												<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
													<TextField
														//required
														fullWidth
														onChange={this.handleChange}
														type="text"
														margin="none"
														//helperText="Requerido"
														name="total_contratos_individuales"
														label="Número de contratos SYS"
														value={this.state.total_contratos_individuales}
														disabled
														//disabled={disabledFieldJuridicoContratoIndividual}
														onKeyPress={EnteroSolo}
													/>
												</Grid>
												
												<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
													<TextField
														//required
														fullWidth
														onChange={this.handleChange}
														type="text"
														margin="none"
														//helperText="Requerido"
														name="total_contratos_individuales_ias"
														label="Número de contratos IAS"
														value={this.state.total_contratos_individuales_ias}
														disabled
														//disabled={disabledFieldJuridicoContratoIndividual}
														onKeyPress={EnteroSolo}
													/>
												</Grid>
												
												<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
													<Grid container spacing={2} alignContent={"flex-start"} alignItems={"flex-start"}>
														<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={8} xl={8} style={{backgroundColor: 'lightgray'}}>
															<div className={'vertical-inline w-100'}>
																<div className={'v-center w-50 margin-10-R'}>
																	<Card className={'padding-10'}>
																		<p className={'text-center text-black padding-0 margin-0'} style={{fontWeight: 'bold'}}>
																			SYS
																		</p>
																		<p className={'text-center px-12 text-black padding-0 margin-0'}>
																			Pendientes para realizar
																		</p>
																		<p className={'text-center text-black padding-0 margin-0 px-25'}>
																			{this.pendiente_para_realizar_firmado()}
																		</p>
																	</Card>
																</div>
																<div className={'v-center w-50 margin-10-L'}>
																	<Card className={'padding-10'}>
																		<p className={'text-center text-black padding-0 margin-0'} style={{fontWeight: 'bold'}}>
																			IAS
																		</p>
																		<p className={'text-center px-12 text-black padding-0 margin-0'}>
																			Pendientes para realizar
																		</p>
																		<p className={'text-center text-black padding-0 margin-0 px-25'}>
																			{this.pendiente_para_realizar_ias_firmado()}
																		</p>
																	</Card>
																</div>
															</div>
														</Grid>
														<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={4} xl={4}>
															<Button variant="outlined" size="large" color="secundary" fullWidth className={'bg_white text-transform-none'}>
																<ModalSeleccionarArchivoExpedienteTrabajador
																	tipo={'add'}
																	componente={
																		<div className={'bg_gray vertical-inline w-100 h-100'}>
																			<img className={'v-center padding-10-R'} src={icon_expediente} alt="add" width={50} height={50}/>
																			<p className={'v-center px-13 margin-0'} style={{color: '#3f51b5', letterSpacing: 'initial'}}>Expedientes del Trabajador </p>
																		</div>
																	}
																	state={this.state}
																	item={this.state}
																	RefreshList={this.props.RefreshList}
																	showSnackBars={this.props.showSnackBars}
																	RefreshFichaTecnica={this.RefreshFichaTecnica}
																/>
															</Button>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid container spacing={2} alignContent={"flex-start"} alignItems={"stretch"}>
												
												{this.pendiente_para_realizar_firmado() > 0 || this.pendiente_para_realizar_ias_firmado() > 0 ? (
													<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
														
														<Card style={{height: '100%', padding: 0, backgroundColor: "white"}}>
															<CardContent style={{padding: '15px'}}>
																<Grid container spacing={3}>
																	
																	<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																		<p className={'margin-0 padding-0 px-12'} style={{color: 'rgba(0, 0, 0, 0.54)', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontWeight: 400}}>
																			Tipo de contrato:
																		</p>
																		
																		<RadioGroup row aria-label="position" name="esquema_firmado" value={this.state.esquema_firmado} onChange={this.handleChange}>
																			<FormControlLabel
																				style={{color: '#333333', fontSize: '13px', fontWeight: 'bold'}}
																				labelPlacement="end"
																				control={<Radio color="primary"/>}
																				value={'0'}
																				label={"SYS"}
																				disabled={disabledFieldJuridicoContratoIndividual}
																			/>
																			<FormControlLabel
																				style={{color: '#333333', fontSize: '13px', fontWeight: 'bold'}}
																				labelPlacement="end"
																				control={<Radio color="primary"/>}
																				value={'1'}
																				label={"IAS"}
																				disabled={disabledFieldJuridicoContratoIndividual}
																			/>
																		</RadioGroup>
																		
																		<TextField
																			required
																			fullWidth
																			onChange={this.handleChange}
																			type="text"
																			margin="none"
																			
																			helperText="Especifica la cantidad de contratos elaborados"
																			name="cantidad_elaborado_firmado"
																			label="Contratos elaborados"
																			value={this.state.cantidad_elaborado_firmado}
																			disabled={disabledFieldJuridicoContratoIndividual}
																			onKeyPress={EnteroSolo}
																		/>
																	</Grid>
																	
																	<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12}
																	      xl={12}>
																		
																		<Grid container spacing={1}>
																			<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																				<BoxSelectFile
																					colorCircleIcon={'#dcdcdc'}
																					style={{height: '120px'}}
																					label={(
																						<div>
																							Contratos elaborados en ZIP <b
																							className={'vertical-inline v-center text-danger'}>(Requerido)</b>
																						</div>
																					)}
																					item={{
																						base64: this.state.excel_elaborado_base64_firmado,
																						base64Tipo: this.state.excel_elaborado_base64Tipo_firmado,
																						archivo: this.state.excel_elaborado_archivo_firmado,
																						formato: this.state.excel_elaborado_formato_firmado,
																					}}
																					onChange={(r) => {
																						this.handleChange({
																							target: {
																								name: 'excel_elaborado_base64_firmado',
																								value: r.base64
																							}
																						});
																						this.handleChange({
																							target: {
																								name: 'excel_elaborado_base64Tipo_firmado',
																								value: r.base64Tipo
																							}
																						});
																						this.handleChange({
																							target: {
																								name: 'excel_elaborado_archivo_firmado',
																								value: r.archivo
																							}
																						});
																						this.handleChange({
																							target: {
																								name: 'excel_elaborado_formato_firmado',
																								value: r.formato
																							}
																						});
																					}}
																					showSnackBars={this.showSnackBars}
																					disabled={disabledFieldJuridicoContratoIndividual}
																				/>
																			</Grid>
																			<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																				<BoxSelectFile
																					colorCircleIcon={'#dcdcdc'}
																					style={{height: '120px'}}
																					label={(
																						<div>
																							Excel de contratos pendientes <b
																							className={'vertical-inline v-center text-blue'}> {' (Opcional)'}</b>
																						</div>
																					)}
																					item={{
																						base64: this.state.excel_pendiente_base64_firmado,
																						base64Tipo: this.state.excel_pendiente_base64Tipo_firmado,
																						archivo: this.state.excel_pendiente_archivo_firmado,
																						formato: this.state.excel_pendiente_formato_firmado,
																					}}
																					onChange={(r) => {
																						this.handleChange({
																							target: {
																								name: 'excel_pendiente_base64_firmado',
																								value: r.base64
																							}
																						});
																						this.handleChange({
																							target: {
																								name: 'excel_pendiente_base64Tipo_firmado',
																								value: r.base64Tipo
																							}
																						});
																						this.handleChange({
																							target: {
																								name: 'excel_pendiente_archivo_firmado',
																								value: r.archivo
																							}
																						});
																						this.handleChange({
																							target: {
																								name: 'excel_pendiente_formato_firmado',
																								value: r.formato
																							}
																						});
																					}}
																					showSnackBars={this.showSnackBars}
																					disabled={disabledFieldJuridicoContratoIndividual}
																				/>
																			</Grid>
																		</Grid>
																	
																	</Grid>
																	
																	<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
																		
																		<Typography className={'px-13 margin-0 padding-0'}>
																			Resguardo Físico
																		</Typography>
																	
																	</Grid>
																	
																	{/*<Grid item xs={12} sm={12} md={6} lg={6} xl={6} align={'center'}>
																		
																		<TextField
																			multiline
																			fullWidth
																			onChange={this.handleChange}
																			type="text"
																			margin="dense"
																			//required
																			helperText=""
																			name="responsable_resguardo"
																			label="Responsable"
																			value={this.state.responsable_resguardo}
																			disabled={disabledFieldJuridicoContratoIndividual}
																		/>
																	
																	</Grid>*/}
																	
																	<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
																		<TextField
																			select
																			fullWidth
																			required
																			margin="none"
																			onChange={this.handleChange}
																			SelectProps={{
																				native: true,
																				MenuProps: {
																					className: '',
																				},
																			}}
																			name="id_plaza_resguardo"
																			label="Plaza"
																			value={this.state.id_plaza_resguardo}
																		>
																			<option key={0} value={''}>&nbsp;</option>
																			{this.state.listar_plazas.map(option => (
																				<option key={option.id_plaza} value={option.id_plaza}>
																					{option.plaza}
																				</option>
																			))}
																		</TextField>
																	</Grid>
																	
																	<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
																		<TextField
																			select
																			fullWidth
																			required
																			margin="none"
																			onChange={this.handleChange}
																			SelectProps={{
																				native: true,
																				MenuProps: {
																					className: '',
																				},
																			}}
																			name="id_responsable_resguardo"
																			label="Responsable"
																			value={this.state.id_responsable_resguardo}
																		>
																			<option key={0} value={''}>&nbsp;</option>
																			{this.state.listar_usuarios.map(option => (
																				<option key={option.id_usuario} value={option.id_usuario}>
																					{option.nombre_completo}
																				</option>
																			))}
																		</TextField>
																	</Grid>
																	
																	<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
																		
																		<TextField
																			multiline
																			fullWidth
																			onChange={this.handleChange}
																			type="text"
																			margin="dense"
																			required
																			helperText=""
																			name="lugar_resguardo"
																			label="Dirección de resguardo"
																			value={this.state.lugar_resguardo}
																			disabled={disabledFieldJuridicoContratoIndividual}
																		/>
																	
																	</Grid>
																	
																	<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12}
																	      xl={12}>
																		<TextField
																			//required={this.requerido_si_falta_contratos()}
																			fullWidth
																			onChange={this.handleChange}
																			type="text"
																			margin="none"
																			helperText=""
																			name="comentario_general_firmado"
																			label="Comentarios adicionales"
																			value={this.state.comentario_general_firmado}
																			disabled={disabledFieldJuridicoContratoIndividual}
																		/>
																	</Grid>
																	
																	<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12}
																	      xl={12}>
																		<Button variant="contained" color="primary"
																		        onClick={this.agregar_cliente_contratos_individuales_firmados}
																		        className={'btn-default-primary text-transform-none'}
																		        disabled={disabledFieldJuridicoContratoIndividual}
																		>
																			Agregar
																		</Button>
																	</Grid>
																</Grid>
															</CardContent>
														</Card>
													
													</Grid>
												) : ''}
												
												<Grid item className={'text-left'}
												      xs={(this.pendiente_para_realizar_firmado() > 0 || this.pendiente_para_realizar_ias_firmado() > 0 ? 12 : 12)}
												      sm={(this.pendiente_para_realizar_firmado() > 0 || this.pendiente_para_realizar_ias_firmado() > 0 ? 12 : 12)}
												      md={(this.pendiente_para_realizar_firmado() > 0 || this.pendiente_para_realizar_ias_firmado() > 0 ? 8 : 12)}
												      lg={(this.pendiente_para_realizar_firmado() > 0 || this.pendiente_para_realizar_ias_firmado() > 0 ? 8 : 12)}
												      xl={(this.pendiente_para_realizar_firmado() > 0 || this.pendiente_para_realizar_ias_firmado() > 0 ? 8 : 12)}
												>
													
													<Card style={{height: '100%', padding: 0, backgroundColor: "lightgray"}}>
														<CardContent style={{padding: '15px'}}>
															<Grid container spacing={2}>
																<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
																	<Grid container spacing={2}>
																		
																		{this.state.cliente_contratos_individuales_firmados.map((item, key) => (
																			
																			<Fragment key={key}>
																				{!item.esquema ? (
																					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																						
																						<Card style={{height: '100%'}}>
																							<CardContent style={{padding: '10px'}}>
																								
																								<div style={{height: 0, width: '100%'}} align={'left'}>
																									{item.id_cliente_contratos_individuales_firmados ? (
																										<div
																											style={{
																												textAlign: 'center',
																												float: 'left',
																												padding: '6px',
																												background: 'gray',
																												height: '6px',
																												width: '25px',
																												lineHeight: '6px',
																												color: 'white',
																												borderRadius: '50%',
																												fontSize: '12px',
																											}}
																											children={'SYS'}
																										/>
																									) : ""}
																								</div>
																								
																								<div style={{height: 0, width: '100%'}} align={'right'}>
																									{!disabledFieldJuridicoContratoIndividual ? (
																										(item.id_cliente_contratos_individuales_firmados ? (
																											<div
																												className={'cursor-pointer'}
																												style={{
																													textAlign: 'center',
																													marginLeft: 5,
																													float: 'right',
																													padding: '6px',
																													background: 'red',
																													height: '6px',
																													width: '6px',
																													lineHeight: '6px',
																													color: 'white',
																													borderRadius: '100%',
																													fontSize: '12px',
																												}}
																												onClick={() => this.eliminar_cliente_contratos_individuales_firmados(item, key)}
																												children={'x'}
																											/>
																										) : "")
																									) : ''}
																								</div>
																								
																								<Typography
																									className={'text-right text-gray margin-0 padding-0'}
																									style={{lineHeight: '18px', fontSize: '12px'}}>
																									{item.created_at ? DateFormat.FechaHoraFormatString(item.created_at) : ''}
																								</Typography>
																								
																								<Typography
																									className={'text-left text-black margin-0 padding-0 padding-5-T px-14'}>
																									Cantidad elaborados: {item.cantidad_elaborado}
																								</Typography>
																								
																								<div className={'w-100'}>
																									{item.excel_elaborado_archivo ? (
																										<div className="vertical-inline">
																											<div className={'v-center'}>
																												<Typography
																													className={'text-left text-black margin-0 padding-0 px-14'}>
																													Contratos elaborados:
																												</Typography>
																											</div>
																											<div
																												className={'padding-5-L padding-5-R w-18-px v-center'}>
																												{Icono.get(item.excel_elaborado_formato, '20px', '20px')}
																											</div>
																											<div className={'v-center'}>
																												<Fragment>
																													<ModalImage
																														tipo={'view'} desing={3}
																														item={{
																															base64: item.excel_elaborado_base64,
																															base64Tipo: item.excel_elaborado_base64Tipo,
																															archivo: item.excel_elaborado_archivo,
																															formato: item.excel_elaborado_formato,
																														}}
																													/>
																												</Fragment>
																											</div>
																										</div>
																									) : ''}
																								</div>
																								
																								<div className={'w-100'}>
																									{item.excel_pendiente_archivo ? (
																										<div className="vertical-inline">
																											<div className={'v-center'}>
																												<Typography
																													className={'text-left text-black margin-0 padding-0 px-14'}>
																													Contratos pendientes:
																												</Typography>
																											</div>
																											<div
																												className={'padding-5-L padding-5-R w-18-px v-center'}>
																												{Icono.get(item.excel_pendiente_formato, '20px', '20px')}
																											</div>
																											<div className={'v-center'}>
																												<Fragment>
																													<ModalImage
																														tipo={'view'} desing={3}
																														item={{
																															base64: item.excel_pendiente_base64,
																															base64Tipo: item.excel_pendiente_base64Tipo,
																															archivo: item.excel_pendiente_archivo,
																															formato: item.excel_pendiente_formato,
																														}}
																													/>
																												</Fragment>
																											</div>
																										</div>
																									) : ''}
																								</div>
																								
																								<Typography className={'text-left px-12 text-black margin-0 padding-0'}>
																									Resguardo
																									Físico: {!item.lugar_resguardo && !item.responsable_resguardo ? 'Sin resguardo' : (item.plaza ? item.plaza + ' - ' : '') + item.lugar_resguardo + (item.lugar_resguardo && item.responsable_resguardo ? ' - ' : ' ') + item.responsable_resguardo}
																								</Typography>
																								
																								<Typography
																									className={'text-left text-black margin-0 padding-0 px-14'}>
																									Comentarios: {item.comentario_general || 'Sin comentarios'}
																								</Typography>
																								
																								{item.comentario ? (
																									<Typography
																										className={'text-left text-black margin-0 padding-0 px-14'}>
																										Comentario del responsable: {item.comentario || 'Sin comentarios'}
																									</Typography>
																								) : null}
																								
																								<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
																									{/*onClick={() => this.confirmar_contrato_individual_firmado_resguardo(item.id_cliente_contratos_individuales_firmados)}*/}
																									{
																										!item.confirmado ? (
																											<Fragment>
																												{this.Usr.id_usuario === item.id_responsable_resguardo ? (
																												<ModalComentarioResguardo
																													tipo={'add'}
																													item={item}
																													component={
																														<Button size="small" variant="contained" color="primary" className={'btn-default-primary text-transform-none w-100'}>
																															Confirmar
																														</Button>
																													}
																													showSnackBars={this.props.showSnackBars}
																													RefreshInit={this.init}
																												/>) : <p style={{
																													textAlign: 'left',
																													margin: '0px 0px 5px 0px',
																													float: 'left',
																													padding: '6px',
																													background: 'gray',
																													height: '6px',
																													lineHeight: '6px',
																													color: 'white',
																													borderRadius: '10px',
																													fontSize: '12px',
																												}}>Por confirmar</p> }
																											</Fragment>
																										) : <p style={{
																											textAlign: 'left',
																											margin: '0px 0px 5px 0px',
																											float: 'left',
																											padding: '6px',
																											background: 'red',
																											height: '6px',
																											lineHeight: '6px',
																											color: 'white',
																											borderRadius: '10px',
																											fontSize: '12px',
																										}}>Confirmado</p>
																									}
																								
																								</Grid>
																							
																							</CardContent>
																						</Card>
																					
																					</Grid>
																				) : null}
																			</Fragment>
																		))}
																	</Grid>
																</Grid>
																
																<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
																	<Grid container spacing={2}>
																		
																		{this.state.cliente_contratos_individuales_firmados.map((item, key) => (
																			
																			<Fragment key={key}>
																				{item.esquema ? (
																					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																						
																						<Card style={{height: '100%'}}>
																							<CardContent style={{padding: '10px'}}>
																								
																								<div style={{height: 0, width: '100%'}} align={'left'}>
																									{item.id_cliente_contratos_individuales_firmados ? (
																										<div
																											style={{
																												textAlign: 'center',
																												float: 'left',
																												padding: '6px',
																												background: 'gray',
																												height: '6px',
																												width: '25px',
																												lineHeight: '6px',
																												color: 'white',
																												borderRadius: '50%',
																												fontSize: '12px',
																											}}
																											children={'IAS'}
																										/>
																									) : ""}
																								</div>
																								
																								<div style={{height: 0, width: '100%'}} align={'right'}>
																									{!disabledFieldJuridicoContratoIndividual ? (
																										(item.id_cliente_contratos_individuales_firmados ? (
																											<div
																												className={'cursor-pointer'}
																												style={{
																													textAlign: 'center',
																													marginLeft: 5,
																													float: 'right',
																													padding: '6px',
																													background: 'red',
																													height: '6px',
																													width: '6px',
																													lineHeight: '6px',
																													color: 'white',
																													borderRadius: '100%',
																													fontSize: '12px',
																												}}
																												onClick={() => this.eliminar_cliente_contratos_individuales_firmados(item, key)}
																												children={'x'}
																											/>
																										) : "")
																									) : ''}
																								</div>
																								
																								<Typography
																									className={'text-right text-gray margin-0 padding-0'}
																									style={{lineHeight: '18px', fontSize: '12px'}}>
																									{item.created_at ? DateFormat.FechaHoraFormatString(item.created_at) : ''}
																								</Typography>
																								
																								<Typography
																									className={'text-left text-black margin-0 padding-0 padding-5-T px-14'}>
																									Cantidad elaborados: {item.cantidad_elaborado}
																								</Typography>
																								
																								<div className={'w-100'}>
																									{item.excel_elaborado_archivo ? (
																										<div className="vertical-inline">
																											<div className={'v-center'}>
																												<Typography
																													className={'text-left text-black margin-0 padding-0 px-14'}>
																													Contratos elaborados:
																												</Typography>
																											</div>
																											<div
																												className={'padding-5-L padding-5-R w-18-px v-center'}>
																												{Icono.get(item.excel_elaborado_formato, '20px', '20px')}
																											</div>
																											<div className={'v-center'}>
																												<Fragment>
																													<ModalImage
																														tipo={'view'} desing={3}
																														item={{
																															base64: item.excel_elaborado_base64,
																															base64Tipo: item.excel_elaborado_base64Tipo,
																															archivo: item.excel_elaborado_archivo,
																															formato: item.excel_elaborado_formato,
																														}}
																													/>
																												</Fragment>
																											</div>
																										</div>
																									) : ''}
																								</div>
																								
																								<div className={'w-100'}>
																									{item.excel_pendiente_archivo ? (
																										<div className="vertical-inline">
																											<div className={'v-center'}>
																												<Typography
																													className={'text-left text-black margin-0 padding-0 px-14'}>
																													Contratos pendientes:
																												</Typography>
																											</div>
																											<div
																												className={'padding-5-L padding-5-R w-18-px v-center'}>
																												{Icono.get(item.excel_pendiente_formato, '20px', '20px')}
																											</div>
																											<div className={'v-center'}>
																												<Fragment>
																													<ModalImage
																														tipo={'view'} desing={3}
																														item={{
																															base64: item.excel_pendiente_base64,
																															base64Tipo: item.excel_pendiente_base64Tipo,
																															archivo: item.excel_pendiente_archivo,
																															formato: item.excel_pendiente_formato,
																														}}
																													/>
																												</Fragment>
																											</div>
																										</div>
																									) : ''}
																								</div>
																								
																								<Typography className={'text-left px-12 text-black margin-0 padding-0'}>
																									Resguardo
																									Físico: {!item.lugar_resguardo && !item.responsable_resguardo ? 'Sin resguardo' : (item.plaza ? item.plaza + ' - ' : '') + item.lugar_resguardo + (item.lugar_resguardo && item.responsable_resguardo ? ' - ' : ' ') + item.responsable_resguardo}
																								</Typography>
																								
																								<Typography
																									className={'text-left text-black margin-0 padding-0 px-14'}>
																									Comentarios: {item.comentario_general || 'Sin comentarios'}
																								</Typography>
																							
																							</CardContent>
																						</Card>
																					
																					</Grid>
																				) : null}
																			</Fragment>
																		))}
																	</Grid>
																</Grid>
																
																{this.state.cliente_contratos_individuales_firmados.length <= 0 ? (
																	<Fragment>
																		<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
																			<VistaVacia
																				numero={0}
																				mensaje='No se han reportado contratos individuales firmados realizados.'
																				width={'100px'}
																				height={'100px'}
																			/>
																		</Grid>
																	</Fragment>
																) : ''}
															
															</Grid>
														</CardContent>
													</Card>
												
												</Grid>
												
												<Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
													
													{(this.pendiente_para_realizar_firmado() <= 0 && Number(this.state.total_contratos_individuales) > 0) &&
													(this.pendiente_para_realizar_ias_firmado() <= 0 && Number(this.state.total_contratos_individuales_ias) > 0) ? (
														<Fragment>
															<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																
																{!disabledFieldJuridicoContratoIndividual ? (
																	<Typography
																		className={'text-center text-black padding-0 margin-0 px-22'}>
																		Has indicado que los contratos individuales de trabajo se firmaron y se
																		completó al 100%, informale a {this.state.cliente.usuario_alta_cliente}.
																	</Typography>
																) : (
																	<Typography
																		className={'text-center text-black padding-0 margin-0 px-22'}>
																		Has indicado que los contratos individuales de trabajo se firmaron y se
																		completó al 100%.
																	</Typography>
																)}
															
															</Grid>
															
															<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																
																<TextField
																	//required
																	fullWidth
																	onChange={this.handleChange}
																	type="text"
																	margin="none"
																	//helperText="Requerido"
																	name="comentario_juridico_contratos_individuales"
																	label="Comentario contratos individuales y firmas"
																	value={this.state.comentario_juridico_contratos_individuales}
																	disabled={disabledFieldJuridicoContratoIndividual}
																/>
															
															</Grid>
														
														</Fragment>
													) : ''}
												
												</Grid>
											</Grid>
										
										
										</TabPanel>
									</TabContext>
								</div>
							</Grid>
						</Grid>
						
						<br/>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={1}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!disabledFieldJuridicoContratoIndividual ? (
									<Button variant="contained" color="primary"
									        onClick={this.guardarJuridicoContratoIndividual}
									        className={'btn-default-primary text-transform-none margin-10-R margin-10-L'}>
										Informar al promotor
									</Button>
								) : ''}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ContratosIndividualesElaborados.propTypes = {
	titulo: PropTypes.string.isRequired,
	id_cliente: PropTypes.number.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ContratosIndividualesElaborados;
