import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import {AttachMoney, DeleteOutlined, EditOutlined, HdrStrong, SearchOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DocSvg from "../../../../assets/img/icons/doc.svg";
import {DropzoneArea} from "material-ui-dropzone";
import {LineaCapturaService} from "../../../../services/LineaCapturaService/LineaCapturaService";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";


class ModalComprobantePago extends Component {
	
	state = {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_linea_captura_comprobante_pago: (props.item || '').id_linea_captura_comprobante_pago,
			id_linea_captura: (props.item || '').id_linea_captura,
			tipo: props.tipo,
			observaciones: '',
			comprobante_pago: 1,
			files: [],
			archivos: [],
			archivo_base64: '',
			archivo_base64Tipo: '',
			archivo: '',
			archivo_formato: '',
			archivo_nombre: '',
		};
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	
	darformato = (file) => {
		return new Promise((resolve, reject) => {
			let formatos = ["text/xml", ".csv", "text/csv", "application/xlsx", "application/pdf", "application/xls", "application/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
			
			let fil = {files: [file]};
			FileBase64.Base64(fil, formatos, this.props.MegaByte || null, true).then((response) => {
				//this.props.onChange(response);
				this.handleChange({
					target: {
						name: 'archivo_base64',
						value: response.base64
					}
				});
				this.handleChange({
					target: {
						name: 'archivo_base64Tipo',
						value: response.base64Tipo
					}
				});
				this.handleChange({
					target: {
						name: 'archivo',
						value: response.archivo
					}
				});
				this.handleChange({
					target: {
						name: 'archivo_formato',
						value: response.formato
					}
				});
				this.handleChange({
					target: {
						name: 'archivo_nombre',
						value: response.name
					}
				});
				resolve(true);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
				reject();
			});
		});
	}
	
	save = () => {
		
		try {
			
			if (!FieldsJs.Field(this.state.files) && this.state.files.length === 0) {
				throw Object({
					status: false, mensaje: 'No se ha seleccionado el archivo.'
				})
			}
			
			this.darformato(this.state.files[0]).then(() => {
				if (this.state.id_linea_captura_comprobante_pago > 0) {
					LineaCapturaService.ComprobantePago(this.state).then((response) => {
						this.props.RefreshList();
						this.modalClose();
						hideSpinner('spinner', 500);
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				} else {
					LineaCapturaService.ComprobantePago(this.state).then((response) => {
						this.props.RefreshList();
						this.modalClose();
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						hideSpinner('spinner', 500);
						this.props.showSnackBars('error', error.mensaje);
					});
				}
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		
		console.log(this.props);
		const {item} = this.props;
		
		this.setState({
			id_linea_captura_comprobante_pago: item.id_linea_captura_comprobante_pago || '',
			id_linea_captura: item.id_linea_captura || '',
			observaciones: '',
			comprobante_pago: 1,
			archivos: [],
			activo: true,
			archivo_base64: '',
			archivo_base64Tipo: '',
			archivo: '',
			archivo_formato: '',
			archivo_nombre: '',
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_linea_captura_comprobante_pago: item.id_linea_captura_comprobante_pago || '',
			id_linea_captura: item.id_linea_captura || '',
			observaciones: item.observaciones || null,
			comprobante_pago: 1,
			archivos: item.archivos || [],
			activo: (item.activo === 1),
			archivo_base64: '',
			archivo_base64Tipo: '',
			archivo: '',
			archivo_formato: '',
			archivo_nombre: '',
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_linea_captura_comprobante_pago: item.id_linea_captura_comprobante_pago || '',
			id_linea_captura: item.id_linea_captura || '',
			observaciones: item.observaciones || null,
			comprobante_pago: 1,
			archivos: item.archivos || [],
			activo: (item.activo === 1),
			archivo_base64: '',
			archivo_base64Tipo: '',
			archivo: '',
			archivo_formato: '',
			archivo_nombre: '',
		});
		
		this.openModal('paper');
		
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
	};
	
	eliminar_documento = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.archivos);
		arr_temp.splice(key, 1);
		this.setState({
			archivos: arr_temp
		});
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_linea_captura_comprobante_pago > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Estatus">
						<IconButton aria-label="Estatus" onClick={() => this.edit()}>
							<HdrStrong/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Comprobante Pago">
					<IconButton aria-label="Comprobante Pago" onClick={() => this.add()}><AttachMoney/></IconButton>
				</Tooltip>
			);
		}
		return BTN_ACTION;
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{'Agregar Comprobante de Pago'}</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									fullWidth
									multiline
									rows={3}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									className={'margin-0'}
									margin="dense"
									name="observaciones"
									label="Observaciones"
									value={this.state.observaciones}
								/>
							</Grid>
							
							
							{this.state.archivos.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'padding-10-T padding-10-B px-14 text-left'} style={{fontWeight: 'bold'}}>
										Documento(s) Anexo:
									</Typography>
									<div className={'vertical-inline'}>
										{this.state.archivos.map((item, key) => (
											<div>
												<div className={'vertical-inline b-r-20 margin-5 margin-0-L'} key={key}>
													<img className={'v-center cursor-pointer'} src={DocSvg} alt="add" width={40} height={40} style={{opacity: 0.7, marginRight: '5px'}} onClick={() => this.descargarZip(item.archivo)}/>
													
													
													{this.props.tipo === 'edit' ? (<div className={'vertical-inline v-end b-r-20 margin-5 ' + (this.props.tipo === 'view' ? 'bg-gray' : 'bg-danger')} key={key}>
														<div className={'padding-3 v-end text-white cursor-pointer'}>
															{this.props.tipo === 'view' ? null : (
																<DeleteOutlined className={'px-16 text-white'} onClick={() => this.eliminar_documento(key)}/>
															)
															}
														</div>
													</div>) : null}
												</div>
											
											
											</div>
										))}
									</div>
								</Grid>
							) : null}
							
							{this.props.tipo !== 'view' ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'dropzone-cronograma margin-15-T'}>
									<div className={'w-100'}>
										<DropzoneArea
											onChange={this.handleChangeDropzoneArea.bind(this)}
											open={this.state.open}
											filesLimit={1}
											dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
											acceptedFiles={['application/pdf']}
											showPreviews={false}
											maxFileSize={5000000}
											showAlerts={false}
											showFileNames={true}
											showFileNamesInPreview={true}
										/>
									</div>
								</Grid>
							) : null}
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_linea_captura_comprobante_pago > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalComprobantePago.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default IntegrationNotistack(ModalComprobantePago);
