import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {PlazaDireccionService} from "../../../../services/_Cat/PlazaDireccion/PlazaDireccionService";
import {
	BotonAccionAbrirModal,
	BotonAccionFooterModal,
	TituloHeaderModal
} from "../../../Include/MiniComponents/GlobalComponent";
import ModalPlaza from "../../Cat/Plaza/Includes/ModalPlaza";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import {GrainOutlined, HdrStrongOutlined, HdrWeakOutlined, ImageAspectRatioOutlined, PinDropOutlined, PlaylistAddOutlined, StreetviewOutlined} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {PlazaService} from "../../../../services/_Cat/Plaza/PlazaService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {CatService} from "../../../../services/_Cat/CatService/CatService";

class ModalPlazaDireccion extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		console.log('PROPS', props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_plaza_direccion: (props.item || {}).id_plaza_direccion,
			abreviatura: '',
			id_plaza: (props.item || {}).id_plaza,
			plaza: (props.item || {}).plaza,
			id_cat_estado: '',
			id_cat_municipio: '',
			calle: '',
			numero_exterior: '',
			nombre_plaza: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			referencia: '',
			domicilio_fiscal: false,
			activo: true,
			
			lista_plaza: [],
			
			
			cat_estado: [],
			cat_municipio: []
			
		};
		
		
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props.item;
		console.log('>>>>>>>>>>>>>>>>', this.state.id_plaza, props.item);
		this.setState({
			modal: {
				open: true
			},
			id_plaza: props.id_plaza
		});
	};
	
	open = () => {
		const {item} = this.props;
		if (item.id_plaza > 0) {
			this.init().then(r => {
				setTimeout(() => this.openModal());
				this.ListaPlaza();
				this.ListEstado();
				
				if (this.state.id_cat_estado > 0)
					this.ListMunicipio(null, this.state.id_cat_estado);
			}).catch(e => {
				console.log(e);
			});
		} else {
			this.props.showSnackBars('error', 'Plaza requerida.');
		}
		
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		if (this.state.id_plaza_direccion > 0) {
			PlazaDireccionService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			PlazaDireccionService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log('PROPS', this.props);
		this.setState({
			id_plaza_direccion: '',
			id_plaza: '',
			plaza: this.props.item.plaza,
			nombre_plaza: '',
			id_cat_estado: '',
			id_cat_municipio: '',
			calle: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			referencia: '',
			domicilio_fiscal: false,
			activo: true
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log('PROPS2', this.props);
		this.setState({
			id_plaza_direccion: item.id_plaza_direccion || '',
			id_plaza: item.id_plaza || '',
			nombre_plaza: item.nombre_plaza || '',
			id_cat_estado: item.id_cat_estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
			calle: item.calle || '',
			numero_exterior: item.numero_exterior || '',
			numero_interior: item.numero_interior || '',
			codigo_postal: item.codigo_postal || '',
			colonia: item.colonia || '',
			referencia: item.referencia || '',
			domicilio_fiscal: (item.domicilio_fiscal === 1),
			activo: (item.activo === 1)
		});
		this.open();
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_plaza_direccion: item.id_plaza_direccion || '',
			id_plaza: item.id_plaza || '',
			nombre_plaza: item.nombre_plaza || '',
			id_cat_estado: item.id_cat_estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
			calle: item.calle || '',
			numero_exterior: item.numero_exterior || '',
			numero_interior: item.numero_interior || '',
			codigo_postal: item.codigo_postal || '',
			colonia: item.colonia || '',
			referencia: item.referencia || '',
			domicilio_fiscal: (item.domicilio_fiscal === 1),
			activo: (item.activo === 1)
		});
		this.open();
	};
	
	
	
	ListEstado = () => {
		CatService.ListEstado().then((response) => {
			this.setState({
				cat_estado: response.data
			});
		});
	};
	
	ListMunicipio = (e, id_cat_estado) => {
		
		if (!id_cat_estado) {
			id_cat_estado = e.target.value;
		}
		
		CatService.ListMunicipio(id_cat_estado).then((response) => {
			this.setState({
				cat_municipio: response.data
			});
		});
	};
	
	
	RefreshListPlaza = (id_plaza) => {
		if (id_plaza > 0) {
			this.setState({
				id_plaza: id_plaza
			})
		}
	};
	
	ListaPlaza = () => {
		PlazaService.Listar().then(value => {
			this.setState({
				lista_plaza: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_plaza: []
			});
		});
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_plaza_direccion}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Dirección de la Oficina'}
						/>
					</DialogTitle>
					
					<DialogContent>
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									<Grid item style={{textAlign:'center', color:'#3f51b5', width: 'calc(100%)'}}>
										
										<label>{this.state.plaza}</label>
										
										{/*<TextField
											type={'text'}
											fullWidth
											margin="none"
											onChange={this.handleChange}
											disabled
											name="plaza"
											label="Plaza"
											defaultValue={this.state.plaza}
											value={this.state.plaza}
										>
										</TextField>*/}
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PinDropOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="nombre_plaza"
										           onChange={this.handleChange}
										           required
										           label="Nombre de la oficina" autoComplete={'off'}
										           value={this.state.nombre_plaza}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PinDropOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="calle"
										           onChange={this.handleChange}
										           label="Calle" autoComplete={'off'}
										           value={this.state.calle}
										           required
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<StreetviewOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="numero_exterior"
										           onChange={this.handleChange}
										           label="Numero Exterior" autoComplete={'off'}
										           value={this.state.numero_exterior}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<StreetviewOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="numero_interior"
										           onChange={this.handleChange}
										           label="Numero Interior" autoComplete={'off'}
										           value={this.state.numero_interior}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<ImageAspectRatioOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="codigo_postal"
										           onChange={this.handleChange}
										           label="Código Postal" autoComplete={'off'}
										           value={this.state.codigo_postal}
										           required
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<GrainOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="colonia"
										           onChange={this.handleChange}
										           label="Colonia" autoComplete={'off'}
										           value={this.state.colonia}
										           required
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Estado</InputLabel>
											<Select
												native
												value={this.state.id_cat_estado}
												onChange={(e) => {
													this.handleChange(e);
													this.ListMunicipio(e);
												}}
												name="id_cat_estado"
												input={<Input/>}
												required
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_estado.map(option => (
													<option key={option.id_cat_estado}
													        value={option.id_cat_estado}>
														{option.estado}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrongOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										
										<FormControl fullWidth>
											<InputLabel>Municipio</InputLabel>
											<Select
												native
												value={this.state.id_cat_municipio}
												onChange={this.handleChange}
												name="id_cat_municipio"
												input={<Input/>}
												required
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_municipio.map(option => (
													<option key={option.id_cat_municipio}
													        value={option.id_cat_municipio}>
														{option.municipio}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<GrainOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="referencia"
										           onChange={this.handleChange}
										           label="Referencia" autoComplete={'off'}
										           value={this.state.referencia}
										           multiline
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='domicilio_fiscal'
												checked={this.state.domicilio_fiscal}
												value="domicilio_fiscal"
											/>
										}
										label={'Solo domicilio fiscal'}
									/>
								</FormGroup>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_plaza_direccion}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPlazaDireccion.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalPlazaDireccion;
