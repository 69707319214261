import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import {FieldsJs} from "../../../../settings/General/General";

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {NubeService} from "../../../../services/NubeService/NubeService";
import {CONFIG} from "../../../../settings/Config/Config";
import {CloudDownload, Folder} from "@material-ui/icons";

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el sexo ${item.sexo}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				NubeService.Eliminar(item.id_cat_sexo).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'} className={'padding-20-L padding-20-R'}>Nombre</TableCell>
							{/*<TableCell component="th" align={'left'}>Tamaño</TableCell>
							<TableCell component="th" align={'left'}>Fecha de Modificación</TableCell>*/}
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<TableRow key={key}>
								<TableCell align={'left'} className={'padding-20-L padding-20-R'}>
									<div className={'vertical-inline cursor-pointer'} onClick={() => this.props.gerContentFile(item.path_display, item.name)}>
										<div className={'v-center padding-15-R'}>
											{item.thumb ? <img src={`data:image/jpeg;base64,${item.thumb.thumbnail}`} width={32}/> : <Folder style={{fontSize: '32px', color: 'rgb(142, 30, 50)'}}/>}
										</div>
										<div className={'v-center'}>
											{item.name}
										</div>
									</div>
								</TableCell>
								{/*<TableCell align={'left'}>{{item.size}}</TableCell>
								<TableCell align={'left'}>{{item.client_modified ? this.nuevaFecha(item.client_modified) : ''}}</TableCell>*/}
								<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
									<div className={'w-auto vertical-inline'}>
										{/*<div className={'v-center'}>
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
														<IconButton aria-label="Descargar"
														            onClick={() => this.descargarArchivo(item)}>
															<GetApp/>
														</IconButton>
													</Tooltip>
												</div>*/}
										
										{FieldsJs.inArray(["/USUARIOS", "empresas", "clientes", "historial", "acuses", "facturacion", "comprobantes_nomina"], item.path_display) === false ? (
											<div className={'v-center'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Descargar">
													<IconButton aria-label="Descargar" onClick={() => this.props.descargarZip(item.path_display)}>
														<CloudDownload style={{color: 'rgb(142, 30, 50)'}}/>
													</IconButton>
												</Tooltip>
											</div>
										) : null}
										
										{/*<div className={'v-center'}>
													<ModalNube
														tipo={'edit'}
														item={item}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
												<div className={'v-center'}>
													<ModalNube
														tipo={'view'}
														item={item}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>*/}
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	gerContentFile: PropTypes.func.isRequired,
	descargarZip: PropTypes.func.isRequired,
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ListaTabla;
