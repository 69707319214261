import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import Typography from "@material-ui/core/Typography";
import {DropzoneArea} from "material-ui-dropzone";
import {ResguardoService} from "../../../../services/ResguardoService/ResguardoService";


class ModalSubirPDF extends Component {
	
	state = {};
	
	Usr = {};
	
	showSnackBars = this.props.showSnackBars;
	RefreshList = this.props.RefreshList;
	
	constructor(props) {
		super(props);
		
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo,
			id_resguardo: (props.item || null).id_resguardo,
			files: [],
			archivos: [],
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		
		console.log(this.state, props);
	};
	
	handleChangeDropzoneArea = (file) => {
		this.setState({
			files: file
		})
	}
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	add = () => {
		let {item} = this.props;
		
		this.setState({
			id_resguardo: item.id_resguardo || null
		})
		
		this.open();
	}
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		
		let {item} = this.props;
		
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	guardarDocTimbradoDbx = () => {
		let ftar = this.state.files;
		let file;
		
		return new Promise((resolve, reject) => {
			let archives = [];
			
			for (let i = 0; i < ftar.length; i++) {
				console.log("2 FTAR::: ", ftar);
				file = ftar[i];
				
				let ruta = '/Resguardo/' + this.Usr.id_usuario + '-' + this.Usr.nombre_completo + '/' + (this.props.item.equipo ? this.props.item.equipo + '_Firmado_': 'Firmado_') + file.name;
				
				DropboxApi.Upload(ruta, file).then(function (response) {
					archives.push(response.path_display);
					resolve(archives);
				}).catch(function (error) {
					reject(error);
				});
				
			}
		});
	};
	
	save = () => {
		console.log("THIS.STATE: ", this.state);
		
		try {
			if (this.state.files.length <= 0) {
				throw Object({
					status: false, mensaje: 'Agrega un archivo.'
				})
			}
			
			let guardar = new Promise((resolve, reject) => {
				this.guardarDocTimbradoDbx().then(response => {
					
					this.setState({
						archivos: response,
					});
					resolve(response);
					
				}).catch(error => {
					reject(error);
				})
			});
			
			guardar.then(response => {
				ResguardoService.SubirPDF(this.state).then((response) => {
					this.closeModal();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			})
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			/*case 'view':
				funcion = this.view;
				break;*/
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_resguardo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Subir Resguardo Firmado
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'margin-15-B'} style={{color: 'gray', opacity: '0.8'}}>
									Solo puedes seleccionar un archivo PDF, con el resguardo firmado.
								</Typography>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100'}>
									<DropzoneArea
										onChange={this.handleChangeDropzoneArea.bind(this)}
										open={this.state.open}
										filesLimit={1}
										dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
										acceptedFiles={['application/pdf']}
										showPreviews={false}
										maxFileSize={5000000}
										showAlerts={false}
										showFileNames={true}
										showFileNamesInPreview={true}
									/>
								</div>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() ? (
									<Button onClick={() => this.save()} color="primary">
										{'Guardar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalSubirPDF.propTypes = {
	item: PropTypes.object.isRequired,
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	componente: PropTypes.element,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
};

export default ModalSubirPDF;
